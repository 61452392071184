import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography, InputLabel, Tooltip, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowForward, Close, Help, Undo } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import { Cards, SelectChip, AlertWrapper, AutocompleteWrapper, DialogButton } from 'src/components';
import { humanReadableArrayOfStrings as hraos } from 'src/utils/util';
import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';


const { NOT_STARTED, LOADING, SUCCESS, ERROR } = fetchStatusEnum;

const useStyles = makeStyles(theme => ({
  helpText: {
    cursor: 'pointer',
    fontSize: theme.spacing(2),
  },
  boxLabel: {
    whiteSpace: 'nowrap',
  },
}));

const ImportPointsDialog = props => {
  const { actions, currentPointsCount, csLang, importPointsCount, propertiesKeys, availablePointInputs } = props;

  const classes = useStyles();

  const showSelectChip = propertiesKeys.length <= 4;

  const [ fetchStatus, setFetchStatus ] = useState(NOT_STARTED);
  const [ errorAlert, setErrorAlert ] = useState({ isOpen: false, message: '' });

  const [ inputs, setInputs ] = useState(availablePointInputs
    .map(({ id, label, helpText }) => ({ label, id, helpText, mapId: undefined, isDisabled: false })));

  const handleOptionClick = (id, mapId) => {
    setInputs(prevInputs => prevInputs.map(input => input.id === id ? { ...input, mapId } : input));
  };

  const hasProperties = propertiesKeys.length > 0;
  const hasPointsToRemove = currentPointsCount > 0;

  const propertyOptions = hasProperties ? propertiesKeys.map(pKey => ({ label: pKey, value: pKey })) : [];

  const cardActions = itemData => ([
    {
      Icon: itemData.isDisabled ? props => <Undo fontSize="small" {...props} /> : Close,
      tooltip: itemData.isDisabled ? 'Habilitar' : 'Descartar',
      onClick: () =>
        setInputs(inputs.map(input => input.id === itemData.id ?
          { ...input, mapId: undefined, isDisabled: !itemData.isDisabled } :
          input,
        )),
    },
  ]);

  const selectedProperties = [ ...new Set(inputs.filter(i => i.mapId).map(i => i.mapId)) ];

  const getInputMapIdValue = itemData => inputs.find(inp => inp.id === itemData.id).mapId;

  const handleConfirm = async () => {
    const propertiesMapper = {};
    inputs.forEach(input => propertiesMapper[input.id] = input.mapId);

    try {
      setFetchStatus(LOADING);
      await actions.importPoints(propertiesMapper);
      setFetchStatus(SUCCESS);
      setTimeout(() => actions.closeDialog(), 300);

    } catch (e) {
      const message = e.serverMessage || 'Hubo un problema al intentar importar el archivo, por favor intenta más tarde';
      setErrorAlert({ isOpen: true, message });
      setFetchStatus(ERROR);
    }
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">Importar {csLang.points}</DialogTitle>
      <DialogContent>
        <Box p={1}>
          <AlertWrapper severity={hasPointsToRemove ? 'warning' : 'info'}>
            <Typography variant="body2">
              {importPointsCount === 1 && <>El KML contiene <strong>1 punto</strong> para importar como {csLang.point}</>}
              {importPointsCount > 1 &&
                <>El KML contiene <strong>{importPointsCount} puntos</strong> para importar como {csLang.points}</>
              }
              {!hasPointsToRemove === 0 && '.'}
              {hasPointsToRemove > 0 &&
                <>, sin embargo, <strong>se borrarán las {currentPointsCount} {csLang.points} existentes</strong>.</>
              }
            </Typography>
          </AlertWrapper>
          <br />
          {hasProperties &&
            <>
              <Typography variant="body1" paragraph>
                {propertiesKeys.length === 1 && <>Se encontró la propiedad</>}
                {propertiesKeys.length > 1 && <>Se encontraron las propiedades</>}
                &nbsp;<strong>{hraos(propertiesKeys)}</strong> en los puntos del KML, seleccione como prefiere
                importar los datos disponibles
              </Typography>
              <Box display="flex" alignItems="flex-end">
                <InputLabel shrink>Datos a importar</InputLabel>
                <Box flexGrow={1} />
              </Box>
              <Cards
                values={inputs}
                actions={cardActions}
                variant="condensed"
                Content={({ itemData }) =>
                  <Box display="flex" alignItems="center">
                    <Box mr={0.25} className={classes.boxLabel}>{itemData.label}</Box>
                    <Tooltip title={itemData.helpText}>
                      <Help className={classes.helpText} />
                    </Tooltip>
                    {!itemData.isDisabled &&
                      <>
                        <Box display="flex" mx={1}><ArrowForward fontSize="small" /></Box>
                        {showSelectChip ?
                          <SelectChip
                            value={inputs.find(inp => inp.id === itemData.id).mapId}
                            options={propertyOptions}
                            onChange={option => handleOptionClick(itemData.id, option.value)}
                            toUpperFirst={false}
                          /> :
                          <Box width="240px">
                            <AutocompleteWrapper
                              value={{ label: getInputMapIdValue(itemData), value: getInputMapIdValue(itemData) }}
                              options={propertyOptions}
                              placeholder={'Seleccione una propiedad'}
                              onChange={(event, option) => handleOptionClick(itemData.id, option?.value)}
                              getOptionSelected={(option, value) => option.value === value.value}
                            />
                          </Box>

                        }
                      </>
                    }
                  </Box>
                }
              />
              <br />
            </>
          }
          <br />
          <Typography variant="body1">
            {hasPointsToRemove > 0 &&
              <>
                ¿Deseas <strong>{hasPointsToRemove ? 'reemplazar' : 'importar'} las antiguas {csLang.points}</strong> por las del KML
                {selectedProperties.length > 0 && <> junto a los datos de <strong>{hraos(selectedProperties)}</strong></>}
                ?
              </>
            }
          </Typography>
          {fetchStatus === ERROR &&
            <Collapse in={errorAlert.isOpen}>
              <Box mt={2}>
                <Alert severity="error" onClose={() => setErrorAlert(s => ({ ...s, isOpen: false }))}>
                  {errorAlert.message}
                </Alert>
              </Box>
            </Collapse>
          }
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog} color="default">Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>
          {hasPointsToRemove ? 'Sí, reemplazar' : 'Sí, importar'}
        </DialogButton>
      </DialogActions>
    </>
  );
};

ImportPointsDialog.propTypes = {
  actions: PropTypes.object,
  currentPointsCount: PropTypes.number,
  csLang: PropTypes.object,
  importPointsCount: PropTypes.number,
  availablePointInputs: PropTypes.array,
  propertiesKeys: PropTypes.array,
};


export {
  ImportPointsDialog,
};
