import React, { useState, useEffect } from 'react';
import { Grid, Box, ImageList, ImageListItem, ImageListItemBar, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton, Pagination, PaginationItem } from '@material-ui/lab';
import { useLocation, useHistory } from 'react-router';
import urlJoin from 'url-join';
import { Link } from 'react-router-dom';

import { TitleContainer, ContentContainer, DialogWrap, DialogImagePreviewer,
  FilterText, FilterSelect, FilterCheckbox, Collapser, AsyncImage } from 'src/components';
import config from 'src/config/local';
import { campaignsApi, speciesApi, usersApi } from 'src/services';
import { upperCaseFirstLetter } from 'src/utils/formatters';
import { objectIsEmpty as oie } from 'src/utils/util';
import { useWidth } from 'src/hooks';


const { serverUrl } = config;

const component = [
  { value: 'flora', label: 'Flora - forestal' },
  { value: 'fauna', label: 'Fauna' },
];

const recordsTypesOptions = [
  { value: 'flora-record', label: 'Registros de flora' },
  { value: 'flora-individual-record', label: 'Registros individuales de flora' },
  { value: 'forest-record', label: 'Registros forestales' },
  { value: 'flora-point-quadrat-record', label: 'Registros point-quadrat de flora' },
  { value: 'flora-point-intercept-record', label: 'Registros de punto intercepto de flora' },
  { value: 'flora-census-record', label: 'Registros de censo flora' },
  { value: 'fauna-direct-record', label: 'Registros directos de fauna' },
  { value: 'fauna-indirect-record', label: 'Registros indirectos de fauna' },
  { value: 'fauna-isolated-record', label: 'Registros aislados de fauna' },
  { value: 'fauna-transit-record', label: 'Registros tránsito de fauna' },
  { value: 'fauna-presence-record', label: 'Registros de presencia de fauna' },
];

const imageListColumnsMapper = { xl: 5, lg: 4, md: 3, sm: 2, xs: 1 };

const campaignFilterKey = 'campaign';
const componentFilterKey = 'component';
const recordTypeFilterKey = 'recordType';
const genusFilterKey = 'genus';
const speciesFilterKey = 'species';
const eeccFilterKey = 'eecc';

const maxPicturesPerPage = 12;


const subtitleMaker = ({ author, eeccCategory, recordType }) => (
  <div>
    { recordType && <><span>{ recordType } </span><br/></> }
    { eeccCategory && <><span>Categoría de conservación: { eeccCategory } </span><br/></> }
    { author && <><span>Por: { author } </span><br/></> }
  </div>
);

const useStyles = makeStyles({
  albumThumbnail: {
    top: '50%',
    width: '100%',
    position: 'relative',
    transform: 'translateY(-50%)',
  },
});

const AlbumContainer = () => {
  const location = useLocation();
  const history = useHistory();
  const widthBreakpoint = useWidth();
  const imageListColumns = imageListColumnsMapper[widthBreakpoint];
  const classes = useStyles();

  const [ isOpenDialog, setIsOpenDialog ] = useState(false);
  const [ pictureIndex, setPictureIndex ] = useState(0);
  const [ picturesList, setPicturesList ] = useState([]);
  const [ isFetching, setIsFetching ] = useState(false);
  const [ filtersLabels, setFiltersLabels ] = useState();
  const [ filters, setFilters ] = useState([]);
  const [ totalPicturesCount, setTotalPicturesCount ] = useState(0);

  const query = new URLSearchParams(location.search);

  const getQueryParams = () => {
    const query = new URLSearchParams(location.search);

    const tempSize = parseInt(query.get('size') || maxPicturesPerPage, 10);
    const size = tempSize < maxPicturesPerPage ? tempSize : maxPicturesPerPage;
    const page = parseInt(query.get('page') || 1, 10);

    const campaign = query.get(campaignFilterKey);
    const component = query.getAll(componentFilterKey).length ? query.getAll(componentFilterKey) : [];
    const recordType = query.getAll(recordTypeFilterKey).length ? query.getAll(recordTypeFilterKey) : [];
    const genus = query.get(genusFilterKey);
    const species = query.get(speciesFilterKey);
    const eecc = query.get(eeccFilterKey) === 'true';

    return { page, size, campaign, component, recordType, genus, species, eecc };
  };

  const { page, size, campaign, genus, species } = getQueryParams();

  useEffect(() => {
    document.title = 'Álbum - Nviro Capture';
    const fetchData = async () => {
      const { campaign, genus, species, component, recordType, eecc } = getQueryParams();
      const filtersThatNeedLabels = { campaign };
      Object.keys(filtersThatNeedLabels).forEach(key => filtersThatNeedLabels[key] === null ? delete filtersThatNeedLabels[key] : {});
      const [ , filtersLabels ] = await Promise.all([
        getPictures(),
        oie(filtersThatNeedLabels) ? {} : usersApi.getLabels(filtersThatNeedLabels),
      ]);
      setFilters({ campaign, genus, species, component, recordType, eecc });
      setFiltersLabels(filtersLabels);
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const getPictures = async () => {
    try {
      setIsFetching(true);
      const queryString = query.toString();
      await history.push({ pathname: location.pathname, search: `?${queryString}` });
      const pictures = (await usersApi.getPictures(queryString)).map(p => ({ ...p, speciesName: p.scientificName }));
      const totalPicturesCount = pictures[0]?.totalPicturesCount || 0;
      setTotalPicturesCount(totalPicturesCount);
      setPicturesList(pictures);
      setIsFetching(false);
    } catch (e) {
      setIsFetching(false);
    }
  };

  const openDialog = pictureIndex => {
    setPictureIndex(pictureIndex);
    setIsOpenDialog(true);
  };

  const closeDialog = () => setIsOpenDialog(false);

  const searchApiMapper = {
    campaign: campaignsApi.search,
    genus: queryText => speciesApi.searchGenuses({ queryText, excludeLost: false, onlyOfficial: false }),
    species: queryText => speciesApi.searchSpecies({ queryText, genus, excludeLost: false, onlyOfficial: false }),
  };

  const transformToOptions = searchResult => searchResult.map(r => ({ ...r, label: upperCaseFirstLetter(r.name), value: r.id }));

  const getOptions = async ({ queryText, id }) => {
    const result = await searchApiMapper[id](queryText);
    return transformToOptions(result);
  };

  const addFiltersToQuery = (query, filters) => {
    Object.keys(filters).forEach(key => {
      if (Array.isArray(filters[key])) {
        query.delete(key);
        filters[key].forEach(val => query.append(key, val));
      } else if (typeof filters[key] === 'boolean') {
        filters[key] ? query.set(key, filters[key]) : query.delete(key);
      } else if (filters[key] !== null) {
        query.set(key, filters[key]);
      }
    });
  };

  const setFilter = async ({ id, value, label = '' }) => {
    const filter = { [id]: value };
    addFiltersToQuery(query, { ...filter, page: 1 });
    setFilters(prev => ({ ...prev, ...filter }));
    label && setFiltersLabels(prev => ({ ...prev, [id]: label }));
    await getPictures();
  };

  const totalPages = Math.ceil(totalPicturesCount / size);

  const handlePage = async (event, page) => {
    query.set('page', page);
    await getPictures();
  };

  const filtersQuery = new URLSearchParams();
  addFiltersToQuery(filtersQuery, filters);
  return (
    <Grid container>
      <TitleContainer maxWidth={false}>Álbum General</TitleContainer>
      <ContentContainer maxWidth={false}>
        <Box mt={1}>
          <Collapser title={'Filtros'}>
            {filtersLabels &&
              <>
                <Grid container spacing={1}>
                  <Grid item>
                    <FilterText
                      id={campaignFilterKey}
                      label="Campaña"
                      prevValue={{ label: filtersLabels[campaignFilterKey], value: campaign }}
                      getOptions={getOptions}
                      onChange={setFilter}
                    />
                  </Grid>
                  <Grid item>
                    <FilterText
                      id={genusFilterKey}
                      label="Género"
                      prevValue={{ label: genus ? upperCaseFirstLetter(genus) : genus, value: genus }}
                      getOptions={getOptions}
                      onChange={setFilter}
                    />
                  </Grid>
                  <Grid item>
                    <FilterText
                      id={speciesFilterKey}
                      label="Especie"
                      prevValue={{ label: species ? upperCaseFirstLetter(species) : species, value: species }}
                      getOptions={getOptions}
                      onChange={setFilter}
                    />
                  </Grid>
                  <Grid item>
                    <FilterSelect
                      id={componentFilterKey}
                      label="Componente"
                      prevValue={filters[componentFilterKey]}
                      options={component}
                      onChange={setFilter}
                    />
                  </Grid>
                  <Grid item>
                    <FilterSelect
                      id={recordTypeFilterKey}
                      label="Tipo de registro"
                      prevValue={filters[recordTypeFilterKey]}
                      options={recordsTypesOptions}
                      onChange={setFilter}
                    />
                  </Grid>
                  <Grid item>
                    <FilterCheckbox
                      id={eeccFilterKey}
                      label="Especie en categoría de conservación"
                      prevValue={filters[eeccFilterKey]}
                      onChange={setFilter}
                    />
                  </Grid>
                </Grid>
              </>
            }
          </Collapser>
        </Box>
        <Box my={1}>
          <Divider />
        </Box>
        <Box p={1}>
          <ImageList rowHeight={190} cols={imageListColumns} gap={8}>
            {isFetching &&
              [ ...Array(picturesList.length < size ? picturesList.length : size) ].map((_, i) =>
                <ImageListItem key={i}>
                  <Skeleton animation="wave" height="100%" variant="rect" />
                </ImageListItem>)
            }
            {
              picturesList.length && !isFetching ?
                picturesList.map((p, i) => {
                  // TODO: En los registros indirectos no llega speciesName, habría que poner quizá el tipo de registro indirecto
                  const formattedSpeciesName = p.speciesName ? upperCaseFirstLetter(p.speciesName) : 'Especie sin reconocer';
                  return (<ImageListItem key={i} cols={1} onClick={() => openDialog(i)} style={{ cursor: 'pointer' }} >
                    <AsyncImage src={urlJoin(serverUrl, p.path)} alt={formattedSpeciesName} className={ classes.albumThumbnail }/>
                    <ImageListItemBar
                      title={ <span style={ { fontStyle: 'italic' } }>{ formattedSpeciesName }</span> }
                      subtitle={ subtitleMaker({ author: p.author, eeccCategory: p.eeccCategory, recordType: p.recordType }) }
                    />
                  </ImageListItem>);
                }) : !isFetching &&
                <Typography variant="body1" color="textPrimary">
                  No hay fotos.
                </Typography>
            }
          </ImageList>
        </Box>
        {totalPicturesCount > size &&
          <Box pt={3} pb={3} display="flex" justifyContent="center">
            <Pagination
              page={page}
              count={totalPages}
              variant="outlined"
              shape="rounded"
              color="primary"
              onChange={handlePage}
              renderItem={item =>
                <PaginationItem
                  component={Link}
                  to={`?page=${item.page}&size=${size}&${filtersQuery.toString()}`}
                  {...item}
                />
              }
            />
          </Box>
        }
      </ContentContainer>
      <DialogWrap maxWidth='sm' fullWidth onClose={closeDialog}
        open={isOpenDialog}>
        <DialogImagePreviewer
          index={pictureIndex}
          pictures={ picturesList.map(p =>
            ({
              url: urlJoin(serverUrl, p.path),
              title: p.speciesName ? upperCaseFirstLetter(p.speciesName) : 'Especie sin reconocer',
              eeccCategory: p.eeccCategory,
            }),
          )}
          additionalClass='image-species-container'
        />
      </DialogWrap>
    </Grid>
  );
};


export { AlbumContainer };