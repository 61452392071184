import React from 'react';
import { Line, Doughnut } from 'react-chartjs-2';
import { Typography } from '@material-ui/core';
import theme from 'src/themes';


const species = {
  title: 'Especies totales',
  mainCount: 660,
  mainText: 'Especies encontradas',
  mainColor: theme.nviro.palette.flora.primary.main,
  secondaryCount: 1258,
  secondaryText: 'registros',
};

const eecc = {
  title: 'Categoría de conservación',
  mainCount: 134,
  mainText: 'Especies en categoría de conservación',
  mainColor: theme.nviro.palette.error.main,
  secondaryCount: 526,
  secondaryText: 'registros ecc',
};

const unrecognized = {
  title: 'No reconocidas',
  mainCount: 27,
  mainColor: theme.nviro.palette.flora.primary.main,
  mainText: 'Especies no reconocidas',
  secondaryCount: 333,
  secondaryText: 'registros no reconocidos',
};

const pointsTime = {
  title: 'Tiempo por parcela',
  mainCount: 54,
  mainColor: theme.nviro.palette.flora.primary.main,
  mainText: 'minutos por parcela',
  secondaryCount: 20,
  secondaryText: 'parcelas sincronizadas',
};

const saturationCurve = {
  title: 'Curva de Saturación de Especies',
  chart: Line,
  data: {
    labels: [ '22/04', '23/04', '24/04', '25/04', '26/04', '27/04' ],
    datasets: [
      {
        data: [ 30, 90, 120, 130, 140, 145 ],
        label: 'Riqueza',
        backgroundColor: 'rgba(247, 147, 30, 0.7)',
        borderColor: 'rgba(247, 147, 30, 0.7)',
        hoverBackgroundColor: 'rgba(247, 147, 30, 1)',
        fill: false,
      },
    ],
  },
  options: {
    scales: {
      y: [ {
        ticks: {
          callback: value => {
            if (Number.isInteger(value)) {
              return value;
            }
          },
        },
      } ],
    },
  },
  legend: { display: false },
};

const generalPointsProgress = {
  title: 'Progreso en parcelas',
  chart: Doughnut,
  data: {
    labels: [
      'Pendientes',
      'Sincronizadas',
      'Revisadas',
    ],
    datasets: [
      {
        data: [ 90, 27, 3 ],
        backgroundColor: [
          'rgba(244, 67, 54, 0.7)',
          'rgba(81, 147, 235, 0.7)',
          'rgba(0, 200, 83, 0.7)',
        ],
        borderColor: 'white',
        hoverBackgroundColor: [ 'rgba(244, 67, 54, 1)', 'rgba(81, 147, 235, 1)', 'rgba(0, 200, 83, 1)' ],
      },
    ],
  },
  options: { maintainAspectRatio: false },
  legend: { position: 'top' },
};

const pointsTimeUser = {
  title: 'Tiempo por parcela',
  data: [
    {
      rank: 1,
      email: 'alan.brito@nviro.cl',
      mean: 0.13,
    },
    {
      rank: 2,
      email: 'aquiles.baeza@nviro.cl',
      mean: 0.17,
    },
    {
      rank: 3,
      email: 'elsa.patito@nviro.cl',
      mean: 0.20,
    },
    {
      rank: 4,
      email: 'armando.casas@nviro.cl',
      mean: 0.40,
    },
  ],
  /* eslint-disable react/display-name */
  columns:
    [
      {
        title: 'Lugar',
        field: 'rank',
        render: r => <Typography variant="caption">{r.rank}</Typography>,
        cellStyle: { width: 8, maxWidth: 8, padding: 1, textAlign: 'center' },
      },
      { title: 'Correo', field: 'email',
        render: r => <Typography variant="caption">{r.email.replace(/@.*/, '')}</Typography>,
        cellStyle: { width: 8, maxWidth: 8, padding: 1, textAlign: 'center' },
      },
      { title: 'Promedio h/p', field: 'mean',
        render: r => <Typography variant="caption">{r.mean}</Typography>,
        cellStyle: { width: 8, maxWidth: 8, padding: 1, textAlign: 'center' },
      },
    ],
  options: {
    search: false, toolbar: false, padding: 'dense', paging: false,
    headerStyle: { fontSize: '13px', width: 8, maxWidth: 8, padding: 1, textAlign: 'center' },
  },
};


export {
  species,
  eecc,
  pointsTime,
  unrecognized,
  saturationCurve,
  generalPointsProgress,
  pointsTimeUser,
};