import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { MoreVert, NavigateBefore, Menu } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
  mainContainer: {
    height: theme.spacing(6),
    padding: theme.spacing(0),
    backgroundColor: theme.palette.flora.primary.main,
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    color: theme.palette.common.white,
  },
  gridContainer: {
    height: '100%',
  },
  gridItem: {
    alignSelf: 'center',
  },
  title: {
    textAlign: 'center',
  },
}));

const AppHeaderUI = ({ backText, title, showContextMenu = true }) => {
  const classes = useStyles();
  return (
    <Box py={1} className={classes.mainContainer}>
      <Grid className={classes.gridContainer} container alignContent="center" >
        <Grid item xs={4} className={classes.gridItem}>
          <Box display="flex" alignItems="center">
            {backText &&
              <>
                <NavigateBefore />
                <Typography variant="body2">{backText}</Typography>
              </>
            }
            {!backText && <Box ml={2} display="flex" alignItems="center"><Menu /></Box>}
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.gridItem}>
          <Typography className={classes.title} variant="body2">{title}</Typography>
        </Grid>
        <Grid item xs={4} className={classes.gridItem}>
          <Box display="flex" justifyContent="flex-end" mr={2}>
            {showContextMenu && <MoreVert />}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

AppHeaderUI.propTypes = {
  backText: PropTypes.string,
  title: PropTypes.string,
  showContextMenu: PropTypes.bool,
};


export {
  AppHeaderUI,
};
