import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography, CircularProgress, Zoom } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';


const useStyles = makeStyles(() => ({
  checkIcon: {
    fontSize: '2.5rem',
  },
}));

const PlanningFinishDialog = ({ actions, cLang, fetchStatus, userIsFan }) => {
  const classes = useStyles();
  return (
    <Fragment>
      <DialogTitle id="form-dialog-title">Finalizar planificación</DialogTitle>
      <DialogContent>
        <Box>
          {
            fetchStatus === fetchStatusEnum.NOT_STARTED &&
            <Box p={1} height={ userIsFan ? 45 : 200 }>
              { !userIsFan && <>
                <Typography variant="body1" color="textPrimary">
                Esta acción terminará la planificación y notificará a todos los colectores
                con {cLang.points} asignadas que la campaña ya está disponible para empezar a trabajar.
                </Typography>
                <br />
                <Typography variant="body1" color="textPrimary">
                  Recuerda, si quieres que más personas puedan ver y trabajar en esta campaña, podrás luego agregarlas
                  en la sección de <strong>Grupo de trabajo</strong>.
                </Typography>
                <br />
              </> }
              <Typography variant="body1" color="textPrimary">
                ¿Deseas terminar la planificación y comenzar la campaña?
              </Typography>
            </Box>
          }
          {
            fetchStatus === fetchStatusEnum.LOADING &&
            <Zoom in>
              <Box display="flex" justifyContent="center">
                <CircularProgress color="secondary" fontSize="large" />
              </Box>
            </Zoom>
          }
          {
            fetchStatus === fetchStatusEnum.SUCCESS &&
            <Box p={1} height={130}>
              <Box display="flex" justifyContent="center">
                <CheckCircleOutline className={classes.checkIcon} color="secondary" />
              </Box>
              <br />
              <Typography variant="body1" color="textPrimary">
                La planificación se ha completado correctamente{ !userIsFan && <> y los colectores ya fueron notificados</> }, se te
                redigirirá a la campaña en progreso.
              </Typography>
            </Box>
          }
        </Box>
      </DialogContent>
      <DialogActions>
        {![ fetchStatusEnum.SUCCESS, fetchStatusEnum.LOADING ].includes(fetchStatus) &&
        <>
          <Button onClick={actions.closeDialog} color="default">Cancelar</Button>
          <Button onClick={actions.startCampaign} color="primary">Confirmar</Button>
        </>
        }
      </DialogActions>
    </Fragment>
  );
};

PlanningFinishDialog.propTypes = {
  actions: PropTypes.object,
  cLang: PropTypes.object,
  fetchStatus: PropTypes.string,
  userIsFan: PropTypes.bool,
};


export {
  PlanningFinishDialog,
};