import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';


const useStyles = makeStyles(() => ({
  label: {
    whiteSpace: 'nowrap',
    maxHeight: '32px',
    color: 'white',
  },
}));

const RecordCreateButton = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <Button classes={{ label: classes.label }} {...props} >
      {children}
    </Button>
  );
};

RecordCreateButton.propTypes = {
  children: PropTypes.any,
};


export {
  RecordCreateButton,
};