import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => ({
  stepDescription: {
    paddingBottom: theme.spacing(3),
    fontSize: '1.5rem',
    lineHeight: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.125rem',
      lineHeight: '30px',
    },
  },
}));

const StepDescription = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.stepDescription}>
        {children}
      </Typography>
    </>
  );
};

StepDescription.propTypes = {
  children: PropTypes.node,
};


export { StepDescription };