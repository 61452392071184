const getId = () => Math.random().toString(36).slice(2);

const compromises = [
  {
    id: getId(),
    name: 'Compromiso 1',
    responsibleUser: 'John Lara',
    checkers: [
      {
        id: getId(),
        name: 'Verificador 1',
      },
      {
        id: getId(),
        name: 'Verificador 2',
      },
      {
        id: getId(),
        name: 'Verificador 3',
      },
    ],
  },
  {
    id: getId(),
    name: 'Compromiso 2',
    responsibleUser: 'Ernesto Silva',
    checkers: [

    ],
  },
  {
    id: getId(),
    name: 'Compromiso 3',
    responsibleUser: 'Rigoberto Valle',
    checkers: [

    ],
  },
];

const getNewCompromise = mp => ({
  id: getId(),
  name: mp.name,
  responsibleUser: 'John Lara',
});

const frequencies = [
  {
    id: getId(),
    name: 'Semanal',
  },
  {
    id: getId(),
    name: 'Mensual',
  },
  {
    id: getId(),
    name: 'Trimestral',
  },
  {
    id: getId(),
    name: 'Cuatrimestral',
  },
  {
    id: getId(),
    name: 'Semestral',
  },
  {
    id: getId(),
    name: 'Anual',
  },
];

const projectStages = [
  {
    id: getId(),
    name: 'Levantamiento',
  },
  {
    id: getId(),
    name: 'Construcción',
  },
  {
    id: getId(),
    name: 'Operación',
  },
  {
    id: getId(),
    name: 'Cierre',
  },
];


export {
  compromises,
  getNewCompromise,
  projectStages,
  frequencies,
};