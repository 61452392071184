import React from 'react';
import PropTypes from 'prop-types';
import { upperCaseFirstLetter } from 'src/utils/formatters';
import { Button, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';

import { AlertWrapper, DialogButton } from 'src/components';
import { useFetchStatus } from 'src/hooks';


const alertBoxProps = { mt: 2 };

const ForestTreeDeleteDialog = ({ tree, actions }) => {
  const { handleConfirm, fetchStatus, errorMessage, hasError } = useFetchStatus({
    confirmFn: actions.deleteTree,
    closeFn: actions.closeDialog,
  });

  return <>
    <DialogTitle id="form-dialog-title">Eliminar agrupación de individuos forestales</DialogTitle>
    <DialogContent>
      <Typography variant="body1" color="textPrimary">
        Estás por eliminar una agrupación de individuos forestales del registro con la
        especie <strong><i>{upperCaseFirstLetter(tree.scientificName)}</i></strong>, esta acción es irreversible y
        <Typography component='span' display="inline" color="error"> perderás todos los datos</Typography> asociados a la
        agrupación de individuos forestales.
      </Typography>
      <AlertWrapper BoxProps={alertBoxProps} severity="error" isOpen={hasError} hideCloseButton>
        { errorMessage }
      </AlertWrapper>
    </DialogContent>
    <DialogActions>
      <Button onClick={actions.closeDialog}>Cancelar</Button>
      <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>Confirmar</DialogButton>
    </DialogActions>
  </>;
};

ForestTreeDeleteDialog.propTypes = {
  tree: PropTypes.object,
  actions: PropTypes.object,
};


export { ForestTreeDeleteDialog };