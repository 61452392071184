import React, { useRef, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import { useRouteMatch, useLocation, useHistory } from 'react-router';

import { companiesApi, usersApi, campaignsApi, pointsApi, speciesApi, floraApi, faunaApi } from 'src/services';
import { WebContext } from 'src/scenes/web-context';
import { PointsList, PointEditDialog, PointsEditDialog, PointsReviewDialog,
  PointDeleteDialog, PointSyncDialog } from 'src/scenes/Campaign/scenes/Points/components';
import { FloraRecordCreateDialog } from 'src/scenes/Campaign/scenes/Records/components';
import { DialogImagePreviewer } from 'src/components/imagesPreviewer/DialogImagePreviewer';
import { TitleContainer, ContentContainer, DialogWrap, Collapser, FilterText, FilterSelect } from 'src/components';
import { upperCaseFirstLetter } from 'src/utils/formatters';
import { objectIsEmpty as oie, enumsToOptions } from 'src/utils/util';
import { isFlora, isTransect, isStation, isPlot,
  isPointQuadrat, isPointIntercept, isFauna } from 'src/utils/checkers';
import { floraCoverageEnum } from 'src/utils/enums';
import { createRecord } from 'src/scenes/Campaign/campaignHelper';
import { PointsSuggestMethodologiesDialog } from './components/PointsSuggestMethodologiesDialog';


const statusFilterKey = 'status';
const userFilterKey = 'user';
const pointFilterKey = 'point';

const PointsContainer = ({ changeActive, checkCompletion }) => {

  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const tableRef = useRef();
  const context = useContext(WebContext);

  const { campaignHash } = match.params;

  const [ formOptions, setFormOptions ] = useState({
    companyUsersOptions: [],
    campaignUsersOptions: [],
    pointStatusesOptions: [],
    companyEnvironmentOptions: [],
    soilCoveragesOptions: [],
    methodologyTypesOptions: [],
  });

  const [ createRecordFormOptions, setCreateRecordFormOptions ] = useState({
    phenologiesStatusesOptions: [],
    phytosanitaryStatusesOptions: [],
    coverageOptions: [],
  });

  const [ dialog, setDialog ] = useState({ isOpen: false, data: {}, type: '' });
  const [ filters, setFilters ] = useState([]);
  const [ filtersLabels, setFiltersLabels ] = useState();

  const showUsersFilter = (context.checkIfAdminPowers() || context.checkIfCoordinatesCampaign()) && !context.isFan();

  const { campaignSubtypeLanguage: csLang, checkIfAdminPowers, isFan, selectedCampaign, selectedCompany: { role: userRole },
    hasPermissionFor } = context;
  const { id: campaignId, hasOwnWorkFinished, statusId, forceReview, mainMethodId: campaignSubtypeId } = selectedCampaign;

  const getQueryParams = () => {
    const query = new URLSearchParams(location.search);

    const page = parseInt(query.get('page') || 1, 10);
    const size = parseInt(query.get('size') || 1, 10);

    const point = query.get(pointFilterKey);
    const user = query.getAll(userFilterKey).length ? query.getAll(userFilterKey) : [];
    const status = query.getAll(statusFilterKey).length ? query.getAll(statusFilterKey) : [];

    return { page, size, user, point, status };
  };

  const { user, point, status } = getQueryParams();

  const addFiltersToQuery = (query, filters) => {
    Object.keys(filters).forEach(key => {
      if (Array.isArray(filters[key])) {
        query.delete(key);
        filters[key].forEach(val => query.append(key, val));
      } else if (typeof filters[key] === 'boolean') {
        filters[key] ? query.set(key, filters[key]) : query.delete(key);
      } else if (filters[key] !== null) {
        query.set(key, filters[key]);
      }
    });
  };

  const setFilter = async ({ id, value, label = '' }) => {
    const queryForPath = new URLSearchParams(location.search);

    const filter = { [id]: value };

    const filtersObj = { ...filter };
    const tablePage = 0;
    addFiltersToQuery(queryForPath, filtersObj);
    setFilters(prev => ({ ...prev, ...filter }));
    label && setFiltersLabels(prev => ({ ...prev, [id]: label }));

    const queryForPathString = queryForPath.toString();
    await history.push({ pathname: location.pathname, search: `?${queryForPathString}` });
    tableRef.current && tableRef.current.onQueryChange({ page: tablePage });
  };

  const transformToOptions = ({ searchResult }) =>
    searchResult.map(r => ({ ...r, label: upperCaseFirstLetter(r.name || r.scientificName), value: r.id }));

  const getOptions = key => async ({ queryText, ...filters }) => {
    const searchApi = {
      point: pointsApi.search,
      species: isStation(campaignSubtypeId) ? speciesApi.searchInFauna : speciesApi.searchInFlora,

    };
    const searchResult = await searchApi[key]({ queryText, campaignId, ...filters });
    return transformToOptions({ searchResult });
  };

  const getPoints = async ({ page, size }) => {
    const query = new URLSearchParams(location.search);
    query.set('campaignHash', campaignHash);
    query.set('page', page);
    query.set('size', size);
    return await pointsApi.getPoints(query.toString());
  };

  useEffect(() => {
    const fetchData = async () => {
      const filtersThatNeedLabels = { point };
      Object.keys(filtersThatNeedLabels).forEach(key => filtersThatNeedLabels[key] === null ? delete filtersThatNeedLabels[key] : {});
      const filtersLabels = oie(filtersThatNeedLabels) ? {} : await usersApi.getLabels({ campaignSubtypeId, ...filtersThatNeedLabels });
      setFilters({ user, point, status });
      setFiltersLabels(filtersLabels);
    };
    fetchData();
    // eslint-disable-next-line
  }, [ campaignHash ]);

  useEffect(() => {
    const fetchData = async () => {
      const [ companyUsers, campaignUsers, pointStatusesOptions, companyEnvironment,
        soilCoveragesOptions, methodologyTypesOptions,
      ] = await Promise.all([
        context.hasPermissionFor('user:get', { checkOnCampaignRole: true }) ? usersApi.getUsersByCompany() : [],
        showUsersFilter ? campaignsApi.getUsers(campaignHash) : [],
        !isTransect(campaignSubtypeId) ? pointsApi.getPointStatuses(campaignSubtypeId) : [],
        showUsersFilter && isStation(campaignSubtypeId) ? companiesApi.getEnvironments() : [],
        isPlot(campaignSubtypeId) ? floraApi.getSoilCoverages() : [],
        isFauna(campaignSubtypeId) ? faunaApi.getMethodologiesTypes() : [],
      ]);

      const companyUsersOptions = companyUsers.map(user => ({ value: user.id, label: user.email }));
      const campaignUsersOptions = campaignUsers.map(user => ({ value: user.id, label: user.email }));
      const companyEnvironmentOptions =
        companyEnvironment.map(environment => ({ value: environment.id, label: environment.name, deleted: false }));
      setFormOptions({
        companyUsersOptions, campaignUsersOptions, pointStatusesOptions, companyEnvironmentOptions,
        soilCoveragesOptions, methodologyTypesOptions,
      });
    };
    fetchData();

    // eslint-disable-next-line
  }, [ campaignHash ]);

  useEffect(() => {
    if (isFlora(campaignSubtypeId)) {
      const fetchOptions = async () => {
        const [ phenologiesStatusesOptions, phytosanitaryStatusesOptions, stratums ] = await Promise.all([
          floraApi.getPhenologiesStatuses(),
          floraApi.getPhytosanitaryStatuses(),
          isPointQuadrat(campaignSubtypeId) ? campaignsApi.getStratums(campaignId) : [],
        ]);
        const stratumsOptions = stratums.map(({ name, id, ...rest }) => ({ label: name, value: id, data: rest }));
        setCreateRecordFormOptions(po => ({
          ...po,
          phenologiesStatusesOptions,
          phytosanitaryStatusesOptions,
          coverageOptions: isPlot(campaignSubtypeId) ? enumsToOptions(floraCoverageEnum) : [],
          stratumsOptions,
        }));
      };
      fetchOptions();
    }
    // eslint-disable-next-line
  }, [ campaignHash ]);

  const toggleDialog = ({ data = {}, type = '' } = {}) => setDialog({ isOpen: !dialog.isOpen, data, type });

  const closeDialog = () => setDialog({ isOpen: false, type: '' });

  const openCreateRecordDialog = point => {
    const { name, id } = point;
    setDialog({
      isOpen: true,
      type: 'create-flora-record',
      data: { pointSelected: { label: name, value: id, data: point } },
    });
  };

  const openEditPointDialog = point => setDialog({ isOpen: true, type: 'edit', data: { points: [ point ] } });

  const openSyncPointDialog = point => setDialog({ isOpen: true, type: 'sync', data: { points: [ point ] } });

  const getDialogType = () => {
    switch (dialog.type) {
      case 'edit': {
        return <PointEditDialog
          previousPointData={dialog.data.points[0]}
          formOptions={formOptions}
          actions={{ editPoints, toggleDialog }}
          csLang={csLang}
          canAddUserToCompany={checkIfAdminPowers()}
          userIsFan={isFan()}
          campaignSubtypeId={campaignSubtypeId}
          canCreateEnvironment={hasPermissionFor('environment:create')}
        />;
      }
      case 'multi-reassign':
        return <PointsEditDialog
          selectedPoints={dialog.data.points}
          formOptions={formOptions}
          actions={{ editPoints, toggleDialog }}
          csLang={csLang}
          canAddUserToCompany={checkIfAdminPowers()}
          userIsFan={isFan()}
          campaignSubtypeId={campaignSubtypeId}
        />;
      case 'multi-suggest-methodologies':
        return <PointsSuggestMethodologiesDialog
          selectedPoints={dialog.data.points}
          options={formOptions.methodologyTypesOptions}
          actions={{ editPoints, closeDialog }}
        />;
      case 'photo':
        return <DialogImagePreviewer pictures={dialog.data.pictures} title={dialog.data.title} additionalClass='image-species-container' />;
      case 'delete': {
        const [ point ] = dialog.data.points;
        return <PointDeleteDialog point={point} csLang={csLang} actions={{ editPoints, toggleDialog }} />;
      }
      case 'review':
        return <PointsReviewDialog selectedPoints={dialog.data.points} csLang={csLang} catalogName={selectedCampaign?.catalogName}
          actions={{ editPoints, toggleDialog }} />;
      case 'create-flora-record': {
        const { length, intervalDistance } = dialog.data.pointSelected.data;
        const transectPoints = (length && intervalDistance) ? length / intervalDistance * 100 + 1 : 0;
        return <FloraRecordCreateDialog
          actions={{ createRecord: createRecord({ campaignId, tableRef }), openEditPointDialog, closeDialog }}
          preselectedData={dialog.data}
          options={{
            ...createRecordFormOptions,
            recordsTypesOptions: [
              ...(isPlot(campaignSubtypeId) ? [
                { label: 'Registro flora', value: 'flora-record' },
                { label: 'Registro forestal', value: 'forest-record' },
              ] : []),
              ...(isPointQuadrat(campaignSubtypeId) ? [
                { label: 'Registro de transecta', value: 'flora-point-quadrat-record' },
              ] : []),
              ...(isPointIntercept(campaignSubtypeId) ? [
                { label: 'Registro de transecta', value: 'flora-point-intercept-record' },
              ] : []),
            ],
            ...(isTransect(campaignSubtypeId) ? {
              transectPointOptions: [ ...Array(transectPoints) ].map((_, idx) => ({ label: (idx + 1).toString(), value: idx + 1 })),
            } : []),
          }}
          getOptions={{ getSpeciesOptions: getOptions('species') }}
          ctLang={context.campaignSubtypeLanguage}
          campaignSubtypeId={campaignSubtypeId}
        />;
      }
      case 'sync' : {
        const [ point ] = dialog.data.points;
        return <PointSyncDialog point={point} csLang={csLang} actions={{ editPoints, closeDialog }} />;
      }
      default:
        break;
    }
  };

  const goTo = {
    records: pointId => changeActive('records', `?point=${pointId}`),
    reviewMode: pointId => changeActive('records', `?review=true&point=${pointId}`),
    methodologies: pointId => changeActive('methodologies', `?point=${pointId}`),
  };

  const editPoints = async toEdit => {
    const { data: { points: selectedPoints } } = dialog;
    const idsToUpdate = selectedPoints.map(p => p.id);
    await pointsApi.updatePoints({ campaignId, idsToUpdate, ...toEdit });
    if (toEdit.reviewed) {
      await checkCompletion();
    }
    tableRef.current && tableRef.current.onQueryChange();
  };

  const hasCampaignPermissionFor = action => context.hasPermissionFor(
    action, { checkOnCampaignRole: true, campaignRole: context.checkIfCoordinatesCampaign() ? 'coordinator' : 'collector' },
  );

  return (
    <>
      <Grid container>
        <TitleContainer maxWidth='xl' breadcrumbs={[ 'company', 'campaigns', 'points' ]}>{csLang.Points}</TitleContainer>
        <ContentContainer maxWidth='xl'>
          <Box my={1}>
            <Collapser title={'Filtros'}>
              {filtersLabels &&
                <>
                  <Grid container spacing={1}>
                    <Grid item>
                      <FilterText
                        id={pointFilterKey}
                        label={csLang.Point}
                        prevValue={{ label: filtersLabels[pointFilterKey], value: point }}
                        getOptions={getOptions(pointFilterKey)}
                        onChange={setFilter}
                      />
                    </Grid>
                    {showUsersFilter &&
                      <Grid item>
                        <FilterSelect
                          id={userFilterKey}
                          toUpperFirst={false}
                          label="Responsable"
                          prevValue={filters[userFilterKey]}
                          options={formOptions.campaignUsersOptions}
                          onChange={setFilter}
                        />
                      </Grid>
                    }
                    <Grid item>
                      <FilterSelect
                        id={statusFilterKey}
                        label="Estado"
                        prevValue={filters[statusFilterKey]}
                        options={formOptions.pointStatusesOptions}
                        onChange={setFilter}
                      />
                    </Grid>
                  </Grid>
                </>
              }
            </Collapser>
          </Box>
          <PointsList
            tableRef={tableRef}
            userRole={userRole}
            userId={ context.currentUser.id }
            campaignInProgress={statusId === 'in-progress'}
            campaignForceReview={forceReview}
            coordinatesCampaign={context.checkIfCoordinatesCampaign()}
            hasAdminPowers={context.checkIfAdminPowers()}
            hasOwnWorkFinished={hasOwnWorkFinished}
            formOptions={formOptions}
            hasPermissionFor={hasCampaignPermissionFor}
            actions={{ getPoints, toggleDialog, goTo, openCreateRecordDialog, openEditPointDialog, openSyncPointDialog }}
            csLang={csLang}
            campaignSubtypeId={campaignSubtypeId}
            setCampaignCounter={context.setCampaignCounter}
          />
        </ContentContainer>
      </Grid>
      <DialogWrap maxWidth='sm' fullWidth onClose={closeDialog} aria-labelledby='form-dialog-title'
        open={ dialog.isOpen }>
        { getDialogType() }
      </DialogWrap>
    </>
  );
};

PointsContainer.propTypes = {
  changeActive: PropTypes.func.isRequired,
  checkCompletion: PropTypes.func,
};


export { PointsContainer };
