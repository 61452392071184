import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, InputLabel,
  FormControlLabel, FormGroup, Checkbox, Zoom, Tooltip } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';

import { SelectChip } from 'src/components';


const useStyles = makeStyles(() => ({
  block: {
    display: 'block',
  },
  tooltip: {
    cursor: 'pointer',
    marginBottom: '-6px',
  },
  paddingCheck: {
    padding: '0 9px 0 9px',
  },
}));

const UserEditDialog = props => {
  const classes = useStyles();
  const { actions, user, rolesOptions } = props;
  const [ selectedRole, setSelectedRole ] = useState(rolesOptions.find(r => r.value === user.role));
  const [ isFreelance, setIsFreelance ] = useState(user.isFreelance);
  const [ error, setError ] = useState('');
  const toggleCheck = () => {
    setIsFreelance(!isFreelance);
  };

  const handleSubmit = async () => {
    try {
      await actions.editUser({ role: selectedRole.value, userId: user.id, isFreelance });
      return;
    } catch (e) {
      console.error(e);
      setError(e.serverMessage ? e.serverMessage : 'Hubo un error en el servidor, por favor intenta más tarde');
    }
  };

  return (
    <Fragment>
      <DialogTitle id="form-dialog-title">Editar rol de usuario</DialogTitle>
      <DialogContent>
        <Box my={2}>
          <InputLabel shrink required>Rol</InputLabel>
          <SelectChip
            value={selectedRole.value}
            returnFullOption
            options={rolesOptions}
            onChange={selectedRole => {
              selectedRole.value === 'admin' ? setIsFreelance(false) : setIsFreelance(user.isFreelance);
              setSelectedRole(selectedRole);
            }}
          />
        </Box>
        { selectedRole.value !== 'admin' &&
          <Box my={2}>
            <FormGroup className={classes.block}>
              <FormControlLabel
                control={
                  <Checkbox checked={isFreelance} onChange={toggleCheck} className={classes.paddingCheck}/>
                }
                label={'¿Es freelance?'}
              />
              <Tooltip title={'Un usuario freelance no podrá interactuar con campañas finalizadas'}>
                <InfoIcon color="disabled" fontSize="small" className={classes.tooltip} />
              </Tooltip>
            </ FormGroup>
          </Box>
        }
        { error &&
          <Box my={2}>
            <Zoom in>
              <Alert severity="error">{ error }</Alert>
            </Zoom>
          </Box>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <Button onClick={handleSubmit} color="primary">Editar</Button>
      </DialogActions>
    </Fragment>
  );
};

UserEditDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  rolesOptions: PropTypes.array.isRequired,
};


export { UserEditDialog };
