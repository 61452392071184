import React, { memo, useMemo } from 'react';
import { Chart } from 'react-chartjs-2';
import PropTypes from 'prop-types';

import { GraphWrapper } from 'src/components';


const dataToGraphData = data => ({
  labels: data.map(d => d.company),
  datasets: [
    {
      type: 'bar',
      label: 'Usuarios activos',
      data: data.map(d => d.activeUsers),
      yAxisID: 'yAxis',
      order: 2,
    },
    {
      type: 'line',
      label: 'Campañas',
      data: data.map(d => d.campaigns),
      yAxisID: 'yAxis',
      order: 1,
    },
  ],
});

const legendOptions = { position: 'top' };

const graphOptions = {
  scales: {
    yAxis: {
      type: 'linear',
      position: 'left',
      title: { display: true, text: '# de camapañas y de usuarios activos' },
    },
  },
};

const BillsMonthStatisticsNoMemo = ({ billsList }) => {

  const dataPreGraph = useMemo(() => {
    const dataForCompanies = billsList.reduce((p, c) => {
      if (!p[c.companyName]) {
        p[c.companyName] = { campaigns: {}, activeUsers: {} };
      }
      p[c.companyName].campaigns[c.campaignId] = c.campaignId;
      p[c.companyName].activeUsers[`${c.companyName}-${c.userEmail}`] = `${c.companyName}-${c.userEmail}`;
      return p;
    }, {});

    return Object.keys(dataForCompanies).map(k => ({
      company: k,
      campaigns: Object.keys(dataForCompanies[k].campaigns).length,
      activeUsers: Object.keys(dataForCompanies[k].activeUsers).length,
    })).sort((a, b) => b.activeUsers - a.activeUsers);
  }, [ billsList ]);

  return (
    <GraphWrapper
      title={`Campañas y usuarios activos por Empresa`}
      info={`Campañas y usuarios activos por Empresa`}
      dataPreGraph={ dataPreGraph }
      dataToGraphData={ dataToGraphData }
      chartjsComponent={ Chart }
      legend={ legendOptions }
      type='bar'
      options={ graphOptions }
    />
  );
};

const BillsMonthStatistics = memo(BillsMonthStatisticsNoMemo,
  (prev, next) =>
    prev.billsList.length === next.billsList.length &&
    prev.billsList.every((b, i) =>
      b.companyName === next.billsList[i].companyName
      && b.campaignId === next.billsList[i].campaignId
      && b.userEmail === next.billsList[i].userEmail),
);

BillsMonthStatisticsNoMemo.propTypes = {
  billsList: PropTypes.array.isRequired,
};


export {
  BillsMonthStatistics,
};