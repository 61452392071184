import React from 'react';
import { Box, Typography, makeStyles, Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { NavigateNext } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
  benefitContainer: {
    width: '456px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '650px',
    [theme.breakpoints.down('sm')]: {
      height: '320px',
      width: '600px',
    },
  },
  benefitText: {
    color: theme.palette.common.black,
    fontSize: '1.5rem',
  },
  benefitTitle: {
    paddingBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  benefitContent: {
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(1),
    },
  },
  benefitLink: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '1.25rem',
    textTransform: 'uppercase',
  },
}));


const Benefit = ({ title, description, link }) => {
  const classes = useStyles();
  return (
    <Box ml={{ xs: 0, md: 10 }} className={classes.benefitContainer}>
      <Typography className={clsx(classes.benefitText, classes.benefitTitle)}>
        {title}
      </Typography>
      <Typography className={clsx(classes.benefitText, classes.benefitContent)}>
        {description}
      </Typography>
      <Box display="flex" alignItems="center">
        <Typography className={clsx(classes.benefitText, classes.benefitLink)}>
          <Link component={RouterLink} to={link}>Quiero saber más</Link>
        </Typography>
        <NavigateNext color="primary" />
      </Box>
    </Box>
  );
};

Benefit.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
};


export { Benefit };
