import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import urlJoin from 'url-join';
import PropTypes from 'prop-types';
import { Edit, PhotoAlbum, Delete } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';

import { TableWrapper, LabelChip } from 'src/components';
import config from 'src/config/local';
import { formatDate, formatDateExtension } from 'src/utils/util';


const { serverUrl } = config;

const getLabelFixColor = theme => ({ style: { color: theme.palette.common.black, borderColor: theme.palette.common.black } });

const ForestTreesList = ({ record, actions, hideEdition, disabledEdition }) => {
  const trees = record.trees;
  const theme = useTheme();

  const detailTableColumns = [
    { title: 'N° agrupación forestal', field: 'index', filtering: false },
    { title: 'Cantidad', field: 'number', filtering: false },
    { title: 'Altura', field: 'height', filtering: false },
    { title: 'Diámetro de copa 1', field: 'firstDiameter', filtering: false },
    { title: 'Diámetro de copa 2', field: 'secondDiameter', filtering: false },
    { title: 'Vástagos', field: 'numberOfOffshoots', filtering: false },
    { title: 'DAP', field: 'dap', filtering: false },
    {
      title: 'Estado fenológico',
      render: useCallback(r => r.phenoMaped.length === 0 ? '-' : r.phenoMaped.map(psId =>
        <Box key={psId} mx={1}>
          <LabelChip
            variant="outlined"
            label={psId}
            color="default"
            {...(!r.byOfficialSource ? getLabelFixColor(theme) : {})}
          />
        </Box>,
      ), [ theme ]),
    },
    {
      title: 'Estado fitosanitario ',
      render: useCallback(r => r.phytoMaped.length === 0 ? '-' : r.phytoMaped.map(psId =>
        <Box key={psId} mx={1}>
          <LabelChip
            variant="outlined"
            label={psId}
            color="default"
            {...(!r.byOfficialSource ? getLabelFixColor(theme) : {})}
          />
        </Box>,
      ), [ theme ]),
    },
    {
      title: 'Fecha de detección',
      render: useCallback(r =>
        <div>{r.detectionDate ? `${formatDate(r.detectionDate)} ${formatDateExtension(r.detectionDate)}` : '-'}</div>, []),
    },
    { title: 'Observaciones', field: 'observation', filtering: false,
      render: useCallback(r => <span>{r.observation ? r.observation : 'Sin observaciones'}</span>, []),
    },
  ];

  const detailTableActions = [
    {
      hidden: hideEdition,
      disabled: disabledEdition,
      icon: () => <Edit color={disabledEdition ? 'disabled' : 'action' } />,
      ...(disabledEdition ? {} : { tooltip: 'Editar' }),
      onClick: (event, rowData) => actions.toggleDialog({ data: rowData, type: 'edit-forest-detail' }),
    },
    rowData => ({
      disabled: rowData.pictures.length === 0,
      icon: () => <PhotoAlbum color={rowData.pictures.length ? 'action' : 'disabled' } />,
      ...(rowData.pictures.length > 0 && { tooltip: 'Ver Fotos' }),
      onClick: (event, rowData) => actions.toggleDialog({
        data: {
          pictures: rowData.pictures.map(p => urlJoin(serverUrl, p)),
          title: rowData.scientificName,
        },
        type: 'photo',
      }),
    }),
    {
      hidden: hideEdition,
      disabled: disabledEdition,
      icon: () => <Delete color={disabledEdition ? 'disabled' : 'action' } />,
      tooltip: disabledEdition ? `No puedes borrar este individuo` : 'Borrar',
      onClick: (event, rowData) => actions.toggleDialog({
        data: { scientificName: record.scientificName, ...rowData },
        type: 'delete-tree',
      }),
    },
  ];

  return (
    <Box mx={5} mb={1} mt={1}>
      <TableWrapper
        columns={detailTableColumns}
        actions={detailTableActions}
        data={trees.map((t, i) => ({ ...t, index: i + 1 }))}
        lastColumnSticked={false}
        options={{ emptyRowsWhenPaging: false, toolbar: false, paging: false }}
      />
    </Box>);
};

ForestTreesList.propTypes = {
  record: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  disabledEdition: PropTypes.bool,
  hideEdition: PropTypes.bool,
};


export { ForestTreesList };