import React, { useState, useRef } from 'react';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { PopoverWrapper } from 'src/components';


const useStyles = makeStyles(theme => ({
  container: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    [theme.breakpoints.down(1500)]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(1) + theme.spacing(1) / 2,
      marginRight: theme.spacing(1) + theme.spacing(1) / 2,
    },
  },

  '@keyframes slide': {
    from: {
      width: 0,
    },
    to: {
      width: '29px',
    },
  },
  title: {
    cursor: 'pointer',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '18px',
    color: theme.palette.common.gray600,
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },

    '&:hover': {
      color: theme.palette.secondary.main,
      marginTop: '6px',
      '&:after': {
        content: '""',
        display: 'block',
        width: '29px',
        borderBottomWidth: '3px',
        borderBottomColor: theme.palette.secondary.main,
        borderBottomStyle: 'solid',
        paddingTop: '3px',
        animationName: '$slide',
        animation: 'linear',
        animationDuration: '100ms',
      },
    },
  },
}));

const MenuTitle = ({ children, Submenu, ...props }) => {
  const classes = useStyles();

  const [ isOpenPopover, setIsOpenPopover ] = useState(false);
  const popoverAnchor = useRef(null);

  const openPopover = () => setIsOpenPopover(true);
  const closePopover = () => setIsOpenPopover(false);

  const mouseActions = { onMouseEnter: openPopover, onMouseLeave: closePopover };
  const hasSubmenu = Boolean(Submenu);

  return (
    <Box className={classes.container}>
      <Typography
        className={classes.title}
        variant="body1"
        color="textSecondary"
        {...(hasSubmenu ? { ...mouseActions, ref: popoverAnchor } : {})} {...props}
      >
        <Box display="flex" alignItems="center" component="span">
          {children}
          {hasSubmenu && <KeyboardArrowDown />}
        </Box>
      </Typography>
      {hasSubmenu &&
        <PopoverWrapper open={isOpenPopover} anchorEl={popoverAnchor.current} onClose={closePopover} PaperProps={mouseActions}>
          <Submenu></Submenu>
        </PopoverWrapper>
      }
    </Box>
  );
};

MenuTitle.propTypes = {
  children: PropTypes.any,
  Submenu: PropTypes.func,
};


export { MenuTitle };