import React from 'react';
import { Typography, Box, Paper, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Delete, Edit } from '@material-ui/icons';

import { AccessControl } from 'src/components';
import { humanReadableFormatDateWithHour } from 'src/utils/util';

const paperStyle = theme => ({
  cursor: 'pointer',
  paddingRight: theme.spacing(1),
  paddingLeft: theme.spacing(3),
  borderRadius: '10px',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  background: 'white',
  transform: 'none',
  boxSizing: 'border-box',
  borderLeftStyle: 'solid',
  borderLeftColor: theme.palette.primary.dark,
  borderLeftWidth: theme.spacing(2),
  transition: theme.transitions.create(
    [ 'transform', 'border-left-width', 'border-left-color' ],
    { duration: theme.transitions.duration.shortest },
  ),
  '&:hover': {
    // transform: `translateY(-3px) scale(1.01,1.01)`,
    borderLeftWidth: theme.spacing(8),
    borderLeftColor: theme.palette.text.primary,
    '& $actions': {
      opacity: 1,
      visibility: 'visible',
    },
  },
});

const useStyles = makeStyles(theme => ({
  paper: paperStyle(theme),
  paperUndeletable: {
    ...paperStyle(theme),
    borderLeftColor: theme.palette.secondary.dark,
  },
  logo: {
    height: theme.spacing(3) + 4,
  },
  number: {
    paddingLeft: theme.spacing(1),
  },
  actions: {
    transform: `translateX(-${theme.spacing(9)}px)`,
    opacity: 0,
    visibility: 'hidden',
    transition: theme.transitions.create(
      [ 'opacity' ],
      { duration: theme.transitions.duration.shortest },
    ),
    minWidth: 30,
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  editButton: {
    color: theme.palette.common.white,
  },
  hiddenLongText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    paddingRight: '20px',
  },
  authorText: {
    fontWeight: 500,
    fontSize: '0.8rem',
  },
}));

const ProjectCard = ({ project, actions, undeletable, userIsAdmin }) => {
  const classes = useStyles();
  const handleDelete = e => {
    e.stopPropagation();
    actions.toggleDialog({ data: project.id, type: 'delete' });
  };
  const handleEdit = e => {
    e.stopPropagation();
    actions.toggleDialog({ data: project, type: 'edit' });
  };
  const handleGoTo = () => actions.goToCampaigns(project.id);

  return (
    <Box pb={2}>
      <Paper className={ undeletable ? classes.paperUndeletable : classes.paper } onClick={handleGoTo}>
        <Box display="flex" alignItems="center">
          <Box display="flex" flexDirection="column" alignItems="center" className={classes.actions}>
            <AccessControl action="project:update" extraCondition={ project.belongsToUser }>
              <IconButton size="small" aria-label="edit" className={classes.editButton} onClick={handleEdit}>
                <Edit />
              </IconButton>
            </AccessControl>
            <AccessControl action="project:delete" extraCondition={ project.belongsToUser && !undeletable }>
              <IconButton size="small" aria-label="delete" className={classes.deleteButton} onClick={handleDelete}>
                <Delete />
              </IconButton>
            </AccessControl>
          </Box>
          <Box className={classes.hiddenLongText} display="flex" flexDirection="column">
            <Typography noWrap variant="h5" color="textPrimary" title={ project.name }>{project.name}</Typography>
            { userIsAdmin &&
              <Typography variant="caption" color="textSecondary" className={classes.authorText}>
                Asignado a {project.creatorName} – {project.creatorEmail}
              </Typography>
            }
            <Typography variant="caption" color="textSecondary">{humanReadableFormatDateWithHour(project.updatedAt)}</Typography>
          </Box>
          <Box mr={1} display="flex" alignItems="center">
            <img className={classes.logo} src="/img/logos/flora-icon.png" alt="flora-icon"></img>
            <Typography variant="body1" className={classes.number} color="textSecondary">{project.campaignsCount.flora || 0}</Typography>
          </Box>
          <Box mr={1} display="flex" alignItems="center">
            <img className={classes.logo} src="/img/logos/fauna-icon.png" alt="fauna-icon"></img>
            <Typography variant="body1" className={classes.number} color="textSecondary">{project.campaignsCount.fauna || 0}</Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

ProjectCard.propTypes = {
  project: PropTypes.object,
  actions: PropTypes.object,
  undeletable: PropTypes.bool,
  userIsAdmin: PropTypes.bool,
};

export { ProjectCard };
