import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import urlJoin from 'url-join';
import { Box } from '@material-ui/core';

import { MonitoringContainer, PointsContainer, DownloadablesContainer,
  MethodologiesContainer, StatisticsContainer, UsersContainer, RecordsContainer,
} from 'src/scenes';
import { CampaignDrawer, DialogWrap, SimpleDialog } from 'src/components';
import { WebContext } from 'src/scenes/web-context';
import { companiesApi, campaignsApi, usersApi, floraApi } from 'src/services';
import { CampaignRoutes } from 'src/scenes/Campaign/CampaignRoutes';
import { isCensus } from 'src/utils/checkers';


const drawerWidth = 240;

const useStyles = () => ({
  mainContainer: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
  maxMainContainer: {
    width: '100%',
  },
});

const routes = {
  common: [
    {
      path: 'monitoring',
      Component: MonitoringContainer,
    },
    {
      path: 'statistics',
      Component: StatisticsContainer,
    },
    {
      path: 'work-team',
      Component: UsersContainer,
      action: 'work-team-page:visit',
    },
    {
      path: 'downloads',
      Component: DownloadablesContainer,
    },
    {
      path: 'records',
      Component: RecordsContainer,
    },
  ],
  plots: [
    {
      path: 'plots',
      Component: PointsContainer,
    },
  ],
  stations: [
    {
      path: 'sampling-stations',
      Component: PointsContainer,
    },
    {
      path: 'sampling-stations/:samplingStationId',
      Component: MethodologiesContainer,
    },
    {
      path: 'methodologies',
      Component: MethodologiesContainer,
    },
  ],
  'point-quadrat': [
    {
      path: 'transects',
      Component: PointsContainer,
    },
  ],
  'point-intercept': [
    {
      path: 'transects',
      Component: PointsContainer,
    },
  ],
};

const routesStateHelper = (prevState, mainMethodId) => prevState.campaign?.mainMethodId !== mainMethodId ?
  { availableRoutes: [ ...routes.common, ...(mainMethodId !== 'census' ? routes[mainMethodId] : []) ] } : {} ;

class CampaignContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      active: 'monitoring',
      availableRoutes: [],
      campaign: undefined,
      campaignItemsList: [],
      isDrawerOpen: true,
      completionStatusByUser: {},
      completionStatusByCampaign: {},
      censusFields: [],
      roleInCampaign: null,
      waitingForCompanyChange: false,
      loading: true,
    };
  }

  async componentDidMount() {
    const { campaignHash } = this.props.match.params;
    const campaign = await campaignsApi.getCampaignByHash(campaignHash);
    const waitingForCompanyChange = campaign.companyId !== this.context.selectedCompany.id;

    const [ roleInCampaign = null, censusFields = [] ] = await Promise.all([
      campaign.statusId !== 'pending' ? campaignsApi.getRoleInCampaign(campaignHash) : null, // es innecesario en la planificación
      isCensus(campaign.mainMethodId) ? floraApi.getCensusFields() : [],
    ]);

    if (censusFields?.length) {
      this.context.setCensusFields(censusFields);
    }

    this.setState(ps => ({
      ...ps, campaign, roleInCampaign, waitingForCompanyChange, ...routesStateHelper(ps, campaign.mainMethodId),
    }));

    if (!waitingForCompanyChange) {
      this.campaignInit(campaign);
    }
  }

  changeToCampaignCompany = async () => {
    const campaign = this.state.campaign;
    const companyData = await companiesApi.changeCompany(campaign.companyId);
    const rolePermissions = await usersApi.getPermissionsByRole();
    this.context.changeCompany({ ...companyData });
    this.context.changeRolePermissions(rolePermissions);
  };

  campaignInit = async campaign => {
    const campaignHash = campaign.hash;
    const campaignItemsList = await usersApi.getCampaignMenuByApp({ campaignSubtypeId: campaign.mainMethodId, campaignHash });

    this.context.setTheme(campaign.componentId);
    this.context.setSelectedProject({ id: campaign.projectId, name: campaign.projectName, userId: campaign.projectOwnerId });
    this.context.setSelectedCampaign(campaign);

    const campaignName = this.context.selectedCampaign.name;
    const projectName = this.context.selectedCampaign.projectName;
    document.title = `${campaignName} - ${projectName} - Nviro Capture`;

    await this.checkCompletion(campaignHash);

    this.setState({ campaignItemsList });
    const active = this.props.location.pathname.replace(`${this.props.match.url}/`, '');
    const search = this.props.location.search;
    this.changeActive(active, search);

    this.unregisterHistoryListener = this.props.history.listen(location => {
      // están yendo atrás:
      if (this.props.history.action === 'POP') {
        const backActive = location.pathname.replace(`${this.props.match.url}/`, '');
        this.setState({ active: backActive });
      }
    });
    this.setState({ loading: false });
  };

  rejectCompanyChange = () => this.props.history.push('/web');

  acceptCompanyChange = async () => {
    await this.changeToCampaignCompany();
    this.setState({ waitingForCompanyChange: false });
    await this.campaignInit(this.state.campaign);
  };

  async componentWillUnmount() {
    this.context.setTheme('nviro');
    if (this.unregisterHistoryListener) {
      this.unregisterHistoryListener();
    }
  }

  changeActive = (newActive, search) => {
    if (!this.state.campaign || !this.state.availableRoutes.map(r => r.path).includes(newActive)) {
      // habrá redirección, así que mejor no hacer nada mientras eso pasa:
      return;
    }
    const samePlace = this.state.active === newActive;
    this.setState({ active: newActive });
    if (search) {
      this.props.history.push({ pathname: urlJoin(this.props.match.url, newActive), search });
    } else {
      const query = new URLSearchParams(this.props.location.search);
      const queryString = query.toString();
      const keepSearch = samePlace && Boolean(queryString);
      // esto no funciona si uso la forma con objeto con pathName y search, no sé por qué
      this.props.history.push(urlJoin(this.props.match.url, newActive, keepSearch ? `?${queryString}` : ''));
    }
  };

  finishCampaign = async () => {
    const { hash: campaignHash } = this.state.campaign;
    await campaignsApi.finishCampaign(campaignHash);
    this.setState(prevState => ({ campaign: { ...prevState.campaign, statusId: 'finished', hasOwnWorkFinished: true } }));
    this.context.setSelectedCampaign({ ...this.context.selectedCampaign, statusId: 'finished', hasOwnWorkFinished: true });
    if (this.context.selectedCompany.isFreelance) {
      this.props.history.push(urlJoin('/web/projects', this.context.selectedProject.id, 'campaigns'));
    } else {
      this.changeActive('downloads');
    }
  };

  finishWork = async () => {
    const { hash: campaignHash } = this.state.campaign;
    await campaignsApi.finishWork(campaignHash);
    this.setState(prevState => ({ campaign: { ...prevState.campaign, hasOwnWorkFinished: true } }));
    this.context.setSelectedCampaign({ ...this.context.selectedCampaign, hasOwnWorkFinished: true });
  };

  completionStatusByCampaign = async campaignHash => {
    const completionStatus = await campaignsApi.completionStatus(campaignHash);
    this.setState(prevState => ({ ...prevState, completionStatusByCampaign: completionStatus }));
  };

  completionStatusByUser = async cHash => {
    const campaignHash = this?.state?.campaign?.hash || cHash;
    const completionStatus = await campaignsApi.completionStatusByUser(campaignHash);
    this.setState(prevState => ({ ...prevState, completionStatusByUser: completionStatus }));
  };

  checkCompletion = async () => {
    const { campaignHash } = this.props.match.params;
    await this.completionStatusByUser(campaignHash);
    if (this.context.checkIfCoordinatesCampaign() || this.context.checkIfAdminPowers()) {
      await this.completionStatusByCampaign(campaignHash);
    }
  };

  toggleDrawer = () => this.setState(prevState => ({ isDrawerOpen: !prevState.isDrawerOpen }));
  setIsDrawerOpen = isOpen => this.setState(({ isDrawerOpen: isOpen }));


  render() {
    const { name: companyName } = this.context.selectedCompany;
    const { campaign, active, campaignItemsList, isDrawerOpen, completionStatusByUser, completionStatusByCampaign,
      waitingForCompanyChange, loading, availableRoutes, roleInCampaign } = this.state;
    const { classes } = this.props;
    const { changeActive, finishCampaign, finishWork, toggleDrawer, setIsDrawerOpen, checkCompletion } = this;

    return (
      <>
        { campaign &&
          <> { !waitingForCompanyChange ?
            <> { !loading && <>
              <CampaignDrawer
                width={drawerWidth} campaign={campaign} completionStatusByUser={completionStatusByUser} roleInCampaign={roleInCampaign}
                completionStatusByCampaign={completionStatusByCampaign}
                campaignItemsList={campaignItemsList} isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen}
                active={active} actions={{ changeActive, finishCampaign, finishWork, toggleDrawer }}
              />
              <Box className={isDrawerOpen ? classes.mainContainer : classes.maxMainContainer }>
                <CampaignRoutes
                  availableRoutes={availableRoutes}
                  checkCompletion={checkCompletion}
                  subtypeId={campaign.mainMethodId}
                  changeActive={changeActive}
                  setIsDrawerOpen={setIsDrawerOpen}
                />
              </Box>
            </> } </>
            : <DialogWrap fullWidth aria-labelledby="form-dialog-title" open={waitingForCompanyChange}>
              <SimpleDialog title={`Trabajar en ${campaign.companyName}`}
                message={<>
                  <p>Para poder entrar a la campaña <strong>{campaign.name}</strong> de la empresa <strong>
                    {campaign.companyName}</strong>, pulsa <strong>Aceptar</strong> para salir de la
                    empresa <strong>{companyName}</strong> e ingresar a <strong>{campaign.companyName}</strong>.</p>
                  <p>De lo contrario, pulsa <strong>Cancelar</strong> e irás a la vista principal de la
                    empresa <strong>{companyName}</strong></p>
                </>}
                actionObjs={[
                  { actionName: 'Cancelar', actionFunc: this.rejectCompanyChange },
                  { actionName: 'Aceptar', actionFunc: this.acceptCompanyChange, color: 'primary' },
                ]}
              />
            </DialogWrap>
          } </>
        }
      </>
    );
  }
}

CampaignContainer.propTypes = {
  history: PropTypes.any,
  classes: PropTypes.object,
  location: PropTypes.any,
  match: PropTypes.object.isRequired,
};

CampaignContainer.contextType = WebContext;

const CampaignContainerWithStyle = withStyles(useStyles)(CampaignContainer);


export default CampaignContainerWithStyle;