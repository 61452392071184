import React from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import FaceIcon from '@material-ui/icons/Face';

import { AlertIcon } from 'src/utils/mdIcons';


const useStyles = makeStyles(theme => ({
  mainContainer: {
    padding: theme.spacing(1),
    minWidth: '200px',
  },
  coverageIndicator: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    backgroundColor: theme.palette.flora.primary.main,
  },
  coverageText: {
    color: theme.palette.common.white,
  },
  scientificName: {
    fontStyle: 'italic',
    fontWeight: '500',
    lineHeight: 1.5,
    color: theme.palette.common.black,
  },
  specificName: {
    fontStyle: 'italic',
    lineHeight: 1,
    color: theme.palette.common.black,
  },
  eeccIcon: {
    fontSize: '18px',
    paddingRight: theme.spacing(0.5),
  },
}));

const AppRecordUI = ({ name, specificName, coverage, eecc = false, unofficial = false }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.mainContainer}>
      <Box display="flex" alignItems="center">
        <Box display="flex" flexDirection="column">
          <Typography className={classes.scientificName} variant="body2">{name}</Typography>
          <Typography className={classes.specificName} variant="caption">{specificName}</Typography>
        </Box>
        <Box flexGrow={1}></Box>
        <Box display="flex" alignItems="center">
          { unofficial && <FaceIcon className={classes.eeccIcon} /> }
          {!unofficial && eecc && <AlertIcon className={classes.eeccIcon} />}
          <Paper className={classes.coverageIndicator} elevation={0}>
            <Box display="flex" height="100%" alignItems="center" justifyContent="center">
              <Typography className={classes.coverageText} variant="body2">{coverage}</Typography>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Paper>
  );
};

AppRecordUI.propTypes = {
  name: PropTypes.string,
  specificName: PropTypes.string,
  coverage: PropTypes.string,
  eecc: PropTypes.bool,
  unofficial: PropTypes.bool,
};


export {
  AppRecordUI,
};
