import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Scroll from 'react-scroll';


const ScrollToTop = ({ children }) => {
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen(() => {
      Scroll.animateScroll.scrollToTop({ duration: 500 });
    });
    return () => {
      unlisten();
    };
  // eslint-disable-next-line
  }, []);

  return children;
};

ScrollToTop.propTypes = {
  children: PropTypes.node,
};

export { ScrollToTop };