import React from 'react';

import { HomeRoutes } from 'src/scenes/Home/HomeRoutes';
import { HomeHeaderContainer, HomeFooterContainer } from 'src/scenes/Home/components';


const HomeContainer = () => (
  <>
    <HomeHeaderContainer />
    <HomeRoutes />
    <HomeFooterContainer />
  </>
);


export { HomeContainer };