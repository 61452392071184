import { recordTypeMap } from 'src/utils/recordTypeMap';
import { isFloraIndividualRecord, isFaunaIsolatedRecord } from 'src/utils/checkers';
import { createForm } from 'src/utils/util';


const createRecord = ({ campaignId, tableRef }) => async record => {
  const { backendName, creatableProps, create } = recordTypeMap[record.type];
  const recordToAdd = {
    recordType: backendName,
    ...((isFloraIndividualRecord(record.type) || isFaunaIsolatedRecord(record.type)) ? { campaignId } : {}),
  };
  creatableProps.forEach(prop => recordToAdd[prop] = record[prop]);

  const form = createForm({ ...recordToAdd, speciesName: record.speciesName });
  await create(form);
  tableRef.current && tableRef.current.onQueryChange();
};


export { createRecord };