import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { MapWrapper } from 'src/components';
import { mapOptions, layers, legend } from 'src/scenes/Home/scenes/CoverPage/components/planningUIDataFaker';
import { PlanningSidePanel } from 'src/scenes/Planning/components';
import { campaignSubtypeLanguage } from 'src/utils/componentLanguage';
import { BrowserUI, HeaderUI } from 'src/scenes/Home/components';


const useStyles = makeStyles(theme => ({
  container: {
  },
  map: {
    borderBottomRightRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
    height: '450px',
  },
  mapContainer: {
    position: 'relative',
  },
  sidePanel: {
    position: 'absolute',
    top: -8,
    right: 0,
    zIndex: 314159,
    pointerEvents: 'initial',
    padding: theme.spacing(2),
    transform: 'scale(0.9)',
    width: '240px',
    height: '95%',
  },
  titleProps: {
    fontSize: '1.75rem',
  },
  buttonProps: {
    transform: 'scale(0.9)',
  },
}));

const PlanningUI = () => {
  const classes = useStyles();

  return (
    <BrowserUI size="small">
      <HeaderUI size="small" user="Juan Von Hildebrand"/>
      <Box className={classes.mapContainer}>
        <MapWrapper id="planning-map" className={classes.map} options={mapOptions} layers={layers} legend={legend} showTools disableZoom />
        <PlanningSidePanel
          className={classes.sidePanel}
          csLang={campaignSubtypeLanguage('plots')}
          userIsFan={false}
          titleProps={classes.titleProps}
        />
      </Box>
    </BrowserUI>
  );
};


export {
  PlanningUI,
};