const checkPermission = (
  { action, extraCondition = true, checkOnCampaignRole = false, notEvenGods = false, webContext, campaignRole },
) => {
  const { rolePermissions: companyRolePerms, selectedCompany: { role: userCompanyRole },
    campaignRolesPermissions, checkIfAdminPowers } = webContext;
  const { collector: campaignCollectorPerms = [], coordinator: campaignCoordinatorPerms = [] } = campaignRolesPermissions;

  let campaignRoleToCheck;
  let campaignPermCheck = false;
  if (checkOnCampaignRole) {
    // if no campaign role was given, get it from the context.
    campaignRoleToCheck = campaignRole ?? (webContext.checkIfCoordinatesCampaign() ? 'coordinator' : 'collector');
    // check perms against their role within the campaign
    campaignPermCheck = campaignRoleToCheck && (
      campaignRoleToCheck === 'coordinator' ? campaignCoordinatorPerms.includes(action) : campaignCollectorPerms.includes(action)
    );
  }

  const companyPermCheck = companyRolePerms.includes(action);

  // check camp role if appropriate, in that case also check admin perms if they have admin powers, otherwise just check company perms.
  const rolePermsCheck = checkOnCampaignRole ? campaignPermCheck || (checkIfAdminPowers() && companyPermCheck) : companyPermCheck;
  // finally: if they're godly, and it's not something even gods shouldn't do, let them pass, otherwise go with the normal check
  return (userCompanyRole === 'god' && !notEvenGods) || (rolePermsCheck && extraCondition);
};


export {
  checkPermission,
};