import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, List, ListItem, ListItemIcon, Checkbox, ListItemText, DialogTitle, DialogContent,
  DialogActions, Typography, Divider, FormControlLabel } from '@material-ui/core';

import { DialogButton, AlertWrapper } from 'src/components';
import { useFetchStatus } from 'src/hooks';


const geometriesMapper = {
  Point: 'punto(s)',
  Polygon: 'polígono(s)',
  LineString: 'línea(s)',
};

const ImportAreaDialog = props => {
  const { actions, data } = props;

  const [ options, setOptions ] = useState({ showMarkers: true, showPolygons: true, showLines: true, replaceAll: false });

  const handleOptionClick = option => () => setOptions(p => ({ ...p, [option]: !p[option] }));

  const { handleConfirm, fetchStatus, errorMessage, hasError } = useFetchStatus({
    confirmFn: () => actions.importInterestArea(options),
    closeFn: actions.closeDialog,
  });

  return (
    <>
      <DialogTitle id="form-dialog-title">Importar área de interés</DialogTitle>
      <DialogContent>
        <Typography display="inline" variant="body1">
          Se encontraron las siguientes geometrías, seleccione las que desea incorporar en el área de interés:
        </Typography>
        <List>
          {data['Point'] &&
            <ListItem role={undefined} dense button onClick={handleOptionClick('showMarkers')}>
              <ListItemIcon>
                <Checkbox edge="start" checked={options.showMarkers} disableRipple />
              </ListItemIcon>
              <ListItemText primary={`${data['Point']} ${geometriesMapper['Point']}`} />
            </ListItem>}
          {data['LineString'] &&
            <ListItem role={undefined} dense button onClick={handleOptionClick('showLines')}>
              <ListItemIcon>
                <Checkbox edge="start" checked={options.showLines} disableRipple />
              </ListItemIcon>
              <ListItemText primary={`${data['LineString']} ${geometriesMapper['LineString']}`} />
            </ListItem>}
          {data['Polygon'] &&
            <ListItem role={undefined} dense button onClick={handleOptionClick('showPolygons')}>
              <ListItemIcon>
                <Checkbox edge="start" checked={options.showPolygons} disableRipple />
              </ListItemIcon>
              <ListItemText primary={`${data['Polygon']} ${geometriesMapper['Polygon']}`} />
            </ListItem>}
          <Divider />
          <Box mt={2}>
            <FormControlLabel
              control={<Checkbox checked={options.replaceAll} onChange={handleOptionClick('replaceAll')} />}
              label="Reemplazar capas anteriores"
            />
            {options.replaceAll &&
              <Typography color="error" variant="caption" component="p">
                * Esta acción eliminará todas las capas cargadas anteriormente
              </Typography>}
          </Box>
        </List>

        {hasError && <AlertWrapper severity="error">{errorMessage}</AlertWrapper>}

      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton onClick={handleConfirm} fetchStatus={fetchStatus} color="primary">Confirmar</DialogButton>
      </DialogActions>
    </>
  );
};

ImportAreaDialog.propTypes = {
  actions: PropTypes.object,
  data: PropTypes.object,
};


export {
  ImportAreaDialog,
};
