import React, { useState, useEffect } from 'react';
import { usersApi } from 'src/services';
import { useParams, useHistory } from 'react-router';
import { Grid, Box, Typography, FormHelperText, Paper } from '@material-ui/core';
import { NviroButton } from 'src/components';
import { NviroTextField, CoolBackground } from 'src/scenes/Home/components';

import { makeStyles, useTheme } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  grid: {
    zIndex: 0,
  },
  title: {
    color: theme.palette.common.gray600,
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '1.5rem',
  },
  formLabel: {
    color: '#6c6c6c',
    marginLeft: '0.75rem',
  },
  recoverPass: {
    color: '#6c6c6c',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  formError: {
    marginLeft: '0.75rem',
  },
  errorLabel: {
    color: theme.palette.error.main,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  errorMsg: {
    color: '#6c6c6c',
  },
  successMsg: {
    color: theme.palette.success.main,
    textTransform: 'uppercase',
  },
  logo: {
    marginTop: theme.spacing(20) * -1,
    paddingBottom: theme.spacing(5),
    cursor: 'pointer',
    height: '80px',
    [theme.breakpoints.down('sm')]: {
      height: '72px',
      marginTop: theme.spacing(25) * -1,
    },
  },
  container: {
    height: '100vh',
    backgroundColor: 'white',
  },
  form: {
    width: '320px',
  },
  paper: {
    paddingRight: theme.spacing(9),
    paddingLeft: theme.spacing(9),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    width: '344px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      width: '100%',
    },
  },
}));

const ResetPasswordContainer = (() => {

  const classes = useStyles();
  const theme = useTheme();
  const { token } = useParams();
  const history = useHistory();

  const [ form, setForm ] = useState({ password: '', repassword: '', token });
  const [ submitting, setSubmitting ] = useState(false);
  const [ errorMsg, setErrorMsg ] = useState('');
  const [ successMsg, setSuccessMsg ] = useState('');
  const { password, repassword } = form;

  useEffect(() => {
    document.title = 'Restablecer contraseña - Nviro Capture';
    const redirectIfUserIsAuthenticated = async () => {
      const isAuthenticated = await usersApi.isAuthenticated();
      if (isAuthenticated) {
        history.push('/web');
      }
    };
    redirectIfUserIsAuthenticated();

    // eslint-disable-next-line
  }, []);

  const handleSubmit = async e => {
    try {
      e.preventDefault();
      setSubmitting(true);
      if (password && password === repassword && password.length >= 8) {
        const { message } = await usersApi.resetPassword({ token, password });
        setSuccessMsg(message);
        setTimeout(() => history.push('/login'), 3000);
      }
    } catch (e) {
      if (e.serverMessage) {
        setErrorMsg(e.serverMessage);
      } else {
        console.error(e);
        setErrorMsg('Error al intentar reestablecer contraseña. Por favor, intente más tarde');
      }
    }
  };

  const handleChange = e => setForm({ ...form, [e.target.name]: e.target.value });

  const inputCommonProps = {
    type: 'password',
    required: true,
    onChange: handleChange,
  };

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.container}>
      <CoolBackground />
      <Grid item className={classes.grid}>
        <img className={classes.logo} src={theme.whiteLogo} alt="nviro-logo" onClick={() => history.push('/')} />
      </Grid>
      <Grid item className={classes.grid} container justifyContent="center">
        <Paper className={classes.paper}>
          <Box mt={3}>
            <Typography variant="h6" className={classes.title} color="primary">RESTABLECER CONTRASEÑA</Typography>
          </Box>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Box mt={2} width="100%">
              <NviroTextField autoFocus name="password" value={form.password} label="Contraseña" {...inputCommonProps}/>
              {submitting && !password &&
                <FormHelperText className={classes.formError} error>
                  DEBES INGRESAR UNA CONTRASEÑA
                </FormHelperText>
              }
              {submitting && password.length < 8 &&
                <FormHelperText className={classes.formError} error>
                  LA CONTRASEÑA DEBE TENER MÁS DE 8 CARACTERES
                </FormHelperText>
              }
            </Box>
            <Box mt={2}>
              <NviroTextField name="repassword" value={form.repassword} label="Repetir contraseña" {...inputCommonProps} />
              {submitting && !repassword && <FormHelperText error>DEBES INGRESAR UNA CONTRASEÑA</FormHelperText>}
              {submitting && password !== repassword &&
                <FormHelperText className={classes.formError} error>
                  LAS CONTRASEÑAS DEBEN COINCIDIR
                </FormHelperText>
              }
            </Box>
            <Box mt={5}>
              <NviroButton color="primary" type="submit" variant="contained">Cambiar contraseña</NviroButton>
            </Box>
            <Box my={3}>
              {errorMsg && !successMsg &&
                <Box display="flex" flexDirection="column" alignItems="center" >
                  <Typography variant="body1" className={classes.errorLabel}>Ha ocurrido un error</Typography>
                  <Typography variant="body1" className={classes.errorMsg}>{errorMsg}</Typography>
                </Box>
              }
              {successMsg &&
                <Box display="flex" flexDirection="column" alignItems="center" >
                  <Typography variant="body1" className={classes.successMsg}>{successMsg}</Typography>
                </Box>
              }
            </Box>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
});


export {
  ResetPasswordContainer,
};