import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import { TableWrapper } from 'src/components';


const BillsList = props => {
  const { billsList, personalCompanies } = props;

  const tableColumns = [
    { title: 'Fila', field: 'id' },
    { title: personalCompanies ? 'N° usuario' : 'N° usuario en empresa', field: 'userNumber' },
    ...(personalCompanies ? [] : [ { title: 'Empresa', field: 'companyName' } ]),
    { title: 'Proyecto', field: 'projectName' },
    { title: 'Campaña', field: 'campaignName' },
    { title: 'Componente', field: 'component' },
    { title: 'Id campaña', field: 'campaignId' },
    { title: 'Email', field: 'userEmail' },
    { title: 'Nombre', field: 'userName' },
    { title: '¿Sincronizó tracks el mes pasado?', field: 'syncTracksLastMonth', render: r => r.syncTracksLastMonth ? 'Sí' : 'No' },
    {
      title: '¿Sincronizó solo tracks el mes pasado?',
      field: 'syncOnlyTracksLastMonth',
      render: r => r.syncOnlyTracksLastMonth ? 'Sí' : 'No',
    },
    {
      title: '¿Sincronizó solo tracks este mes?',
      field: 'syncOnlyTracksInCurrentMonth',
      render: r => r.syncOnlyTracksInCurrentMonth ? 'Sí' : 'No',
    },
    { title: 'Conjuntos de tracks sincronizados', field: 'tracksSetsSynced' },
    { title: 'Puntos sincronizados', field: 'pointsSynced' },
    { title: 'Metodologías sincronizadas', field: 'metodologiesSynced' },
    { title: 'Registros Sincronizados', field: 'recordSynced' },
    { title: 'Individuos sincronizados', field: 'individualSynced' },
    { title: 'Conjuntos de árboles sincronizados', field: 'treesSetsSynced' },
    { title: 'Total de sincronizaciones', field: 'totalSyncs' },
  ];

  return (
    <Box width="100%">
      <TableWrapper
        columns={tableColumns}
        data={billsList}
        options={ {
          pageSize: billsList.length >= 100 ? 100 : billsList.length >= 50 ? 50 : billsList.length >= 20 ? 20 : 10,
          pageSizeOptions: [ 10, 20, 50, 100 ],
        } }
      />
    </Box>
  );
};

BillsList.propTypes = {
  billsList: PropTypes.array,
  personalCompanies: PropTypes.bool,
};


export { BillsList };
