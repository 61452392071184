import React from 'react';
import { InputLabel, Box, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';

import { AutocompleteWrapper, NaturalNumberField } from 'src/components';


const FloraRecordInputs = ({ record, handlers, validators, options }) => {

  const { individuals, coverage } = record;
  const { coverageOptions } = options;
  const { handleChange, handleSelect } = handlers;
  const { hasBadData, isIndividualsEmptyOrBetween1And10000 } = validators;

  return (
    <>
      <Box my={2}>
        <InputLabel shrink>Cobertura</InputLabel>
        <AutocompleteWrapper
          value={coverageOptions.find(c => c.value === coverage)}
          options={coverageOptions}
          onChange={(_, { value }) => handleSelect({ coverage: value })}
        />
      </Box>
      <Box my={2}>
        <InputLabel shrink>Número de individuos (opcional)</InputLabel>
        <NaturalNumberField
          name="individuals"
          value={individuals || ''}
          inputProps={{ min: 1 }}
          onChange={handleChange}
          fullWidth
          autoComplete="off"
          variant="outlined"
          size="small"
          error={hasBadData && !isIndividualsEmptyOrBetween1And10000}
        />
        {hasBadData && !isIndividualsEmptyOrBetween1And10000 &&
          <FormHelperText error>Solo se permiten valores entre 1 y 10000</FormHelperText>
        }
      </Box>
    </>
  );
};

FloraRecordInputs.propTypes = {
  record: PropTypes.object.isRequired,
  handlers: PropTypes.object,
  validators: PropTypes.objectOf(PropTypes.bool),
  options: PropTypes.object,
};


export { FloraRecordInputs };