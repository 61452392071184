import React, { useEffect, useState, useContext } from 'react';
import { Grid, Box, Paper } from '@material-ui/core';
import { WebContext } from 'src/scenes/web-context';
import { MonitoringMap } from 'src/scenes/Campaign/scenes/Monitoring/components/MonitoringMap';
import { pointsApi, campaignsApi, floraApi, faunaApi } from 'src/services';
import { TitleContainer, ContentContainer } from 'src/components';
import { makeStyles } from '@material-ui/styles';
import { Skeleton } from '@material-ui/lab';

import { CensusRecordPopover } from 'src/scenes/Campaign/scenes/Monitoring/components';


const useStyles = makeStyles(theme => ({
  mapContainer: {
    position: 'relative',
  },
  popover: {
    position: 'absolute',
    bottom: '18px',
    right: '20px',
    zIndex: 314159,
    pointerEvents: 'initial',
    padding: theme.spacing(3),
    width: '240px',
  },
}));

const methodologiesTypeOptions = [
  { value: 'lineal-transect-amphibians', label: 'Transecto Lineal - Anfibios' },
  { value: 'lineal-transect-birds', label: 'Transecto Lineal - Aves' },
  { value: 'lineal-transect-mammals', label: 'Transecto Lineal - Mamíferos' },
  { value: 'lineal-transect-reptiles', label: 'Transecto Lineal - Reptiles' },
  { value: 'lineal-transect-mixed', label: 'Transecto Lineal - Mixto' },
  { value: 'sampling-point', label: 'Punto de Muestreo' },
  { value: 'sherman-traps', label: 'Trampas Sherman' },
  { value: 'aerial-transit', label: 'Tránsito Aéreo' },
  { value: 'camera-traps', label: 'Trampas Cámara' },
  { value: 'echo-location-detection', label: 'Detección de Eco Localizaciones' },
  { value: 'play-back-amphibians', label: 'Play Back - Anfibios' },
  { value: 'play-back-birds', label: 'Play Back - Aves' },
];

const TOO_MANY_ITEMS_LIMIT = 2000;

const MonitoringContainer = () => {

  const context = useContext(WebContext);
  const classes = useStyles();

  const [ state, setState ] = useState({
    pointsList: [],
    campaignTracksList: [],

    individualRecordsList: [],

    censusRecordsList: [],

    methodologiesList: [],
    isolatedRecordsList: [],
    indirectRecordsList: [],
    individualsList: [],

    options: {},
    fetched: false,
    originalLengths: {
      pointsList: 0,
      individualRecordsList: 0,
      censusRecordsList: 0,
      methodologiesList: 0,
      isolatedRecordsList: 0,
      indirectRecordsList: 0,
      individualsList: 0,
    },
  });

  const { hash: campaignHash, mainMethodId: campaignSubtypeId, layers, id: campaignId } = context.selectedCampaign;

  const processItemsList = itemListsObj => {
    const res = {
      itemLists: {},
      originalLengths: {},
    };

    for (const listName in itemListsObj) {
      res.originalLengths[listName] = itemListsObj[listName].length;
      if (itemListsObj[listName].length > TOO_MANY_ITEMS_LIMIT) {
        res.itemLists[listName] = itemListsObj[listName].splice(0, TOO_MANY_ITEMS_LIMIT);
      } else {
        res.itemLists[listName] = itemListsObj[listName];
      }
    }

    return res;
  };

  useEffect(() => {
    const fetchData = async () => {
      switch (campaignSubtypeId) {
        case 'plots': {
          const [ pointsList, campaignTracksList, individualRecordsList ] = await Promise.all([
            pointsApi.getPointsForMonitoringMap({ campaignHash, campaignSubtypeId }),
            campaignsApi.getTracks(campaignHash),
            floraApi.getIndividualRecordsForMap(campaignHash),
          ]);
          const { itemLists, originalLengths } = processItemsList({ pointsList, individualRecordsList });
          setState({ ...state, campaignTracksList, ...itemLists, originalLengths, fetched: true });
          break;
        }
        case 'stations': {
          const [ pointsList, campaignTracksList, faunaRecords, sexTypesOptions, ageStratumsOptions,
            indirectRecordsTypesOptions, methodologiesList ] = await Promise.all([
            pointsApi.getPointsForMonitoringMap({ campaignHash, campaignSubtypeId }),
            campaignsApi.getTracks(campaignHash),
            faunaApi.getRecordsForMonitoringMap({ campaignHash }),
            faunaApi.getSexTypes(),
            faunaApi.getAgeStratums(),
            faunaApi.getIndirectRecordTypes(),
            faunaApi.getMapMethodologies({ campaignHash }),
          ]);

          const { individuals: individualsList, indirectRecords: indirectRecordsList, isolatedRecords: isolatedRecordsList } = faunaRecords;

          const { itemLists, originalLengths } = processItemsList(
            { pointsList, methodologiesList, individualsList, indirectRecordsList, isolatedRecordsList },
          );

          setState({
            ...state,
            campaignTracksList,
            options: {
              sexTypesOptions,
              ageStratumsOptions,
              indirectRecordsTypesOptions,
              methodologiesTypeOptions,
            },
            ...itemLists,
            originalLengths,
            fetched: true,
          });
          break;
        }
        case 'point-quadrat': {
          const [ pointsList, campaignTracksList ] = await Promise.all([
            pointsApi.getPointsForMonitoringMap({ campaignHash, campaignSubtypeId }),
            campaignsApi.getTracks(campaignHash),
          ]);

          const { itemLists, originalLengths } = processItemsList({ pointsList });
          setState({ ...state, ...itemLists, originalLengths, campaignTracksList, fetched: true });
          break;
        }
        case 'point-intercept': {
          const [ pointsList, campaignTracksList ] = await Promise.all([
            pointsApi.getPointsForMonitoringMap({ campaignHash, campaignSubtypeId }),
            campaignsApi.getTracks(campaignHash),
          ]);
          const { itemLists, originalLengths } = processItemsList({ pointsList });

          setState({ ...state, ...itemLists, originalLengths, campaignTracksList, fetched: true });
          break;
        }
        case 'census' : {
          const [ campaignTracksList, censusRecordsList ] = await Promise.all([
            campaignsApi.getTracks(campaignHash),
            floraApi.getCensusRecordsForMap(campaignId),
          ]);

          const { itemLists, originalLengths } = processItemsList({ censusRecordsList });
          setState({ ...state, campaignTracksList, ...itemLists, originalLengths, fetched: true });
          break;
        }
        default:
          console.error(`Campaign subtype doesn't exist`);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [ campaignHash, campaignSubtypeId ]);

  const { pointsList, campaignTracksList, fetched, individualRecordsList, censusRecordsList,
    methodologiesList, isolatedRecordsList, indirectRecordsList, individualsList, originalLengths, options } = state;

  const fixedPointsList = pointsList.map(p => ({
    ...p,
    coords: p.plannedLatitude && p.plannedLongitude ? [ p.plannedLatitude, p.plannedLongitude ] : [ p.latitude, p.longitude ],
  }));

  const [ popoverData, setPopoverData ] = useState(undefined);

  return (
    <Grid container>
      <TitleContainer maxWidth="xl" breadcrumbs={[ 'company', 'campaigns', { name: 'Mapa' } ]}>Mapa</TitleContainer>
      <ContentContainer maxWidth="xl">
        <Box p={1} component={Paper} className={classes.mapContainer}>
          {fetched ? <>
            <MonitoringMap
              pointsList={fixedPointsList}
              campaignTracksList={campaignTracksList}
              interestArea={layers}
              csLang={context.campaignSubtypeLanguage}
              campaignSubtypeId={campaignSubtypeId}
              campaignHash={campaignHash}
              censusRecordsList={censusRecordsList}
              individualRecordsList={individualRecordsList}
              methodologiesList={methodologiesList}
              isolatedRecordsList={isolatedRecordsList}
              indirectRecordsList={indirectRecordsList}
              individualsList={individualsList}
              itemsLengthLimit={TOO_MANY_ITEMS_LIMIT}
              originalLengths={originalLengths}
              setPopoverData={setPopoverData}
              options= {options}
            />
            <CensusRecordPopover className={classes.popover} data={popoverData} />
          </> : <Box height="75vh"><Skeleton animation="wave" variant="rect" height="75vh" /></Box>
          }
        </Box>
      </ContentContainer>
    </Grid>
  );
};


export {
  MonitoringContainer,
};
