import React, { PureComponent } from 'react';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';

import { GraphWrapper } from 'src/components';
import { campaignStatisticsApi } from 'src/services';
import { getRandomColor, addOrReplaceOpacityInColor } from 'src/utils/util';


export class SpeciesByUser extends PureComponent {

  apiCall = () => campaignStatisticsApi.getSpeciesByUser(this.props.campaignHash);

  dataToGraphData = data => {
    const colors = data.map(getRandomColor);

    return {
      labels: data.map(d => d.email.replace(/@.*/, '')),
      datasets: [
        {
          data: data.map(d => d.total),
          backgroundColor: colors.map(c => addOrReplaceOpacityInColor(c, 0.7)),
          borderColor: 'white',
          hoverBackgroundColor: colors.map(c => addOrReplaceOpacityInColor(c, 1)),
        },
      ],
    };
  };

  render() {
    return (
      <GraphWrapper
        title='Especies por colector'
        info='Muestra la cantidad de especies distintas registradas por usuario'
        apiCall={ this.apiCall }
        dataToGraphData={ this.dataToGraphData }
        chartjsComponent={ Doughnut }
        legend={ { position: 'top' } }
      />
    );
  }
}

SpeciesByUser.propTypes = {
  campaignHash: PropTypes.string.isRequired,
};
