import React from 'react';
import PropTypes from 'prop-types';
import { Box, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { AppHeaderUI, FloraAppRecordsUI, MobileUI, AppTabsUI } from 'src/scenes/Home/components';
import { AppRecordUI } from 'src/scenes/Home/components';


const useStyles = makeStyles(theme => ({
  mobile: {
    transform: 'scale(0.9)',
    [theme.breakpoints.down('sm')]: {
      transform: 'scale(0.85)',
    },
  },
  recordZero: {
    zIndex: 1,
    transform: 'translate(-50px, -50px)',
  },
  recordOne: {
    zIndex: 1,
    transform: 'translate(120px, 25px)',
  },
  recordTwo: {
    zIndex: 1,
    transform: 'translate(100px, 80px)',
  },
  recordThree: {
    zIndex: 1,
    transform: 'translate(10px, -132px)',
  },
  recordFour: {
    zIndex: 1,
    transform: 'translate(-120px, 55px)',
  },
}));

const records = [
  { name: 'Chloraea heteroglossa', specificName: 'orchidaceae', eecc: true, coverage: '3' },
  { name: 'Passiflora pinnatistipula', specificName: 'passifloraceae', coverage: 'r' },
];

const otherRecords = [
  { name: 'Llagunoa glandulosa', specificName: 'sapindaceae', eecc: true, coverage: '3' },
  { name: 'Fuchsia lycioides', specificName: 'onagraceae', coverage: '1' },
  { name: 'Geoffroea decorticans', specificName: 'fabaceae', coverage: '+' },
  { name: 'Bridgesia incisifolia', specificName: 'sapindaceae', coverage: '4' },
  { name: 'Adesmia confusa', specificName: 'fabaceae', coverage: 'p' },
];

const FloraAppRecordsCompositionUI = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box display="flex" alignItems="center">
      {!isDownSm && <Box display="flex" flexDirection="column">
        <Box className={classes.recordZero} px={1} pb={1} >
          <AppRecordUI {...otherRecords[0] } />
        </Box>
        <Box className={classes.recordOne} px={1} pb={1} >
          <AppRecordUI {...otherRecords[1] } />
        </Box>
        <Box className={classes.recordTwo} px={1} pb={1} >
          <AppRecordUI {...otherRecords[2] } />
        </Box>
      </Box>
      }
      <Box className={classes.mobile}>
        <MobileUI shadow={!isDownSm}>
          <AppHeaderUI backText="Atrás" title="Parcela 1" />
          <AppTabsUI />
          <FloraAppRecordsUI records={isDownSm ? otherRecords : records} />
        </MobileUI>
      </Box>
      {!isDownSm &&
        <Box display="flex" flexDirection="column">
          <Box className={classes.recordThree} px={1} pb={1} >
            <AppRecordUI {...otherRecords[3] } />
          </Box>
          <Box className={classes.recordFour} px={1} pb={1} >
            <AppRecordUI {...otherRecords[4] } />
          </Box>
        </Box>
      }
    </Box>
  );
};

// recommended to not go for more than 5 records here
FloraAppRecordsCompositionUI.propTypes = {
  records: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      specificName: PropTypes.string,
      eecc: PropTypes.bool,
      unofficial: PropTypes.bool,
      coverage: PropTypes.string,
    }),
  ),
};


export {
  FloraAppRecordsCompositionUI,
};