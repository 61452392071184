import React, { Component } from 'react';
import { Tooltip, Paper, Box } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';

import { LoadingCard, TableWrapper } from 'src/components';
import { objectIsEmpty } from 'src/utils/util';


export class PointTimeByUser extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
    };
  }

  componentDidUpdate = async prevProps => {
    if (!objectIsEmpty(this.props.data) && this.props.data !== prevProps.data) {
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading, error } = this.state;
    const { data, cLang, componentId } = this.props;
    const users = data;

    const tableColumns = [
      { title: 'Lugar', field: 'rank' },
      { title: 'Correo', field: 'email', render: r => <div>{r.email.replace(/@.*/, '')}</div> },
      { title: 'Promedio h/p', field: 'mean', render: r =>
        <Box display="flex">
          <Box flexGrow={1}>{r.mean}</Box>
          <Tooltip title={`Calculado de sus ${r.total} ${cLang.points} terminadas con al menos un registro`}>
            <InfoIcon color="disabled" style={{ fontSize: '1rem', cursor: 'pointer' }} />
          </Tooltip>
        </Box>,
      },
    ];
    const usersList = users.map((u, i) => ({ id: i, rank: i + 1, ...u }));
    return (
      <LoadingCard loading={ loading } error={ error }
        title={`Tiempo por ${cLang.point}`}
        info={
          componentId === 'flora' ?
            `
              Muestra la velocidad en h/${cLang.p} (horas por ${cLang.point}) o min/${cLang.p} (minutos por ${cLang.point}) a la cual se
              están completando las ${cLang.points}, utilizando como datos la fecha del 1er registro capturado y la fecha en la cual se
              finalizó la ${cLang.point}. Para esta medición no se consideran las ${cLang.points} sin registros.
            ` :
            `
              Muestra la velocidad en h/${cLang.p} (horas por ${cLang.point}) o min/${cLang.p} (minutos por ${cLang.point}) a la cual se
              están completando las ${cLang.points}, utilizando como datos la fecha de la 1ra metodología creada y la fecha en la cual se
              finalizó la ${cLang.point}. Para esta medición no se consideran las ${cLang.points} sin metodologías.
            `
        }
      >
        <Box display="flex" flexDirection="column" height="100%">
          <Box display="flex" flexGrow={1} width="100%">
            <TableWrapper columns={tableColumns} data={[ ...usersList ]}
              options={{ search: false, toolbar: false, padding: 'dense', paginationType: 'stepped',
                pageSizeOptions: [ 10 ], paging: usersList.length >= 10 }}
              components={{ Container: props => <Paper {...props} elevation={0} style={{ width: '100%' }} /> }}
            />
          </Box>
        </Box>
      </LoadingCard>
    );
  }
}


PointTimeByUser.propTypes = {
  campaignHash: PropTypes.string.isRequired,
  data: PropTypes.array,
  cLang: PropTypes.object,
  componentId: PropTypes.string.isRequired,
};
