import React, { useState } from 'react';
import { InputLabel, DialogContent, Box, DialogActions, Button,
  DialogTitle, Typography } from '@material-ui/core';

import PropTypes from 'prop-types';
import { AutocompleteWrapper, AlertWrapper, DialogButton } from 'src/components';
import { useFetchStatus } from 'src/hooks';

const getOptionSelected = (opt, optSelected) => opt.value === optSelected.value;

const SuggestMethodologiesDialog = props => {
  const { actions = {}, options, preSelectedMethodologies = [], onlyUI = false } = props;

  const [ selectedMethodologies, setSelectedMethodologies ] = useState(preSelectedMethodologies);

  const submit = async () =>
    await props.actions.updatePoints({ suggestedMethodologies: selectedMethodologies.map(opt => ({ methodEnumId: opt.value })) });

  const { fetchStatus, setFetchStatus, errorMessage, hasError, fetchStatusEnum, handleConfirm } = useFetchStatus({
    confirmFn: submit,
    closeFn: actions.closeDialog,
  });

  const handleSelectMethodologies = (event, selectedMethodologies) => {
    setFetchStatus(fetchStatusEnum.NOT_STARTED);
    setSelectedMethodologies(selectedMethodologies);
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">Sugerir metodologías a realizar</DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="textPrimary">
            Sugiere tipos de metodologías a hacer en las estaciones de muestreo seleccionadas.
            Luego, podrás hacer seguimiento de si las metodologías se han realizado o no.
        </Typography>
        <Box px={1}>
          <Box my={2}>
            <InputLabel shrink>Metodología(s) a sugerir</InputLabel>
            <AutocompleteWrapper options={options} className="basic-multi-select"
              value={selectedMethodologies}
              onChange={handleSelectMethodologies}
              getOptionSelected={getOptionSelected}
              placeholder={'Seleccione las metodologías a realizar en la estación'} noOptionsText="No hay más tipos de metodologías"
              disabled={onlyUI} multiple clearable
            />
          </Box>
          <Box>
            { hasError && <AlertWrapper severity="error">{errorMessage}</AlertWrapper> }
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog} color="default">Cancelar</Button>
        <DialogButton color='primary' disabled={onlyUI} fetchStatus={fetchStatus} onClick={onlyUI ? () => {} : handleConfirm}>
          Confirmar
        </DialogButton>
      </DialogActions>
    </>
  );
};


SuggestMethodologiesDialog.propTypes = {
  options: PropTypes.array,
  actions: PropTypes.object,
  preSelectedMethodologies: PropTypes.array,
  onlyUI: PropTypes.bool,
};


export { SuggestMethodologiesDialog };
