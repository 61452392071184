import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography, Container, Box, Select, MenuItem, Button, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Alert } from '@material-ui/lab';

import { TitleContainer, ContentContainer, AlertWrapper, TableSkeleton } from 'src/components';
import { BillsActiveUsersSummaryStatistics, BillsList, BillsMonthStatistics, BillsUsersCounterInMonthStatistics }
  from 'src/scenes/Bills/components';
import { companiesApi } from 'src/services';
import { monthNames, prependZero } from 'src/utils/util';
import { useFetchStatus } from 'src/hooks';
import { WebContext } from 'src/scenes/web-context';


const useStyles = makeStyles(theme => ({
  statisticCard: {
    height: 'calc(100vh - 130px)',
  },
  miniStatisticsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  statisticCardSmall: {
    height: theme.spacing(50),
    width: '49.5%',
  },
}));

const INITIAL_YEAR = 2023;
const currentDate = new Date();
const [ , currentYear, currentMonth ] = currentDate.toISOString().match(/^(\d{4})-(\d{2})-\d{2}/);
const BillsContainer = () => {

  const styles = useStyles();
  const { selectedCompany: { name: companyName } } = useContext(WebContext);

  const [ year, setYear ] = useState(currentYear);
  const [ month, setMonth ] = useState(currentMonth);
  const [ personalCompanies, setPersonalCompanies ] = useState(false);
  const [ forceCurrentCompany, setForceCurrentCompany ] = useState(false);
  const [ billsList, setBillsList ] = useState([]);

  const { fetchStatus, setFetchStatus, fetchStatusEnum, errorMessage, setErrorMessage, hasError } = useFetchStatus();
  const { LOADING, SUCCESS, ERROR } = fetchStatusEnum;

  useEffect(() => {
    document.title = 'Facturación - Nviro Capture';
    const fetchData = async () => {
      try {
        setFetchStatus(LOADING);
        const billsList = (await companiesApi.billsSummary({ year, month, personalCompanies, forceCurrentCompany }))
          .map((r, i) => ({ ...r, id: i + 1 }));
        setBillsList(billsList);
        setFetchStatus(SUCCESS);
      } catch (e) {
        setBillsList([]);
        setFetchStatus(ERROR);
        setErrorMessage(e.serverMessage);
      }
    };
    fetchData();
  }, [ year, month, personalCompanies, forceCurrentCompany ]); // eslint-disable-line

  const onChangeCompaniesOption = e => {
    const optionSelected = e.target.value;
    if (optionSelected === 0) { // Empresas
      setPersonalCompanies(false);
      setForceCurrentCompany(false);
    } else if (optionSelected === 1) { // Usuarios Fan
      setPersonalCompanies(true);
      setForceCurrentCompany(false);
    } else { // Empresa actual
      setPersonalCompanies(false);
      setForceCurrentCompany(true);
    }
  };

  const companyOptionValue = !personalCompanies && !forceCurrentCompany ? 0
    : personalCompanies && !forceCurrentCompany ? 1
    : !personalCompanies && forceCurrentCompany ? 2
    : -1;

  const activeCompanies = Object.keys(billsList.reduce((p, c) => ({ ...p, [c.companyName]: true }), {})).length;
  const activeCampaigns = Object.keys(billsList.reduce((p, c) => ({ ...p, [c.campaignId]: true }), {})).length;
  const activeUsers = Object.keys(billsList.reduce((p, c) => ({ ...p, [`${c.companyName}-${c.userEmail}`]: true }), {})).length;

  const totalSyncs = billsList.reduce((p, c) => p + c.totalSyncs, 0);
  const totalPointsSyncs = billsList.reduce((p, c) => p + c.pointsSynced, 0);
  const totalRecordsSyncs = billsList.reduce((p, c) => p + c.recordSynced, 0);

  return (
    <Grid container >
      <TitleContainer maxWidth="xl">
        Resumen Facturación
      </TitleContainer>
      <ContentContainer maxWidth="xl">
        <Container maxWidth="xl">
          <Box mt={1} my={ 1 }>
            <Select value={month} onChange={e => setMonth(e.target.value)}>
              {monthNames.map((name, idx) => <MenuItem key={idx} value={prependZero(idx + 1)}>{name}</MenuItem>)}
            </Select>
            <Select value={ year } onChange={e => setYear(e.target.value)}>
              {
                new Array(currentDate.getFullYear() - INITIAL_YEAR + 1).fill().map((_, i) => INITIAL_YEAR + i)
                  .map(year => <MenuItem key={year} value={`${year}`}>{year}</MenuItem>)
              }
            </Select>
            <Select value={companyOptionValue} onChange={ onChangeCompaniesOption }>
              <MenuItem value={ 0 }>Empresas</MenuItem>
              <MenuItem value={ 1 }>Usuarios Fan</MenuItem>
              <MenuItem value={ 2 }>{ companyName }</MenuItem>
            </Select>
            <Button size="small" href={ companiesApi.getBillsSummaryExportUrl({ year, month }) } target="_blank">
              Exportar
            </Button>
          </Box>
          <Box mt={1} display='flex' justifyContent="space-between">
            <Box flex={ 1 }>
              <Alert severity="info" variant='filled'>Hay <strong>{activeCompanies}</strong> empresas activas</Alert>
            </Box>
            <Box flex={ 1 } pl={ 1 } pr={ 1 }>
              <Alert severity="info" variant='filled'>Hay <strong>{activeCampaigns}</strong> campañas activas</Alert>
            </Box>
            <Box flex={ 1 }>
              <Alert severity="info" variant='filled'>Hay <strong>{activeUsers}</strong> usuarios activos</Alert>
            </Box>
          </Box>
          <Box mt={1} display='flex' justifyContent="space-between">
            <Box flex={ 1 }>
              <Alert severity="info" variant='filled'>Sincronizaciones totales: <strong>{totalSyncs}</strong></Alert>
            </Box>
            <Box flex={ 1 } pl={ 1 } pr={ 1 }>
              <Alert severity="info" variant='filled'>Puntos sincronizados: <strong>{totalPointsSyncs}</strong></Alert>
            </Box>
            <Box flex={ 1 }>
              <Alert severity="info" variant='filled'>Registros sincronizados: <strong>{totalRecordsSyncs}</strong></Alert>
            </Box>
          </Box>
          <Box mt={ 1 } mb={ 1 } className={ styles.miniStatisticsContainer }>
            <Card className={ styles.statisticCardSmall }>
              <BillsMonthStatistics billsList={ billsList } />
            </Card>
            <Card className={ styles.statisticCardSmall }>
              <BillsUsersCounterInMonthStatistics year={ year } month={ month } personalCompanies={ personalCompanies }
                forceCurrentCompany={ forceCurrentCompany }
              />
            </Card>
          </Box>
          {fetchStatus === LOADING ?
            <TableSkeleton />
            : (
              billsList.length ?
                <BillsList billsList={billsList} personalCompanies={ personalCompanies }/>
                : <Typography variant="body1" color="textPrimary">No hay datos sobre sincronizaciones en campañas.</Typography>
            )
          }
          {hasError && <Box mt={1}><Alert severity="error" variant='filled'>{errorMessage}</Alert></Box> }
          <Box mt={ 1 } mb={ 1 }>
            <Card className={ styles.statisticCard }>
              <BillsActiveUsersSummaryStatistics forceCurrentCompany={ forceCurrentCompany } />
            </Card>
          </Box>
          <Box mt={ 2 } mb={ 3 }>
            <AlertWrapper severity="info">
              El conteo de empresas/campañas/usuarios activos corresponden a usuarios que han tenido algún tipo de
              actividad de sincronización de datos a la web, no se toma en cuenta si estos datos fueron o no tracks.<br/><br/>
              Además son excluidos los siguientes correos del listado:
              <ul>
                {[ 'edward.moreno@nviro.cl', 'edward.moreno@csw.cl', 'edward.moreno.94@gmail.com', 'edward_moreno_94@hotmail.com',
                  'yhann.que@no.existe.cl', 'yhann.jara@faq.cl', 'yhann.jara@outlook.com', 'yhann.jara@nviro.cl', 'franco.pino@nviro.cl',
                  'sormeno@nviro.cl' ].map(mail => <li key={mail}>{mail}</li>)
                }
              </ul>
              {
                personalCompanies ?
                  'También se toman en cuenta solo empresas personales' :
                  `También se ignoran las siguientes empresas: "Mi espacio", "Demo" y "Test"`
              }.
            </AlertWrapper>
          </Box>
        </Container>
      </ContentContainer>
    </Grid>
  );
};


export {
  BillsContainer,
};