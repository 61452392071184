import urlJoin from 'url-join';
import config from 'src/config/local';

import { get, del, patch, post } from 'src/services/api';


const getCompanies = async () => (await get('company')).data;

const getSelectableCompanies = async () => (await get('company/selectables')).data;

const create = async newCompany => (await post('company', newCompany)).data;

const update = async (companyId, companyToUpdate) => (await patch(`company/${companyId}`, companyToUpdate)).data;

const remove = async companyId => (await del(`company/${companyId}`)).data;

const changeCompany = async companyId => (await get(`company/${companyId}/change`)).data;

const getEnvironments = async () => (await get(`company/environments`)).data;

const createEnvironment = async newEnvironment => (await post('company/environments', newEnvironment)).data;

const updateEnvironment = async (environmentId, environmentToUpdate) =>
  (await patch(`company/environments/${environmentId}`, environmentToUpdate)).data;

const removeEnvironment = async environmentId => (await del(`company/environments/${environmentId}`)).data;

const billsSummary = async ({ year, month, personalCompanies, forceCurrentCompany }) =>
  (await get('company/active-users', { year, month, personalCompanies, forceCurrentCompany })).data;

const getBillsSummaryExportUrl = ({ year, month }) =>
  urlJoin(config.serverUrl, `api/company/active-users?year=${year}&month=${month}&exportFile=true`);

const getActiveUsersCounterAllTime = async ({ forceCurrentCompany }) =>
  (await get('company/active-users-counters-all-time', { forceCurrentCompany })).data;

const getUsersSyncCounterByDayAcc = async ({ year, month, personalCompanies, forceCurrentCompany }) =>
  (await get('company/users-sync-counter-by-day-acc', { year, month, personalCompanies, forceCurrentCompany })).data;

const getLogos = async () => (await get('company/logos')).data;


export {
  getCompanies,
  create,
  update,
  remove,
  changeCompany,
  getSelectableCompanies,
  getEnvironments,
  createEnvironment,
  updateEnvironment,
  removeEnvironment,
  billsSummary,
  getBillsSummaryExportUrl,
  getActiveUsersCounterAllTime,
  getUsersSyncCounterByDayAcc,
  getLogos,
};
