import React from 'react';
import { Box, CircularProgress, Zoom, Button } from '@material-ui/core';
import { CheckCircleOutlineOutlined } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';


const { LOADING, SUCCESS } = fetchStatusEnum;

const DialogButton = ({ children, fetchStatus, ...props }) =>
  <Box minWidth={64} display="flex" justifyContent="center">
    {
      fetchStatus === LOADING ? <CircularProgress size={25} thickness={4.8} />
      : fetchStatus === SUCCESS ? <Zoom in><CheckCircleOutlineOutlined color="primary" size="large" /></Zoom>
      : <Button {...props}>{children ? children : 'Confirmar'}</Button>
    }
  </Box>;

DialogButton.propTypes = {
  children: PropTypes.any,
  fetchStatus: PropTypes.oneOf([ ...Object.values(fetchStatusEnum) ]).isRequired,
};


export { DialogButton } ;