import React from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList as List } from 'react-window';


const maxOptionsCount = 7;
const optionHeight = 35;

const FixedMenuList = props => {
  const { children } = props;
  const optionsHeight = children.length ? (children.length * optionHeight) + 5 : 5;
  const height = children.length ? (children.length < maxOptionsCount ? optionsHeight : 250) : 20;

  return (
    <List
      height={height}
      itemCount={children.length ?? 0}
      itemSize={optionHeight}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

FixedMenuList.propTypes = {
  options: PropTypes.array,
  children: PropTypes.any,
  getValue: PropTypes.func,
};


export {
  FixedMenuList,
};