import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import { LoadingCard } from 'src/components/cards/LoadingCard';


export const GraphWrapper = ({ apiCall, dataPreGraph, dataToGraphData, title, info, chartjsComponent: ChartjsComponent, ...props }) => {

  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(false);
  const [ graphData, setGraphData ] = useState({});

  const updateGraphData = useCallback(data => {
    const graphData = data && (Array.isArray(data) ? data : Object.keys(data)).length ? dataToGraphData(data) : {};
    setLoading(false);
    setError(false);
    setGraphData(graphData);
  }, [ dataToGraphData ]);

  useEffect(() => {
    const updateGraph = async () => {
      try {
        const data = dataPreGraph || await apiCall();
        updateGraphData(data);
      } catch (e) {
        console.error(e);
        setError(true);
        setLoading(false);
      }
    };

    updateGraph();
  }, [ apiCall, dataPreGraph, updateGraphData ]);

  const { options, ...otherProps } = props;
  const defaultOptions = {
    maintainAspectRatio: false,
  };

  return (
    <LoadingCard title={ title } info={ info } loading={ loading } error={ error }>
      <Box display="flex" alignItems="center" height="100%" p={3}>
        {
          Object.keys(graphData).length ?
            <ChartjsComponent data={ graphData } options={ { ...defaultOptions, ...options } } { ...otherProps } /> :
            <span>Faltan datos para poder dibujar el gráfico</span>
        }
      </Box>
    </LoadingCard>
  );
};

GraphWrapper.propTypes = {
  apiCall: PropTypes.func,
  dataPreGraph: PropTypes.array, // se pasa esto o la prop de arriba
  dataToGraphData: PropTypes.func,
  title: PropTypes.string,
  info: PropTypes.string,
  options: PropTypes.object,
  chartjsComponent: PropTypes.object.isRequired,
};
