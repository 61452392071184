import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';


const UserUnlinkDialog = props => {
  const { actions } = props;
  return (
    <Fragment>
      <DialogTitle id="form-dialog-title">Desvincular persona</DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Typography color="textPrimary" variant="body1" component="div">
            Esta acción le quitará a esta persona la posibilidad de interactuar con esta campaña.
          </Typography>
          <br />
          <Typography color="textPrimary" variant="body1">
            ¿Realmente deseas desvincular a la persona?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <Button onClick={actions.unlinkUser} color="primary">Desvincular</Button>
      </DialogActions>
    </Fragment>
  );
};

UserUnlinkDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  cLang: PropTypes.object.isRequired,
};


export { UserUnlinkDialog };
