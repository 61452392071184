import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography, Collapse } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';
import { withAccessControl, DialogButton } from 'src/components';


const { NOT_STARTED, LOADING, SUCCESS, ERROR } = fetchStatusEnum;

const ProjectDeleteDialog = props => {
  const { actions } = props;

  const [ fetchStatus, setFetchStatus ] = useState(NOT_STARTED);
  const [ errorAlert, setErrorAlert ] = useState({ isOpen: false, message: '' });

  const handleConfirm = async () => {
    try {
      setFetchStatus(LOADING);
      await actions.deleteProject();
      setFetchStatus(SUCCESS);
      setTimeout(() => actions.closeDialog(), 300);
    } catch (e) {
      console.error(e);
      setErrorAlert({ isOpen: true, message: e.serverMessage || e.message });
      setFetchStatus(ERROR);
    }
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">Borrar proyecto</DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Typography variant="body1" color="textPrimary">
            Al eliminar este proyecto también borrarás las campañas y parcelas/estaciones de muestreo que contiene
            para ti y para todos los involucrados
          </Typography>
          <br />
          <Typography variant="body1" color="textPrimary">
            ¿Realmente deseas eliminar el proyecto?
          </Typography>
          <br />
          {fetchStatus === ERROR &&
            <Collapse in={errorAlert.isOpen}>
              <Alert severity="error" onClose={() => setErrorAlert(s => ({ ...s, isOpen: false }))}>
                {errorAlert.message}
              </Alert>
            </Collapse>
          }
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color="primary">Borrar</DialogButton>
      </DialogActions>
    </>
  );
};

ProjectDeleteDialog.propTypes = {
  actions: PropTypes.object.isRequired,
};

const ProjectDeleteDialogWithAccessControl = withAccessControl({ action: 'project:delete' })(ProjectDeleteDialog);


export { ProjectDeleteDialogWithAccessControl as ProjectDeleteDialog };
