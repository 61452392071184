import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';


const TableSkeleton = ({ columns = 6, rows = 7 }) =>
  <Grid container spacing={2}>
    {[ ...new Array(columns * rows) ].map((_, idx) =>
      <Grid key={idx} item xs={2}>
        <Skeleton animation="wave" />
      </Grid>,
    )}
  </Grid>;

TableSkeleton.propTypes = {
  columns: PropTypes.number,
  rows: PropTypes.number,
};


export {
  TableSkeleton,
};