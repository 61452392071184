const upperCaseFirstLetter = (str = '') => str.replace(/^(.)/, m => m.toUpperCase());

const species = ({ genus, species, subspecies, variety, family }) => upperCaseFirstLetter(
  [ genus, species, subspecies, variety, family ]
    .join(' ')
    .replace(/ +/g, ' ')
    .trim());

const upperCaseOnlyFirstLetter = string => string !== '' ? string[0].toUpperCase() + string.slice(1).toLowerCase() : '';


module.exports = {
  upperCaseFirstLetter,
  species,
  upperCaseOnlyFirstLetter,
};