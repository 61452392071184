import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(5),
    textAlign: 'center',
  },
  headerText: {
    fontSize: '1.25rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
  },
  planText: {
    color: theme.palette.primary.main,
  },
  featureHeaderText: {
    color: '#6C757A',
  },
  border: {
    borderBottom: '4px solid #EEEEEE',
  },
  borderWithColor: {
    borderBottom: `4px solid rgba(81, 147, 235, 0.2)`,
  },
}));

const FeaturesHeader = ({ plans }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.container} alignItems="center" container>
      <Grid item xs={3} md={6}>
        <Box className={classes.border} width='95%' mx="auto">
          <Typography className={clsx(classes.headerText, classes.featureHeaderText)}>Características</Typography>
        </Box>
      </Grid>
      {plans.map((plan, idx) =>
        <Grid key={idx} item xs={3} md={2}>
          <Box className={classes.borderWithColor} width='90%' mx="auto">
            <Typography className={clsx(classes.headerText, classes.planText)}>{plan}</Typography>
          </Box>
        </Grid>,
      )}
    </Grid>
  );
};

FeaturesHeader.propTypes = {
  plans: PropTypes.array,
};


export { FeaturesHeader };