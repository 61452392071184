import React, { useEffect, useState } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { companiesApi } from 'src/services';
import config from 'src/config/local';
import urlJoin from 'url-join';


const { serverUrl } = config;

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '4.375rem',
    fontWeight: 'bold',
    lineHeight: '90px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.75rem',
      lineHeight: '58px',
    },
  },
  benefitsSectionTitle: {
    paddingBottom: theme.spacing(10),
    color: theme.palette.common.black,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      paddingBottom: theme.spacing(4),
    },
  },

  gridItem: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },

  logoContainer: {
    height: 120,
    width: 220,
    padding: 10,
  },

  logo: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
}));


const ClientsSection = () => {

  const classes = useStyles();

  const [ companyLogos, setCompanyLogos ] = useState([]);

  useEffect(() => {
    const fn = async () => {
      const logos = await companiesApi.getLogos();
      setCompanyLogos(logos);
    };

    fn();
  }, []);

  return (
    <>
      <Grid item className={clsx(classes.gridItem, classes.benefitsSectionTitle)}>
        <Typography className={classes.title}>Confían en Nviro Capture</Typography>
      </Grid>
      <Grid container direction='row' justifyContent='space-around' >
        {
          companyLogos.map(({ id, name, thumbnailLogoPath }) => (
            <Grid item key={ id } className={ classes.logoContainer }>
              <img src={ urlJoin(serverUrl, thumbnailLogoPath) } alt={ `Logo ${name}` } className={ classes.logo } />
            </Grid>
          ))
        }
      </Grid>
    </>
  );
};


export { ClientsSection };
