import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import urlJoin from 'url-join';

import { humanReadableHrMin } from 'src/utils/util';
import { PopupObservation } from 'src/scenes/Campaign/scenes/Monitoring/components/PopupObservation';


const useStyles = makeStyles(() => ({
  body2: {
    margin: '1px 0!important',
    fontSize: '14px',
  },
}));

const MethodologyPopup = ({ campaignHash, methodology, options }) => {

  const { id, name, typeId, radius, spentTime, observation, email, area, samplingStationName, nights } = methodology;
  const { methodologiesTypeOptions } = options;

  const methodologyTypeLabel = methodologiesTypeOptions.find(({ value }) => value === typeId).label;
  const stationNameStr = `${'Estación de muestreo: '}${samplingStationName}`;
  const classes = useStyles();

  return <Fragment>
    <Card elevation={0}>
      <CardContent style={{ padding: '5px' }}>
        <Typography variant="h5">
          {name}
        </Typography>
        <Typography variant="body2" className={ classes.body2 } color="textPrimary">
          {stationNameStr}
        </Typography>
        <Typography variant="body2" className={ classes.body2 } color="textPrimary">
          {email}
        </Typography>
        <Typography style={{ marginTop: '15px', marginBottom: '-10px' }} variant="overline" component="p">
          {methodologyTypeLabel}
        </Typography>
        {area &&
          <Typography style={{ marginTop: '-10px' }} variant="overline" component="p">
            Área: {area} metros²
          </Typography>
        }
        {radius &&
          <Typography style={{ marginTop: '-10px' }} variant="overline" component="p">
            Radio: {radius} metros
          </Typography>
        }
        {spentTime &&
          <Typography style={{ marginTop: '-10px' }} variant="overline" component="p">
            Tiempo: {humanReadableHrMin(spentTime)}
          </Typography>
        }
        {nights &&
          <Typography style={{ marginTop: '-10px' }} variant="overline" component="p">
            Noches: { nights }
          </Typography>
        }
        <PopupObservation observation={observation}/>
        <Button fullWidth target='_blank' variant="outlined" color='primary'
          href={ urlJoin('/web/campaigns', campaignHash, `records?methodology=${id}&page=1`) }
        >
          Ir a registros
        </Button>
      </CardContent>
    </Card>
  </Fragment>;
};

MethodologyPopup.propTypes = {
  campaignHash: PropTypes.string,
  methodology: PropTypes.object,
  options: PropTypes.object,
};


export {
  MethodologyPopup,
};
