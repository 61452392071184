import React, { useState, Fragment } from 'react';
import { DatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, TextField, InputLabel, FormHelperText, Collapse } from '@material-ui/core';

import { Alert } from '@material-ui/lab';
import { SelectWrapper } from 'src/components';
import { startDateErrorMsg, endDateErrorMsg } from 'src/utils/util';
import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';


const { NOT_STARTED, LOADING, SUCCESS, ERROR, BAD_DATA } = fetchStatusEnum;

const CampaignEditDialog = props => {

  const { companyUsersOptions, campaign: prevCampaign, isFan, actions } = props;

  const coordinatorsIds = prevCampaign.data.users.filter(u => u.rol === 'coordinator').map(u => u.userId.toString());

  const [ campaign, setCampaign ] = useState({
    name: prevCampaign.name,
    projectOwner: companyUsersOptions.find(userOption => userOption.isFixed),
    selectedUsers: companyUsersOptions.filter(userOption => coordinatorsIds.includes(userOption.value)),
    startDate: new Date(prevCampaign.startDate),
    endDate: new Date(prevCampaign.endDate),
    objective: prevCampaign.objective,
    observation: prevCampaign.observation,
  });

  const [ fetchStatus, setFetchStatus ] = useState(NOT_STARTED);
  const hasBadData = fetchStatus === BAD_DATA;

  const [ errorAlert, setErrorAlert ] = useState({ isOpen: false, message: '' });

  // quien creó el proyecto, y nadie más, tendrá el isFixed en true
  const isProjectOwnerACoordinator = campaign.selectedUsers.includes(campaign.projectOwner);

  const handleSubmit = async () => {
    try {
      const { name, selectedUsers, startDate, endDate, objective, observation } = campaign;
      setFetchStatus(LOADING);
      if (name && isProjectOwnerACoordinator && startDate && endDate && (startDate <= endDate)) {
        const campaignToEdit = { name, startDate, endDate, coordinatorsIds: selectedUsers.map(user => user.value), objective, observation };
        await props.actions.editCampaign(props.campaign.hash, campaignToEdit);
        setFetchStatus(SUCCESS);
        setTimeout(() => actions.closeDialog(), 300);
      } else {
        setFetchStatus(BAD_DATA);
      }
    } catch (e) {
      console.error(e);
      setErrorAlert({ isOpen: true, message: e.serverMessage || 'Error al intentar editar campaña. Por favor, intente más tarde' });
      setFetchStatus(ERROR);
    }
  };

  const handleChange = e => setCampaign({ ...campaign, [e.target.name]: e.target.value });

  const handleChangeOnSelect = (selectedUsers, option) => {
    if (option.action === 'clear') {
      setCampaign(prevState => ({ ...prevState, selectedUsers: prevState.selectedUsers.filter(opt => opt.isFixed) }));
      return;
    }
    return option.removedValue && option.removedValue.isFixed ? undefined : setCampaign({ ...campaign, selectedUsers });
  };

  const { startDate, endDate, selectedUsers, name, objective, observation } = campaign;

  return (
    <>
      <DialogTitle id="form-dialog-title">Editar campaña</DialogTitle>
      <DialogContent>
        <Box my={2}>
          <InputLabel required shrink>Nombre</InputLabel>
          <TextField error={hasBadData && !name} name="name" value={name} onChange={handleChange} fullWidth autoFocus
            required autoComplete="off" variant="outlined" size="small" placeholder="Ej: Campaña primavera 2022" />
          {hasBadData && !name && <FormHelperText error>Debes ingresar un nombre para la campaña</FormHelperText>}
        </Box>
        {!isFan &&
          <Box my={2}>
            <InputLabel shrink error={hasBadData && selectedUsers.length === 0}>Coordinador(es)</InputLabel>
            <SelectWrapper isMulti fixedList options={companyUsersOptions} className="basic-multi-select"
              value={selectedUsers} classNamePrefix="select" onChange={handleChangeOnSelect}
              placeholder={'Seleccione coordinador(es)'} noOptionsMessage={() => 'No hay más usuarios'}
            />
            {hasBadData && !isProjectOwnerACoordinator &&
              <FormHelperText error>El responsable del proyecto debe ser coordinador en la campaña</FormHelperText>
            }
          </Box>
        }
        <Box my={2}>
          <InputLabel shrink error={hasBadData && !startDate}>Fecha de inicio</InputLabel>
          <DatePicker value={startDate} selected={startDate} autoOk
            TextFieldComponent={props => <TextField {...props} variant="outlined" size="small" />}
            onChange={startDate => setCampaign({ ...campaign, startDate })} format="DD-MM-YYYY"
            helperText={startDateErrorMsg({ submitting: hasBadData, startDate })}
            error={hasBadData && !startDate} />
        </Box>
        <Box my={2}>
          <InputLabel shrink error={hasBadData && (!endDate || (endDate && (endDate < startDate)))}>Fecha de término</InputLabel>
          <DatePicker value={endDate} autoOk selected={endDate}
            onChange={endDate => setCampaign({ ...campaign, endDate })} format="DD-MM-YYYY" minDate={startDate}
            TextFieldComponent={props => <TextField {...props} variant="outlined" size="small" />}
            helperText={endDateErrorMsg({ submitting: hasBadData, startDate, endDate })}
            error={(hasBadData && !endDate) || (endDate && (endDate < startDate))} />
        </Box>
        <Box my={2}>
          <InputLabel shrink>Objetivos (opcional)</InputLabel>
          <TextField
            name="objective" value={objective} variant="outlined" size="small"
            minRows="2" maxRows='5' multiline fullWidth autoComplete="off"
            placeholder="Ej: Objetivos de la campaña" onChange={handleChange}
          />
        </Box>
        <Box my={2}>
          <InputLabel shrink>Comentarios adicionales (opcional)</InputLabel>
          <TextField
            name="observation" value={observation} variant="outlined" size="small"
            minRows="2" maxRows='5' multiline fullWidth autoComplete="off"
            placeholder="Ej: Comentarios adicionales de la campaña" onChange={handleChange}
          />
        </Box>

        {fetchStatus === ERROR &&
            <Collapse in={errorAlert.isOpen}>
              <Alert severity="error" onClose={() => setErrorAlert(s => ({ ...s, isOpen: false }))}>
                {errorAlert.message}
              </Alert>
            </Collapse>
        }

      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog} color="secondary">Cancelar</Button>
        <Button onClick={handleSubmit} color="primary">Guardar</Button>
      </DialogActions>
    </>
  );
};

CampaignEditDialog.propTypes = {
  companyUsersOptions: PropTypes.array.isRequired,
  campaign: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  isFan: PropTypes.bool.isRequired,
};


export {
  CampaignEditDialog,
};
