import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Grid, Box, Paper, Typography, CircularProgress } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import { usersApi } from 'src/services';
import { NviroButton } from 'src/components';


const useStyles = makeStyles(theme => ({
  errorLabel: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  errorMsg: {
    color: '#6c6c6c',
  },
  successMsg: {
    color: theme.palette.success.main,
    textTransform: 'uppercase',
  },
  container: {
    height: '100vh',
    paddingBottom: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      paddingBottom: theme.spacing(0),
    },
  },
  form: {
    width: '320px',
  },
  loadIcon: {
    color: theme.palette.common.primary,
    marginTop: '1em',
  },
  grid: {
    zIndex: 0,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 'inherit',
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
  },
  paper: {
    width: '344px',
    minHeight: '6rem',
    marginTop: theme.spacing(4),
    paddingRight: theme.spacing(10),
    paddingLeft: theme.spacing(10),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: 'unset',
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(3),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
    },
  },
  title: {
    color: theme.palette.common.gray600,
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '1.5rem',
    textAlign: 'center',
    lineHeight: '1.3',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3rem',
    },
  },
  logo: {
    marginTop: theme.spacing(20) * -1,
    cursor: 'pointer',
    height: '80px',
    [theme.breakpoints.down('sm')]: {
      height: '72px',
      marginTop: theme.spacing(25) * -1,
    },
  },
  buttonContainer: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));

const EmailVerificationContainer = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { token } = useParams();

  const [ tokenValidity, setTokenValidity ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  const [ submitting, setSubmitting ] = useState(false);
  const [ errorMsg, setErrorMsg ] = useState('');
  const [ successMsg, setSuccessMsg ] = useState('');

  useEffect(() => {
    document.title = 'Validación mail - Nviro Capture';
    const fetchData = async () => {
      try {
        await usersApi.getVerificationTokenValidity(token);
        setTokenValidity(true);
      } catch (e) {
        setErrorMsg(e.serverMessage);
        setTokenValidity(false);
      }
      setLoading(false);
    };
    fetchData();

    // eslint-disable-next-line
  }, []);

  const handleSubmit = async e => {
    if (submitting || successMsg || errorMsg) {
      return;
    }
    try {
      e.preventDefault();
      setSubmitting(true);
      const { message } = await usersApi.verifyEmail({ token });
      setSuccessMsg(message);
    } catch (e) {
      if (e.serverMessage) {
        setErrorMsg(e.serverMessage);
      } else {
        console.error(e);
        setErrorMsg('Error al intentar reenviar enlace. Por favor, intente más tarde');
      }
    }
    setSubmitting(false);
  };

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.container}>
      <Grid item className={classes.grid}>
        <Link to='/'>
          <img className={classes.logo} src={theme.logo} alt="Logo de Nviro: link a home del sitio web" />
        </Link>
      </Grid>
      <Grid item className={classes.grid}>
        <Paper className={classes.paper} elevation={5}>
          <Box display="flex" flexDirection="column" alignItems="center">
            { loading ?
              <>
                <Typography variant="h6" color="primary">Cargando, por favor espere...</Typography>
                <CircularProgress size={25} thickness={4.8} className={classes.loadIcon} />
              </>
              : <>
                <Box mt={3}>
                  { tokenValidity ?
                    <Typography variant="h6" className={classes.title} color="primary">
                      Presione el botón para verificar su email
                    </Typography>
                    : <Typography variant="h6" className={classes.title} color="secondary">{ errorMsg }</Typography>
                  }
                </Box>
                { tokenValidity &&
                  <form className={classes.form} onSubmit={handleSubmit}>
                    <Box className={classes.buttonContainer}>
                      <NviroButton loading={ submitting } success={ Boolean(successMsg) }
                        color="primary" type="submit" variant="contained"
                      >
                        Verificar Email
                      </NviroButton>
                    </Box>
                    <Box my={3}>
                      { errorMsg && !successMsg &&
                        <Box display="flex" flexDirection="column" alignItems="center" >
                          <Typography variant="body1" className={classes.errorLabel}>Ha ocurrido un error</Typography>
                          <Typography variant="body1" className={classes.errorMsg}>{errorMsg}</Typography>
                        </Box>
                      }
                      { successMsg && <>
                        <Box display="flex" flexDirection="column" alignItems="center" >
                          <Typography variant="body1" className={classes.successMsg}>{successMsg}</Typography>
                        </Box>
                        <Box mt={1} display="flex" flexDirection="column" alignItems="center" >
                          <Link to='/web'><Typography variant="body2">Ir a Nviro Web</Typography></Link>
                        </Box>
                      </>}
                    </Box>
                  </form>
                }
              </>
            }
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};


export {
  EmailVerificationContainer,
};
