import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { Grid, Box, Fade } from '@material-ui/core';
import { useRouteMatch, useHistory, useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { PostAdd } from '@material-ui/icons';

import { TitleContainer, ContentContainer, DialogWrap, DialogImagePreviewer,
  SpeciesQuestionDialog, FilterSelect, FilterText, Collapser, ActionsBox } from 'src/components';
import { RecordsList, RecordDeleteDialog, RecordWasEditedDialog, RecordCreateButton, RecordWasDeletedDialog,
  PointCard, FaunaRecordEditDialog, FaunaRecordCreateDialog, FloraRecordEditDialog, FloraRecordCreateDialog,
} from 'src/scenes/Campaign/scenes/Records/components';

import { DirectIndividualEditDialog, DirectIndividualDeleteDialog, DirectIndividualCreateDialog, ForestTreeEditDialog,
  ForestTreeCreateDialog, ForestTreeDeleteDialog } from 'src/scenes/Campaign/scenes/Records/components/RecordDetail';
import { faunaApi, floraApi, speciesApi, forestApi, pointsApi, campaignsApi, usersApi } from 'src/services';
import { WebContext } from 'src/scenes/web-context';
import { upperCaseFirstLetter } from 'src/utils/formatters';
import { createForm, objectIsEmpty, enumsToOptions } from 'src/utils/util';
import { cardinalPointsEnum, floraCoverageEnum, faunaRecordsTypesEnum, floraRecordsTypesEnum } from 'src/utils/enums';
import { recordTypeMap } from 'src/utils/recordTypeMap';
import { isTransect, isPlot, isStation, isPointQuadrat, isFauna, isFlora, isCensus } from 'src/utils/checkers';
import { createRecord } from 'src/scenes/Campaign/campaignHelper';


const recordTypeFilterKey = 'recordType';
const pointFilterKey = 'point';
const userFilterKey = 'user';
const genusFilterKey = 'genus';
const speciesFilterKey = 'species';
const sourceFilterKey = 'source';
const reviewFilterKey = 'review';
const methodologyFilterKey = 'methodology';
const methodologyTypeFilterKey = 'methodologyType';

const sourcesOptions = {
  flora: [
    { value: 'official', label: 'Conocida' },
    { value: 'nviro-flora', label: 'Sin reconocer' },
  ],
  fauna: [
    { value: 'official', label: 'Conocida' },
    { value: 'nviro-fauna', label: 'Sin reconocer' },
  ],
};

const editionDataMaker = ({ recordToUpdate, renameAll }, recordType) => [
  { name: 'Nombre de especie', value: upperCaseFirstLetter(recordToUpdate.scientificName) },
  ...renameAll !== undefined ? [ { name: '¿Renombrar todos los registros?', value: renameAll ? 'Sí' : 'No' } ] : [],
  ...recordType !== 'forest-record' ? [ { name: 'Observación', value: recordToUpdate.observation } ] : [],
  ...recordType === 'flora-record' ? [ { name: 'Cobertura', value: recordToUpdate.coverage } ] : [],
];

const RecordsContainer = props => {
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const tableRef = useRef();
  const context = useContext(WebContext);
  const { campaignHash } = match.params;
  const { id: campaignId, componentId, mainMethodId: campaignSubtypeId } = context.selectedCampaign;
  const isCensusCampaign = isCensus(campaignSubtypeId);
  const isFaunaCampaign = componentId === 'fauna';


  const [ dialog, setDialog ] = useState({ isOpen: false, data: {}, type: '' });
  const [ filters, setFilters ] = useState({});
  const [ filtersLabels, setFiltersLabels ] = useState();

  const showUsersFilter = (context.checkIfAdminPowers() || context.checkIfCoordinatesCampaign()) && !context.isFan();

  const getQueryParams = () => {
    const query = new URLSearchParams(location.search);

    const page = parseInt(query.get('page') || 1, 10);
    const size = parseInt(query.get('size') || 1, 10);

    const recordType = query.getAll(recordTypeFilterKey).length ? query.getAll(recordTypeFilterKey) : [];
    const point = query.get(pointFilterKey);
    const user = query.getAll(userFilterKey).length ? query.getAll(userFilterKey) : [];
    const genus = query.get(genusFilterKey);
    const species = query.get(speciesFilterKey);
    const source = query.getAll(sourceFilterKey).length ? query.getAll(sourceFilterKey) : [];
    const isActiveReviewPoint = Boolean(query.get(reviewFilterKey)) && Boolean(point) && !isCensusCampaign;
    const methodology = query.get(methodologyFilterKey);
    const methodologyType = query.getAll(methodologyTypeFilterKey).length ? query.getAll(methodologyTypeFilterKey) : [];

    return { page, size, ...(isCensusCampaign ? {} : { recordType, point, isActiveReviewPoint }), user, source, genus, species,
      ...(isFaunaCampaign ? { methodology, methodologyType } : {}) };
  };

  const { methodology, point, isActiveReviewPoint, genus, species } = getQueryParams();

  const handleEditionConflict = ({ recordToUpdate, renameAll, initialState, errorData, actionName }, recordType) => {
    // if they were editing, save the values of that edition so they aren't lost
    const editionData = recordToUpdate ? editionDataMaker({ recordToUpdate, renameAll }, recordType) : undefined;
    const newInitial = { ...initialState };
    const conflictData = [];

    const { speciesHash: newSpeciesHash, coverage: newCoverage, observation: newObservation } = errorData.currentData;

    // what's new in the database compared to what this person saw?
    if (initialState.speciesHash !== newSpeciesHash) {
      conflictData.push({
        name: 'Nombre de especie', value: upperCaseFirstLetter(errorData.currentData.scientificName),
      });
      newInitial.speciesHash = errorData.currentData.speciesHash;
      newInitial.scientificName = errorData.currentData.scientificName;
    }
    if (newObservation !== undefined && initialState.observation !== newObservation) {
      conflictData.push({ name: 'Observación', value: newObservation });
      newInitial.observation = newObservation;
    }
    if (newCoverage !== undefined && initialState.coverage !== newCoverage) {
      conflictData.push({ name: 'Cobertura', value: newCoverage });
      newInitial.coverage = newCoverage;
    }

    const redoAction = recordToUpdate ? () => updateRecord({ recordToUpdate, renameAll, initialState: newInitial }, recordType)
      : () => deleteRecord({ record: newInitial }, recordType);

    const dialogData = {
      conflictData,
      actionName,
      editionData,
      redoAction,
    };

    setDialog({ isOpen: true, data: dialogData, type: 'conflict-edited' });
  };

  const handleDeletionConflict = ({ recordToUpdate, renameAll, actionName }, recordType) => {
    const dialogData = {
      conflictType: 'deleted',
      actionName,
      editionData: recordToUpdate ? editionDataMaker({ recordToUpdate, renameAll }, recordType) : undefined,
      refreshAction: () => {
        // if the record died, there's nothing else to do, so just refresh and close the dialog
        tableRef.current && tableRef.current.onQueryChange({ page: 0 });
        closeDialog();
      },
    };

    setDialog({ isOpen: true, data: dialogData, type: 'conflict-deleted' });
  };

  const updateRecord = async ({ recordToUpdate, renameAll, initialState }, recordType) => {
    try {
      const recordTypeUtils = recordTypeMap[recordType];
      const isInvalidRecord = recordToUpdate.isInvalid;
      if (!isInvalidRecord) {
        const backRecordType = recordTypeUtils?.backendName;

        const boundedInitialState = {};
        initialState && recordTypeUtils.updatableProps.forEach(prop => boundedInitialState[prop] = initialState[prop]);

        const dataToUpdate = {
          recordType: backRecordType,
          renameAll,
          campaignHash,
          ...(initialState ? { initialState: boundedInitialState } : {}),
        };
        recordTypeUtils.updatableProps.forEach(prop => dataToUpdate[prop] = recordToUpdate[prop]);
        await recordTypeUtils.update({
          recordId: recordToUpdate.recordId,
          updatedData: { ...dataToUpdate, speciesName: recordToUpdate.speciesName },
        });
        tableRef.current && tableRef.current.onQueryChange();
      }
    } catch (e) {
      if (e.httpStatus === 409) {
        handleEditionConflict({ recordToUpdate, renameAll, initialState, errorData: e.data, actionName: 'editar registro' }, recordType);
        return;
      } else if (e.httpStatus === 410) {
        handleDeletionConflict({ recordToUpdate, actionName: 'editar registro' }, recordType);
        return;
      }
      throw e;
    }
  };

  const deleteRecord = async ({ record }, recordType) => {
    try {
      const recordTypeUtils = recordTypeMap[recordType];

      const backRecordType = recordTypeUtils?.backendName;

      const initialState = {};
      recordTypeUtils.updatableProps.forEach(prop => initialState[prop] = record[prop]);

      const updatedData = {
        toDelete: true,
        recordType: backRecordType,
        campaignHash,
        ...(isPlot(campaignSubtypeId) ? { initialState } : {}), // TODO: para fauna y transecta cuando haya solucionador de conflictos
      };

      await recordTypeUtils.update({ recordId: record.recordId, updatedData });
      tableRef.current && tableRef.current.onQueryChange();
    } catch (e) {
      if (e.httpStatus === 409) {
        handleEditionConflict({ initialState: record, errorData: e.data, actionName: 'borrar registro' }, recordType);
        return;
      } else if (e.httpStatus === 410) {
        handleDeletionConflict({ actionName: 'borrar registro' }, recordType);
        return;
      }
      throw e;
    }
  };

  const createTree = async ({ pointId, newTree, forestRecordHash }) => {
    const data = { recordType: 'forest', pointId, forestRecordHash, ...newTree, detectionDate: newTree.detectionDate.toISOString() };
    const newTreeForm = createForm(data);
    await forestApi.createTree(newTreeForm);
    tableRef.current && tableRef.current.onQueryChange();
  };

  const updateTree = async dataToUpdate => {
    await forestApi.updateTree({ ...dataToUpdate, campaignHash });
    tableRef.current && tableRef.current.onQueryChange();
  };

  const deleteTree = async ({ treeId }) => {
    await forestApi.deleteTree({ toDelete: true, treeId });
    tableRef.current && tableRef.current.onQueryChange();
  };

  const createIndividual = async ({ newIndividual, directRecordId }) => {
    const data = { recordType: 'direct', directRecordId, ...newIndividual };
    const newIndividualForm = createForm(data);
    await faunaApi.createIndividual(newIndividualForm);
    tableRef.current && tableRef.current.onQueryChange();
  };

  const updateIndividual = async individualToUpdate => {
    await faunaApi.updateIndividual(individualToUpdate);
    tableRef.current && tableRef.current.onQueryChange();
  };

  const deleteFaunaIndividual = async individualToDelete => {
    await faunaApi.deleteIndividual(individualToDelete);
    tableRef.current && tableRef.current.onQueryChange();
    closeDialog();
  };


  const askAQuestion = async (recordHash, recordType) => {
    await speciesApi.askAFloraQuestion(recordHash, recordTypeMap[recordType].backendName);
    closeDialog();
  };

  const addFiltersToQuery = (query, filters) => {
    Object.keys(filters).forEach(key => {
      if (Array.isArray(filters[key])) {
        query.delete(key);
        filters[key].forEach(val => query.append(key, val));
      } else if (typeof filters[key] === 'boolean') {
        filters[key] ? query.set(key, filters[key]) : query.delete(key);
      } else if (filters[key] !== null) {
        query.set(key, filters[key]);
      }
    });
  };

  const setFilter = async ({ id, value, label = '' }) => {
    const queryForPath = new URLSearchParams(location.search);

    const filter = { [id]: value };
    // value es un arreglo vacío por defecto, pero no es arreglo cuando tiene valores??
    // ... TODO: considerar usar algo más natural como null, undefined o incluso false? sirve de algo que sea un arreglo?
    if (id === pointFilterKey && value?.length === 0) {
      // puede que se le está quitando el filtro a la vista de review: hay que quitar el review=true
      // TODO: considerar usar otra funcion para matar filtro de point+review que te envie a parcelas/estaciones en vez de registros?
      if (queryForPath.get('review')) {
        props.setIsDrawerOpen(true);
        queryForPath.delete('review');
      }
    }
    const filtersObj = { ...filter };
    const tablePage = 0;
    addFiltersToQuery(queryForPath, filtersObj);
    setFilters(prev => ({ ...prev, ...filter }));
    label && setFiltersLabels(prev => ({ ...prev, [id]: label }));

    const queryForPathString = queryForPath.toString();
    history.push({ pathname: location.pathname, search: `?${queryForPathString}` });
    tableRef.current && tableRef.current.onQueryChange({ page: tablePage });
  };

  // like setFiler, but multiple stuff. Filters is an array of { id, value, label }.
  const setQueryFilters = async (filters, replace = false) => {
    const idValues = {};
    const labelsObj = {};
    filters.forEach(filt => {
      idValues[filt.id] = filt.value;
      if (filt.label) {
        labelsObj[filt.id] = filt.label;
      }
    });

    if (!idValues[pointFilterKey] && idValues.review) {
      // You shouldn't have review set without also setting a point
      delete idValues.review;
    }

    let queryString;
    if (replace) {
      const newQueryObj = new URLSearchParams('');

      addFiltersToQuery(newQueryObj, { ...idValues });
      setFilters(idValues);
      setFiltersLabels(labelsObj);

      queryString = newQueryObj.toString();
    } else {
      const currentQueryObj = new URLSearchParams(location.search);

      addFiltersToQuery(currentQueryObj, { ...idValues });
      setFilters(prev => ({ ...prev, ...idValues }));
      setFiltersLabels(prev => ({ ...prev, ...labelsObj }));

      queryString = currentQueryObj.toString();
    }

    await history.push({ pathname: location.pathname, search: `?${queryString}` });

    const tablePage = idValues['page'] !== undefined ? idValues['page'] - 1 : 0;
    tableRef.current && tableRef.current.onQueryChange({ page: tablePage });
  };

  const closeDialog = () => setDialog({ isOpen: false, type: '' });
  const toggleDialog = ({ data = {}, type = '' } = {}) => setDialog({ isOpen: !dialog.isOpen, data, type });

  const getDialogType = () => {
    switch (dialog.type) {
      case 'create-flora-record': {
        return <FloraRecordCreateDialog
          actions={{ createRecord: createRecord({ campaignId, tableRef }), closeDialog }}
          options={{
            ...options,
            recordsTypesOptions: [ { label: 'Registro individual', value: 'flora-individual-record' } ],
          }}
          getOptions={{ getSpeciesOptions: getOptions('scientificName') }}
          ctLang={context.campaignSubtypeLanguage}
          campaignSubtypeId={campaignSubtypeId}
        />;
      }
      case 'create-fauna-record': {
        return <FaunaRecordCreateDialog
          actions={{ createRecord: createRecord({ campaignId, tableRef }), closeDialog }}
          options={{
            ...options,
            recordsTypesOptions: [ { label: 'Registro aislado', value: 'fauna-isolated-record' } ],
          }}
          getOptions={{
            getSpeciesOptions: getOptions('scientificName'),
            // getMethodologiesOptions: getOptions(methodologyFilterKey),
            // getPointsOptions: getOptions(pointFilterKey),
          }}
          ctLang={context.campaignSubtypeLanguage}
        />;
      }
      case 'edit-flora-record': {
        const { type: recordType } = dialog.data;
        return <FloraRecordEditDialog
          actions={{ updateRecord: data => updateRecord(data, recordType), closeDialog }}
          currentUser={context.currentUser}
          prevRecord={dialog.data}
          options={options}
          getOptions={{ getSpeciesOptions: getOptions('scientificName') }}
        />;
      }
      case 'edit-fauna-record': {
        return <FaunaRecordEditDialog
          actions={{ updateRecord, closeDialog }}
          currentUser={context.currentUser}
          prevRecord={dialog.data}
          options={options}
          getOptions={{ getSpeciesOptions: getOptions('scientificName') }}
        />;
      }
      case 'create-tree': {
        return <ForestTreeCreateDialog
          actions={{ createTree: newTree => createTree({
            forestRecordHash: dialog.data.hash,
            pointId: dialog.data.plotId,
            newTree,
          }), closeDialog }}
          options={options}
        />;
      }
      case 'edit-forest-detail': {
        return <ForestTreeEditDialog
          prevForestData={dialog.data}
          actions={{ updateTree, closeDialog }}
          options={options}
        />;
      }
      case 'delete-tree':
        return <ForestTreeDeleteDialog tree={dialog.data}
          actions={{ deleteTree: () => deleteTree({ treeId: dialog.data.id }), closeDialog }}
        />;
      case 'create-individual':
        return <DirectIndividualCreateDialog
          actions={{ createIndividual: newIndividual => createIndividual({
            directRecordId: dialog.data.recordId,
            newIndividual,
          }), closeDialog }}
          options={options}
        />;
      case 'edit-individual':
        return <DirectIndividualEditDialog
          prevIndividual={dialog.data}
          actions={{ updateIndividual, closeDialog }}
          options={options}
        />;
      case 'delete-individual':
        return <DirectIndividualDeleteDialog individual={ dialog.data }
          actions={{ deleteIndividual: data => deleteFaunaIndividual(data.individual, dialog.data.type), toggleDialog: closeDialog }}
        />;
      case 'photo':
        return <DialogImagePreviewer
          pictures={dialog.data.pictures}
          title={upperCaseFirstLetter(dialog.data.title)}
          additionalClass='image-species-container'
        />;
      // TODO: implementar consulta a la comunidad y restaurar el botón en recordsList
      case 'ask-a-question': {
        const { speciesName, recordHash, type: recordType } = dialog.data;
        return <SpeciesQuestionDialog speciesName={ speciesName }
          actions={ { toggleDialog: closeDialog, askAQuestion: () => askAQuestion(recordHash, recordType) } } />;
      }
      case 'delete':
        return <RecordDeleteDialog record={ dialog.data } recordType={ dialog.data.type }
          actions={{ deleteRecord: data => deleteRecord(data, dialog.data.type), closeDialog }}
        />;
      case 'conflict-deleted':
        return <RecordWasDeletedDialog editionData={ dialog.data.editionData }
          refreshAction={ dialog.data.refreshAction } closeDialog={ closeDialog }
        />;
      case 'conflict-edited':
        return <RecordWasEditedDialog conflictData={ dialog.data.conflictData } editionData={ dialog.data.editionData }
          actionName={ dialog.data.actionName } redoAction={ dialog.data.redoAction } closeDialog={ closeDialog }
        />;
      default:
        break;
    }
  };


  const getRecords = async ({ page, size }) => {
    const query = new URLSearchParams(location.search);
    query.set('campaignHash', campaignHash);
    query.set('page', page);
    query.set('size', size);
    return await campaignsApi.getRecords(query.toString());
  };

  const [ pointsList, setPointsList ] = useState([]);
  const [ selectedPointIndex, setSelectedPointIndex ] = useState();
  const [ pointStatusesOptions, setPointStatusesOptions ] = useState([]);

  const [ options, setOptions ] = useState({
    recordsTypesOptions: enumsToOptions(
      isPlot(campaignSubtypeId) ? floraRecordsTypesEnum
      : isStation(campaignSubtypeId) ? faunaRecordsTypesEnum
      : isTransect(campaignSubtypeId) ? {}
      : {},
    ),
    sexTypesOptions: [],
    ageStratumsOptions: [],
    indirectRecordsTypesOptions: [],
    methodologiesTypesOptions: [],
    cardinalPointsOptions: Object.keys(cardinalPointsEnum).map(key => ({ label: cardinalPointsEnum[key], value: key })),
    flightTypes: [],
    phenologiesStatusesOptions: [],
    phytosanitaryStatusesOptions: [],
    coverageOptions: [],
    campaignUsersOptions: [],
  });

  const changePointTo = async position => {
    const newPointIndex = (
      position === 'next' ? selectedPointIndex + 1
      : position === 'prev' ? selectedPointIndex - 1
      : selectedPointIndex
    );

    const pointsNumber = pointsList.length;
    const pointIndex = (newPointIndex + pointsNumber) % pointsNumber;
    setSelectedPointIndex(pointIndex);

    const pointId = pointsList[pointIndex].id;
    const pointName = pointsList[pointIndex].name;
    const modifiedFilters = [
      { id: 'review', value: true }, { id: pointFilterKey, value: pointId, label: pointName },
      { id: 'page', value: 1 },
    ];
    setQueryFilters(modifiedFilters);
  };

  const reviewPoint = async () => {
    const pointId = point;
    await pointsApi.updatePoints({ campaignId, idsToUpdate: [ pointId ], reviewed: true });
    await props.checkCompletion();
    setPointsList(prevPoints => prevPoints.map(p => pointId === p.id ? { ...p, reviewed: true } : p));
  };

  const transformToOptions = ({ searchResult, capitalize }) =>
    searchResult.map(r => ({
      ...r,
      label: capitalize ? upperCaseFirstLetter(r.name || r.scientificName) : r.name,
      value: r.id,
    }));

  const getOptions = key => async ({ queryText, ...filters }) => {
    const searchApi = {
      point: pointsApi.search,
      methodology: faunaApi.search,
      scientificName: isStation(campaignSubtypeId) ? speciesApi.searchInFauna : speciesApi.searchInFlora,
      genus: ({ queryText }) => speciesApi.searchGenuses({ queryText, campaignId, excludeLost: false, onlyOfficial: false }),
      species: ({ queryText }) => speciesApi.searchSpecies({ queryText, genus, campaignId, excludeLost: false, onlyOfficial: false }),
    };

    const searchResult = await searchApi[key]({ queryText, campaignId, ...filters });
    return transformToOptions({ searchResult, capitalize: key !== 'species' });
  };

  useEffect(() => {
    if (isActiveReviewPoint) {
      props.setIsDrawerOpen(false);
      const pointId = point;
      const fetchData = async () => {
        const [ pointsList, pointStatusesOptions ] = await Promise.all([
          pointsApi.getPoints({ campaignHash }),
          !isTransect(campaignSubtypeId) ? pointsApi.getPointStatuses(campaignSubtypeId) : [],
        ]);
        setPointsList(pointsList);
        const pointIndex = pointsList.findIndex(p => p.id === pointId);
        const selectedPointIndex = pointIndex === -1 ? undefined : pointIndex;
        setSelectedPointIndex(selectedPointIndex);
        setPointStatusesOptions(pointStatusesOptions);
      };
      fetchData();
    }

    const fetchUsers = async () => {
      const campaignUsers = showUsersFilter ? await campaignsApi.getUsers(campaignHash) : [];
      const campaignUsersOptions = campaignUsers.map(user => ({ value: user.id, label: user.email }));
      setOptions(po => ({ ...po, campaignUsersOptions }));
    };
    fetchUsers();

    if (isFauna(campaignSubtypeId)) {
      const fetchOptions = async () => {
        const [ sexTypesOptions, ageStratumsOptions, indirectRecordsTypesOptions, methodologiesTypesOptions,
          flightTypes ] = await Promise.all([
          faunaApi.getSexTypes(),
          faunaApi.getAgeStratums(),
          faunaApi.getIndirectRecordTypes(),
          faunaApi.getMethodologiesTypes(),
          faunaApi.getFlightTypes(),
        ]);
        setOptions(po => ({ ...po, sexTypesOptions, ageStratumsOptions, indirectRecordsTypesOptions, methodologiesTypesOptions,
          flightTypes }));
      };
      fetchOptions();
    }
    if (isFlora(campaignSubtypeId)) {
      if (isCensus(campaignSubtypeId)) {
        setOptions(po => ({
          ...po,
          coverageOptions: enumsToOptions(floraCoverageEnum),
          ...context.censusFields.reduce((acc, fieldDef) => ({
            ...acc,
            ...(fieldDef.options ? { [`${fieldDef.id}Options`]: fieldDef.options } : {}),
          }), {}),
        }));
      } else {
        const fetchOptions = async () => {
          const [ phenologiesStatusesOptions, phytosanitaryStatusesOptions, stratums ] = await Promise.all([
            floraApi.getPhenologiesStatuses(),
            floraApi.getPhytosanitaryStatuses(),
            isPointQuadrat(campaignSubtypeId) ? campaignsApi.getStratums(campaignId) : [],
          ]);
          const stratumsOptions = stratums.map(({ name, id, ...rest }) => ({ label: name, value: id, data: rest }));
          setOptions(po => ({
            ...po,
            phenologiesStatusesOptions,
            phytosanitaryStatusesOptions,
            stratumsOptions,
            coverageOptions: isPlot(campaignSubtypeId) ? enumsToOptions(floraCoverageEnum) : [],
          }));
        };
        fetchOptions();
      }
    }
    // eslint-disable-next-line
  }, [ campaignHash ]);

  useEffect(() => {
    const fetchData = async () => {
      const { recordType, point, user, source, genus, species, methodologyType, isActiveReviewPoint } = getQueryParams();
      if (isCensus(campaignSubtypeId)) {
        setFilters({ user, source, genus, species });
        return;
      }
      const filtersThatNeedLabels = { point, methodology };
      Object.keys(filtersThatNeedLabels).forEach(key => filtersThatNeedLabels[key] == null ? delete filtersThatNeedLabels[key] : {});
      // TODO: las "labels" (nombre de punto) no puede sacarlo de pointsList? quizás sería mejor correr esto con un useEffect que
      // dependa de un "loadingData" o algo para esperar que haya pointsList y sacar las labels de ahí?
      const filtersLabels = objectIsEmpty(filtersThatNeedLabels) ?
        {} : await usersApi.getLabels({ campaignSubtypeId, ...filtersThatNeedLabels });
      setFilters({ recordType, point, user, source, methodologyType, genus, species, isActiveReviewPoint });
      setFiltersLabels(filtersLabels);
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const censusColumns = useMemo(() => {
    if (!isCensus(campaignSubtypeId)) {
      return [];
    }

    const usedFieldsSet = new Set();
    context.selectedCampaign.settings.fields.forEach(field => usedFieldsSet.add(field.id));

    return context.censusFields.filter(cf => usedFieldsSet.has(cf.id));
    // eslint-disable-next-line -- campaignId implica cambios en todas las otras cosas relevantes de la campaña (mientras no sean editables)
  }, [ context.censusFields, campaignId ]);

  const title = isActiveReviewPoint ? `Revisión de ${context.campaignSubtypeLanguage.points}` : 'Registros';
  const selectedPoint = pointsList[selectedPointIndex];
  const [ fadeIn, setFadeIn ] = useState(true);
  const onArrowClick = position => {
    setFadeIn(false);
    setTimeout(() => {
      changePointTo(position);
      setFadeIn(true);
    }, 300);
  };

  const { sexTypesOptions, ageStratumsOptions } = options;

  return (
    <Grid container>
      <TitleContainer maxWidth="xl" breadcrumbs={[ 'company', 'campaigns', { name: title } ]}>
        {title}
      </TitleContainer>
      <ContentContainer maxWidth="xl">
        <Box my={1}>
          <Collapser title={'Filtros'}>
            <ActionsBox display="flex">
              {(filtersLabels || (isCensusCampaign && filters[sourceFilterKey])) &&
                <>
                  <Grid container spacing={1}>
                    <Grid item>
                      <FilterText
                        id={genusFilterKey}
                        label="Género"
                        prevValue={{
                          label: Array.isArray(filters[genusFilterKey]) ? '' : upperCaseFirstLetter(filters[genusFilterKey] || ''),
                          value: genus,
                        }}
                        getOptions={getOptions(genusFilterKey)}
                        onChange={setFilter}
                        showComponent={ false }
                      />
                    </Grid>
                    <Grid item>
                      <FilterText
                        id={speciesFilterKey}
                        label="Especie"
                        prevValue={{ label: filters[speciesFilterKey], value: species }}
                        getOptions={getOptions(speciesFilterKey)}
                        onChange={setFilter}
                        showComponent={ false }
                      />
                    </Grid>
                    { !isCensusCampaign &&
                      <Grid item>
                        <FilterText
                          id={pointFilterKey}
                          label={context.campaignSubtypeLanguage.Point}
                          prevValue={{ label: filtersLabels[pointFilterKey], value: point }}
                          getOptions={getOptions(pointFilterKey)}
                          onChange={setFilter}
                        />
                      </Grid>
                    }
                    {showUsersFilter &&
                      <Grid item>
                        <FilterSelect
                          id={userFilterKey}
                          toUpperFirst={false}
                          label="Capturada por"
                          prevValue={filters[userFilterKey]}
                          options={options.campaignUsersOptions}
                          onChange={setFilter}
                        />
                      </Grid>
                    }
                    { isFaunaCampaign &&
                      <>
                        <Grid item>
                          <FilterText
                            id={methodologyFilterKey}
                            label="Metodología"
                            prevValue={{ label: filtersLabels[methodologyFilterKey], value: methodology }}
                            getOptions={getOptions(methodologyFilterKey)}
                            onChange={setFilter}
                          />
                        </Grid>
                        <Grid item>
                          <FilterSelect
                            id={methodologyTypeFilterKey}
                            label="Tipo de metodología"
                            prevValue={filters[methodologyTypeFilterKey]}
                            options={options.methodologiesTypesOptions}
                            onChange={setFilter}
                          />
                        </Grid>
                      </>
                    }
                    { !isTransect(campaignSubtypeId) && !isCensusCampaign && <Grid item>
                      <FilterSelect
                        id={recordTypeFilterKey}
                        label="Tipo de registro"
                        prevValue={filters[recordTypeFilterKey]}
                        options={options.recordsTypesOptions}
                        onChange={setFilter}
                      />
                    </Grid>}
                    <Grid item>
                      <FilterSelect
                        id={sourceFilterKey}
                        label="Fuente"
                        prevValue={filters[sourceFilterKey]}
                        options={sourcesOptions[componentId]}
                        onChange={setFilter}
                      />
                    </Grid>
                  </Grid>
                </>
              }
              {!isActiveReviewPoint && !isTransect(campaignSubtypeId) && !isCensus(campaignSubtypeId) &&
                <Box>
                  <RecordCreateButton
                    size="small"
                    startIcon={<PostAdd />}
                    variant="contained"
                    color="primary"
                    onClick={() => toggleDialog({ type: `create-${isPlot(campaignSubtypeId) ? 'flora' : 'fauna'}-record` })}
                    disabled={isFaunaCampaign && (!sexTypesOptions.length || !ageStratumsOptions.length)}
                  >
                    Crear registro {context.campaignSubtypeLanguage.single}
                  </RecordCreateButton>
                </Box>
              }
            </ActionsBox>
          </Collapser>
        </Box>
        <Fade in={fadeIn} timeout={300}>
          <Box>
            {isActiveReviewPoint && selectedPoint && !isCensusCampaign &&
              <PointCard
                canModify={
                  !context.selectedCampaign.hasOwnWorkFinished || context.checkIfAdminPowers() || context.checkIfCoordinatesCampaign()
                }
                point={selectedPoint}
                hasMorePoints={pointsList.length > 1}
                options={{ pointStatusesOptions }}
                actions={{ onArrowClick, reviewPoint }}
                csLang={context.campaignSubtypeLanguage}
                campaignSubtypeId={campaignSubtypeId}
              />
            }
            <RecordsList
              tableRef={tableRef}
              hasOwnWorkFinished={context.selectedCampaign.hasOwnWorkFinished}
              campaignInProgress={context.selectedCampaign.statusId === 'in-progress'}
              actions={{ toggleDialog, updateTree, getRecords }}
              reviewPoint={isActiveReviewPoint}
              coordinatesCampaign={context.checkIfCoordinatesCampaign()}
              hasAdminPowers={context.checkIfAdminPowers()}
              currentUser={context.currentUser}
              forceReview={context.selectedCampaign.forceReview}
              userIsFan={context.isFan()}
              campaignSubtypeId={campaignSubtypeId}
              options={options}
              cLang={context.campaignSubtypeLanguage}
              setCampaignCounter={context.setCampaignCounter}
              censusColumns={censusColumns}
            />
          </Box>
        </Fade>
      </ContentContainer>
      <DialogWrap maxWidth='sm' fullWidth onClose={closeDialog} aria-labelledby='form-dialog-title'
        open={dialog.isOpen}>
        {getDialogType()}
      </DialogWrap>
    </Grid>
  );
};

RecordsContainer.propTypes = {
  setIsDrawerOpen: PropTypes.func.isRequired,
  checkCompletion: PropTypes.func,
};


export {
  RecordsContainer,
};
