import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, TextField, InputLabel } from '@material-ui/core';

import { withAccessControl, SelectWrapper } from 'src/components';


const CompanyCreateDialog = props => {
  const [ company, setCompany ] = useState({
    companyName: '',
    selectedUser: '',
  });

  const handleChange = e => setCompany({ [e.target.name]: e.target.value });
  const handleSubmit = () => {
    const { companyName: name, selectedUser } = company;
    if (name && selectedUser) {
      actions.createCompany({ name, userId: selectedUser.value });
    }
  };

  const { actions } = props;
  const { companyName, selectedUser } = company;
  return (
    <Fragment>
      <DialogTitle id="form-dialog-title">Agregar empresa</DialogTitle>
      <DialogContent>
        <Box my={3}>
          <TextField label="Nombre de la empresa" name="companyName" value={companyName} onChange={handleChange}
            fullWidth autoFocus autoComplete="off" />
        </Box>
        <Box my={3}>
          <InputLabel shrink>Administrador</InputLabel>
          <SelectWrapper options={props.usersOptions} className="basic-multi-select" value={selectedUser}
            classNamePrefix="select" onChange={selectedUser => setCompany({ ...company, selectedUser })} fixedList
            placeholder={'Seleccione administrador'} noOptionsMessage={() => 'No hay más usuarios'} maxMenuHeight={100} menuPlacement="auto"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog} color="secondary">Cancelar</Button>
        <Button onClick={handleSubmit} color="primary">Agregar</Button>
      </DialogActions>
    </Fragment>
  );
};

CompanyCreateDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  usersOptions: PropTypes.array.isRequired,
};

const CompanyCreateDialogWithAccessControl = withAccessControl({ action: 'company:create' })(CompanyCreateDialog);


export { CompanyCreateDialogWithAccessControl as CompanyCreateDialog };
