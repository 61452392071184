import React from 'react';
import { Box, Paper, Typography, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { MapIcon, CloudSyncIcon, GridIcon, GoKartTrackIcon, PinOutlineIcon, CheckIcon, CloseIcon } from 'src/utils/mdIcons';


const useStyles = makeStyles(theme => ({
  mainContainer: {
    padding: theme.spacing(1),
    color: theme.palette.common.black,
    borderLeft: `8px solid ${theme.palette.error.main}`,
  },
  mainContainerSynced: {
    padding: theme.spacing(1),
    color: theme.palette.common.black,
    borderLeft: `8px solid #5193EB`,
  },
  title: {
    fontWeight: '500',
    fontSize: '1rem',
    lineHeight: 1.8,
  },
  content: {
    fontSize: '0.75rem',
    lineHeight: 1.2,
  },
  avatar: {
    height: '28px',
    width: '28px',
    fontSize: '12px',
    backgroundColor: theme.palette.flora.primary.main,
  },
  data: {
    fontSize: '0.65rem',
  },
  smallestIcon: {
    fontSize: '12px',
  },
  stat: {
    width: '40px',
  },
}));

const AppCampaignUI = ({ title, project, dateText, companyName, companyInitials, catalogue, data, syncedColor = false }) => {
  const { plots = { done: 0, total: 0 }, synced = 0, map = false, area = false, ri = 0 } = data;
  const classes = useStyles();
  return (
    <Paper className={ syncedColor ? classes.mainContainerSynced : classes.mainContainer } elevation={4}>
      <Box display="flex" flexDirection="column">
        <Box display="flex">
          <Box display="flex" flexDirection="column">
            <Typography className={classes.title} variant="body2">{title}</Typography>
            <Typography className={classes.content}>{project}</Typography>
            <Typography className={classes.content}>{dateText}</Typography>
          </Box>
          <Box flexGrow={1} />
          <Box>
            <Avatar className={classes.avatar}>{companyInitials}</Avatar>
            <Typography className={classes.data}>{companyName}</Typography>
          </Box>
        </Box>
        <Typography className={classes.content}>{catalogue}</Typography>
        <Box mt={0.4} display="flex" flexDirection="column">
          <Box display="flex" justifyContent="space-between">
            <Box className={classes.stat} display="flex" flexDirection="column" alignItems="center">
              <Typography className={classes.data}>Parcelas</Typography>
              <GridIcon fontSize="small" />
              <Typography className={classes.data}>{plots.done} / {plots.total} </Typography>
            </Box>
            <Box className={classes.stat} display="flex" flexDirection="column" alignItems="center">
              <Typography className={classes.data}>RI</Typography>
              <PinOutlineIcon fontSize="small" />
              <Typography className={classes.data}>{ri}</Typography>
            </Box>
            <Box className={classes.stat} display="flex" flexDirection="column" alignItems="center">
              <Typography className={classes.data}>Sync</Typography>
              <CloudSyncIcon fontSize="small" />
              <Typography className={classes.data}>{synced} %</Typography>
            </Box>
            <Box className={classes.stat} display="flex" flexDirection="column" alignItems="center">
              <Typography className={classes.data}>Mapa</Typography>
              <MapIcon fontSize="small" />
              {map ? <CheckIcon className={classes.smallestIcon} /> : <CloseIcon className={classes.smallestIcon} /> }
            </Box>
            <Box className={classes.stat} display="flex" flexDirection="column" alignItems="center">
              <Typography className={classes.data}>Área</Typography>
              <GoKartTrackIcon fontSize="small" />
              {area ? <CheckIcon className={classes.smallestIcon} /> : <CloseIcon className={classes.smallestIcon} /> }
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

AppCampaignUI.propTypes = {
  title: PropTypes.string,
  project: PropTypes.string,
  companyName: PropTypes.string,
  companyInitials: PropTypes.string,
  dateText: PropTypes.string,
  catalogue: PropTypes.string,
  data: PropTypes.object,
  syncedColor: PropTypes.bool,
};


export {
  AppCampaignUI,
};
