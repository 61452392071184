import React from 'react';
import PropTypes from 'prop-types';
import { Box, Paper, Typography } from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import clsx from 'clsx';


const getColor = theme => ({ color }) => (
  color === 'primary' ? theme.palette.primary.main
  : color === 'secondary' ? theme.palette.secondary.main
  : color === 'flora' ? theme.palette.flora.primary.main
  : color === 'fauna' ? theme.palette.fauna.primary.main
  : theme.palette.primary.main
);

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    backgroundColor: ({ variant }) => (
      variant === 'dark' ? theme.palette.common.black
      : variant === 'light' ? theme.palette.common.white
      : theme.palette.common.white
    ),
    minWidth: '256px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '256px',
    },
  },
  title: {
    fontSize: theme.typography.subtitle1.fontSize,
    color: ({ variant }) => (
      variant === 'light' ? theme.palette.common.gray600
      : variant === 'dark' ? theme.palette.common.lightGray
      : theme.palette.common.gray600
    ),
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  number: {
    fontSize: theme.typography.h4.fontSize,
    fontWeight: 600,
    color: ({ variant }) => (
      variant === 'light' ? theme.palette.common.black
      : variant === 'dark' ? theme.palette.common.white
      : theme.palette.common.black
    ),
  },
  percent: {
    fontSize: theme.typography.subtitle1.fontSize,
    color: getColor(theme),
    fontWeight: 600,
  },
  percentDesc: {
    fontSize: '0.8rem',
    color: ({ variant }) => (
      variant === 'light' ? alpha(theme.palette.common.gray700, 0.5)
      : variant === 'dark' ? theme.palette.common.lightGray
      : alpha(theme.palette.common.gray700, 0.5)
    ),
    fontWeight: 600,
  },
  trendIcon: {
    fontSize: '1.25rem',
    fontWeight: 600,
    color: getColor(theme),
  },
  icon: {
    color: getColor(theme),
  },
  iconBackground: {
    height: '40px',
    width: '40px',
    backgroundColor: ({ color }) => alpha(getColor(theme)({ color }), 0.15),
    borderRadius: '50%',
  },
}));

const MapIndicator = props => {
  const { className, title = '', number = 0, percent = 0, trend = 'up', color = 'primary', Icon = () => <></>, variant = 'light' } = props;
  const classes = useStyles({ color, variant });
  return (
    <Paper className={clsx(classes.container, className)}>
      <Box display="flex">
        <Box display="flex" flexDirection="column" width="100%">
          <Box mb={1}>
            <Typography className={classes.title}>{title}</Typography>
          </Box>
          <Box display="flex" mb={1}>
            <Typography className={classes.number}>{number.toLocaleString('es-CL')}</Typography>
            <Box flexGrow={1} />
            <Box p={1} display="flex" alignItems="center" justifyContent="center" className={classes.iconBackground}>
              <Icon fontSize="large" className={classes.icon} />
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography className={classes.percent}>{(Math.round(percent * 10) / 10).toLocaleString('es-CL')}%</Typography>
            <Box px={1} display="flex" alignItems="center">
              {trend === 'up' && <ArrowUpward className={classes.trendIcon} />}
              {trend === 'down' && <ArrowDownward className={classes.trendIcon} />}
            </Box>
            <Typography className={classes.percentDesc}>hasta mayo del 2024</Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

MapIndicator.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  number: PropTypes.number,
  percent: PropTypes.number,
  trend: PropTypes.bool,
  Icon: PropTypes.oneOfType([ PropTypes.object, PropTypes.func ]),
  color: PropTypes.string,
  variant: PropTypes.string,
};


export { MapIndicator };
