import React from 'react';
import PropTypes from 'prop-types';

import { TableWrapper } from 'src/components';
import { Delete, Edit } from '@material-ui/icons';


const UsersList = props => {
  const { usersList, rolesOptions, actions, currentUser } = props;
  /* eslint-disable react/display-name */
  const tableColumns = [
    { title: 'Correo', field: 'email' },
    { title: 'Nombre', field: 'name', render: r => <div>{r.firstName} {r.lastName}</div> },
    { title: 'Rol', field: 'role', render: r => <div>{rolesOptions.find(role => role.value === r.role)?.label}</div> },
    { title: 'Tipo de usuario', field: 'isFreelance', render: r => r.isFreelance ? 'Freelance' : 'Interno' },
  ];

  const itsMe = email => currentUser.email === email;

  const tableActions = [
    rowData => ({
      disabled: itsMe(rowData.email),
      tooltip: 'Editar',
      icon: () => <Edit color={itsMe(rowData.email) ? 'disabled' : 'action'} />,
      ...(!itsMe(rowData.email) && { onClick: (event, rowData) => actions.toggleDialog({ data: rowData, type: 'edit' }) }),
    }),
    rowData => ({
      disabled: itsMe(rowData.email),
      tooltip: 'Desvincular',
      icon: () => <Delete color={itsMe(rowData.email) ? 'disabled' : 'action'} />,
      ...(!itsMe(rowData.email) && { onClick: (event, rowData) => actions.toggleDialog({ data: rowData.id, type: 'delete' }) }),
    }),
  ];

  const tableOptions = {
    pageSizeOptions: [],
  };

  return (
    <TableWrapper columns={tableColumns} data={usersList} actions={tableActions} options={tableOptions} />
  );
};

UsersList.propTypes = {
  actions: PropTypes.object.isRequired,
  usersList: PropTypes.array.isRequired,
  rolesOptions: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
};


export { UsersList };
