import React, { useState, useEffect } from 'react';
import { Paper, Grid, Typography, Accordion, AccordionDetails, AccordionSummary, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';

import { TitleContainer, ContentContainer, AsyncImage } from 'src/components';
import { usersApi } from 'src/services';


const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: 18,
    fontWeight: 400,
    paddingLeft: theme.spacing(2),
  },
  video: {
    maxWidth: '960px',
  },
  // TODO: poner bien los anchos, altos y justificado para que quede bien sin importar el tamaño de la imagen
  imageBox: {
    maxWidth: '960px',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.gray400,
    borderRadius: theme.shape.borderRadius,
  },
}));

const textToLinesArray = text => text.split(/\n\s*\n\s*/);

const FaqContainer = () => {

  const classes = useStyles();
  const [ expanded, setExpanded ] = useState(false);
  const [ faqByCategories, setFaqByCategories ] = useState([]);

  const handleChange = panelId => (event, isExpanded) => setExpanded(isExpanded ? panelId : false);

  useEffect(() => {
    document.title = 'Faq - Nviro Capture';
    const fetchData = async () => {
      const faqByCategories = await usersApi.getFaq();
      let totalItems = 0;
      for (const category of faqByCategories) {
        for (const faqItem of category.faq) {
          faqItem.number = totalItems++;
        }
      }
      setFaqByCategories(faqByCategories);
    };
    fetchData();
  }, []);

  return (
    <Grid container>
      <TitleContainer >
        Centro de ayuda
      </TitleContainer>
      <ContentContainer maxWidth="lg">
        <Box my={5} />
        {faqByCategories.map((category, cIndex) => category.faq.length > 0 &&
          <Box key={cIndex} my={3}>
            <Paper >
              <Box py={3} display="flex" flexDirection="column" textAlign="center">
                <Typography color="primary" variant="h4">{category.label}</Typography>
                <Box mt={2} px={6}>
                  <Typography variant="body1" color="textSecondary" component="p">{category.description}</Typography>
                </Box>
              </Box>
              {category.faq.map(f =>
                <Accordion key={f.number} expanded={expanded === f.number}
                  onChange={handleChange(f.number)}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h6" className={classes.heading}>{f.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {(f.app === 'nviro-data-capture' || !f.url) &&
                      <Box display="flex" flexDirection="column" width="100%">
                        <Box p={2}>
                          {textToLinesArray(f.answer).map((paragraph, index) =>
                            <Typography key={`para-${index}`} variant="body1" color="textSecondary" paragraph>
                              {paragraph}
                            </Typography>,
                          )}
                        </Box>
                        {f.url &&
                          <Box p={2} alignSelf="center">
                            { f.mediaType === 'video' &&
                              <video className={classes.video} width="100%" controls>
                                <source src={f.url} type="video/mp4" />
                                Tu navegador no soporta videos html
                              </video>
                              || f.mediaType === 'image' &&
                              <Box className={classes.imageBox}>
                                <AsyncImage src={f.url} alt={f.imgAlt ?? ''} width="100%"/>
                              </Box>
                            }
                          </Box>
                        }
                        <Box px={2} pt={2}>
                          {f.note && <Typography variant="body2" color="textSecondary">{`* ${f.note}`}</Typography>}
                        </Box>
                      </Box>
                    }

                    {(f.app === 'nviro-flora' || f.app === 'nviro-fauna') && f.url &&
                      <Box display="flex" width="100%" alignItems="center" px={'12%'}>
                        <Box display="flex" flexDirection="column">
                          <Box pt={2} px={2}>
                            {textToLinesArray(f.answer).map((paragraph, index) =>
                              <Typography key={`para-${index}`} variant="body1" color="textSecondary" paragraph>
                                {paragraph}
                              </Typography>,
                            )}
                          </Box>
                          <Box pt={4} px={2}>
                            {f.note && <Typography variant="body2" color="textSecondary">{`* ${f.note}`}</Typography>}
                          </Box>
                        </Box>
                        {f.url &&
                          <Box p={2} alignSelf="center">
                            { f.mediaType === 'video' &&
                              <video width={310} controls>
                                <source src={f.url} type="video/mp4" />
                                Tu navegador no soporta videos html
                              </video>
                              || f.mediaType === 'image' &&
                              <Box className={classes.imageBox}>
                                <AsyncImage src={f.url} alt={f.imgAlt ?? ''} width={'100%'}/>
                              </Box>
                            }
                          </Box>
                        }
                      </Box>
                    }
                  </AccordionDetails>
                </Accordion>,
              )}
            </Paper>
          </Box>)
        }
      </ContentContainer>
    </Grid>
  );
};


export {
  FaqContainer,
};