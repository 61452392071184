import React from 'react';
import PropTypes from 'prop-types';
import { Box, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { upperCaseOnlyFirstLetter as ucofl } from 'src/utils/formatters';


const useStyles = makeStyles(() => ({
  chip: {
    lineHeight: '1',
  },
  label: {
    overflow: 'visible',
  },
}));

const SelectChip = props => {
  const { options, onChange, isMulti, value, returnFullOption, disabled: disabledAll = false,
    allowEmpty = false, toUpperFirst = true, chipProps } = props;

  const classes = useStyles();

  const handleClick = option => {
    let valueSelected;
    if (isMulti) {
      if (returnFullOption) {
        throw new Error('returnFullOption no es compatible con isMulti aun');
      }
      if (value.includes(option.value)) {
        if (allowEmpty) {
          valueSelected = value.filter(v => v !== option.value);
        } else {
          valueSelected = value.length === 1 ? value : value.filter(v => v !== option.value);
        }
      } else {
        valueSelected = [ ...value, option.value ];
      }
      valueSelected = { value: valueSelected };
    } else {
      if (option.value === value) {
        if (allowEmpty) {
          valueSelected = [];
        } else {
          // se está seleccionando algo ya seleccionado así que no se hace nada
          return;
        }
      } else {
        valueSelected = returnFullOption ? option : { value: option.value };
      }
    }
    onChange(valueSelected);
  };

  const isSelected = optionValue => isMulti ? value.includes(optionValue) : (value === optionValue);
  return (
    <Box display="inline-block">
      {options.map((option, index) =>
        <Box key={index} mr={1} fontSize="1.7em" display="inline-block">
          <Chip
            className={classes.chip}
            classes={{ label: classes.label }}
            label={toUpperFirst ? ucofl(option.label) : option.label}
            onClick={() => handleClick(option)}
            clickable
            disabled={disabledAll || option.disabled || false}
            variant={isSelected(option.value) ? 'default' : 'outlined' }
            color={isSelected(option.value) && !option.disabled ? 'secondary' : 'default'}
            {...chipProps}
          />
        </Box>,
      )}
    </Box>
  );
};

SelectChip.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    label: PropTypes.string.isRequired,
  })),
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  allowEmpty: PropTypes.bool,
  value: PropTypes.any,
  returnFullOption: PropTypes.bool,
  disabled: PropTypes.bool,
  toUpperFirst: PropTypes.bool,
  chipProps: PropTypes.object,
};


export {
  SelectChip,
};