import React from 'react';
import { Typography, Box, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { LabelChip } from 'src/components';
import { upperCaseOnlyFirstLetter as upofl } from 'src/utils/formatters';
import { formatDate } from 'src/utils/util';


const useStyles = makeStyles(theme => ({
  container: {
    cursor: 'pointer',
    // width: '70%',
  },
  title: {
    fontWeight: 400,
    fontSize: '18px',
  },
  icon: {
    marginLeft: theme.spacing(0.5),
    cursor: 'pointer',
    fontSize: '14px',
    color: theme.palette.common.gray600,
  },
  labelDate: {
    width: 'fit-content',
    cursor: 'pointer',
  },
  checkbox: {
    padding: 0,
  },
  checkboxLabel: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  label: {
    cursor: 'pointer',
  },
}));

const CampaignSettingCard = ({ variant = 'default', campaign, actions, hasExportAllowed }) => {
  const classes = useStyles();

  const { id: campaignId, name, componentType, startDate, endDate, mainType, methodType } = campaign;

  const isDefaultOrCondensed = variant === 'default' || variant === 'condensed';
  const isGrid = variant === 'grid';

  return (
    <Box display="flex" alignItems="center" flexGrow={1} className={classes.container}
      onClick={() => actions.toggleHasExportAllowed(campaignId)}>
      {isDefaultOrCondensed &&
        <>
          <Box display='flex' flexDirection="column" sx={{ p: 1, width: '5%' }}>
            <Checkbox
              className={classes.checkbox}
              checked={hasExportAllowed}
              disableRipple
              disableFocusRipple
              size="small"
              classes={{ root: classes.checkboxLabel }}
            />
          </Box>
          <Box display='flex' flexDirection="column" sx={{ ml: 2, p: 0, width: '25%' }}>
            <Typography noWrap className={classes.title} variant="h6" color="textPrimary">
              {name}
            </Typography>
          </Box>
          <Box display='flex' flexDirection="column" sx={{ p: 0, width: '20%' }}>
            <Box display="flex" alignItems="center">
              <Box px={0.25}>
                <LabelChip className={classes.label} label={upofl(componentType)} color={`${campaign.componentType}.primary.main`} />
              </Box>
              <Box px={0.25}><LabelChip className={classes.label} label={mainType} color="gray800" /></Box>
              <Box px={0.25}><LabelChip className={classes.label} label={methodType} color="gray600" /></Box>
            </Box>
          </Box>
          <Box display='flex' flexDirection="column" alignItems="center" sx={{ p: 1, width: '20%' }}>
            <Box display="flex" alignItems="center">
              <LabelChip
                className={classes.labelDate}
                color="default"
                variant="outlined"
                label={`${formatDate(startDate)} - ${formatDate(endDate)}`}>
              </LabelChip>
            </Box>
          </Box>
          <Box display='flex' flexDirection="column" sx={{ p: 1, width: '30%' }}>
          </Box>
        </>
      }
      {isGrid &&
        <Box display="flex" flexDirection="column">
          <Box display="flex">
            <Checkbox
              className={classes.checkbox}
              checked={hasExportAllowed}
              disableRipple
              disableFocusRipple
              size="small"
              classes={{ root: classes.checkboxLabel }}
            />
            <Box ml={1}>
              <Typography noWrap className={classes.title} variant="h6" color="textPrimary">
                {name}
              </Typography>
            </Box>
          </Box>
          <Box display='flex' flexDirection="column">
            <Box display="flex" alignItems="center">
              <Box px={0.25}>
                <LabelChip className={classes.label} label={upofl(componentType)} color={`${campaign.componentType}.primary.main`} />
              </Box>
              <Box px={0.25}><LabelChip className={classes.label} label={mainType} color="gray800" /></Box>
              <Box px={0.25}><LabelChip className={classes.label} label={methodType} color="gray600" /></Box>
            </Box>
          </Box>
          <Box mt={1} display="flex" alignItems="center">
            <LabelChip
              className={classes.labelDate}
              color="default"
              variant="outlined"
              label={`${formatDate(startDate)} - ${formatDate(endDate)}`}>
            </LabelChip>
          </Box>
        </Box>
      }
    </Box>
  );
};

CampaignSettingCard.propTypes = {
  campaign: PropTypes.object,
  variant: PropTypes.string,
  actions: PropTypes.object,
  hasExportAllowed: PropTypes.bool,
};


export { CampaignSettingCard };


