const recordTypesByMethodologies = {
  'lineal-transect-amphibians': [ 'fauna-direct-record', 'fauna-indirect-record' ],
  'lineal-transect-birds': [ 'fauna-direct-record', 'fauna-indirect-record' ],
  'lineal-transect-mammals': [ 'fauna-direct-record', 'fauna-indirect-record' ],
  'lineal-transect-reptiles': [ 'fauna-direct-record', 'fauna-indirect-record' ],
  'lineal-transect-mixed': [ 'fauna-direct-record', 'fauna-indirect-record' ],
  'sampling-point': [ 'fauna-direct-record' ],
  'sherman-traps': [ 'fauna-direct-record' ],
  'camera-traps': [ 'fauna-presence-record' ],
  'aerial-transit': [ 'fauna-transit-record' ],
  'echo-location-detection': [ 'fauna-presence-record' ],
  'play-back-amphibians': [ 'fauna-presence-record' ],
  'play-back-birds': [ 'fauna-presence-record' ],
};


export {
  recordTypesByMethodologies,
};