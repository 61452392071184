import React from 'react';
import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({
  tooltip: ({ fontSize, paddingX }) => ({
    fontSize: fontSize ?? '0.7rem',
    paddingLeft: paddingX ?? '0.7rem',
    paddingRight: paddingX ?? '0.7rem',
  }),
}));

// Solo para aumentarle el tamaño a la letra ultra chica de los tooltip. PaddingX porque cuando crece la letra
// a veces el padding se vuelve muy poco
const TooltipWrapper = ({ fontSize, paddingX, children, ...props }) => {
  const classes = useStyles({ fontSize, paddingX });

  return <Tooltip classes={classes} {...props}>{ children }</Tooltip>;
};

TooltipWrapper.propTypes = {
  fontSize: PropTypes.string,
  paddingX: PropTypes.string,
  children: PropTypes.any,
};


export {
  TooltipWrapper,
};