import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Collapse } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { withAccessControl, StepperWrapper, DialogButton } from 'src/components';
import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';
import { CampaignCreateFirstStep, CampaignCreateSecondStep, CampaignCreatePointQuadratThirdStep,
  CampaignCreateCensusThirdStep, CampaignCreateCensusFourthStep } from 'src/scenes/Project/scenes/Campaigns/components/CampaignCreate';
import { speciesApi } from 'src/services';
import { upperCaseFirstLetter } from 'src/utils/formatters';
import { isCensus, isPointQuadrat } from 'src/utils/checkers';

const { NOT_STARTED, LOADING, SUCCESS, ERROR, BAD_DATA } = fetchStatusEnum;

const transformSpeciesOptions = searchResult => searchResult.map(r =>
  ({ ...r, label: upperCaseFirstLetter(r.scientificName), value: r.hash }),
);

const getEmptyOption = () => ({ label: '', value: '' }); // getter porque como es un objeto me da cuco que se mute


const CampaignCreateDialog = props => {
  const layerCounterId = useRef(0);
  const { isFan, options, catalogs, actions, projectId, projectUserId } = props;
  const { companyUsersOptions, campaignMainTypesOptions, campaignMethodsInComponentOptions, censusSettingsOptions } = options;

  const componentOptions = [ { label: 'Flora - forestal', value: 'flora' }, { label: 'Fauna', value: 'fauna' } ];

  const [ campaign, setCampaign ] = useState({
    name: '',
    projectOwner: companyUsersOptions.find(userOption => userOption.value === projectUserId),
    selectedCoordinators: [ companyUsersOptions.find(userOption => userOption.value === projectUserId) ],
    selectedCollectors: [],
    collectorsOptions: companyUsersOptions.filter(userOption => userOption.value !== projectUserId),
    selectedCampaignType: campaignMainTypesOptions[0],
    selectedComponent: componentOptions[0],
    selectedCampaignMethod: campaignMethodsInComponentOptions[componentOptions[0].value][0],
    selectedCatalog: catalogs[componentOptions[0].value][0],
    startDate: new Date(),
    endDate: new Date(),
    objective: '',
    observation: '',
    stratumList: [],
    censusSettings: {
      smaParameterIndex: censusSettingsOptions.fields.findIndex(field => field.isDefaultSmaParameter),
      fields: censusSettingsOptions.fields,
      defaultSpecies: getEmptyOption(),
    },
    interestAreas: [], // array de  { kmlFile, fileName, showMarkers, showLines, showPolygons, layer }
  });

  const [ fetchStatus, setFetchStatus ] = useState(NOT_STARTED);
  const hasBadData = fetchStatus === BAD_DATA;

  const [ errorAlert, setErrorAlert ] = useState({ isOpen: false, message: '' });

  const [ activeStep, setActiveStep ] = useState(0);
  const goNext = () => setActiveStep(prevActiveStep => prevActiveStep + 1);
  const goBack = () => setActiveStep(prevActiveStep => prevActiveStep - 1);

  const isProjectOwnerACoordinator = campaign.selectedCoordinators.includes(campaign.projectOwner);
  const hasStratus = campaign.stratumList.length > 0;

  const handleNext = () => {
    if (activeStep === 0 && isProjectOwnerACoordinator && name && selectedCampaignType.value && selectedCatalog?.value) {
      goNext();
    } else if (activeStep === 1 && startDate && endDate && (startDate <= endDate)) {
      setFetchStatus(NOT_STARTED);
      isLastStep ? handleSubmit() : goNext();
    } else if (activeStep === 2 && selectedCampaignMethod.value === 'point-quadrat' && hasStratus) {
      setFetchStatus(NOT_STARTED);
      isLastStep ? handleSubmit() : goNext();
    } else if (activeStep >= 2 && activeStep <= 4 && selectedCampaignMethod.value === 'census') {
      setFetchStatus(NOT_STARTED);
      isLastStep ? handleSubmit() : goNext();
    } else {
      setFetchStatus(BAD_DATA);
    }
  };

  const createCampForm = data => {
    const form = new FormData();
    let kmlFiles = [];
    Object.keys(data).forEach(key => {
      if (data[key] !== undefined) {
        if (Array.isArray(data[key])) {
          if (key === 'kmlFiles') {
            kmlFiles = data[key];
          } else if (data[key].length && (typeof data[key][0] === 'object')) {
            form.append(key, JSON.stringify(data[key]));
          } else {
            data[key].forEach(d => form.append(key, d)); // si hay un solo elemento, aparecerá suelto y no en un array
          }
        } else if (data[key] instanceof Date || data[key]._isAMomentObject) {
          form.append(key, data[key].toISOString());
        } else if (typeof data[key] === 'object') {
          form.append(key, JSON.stringify(data[key]));
        } else {
          form.append(key, data[key]);
        }
      }
    });

    if (kmlFiles.length > 0) {
      kmlFiles.forEach(kmlFile => form.append('kmlFile', kmlFile.file, kmlFile.fileName));
    }

    return form;
  };

  const handleSubmit = async () => {
    const { name, selectedCoordinators, selectedCampaignType, selectedCatalog, startDate, endDate, objective,
      observation, stratumList, censusSettings, interestAreas } = campaign;
    try {
      setFetchStatus(LOADING);
      const newCampaign = {
        projectId,
        name,
        coordinatorsIds: selectedCoordinators.map(user => parseInt(user.value, 10)),
        collectorsIds: selectedCollectors.map(user => parseInt(user.value, 10)),
        mainTypeId: selectedCampaignType.value,
        componentId: selectedComponent.value,
        mainMethodId: selectedCampaignMethod.value,
        catalogId: selectedCatalog.value,
        startDate,
        endDate,
        objective,
        observation,
        ...(isPointQuadrat(selectedCampaignMethod.value) ? { stratums: stratumList } : {}),
      };

      if (isCensus(selectedCampaignMethod.value)) {
        newCampaign.settings = { fields: [] };
        const { smaParameterIndex } = censusSettings;
        censusSettings.fields.forEach((field, index) => {
          const { isActive, isRequired, id } = field;
          if (isActive) {
            newCampaign.settings.fields.push({
              isRequired, id, isSmaParameter: index === smaParameterIndex,
            });
          }
        });

        newCampaign.interestAreaConfigs = [];
        newCampaign.kmlFiles = [];
        interestAreas.forEach(area => {
          const { kmlFile, fileName } = area.kmlData;
          newCampaign.kmlFiles.push({ file: kmlFile, fileName });
          // const newInteresArea = { kmlData, config: { showLines, showMarkers, showPolygons }, layer: newLayer };
          newCampaign.interestAreaConfigs.push({ ...area.config });
        });

        if (censusSettings.defaultSpecies?.value) {
          newCampaign.settings.defaultSpeciesHash = censusSettings.defaultSpecies.value;
        } else if (censusSettings.defaultSpecies?.label) {
          newCampaign.settings.defaultSpeciesName = censusSettings.defaultSpecies.label;
        }
        // si no hay value ni label, no se envian los campos en las settings
      }

      await actions.createCampaign(createCampForm(newCampaign));
      setFetchStatus(SUCCESS);
      setTimeout(() => actions.closeDialog(), 300);
    } catch (e) {
      console.error(e);
      setErrorAlert({ isOpen: true, message: e.serverMessage ?? 'Ocurió un error inesperado, por favor intenta más tarde' });
      setFetchStatus(ERROR);
    }
  };

  const handleChange = e => setCampaign({ ...campaign, [e.target.name]: e.target.value });

  const handleSelect = obj => setCampaign({ ...campaign, ...obj });

  const handleCatalogChange = selectedCatalog => setCampaign(pc => ({
    ...pc, selectedCatalog,
    censusSettings: { ...pc.censusSettings, defaultSpecies: getEmptyOption() },
  }));

  const handleSelectCoordinators = (selectedCoordinators, actionProps) => {
    if (actionProps.action === 'clear') {
      setCampaign(pc => ({ ...pc, selectedCoordinators: pc.selectedCoordinators.filter(opt => opt.isFixed) }));
      return;
    }
    return actionProps.action === 'pop-value' && actionProps.removedValue.isFixed ? undefined : setCampaign(pc => ({
      ...pc, selectedCoordinators,
      // Si se añade una persona acá, sacarla de selectedCollectors
      ...(actionProps.action === 'select-option' &&
        { selectedCollectors: pc.selectedCollectors.filter(prevCollector => prevCollector.value !== actionProps.option.value) }
      ),
    }));
  };

  const handleSelectCollectors = (selectedCollectors, actionProps) => {
    if (actionProps.action === 'clear') {
      setCampaign(pc => ({ ...pc, selectedCollectors: [] }));
      return;
    }
    return setCampaign(pc => ({
      ...pc, selectedCollectors,
      ...(actionProps.action === 'select-option' &&
        { selectedCoordinators: pc.selectedCoordinators.filter(prevCordinator => prevCordinator.value !== actionProps.option.value) }
      ),
    }));
  };

  const handleChangeComponent = selectedComponent => {
    setCampaign({
      ...campaign,
      selectedComponent,
      selectedCampaignMethod: campaignMethodsInComponentOptions[selectedComponent.value][0],
      selectedCatalog: catalogs[selectedComponent.value][0],
    });
  };

  const handleParameterSelect = option =>
    setCampaign(pc => ({ ...pc, censusSettings: { ...pc.censusSettings, smaParameterIndex: option?.value ?? null } }));

  const handleCensusFieldUsageChange = (fieldId, isActive) => {
    setCampaign(pc => {
      const fields = [ ...pc.censusSettings.fields ];
      const fieldIndex = fields.findIndex(field => field.id === fieldId);
      fields[fieldIndex] = { ...fields[fieldIndex], isActive };
      const prevSmaParamIndex = pc.censusSettings.smaParameterIndex;
      return { ...pc,
        censusSettings: {
          ...pc.censusSettings,
          fields,
          ...(prevSmaParamIndex !== null && !fields[prevSmaParamIndex].isActive && { smaParameterIndex: null }) },
      };
    });
  };

  const handleCensusFieldRequirementChange = (fieldId, isRequired) => {
    setCampaign(pc => {
      const fields = [ ...pc.censusSettings.fields ];
      const fieldIndex = fields.findIndex(field => field.id === fieldId);
      fields[fieldIndex] = { ...fields[fieldIndex], isRequired };
      return { ...pc,
        censusSettings: {
          ...pc.censusSettings,
          fields },
      };
    });
  };

  const handleSpeciesSelect = species =>
    setCampaign(pc => ({
      ...pc,
      censusSettings: { ...pc.censusSettings,
        defaultSpecies: species ?? { label: '', value: '' },
      },
    }));

  const handleAreaImport = (kmlData, config) => {
    setCampaign(pc => {
      const id = layerCounterId.current++;
      const { showMarkers, showLines, showPolygons } = config;
      const filteredFeats = kmlData.geojson.features.filter(feat => {
        const type = feat.geometry?.type;
        return type && (
          type === 'Point' && showMarkers ||
          type === 'LineString' && showLines ||
          type === 'Polygon' && showPolygons
        );
      });
      const newLayer = { geojson: { ...kmlData.geojson, features: filteredFeats }, id, name: kmlData.fileName };
      const newInteresArea = { kmlData, config: { showLines, showMarkers, showPolygons }, layer: newLayer };
      if (config.replaceAll) {
        return { ...pc, interestAreas: [ newInteresArea ] };
      } else {
        return { ...pc, interestAreas: [ ...pc.interestAreas, newInteresArea ] };
      }
    });
  };

  const handleDeleteArea = id => {
    setCampaign(pc => ({ ...pc, interestAreas: pc.interestAreas.filter(area => area.layer.id !== id) }));
  };
  // const handleAreaDelete = idToDelete => {

  // };

  const { name, selectedComponent, selectedCampaignMethod, selectedCampaignType, selectedCoordinators, selectedCollectors,
    selectedCatalog, startDate, endDate, objective, observation, stratumList, censusSettings, interestAreas, collectorsOptions } = campaign;

  const steps = [
    { label: 'Datos principales' },
    { label: 'Fechas y objetivos' },
    ...(selectedCampaignMethod.value === 'point-quadrat' ?
      [ { label: 'Estratos' } ]
      : selectedCampaignMethod.value === 'census' ? [ { label: 'Formulario' }, { label: 'Área de interés' } ]
      : []
    ),
  ];

  const lastStep = steps.length - 1;
  const isLastStep = activeStep === lastStep;
  const isFirstStep = activeStep === 0;

  // TODO: ver si poner un scroll hacia el inicio de cada paso (para no preservar el scroll entre pasos que puede sentirse raro)
  return (
    <>
      <DialogTitle id="form-dialog-title">Crear campaña</DialogTitle>
      <DialogContent>
        <Box px={1}>
          <StepperWrapper steps={steps} activeStep={activeStep} styled/>
          <Box pt={5} width="100%" display="flex" alignItems="center">
            {activeStep === 0 &&
              <CampaignCreateFirstStep
                form={{ name, selectedCoordinators, selectedCollectors, selectedComponent,
                  selectedCampaignType, selectedCampaignMethod, selectedCatalog }}
                options={{ companyUsersOptions, collectorsOptions, componentOptions, campaignMainTypesOptions,
                  campaignMethodsInComponentOptions, catalogs }}
                handlers={{ handleChange, handleSelectCoordinators, handleSelectCollectors,
                  handleChangeComponent, handleSelect, handleCatalogChange }}
                validators={{ hasBadData, isFan, isProjectOwnerACoordinator }}
              />
            }

            {activeStep === 1 &&
              <CampaignCreateSecondStep
                form={{ startDate, endDate, objective, observation }}
                handlers={{ handleChange, handleSelect }}
                validators={{ hasBadData }}
              />
            }

            {activeStep === 2 && selectedCampaignMethod.value === 'point-quadrat' &&
              <CampaignCreatePointQuadratThirdStep
                form={{ stratumList }}
                handlers={{ handleSelect }}
                validators={{ hasBadData }}
              />
            }
            {activeStep === 2 && selectedCampaignMethod.value === 'census' &&
              <CampaignCreateCensusThirdStep
                form={{ censusSettings }}
                options={{
                  parameterOptions: censusSettingsOptions.parameterOptions,
                  getSpeciesOptions: async ({ queryText }) => transformSpeciesOptions(
                    await speciesApi.searchInFlora({ queryText, catalogId: selectedCatalog.value }),
                  ),
                }}
                handlers={{ handleParameterSelect, handleCensusFieldUsageChange, handleCensusFieldRequirementChange, handleSpeciesSelect }}
                validators={{ hasBadData }}
              />
            }
            {activeStep === 3 && selectedCampaignMethod.value === 'census' &&
              <CampaignCreateCensusFourthStep
                form={{ interestAreas }}
                options={{
                  parameterOptions: censusSettingsOptions.parameterOptions,
                  getSpeciesOptions: async ({ queryText }) => transformSpeciesOptions(
                    await speciesApi.searchInFlora({ queryText, catalogId: selectedCatalog.value }),
                  ),
                }}
                handlers={{ handleAreaImport, handleDeleteArea }}
                validators={{ hasBadData }}
              />
            }
          </Box>
          {fetchStatus === ERROR &&
            <Collapse in={errorAlert.isOpen}>
              <Alert severity="error" onClose={() => setErrorAlert(s => ({ ...s, isOpen: false }))}>
                {errorAlert.message}
              </Alert>
            </Collapse>
          }
        </Box>
      </DialogContent>
      {/* TODO: que la campaña no inicie al-tiro en el caso de censo, si no que que te pregunte si quieres iniciarla y si no
          que "planificar" en la tarjeta de campaña se transforme en un "iniciar" */}
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        { !isFirstStep && <Button onClick={goBack}>Volver</Button> }
        <DialogButton fetchStatus={fetchStatus} onClick={handleNext} color='primary'>
          {isLastStep ? 'Crear' : 'Siguiente'}
        </DialogButton>
      </DialogActions>
    </>
  );
};

CampaignCreateDialog.propTypes = {
  options: PropTypes.object.isRequired,
  censusSettingsOptions: PropTypes.object,
  isFan: PropTypes.bool.isRequired,
  projectId: PropTypes.number.isRequired,
  projectUserId: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  catalogs: PropTypes.shape({
    flora: PropTypes.array.isRequired,
    fauna: PropTypes.array.isRequired,
  }).isRequired,
};

const CampaignCreateDialogWithAccessControl = withAccessControl({ action: 'campaign:create' })(CampaignCreateDialog);


export {
  CampaignCreateDialogWithAccessControl as CampaignCreateDialog,
};