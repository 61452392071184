import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import { LabelChip } from 'src/components';
import { wgs84ToUTM } from 'src/utils/converters';
import { isEmpty } from 'src/utils/util';
import { TooltipWrapper as Tooltip } from 'src/components/tooltipWrapper/TooltipWrapper';


const LabelCoords = ({ latitude, longitude, textColor }) => {
  const hasValidCoords = !isEmpty(latitude) && !isEmpty(longitude);
  const { south, west, timeZone, hemisphereLetter } = hasValidCoords ? wgs84ToUTM({ latitude, longitude }) : '';
  const coordsUtmFormatted = [ south, west, `${timeZone}${hemisphereLetter}` ].join(' · ');
  return (
    <>
      {hasValidCoords ?
        <Tooltip interactive title={`Lat: ${parseFloat(latitude.toFixed(8))} Lng: ${parseFloat(longitude.toFixed(8))}`}>
          <Box>
            <LabelChip label={coordsUtmFormatted} color="default" textColor={textColor} variant="outlined"/>
          </Box>
        </Tooltip> :
        <Box>-</Box>
      }
    </>
  );
};

LabelCoords.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  textColor: PropTypes.string,
};


export {
  LabelCoords,
};