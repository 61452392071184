import React from 'react';
import { AppBar, Toolbar, IconButton, Box, Typography } from '@material-ui/core';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AccountCircle from '@material-ui/icons/AccountCircle';


const menuTitleStyles = theme => ({

  '@keyframes slide': {
    from: {
      width: 0,
    },
    to: {
      width: '29px',
    },
  },
  '@keyframes slidend': {
    from: {
      width: '29px',
    },
    to: {
      width: '100%',
    },
  },

  root: {
    cursor: 'pointer',
    fontWeight: '600',
    color: props => props.selected ? theme.palette.secondary.main : theme.palette.common.white,
    fontSize: ({ size }) => size === 'small' ? theme.typography.body2.fontSize : theme.typography.body1.fontSize,
  },

  hovered: {
    '&:hover': {
      color: theme.palette.secondary.main,
      marginTop: '6px',
      '&:after': {
        content: '""',
        display: 'block',
        width: props => props.selected ? '100%' : '29px',
        borderBottomWidth: '3px',
        borderBottomColor: theme.palette.secondary.main,
        borderBottomStyle: 'solid',
        paddingTop: '3px',
        animationName: '$slide',
        animation: 'linear',
        animationDuration: '100ms',
      },
    },
  },

  focused: {
    marginTop: '6px',
    '&:after': {
      content: '""',
      display: 'block',
      width: props => props.selected ? '100%' : '29px',
      borderBottomWidth: '3px',
      borderBottomColor: theme.palette.secondary.main,
      borderBottomStyle: 'solid',
      paddingTop: '3px',
      animationName: '$slidend',
      animation: 'linear',
      animationDuration: '100ms',
    },
  },
});

const MenuTitle = withStyles(menuTitleStyles)(({ children, selected, classes, ...props }) =>
  <Typography className={`${classes.root} ${selected ? classes.focused : classes.hovered}`} variant="body1" {...props}>
    {children}
  </Typography>);

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.common.black,
  },
  toolbar: {
    height: ({ size }) => size === 'small' ? theme.spacing(8) : theme.spacing(10),
  },
  logo: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: ({ size }) => size === 'small' ? theme.spacing(4) : theme.spacing(6),
    cursor: 'pointer',
  },
  welcome: {
    color: theme.palette.common.white,
    fontSize: ({ size }) => size === 'small' ? theme.typography.body2.fontSize : theme.typography.body1.fontSize,
  },
}));

const HeaderUI = ({ user, size = 'default' }) => {
  const classes = useStyles({ size });
  const theme = useTheme();
  return (
    <>
      <AppBar position="relative" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <img src={theme.whiteLogo} className={classes.logo} alt="Logo Header" />
          <Box ml={8}><MenuTitle size={size} selected={false}>Inicio</MenuTitle></Box>
          <Box ml={4}><MenuTitle size={size} selected={false}>Álbum</MenuTitle></Box>
          <Box ml={4}><MenuTitle size={size} selected={false}>Ayuda</MenuTitle></Box>
          <Box flexGrow={1}></Box>
          <Typography variant="body2" className={classes.welcome}>
            Bienvenido, {user}
          </Typography>
          <IconButton aria-label="account of current user" aria-controls="menu-appbar" color="secondary">
            <AccountCircle {...(size === 'small' ? {} : { fontSize: 'large' }) } />
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  );
};

HeaderUI.propTypes = {
  user: PropTypes.string,
  size: PropTypes.string,
};


export {
  HeaderUI,
};
