import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { AppHeaderUI, FloraAppCampaignsUI, MobileUI } from 'src/scenes/Home/components';


const FloraAppCampaignsPageUI = ({ syncedColor = false, campaignData }) => {
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MobileUI color="white" shadow={!isDownSm}>
      <AppHeaderUI title="Campañas" />
      <FloraAppCampaignsUI syncedColor={ syncedColor } campaignData={ campaignData } />
    </MobileUI>
  );
};

FloraAppCampaignsPageUI.propTypes = {
  syncedColor: PropTypes.bool,
  campaignData: PropTypes.object,
};

export {
  FloraAppCampaignsPageUI,
};