import React, { useState } from 'react';
import { DialogContent, DialogActions, Button, DialogTitle, Box, InputLabel,
  TextField, FormHelperText, Collapse, Typography, InputAdornment } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';

import { AlertWrapper, CoordinatesInput, DialogButton } from 'src/components';
import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';
import checkers from 'src/utils/checkers';
import { isEmpty, transformEmptyToNull } from 'src/utils/util';


const { NOT_STARTED, LOADING, SUCCESS, ERROR, BAD_DATA } = fetchStatusEnum;

const MethodologyEditDialog = props => {
  const { actions, prevMethodology } = props;

  const [ methodology, setMethodology ] = useState({ ...prevMethodology });

  const [ coords, setCoords ] = useState({
    lat: prevMethodology.latitude,
    lng: prevMethodology.longitude,
    accuracy: isEmpty(prevMethodology.accuracy) ? '' : prevMethodology.accuracy,
    altitude: isEmpty(prevMethodology.altitude) ? '' : prevMethodology.altitude,
  });

  const [ errorAlert, setErrorAlert ] = useState({ isOpen: false, message: '' });

  const [ fetchStatus, setFetchStatus ] = useState(NOT_STARTED);
  const hasBadData = fetchStatus === BAD_DATA;

  const handleChange = e => setMethodology({ ...methodology, [e.target.name]: e.target.value });
  const handleNumber = ({ target: { name, value } }) => setMethodology({ ...methodology, [name]: value ? parseInt(value, 10) : value });

  const exclusiveConditions = () => (
    isLinealTransect ? isWidthBetween1And100
    : isSamplingPoint ? isRadiusBetween1And1000
    : isShermanTraps ? isAreaBetween1And20000 && isNightBetween1And30
    : (isCameraTraps || isEchoLocationDetection) ? isNightBetween1And30
    : true
  );

  const handleConfirm = async () => {
    try {
      setFetchStatus(LOADING);
      if (hasName && hasCoords && exclusiveConditions()) {
        await actions.editMethodology({
          id: methodology.id,
          name: methodology.name,
          area: methodology.area,
          width: methodology.width,
          nights: methodology.nights,
          radius: methodology.radius,
          observation: methodology.observation,
          latitude: coords.lat,
          longitude: coords.lng,
          accuracy: coords.accuracy,
          altitude: transformEmptyToNull(coords.altitude),
        });
        setFetchStatus(SUCCESS);
        setTimeout(() => actions.closeDialog(), 300);
      } else {
        setFetchStatus(BAD_DATA);
      }
    } catch (e) {
      console.error(e);
      setErrorAlert({ isOpen: true, message: e.serverMessage || e.message });
      setFetchStatus(ERROR);
    }
  };

  const { name, radius, area, width, nights, observation } = methodology;

  const isLinealTransect = checkers.isLinealTransect(methodology.typeId);
  const isSamplingPoint = checkers.isSamplingPoint(methodology.typeId);
  const isShermanTraps = checkers.isShermanTraps(methodology.typeId);
  const isCameraTraps = checkers.isCameraTraps(methodology.typeId);
  const isEchoLocationDetection = checkers.isEchoLocationDetection(methodology.typeId);

  const hasName = Boolean(name);
  const isWidthBetween1And100 = 1 <= width && width <= 100;
  const isRadiusBetween1And1000 = 1 <= radius && radius <= 1000;
  const isAreaBetween1And20000 = 1 <= area && area <= 20000;
  const isNightBetween1And30 = 1 <= nights && nights <= 30;

  const hasCoords = !isEmpty(coords.lat) && !isEmpty(coords.lng);

  const commonInputProps = {
    fullWidth: true,
    required: true,
    autoComplete: 'off',
    variant: 'outlined',
    size: 'small',
  };

  return (
    <>
      <DialogTitle id='form-dialog-title'>Editar metodología
      </DialogTitle>
      <DialogContent>
        <Box p={1}>

          <AlertWrapper severity="info">
            <Typography variant="body2">
              Estás editando la metodología <strong>{prevMethodology.name}</strong> de
              tipo <strong>{prevMethodology.typeName}</strong> ubicada en
              la <strong>estación {prevMethodology.samplingStationName}</strong>
            </Typography>
          </AlertWrapper>

          <Box my={2}>
            <InputLabel shrink>Nombre</InputLabel>
            <TextField name="name" value={name} autoFocus error={hasBadData && !hasName} onChange={handleChange} {...commonInputProps} />
            {hasBadData && !hasName && <FormHelperText error>Debes ingresar un nombre para la metodología</FormHelperText>}
          </Box>

          <Box my={2}>
            <InputLabel shrink>Coordenadas</InputLabel>
            <CoordinatesInput required value={coords} onChange={setCoords} />
            {hasBadData && !hasCoords && <FormHelperText error>Debes ingresar latitud y longitud</FormHelperText>}
          </Box>

          {isSamplingPoint &&
            <Box my={2}>
              <InputLabel shrink>Radio</InputLabel>
              <TextField
                name="radius"
                value={radius.toString()}
                type="number"
                error={hasBadData && !isRadiusBetween1And1000}
                InputProps={{ endAdornment: <InputAdornment position="end">metros</InputAdornment> }}
                onChange={handleNumber}
                {...commonInputProps}
              />
              {hasBadData && !isRadiusBetween1And1000 && <FormHelperText error>El radio debe estar entre 1 y 1000 metros</FormHelperText>}
            </Box>
          }

          {isShermanTraps &&
            <Box my={2}>
              <InputLabel shrink>Área</InputLabel>
              <TextField
                name="area"
                value={area.toString()}
                type="number"
                error={hasBadData && !isAreaBetween1And20000}
                InputProps={{ endAdornment: <InputAdornment position="end">metros²</InputAdornment> }}
                onChange={handleNumber}
                {...commonInputProps}
              />
              {hasBadData && !isAreaBetween1And20000 && <FormHelperText error>El área debe estar entre 1 y 20.000 ㎡</FormHelperText>}
            </Box>
          }

          {isLinealTransect &&
            <Box my={2}>
              <InputLabel shrink>Ancho</InputLabel>
              <TextField
                name="width"
                value={width.toString()}
                type="number"
                error={hasBadData && !isWidthBetween1And100}
                InputProps={{ endAdornment: <InputAdornment position="end">metros</InputAdornment> }}
                onChange={handleNumber}
                {...commonInputProps}
              />
              {hasBadData && !isWidthBetween1And100 && <FormHelperText error>El ancho debe estar entre 1 y 100 metros</FormHelperText>}
            </Box>
          }

          {(isShermanTraps || isCameraTraps || isEchoLocationDetection) &&
            <Box my={2}>
              <InputLabel shrink>Noches</InputLabel>
              <TextField
                name="nights"
                value={nights.toString()}
                type="number"
                error={hasBadData && !isNightBetween1And30}
                InputProps={{ endAdornment: <InputAdornment position="end">noches</InputAdornment> }}
                onChange={handleNumber}
                {...commonInputProps}
              />
              {hasBadData && !isNightBetween1And30 && <FormHelperText error>La cantidad de noches deben estar entre 1 y 30</FormHelperText>}
            </Box>
          }

          <Box my={2}>
            <InputLabel shrink>Observaciones</InputLabel>
            <TextField
              name="observation"
              value={observation}
              minRows="2"
              maxRows='5'
              multiline
              onChange={handleChange}
              {...commonInputProps}
            />
          </Box>

          {fetchStatus === ERROR &&
            <Collapse in={errorAlert.isOpen}>
              <Alert severity="error" onClose={() => setErrorAlert(s => ({ ...s, isOpen: false }))}>
                {errorAlert.message}
              </Alert>
            </Collapse>
          }

        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>Confirmar</DialogButton>
      </DialogActions>
    </>
  );
};

MethodologyEditDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  prevMethodology: PropTypes.object,
};


export { MethodologyEditDialog };