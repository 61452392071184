import React, { useState, useEffect, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Box, Card, CardContent, Divider, CircularProgress, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { TitleContainer, ContentContainer } from 'src/components';
import { catalgosAndSourcesApi } from 'src/services';


const useSectionStyles = makeStyles(() => ({
  description: {
    whiteSpace: 'pre-wrap',
  },
  divider: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  sourceName: {
    fontWeight: 600,
  },
}));

const CatalogInfoSection = memo(({ catalog, sources }) => {
  const classes = useSectionStyles();

  const orderedSources = useMemo(() =>
    [ ...sources ].sort((a, b) => a.displayName.localeCompare(b.displayName, 'es')),
  [ sources ]);

  return <Card>
    <CardHeader title={catalog.displayName}/>
    <CardContent>
      { catalog.description &&
        <Box px={2}>
          <Typography component='p' className={classes.description}>
            { catalog.description }
          </Typography>
        </Box>
      }
      <Divider variant="middle" className={classes.divider}/>
      <Box px={2}>
        <Typography variant="h6">
          Fuentes:
        </Typography>
        <Box component="ul"> { orderedSources.map(src =>
          <Typography key={src.id} component="li">
            <Typography component="span" className={classes.sourceName}>{ src.displayName }</Typography>{
              src.url ? <>: <a href={src.url} rel='noopener noreferrer' target='_blank'>{ src.url }</a></>
              : src.id.includes('nviro-') ? <Typography component={'span'}>: fuente creada por el equipo de Nviro</Typography>
              : null
            }
          </Typography>)
        } </Box>
      </Box>
    </CardContent>
  </Card>;
}, (prev, next) => prev.catalog?.id === next.catalog.id);

CatalogInfoSection.propTypes = {
  catalog: PropTypes.object,
  sources: PropTypes.array,
};

const width100 = { width: '100%' };
const CenteredLoading = () => <Box display="flex" style={width100} alignItems="center" flexDirection="column">
  <CircularProgress/>
</Box>;

const CatalogsInfoContainer = () => {
  const [ faunaCatalog, setFaunaCatalogs ] = useState([]);
  const [ floraCatalog, setFloraCatalogs ] = useState([]);
  const [ sourcesDict, setSourcesDict ] = useState([]);
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    document.title = 'Catálogos - Nviro Capture';
    const fetchData = async () => {
      const catalogs = await catalgosAndSourcesApi.getSelectableCatalogs();
      const sources = await catalgosAndSourcesApi.getAllSources();

      setFaunaCatalogs(catalogs.filter(cat => cat.componentId === 'fauna'));
      setFloraCatalogs(catalogs.filter(cat => cat.componentId === 'flora'));
      setSourcesDict(sources.reduce((acc, src) => ({ ...acc, [src.id]: src }), {}));
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <Grid container>
      <TitleContainer>
        Catálogos
      </TitleContainer>
      { loading ? <CenteredLoading/> :
      <ContentContainer maxWidth="xl">
        <Typography variant='h4'>Flora</Typography>
        <Box mt={1} p={2}>
          { floraCatalog.map(cat =>
            <Box key={cat.id} mb={3}>
              <CatalogInfoSection catalog={cat} sources={cat.sourceIds.map(id => sourcesDict[id])} />
            </Box>,
          )}
        </Box>
        <Typography variant='h4'>Fauna</Typography>
        <Box mt={1} mb={8} p={2}>
          { faunaCatalog.map(cat =>
            <CatalogInfoSection key={cat.id} catalog={cat} sources={cat.sourceIds.map(id => sourcesDict[id])} />,
          )}
        </Box>
      </ContentContainer>
      }
    </Grid>
  );
};


export {
  CatalogsInfoContainer,
};