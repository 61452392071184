import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, TextField, InputLabel, FormHelperText, Collapse,
  InputAdornment, FormControlLabel, Checkbox } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Alert } from '@material-ui/lab';

import { AlertWrapper, AutocompleteWrapper, CoordinatesInput, DialogButton } from 'src/components';
import { santiagoCoords } from 'src/components/map/mapHelper';
import checkers, { isStation } from 'src/utils/checkers';
import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';
import { isEmpty, transformEmptyCoordsPropsToNull as tecptn } from 'src/utils/util';


// eslint-disable-next-line react/prop-types
const Strong500 = ({ children }) => <Box component="span" display="inline" fontWeight={600}>{children}</Box>;

const { NOT_STARTED, LOADING, SUCCESS, ERROR, BAD_DATA } = fetchStatusEnum;

const getOptionSelected = (opt, optSelected) => opt.value === optSelected.value;

const PointEditDialog = props => {

  const { actions, previousPointData: prevPoint, formOptions, csLang, canAddUserToCompany, userIsFan, campaignSubtypeId,
    canCreateEnvironment } = props;
  const { companyEnvironmentOptions, methodologyTypesOptions } = formOptions;
  const editedCompanyEnvironmentOptions = [ ...companyEnvironmentOptions ];
  if (prevPoint.environmentDeletedAt) {
    const envData = { value: prevPoint.environmentId, label: prevPoint.environmentName, deleted: true };
    editedCompanyEnvironmentOptions.push(envData);
  }

  const isTransect = checkers.isTransect(campaignSubtypeId);

  const ownCompanyUsersOptions = useMemo(() => formOptions.companyUsersOptions.some(uOpt => uOpt.value === prevPoint.userId) ?
    formOptions.companyUsersOptions
    : [ ...formOptions.companyUsersOptions, { value: prevPoint.userId, label: prevPoint.email, notInCompany: true } ]
  , [ formOptions.companyUsersOptions, prevPoint ]);

  const [ form, setForm ] = useState({
    // Si no se encuentra la persona responsable, entonces murió (de la empresa) y la pusimos al final de ownCompanyUserOptions.
    // si el punto esta sincronizado, se muestra la opción en el selector deshabilitado. Si no, se pone el selector vacío para que
    // pongan a alguien más
    selectedUser: formOptions.companyUsersOptions.find(user => user.value === prevPoint.userId) ?? (
      (prevPoint.statusId === 'synced' || prevPoint.synced || prevPoint.createdOnTerrain) ?
        ownCompanyUsersOptions[ownCompanyUsersOptions.length - 1]
        : null
    ),
    observation: prevPoint.observation || '',
    selectedEnvironment: editedCompanyEnvironmentOptions.find(environment => environment.value === prevPoint.environmentId),
    length: prevPoint.length || '',
    intervalDistance: prevPoint.intervalDistance || '',
    isTerrainCoordsEditable: false,
    selectedSuggestedMethodologies: isStation(campaignSubtypeId) ?
      prevPoint.suggestedMethodologies?.map(sm => (
        { value: sm.methodEnumId, label: methodologyTypesOptions.find(mt => mt.value === sm.methodEnumId).label }
      )) ?? []
      : null,
  });

  const [ coords, setCoords ] = useState({
    plannedCoords: {
      lat: prevPoint.plannedLatitude,
      lng: prevPoint.plannedLongitude,
      accuracy: !isEmpty(prevPoint.plannedAccuracy) ? prevPoint.plannedAccuracy : '',
      altitude: !isEmpty(prevPoint.plannedAltitude) ? prevPoint.plannedAltitude : '',
    },
    ...(isTransect ? {
      startTerrainCoords: {
        lat: !isEmpty(prevPoint.latitude) ? prevPoint.latitude : santiagoCoords.lat,
        lng: !isEmpty(prevPoint.longitude) ? prevPoint.longitude : santiagoCoords.lng,
        accuracy: !isEmpty(prevPoint.startAccuracy) ? prevPoint.startAccuracy : '',
        altitude: !isEmpty(prevPoint.startAltitude) ? prevPoint.startAltitude : '',
      },
      endTerrainCoords: {
        lat: !isEmpty(prevPoint.endLatitude) ? prevPoint.endLatitude : santiagoCoords.lat,
        lng: !isEmpty(prevPoint.endLongitude) ? prevPoint.endLongitude : santiagoCoords.lng,
        accuracy: !isEmpty(prevPoint.endAccuracy) ? prevPoint.endAccuracy : '',
        altitude: !isEmpty(prevPoint.endAltitude) ? prevPoint.endAltitude : '',
      },
    } : {
      terrainCoords: {
        lat: prevPoint.latitude ? prevPoint.latitude : santiagoCoords.lat,
        lng: prevPoint.longitude ? prevPoint.longitude : santiagoCoords.lng,
        accuracy: !isEmpty(prevPoint.accuracy) ? prevPoint.accuracy : '',
        altitude: !isEmpty(prevPoint.altitude) ? prevPoint.altitude : '',
      },
    }),
  });

  const [ fetchStatus, setFetchStatus ] = useState(NOT_STARTED);
  const hasBadData = fetchStatus === BAD_DATA;
  const [ errorAlert, setErrorAlert ] = useState({ isOpen: false, message: '' });

  const handleChange = e => setForm({ ...form, [e.target.name]: e.target.value });

  const handleSelect = obj => setForm(prevForm => ({ ...prevForm, ...obj }));

  const handleCoords = obj => setCoords(prevCoords => ({ ...prevCoords, ...obj }));

  const exclusiveConditions = () => (
    isTransect ? isLengthEmptyOrBetween1And500 && isIntervalDistanceEmptyOrBetween1And500 && isIntervalDistanceDivisibleByLength
    : true
  );

  const coordsConditions = () => (
    isTransect && isTerrainCoordsEditable ? hasStartTerrainCoords && hasEndTerrainCoords
    : isTerrainCoordsEditable ? hasTerrainCoords
    : true
  );

  const isSynced = prevPoint.synced;

  const handleSubmit = async () => {
    try {
      setFetchStatus(LOADING);
      if ((prevPoint.createdOnTerrain ? true : hasPlannedCoords) && exclusiveConditions() && coordsConditions()) {
        const { selectedUser, observation, selectedEnvironment } = form;
        if (selectedUser) {
          const pointToEdit = {
            userId: selectedUser.value,
            plannedCoords: tecptn(plannedCoords),
            ...(selectedEnvironment ? { environmentId: selectedEnvironment.value } : { environmentId: null }),
            ...(isTransect ? { length, intervalDistance } : {}),
            ...(isTerrainCoordsEditable ? (
              isTransect ? {
                startTerrainCoords: tecptn(startTerrainCoords),
                endTerrainCoords: tecptn(endTerrainCoords),
              } : {
                terrainCoords: tecptn(terrainCoords),
              }) : {}
            ),
            observation,
            ...(isStation(campaignSubtypeId) ?
              { suggestedMethodologies: selectedSuggestedMethodologies.map(opt => ({ methodEnumId: opt.value })) } : {}
            ),
          };
          await actions.editPoints(pointToEdit);
          setFetchStatus(SUCCESS);
          setTimeout(() => actions.toggleDialog(), 300);
        } else {
          setFetchStatus(BAD_DATA);
        }
      } else {
        setFetchStatus(BAD_DATA);
      }

    } catch (e) {
      setErrorAlert({ isOpen: true, message: e.serverMessage || e.message });
      setFetchStatus(ERROR);
    }
  };

  const { selectedUser, observation, selectedEnvironment, length,
    intervalDistance, isTerrainCoordsEditable, selectedSuggestedMethodologies } = form;

  const { plannedCoords, terrainCoords, startTerrainCoords, endTerrainCoords } = coords;

  const isLengthEmptyOrBetween1And500 = length === '' || length === null || (1 <= length && length <= 500);
  const isIntervalDistanceEmptyOrBetween1And500 = intervalDistance === '' || intervalDistance === null ||
    (1 <= intervalDistance && intervalDistance <= 500);
  const transectPoints = (length && intervalDistance) ? length / intervalDistance * 100 + 1 : 0;
  const isIntervalDistanceDivisibleByLength = (length && intervalDistance) ? length * 100 % intervalDistance === 0 : true;
  const hasLengthAndIntervalChanges = prevPoint.length !== parseInt(length, 10) ||
    prevPoint.intervalDistance !== parseInt(intervalDistance, 10);
  const hasPlannedCoords = !isEmpty(plannedCoords.lat) && !isEmpty(plannedCoords.lng);
  const hasTerrainCoords = !isTransect && isTerrainCoordsEditable && !isEmpty(terrainCoords.lat) && !isEmpty(terrainCoords.lng);
  const hasStartTerrainCoords = isTransect && isTerrainCoordsEditable
    && !isEmpty(startTerrainCoords.lat) && !isEmpty(startTerrainCoords.lng);
  const hasEndTerrainCoords = isTransect && isTerrainCoordsEditable && !isEmpty(endTerrainCoords.lat) && !isEmpty(endTerrainCoords.lng);

  const statusLabel = isTransect ? prevPoint.synced ? 'Sincronizada' : 'Pendiente'
    : formOptions.pointStatusesOptions.find(pso => pso.value === prevPoint.statusId).label;

  const isSyncedPoint = isTransect ? prevPoint.synced : prevPoint.statusId === 'synced';

  return (
    <>
      <DialogTitle id="form-dialog-title">
        <span>Editar {csLang.point}</span>
      </DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Box my={2} title={`Nombre: "${prevPoint.name}". Estado: ${statusLabel}`}>
            <AlertWrapper severity="info">
              Estás editando la {csLang.point} <strong>{prevPoint.name}</strong> que se encuentra en
              estado {statusLabel}
            </AlertWrapper>
          </Box>
          {!userIsFan &&
            <Box my={2}>
              <InputLabel shrink error={hasBadData && !form.selectedUser}>
                Asignar a { canAddUserToCompany &&
                  <Link style={{ marginLeft: 5 }} to={`/web/company/users`}>¿Necesitas más personas en la empresa?</Link>
                }
              </InputLabel>
              <AutocompleteWrapper
                disabled={prevPoint.statusId === 'synced' || prevPoint.synced || prevPoint.createdOnTerrain}
                options={ownCompanyUsersOptions} placeholder={'Seleccione responsable'}
                onChange={(event, selectedUser) => handleSelect({ selectedUser })}
                value={selectedUser}
                getOptionDisabled={ option => option.notInCompany}
                renderOption={
                  option => option.notInCompany ? <span>{option.label} <em>(ya no está en la empresa)</em></span> : option.label
                }
              />
              {hasBadData && !form.selectedUser &&
                <FormHelperText error>Debes seleccionar a alguien como responsable</FormHelperText>
              }
            </Box>
          }
          {isStation(campaignSubtypeId) &&
            <Box my={2}>
              <InputLabel shrink >
                Ambiente (opcional)
                { canCreateEnvironment &&
                  <Link style={{ marginLeft: 5 }} to={`/web/company/environment`}>
                    {editedCompanyEnvironmentOptions.length > 0 ?
                      '¿Necesitas editar ambientes?' :
                      '¿Necesitas crear ambientes?'
                    }
                  </Link>
                }
              </InputLabel>
              <AutocompleteWrapper
                disabled={editedCompanyEnvironmentOptions.length === 0 ||
                  !(prevPoint.statusId === 'synced' || prevPoint.synced)}
                options={editedCompanyEnvironmentOptions}
                onChange={(event, selectedEnvironment) => handleSelect({ selectedEnvironment })}
                clearable={true}
                value={selectedEnvironment}
              />
              {selectedEnvironment?.deleted &&
                <FormHelperText>Considere que el ambiente seleccionado ha sido eliminado</FormHelperText>}
              <Box my={2}>
                <InputLabel shrink>Metodología(s) {isSyncedPoint ? 'sugeridas' : 'a sugerir' }</InputLabel>
                <AutocompleteWrapper className="basic-multi-select"
                  options={methodologyTypesOptions}
                  value={selectedSuggestedMethodologies ?? []}
                  getOptionSelected={getOptionSelected}
                  onChange={(event, selectedSuggestedMethodologies) => handleSelect({ selectedSuggestedMethodologies })}
                  placeholder={isSyncedPoint ?
                    'Deshabilitado en estaciones ya sincronizadas' : 'Seleccione las metodologías a realizar en la estación'
                  }
                  noOptionsText="No hay más tipos de metodologías"
                  disabled={methodologyTypesOptions.length === 0 || isSyncedPoint}
                  multiple clearable
                />
              </Box>
            </Box>
          }

          {isTransect &&
            <>
              <Box my={2}>
                <InputLabel shrink disabled={!isSynced}>Largo</InputLabel>
                <TextField value={length} inputProps={{ min: 1, max: 500 }} name="length" onChange={handleChange} type="number" fullWidth
                  size="small" autoComplete="off" error={hasBadData && !isLengthEmptyOrBetween1And500} variant="outlined"
                  disabled={!isSynced} InputProps={{ endAdornment:
                    <InputAdornment position="end">
                      <Box color={!isSynced ? 'common.gray' : 'inherit'}>m</Box>
                    </InputAdornment>,
                  }}
                />
                {hasBadData && !isLengthEmptyOrBetween1And500 &&
                  <FormHelperText error>El largo debe estar entre 1 y 500</FormHelperText>
                }
                {!isSynced &&
                  <FormHelperText>
                    Para editar el <Strong500>largo</Strong500>, la transecta debe encontrarse en <Strong500>estado sincronizada</Strong500>
                  </FormHelperText>
                }
              </Box>
              <Box my={2}>
                <InputLabel shrink disabled={!isSynced}>Distancia de intervalos</InputLabel>
                <TextField value={intervalDistance} inputProps={{ min: 1, max: 500 }} name="intervalDistance" onChange={handleChange}
                  type="number" fullWidth autoComplete="off" error={hasBadData && !isIntervalDistanceEmptyOrBetween1And500} size="small"
                  variant="outlined" disabled={!isSynced} InputProps={{ endAdornment:
                    <InputAdornment position="end">
                      <Box color={!isSynced ? 'common.gray' : 'inherit'}>cm</Box>
                    </InputAdornment>,
                  }}
                />
                {isIntervalDistanceDivisibleByLength && transectPoints > 0 && hasLengthAndIntervalChanges &&
                  <FormHelperText>Se generarán <strong>{transectPoints} puntos</strong> en la transecta</FormHelperText>
                }
                {length && intervalDistance && !isIntervalDistanceDivisibleByLength &&
                  <FormHelperText error>{length * 100} debe ser divisible por {intervalDistance}</FormHelperText>
                }
                {hasBadData && !isIntervalDistanceEmptyOrBetween1And500 &&
                  <FormHelperText error>La distancia de intervalos debe estar entre 1 y 500</FormHelperText>
                }
                {!isSynced &&
                  <FormHelperText>
                    Para editar la <Strong500>distancia de intervalos</Strong500>, la
                    transecta debe encontrarse en <Strong500>estado sincronizada</Strong500>
                  </FormHelperText>
                }
              </Box>
            </>
          }

          {!prevPoint.createdOnTerrain &&
            <Box my={2}>
              <InputLabel shrink>Coordenadas planificadas</InputLabel>
              <CoordinatesInput id="planned-coords" required value={plannedCoords}
                onChange={coords => handleCoords({ plannedCoords: coords })}
                onlyCoords={isTransect}
                showAccuracy={false}
              />
              {hasBadData && !hasPlannedCoords && <FormHelperText error>Debes ingresar latitud y longitud</FormHelperText>}
            </Box>
          }

          {isSyncedPoint &&
            <FormControlLabel
              label="¿Editar coordenadas en terreno?"
              control={
                <Checkbox
                  checked={isTerrainCoordsEditable}
                  onChange={ () => handleSelect({ isTerrainCoordsEditable: !isTerrainCoordsEditable }) }
                  name="Recapturado"
                  color="primary"
                />
              }
            />
          }

          {isTerrainCoordsEditable &&
            <>
              {isTransect ?
                <>
                  <Box my={2}>
                    <InputLabel shrink>Coordenadas en terreno iniciales</InputLabel>
                    <CoordinatesInput id="start-terrain-coords" required value={startTerrainCoords}
                      onChange={coords => handleCoords({ startTerrainCoords: coords })} />
                    {hasBadData && !hasStartTerrainCoords && <FormHelperText error>Debes ingresar latitud y longitud</FormHelperText>}
                  </Box>
                  <Box my={2}>
                    <InputLabel shrink>Coordenadas en terreno finales</InputLabel>
                    <CoordinatesInput id="end-terrain-coords" required value={endTerrainCoords}
                      onChange={coords => handleCoords({ endTerrainCoords: coords })} />
                    {hasBadData && !hasEndTerrainCoords && <FormHelperText error>Debes ingresar latitud y longitud</FormHelperText>}
                  </Box>
                </> :
                <Box my={2}>
                  <InputLabel shrink>Coordenadas en terreno</InputLabel>
                  <CoordinatesInput id="terrain-coords" required value={terrainCoords}
                    onChange={coords => handleCoords({ terrainCoords: coords })} />
                  {hasBadData && !hasTerrainCoords && <FormHelperText error>Debes ingresar latitud y longitud</FormHelperText>}
                </Box>
              }
            </>
          }
          <Box my={2}>
            <InputLabel shrink>Observaciones</InputLabel>
            <TextField
              value={observation} name="observation" variant="outlined" size="small"
              onChange={handleChange}
              minRows="2" maxRows='5' multiline fullWidth autoComplete="off"
              placeholder="Ingresar observación"
            />
          </Box>

          {fetchStatus === ERROR &&
            <Collapse in={errorAlert.isOpen}>
              <Alert severity="error" onClose={() => setErrorAlert(s => ({ ...s, isOpen: false }))}>
                {errorAlert.message}
              </Alert>
            </Collapse>
          }
        </Box>

      </DialogContent>
      <DialogActions>
        <Button onClick={actions.toggleDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleSubmit} color='primary'>Editar</DialogButton>
      </DialogActions>
    </>
  );
};

PointEditDialog.propTypes = {
  previousPointData: PropTypes.object,
  actions: PropTypes.object,
  formOptions: PropTypes.object,
  csLang: PropTypes.object,
  canAddUserToCompany: PropTypes.bool,
  userIsFan: PropTypes.bool,
  campaignSubtypeId: PropTypes.string,
  canCreateEnvironment: PropTypes.bool,
};


export {
  PointEditDialog,
};
