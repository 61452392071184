import wgs84Util from 'wgs84-util';


const wgs84ToUTM = ({ latitude, longitude }) => {
  const utmCoords = wgs84Util.LLtoUTM({ type: 'Point', coordinates: [ longitude, latitude ] });
  return {
    south: utmCoords.geometry.coordinates[1],
    west: utmCoords.geometry.coordinates[0],
    timeZone: utmCoords.properties.zoneNumber,
    hemisphereLetter: utmCoords.properties.zoneLetter,
  };
};


export {
  wgs84ToUTM,
};