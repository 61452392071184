import React, { useState } from 'react';
import { InputLabel, Box, TextField, FormHelperText, Checkbox, FormControlLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ArrowForward } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'src/utils/util';

import { AutocompleteWrapper } from 'src/components';


const useStyles = makeStyles(() => ({
  paddingCheck: {
    padding: '0 9px 0 9px',
  },
}));

const TransitRecordInputs = props => {
  const classes = useStyles();
  const { record, handlers, options, validators } = props;
  const { number, height, minHeight, maxHeight, selectedRange: prevSelectedRange } = record;
  const { handleChange, handleSelect } = handlers;
  const { cardinalPointsOptions, flightTypes } = options;
  const [ currentFlightTypes, setCurrentFlightTypes ] = useState([
    ...flightTypes,
    ...(record.flightTypeId === 'other' ? [ { value: 'other', label: record.flightTypeValue } ] : []),
  ]);
  const { hasBadData, isNumberBetween1And500, isHeightBetween0And1000, isMinHeightBetween0And1000, isValidMinMax,
    isMaxHeightBetween0And1000 } = validators;

  const selectedRange = prevSelectedRange === undefined ?
    (height === null && (minHeight !== null && maxHeight !== null)) :
    prevSelectedRange;

  const selectedFrom = cardinalPointsOptions.find(cpo => cpo.value === record.from);
  const selectedTowards = cardinalPointsOptions.find(cpo => cpo.value === record.towards);
  const selectedFlightType = currentFlightTypes.find(ft => ft.value === record.flightTypeId) || { value: '', label: '' };

  const checkInRange = () => {
    handleSelect({ selectedRange: !selectedRange });
  };

  const onChangeToward = optionSelected => {
    if (optionSelected?.inputValue) {
      handleSelect({ towards: optionSelected.value });
    } else {
      const optionToSave = (optionSelected === null || optionSelected.value === '') ? null : optionSelected.value;
      handleSelect({ towards: optionToSave });
    }
  };

  const onChangeFrom = optionSelected => {
    if (optionSelected?.inputValue) {
      handleSelect({ from: optionSelected.value });
    } else {
      const optionToSave = (optionSelected === null || optionSelected.value === '') ? null : optionSelected.value;
      handleSelect({ from: optionToSave });
    }
  };

  const onChangeFlightType = optionSelected => {
    if (typeof optionSelected === 'string') {
      const selectedFlightType = currentFlightTypes.filter(e => e.label.toLowerCase() === optionSelected);
      if (selectedFlightType.length > 0) {
        handleSelect({ flightTypeId: selectedFlightType[0].value });
      } else {
        const fixedFlightTypes = currentFlightTypes.filter(e => e.value !== 'other');
        handleSelect({ flightTypeId: 'other', flightTypeOther: optionSelected });
        setCurrentFlightTypes([ ...fixedFlightTypes, { value: 'other', label: optionSelected } ]);
      }
    } else if (optionSelected?.inputValue) {
      const fixedFlightTypes = currentFlightTypes.filter(e => e.value !== 'other');
      handleSelect({ flightTypeId: 'other', flightTypeOther: optionSelected.inputValue });
      setCurrentFlightTypes([ ...fixedFlightTypes, { value: 'other', label: optionSelected.inputValue } ]);
    } else {
      const optionToSave = (optionSelected === null || optionSelected.value === '') ? null : optionSelected.value;
      handleSelect({ flightTypeId: optionToSave });
    }
  };

  const flightTypeFiltersOptions = (options, params) => {
    const isOtherFlightType = options.some(o => o.label.toLowerCase() === params.inputValue.toLowerCase());
    if (params.inputValue !== '' && !isOtherFlightType) {
      options.push({
        inputValue: params.inputValue,
        label: `Agregar tipo: "${params.inputValue}"`,
        value: 'other',
      });
    }
    return options;
  };

  return (
    <>
      <Box my={2} display="flex" width="100%">
        <Box width="inherit">
          <InputLabel shrink>Cantidad</InputLabel>
          <TextField value={number} inputProps={{ min: 1, max: 500 }} name="number" onChange={handleChange}
            type="number" fullWidth autoComplete="off" error={hasBadData && !isNumberBetween1And500} variant="outlined" size="small"
          />
          {hasBadData && !isNumberBetween1And500 && <FormHelperText error>La cantidad debe estar entre 1 y 500</FormHelperText>}
        </Box>
      </Box>

      { !selectedRange &&
        <Box my={2} display="flex" width="100%">
          <Box width="inherit">
            <InputLabel shrink>Altura</InputLabel>
            <TextField value={height} inputProps={{ min: 0, max: 1000 }} name="height" onChange={handleChange}
              type="number" fullWidth autoComplete="off" error={hasBadData && !isHeightBetween0And1000} variant="outlined" size="small"
            />
            {hasBadData && !isHeightBetween0And1000 && <FormHelperText error>La altura debe estar entre 0 y 1000 metros</FormHelperText>}
          </Box>
        </Box>
      }

      { selectedRange &&
        <Box my={2} display="flex" width="100%">
          <Box width="inherit">
            <InputLabel shrink>Altura mínima</InputLabel>
            <TextField value={minHeight} inputProps={{ min: 0, max: 1000 }} name="minHeight" onChange={handleChange}
              type="number" fullWidth autoComplete="off" error={hasBadData && (!isMinHeightBetween0And1000 || !isValidMinMax)}
              variant="outlined" size="small"
            />
            {hasBadData && !isMinHeightBetween0And1000 && <FormHelperText error>La cantidad debe estar entre 0 y 1000</FormHelperText>}
          </Box>
          <Box width="56px"></Box>
          <Box width="inherit">
            <InputLabel shrink>Altura máxima</InputLabel>
            <TextField value={maxHeight} inputProps={{ min: 0, max: 1000 }} name="maxHeight" onChange={handleChange}
              type="number" fullWidth autoComplete="off" error={hasBadData && (!isMaxHeightBetween0And1000 || !isValidMinMax)}
              variant="outlined" size="small"
            />
            {hasBadData && !isMaxHeightBetween0And1000 && <FormHelperText error>La cantidad debe estar entre 0 y 1000</FormHelperText>}
          </Box>
        </Box>
      }

      {hasBadData && !isValidMinMax && <FormHelperText error>La altura mínima no puede ser mayor que la máxima</FormHelperText>}

      <Box display="flex" width="100%">
        <FormControlLabel
          control={
            <Checkbox checked={selectedRange} onChange={checkInRange} className={classes.paddingCheck}/>
          }
          label={'¿Utilizar rango de vuelo?'}
        />
      </Box>

      <Box my={2} display="flex" width="100%" alignItems="center">
        <Box width="inherit">
          <InputLabel shrink>Desde</InputLabel>
          <AutocompleteWrapper
            clearable={ !isEmpty(selectedFrom?.value) }
            id="from"
            value={{ label: selectedFrom?.label, value: selectedFrom?.value }}
            options={cardinalPointsOptions}
            onChange={(_, optionSelected) => onChangeFrom(optionSelected)}
            getOptionSelected={(option, value) => option.value === value.value}
            placeholder='Ej: Norte'
            fixEmptyWarn
          />
        </Box>
        <Box>
          <Box mt={3} mx={1}>
            <ArrowForward fontSize="small" />
          </Box>
        </Box>
        <Box width="inherit">
          <InputLabel shrink>Hacia</InputLabel>
          <AutocompleteWrapper
            clearable={ !isEmpty(selectedTowards?.value) }
            id="towards"
            value={{ label: selectedTowards?.label, value: selectedTowards?.value }}
            options={cardinalPointsOptions}
            onChange={(_, optionSelected) => onChangeToward(optionSelected)}
            getOptionSelected={(option, value) => option.value === value.value}
            placeholder='Ej: Sur'
            fixEmptyWarn
          />
        </Box>
      </Box>

      <Box width="100%">
        <InputLabel shrink>Tipo de vuelo</InputLabel>
        <AutocompleteWrapper
          clearable
          id="flightTypeValue"
          value={{ label: selectedFlightType?.label, value: selectedFlightType?.value }}
          placeholder={'Seleccione tipo de vuelo'}
          options={currentFlightTypes}
          freeSolo
          onChange={(_, optionSelected) => onChangeFlightType(optionSelected)}
          filterOptions={flightTypeFiltersOptions}
          getOptionLabel={option => typeof option === 'string' ? option : option.label ? option.label : '' }
        />
      </Box>
    </>
  );
};

TransitRecordInputs.propTypes = {
  record: PropTypes.object.isRequired,
  options: PropTypes.object,
  handlers: PropTypes.object,
  validators: PropTypes.objectOf(PropTypes.bool),
};


export { TransitRecordInputs };