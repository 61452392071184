import React, { PureComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';

import { GraphWrapper } from 'src/components';
import { campaignStatisticsApi } from 'src/services';
import { getRandomColor, addOrReplaceOpacityInColor } from 'src/utils/util';


export class PointsByUser extends PureComponent {

  apiCall = () => campaignStatisticsApi.getPointsByUser(this.props.campaignHash);

  dataToGraphData = data => {
    const datasetsMetadata = [
      { status: 'todo', title: 'Pendientes' },
      { status: 'synced', title: 'Sincronizadas' },
    ].map(dm => ({ ...dm, color: getRandomColor() }));

    return {
      labels: data.map(d => d.email.replace(/@.*/, '')),
      datasets: datasetsMetadata.map(dm => (
        {
          label: dm.title,
          data: data.map(d => d.progress[dm.status] || 0),
          backgroundColor: addOrReplaceOpacityInColor(dm.color, 0.7),
          borderColor: 'white',
          hoverBackgroundColor: addOrReplaceOpacityInColor(dm.color, 1),
        }
      )),
    };
  };

  render() {
    const { cLang } = this.props;
    return (
      <GraphWrapper
        title={`${cLang.Points} por colector`}
        info={`Muestra la cantidad de ${cLang.points} por estado por usuario`}
        apiCall={ this.apiCall }
        dataToGraphData={ this.dataToGraphData }
        chartjsComponent={ Bar }
        legend={ { position: 'top' } }
        options={ {
          scales: {
            x: { stacked: true },
            y: { stacked: true, ticks: { maxTicksLimit: 5 } },
          },
          plugins: {
            tooltip: {
              mode: 'index',
              axis: 'y',
              callbacks: {
                afterTitle: tooltipItem => {
                  const totalRecords = tooltipItem.map(ti => parseFloat(ti.raw)).reduce((pv, cv) => pv + cv, 0);
                  return `${totalRecords} ${cLang.points}`;
                },
              },
            },
          },
        } }
      />
    );
  }
}


PointsByUser.propTypes = {
  campaignHash: PropTypes.string.isRequired,
  cLang: PropTypes.object.isRequired,
};
