import React, { PureComponent } from 'react';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';

import { GraphWrapper } from 'src/components';
import { campaignStatisticsApi } from 'src/services';


export class SpeciesSaturationCurve extends PureComponent {

  apiCall = () => campaignStatisticsApi.getSpeciesSaturationCurve(this.props.campaignHash);

  dataToGraphData = data => ({
    labels: data.map(d => d.date.replace(/\/\d{4}$/, '')),
    datasets: [
      {
        data: data.map(d => d.accum),
        label: 'Riqueza',
        backgroundColor: 'rgba(247, 147, 30, 0.7)',
        borderColor: 'rgba(247, 147, 30, 0.7)',
        hoverBackgroundColor: 'rgba(247, 147, 30, 1)',
        fill: false,
      },
    ],
  });

  render() {
    return (
      <GraphWrapper
        title='Curva de Saturación de Especies'
        info='Muestra la cantidad de especies distintas que se han registrado acumulada por día'
        apiCall={ this.apiCall }
        dataToGraphData={ this.dataToGraphData }
        chartjsComponent={ Line }
        options={ {
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              ticks: {
                callback: value => Number.isInteger(value) ? value : undefined,
              },
            },
          },
        } }
      />
    );
  }
}

SpeciesSaturationCurve.propTypes = {
  campaignHash: PropTypes.string.isRequired,
};
