import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// with thanks to https://dev.to/diraskreact/react-async-image-loading-lka
// Se recomienda usar propiedades de clases (classes, sx, lo que sea) para configurar el tamaño de img
const AsyncImage = props => {
  const [ loadedSrc, setLoadedSrc ] = useState(null);

  useEffect(() => {
    setLoadedSrc(null);
    if (props.src) {
      const handleLoad = () => {
        setLoadedSrc(props.src);
      };
      const image = new Image();
      image.addEventListener('load', handleLoad);
      image.src = props.src;
      return () => {
        image.removeEventListener('load', handleLoad);
      };
    }
  }, [ props.src ]);
  if (loadedSrc === props.src) {
    return (
      <img {...props} />
    );
  }
  return null;
};

AsyncImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};


export {
  AsyncImage,
};
