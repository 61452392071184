const floraRecordsTypesEnum = {
  'flora-record': 'Registro flora',
  'flora-individual-record': 'Registro individual',
  'forest-record': 'Registro forestal',
};

const faunaRecordsTypesEnum = {
  'fauna-direct-record': 'Registro directo',
  'fauna-indirect-record': 'Registro indirecto',
  'fauna-isolated-record': 'Registro aislado',
  'fauna-transit-record': 'Registro de tránsito',
  'fauna-presence-record': 'Registros de presencia',
};


export {
  floraRecordsTypesEnum,
  faunaRecordsTypesEnum,
};