import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SnackbarContent } from '@material-ui/core';
import { Error, Info, Warning, CheckCircle } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import { amber, green } from '@material-ui/core/colors';


const styles = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: Error,
  info: Info,
};


class StaticSnackbar extends Component {

  render() {
    const { message, variant, classes, ...props } = this.props;
    const Icon = variantIcon[variant];
    return (
      <SnackbarContent aria-describedby="client-snackbar" className={classes[variant]}
        message={<span className={classes.message} id="client-snackbar">
          <Icon className={[ classes.icon, classes.iconVariant ].join(' ')}/>{message}
        </span>}
        {...props}
      />
    );
  }
}


StaticSnackbar.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf([ 'error', 'info', 'success', 'warning' ]).isRequired,
  classes: PropTypes.object.isRequired,
};

const StaticSnackbarWithStyles = withStyles(styles)(StaticSnackbar);


export { StaticSnackbarWithStyles as StaticSnackbar };
