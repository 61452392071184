import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';

import { TableWrapper } from 'src/components';


const CompaniesList = props => {
  const { companiesList, actions } = props;

  const tableColumns = [
    /* eslint-disable react/display-name */
    { title: 'Nombre', field: 'name' },
    { title: 'Fecha creación', field: 'createdAt', render: r => <div>{new Date(r.createdAt).toLocaleDateString('es')}</div> },
  ];

  const tableActions = [
    {
      icon: () => <Edit />,
      tooltip: 'Editar',
      onClick: (event, rowData) => actions.toggleDialog({ data: { ...rowData }, type: 'edit' }),
    },
    {
      icon: () => <Delete />,
      tooltip: 'Borrar',
      onClick: (event, rowData) => actions.toggleDialog({ data: rowData.id, type: 'delete' }),
    },
  ];

  return (
    <Box width="100%">
      <TableWrapper
        columns={tableColumns}
        data={companiesList}
        actions={tableActions}
      />
    </Box>
  );
};

CompaniesList.propTypes = {
  companiesList: PropTypes.array,
  options: PropTypes.object,
  actions: PropTypes.object.isRequired,
};


export { CompaniesList };
