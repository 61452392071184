import React from 'react';
import { Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';


const useStyles = makeStyles(() => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    pointerEvents: 'auto',
  },
}));

const PopoverWrapper = ({ children, id = 'popover', ...otherProps }) => {
  const classes = useStyles();
  return (
    <Popover
      id={id}
      className={classes.popover}
      classes={{ paper: classes.paper }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      disableRestoreFocus
      {...otherProps}
    >
      {children}
    </Popover>
  );
};

PopoverWrapper.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any,
};


export { PopoverWrapper };