import React, { useState } from 'react';
import { DialogContent, Box, DialogActions, Button, DialogTitle, InputLabel, FormControlLabel, Checkbox,
  Collapse, TextField, FormHelperText } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import { DateTimePicker } from '@material-ui/pickers';

import { SpeciesNameAlt, SelectAsync, CoordinatesInput, DialogButton } from 'src/components';
import { DirectRecordInputs, IndirectRecordInputs, AgeStratumAndSexInputs, TransitRecordInputs }
  from 'src/scenes/Campaign/scenes/Records/components/FaunaRecordInputs';
import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';
import checkers from 'src/utils/checkers';
import { upperCaseFirstLetter as ucfl } from 'src/utils/formatters';
import { isEmpty } from 'src/utils/util';


const { NOT_STARTED, LOADING, SUCCESS, ERROR, BAD_DATA } = fetchStatusEnum;

const emptyOption = { label: '', value: '' };

const FaunaRecordEditDialog = props => {
  const { prevRecord, actions, options, currentUser, getOptions } = props;
  const { indirectRecordsTypesOptions, sexTypesOptions, ageStratumsOptions,
    cardinalPointsOptions, flightTypes } = options;
  const { getSpeciesOptions } = getOptions;

  const isFaunaDirectRecord = checkers.isFaunaDirectRecord(prevRecord.type);
  const isFaunaIndirectRecord = checkers.isFaunaIndirectRecord(prevRecord.type);
  const isFaunaIsolatedRecord = checkers.isFaunaIsolatedRecord(prevRecord.type);
  const isFaunaTransitRecord = checkers.isFaunaTransitRecord(prevRecord.type);

  const [ record, setRecord ] = useState({ ...prevRecord });

  const [ coords, setCoords ] = useState({
    ...(isFaunaIndirectRecord || isFaunaIsolatedRecord) ? {
      lat: prevRecord.latitude,
      lng: prevRecord.longitude,
      accuracy: isEmpty(prevRecord.accuracy) ? '' : prevRecord.accuracy,
      altitude: isEmpty(prevRecord.altitude) ? '' : prevRecord.altitude,
    } : {},
  });

  const [ renameAll, setRenameAll ] = useState(false);

  const [ fetchStatus, setFetchStatus ] = useState(NOT_STARTED);
  const hasBadData = fetchStatus === BAD_DATA;
  const [ errorAlert, setErrorAlert ] = useState({ isOpen: false, message: '' });

  const [ selectedSpecies, setSelectedSpecies ] = useState(emptyOption);

  const handleChange = e => setRecord({ ...record, [e.target.name]: e.target.value });
  const handleSelect = obj => {
    setRecord(prevRecord => ({ ...prevRecord, ...obj }));
  };

  const handleDetectionDateChange = newDetectionDate => {
    const zeroedDate = new Date(newDetectionDate);
    zeroedDate.setSeconds(0, 0);
    setRecord(pr => ({ ...pr, detectionDate: zeroedDate }));
  };

  const toggleRenameAll = () => setRenameAll(prevRenameAll => !prevRenameAll);

  const exclusiveConditions = () => (
    isFaunaDirectRecord ? !isNumberUnderOne && !isNumberUnderCurrentIndividuals
    : isFaunaIndirectRecord ? hasCoords && hasSelectedIndirectRecordType && (isRecognizableSpecies ? true : hasObservation)
    : isFaunaTransitRecord ? isNumberBetween1And500 &&
      (isHeightBetween0And1000 || (isMinHeightBetween0And1000 && isMaxHeightBetween0And1000 && isValidMinMax))
    : isFaunaIsolatedRecord ? hasCoords
    : true
  );

  const { number, methodHash, observation, type: recordType, individuals, height, minHeight, maxHeight,
    selectedRange: prevSelectedRange, detectionDate } = record;

  const selectedRange = prevSelectedRange === undefined ?
    (height === null && (minHeight !== null && maxHeight !== null)) :
    prevSelectedRange;

  const isNumberUnderOne = number < 1;
  const isNumberUnderCurrentIndividuals = number < Math.max(individuals?.length, 1);
  const hasObservation = Boolean(record.observation);
  const isNumberBetween1And500 = 1 <= number && number <= 500;
  const isHeightBetween0And1000 = !selectedRange && height ? (0 <= height && height <= 1000) : false;
  const isMinHeightBetween0And1000 = selectedRange && minHeight ? (0 <= minHeight && minHeight <= 1000) : false;
  const isMaxHeightBetween0And1000 = selectedRange && maxHeight ? (0 <= maxHeight && maxHeight <= 1000) : false;
  const isValidMinMax = minHeight && maxHeight ? minHeight <= maxHeight : true;
  const hasSelectedIndirectRecordType = Boolean(record.typeId);
  const isRecognizableSpecies = record.recognizable;

  const hasSpeciesChange = selectedSpecies.label !== '' && prevRecord.scientificName !== selectedSpecies.label;

  const captorEmail = record.email;
  const isCaptor = currentUser.email === captorEmail;

  const hasCoords = !isEmpty(coords?.lat) && !isEmpty(coords?.lng);

  const handleConfirm = async () => {
    try {
      setFetchStatus(LOADING);

      if (exclusiveConditions()) {
        await actions.updateRecord({
          recordToUpdate: {
            ...record,
            speciesId: selectedSpecies.value === '' ? record.speciesId : selectedSpecies.value,
            speciesName: selectedSpecies.value ? undefined : selectedSpecies.label,
            ...(isFaunaIndirectRecord || isFaunaIsolatedRecord) ?
              { latitude: coords.lat, longitude: coords.lng, altitude: coords.altitude, accuracy: coords.accuracy } : {},
            ...isFaunaTransitRecord ? selectedRange ? { height: null } : { maxHeight: null, minHeight: null } : {},
          },
          renameAll: renameAll && Boolean(prevRecord.scientificName), // el && es solo para asegurarme que solo se usa cuando sea válido
          initialState: prevRecord,
        }, recordType);
        setFetchStatus(SUCCESS);
        setTimeout(() => actions.closeDialog(), 300);
      } else {
        setFetchStatus(BAD_DATA);
      }

    } catch (e) {
      setErrorAlert({ isOpen: true, message: e.serverMessage || e.message });
      setFetchStatus(ERROR);
    }
  };

  return (
    <>
      <DialogTitle id='form-dialog-title'>
        Edición {prevRecord.scientificName && <Box component='span' fontStyle='italic' >{`"${prevRecord.scientificName}"`}</Box>}
      </DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Box my={2}>
            <InputLabel required shrink>Especie <em>(se mostrarán máximo 10 resultados)</em></InputLabel>
            <SelectAsync
              id="select-species"
              initialOptions={false}
              value={selectedSpecies}
              getOptions={
                ({ queryText }) =>
                  getSpeciesOptions({
                    queryText,
                    recordType: record.type,
                    recordId: record.recordId,
                    ...(!isFaunaIsolatedRecord ? { methodHash } : {}),
                  })
              }
              placeholder={ucfl(record.scientificName || 'Seleccionar una especie')}
              useScientificNameFormat
              useScientificNameFormatInPlaceholder={record.scientificName !== null}
              freeSolo
              onChange={optionSelected => {
                if (typeof optionSelected === 'string') { // Cuando se selecciona con enter llega un string, magic
                  setSelectedSpecies({ label: optionSelected, value: undefined });
                } else if (optionSelected?.inputValue) { // Cuando se agrega un nuevo tipo
                  setSelectedSpecies({ label: optionSelected.inputValue, value: undefined });
                } else { // Cuando se selecciona normalmente
                  setSelectedSpecies({ label: optionSelected.label, value: optionSelected.value });
                }
              }}
              filterOptions={(options, params) => {
                const speciesAlreadyExist = (
                  options.some(o => o.label.toLowerCase() === params.inputValue.toLowerCase() && o.userCreatorInfo === 'ti')
                );
                if (params.inputValue !== '' && !speciesAlreadyExist) {
                  options.push({
                    inputValue: params.inputValue,
                    label: `Agregar "${params.inputValue}"`,
                  });
                }
                return options;
              }}
              getOptionLabel={option => typeof option === 'string' ? option : option.label ? option.label : '' }
            />
          </Box>
          { prevRecord.scientificName &&
            <Box my={2}>
              <FormControlLabel
                control={<Checkbox checked={renameAll} onChange={toggleRenameAll} />}
                label="¿Renombrar todos los registros a la misma especie?"
              />
              <Collapse in={hasSpeciesChange && renameAll}>
                <Alert variant="filled" severity='warning'>
                  {hasSpeciesChange &&
                    <>
                      Esto <strong>renombrará todos {isCaptor ? 'tus' : 'los'} registros</strong> que tengan
                      la especie <SpeciesNameAlt name={prevRecord.scientificName} /> a <SpeciesNameAlt name={selectedSpecies.label} />
                      {!isCaptor && <>que hayan sido registradas por {captorEmail}</>}
                      .
                    </>
                  }
                </Alert>
              </Collapse>
            </Box>
          }
          {isFaunaDirectRecord &&
            <DirectRecordInputs
              record={record}
              handlers={{ handleSelect }}
              validators={{ isNumberUnderOne, isNumberUnderCurrentIndividuals, hasBadData }}
            />
          }

          {(isFaunaIndirectRecord || isFaunaIsolatedRecord) &&
            <Box my={2}>
              <InputLabel shrink>Coordenadas</InputLabel>
              <CoordinatesInput required value={coords} onChange={setCoords} />
              {hasBadData && !hasCoords && <FormHelperText error>Debes ingresar latitud y longitud</FormHelperText>}
            </Box>
          }

          {isFaunaIndirectRecord &&
            <IndirectRecordInputs
              record={record}
              handlers={{ handleSelect }}
              options={{ indirectRecordsTypesOptions }}
              validators={{ hasBadData, hasSelectedIndirectRecordType }}
            />
          }
          {isFaunaIsolatedRecord &&
            <AgeStratumAndSexInputs
              record={record}
              handlers={{ handleSelect }}
              options={{ sexTypesOptions, ageStratumsOptions }}
            />
          }
          {isFaunaTransitRecord &&
            <TransitRecordInputs
              record={record}
              handlers={{ handleChange, handleSelect }}
              validators={{ hasBadData, isNumberBetween1And500, isHeightBetween0And1000,
                isMinHeightBetween0And1000, isMaxHeightBetween0And1000, isValidMinMax }}
              options={{ cardinalPointsOptions, flightTypes }}
            />
          }

          <Box my={2}>
            <InputLabel shrink>Fecha de detección</InputLabel>
            <DateTimePicker
              value={detectionDate}
              selected={detectionDate}
              autoOk
              onChange={handleDetectionDateChange}
              format="DD-MM-YYYY HH:mm"
              TextFieldComponent={props => <TextField {...props} variant="outlined" size="small" />}
              ampm={false}
            />
          </Box>

          <Box my={2}>
            <InputLabel shrink required={isFaunaIndirectRecord && !isRecognizableSpecies}>Observaciones</InputLabel>
            <TextField
              value={observation} name="observation" variant="outlined" size="small" onChange={handleChange}
              minRows="2" maxRows='5' multiline fullWidth autoComplete="off"
              error={isFaunaIndirectRecord && hasBadData && !hasObservation && !isRecognizableSpecies}
            />
            {isFaunaIndirectRecord && hasBadData && !hasObservation && !isRecognizableSpecies &&
              <FormHelperText error>Debes agregar una observación</FormHelperText>}
          </Box>

          {fetchStatus === ERROR &&
            <Collapse in={errorAlert.isOpen}>
              <Alert severity="error" onClose={() => setErrorAlert(s => ({ ...s, isOpen: false }))}>
                {errorAlert.message}
              </Alert>
            </Collapse>
          }

        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>Editar</DialogButton>
      </DialogActions>
    </>
  );
};

FaunaRecordEditDialog.propTypes = {
  prevRecord: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  options: PropTypes.object,
  getOptions: PropTypes.object,
};


export { FaunaRecordEditDialog };