import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { HomeContainer } from 'src/scenes/Home/HomeContainer';
import { WebContainer } from 'src/scenes/WebContainer';
import { MaintenanceContainer } from 'src/scenes/Maintenance/MaintenanceContainer';
import { EmailVerificationContainer, LoginContainer,
  RegisterContainer, ResetPasswordContainer } from 'src/scenes/Home/scenes';


const AppRoutes = () => (
  <Router>
    <Switch>
      <Route path="/reset-password/:token" component={ResetPasswordContainer} />
      <Route path="/verify-email/:token" component={EmailVerificationContainer} />
      <Route path="/web" component={WebContainer} />
      <Route path="/login" component={LoginContainer} />
      <Route path="/register" component={RegisterContainer} />
      <Route path="/maintenance" component={MaintenanceContainer} />
      <Route path="/" component={HomeContainer} />
    </Switch>
  </Router>
);


export { AppRoutes };
