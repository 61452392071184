import React, { useEffect, useState } from 'react';
import { Grid, Box, Paper, Typography, CircularProgress, Zoom, useMediaQuery, IconButton,
  FormControlLabel, Checkbox } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Error, ArrowBack } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { NviroButton, MaintenanceAlert } from 'src/components';
import { NviroTextField, CoolBackground } from 'src/scenes/Home/components';
import { usersApi } from 'src/services';
import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';


const useStyles = makeStyles(theme => ({
  container: {
    height: '100vh',
  },
  grid: {
    zIndex: 0,
  },
  paper: {
    width: '344px',
    paddingRight: theme.spacing(12),
    paddingLeft: theme.spacing(12),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      width: '344px',
    },
    [theme.breakpoints.down('xs')]: {
      fallbacks: [
        { width: 'fill-available' },
        { width: '-moz-available' },
        { width: '-webkit-fill-available' },
      ],
    },
  },
  title: {
    color: theme.palette.common.gray600,
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '1.5rem',
  },
  registerText: {
    textTransform: 'uppercase',
    fontWeight: 600,
    cursor: 'pointer',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },
  logo: {
    marginTop: theme.spacing(20) * -1,
    paddingBottom: theme.spacing(5),
    cursor: 'pointer',
    height: '80px',
    [theme.breakpoints.down('sm')]: {
      height: '72px',
      marginTop: theme.spacing(25) * -1,
    },
  },
  helperText: {
    fontSize: '0.875rem',
  },
  backButton: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  errorMessage: {
    color: theme.palette.error.main,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  termsText: {
    [theme.breakpoints.down('sm')]: {
      width: 'unset',
      fontSize: '0.875rem',
    },
  },
  link: {
    pointerEvents: 'auto',
    color: theme.palette.nviro.primary.main,
    '&:visited': {
      color: theme.palette.nviro.primary.dark,
    },
    '&:hover': {
      filter: 'brightness(1.2)',
    },
  },
}));

const loginStepsEnum = {
  SET_USER: 'set_user',
  SET_PASSWORD: 'set_password',
};


const emailRegexp = /^.+@.+$/;

const LoginContainer = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [ credentials, _setCredentials ] = useState({ email: '', password: '' });

  const { SET_USER, SET_PASSWORD } = loginStepsEnum;
  const [ step, setStep ] = useState(SET_USER);

  const { NOT_STARTED, LOADING, SUCCESS, ERROR } = fetchStatusEnum;
  const [ submitStatus, setSubmitStatus ] = useState(NOT_STARTED);
  const [ recoverPasswordStatus, setRecoverPasswordStatus ] = useState(NOT_STARTED);
  const [ successMsg, setSuccessMsg ] = useState();
  const [ errorMsg, setErrorMsg ] = useState();
  const [ emailError, setEmailError ] = useState();

  const [ needsToAcceptTerms, setNeedsToAcceptTerms ] = useState(false);
  const [ termsAndPolicyAccepted, setTermsAndPolicyAccepted ] = useState(false);
  const [ termsError, setTermsError ] = useState(false);


  const { email, password } = credentials;

  useEffect(() => {
    document.title = 'Inicio sesión - Nviro Capture';
    const redirectIfUserIsAuthenticated = async () => {
      const isAuthenticated = await usersApi.isAuthenticated();
      if (isAuthenticated) {
        history.push('web');
      }
    };

    redirectIfUserIsAuthenticated();

    // eslint-disable-next-line
  }, []);

  const setCredentials = newCredentials => {
    _setCredentials(newCredentials);
    setEmailError(undefined);
  };

  const goToUserForm = () => {
    setSubmitStatus(NOT_STARTED);
    setStep(SET_USER);
    setRecoverPasswordStatus(NOT_STARTED);
  };

  const goToRegistration = () => history.push('register');

  const goToPasswordForm = () => {
    if (credentials.email === '') {
      setEmailError('Debes introducir un email.');
    } else if (!credentials.email.match(emailRegexp)) {
      setEmailError('El email ingresado no tiene un formato válido.');
    } else {
      setStep(SET_PASSWORD);
    }
  };

  const handleSubmit = async () => {

    const query = new URLSearchParams(location.search);
    const needLogin = query.get('needLogin') === 'true';

    try {
      if (submitStatus === LOADING) {
        return;
      }
      setSubmitStatus(LOADING);
      if (needsToAcceptTerms && !termsAndPolicyAccepted) {
        setSubmitStatus(NOT_STARTED);
        setTermsError(true);
        return;
      }

      const authenticationFn = needsToAcceptTerms ? usersApi.authenticateAcceptingTerms : usersApi.authenticate;

      const { lastCompanyVisited, ...user } = await authenticationFn(credentials);
      if (user) {
        if (!user.termsAndPolicyAccepted) {
          setSubmitStatus(NOT_STARTED);
          setNeedsToAcceptTerms(true);
          return;
        }
        setSubmitStatus(SUCCESS);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('company', JSON.stringify(lastCompanyVisited));

        const prevUrl = document.referrer !== window.location.href && document.referrer;
        if (needLogin && prevUrl) {
          const urlWithoutDomain = prevUrl.replace(/^(https?:\/\/)?.*?\//, '/');
          history.replace(urlWithoutDomain);
        } else {
          setTimeout(() => history.push('/web'), 500);
        }

      } else {
        setSubmitStatus(ERROR);
        setErrorMsg('Problemas para iniciar sesión, intente más tarde.');
      }
    } catch (e) {
      setSubmitStatus(ERROR);
      setErrorMsg(e.serverMessage || 'Problemas para iniciar sesión, intente más tarde.');
    }
  };

  const keyDownIsEnter = e => e.key === 'Enter';
  const keyDownIsEscape = e => e.key === 'Escape';

  const handleKeyDown = e => {
    if (keyDownIsEnter(e)) {
      step === SET_USER && goToPasswordForm();
      step === SET_PASSWORD && handleSubmit();
    }
    if (keyDownIsEscape(e)) {
      step === SET_PASSWORD && goToUserForm();
    }
  };

  const handleRecoverPassword = async () => {
    try {
      setRecoverPasswordStatus(LOADING);
      if (email) {
        const { message } = await usersApi.recoverPassword({ email });
        setRecoverPasswordStatus(SUCCESS);
        setSuccessMsg(message);
      }
    } catch (e) {
      setRecoverPasswordStatus(ERROR);
      setErrorMsg(e.serverMessage || 'Error al recuperar contraseña, intente más tarde');
    }
  };

  return (
    <Grid className={classes.container} direction="column" container alignItems="center" justifyContent="center">
      <CoolBackground />
      <Grid item className={classes.grid}>
        <img className={classes.logo} src={theme.whiteLogo} alt="nviro-logo" onClick={() => history.push('/')} />
      </Grid>
      <Grid item className={classes.grid} container justifyContent="center">
        <Box display="flex" flexDirection="column" alignItems="center">
          <MaintenanceAlert />
          <Paper className={classes.paper} elevation={5} onKeyDown={handleKeyDown}>
            {step === SET_USER &&
              <>
                <Typography className={classes.title}>Iniciar sesión</Typography>
                <Box pt={5} width="100%">
                  <NviroTextField
                    size={isDownSm ? 'small' : 'medium'}
                    value={email}
                    color="secondary"
                    name="email"
                    type="text"
                    label="Correo electrónico"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Ingresa tu correo electrónico"
                    fullWidth
                    onChange={e => setCredentials({ email: e.target.value, password })}
                    autoFocus
                    autoComplete="email"
                    FormHelperTextProps= { { component: 'div' }}
                    helperText={ emailError &&
                      <Box display="flex" alignItems="center" component="span">
                        <Box mr={1}><Error /></Box>
                        <Typography display="initial" className={classes.helperText}>{emailError}</Typography>
                      </Box>
                    }
                    error={Boolean(emailError)}
                  />
                </Box>
                <Box pt={{ xs: 2, md: 5 }} width="100%">
                  <NviroButton size={isDownSm ? 'small' : 'medium'} onClick={goToPasswordForm}>Continuar</NviroButton>
                </Box>
                <Box pt={4}>
                  <Typography className={classes.registerText} onClick={goToRegistration} color="primary">Crear cuenta</Typography>
                </Box>
              </>
            }
            {step === SET_PASSWORD &&
              <>
                <Box p={2} className={classes.backButton}>
                  <IconButton onClick={goToUserForm}>
                    <ArrowBack fontSize="large" />
                  </IconButton>
                </Box>
                <Typography className={classes.title}>Iniciar sesión</Typography>
                <Box pt={5} width="100%">
                  <NviroTextField
                    size={isDownSm ? 'small' : 'medium'}
                    value={password}
                    color="secondary"
                    name="password"
                    label="Contraseña"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Ingresa tu contraseña"
                    fullWidth
                    onChange={e => setCredentials({ email, password: e.target.value })}
                    type="password"
                    autoFocus
                    FormHelperTextProps= { { component: 'div' }}
                    helperText={submitStatus === ERROR &&
                      <Box display="flex" alignItems="center" component="span">
                        <Box mr={1}><Error /></Box>
                        <Typography display="initial" className={classes.helperText}>{errorMsg}</Typography>
                      </Box>
                    }
                    error={submitStatus === ERROR}
                  />
                </Box>
                { needsToAcceptTerms &&
                  <Box width="100%" className={classes.formInput} mt={2} pl={1}>
                    <FormControlLabel required style={{ pointerEvents: 'none' }}
                      control={ <Checkbox checked={ termsAndPolicyAccepted } style={{ pointerEvents: 'auto' }}
                        onChange={ e => setTermsAndPolicyAccepted(e.target.checked) }
                      /> }
                      label={<Typography className={classes.termsText}>
                        Para completar su registro marque la casilla indicando que acepta
                        los <Link className={classes.link} target="_blank" to='/terms'>Términos de Uso</Link> y
                        las <Link className={classes.link} target="_blank" to='/privacy'>Políticas de Privacidad</Link>
                      </Typography>}
                    />
                    { termsError && !termsAndPolicyAccepted &&
                      <Box display="flex" alignItems="center" component="span" className={classes.errorMessage}>
                        <Box mr={1}><Error/></Box>
                        <Typography display="initial" className={classes.helperText}>
                          Debes aceptar para poder terminar tu registro.
                        </Typography>
                      </Box>
                    }
                  </Box>
                }
                <Box pt={{ xs: 2, md: 5 }} width="100%">
                  <NviroButton
                    loading={submitStatus === LOADING}
                    success={submitStatus === SUCCESS}
                    onClick={handleSubmit}
                    size={isDownSm ? 'small' : 'medium'}
                  >
                    Confirmar
                  </NviroButton>
                </Box>
                <Box pt={4} width="100%">
                  {recoverPasswordStatus === NOT_STARTED &&
                    <Typography className={classes.registerText} color="primary" onClick={handleRecoverPassword}>
                      Recuperar contraseña
                    </Typography>
                  }
                  {recoverPasswordStatus === LOADING && <CircularProgress size={35} thickness={4.8} color="secondary" /> }
                  {recoverPasswordStatus === ERROR &&
                    <Zoom in>
                      <Alert severity="error">{errorMsg}</Alert>
                    </Zoom>
                  }
                  {recoverPasswordStatus === SUCCESS &&
                    <Zoom in>
                      <Alert severity="success">{successMsg}</Alert>
                    </Zoom>
                  }
                </Box>
              </>
            }
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
};


export {
  LoginContainer,
};
