import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography, Container, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { NviroBreadcrumbs } from 'src/components';
import { arraysAreEqual } from 'src/utils/util';


const useStyles = makeStyles(theme => ({
  containerTitle: {
    paddingTop: props => props.breadcrumbs ? theme.spacing(2) : theme.spacing(5),
    paddingBottom: theme.spacing(1),
  },
}));

const TitleContainer = ({ children, breadcrumbs, ...props }) => {
  const classes = useStyles({ breadcrumbs });
  return (
    <Container className={classes.containerTitle} maxWidth={ false } {...props}>
      {breadcrumbs && <NviroBreadcrumbs breadcrumbs={breadcrumbs} />}
      <Typography variant="h3" display="inline" color="primary">{children}</Typography>
      <Divider />
    </Container>
  );
};

TitleContainer.propTypes = {
  children: PropTypes.any,
  breadcrumbs: PropTypes.array,
};

const areEqual = (prevProps, nextProps) => prevProps.breadcrumbs && arraysAreEqual(prevProps.breadcrumbs, nextProps.breadcrumbs);

const MemoizedTitleContainer = memo(TitleContainer, areEqual);


export {
  MemoizedTitleContainer as TitleContainer,
};