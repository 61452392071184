import React from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';


// NOTAR: Esto no es un reemplazo para la validación, es solo para *intentar* que el input deje escribir solo lo que debería
// y bajar las probabilidades de que se escriban cosas en el input que se verán, pero que por debajo se descartan y no pueden ser
// validadas

const onKeyDown = event => {
  // permite números, teclas de navegación varias, y las F-etc
  const regex = new RegExp(/(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight|Control|Alt|Enter|Home|End|Command)|(^F\d{1,2}$)/);
  // permitir varias funciones y formas de navegación que usan ctrl, alt, command (esta lista ni es completa ni necesariamente libre
  // de bugs :>? pero es mejorable agregando cosas o conviertiendo a listas separadas para ctrl, alt y command si se necesita)
  const allowedMetaAltCtrlKeys = [ 'c', 'v', 'z', 'd', 's', 'e', 'f', 'n', 'p', 'a', 'y' ];
  if (event.key.match(regex) || ((event.ctrlKey || event.metaKey || event.altKey) && allowedMetaAltCtrlKeys.includes(event.key))) {
    return;
  }
  event.preventDefault();
};

// permitir pegar cosas, pero solo si son puros números
const onPaste = event => {
  const pasteData = event.clipboardData.getData('text');
  if (!/^\d*$/.test(pasteData)) {
    event.preventDefault();
    return false;
  }
};

const NaturalNumberField = ({ inputProps, allowZero = false, ...props }) =>
  <TextField
    { ...props }
    inputProps={{ ...(inputProps ?? {}), min: allowZero ? 0 : 1 }}
    type="number"
    onKeyDown={onKeyDown}
    onPaste={onPaste}
  />;

NaturalNumberField.propTypes = {
  inputProps: PropTypes.object,
  allowZero: PropTypes.bool,
};


export { NaturalNumberField };
