import React, { memo, useEffect } from 'react';
import { Grid, Box, Typography, Link, Hidden, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PhoneAndroid, FilterVintage, Pets } from '@material-ui/icons';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router';
import * as Scroll from 'react-scroll';
import PropTypes from 'prop-types';

import { DownloadablesPageUI, FloraAppRecordsPageUI } from 'src/scenes/Home/components';
import { MapSection, BenefitsSection, ClientsSection } from 'src/scenes/Home/scenes/CoverPage/components';
import { NviroButton, MaintenanceAlert, AlertWrapper } from 'src/components';
import config from 'src/config/local';
import { getMaintenanceStatus } from 'src/utils/util';
import { Mushroom } from 'src/utils/mdIcons';
import { useServerMaintenanceOn } from 'src/hooks';


const isTestServer = config.webUrl.includes('test');

const floraAndroidUrl = `https://play.google.com/store/apps/details?id=cl.nviro.flora${isTestServer ? '.test' : ''}`;
const floraIosUrl = isTestServer ? 'https://apps.apple.com/us/app/testflight/id899247664' : 'https://apps.apple.com/app/id1576257451';

const faunaAndroidUrl = `https://play.google.com/store/apps/details?id=cl.nviro.fauna${isTestServer ? '.test' : ''}`;
const faunaIosUrl = isTestServer ? 'https://apps.apple.com/us/app/testflight/id899247664' : 'https://apps.apple.com/app/id1576421200';

const Element = Scroll.Element;
const scroller = Scroll.scroller;

const useStyles = makeStyles(theme => ({
  mainContainer: {
    overflow: 'hidden',
  },
  textSectionContainer: {
    marginTop: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  title: {
    fontSize: '4.375rem',
    fontWeight: 'bold',
    lineHeight: '90px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3.125rem',
      lineHeight: '58px',
    },
  },
  titleSectionOne: {
    color: theme.palette.common.white,
  },
  titleSectionTwo: {
    color: theme.palette.common.black,
  },
  subtitle: {
    color: theme.palette.common.white,
    fontSize: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.875rem',
      lineHeight: '34px',
    },
  },
  coverButtons: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignContent: 'center',
    },
  },
  button: {
    minWidth: theme.spacing(17),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(36),
    },
  },
  browserComponent: {
    width: theme.spacing(160),
    position: 'relative',
    zIndex: 2,
    marginTop: -1 * theme.spacing(12),
    marginLeft: theme.spacing(30),
  },
  mobileComponent: {
    position: 'relative',
    zIndex: 3,
    marginTop: -1 * theme.spacing(65),
    marginLeft: theme.spacing(12),
  },
  coverSection: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing(22),
    paddingBottom: theme.spacing(20),
    paddingRight: theme.spacing(21),
    paddingLeft: theme.spacing(21),

    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(10),
      paddingRight: theme.spacing(5),
      paddingLeft: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
  benefitsSection: {
    backgroundColor: theme.palette.common.lightGray,
    paddingTop: theme.spacing(13),
    paddingBottom: theme.spacing(20),
    paddingRight: theme.spacing(21),
    paddingLeft: theme.spacing(21),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0),
    },
  },
  appsSection: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(10),
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  clientsSection: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    paddingRight: theme.spacing(21),
    paddingLeft: theme.spacing(21),
    backgroundColor: theme.palette.common.lightGray,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  appsText: {
    color: theme.palette.common.black,
    textAlign: 'center',
  },
  appsIcon: {
    color: theme.palette.common.white,
    fontSize: '5.5rem',
  },
  storeLinkContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  downloadAppBtns: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: theme.spacing(3),
    },
  },
  downloadAppBtn: {
    height: '4rem',
    width: '208px',
    [theme.breakpoints.down('sm')]: {
      height: '64px',
      width: '200px',
    },
  },
  appsContainer: {
    paddingTop: theme.spacing(5),
  },
  downloadAppBtnCS: {
    zIndex: 0,
    filter: 'blur(3px)',
    opacity: 0.3,
  },
  comingSoon: {
    position: 'absolute',
    zIndex: 1,
    color: theme.palette.common.white,
    border: `2px solid ${theme.palette.common.white}`,
    borderRadius: '8px',
    width: '202px',
    height: '60px',
    marginLeft: '16px',
    textAlign: 'center',
    fontSize: '1.7rem',
    lineHeight: 2.2,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
      width: '192px',
      height: '60px',
      lineHeight: 2.8,
      fontWeight: 500,
    },
  },
  appLogo: {
    [theme.breakpoints.down('sm')]: {
      height: '90px',
    },
  },
  alert: {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 16%)',
    width: '98%',
  },
  icons: {
    fontSize: '20px',
  },
}));

const StoreLink = memo(({ url, ios = false }) => {
  const classes = useStyles();

  const storeSrc = `/img/landing/${ ios ? 'app-store-btn' : 'google-play-btn' }.png`;
  const storeAlt = ios ? 'app-store' : 'google-play';

  return url ?
    <Box className={ classes.storeLinkContainer }>
      <Link href={ url } target="_blank" rel="noreferrer">
        <img className={classes.downloadAppBtn} src={ storeSrc } alt={ storeAlt } />
      </Link>
    </Box> :
    <Box display="flex">
      <Typography className={classes.comingSoon}>Próximamente</Typography>
      <Box className={ classes.storeLinkContainer }>
        <img className={ clsx(classes.downloadAppBtn, classes.downloadAppBtnCS) } src={ storeSrc } alt={ storeAlt } />
      </Box>
    </Box>;
});

StoreLink.propTypes = {
  url: PropTypes.string,
  ios: PropTypes.bool,
};

const CoverPageContainer = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const isServerMaintenanceOn = useServerMaintenanceOn();

  useEffect(() => {
    document.title = 'Nviro Capture';
  }, []);


  if (location.hash === '#apps') {
    scroller.scrollTo('apps', { duration: 700, smooth: true, spy: true });
  }

  const { isMaintenanceScheduledOrOn } = getMaintenanceStatus();

  return (
    <Box className={classes.mainContainer}>
      {isMaintenanceScheduledOrOn || isServerMaintenanceOn ?
        <MaintenanceAlert /> :
        <AlertWrapper TransitionComponent={Fade} BoxProps={{ className: classes.alert }} variant="filled" severity="warning" icon={
          <Box display="flex" alignItems="center">
            <FilterVintage className={classes.icons} />
            <Pets className={classes.icons} />
            <Mushroom className={classes.icons} />
          </Box>}
        >
          Con nuestra aplicación podrás descargar los reportes de biodiversidad bajo el estándar Darwin Core
          exigido por la SMA en la Res. Ex. SMA Nº343/2022.
        </AlertWrapper>
      }
      <Grid container className={classes.coverSection}>
        <Grid className={classes.textSectionContainer} item xs={12} md={5}>
          {/* <Hidden smDown>
            <Typography className={clsx(classes.title, classes.titleSectionOne)}>Un entorno</Typography>
            <Typography className={clsx(classes.title, classes.titleSectionOne)}>adecuado</Typography>
          </Hidden>
          <Hidden mdUp>
            <Typography className={clsx(classes.title, classes.titleSectionOne)}>Un entorno adecuado</Typography>
          </Hidden>
          <Typography className={clsx(classes.title, classes.titleSectionTwo)}>en terreno</Typography>
          <Box mt={1}>
            <Typography className={classes.subtitle} variant="h2" color="textSecondary">
              Ahorra tiempo de captura y procesa automáticamente los resultados
            </Typography>
          </Box> */}
          <Typography className={clsx(classes.title, classes.titleSectionOne)}>Plataforma para la captura y gestión de</Typography>
          {/* TODO, autowrite con "Planificación, Terreno, Análisis" */}
          <Typography className={clsx(classes.title, classes.titleSectionTwo)}>biodiversidad</Typography>
          <Box mt={1}>
            <Typography className={classes.subtitle} variant="h2" color="textSecondary">
              Ahorra tiempo en terreno y procesa automáticamente tus datos bióticos
            </Typography>
          </Box>
          <Grid container className={classes.coverButtons}>
            <Grid item xs={12} md={6}>
              <Box mr={{ xs: 0, md: 2 }} mb={{ xs: 1, md: 0 }}>
                <NviroButton className={classes.button} color="black" onClick={() => history.push('/register')}>
                  Comenzar
                </NviroButton>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box ml={{ xs: 0, md: 2 }} mt={{ xs: 1, md: 0 }}>
                <NviroButton
                  className={classes.button}
                  onClick={() => scroller.scrollTo('apps', { duration: 700, smooth: true, spy: true })}
                >
                  Descargar apps
                </NviroButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid item md={7}>
            <Box className={classes.browserComponent}>
              <DownloadablesPageUI />
            </Box>
            <Box className={classes.mobileComponent}>
              <FloraAppRecordsPageUI />
            </Box>
          </Grid>
        </Hidden>
      </Grid>
      <Grid container justifyContent="center" className={classes.benefitsSection}>
        <BenefitsSection />
      </Grid>
      <Element>
        <Grid className={classes.appsSection} id="apps" container alignItems="center" direction="column">
          <Grid item>
            <PhoneAndroid className={classes.appsIcon} />
          </Grid>
          <Grid item>
            <Typography className={clsx(classes.title, classes.appsText)}>
              Descarga las aplicaciones
            </Typography>
          </Grid>
          <Grid item>
            <Grid container className={classes.appsContainer}>
              <Grid item xs={12} md={6}>
                <Box px={5} mt={{ xs: 10, md: 2 }} display="flex" flexDirection="column">
                  <img className={classes.appLogo} height={112} src="/img/logos/flora-color-white.svg" alt="flora-logo" />
                  <Box pt={3} display="flex" className={classes.downloadAppBtns}>
                    <StoreLink url={ floraAndroidUrl } />
                    <StoreLink url={ floraIosUrl } ios />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box px={5} mt={{ xs: 10, md: 2 }} display="flex" flexDirection="column">
                  <img className={classes.appLogo} height={112} src="/img/logos/fauna-color-white.svg" alt="fauna-logo" />
                  <Box pt={3} display="flex" className={classes.downloadAppBtns}>
                    <StoreLink url={ faunaAndroidUrl }/>
                    <StoreLink url={ faunaIosUrl } ios />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Element>
      <Grid container justifyContent="center" alignItems="center">
        <MapSection />
      </Grid>
      <Grid container alignItems="center" direction="column" className={classes.clientsSection}>
        <ClientsSection />
      </Grid>
    </Box>
  );
};


export { CoverPageContainer } ;