import React, { Component, Fragment } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { PersonAdd } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { WebContext } from 'src/scenes/web-context';
import { CompanyDrawer, TitleContainer, ContentContainer, ActionsBox, DialogWrap } from 'src/components';
import { UserLinkDialog, UserUnlinkDialog, UsersList, UserEditDialog } from 'src/scenes/CompanyUsers/components';
import { upperCaseFirstLetter } from 'src/utils/formatters';
import { usersApi } from 'src/services';


const useStyles = () => ({
  mainContainer: {
    width: 'calc(100% - 240px)',
  },
});

class CompanyUsersContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      usersList: [],
      rolesOptions: [],
      dialog: {
        isOpen: false,
        data: {},
        type: '',
      },
    };
  }

  toggleDialog = ({ data = {}, type = '' }) => this.setState(prevState => ({ dialog: { isOpen: !prevState.dialog.isOpen, data, type } }));

  closeDialog = () => this.setState({ dialog: { isOpen: false, type: '' } });

  getDialogType = () => {
    const { dialog, rolesOptions } = this.state;
    switch (dialog.type) {
      case 'edit':
        return <UserEditDialog
          user={dialog.data}
          rolesOptions={rolesOptions}
          actions={{ editUser: this.editUser, closeDialog: this.closeDialog }}
        />;
      case 'delete':
        return <UserUnlinkDialog
          actions={{ closeDialog: this.closeDialog, unlinkUser: () => this.unlinkUser(dialog.data) }}
        />;
      case 'create':
        return <UserLinkDialog rolesOptions={rolesOptions} currentUsers={ this.state.usersList }
          actions={{ closeDialog: this.closeDialog, linkUser: this.linkUser, getUser: this.getUser }}
        />;
      default:
        break;
    }
  };

  componentDidMount = async () => {
    const [ usersList, rolesList ] = await Promise.all([
      usersApi.getUsersByCompany(),
      usersApi.getCompanyRoles(),
    ]);
    const rolesOptions = rolesList.map(({ id, value }) => ({ value: id, label: upperCaseFirstLetter(value) }));
    this.setState({ usersList, rolesOptions });
    document.title = 'Personas - Nviro Capture';
  };

  getUser = async email => await usersApi.getOne(email);

  linkUser = async newUser => {
    const linkedUser = await usersApi.linkUser(newUser);
    this.setState(prevState => ({
      usersList: [ linkedUser, ...prevState.usersList ],
      dialog: { isOpen: false },
    }));
  };

  unlinkUser = async userId => {
    try {
      await usersApi.unlinkUserFromCompany(userId);
      this.setState(prevState => ({
        usersList: prevState.usersList.filter(({ id }) => id !== userId),
        dialog: { isOpen: false },
      }));
    } catch (e) {
      console.error(e);
    }
  };

  editUser = async ({ role, userId, isFreelance }) => {
    await usersApi.updateUser({ role, userId, isFreelance });
    this.setState(prevState => ({
      usersList: prevState.usersList.map(user => user.id === userId ? { ...user, role, isFreelance } : user),
      dialog: { isOpen: false },
    }));
  };

  render() {
    const { usersList, rolesOptions, dialog } = this.state;
    return (
      <Fragment>
        <CompanyDrawer />
        <Grid container>
          <TitleContainer maxWidth='xl' breadcrumbs={[ 'company', { name: 'Personas' } ]}>Personas</TitleContainer>
          <ContentContainer maxWidth='xl'>
            <ActionsBox>
              {rolesOptions.length > 0 &&
                <Button size="small" color="primary" variant="contained" onClick={() => this.toggleDialog({ type: 'create' })}
                  startIcon={<PersonAdd />}>
                  Agregar persona
                </Button>}
            </ActionsBox>
            {usersList.length ?
              <UsersList usersList={usersList} currentUser={this.context.currentUser} rolesOptions={rolesOptions}
                actions={{ toggleDialog: this.toggleDialog }} /> :
              <Typography variant="body1" color="textPrimary">No se encontraron personas en la empresa.</Typography>
            }
          </ContentContainer>
        </Grid>
        <DialogWrap maxWidth="xs" fullWidth onClose={this.closeDialog} aria-labelledby="form-dialog-title" open={dialog.isOpen}>
          { this.getDialogType() }
        </DialogWrap>
      </Fragment>
    );
  }
}

CompanyUsersContainer.contextType = WebContext;

const CompanyUsersContainerWithStyles = withStyles(useStyles)(CompanyUsersContainer);

CompanyUsersContainer.propTypes = {
  classes: PropTypes.object,
};


export { CompanyUsersContainerWithStyles as CompanyUsersContainer };
