import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReportProblemOutlined as WarningIcon } from '@material-ui/icons/';

import { TooltipWrapper } from 'src/components/tooltipWrapper/TooltipWrapper';


const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(0.5),
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const WarnableAccuracy = ({ accuracy, htmlColor = '#ff9800' }) => {
  const classes = useStyles();
  return accuracy !== null && accuracy !== undefined && accuracy !== '' ?
    <Box className={classes.container}>
      <Typography component={'span'} className={classes.accuracyText}>{ accuracy }</Typography>
      { accuracy > 50 &&
        <TooltipWrapper interactive title={'La precisión tiene un margen de error demasiado alto'} className={classes.icon}>
          <WarningIcon htmlColor={htmlColor} fontSize="small"/>
        </TooltipWrapper>
      }
    </Box>
    : <Box>-</Box>;
};

WarnableAccuracy.propTypes = {
  accuracy: PropTypes.number,
  htmlColor: PropTypes.string,
};


export {
  WarnableAccuracy,
};
