import React, { useState, useEffect } from 'react';
import { Box, Collapse, useMediaQuery, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({
  container: {
    // sin esto, pasan cosas raras con el ancho cuando se cierra y abre muy ráṕidamente
    width: '100%',
  },
}));

const AlertWrapper = ({ children, onClose, BoxProps = {}, enterTransition = true, TransitionComponent = Collapse,
  hideCloseButton = false, isOpen = true, ...props
}) => {
  const classes = useStyles();
  const [ isAlertOpen, setIsAlertOpen ] = useState(!enterTransition && isOpen);
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('xs'));

  // hay que settearlo en un useEffect en vez de que esté abierto por defecto, para que la animación de entrada esté cuando
  // otros componentes hagan cosas como "condition && <AlertWrapper...>..."
  useEffect(() => {
    setIsAlertOpen(isOpen);
  }, [ isOpen ]);

  return (
    <TransitionComponent in={isAlertOpen} timeout={500} className={classes.container}>
      <Box { ...BoxProps } >
        <Alert
          {...((isDownSm && isAlertOpen) || hideCloseButton ? {} : {
            action: <Button color="inherit" size="small" onClick={onClose ? onClose : () => setIsAlertOpen(false)}>Cerrar</Button>,
          })}
          {...props}
        >
          {children}
        </Alert>
      </Box>
    </TransitionComponent>
  );
};

AlertWrapper.propTypes = {
  children: PropTypes.any,
  BoxProps: PropTypes.object,
  TransitionComponent: PropTypes.object,
  onClose: PropTypes.func,
  hideCloseButton: PropTypes.bool,
  // Si hay animación de entrada o no. Útil si está abierto por defecto y la animación de entrada se siente demás
  enterTransition: PropTypes.bool,
  isOpen: PropTypes.bool, // si está abierto o no. sirve para controlar la animación de salida desde el exterior
};


export { AlertWrapper };