import React, { Fragment, useState } from 'react';
import { Box, DialogContent, InputLabel, DialogActions, Button, DialogTitle } from '@material-ui/core';
import PropTypes from 'prop-types';

import { SelectChip, AccessControl } from 'src/components';


const campaignParticipationOptions = [
  { value: 'all', label: 'Todas' },
  { value: 'linked', label: 'Participo' },
  { value: 'unlinked', label: 'No participo' },
];

const campaignStatusesOptions = [
  { value: 'pending', label: 'Pendiente' },
  { value: 'in-progress', label: 'En progreso' },
  { value: 'finished', label: 'Finalizada' },
];

const campaignComponentOptions = [
  { value: 'flora', label: 'Flora - forestal' },
  { value: 'fauna', label: 'Fauna' },
];

const CampaignsFiltersDialog = props => {
  const { actions, prevFilters, defaultFilters } = props;

  const [ filters, setFilters ] = useState({
    participation: prevFilters.participation ?? defaultFilters.participation,
    status: prevFilters.status ?? defaultFilters.status,
    component: prevFilters.component ?? defaultFilters.component,
  });
  const handleChange = filterId => option => setFilters({ ...filters, [ filterId ]: option.value });

  return (
    <Fragment>
      <DialogTitle id='form-dialog-title'>Filtrar campañas</DialogTitle>
      <DialogContent>
        <AccessControl action="campaign:filter-participation">
          <Box my={4} ml={1}>
            <InputLabel shrink>Mostrar</InputLabel>
            <SelectChip value={filters.participation} options={campaignParticipationOptions}
              onChange={handleChange('participation')} />
          </Box>
        </AccessControl>
        <Box my={2} ml={1}>
          <InputLabel shrink>Estado</InputLabel>
          <SelectChip value={filters.status} options={campaignStatusesOptions} isMulti onChange={handleChange('status')} />
        </Box>
        <Box my={4} ml={1}>
          <InputLabel shrink>Componente</InputLabel>
          <SelectChip value={filters.component} options={campaignComponentOptions} isMulti onChange={handleChange('component')} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box color="error.main">
          <Button color="inherit" onClick={() => actions.applyFilters(defaultFilters)}>Reiniciar filtros y aplicar</Button>
        </Box>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <Button onClick={() => actions.applyFilters(filters)} color="primary">Aplicar</Button>
      </DialogActions>
    </Fragment>
  );
};

CampaignsFiltersDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  prevFilters: PropTypes.object.isRequired,
  defaultFilters: PropTypes.object.isRequired,
};


export { CampaignsFiltersDialog };