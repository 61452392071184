import React, { Component } from 'react';
import { WebContext } from 'src/scenes/web-context';
import { checkPermission } from 'src/utils/checkPermission';


/* eslint-disable react/display-name */
const withAccessControl = ({ action, noAccessRender = () => null }) => WrappedComponent => class extends Component {
  static contextType = WebContext;
  render() {
    return checkPermission({ action, webContext: this.context }) ? <WrappedComponent {...this.props} /> : noAccessRender();
  }
};

export {
  withAccessControl,
};