import React from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Replay, MoreVert, Add, FiberManualRecord, NavigateBefore, NavigateNext } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
  mainContainer: {
    // backgroundColor: '#f5f8fb',
    boxShadow: ({ shadow }) => shadow ?
      'inset 0 1px 1px 0 hsl(0deg 0% 100% / 10%), 0 50px 100px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%)' :
      'none',
    color: theme.palette.common.white,
    borderBottomRightRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
  },

  top: {
    height: ({ size }) => size === 'small' ? theme.spacing(4) : theme.spacing(6),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    backgroundColor: ({ darkMode }) => darkMode ? 'rgba(0, 0, 0, .4)' : 'hsla(0, 0%, 100%, .4)',
  },

  paper: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    height: '65%',
    width: '80%',
    color: theme.palette.common.white,
  },
  red: {
    color: '#FF605C',
  },
  yellow: {
    color: '#FFBD44',
  },
  green: {
    color: '#00CA4E',
  },

  content: {
    backgroundColor: '#f5f8fb',
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
}));

const BrowserUI = ({ size = 'default', shadow = true, darkMode, children }) => {
  const classes = useStyles({ size, shadow, darkMode });
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.top} display="flex" justifyContent="space-between" alignItems="center">
        <Box className={classes.buttons} display="flex" alignItems="center">
          <FiberManualRecord className={classes.red} fontSize="small" />
          <FiberManualRecord className={classes.yellow} fontSize="small" />
          <FiberManualRecord className={classes.green} fontSize="small" />
          <Box display="flex" ml={1} alignItems="center">
            <NavigateBefore />
          </Box>
          <NavigateNext />
        </Box>
        <Paper className={classes.paper} elevation={0}>
          <Box display="flex" alignItems="center" height="100%">
            <Box display="flex" justifyContent="center" flexGrow={1}>
              <Typography>capture.nviro.cl</Typography>
            </Box>
            <Box display="flex" alignItems="center" mr={1}>
              <Replay fontSize="small" />
            </Box>
          </Box>
        </Paper>
        <Box display="flex">
          <Box display="flex" alignItems="center" mr={1}>
            <Add />
          </Box>
          <MoreVert />
        </Box>
      </Box>
      <Box className={classes.content}>
        {children}
      </Box>
    </Box>
  );
};

BrowserUI.propTypes = {
  children: PropTypes.node,
  size: PropTypes.string,
  shadow: PropTypes.bool,
  darkMode: PropTypes.bool,
};


export {
  BrowserUI,
};