import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';

import { withAccessControl } from 'src/components';


const EnvironmentDeleteDialog = props => {
  const { actions } = props;
  return (
    <Fragment>
      <DialogTitle id="form-dialog-title">Borrar ambiente</DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Typography variant="body1" color="textPrimary">
            ¿Realmente deseas eliminar el ambiente?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog} color="secondary">Cancelar</Button>
        <Button onClick={actions.deleteEnvironment} color="primary">Confirmar</Button>
      </DialogActions>
    </Fragment>
  );
};

EnvironmentDeleteDialog.propTypes = {
  actions: PropTypes.object.isRequired,
};

const EnvironmentDeleteDialogWithAccessControl = withAccessControl({ action: 'environment:delete' })(EnvironmentDeleteDialog);


export { EnvironmentDeleteDialogWithAccessControl as EnvironmentDeleteDialog };