import React from 'react';
import { Box, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { AppCampaignUI } from 'src/scenes/Home/components';


const headerHeight = '48px';
const tabsHeight = '41px';

const useStyles = makeStyles(() => ({
  mainContainer: {
    height: `calc(100% - ${headerHeight} - ${tabsHeight})`,
  },
  chip: {
    fontSize: '0.75rem',
  },
}));

const FloraAppCampaignsUI = ({ syncedColor, campaignData }) => {
  const classes = useStyles();
  return (
    <Box className={classes.mainContainer} display="flex" flexDirection="column">
      <Box mt={2} display="flex">
        <Chip className={classes.chip} label="Personales" size="small" />
        <Chip className={classes.chip} label="Asignadas" size="small" />
      </Box>
      <Box pt={1} px={1} pb={1}>
        <AppCampaignUI
          title="Campaña de Invierno"
          project="Proyecto 2021"
          dateText="Comenzó hace 2 días"
          catalogue="Plantas Vasculares de Chile (Rodríguez)"
          companyName="Nviro"
          companyInitials="NVI"
          data={ campaignData }
          syncedColor={ syncedColor }
        />
      </Box>
    </Box>
  );
};

FloraAppCampaignsUI.propTypes = {
  syncedColor: PropTypes.bool,
  campaignData: PropTypes.object,
};

FloraAppCampaignsUI.defaultProps = {
  campaignData: { plots: { total: 5, done: 3 }, synced: 50, map: true },
};

export {
  FloraAppCampaignsUI,
};