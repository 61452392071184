import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Fade, Paper } from '@material-ui/core';
import clsx from 'clsx';

import { CensusRecordPopup } from 'src/scenes/Campaign/scenes/Monitoring/components';
import { CloseIcon } from 'src/utils/mdIcons';


const componentMapper = {
  census: CensusRecordPopup,
};

const useStyles = makeStyles(() => ({
  closeIcon: {
    marginRight: '-12px',
    marginTop: '-12px',
    cursor: 'pointer',
  },
}));

const CensusRecordPopover = props => {
  const { className, data } = props;
  const classes = useStyles();

  const [ isShowing, setIsShowing ] = useState(false);

  const Component = componentMapper['census'];

  useEffect(() => {
    setIsShowing(Boolean(data));
  }, [ data ]);

  return (
    <Fade in={isShowing} timeout={300}>
      <Paper className={clsx(classes.mapPaper, className)}>
        <Box display="flex">
          <Component censusRecord={data} />
          <Box flexGrow={1}></Box>
          <CloseIcon className={classes.closeIcon} fontSize="small" onClick={() => setIsShowing(false)} />
        </Box>
      </Paper>
    </Fade>
  );
};

CensusRecordPopover.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
};


export {
  CensusRecordPopover,
};
