import React, { useState, useContext, useEffect } from 'react';
import { Grid, Box, Button, DialogTitle, DialogContent, Typography, DialogActions } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';

import { WebContext } from 'src/scenes/web-context';
import { Downloadable } from 'src/scenes/Campaign/scenes/Downloadables/components/Downloadable';
import { downloadables } from 'src/scenes/Campaign/scenes/Downloadables/downloadablesHelper';
import { TitleContainer, ContentContainer, DialogWrap } from 'src/components';
import { campaignsApi } from 'src/services';
import { humanReadableArrayOfStrings } from 'src/utils/util';


const buildFullName = ({ firstName, lastName }) => [ firstName, lastName ].join(' ').trim();

const DownloadablesContainer = props => {
  const { hasPermissionFor, selectedCampaign, isFan, selectedCompany: { role: userRole } } = useContext(WebContext);
  const { componentId, mainMethodId, id, recordsCounter: { unofficial_source: uncorrectedRecords } } = selectedCampaign;
  const [ config, setConfig ] = useState({});
  const [ usersBlocking, setUsersBlocking ] = useState([]);

  useEffect(() => {
    const checkUsersWithSomeBlock = async () => {
      const usersBlocking = await campaignsApi.getUsersWithSomeBlock(id);
      setUsersBlocking(usersBlocking);
    };

    checkUsersWithSomeBlock();
  }, [ id ]);

  const componentDownloadables = downloadables({
    componentId, mainMethodId, campaignId: id, hasPermissionFor, userIsFan: isFan(), config, setConfig,
  });
  const hasUncorrectedRecords = uncorrectedRecords > 0;

  const goToRecords = () => props.changeActive('records', `?source=nviro-${componentId}`);

  const [ isOpenDialog, setIsOpenDialog ] = useState(hasUncorrectedRecords);
  const closeDialog = () => setIsOpenDialog(false);

  const singularRecord = uncorrectedRecords === 1;

  return (
    <Grid container>
      <TitleContainer maxWidth="xl" breadcrumbs={[ 'company', 'campaigns', { name: 'Descargas' } ]}>
        Descargas
      </TitleContainer>
      <ContentContainer maxWidth="xl">
        {usersBlocking.length > 0 &&
          <Box mt={1} mb={2}>
            <Alert variant="filled" severity="error"
            >
              Hemos detectado que {humanReadableArrayOfStrings(usersBlocking.map(u => `"${buildFullName(u)}"`))} está
              { usersBlocking.length > 1 ? 'n' : ''} usando múltiples dispositivos en esta campaña.

              Se debe regularizar esta situación para volver a activar los descargables.
            </Alert>
          </Box>
        }
        {hasUncorrectedRecords &&
          <Box mt={1} mb={2}>
            <Alert variant="filled" severity="warning"
              action={<Button color="inherit" size="small" onClick={goToRecords}>Ir a corregir</Button>}
            >
              Tienes <strong>{uncorrectedRecords} {`registro${singularRecord ? '' : 's'}`} sin corregir</strong>,
              recuerda hacerlo para que los descargables contengan la información correcta
            </Alert>
          </Box>
        }
        <Grid container spacing={ 2 }>
          {componentDownloadables.map((d, i) =>
            <Grid key={ i.toString() } item xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 } >
              <Downloadable { ...{ ...d, ...(usersBlocking.length && { disabled: userRole !== 'god' }) } }/>
            </Grid>)
          }
        </Grid>
      </ContentContainer>
      <DialogWrap maxWidth="sm" fullWidth onClose={closeDialog} aria-labelledby="form-dialog-title" open={isOpenDialog}>
        <DialogTitle>Tienes registros sin corregir</DialogTitle>
        <DialogContent>
          <Box>
            <Typography variant="body1">
              {singularRecord ? 'Se encontró ' : 'Se encontraron '}
              <strong>{uncorrectedRecords} {singularRecord ? 'registro' : 'registros'} sin corregir</strong>,
              los reportes descargados podrían estar  incompletos o no ser exactos del todo.
            </Typography>
            <br/>
            <Typography variant="body1">
              ¿Deseas ir a corregirlo{singularRecord ? '' : 's'}?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>No por ahora</Button>
          <Button color="primary" onClick={goToRecords}>Sí, ir a corregir</Button>
        </DialogActions>
      </DialogWrap>
    </Grid>
  );
};

DownloadablesContainer.propTypes = {
  changeActive: PropTypes.func,
};


export {
  DownloadablesContainer,
};
