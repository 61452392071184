import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';
import { humanReadableArrayOfStrings } from 'src/utils/util';


const FinishCampaignDialog = props => {
  const { actions, cLang, catalogName, completionStatusByCampaign, isFreelance = false } = props;
  const handleFinishCampaign = async () => {
    await actions.finishCampaign();
    actions.closeDialog();
  };

  const { hasTodoPoints, hasUnreviewedPoints, hasUncorrectedSingleRecords } = completionStatusByCampaign;
  const hasSomePending = hasTodoPoints || hasUnreviewedPoints || hasUncorrectedSingleRecords;

  const hashPendingText = {
    hasTodoPoints: `${cLang.points} pendientes`,
    hasUnreviewedPoints: `${cLang.points} sin revisar`,
    hasUncorrectedSingleRecords: `registros ${cLang.singles} con nombres que no pertenecen a ${catalogName}`,
  };

  const pendingText = Object.keys(completionStatusByCampaign).filter(k => completionStatusByCampaign[k]).map(k => hashPendingText[k]);

  return (
    <Fragment>
      <DialogTitle id="form-dialog-title">Finalizar campaña</DialogTitle>
      <DialogContent>
        <Box p={1}>
          {isFreelance ?
            <Typography color="textPrimary" variant="body1">
              Esta acción provocará que ningún involucrado en la campaña pueda realizar más cambios en ella
              y <strong>ya no podras interactuar con ella</strong> (serás redireccionado a la página principal del proyecto).
            </Typography> :
            <Typography color="textPrimary" variant="body1">
              Esta acción provocará que ningún involucrado en la campaña pueda realizar más cambios en ella.
            </Typography>
          }
          <br />
          {hasSomePending && <>
            <Typography variant="body1" color="textPrimary">
            Debes considerar que aún hay&nbsp;
              <Typography display="inline" component="span" color="error">
                {humanReadableArrayOfStrings(pendingText)}
              </Typography>
              .
            </Typography>
            <br />
          </>}

          <Typography color="textPrimary" variant="body1">
            ¿Realmente deseas finalizar la campaña?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <Button onClick={handleFinishCampaign} color="primary">Confirmar</Button>
      </DialogActions>
    </Fragment>
  );
};

FinishCampaignDialog.propTypes = {
  campaign: PropTypes.object,
  actions: PropTypes.object.isRequired,
  cLang: PropTypes.object.isRequired,
  catalogName: PropTypes.string.isRequired,
  completionStatusByCampaign: PropTypes.object,
  isFreelance: PropTypes.bool.isRequired,
};


export { FinishCampaignDialog };
