import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { AddCircle, Edit, Delete, PhotoAlbum } from '@material-ui/icons';
import urlJoin from 'url-join';
import config from 'src/config/local';

import { Paw } from 'src/utils/mdIcons';
import { humanReadableHrMin as hrhm } from 'src/utils/util';
import { upperCaseOnlyFirstLetter as upofl } from 'src/utils/formatters';
import { TableWrapper } from 'src/components';


const { serverUrl } = config;

const NA = () => <Box color="text.hint">No aplica</Box>;

const MethodologiesList = props => {
  const { tableRef, actions, selectedCampaign, hasAdminPowers } = props;
  const isCampaignInProgress = selectedCampaign.statusId === 'in-progress' && !selectedCampaign.hasOwnWorkFinished;

  const isSynced = statusId => statusId === 'synced';

  const isAddRecordAllowed = ({ samplingStationStatusId }) => (isCampaignInProgress || hasAdminPowers) && isSynced(samplingStationStatusId);

  const tableColumns = [
    /* eslint-disable react/display-name */
    { title: 'Nombre', field: 'name' },
    { title: 'Tipo', field: 'typeName', emptyValue: '-', render: r => <div>{upofl(r.typeName)}</div> },
    { title: 'Estación de muestreo', field: 'samplingStationName' },
    { title: 'Autor', field: 'email' },
    { title: 'Registros', field: 'recordsCount' },
    { title: 'Radio (m)', field: 'radius', render: r => r.radius ? r.radius : <NA /> },
    { title: 'Área (㎡)', field: 'area', render: r => r.area ? r.area : <NA /> },
    { title: 'Ancho (m)', field: 'width', render: r => r.width ? r.width : <NA /> },
    { title: 'Noches', field: 'nights', render: r => r.nights ? r.nights : <NA /> },
    { title: 'Tiempo Ocupado', field: 'spentTime', render: r => r.spentTime ? hrhm(r.spentTime) : <NA /> },
    { title: 'Observaciones', field: 'observation',
      render: r => <div>{r.observation.trim() ? r.observation : 'Sin observaciones'}</div>,
    },
  ];

  const tableActions = [
    {
      icon: () => <Paw color="primary" />,
      tooltip: 'Ver registros',
      onClick: (event, rowData) => actions.goToRecords(rowData.id),
    },
    rowData => ({
      icon: () => <AddCircle color={isAddRecordAllowed(rowData) ? 'action' : 'disabled'} />,
      tooltip: (
        isAddRecordAllowed(rowData) ? 'Agregar registro'
        : (!isSynced(rowData) ? `La estación de muestreo debe encontrarse en estado sincronizada` : 'Trabajo ya fue finalizado')
      ),
      onClick: (event, rowData) => actions.openCreateRecordDialog(rowData),
      disabled: !isAddRecordAllowed(rowData),
    }),
    rowData => {
      const hasPictures = rowData.pictures.length > 0;
      return {
        disabled: !hasPictures,
        icon: () => <PhotoAlbum color={hasPictures ? 'action' : 'disabled'} />,
        tooltip: hasPictures ? 'Ver fotos' : 'No hay fotos',
        onClick: (event, rowData) => actions.openViewPhotoDialog({
          pictures: rowData.pictures.map(p => urlJoin(serverUrl, p)),
          title: `Metodología ${rowData.name}`,
        }),
      };
    },
    {
      icon: () => <Edit color="action" />,
      tooltip: 'Editar',
      onClick: (event, rowData) => actions.openEditMethodologyDialog(rowData),
    },
    {
      icon: () => <Delete color="action" />,
      tooltip: 'Eliminar',
      onClick: (event, rowData) => actions.openDeleteMethodologyDialog(rowData),
    },
  ];

  const handleData = async query => {
    const methodologies = await actions.getMethodologies({ page: query.page + 1, size: query.pageSize });
    return ({
      data: methodologies,
      page: query.page,
      totalCount: methodologies[0]?.totalMethodologiesCount || 0,
    });
  };
  return (
    <Box width="100%">
      <TableWrapper
        tableRef={tableRef}
        columns={tableColumns}
        data={handleData}
        actions={tableActions}
        options={{ pageSize: 10, pageSizeOptions: [], idSynonym: 'id', search: false }}
      />
    </Box>
  );
};

MethodologiesList.propTypes = {
  tableRef: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  selectedCampaign: PropTypes.object.isRequired,
  hasAdminPowers: PropTypes.bool.isRequired,
};


export { MethodologiesList };
