import React from 'react';
import { Paper, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AssignPointsDialog } from 'src/scenes/Planning/components';
import { campaignSubtypeLanguage } from 'src/utils/componentLanguage';


const useStyles = makeStyles(theme => ({
  paper: {
    transform: 'scale(0.85)',
    maxWidth: '400px',
    [theme.breakpoints.down('sm')]: {
      transform: 'scale(0.75)',
    },
  },
}));

const companyUsersOptions = [
  { value: 1, label: 'john.lara@nviro.cl' },
  { value: 2, label: 'ernesto.firulais@nviro.cl' },
];

const AssignPointsUI = () => {
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="center">
      <Paper className={classes.paper}>
        <AssignPointsDialog
          options={{ companyUsersOptions }}
          csLang={campaignSubtypeLanguage('plots')}
          canAddUserToCompany
          onlyUI
        />
      </Paper>
    </Box>
  );
};


export {
  AssignPointsUI,
};