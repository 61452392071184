const pricing = [
  {
    title: 'Profesional',
    description: 'Para particulares y freelances que quieran probar la aplicación',
    price: 0,
    releaseOffer: true,
    button: {
      text: 'Comenzar',
      link: '/register',
      color: 'black',
    },
    features: {
      data: [
        '1 usuario activo por campaña',
        'Campañas ilimitadas',
        'Almacenamiento ilimitado',
        'Descargas',
      ],
    },
    note: '',
  },
  {
    title: 'Universidad/ONG',
    description: 'Para centros de estudio y entidades sin fines de lucro',
    price: 0,
    releaseOffer: true,
    button: {
      text: 'Contáctanos',
      link: '/contact',
      color: 'blue',
    },
    features: {
      text: '',
      data: [ 'Campañas ilimitadas', 'Trabajo en equipo', 'Almacenamiento ilimitado', 'Descargas avanzadas' ],
    },
    disabled: false,
  },
  {
    title: 'Empresa',
    description: 'Para empresas y organizaciones que requieren trabajo en equipo',
    price: undefined,
    button: {
      text: 'Contáctanos',
      link: '/contact',
      color: 'primary',
    },
    features: {
      text: '',
      data: [ 'Administración de usuarios', 'Trabajo en equipo', 'Procesamiento de datos para múltiples usuarios' ],
    },
    note: '* Los precios varían según sus requerimientos',
  },
];

const faq = [
  {
    question: '¿Cómo crear una cuenta?',
    answer: `Lorem ípsum dolorursu, malesuada erat ut, egestas odio. Quisque suscipit, urna ac vulputate sollicitudin, mi urna
      elementum augue, id tristique arcu erat non enim. Lorem ípsum dolor sit amet, consectetur adipiscing elit. Integer non
      leo pretium pellentesque. Curabitur blandit urna cursus, malesuada erat ut, egestas.`,
  },
  {
    question: '¿Cómo puedo contratar sus servicios?',
    answer: `Lorem ípsum dolorcursus, malesuada erat ut, egestas odio. Quisque suscipit, urna ac vulputate sollicitudin, mi urna
      elementum augue, id tristique arcu erat non enim. Lorem ípsum dolor sit amet, consectetur adipiscing elit. Integer non
      leo pretium pellentesque. Curabitur blandit urna cursus, malesuada erat ut, egestas.`,
  },
  {
    question: '¿Puedo cancelar la suscripción en cualquier momento?',
    answer: `Lorem ípsum dolor sit amet, consectetur adipiscing elit. Integer euismod odio non leo pretium pellentesque.
      Curabitur blandit urna cursus, malesuada erat ut, egestas odio. Quisque suscipit, urna ac vulputate sollicitudin, mi urna
      elementum augue, id tristique arcu erat non enim. Lorem ípsum dolor sit amet, consectetur adipiscing elit. Integer non
      leo pretium pellentesque. Curabitur blandit urna cursus, malesuada erat ut, egestas.`,
  },
  {
    question: '¿Mis datos estaran seguros?',
    answer: `Lorem ípsum dolor sit amet, consectetur adipiscing elit. Integer euismod odio non leo pretium pellentesque.
      Curabitur blandite, id tristique arcu erat non enim. Lorem ípsum dolor sit amet, consectetur adipiscing elit. Integer non
      leo pretium pellentesque. Curabitur blandit urna cursus, malesuada erat ut, egestas.`,
  },
  {
    question: '¿Cual es el beneficio de un plan anual?',
    answer: `Lorem ípsum dolor sit amet, consectetur adipiscing elit. Integer euismod odio non leo pretium pellentesque.
      Curabitur blandit urna cursus, ma tristique arcu erat non enim. Lorem ípsum dolor sit amet, consectetur adipiscing elit. Integer non
      leo pretium pellentesque. Curabitur blandit urna cursus, malesuada erat ut, egestas.`,
  },
  {
    question: '¿Que ocurre con mis datos si acaba mi suscripción?',
    answer: `Lorem ípsum dolor sit amet, coon enim. Lorem ípsum dolor sit amet, consectetur adipiscing elit. Integer non
      leo pretium pellentesque. Curabitur blandit urna cursus, malesuada erat ut, egestas.`,
  },
  {
    question: '¿Que ocurre con mis datos si acaba mi suscripción?',
    answer: `Lorem ípsum dolor sarcu erat non enim. Lorem ípsum dolor sit amet, consectetur adipiscing elit. Integer non
      leo pretium pellentesque. Curabitur blandit urna cursus, malesuada erat ut, egestas.`,
  },
  {
    question: '¿Que medios de pago se aceptan?',
    answer: `Lorem ípsum dolor sit amet, consectetur adipiscing elit. Integer euismod odio non leo pretium pellentesque.
      Curabitur blandit urna cursus, malesuada erat .`,
  },
  {
    question: '¿Cómo puedo actualizar mi plan?',
    answer: `Lorem ípsum dolor sit amet, consectetur adipiscing elit. Integer euismod odio non leo pretium pellentesque.
      Curabitur blandit urna cursus, malesuada erat .`,
  },
];


export { pricing, faq };
