import React, { useState } from 'react';
import { Collapse, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';

import { useRouteMatch } from 'react-router';
import clsx from 'clsx';

import { getMaintenanceStatus } from 'src/utils/util';
import { AlertWrapper, SnackbarWrapper } from 'src/components';
import { useServerMaintenanceOn } from 'src/hooks';


const useStyles = makeStyles(theme => ({
  coverAlert: {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 16%)',
    width: '98%',
  },
  loginAlert: {
    width: '536px',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: '424px',
      alignSelf: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  },
  mainAlert: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  inWeb: {
    marginTop: theme.spacing(10),
    width: '98%',
  },
  inWebAlert: {
    width: '100%',
  },
}));

const MaintenanceAlert = () => {

  const classes = useStyles();
  const { path: routePath } = useRouteMatch();
  const { hrStartDate, hrFinishDate, isMaintenanceScheduled, isMaintenanceOn, isMaintenanceScheduledOrOn } = getMaintenanceStatus();

  const [ snackbarOpen, setSnackbarOpen ] = useState(true);
  const isServerMaintenanceOn = useServerMaintenanceOn();

  const isLoginPage = routePath === '/login';
  const isCoverPage = routePath === '/';

  const Message = () => (
    isMaintenanceScheduled ?
      <>
        Nos encontramos en constante evolución por lo que se realizará una mantención
        entre <strong>{hrStartDate.toLocaleLowerCase()}</strong> y&nbsp;
        <strong>{hrFinishDate.toLocaleLowerCase()}</strong> motivo por el cual no se podrá utilizar la plataforma web
      </>
      : (isMaintenanceOn || isServerMaintenanceOn) ?
        <>
          Lamentamos las molestias, actualmente nos encontramos mejorando la plataforma web
        </>
        : <></>
  );

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      {(isMaintenanceScheduledOrOn || isServerMaintenanceOn) &&
        (
          (isLoginPage || isCoverPage) ?
            <AlertWrapper
              TransitionComponent={isCoverPage ? Fade : Collapse}
              variant="filled"
              severity="warning"
              BoxProps={{ className:
                clsx({
                  [classes.coverAlert]: isCoverPage,
                  [classes.loginAlert]: isLoginPage,
                }),
              }}
            >
              <Message />
            </AlertWrapper> :
            <SnackbarWrapper
              classes={{ root: classes.inWeb }}
              open={snackbarOpen}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              transitionDuration={1000}
              TransitionComponent={Fade}
            >
              <Alert classes={{ root: classes.inWebAlert }} onClose={handleClose} variant="filled" severity="warning">
                <Message />
              </Alert>
            </SnackbarWrapper>
        )
      }
    </>
  );
};


export { MaintenanceAlert };
