import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab, Box, useMediaQuery } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';


const TabPanel = ({ children, value, index, ...other }) => (
  <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
    {value === index && (
      <Box mt={3}>
        {children}
      </Box>
    )}
  </div>
);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const tabProps = id => ({
  id: `simple-tab-${id}`,
  'aria-controls': `simple-tabpanel-${id}`,
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    alignItems: 'center',
  },
  tab: {
    maxHeight: '100%',
  },
}));

const FeaturesTabs = ({ tabs = [ { id: '', label: '', Component: Fragment } ] }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [ value, setValue ] = useState(0);

  const handleChange = (event, value) => setValue(value);

  const handleChangeIndex = index => setValue(index);

  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));


  return (
    <div className={classes.root}>
      <AppBar position="static" elevation={0} className={classes.appBar}>
        <Tabs value={value} onChange={handleChange} variant="fullWidth">
          {tabs.map((tab, index) => <Tab key={tab.id} label={tab.label} {...tabProps(index)} />)}
        </Tabs>
      </AppBar>
      {isDownSm ?
        <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} onChangeIndex={handleChangeIndex}>
          {tabs.map(({ Component, id }, index) =>
            <TabPanel className={classes.tab} key={id} value={value} index={index}>
              <Component />
            </TabPanel>,
          )}
        </SwipeableViews> :
        tabs.map(({ Component, id }, index) =>
          <TabPanel className={classes.tab} key={id} value={value} index={index}>
            <Component />
          </TabPanel>,
        )}
    </div>
  );
};

FeaturesTabs.propTypes = {
  tabs: PropTypes.array,
};


export {
  FeaturesTabs,
};