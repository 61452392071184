import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography, InputLabel, Grid, Chip, Collapse } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Alert } from '@material-ui/lab';

import { DialogButton, SelectWrapper } from 'src/components';
import { isTransect } from 'src/utils/checkers';
import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';


const { NOT_STARTED, LOADING, SUCCESS, ERROR } = fetchStatusEnum;

const PointsEditDialog = ({ actions, formOptions, selectedPoints, csLang, canAddUserToCompany, userIsFan, campaignSubtypeId }) => {

  const [ selectedUser, setSelectedUser ] = useState('');

  const [ fetchStatus, setFetchStatus ] = useState(NOT_STARTED);
  const [ errorAlert, setErrorAlert ] = useState({ isOpen: false, message: '' });

  const handleConfirm = async () => {
    try {
      setFetchStatus(LOADING);
      const toEdit = {
        ...(selectedUser.value ? { userId: selectedUser.value } : {}),
      };
      await actions.editPoints(toEdit, selectedUser.label);
      setFetchStatus(SUCCESS);
      setTimeout(() => actions.toggleDialog(), 300);
    } catch (e) {
      setErrorAlert({ isOpen: true, message: e.serverMessage || e.message });
      setFetchStatus(ERROR);
    }
  };

  const isSynced = status => status === true || status === 'synced';

  const pointsSynced = selectedPoints.filter(p => isSynced(isTransect(campaignSubtypeId) ? p.synced : p.statusId));

  return (
    <>
      <DialogTitle id="form-dialog-title">Reasignar múltiples {csLang.points}</DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Typography color="textPrimary" variant="body1">{csLang.Points} seleccionadas:</Typography>
          <Grid container justifyContent="center" alignItems="center"
            style={{ ...(selectedPoints.length > 40 ? { overflowY: 'scroll' } : {}), maxHeight: '135px' }}>
            {selectedPoints.map(point =>
              <Box p={0.2} key={point.id}>
                <Chip label={isSynced(point.statusId) ? <strong><u>{point.name}</u></strong> : point.name} />
              </Box>,
            )}
          </Grid>
        </Box>
        { !userIsFan && <>
          {pointsSynced.length ?
            <Box p={1}>
              <Typography variant="body1" color="textPrimary">
                Se han seleccionado {csLang.points} que ya fueron
                <Typography display="inline" component="span" color="error"> sincronizadas</Typography>
                , por lo que no se pueden reasignar, para continuar debes <strong>sacarlas de la selección</strong>.
              </Typography>
            </Box> :
            <Box p={1}>
              <InputLabel shrink >
                Asignar a { canAddUserToCompany &&
                  <Link style={{ marginLeft: 5 }} to={`/web/company/users`}>¿Necesitas más personas en la empresa?</Link>
                }
              </InputLabel>
              <SelectWrapper options={formOptions.companyUsersOptions} placeholder={'Seleccione un usuario'}
                onChange={selectedUser => setSelectedUser(selectedUser)} fixedList />
            </Box>
          }
        </>}
        {fetchStatus === ERROR &&
          <Collapse in={errorAlert.isOpen}>
            <Alert severity="error" onClose={() => setErrorAlert(s => ({ ...s, isOpen: false }))}>
              {errorAlert.message}
            </Alert>
          </Collapse>
        }
      </DialogContent>
      <DialogActions>
        {pointsSynced.length ?
          <Button onClick={actions.toggleDialog} color="primary">Cerrar</Button> :
          <>
            <Button onClick={actions.toggleDialog} color="secondary">Cancelar</Button>
            <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>Editar</DialogButton>
          </>
        }
      </DialogActions>
    </>
  );
};

PointsEditDialog.propTypes = {
  selectedPoints: PropTypes.array,
  actions: PropTypes.object,
  formOptions: PropTypes.object,
  csLang: PropTypes.object,
  canAddUserToCompany: PropTypes.bool,
  userIsFan: PropTypes.bool,
  campaignSubtypeId: PropTypes.string,
};


export {
  PointsEditDialog,
};
