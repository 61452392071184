import React, { PureComponent } from 'react';
import { Grid, Box, CircularProgress, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { InfoTooltip } from 'src/components';


export class LoadingCard extends PureComponent {
  render() {
    const { title, info, loading, error, children } = this.props;
    return (
      <Box display="flex" flexDirection="column" p={2} height="100%" style={{ position: 'relative' }}>
        <Box display="flex" alignItems="center">
          <Typography noWrap variant="subtitle1" color="textSecondary">{title}</Typography>
        </Box>
        {
          loading ?
            <Grid container justifyContent='center'><Grid item><CircularProgress /></Grid></Grid> :
            error ?
              'Error!' :
              children
        }
        <Box position="absolute" bottom="8px" right="8px">
          <InfoTooltip color="disabled" contents={info}/>
        </Box>
      </Box>
    );
  }
}

LoadingCard.propTypes = {
  children: PropTypes.object,
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.bool,
};
