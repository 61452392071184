import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  observationTitle: {
    marginBottom: '4px !important',
  },
  observationContent: {
    whiteSpace: 'pre-wrap',
    overflowY: 'scroll',
    overflowWrap: 'break-word',
    minHeight: '4em',
    maxHeight: '12em',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '12px',
    borderColor: theme.palette.common.gray400,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginTop: '0px !important',
    resize: 'none',
    width: '100%',
    color: theme.palette.text.primary,
  },
  noObservation: {
    fontStyle: 'italic',
  },
}));

const PopupObservation = ({ observation }) => {
  const classes = useStyles();
  const trimmedObs = observation?.trim();

  return trimmedObs ?
    <div>
      <Typography variant="overline" component="p" className={classes.observationTitle}>
        Observación:
      </Typography>
      <Typography variant="body2" component="p" className={classes.observationContent}>
        { trimmedObs }
      </Typography>
    </div>
    : <Typography variant="body2" component="p" className={classes.noObservation}>
      Sin observación
    </Typography>
  ;
};

PopupObservation.propTypes = {
  observation: PropTypes.string,
};


export {
  PopupObservation,
};
