import { floraApi, faunaApi, forestApi } from 'src/services';


const recordTypeMap = {
  'flora-record': {
    create: floraApi.createRecord,
    update: floraApi.updateRecord,
    backendName: 'flora',
    creatableProps: [ 'pointId', 'speciesHash', 'coverage', 'individuals', 'phenologiesStatuses', 'phytosanitaryStatuses',
      'detectionDate', 'observation', 'imgFiles', 'imgFilesData' ],
    updatableProps: [ 'speciesHash', 'coverage', 'individuals', 'phenologiesStatuses', 'phytosanitaryStatuses', 'detectionDate',
      'observation' ],
  },
  'forest-record': {
    create: floraApi.createRecord,
    update: forestApi.updateRecord,
    backendName: 'forest',
    creatableProps: [ 'pointId', 'speciesHash' ],
    updatableProps: [ 'speciesHash' ],
  },
  'flora-individual-record': {
    create: floraApi.createRecord,
    update: floraApi.updateIndividualRecord,
    backendName: 'individual',
    creatableProps: [ 'speciesHash', 'latitude', 'longitude', 'altitude', 'accuracy', 'detectionDate',
      'observation', 'phenologiesStatuses', 'phytosanitaryStatuses', 'imgFiles', 'imgFilesData' ],
    updatableProps: [ 'speciesHash', 'latitude', 'longitude', 'altitude', 'accuracy', 'detectionDate',
      'observation', 'phenologiesStatuses', 'phytosanitaryStatuses' ],
  },
  'flora-point-quadrat-record': {
    create: floraApi.createRecord,
    update: floraApi.updateTransectRecord,
    backendName: 'pointQuadrat',
    creatableProps: [ 'pointId', 'speciesHash', 'touches', 'stratumsIds', 'phenologiesStatuses', 'phytosanitaryStatuses',
      'observation', 'detectionDate', 'transectPoint', 'imgFiles', 'imgFilesData' ],
    updatableProps: [ 'speciesHash', 'touches', 'stratumsIds', 'phenologiesStatuses', 'phytosanitaryStatuses',
      'observation', 'detectionDate' ],
  },
  'flora-point-intercept-record': {
    create: floraApi.createRecord,
    update: floraApi.updateTransectRecord,
    backendName: 'pointIntercept',
    creatableProps: [ 'pointId', 'speciesHash', 'observation', 'detectionDate', 'transectPoint', 'phenologiesStatuses',
      'phytosanitaryStatuses', 'imgFiles', 'imgFilesData' ],
    updatableProps: [ 'speciesHash', 'observation', 'detectionDate', 'phenologiesStatuses', 'phytosanitaryStatuses' ],
  },
  'flora-census-record': {
    create: null,
    update: floraApi.updateCensusRecord,
    backendName: 'censusRecord',
    // TODO: update data
    updatableProps: [ 'speciesHash', 'latitude', 'longitude', 'altitude', 'accuracy', 'detectionDate',
      'observation' ],
  },
  'fauna-direct-record': {
    create: faunaApi.create,
    update: faunaApi.updateRecord,
    backendName: 'direct',
    creatableProps: [ 'methodologyId', 'speciesId', 'number', 'detectionDate', 'observation' ],
    updatableProps: [ 'speciesId', 'number', 'observation', 'detectionDate' ],
  },
  'fauna-indirect-record': {
    create: faunaApi.create,
    update: faunaApi.updateRecord,
    backendName: 'indirect',
    creatableProps: [ 'methodologyId', 'speciesId', 'typeId', 'otherType', 'recognizable', 'latitude', 'longitude',
      'altitude', 'accuracy', 'detectionDate', 'observation', 'imgFiles', 'imgFilesData' ],
    updatableProps: [ 'speciesId', 'typeId', 'observation', 'otherType', 'latitude', 'longitude',
      'altitude', 'accuracy', 'detectionDate' ],
  },
  'fauna-isolated-record': {
    create: faunaApi.create,
    update: faunaApi.updateRecord,
    backendName: 'isolated',
    creatableProps: [ 'speciesId', 'sexId', 'ageStratumId', 'latitude', 'longitude', 'altitude', 'accuracy',
      'detectionDate', 'observation', 'imgFiles', 'imgFilesData' ],
    updatableProps: [ 'speciesId', 'sexId', 'ageStratumId', 'latitude', 'longitude',
      'altitude', 'accuracy', 'observation', 'detectionDate' ],
  },
  'fauna-transit-record': {
    create: faunaApi.create,
    update: faunaApi.updateRecord,
    backendName: 'transit',
    creatableProps: [ 'methodologyId', 'speciesId', 'height', 'number', 'from', 'towards', 'detectionDate', 'observation',
      'imgFiles', 'imgFilesData', 'flightTypeId', 'flightTypeOther', 'minHeight', 'maxHeight' ],
    updatableProps: [ 'speciesId', 'height', 'number', 'from', 'towards', 'observation',
      'flightTypeId', 'flightTypeOther', 'minHeight', 'maxHeight', 'detectionDate' ],
  },
  'fauna-presence-record': {
    create: faunaApi.create,
    update: faunaApi.updateRecord,
    backendName: 'presence',
    creatableProps: [ 'methodologyId', 'speciesId', 'detectionDate', 'observation', 'imgFiles', 'imgFilesData' ],
    updatableProps: [ 'speciesId', 'observation', 'detectionDate', 'detectionDate' ],
  },
};


export { recordTypeMap };
