import { useState, useEffect } from 'react';


const useServerMaintenanceOn = () => {
  const [ isServerMaintenanceOn, setServerMaintenanceStatus ] = useState(false);

  const serverMaintenanceEventHandler = () => {
    setServerMaintenanceStatus(localStorage.getItem('maintenance') === 'on');
  };

  useEffect(() => {
    serverMaintenanceEventHandler();
    window.addEventListener('server-maintenance', serverMaintenanceEventHandler, false);
    return () => window.removeEventListener('server-maintenance', serverMaintenanceEventHandler);
  }, []);

  return isServerMaintenanceOn;
};


export { useServerMaintenanceOn };