const isPointIntercept = campaignSubtypeId => campaignSubtypeId === 'point-intercept';
const isPointQuadrat = campaignSubtypeId => campaignSubtypeId === 'point-quadrat';
const isTransect = campaignSubtypeId => isPointIntercept(campaignSubtypeId) || isPointQuadrat(campaignSubtypeId);

const isPlot = campaignSubtypeId => campaignSubtypeId === 'plots';

const isStation = campaignSubtypeId => campaignSubtypeId === 'stations';

const isCensus = campaignSubtypeId => campaignSubtypeId === 'census';

const isFlora = componentOrSubtypeId =>
  componentOrSubtypeId === 'flora' || isPlot(componentOrSubtypeId) || isTransect(componentOrSubtypeId) || isCensus(componentOrSubtypeId);

const isFauna = componentOrSubtypeId => componentOrSubtypeId === 'fauna' || isStation(componentOrSubtypeId);

const isFaunaDirectRecord = recordType => recordType === 'fauna-direct-record';
const isFaunaIndirectRecord = recordType => recordType === 'fauna-indirect-record';
const isFaunaIsolatedRecord = recordType => recordType === 'fauna-isolated-record';
const isFaunaPresenceRecord = recordType => recordType === 'fauna-presence-record';
const isFaunaTransitRecord = recordType => recordType === 'fauna-transit-record';

const isFloraIndividualRecord = recordType => recordType === 'flora-individual-record';
const isFloraRecord = recordType => recordType === 'flora-record';
const isForestRecord = recordType => recordType === 'forest-record';
const isFloraPointQuadratRecord = recordType => recordType === 'flora-point-quadrat-record';
const isFloraPointInterceptRecord = recordType => recordType === 'flora-point-intercept-record';
const isCensusRecord = recordType => recordType === 'flora-census-record';

const isLinealTransect = methodologyType => methodologyType.includes('lineal-transect');
const isSamplingPoint = methodologyType => methodologyType === 'sampling-point';
const isShermanTraps = methodologyType => methodologyType === 'sherman-traps';
const isCameraTraps = methodologyType => methodologyType === 'camera-traps';
const isAerialTransit = methodologyType => methodologyType === 'aerial-transit';
const isEchoLocationDetection = methodologyType => methodologyType === 'echo-location-detection';
const isPlayBack = methodologyType => methodologyType.includes('play-back');


module.exports = {
  isTransect,
  isPlot,
  isStation,
  isCensus,
  isFlora,
  isFauna,
  isPointIntercept,
  isPointQuadrat,
  isFaunaDirectRecord,
  isFaunaIndirectRecord,
  isFaunaIsolatedRecord,
  isFaunaPresenceRecord,
  isFaunaTransitRecord,
  isFloraIndividualRecord,
  isFloraRecord,
  isForestRecord,
  isFloraPointQuadratRecord,
  isFloraPointInterceptRecord,
  isCensusRecord,
  isLinealTransect,
  isSamplingPoint,
  isShermanTraps,
  isCameraTraps,
  isAerialTransit,
  isEchoLocationDetection,
  isPlayBack,
};