import React, { memo } from 'react';
import { Box, InputLabel, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { AutocompleteWrapper, SelectChip, AlertWrapper, SelectAsync, Cards, InfoTooltip } from 'src/components';


const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 500,
  },
  root: {
    borderRadius: theme.shape.borderRadius,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.gray,
  },
  textField: {
    height: theme.spacing(4),
    fontSize: theme.typography.body2.fontSize,
  },
  list: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.gray400,
    borderRadius: theme.shape.borderRadius,
  },
  listItem: {
    width: '100%',
    alignItems: 'center',
  },
  itemData: {
    fontWeight: 600,
    marginLeft: theme.spacing(1),
    display: 'inline-flex',
    alignItems: 'center',
  },
  itemLabel: {
    marginRight: theme.spacing(1),
  },
  itemUnit: {
    marginLeft: theme.spacing(0.5),
  },
  selector: {
    marginTop: theme.spacing(1),
  },
}));

const usageOptions = [
  { label: 'Requerido', value: 'required' },
  { label: 'Opcional', value: 'optional' },
  { label: 'No aplica', value: 'hide' },
];

const defaultSpeciesExplanation = 'Si se elige una especie, ésta estará preseleccionada en el formulario de creación de registros de esta'
  + ' campaña. Puedes dejar este campo vacío para no sugerir una especie en el formulario.';

const parameterExplanation = 'En el descargable SMA-Darwincore, la columna "Parámetro" de la hoja de ocurrencias'
  + ' representa la principal característica numérica de interés de la población estudiada.';

const fieldsExplanation = <div>
  <p>Aquí puedes elegir qué campos se usarán en el formulario de registros de censo en la aplicación.</p>
  <p>Puedes elegir si los campos son requeridos o opcionales, y puedes también deshabilitar campos que no se usarán en la campaña
    (usando el botón { '"No aplica"' })</p>
</div>;

const fixedFieldInfo = {
  'Opcional': 'Este campo siempre está presente en el formulario como campo opcional.',
  'Automático': 'Este campo siempre está presente en el formulario y es rellenado automáticamente.',
};

const fixedFields = [
  { label: 'Observación', state: 'Opcional', isDisabled: true },
  { label: 'Fotos', state: 'Opcional', isDisabled: true },
  { label: 'Ubicación', state: 'Automático', isDisabled: true },
];

const FixedField = memo(({ field }) => {
  const classes = useStyles();

  return <Box display="flex" className={classes.listItem} px={2} py={0.5}>
    <Box width="60%">
      <Typography display="inline" variant="body2" className={classes.itemData}>
        <span className={classes.itemLabel}>{field.label}</span>
        <InfoTooltip placement="right" contents={fixedFieldInfo[field.state]} outlined/>
      </Typography>
    </Box>
    <Box width="30%" flexGrow={1}>
      <Box my={0.5}>
        {field.state}
      </Box>
    </Box>
  </Box>;
});


FixedField.propTypes = {
  field: PropTypes.object,
};


const FixedFieldsList = memo(() => <Cards
  values={fixedFields}
  variant="condensed"
  Content={({ itemData: field }) => <FixedField field={field}/>}
/>, (prev, next) => prev.fixedFields === next.fixedFields);

const makeFieldInfo = field => {
  if (!field.options) {
    return field.description;
  }

  return <div>
    {field.description && <p>{field.description}</p> }
    <p>En este campo se podrá elegir de estos valores: {field.options.map(opt => opt.label).join(', ')} </p>
  </div>;
};

// TODO: ver si implementamos lógica para que solo los campos requeridos puedan ser Parámetro
const CampaignCreateCensusThirdStep = ({ form, handlers, options, validators }) => {
  const classes = useStyles();

  const { censusSettings } = form;
  const { parameterOptions, getSpeciesOptions } = options;
  const { handleParameterSelect, handleCensusFieldUsageChange, handleCensusFieldRequirementChange, handleSpeciesSelect } = handlers;
  const { hasBadData } = validators;
  const { fields } = censusSettings;

  const handleChipChange = (filedId, selectedValue) => {
    if (selectedValue.value === 'hide') {
      handleCensusFieldRequirementChange(filedId, false);
      handleCensusFieldUsageChange(filedId, false);
    } else {
      handleCensusFieldRequirementChange(filedId, selectedValue.value === 'required');
      handleCensusFieldUsageChange(filedId, true);
    }
  };

  return (
    <Box px={1} width="100%">
      <Typography className={classes.title} variant="body1">Configurar Campaña</Typography>
      <Box my={2} display="flex">
        <Box width="100%">
          <Box mb={0.5}>
            <InputLabel shrink>
              Especie a sugerir <InfoTooltip placement="right" contents={defaultSpeciesExplanation}/>
              <small><em> (se mostrarán máximo 10 resultados)</em></small>
            </InputLabel>
          </Box>
          <SelectAsync
            id="select-species"
            initialOptions={false}
            value={censusSettings.defaultSpecies}
            onChange={handleSpeciesSelect}
            getOptions={getSpeciesOptions}
            placeholder="Elige una especie"
            useScientificNameFormat
            clearable={true}
          />
        </Box>
      </Box>
      <Box my={2}>
        <InputLabel shrink error={hasBadData && censusSettings.length === 0}>
          Campos a usar en los registros <InfoTooltip placement="right" contents={fieldsExplanation}/>
        </InputLabel>
        <Box px={2} py={2} mt={0.5} display="flex" flexDirection="column" className={classes.root}>
          <Box mb={2}>
            <Cards
              alwaysShowActions={true}
              values={fields}
              variant="condensed"
              checkItemDisabled={field => !field.isActive}
              Content={({ itemData: field }) =>
                <Box key={field.id} display="flex" className={classes.listItem} px={2} py={0.5}>
                  <Box width="60%">
                    <Typography display="inline" variant="body2" className={classes.itemData}>
                      <span className={classes.itemLabel}>
                        <span>{field.label}</span>
                        { field.unit && <small className={classes.itemUnit}>({ field.unit })</small>}
                      </span>
                      { (field.description || field.options) && <InfoTooltip placement="right" contents={makeFieldInfo(field)} outlined/> }
                    </Typography>
                  </Box>
                  <Box width="30%" flexGrow={1}>
                    <Box my={0.5}>
                      <SelectChip
                        value={ field.isRequired ? 'required' : !field.isActive ? 'hide' : 'optional' }
                        options={usageOptions}
                        onChange={value => handleChipChange(field.id, value)}
                      />
                    </Box>
                  </Box>
                </Box>
              }
            />
          </Box>
          <Box mb={1}>
            <FixedFieldsList/>
          </Box>
        </Box>
        <Box mt={3}>
          <InputLabel shrink>Campo Parámetro del descargable SMA-Darwincore <InfoTooltip placement="right"
            contents={parameterExplanation}/></InputLabel>
          <AutocompleteWrapper className={classes.selector}
            value={ censusSettings.smaParameterIndex === null ?
              null : { value: censusSettings.smaParameterIndex, label: censusSettings.fields[censusSettings.smaParameterIndex].label }
            }
            options={ parameterOptions }
            onChange={(_, option) => handleParameterSelect(option)}
            getOptionSelected={(option, value) => option.value === value?.value}
            getOptionDisabled={ option => !fields[option.value].isActive}
            renderOption={
              option => fields[option.value].isActive ? option.label : <span>{option.label} <em>(no usado en formulario)</em></span>
            }
            clearText={ 'Borrar' }
            clearable
          />
          <Box mt={1}>
            <AlertWrapper variant="outlined" severity='warning' isOpen={censusSettings.smaParameterIndex === null}>
              Si no se elige o no se puede elegir ningún campo como parámetro, se usará {'"Presencia/Ausencia"'} con
              valor 1 en el descargable SMA-Darwincore, a modo de no dejar esa columna vacía.
            </AlertWrapper>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

CampaignCreateCensusThirdStep.propTypes = {
  form: PropTypes.object,
  options: PropTypes.object,
  handlers: PropTypes.object,
  validators: PropTypes.object,
};


export {
  CampaignCreateCensusThirdStep,
};
