import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { upperCaseFirstLetter } from 'src/utils/formatters';
import { Button, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';

import { AlertWrapper, DialogButton } from 'src/components';
import { useFetchStatus } from 'src/hooks';


const recordTypeName = {
  'flora-record': 'registro de flora',
  'flora-individual-record': 'registro de flora individual',
  'flora-point-quadrat-record': 'registro de point-quadrat',
  'flora-point-intercept-record': 'registro de point intercept',
  'forest-record': 'registro forestal',
  'flora-census-record': 'registro de censo',
  'fauna-direct-record': 'registro directo',
  'fauna-indirect-record': 'registro indirecto',
  'fauna-isolated-record': 'registro aislado',
  'fauna-presence-record': 'registro de presencia',
  'fauna-transit-record': 'registro de tránsito',
};

const alertBoxProps = { mt: 2 };

const RecordDeleteDialog = ({ record, recordType, actions }) => {
  const upSpeciesName = record.scientificName ? upperCaseFirstLetter(record.scientificName) : 'Sin reconocer';

  const { handleConfirm, fetchStatus, errorMessage, hasError } = useFetchStatus({
    confirmFn: () => actions.deleteRecord({ record }),
    closeFn: actions.closeDialog,
  });

  return (
    <Fragment>
      <DialogTitle id="form-dialog-title">Eliminar Registro</DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="textPrimary">
          Estás por eliminar un { recordTypeName[recordType] } de la especie <strong><i>{ upSpeciesName }</i></strong>,
          esta acción es irreversible y
          <Typography component='span' display="inline" color="error"> perderás todos los datos</Typography> asociados al registro.
        </Typography>
        <AlertWrapper BoxProps={alertBoxProps} severity="error" isOpen={hasError} hideCloseButton>
          { errorMessage }
        </AlertWrapper>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>Confirmar</DialogButton>
      </DialogActions>
    </Fragment>
  );
};

RecordDeleteDialog.propTypes = {
  record: PropTypes.object,
  actions: PropTypes.object,
  recordType: PropTypes.string,
};

export { RecordDeleteDialog };
