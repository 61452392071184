import { get, patch, post } from 'src/services/api';


const getIndividualRecordsByCampaign = async campaignHash => (await get('flora/individual-records', { campaignHash })).data;

const getIndividualRecordsForMap = async campaignHash => (await get('flora/individual-records-for-map', { campaignHash })).data;

const getRecordsByPlot = async plotHash => (await get('flora/records', { plotHash })).data;

const updateIndividualRecord = async ({ updatedData, recordId }) => (await patch(`/flora/individual-record/${recordId}`, updatedData)).data;

const updateRecord = async ({ recordId, updatedData }) => (await patch(`/flora/record/${recordId}`, updatedData)).data;

const updateTransectRecord = async ({ recordId, updatedData }) => (await patch(`/flora/transect-record/${recordId}`, updatedData)).data;

const updateCensusRecord = async ({ recordId, updatedData }) => (await patch(`/flora/census-record/${recordId}`, updatedData)).data;

const getCatalogs = async () => (await get('/flora/catalogs')).data;

const getPhenologiesStatuses = async () => (await get('flora/phenology-statuses')).data;

const getPhytosanitaryStatuses = async () => (await get('flora/phytosanitary-statuses')).data;

const getSoilCoverages = async () => (await get('flora/soil-cover')).data;

const createRecord = async record => (await post(`/flora/record`, record, { headers: { 'Content-Type': 'multipart/form-data' } })).data;

const getCensusFields = async () => (await get('flora/census-fields')).data;

const getCensusRecordsForMap = async campaignId => (await get('flora/census-records-for-map', { campaignId })).data;

const getCensusRecordsByCampaign = async campaignId => (await get('flora/census-records', { campaignId })).data;


export {
  getIndividualRecordsByCampaign,
  getIndividualRecordsForMap,
  getRecordsByPlot,
  updateIndividualRecord,
  updateRecord,
  getCatalogs,
  getPhenologiesStatuses,
  getPhytosanitaryStatuses,
  getSoilCoverages,
  updateTransectRecord,
  updateCensusRecord,
  createRecord,
  getCensusFields,
  getCensusRecordsForMap,
  getCensusRecordsByCampaign,
};
