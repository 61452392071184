import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { AccessControl } from 'src/components';


const CampaignRoutes = ({ changeActive, setIsDrawerOpen, checkCompletion, availableRoutes }) => {
  const { path: basePath, url: routeUrl } = useRouteMatch();
  const redirectToCampaignMainPage = () => <Redirect to={`${routeUrl}/monitoring`} />;

  return (
    <Switch>
      { availableRoutes.map(({ path, Component, action }, index) =>
        <Route key={index} exact path={`${basePath}/${path}`} render={ props =>
          action ?
            <AccessControl action={ action } noAccessRender={ redirectToCampaignMainPage }
              checkOnCampaignRole>
              <Component {...props} checkCompletion={checkCompletion} changeActive={changeActive} setIsDrawerOpen={setIsDrawerOpen} />
            </AccessControl>
            : <Component {...props} checkCompletion={checkCompletion} changeActive={changeActive} setIsDrawerOpen={setIsDrawerOpen} /> }
        />)
      }
      <Route path={basePath} component={redirectToCampaignMainPage} />
    </Switch>
  );
};

CampaignRoutes.propTypes = {
  subtypeId: PropTypes.string,
  availableRoutes: PropTypes.array,
  changeActive: PropTypes.func,
  setIsDrawerOpen: PropTypes.func,
  checkCompletion: PropTypes.func,
};


export { CampaignRoutes };
