import React from 'react';
import { Box, InputLabel, TextField, Typography, Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';

import { startDateErrorMsg, endDateErrorMsg } from 'src/utils/util';


const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 500,
  },
  tooltip: {
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
  },
}));

const CampaignCreateSecondStep = ({ form, handlers, validators }) => {

  const classes = useStyles();

  const { startDate, endDate, objective, observation } = form;
  const { handleChange, handleSelect } = handlers;
  const { hasBadData } = validators;

  return (
    <Box px={1} width="100%">
      <Typography className={classes.title} variant="body1">Ingresar fechas y objetivos</Typography>
      <Box my={2}>
        <InputLabel shrink error={hasBadData && !startDate}>Fecha de inicio</InputLabel>
        <DatePicker value={startDate} selected={startDate} autoOk
          TextFieldComponent={props => <TextField {...props} variant="outlined" size="small" />}
          onChange={startDate => handleSelect({ startDate })} format="DD-MM-YYYY"
          helperText={startDateErrorMsg({ submitting: hasBadData, startDate })}
          error={hasBadData && !startDate} />
      </Box>
      <Box my={2}>
        <InputLabel shrink error={hasBadData && (!endDate || (endDate && (endDate < startDate)))}>Fecha de término</InputLabel>
        <DatePicker value={endDate} autoOk selected={endDate}
          onChange={endDate => handleSelect({ endDate })} format="DD-MM-YYYY" minDate={startDate}
          TextFieldComponent={props => <TextField {...props} variant="outlined" size="small" />}
          helperText={endDateErrorMsg({ submitting: hasBadData, startDate, endDate })}
          error={(hasBadData && !endDate) || (endDate && (endDate < startDate))} />
      </Box>
      <Box my={2}>
        <InputLabel shrink>
          <Box display="flex">
            Objetivos (opcional)
            <Tooltip title="Se recomienda ingresar objetivos ya que estos se verán en el descargable del SMA-Darwincore">
              <Info fontSize="small" className={classes.tooltip} />
            </Tooltip>
          </Box>
        </InputLabel>
        <TextField
          name="objective" value={objective} variant="outlined" size="small"
          minRows="2" maxRows='5' multiline fullWidth autoComplete="off"
          placeholder="Ej: Objetivos de la campaña" onChange={handleChange}
        />
      </Box>
      <Box my={2}>
        <InputLabel shrink>
          <Box display="flex">
            Comentarios adicionales (opcional)
            <Tooltip
              title="Se recomienda ingresar comentarios adicionales si es que deseas que estos se vean en el descargable del SMA-Darwincore"
            >
              <Info fontSize="small" className={classes.tooltip} />
            </Tooltip>
          </Box>
        </InputLabel>
        <TextField
          name="observation" value={observation} variant="outlined" size="small"
          minRows="2" maxRows='5' multiline fullWidth autoComplete="off"
          placeholder="Ej: Comentarios adicionales de la campaña" onChange={handleChange}
        />
      </Box>
    </Box>
  );
};

CampaignCreateSecondStep.propTypes = {
  form: PropTypes.object,
  handlers: PropTypes.object,
  validators: PropTypes.object,
};


export {
  CampaignCreateSecondStep,
};