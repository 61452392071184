import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';

import { CompanyProfileContainer, CompanyLinkedAppsContainer, CompanyLinkedCampaignsContainer } from 'src/scenes/CompanySettings/scenes';


const routes = [
  {
    path: 'profile',
    Component: CompanyProfileContainer,
  },
  {
    path: 'suscription',
    Component: () => <>Hola suscripciones</>,
  },
  {
    path: 'linked-apps',
    Component: CompanyLinkedAppsContainer,
  },
  {
    path: 'linked-apps/:companyId/allowed-campaigns',
    Component: CompanyLinkedCampaignsContainer,
  },
];


const CompanySettingsRoutes = props => {
  const match = useRouteMatch();
  const { path: basePath, url: routeUrl } = match;
  const redirectToCompanySettingsMainPage = () => <Redirect to={`${routeUrl}/profile`} />;

  return (
    <Switch>
      {routes.map(({ path, Component, hasNestedRoutes }, index) =>
        <Route
          key={index}
          exact={!hasNestedRoutes}
          path={`${basePath}/${path}`}
          render={rProps => <Component {...rProps} {...props} />}
        />)
      }
      <Route path={basePath} component={redirectToCompanySettingsMainPage} />
    </Switch>
  );
};


export { CompanySettingsRoutes };