const getId = () => Math.random().toString(36).slice(2);

const monitoringPlans = [
  {
    id: getId(),
    name: 'Plan de monitoreo 1',
    frequency: 'Semanal',
    deliveryDate: '',
    finishDate: '',
    isFinished: false,
    responsibleUser: 'Rigoberto Valle',
    componentId: 'flora',
    catalogId: 'Darwinion',
    samplingUnit: 'plots', // plots || stations || transects || some-shit (censo unit) || another-shit (reforest unit)
  },
  {
    id: getId(),
    name: 'Plan de monitoreo 2',
    frequency: 'Mensual',
    deliveryDate: '',
    finishDate: '',
    isFinished: false,
    responsibleUser: 'John Lara',
    componentId: 'flora',
    catalogId: 'Darwinion',
    samplingUnit: 'transects',
  },
  {
    id: getId(),
    name: 'Plan de monitoreo 3',
    frequency: 'Anual',
    deliveryDate: '',
    finishDate: '',
    isFinished: false,
    responsibleUser: 'Ernesto Silva',
    componentId: 'fauna',
    catalogId: 'Fauna de chile',
    samplingUnit: 'plots',
  },
];

const getNewMonitoringPlan = mp => ({
  id: getId(),
  name: mp.name,
  frequency: mp.frequency,
  deliveryDate: '',
  finishDate: '',
  isFinished: false,
  responsibleUser: 'John Lara',
  componentId: mp.componentId,
  catalogId: 'Darwinion',
  samplingUnit: 'transects',
});

const samplingUnits = {
  flora: [ { label: 'Parcelas', value: 'plots' }, { label: 'Transectas', value: 'transect' }, { label: 'Censo', value: 'census' } ],
  fauna: [ { label: 'Estaciones', value: 'stations' } ],
};

const formTypes = [
  {
    id: getId(),
    name: 'Formulario avellanita',
    fields: [
      { id: getId(), label: 'Código', required: true },
      { id: getId(), label: 'Sobrevivencia', required: false },
      { id: getId(), label: 'Altura', required: false },
      { id: getId(), label: 'Tipo de tallo', required: false },
      { id: getId(), label: 'Estado', required: false },
    ],
  },
  {
    id: getId(),
    name: 'Formulario monitoreo 1',
    fields: [
      { id: getId(), label: 'Código', required: true },
      { id: getId(), label: 'Estado', required: true },
      { id: getId(), label: 'Observaciones', required: false },
    ],
  },
  {
    id: getId(),
    name: 'Formulario monitoreo rca-1024',
    fields: [
      { id: getId(), label: 'Código', required: true },
      { id: getId(), label: 'Nivel de desarrollo', required: false },
      { id: getId(), label: 'Comportamiento', required: false },
      { id: getId(), label: 'Observaciones', required: false },
    ],
  },
];


export {
  monitoringPlans,
  getNewMonitoringPlan,
  samplingUnits,
  formTypes,
};