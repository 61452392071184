import React, { Fragment, useState } from 'react';
import { Box, DialogContent, InputLabel, DialogActions, Button, DialogTitle, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

import { SelectChip, AccessControl } from 'src/components';


const projectStatusesOptions = [
  { value: 'all', label: 'Todos' },
  { value: 'active', label: 'Activos' },
  { value: 'completed', label: 'Completados' },
];

const projectCreatorOptions = [
  { value: 'anyone', label: 'Cualquiera' },
  { value: 'me', label: 'Mi' },
  { value: 'other', label: 'Otros' },
];

const projectComponentOptions = [
  { value: 'flora', label: 'Flora - forestal' },
  { value: 'fauna', label: 'Fauna' },
];

const ProjectsFiltersDialog = props => {
  const { actions, prevFilters, defaultFilters, inPersonalCompany } = props;

  const [ filters, setFilters ] = useState({
    name: prevFilters?.name ?? defaultFilters.name,
    status: prevFilters?.status ?? defaultFilters.status,
    creator: prevFilters?.creator ?? defaultFilters.creator,
    component: prevFilters?.component ?? defaultFilters.component,
  });
  const handleChange = filterId => option => setFilters({ ...filters, [ filterId ]: option.value });

  const handleNameChange = e => setFilters({ ...filters, name: e.target.value });

  return (
    <Fragment>
      <DialogTitle id='form-dialog-title'>Filtrar proyectos</DialogTitle>
      <DialogContent>
        <Box my={2} ml={1}>
          <Box display="flex">
            <InputLabel shrink>Nombre incluye</InputLabel>
          </Box>
          <TextField value={filters.name} onChange={ handleNameChange } fullWidth autoFocus autoComplete="off" size="small"/>
        </Box>
        <Box my={4} ml={1}>
          <InputLabel shrink>Mostrar</InputLabel>
          <SelectChip value={filters.status} options={projectStatusesOptions} onChange={handleChange('status')} />
        </Box>
        <AccessControl action="project:create" extraCondition={ !inPersonalCompany }>
          <Box my={4} ml={1}>
            <InputLabel shrink>Creados por</InputLabel>
            <SelectChip value={filters.creator} options={projectCreatorOptions} onChange={handleChange('creator')} />
          </Box>
        </AccessControl>
        <Box my={4} ml={1}>
          <InputLabel shrink>Componente</InputLabel>
          <SelectChip value={filters.component} options={projectComponentOptions} isMulti onChange={handleChange('component')} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box color="error.main">
          <Button color="inherit" onClick={() => actions.applyFilters(defaultFilters)}>Reiniciar filtros y aplicar</Button>
        </Box>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <Button onClick={() => actions.applyFilters(filters)} color="primary">Aplicar</Button>
      </DialogActions>
    </Fragment>
  );
};

ProjectsFiltersDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  prevFilters: PropTypes.object.isRequired,
  defaultFilters: PropTypes.object.isRequired,
  inPersonalCompany: PropTypes.bool.isRequired,
};


export { ProjectsFiltersDialog };