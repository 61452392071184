import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { ExpandMore } from '@material-ui/icons';
import {
  Typography, Box,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Accordion as MuiAccordion,
} from '@material-ui/core';


const Accordion = withStyles(theme => ({
  root: {
    marginTop: theme.spacing(10),
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  },
}))(MuiAccordion);

const AccordionSummary = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.lightGray,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.lightGray,
  },
}))(MuiAccordionDetails);


const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: '1280px',
    paddingBottom: theme.spacing(13),
  },
  questionText: {
    fontSize: '1.5rem',
  },
  answerText: {
    fontSize: '1rem',
    color: theme.palette.common.gray600,
    marginBottom: theme.spacing(10),
  },
}));

// TODO: No se usa en nada: que se use en la cosa de precios para repsonder preguntas... cuando haya precios y cosas que responder
const Faq = ({ faq }) => {

  const classes = useStyles();
  const [ expanded, setExpanded ] = useState(0);
  const handleChange = panel => (event, newExpanded) => setExpanded(newExpanded ? panel : false);

  return (
    <Box className={classes.container}>
      {faq.map(({ question, answer }, index) =>
        <Accordion key={index} square expanded={expanded === index} onChange={handleChange(index)}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography color="primary" className={classes.questionText}>{question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.answerText}>
              {answer}
            </Typography>
          </AccordionDetails>
        </Accordion>,
      )}
    </Box>
  );
};

Faq.propTypes = {
  faq: PropTypes.array,
};


export { Faq };