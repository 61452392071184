import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText } from '@material-ui/core';


// Para usar dentro de diálogos de registro borrado o editado
const RecordDataList = ({ data }) => (
  <List py={0} disablePadding>
    { data.map(({ name, value }, ind) =>
      <ListItem py={0} key={ ind } alignItems="flex-start" dense>
        <ListItemText py={0} style={{ whiteSpace: 'pre-line' }}
          primary={ name }
          secondary={
            <Fragment>
              { value ? value : <em>{ '<Este campo se dejó vacío>' }</em>}
            </Fragment>
          }
        />
      </ListItem>,
    )}
  </List>
);

RecordDataList.propTypes = {
  data: PropTypes.array,
};

export { RecordDataList };
