import React from 'react';
import { Line, Bar, Pie, Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);


const chartTypeMapper = {
  line: Line,
  bar: Bar,
  pie: Pie,
  doughnut: Doughnut,
};

const ChartWrapper = ({ children, type, id = 'chart', ...otherProps }) => {
  const Chart = chartTypeMapper[type];

  if (Chart === undefined) {
    throw Error ('Tipo no soportado');
  }

  return (
    <Chart id={id} {...otherProps}>
      {children}
    </Chart>
  );
};

ChartWrapper.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any,
  type: PropTypes.string,
};


export { ChartWrapper };