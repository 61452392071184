import { get } from 'src/services/api';


const getSpeciesCounter = async campaignHash => (await get(`campaigns/${campaignHash}/statistics/species-counter`)).data;
const getRecordsCounter = async campaignHash => (await get(`campaigns/${campaignHash}/statistics/records-counter`)).data;
const getPointTimeByUser = async campaignHash => (await get(`campaigns/${campaignHash}/statistics/point-time-by-user`)).data;
const getSpeciesSaturationCurve = async campaignHash => (await get(`campaigns/${campaignHash}/statistics/species-saturation-curve`)).data;
const getGeneralPointsProgress = async campaignHash => (await get(`campaigns/${campaignHash}/statistics/general-points-progress`)).data;
const getPointsByUser = async campaignHash => (await get(`campaigns/${campaignHash}/statistics/users-progress-in-points`)).data;
const getSpeciesByUser = async campaignHash => (await get(`campaigns/${campaignHash}/statistics/species-counter-by-user`)).data;
const getRecordsByUser = async campaignHash => (await get(`campaigns/${campaignHash}/statistics/records-counter-by-user`)).data;
const getSpeciesCounterByPoint = async campaignHash => (await get(`campaigns/${campaignHash}/statistics/species-counter-by-point`)).data;

const getSpeciesByClass = async campaignHash => (await get(`campaigns/${campaignHash}/statistics/species-counter-by-class`)).data;
const getFamiliesByClass = async campaignHash => (await get(`campaigns/${campaignHash}/statistics/families-counter-by-class`)).data;

const getCoverageByPoint = async campaignHash => (await get(`campaigns/${campaignHash}/statistics/coverage-by-point`)).data;


export {
  getSpeciesCounter,
  getRecordsCounter,
  getPointTimeByUser,
  getSpeciesSaturationCurve,
  getGeneralPointsProgress,
  getPointsByUser,
  getRecordsByUser,
  getSpeciesByUser,
  getSpeciesCounterByPoint,

  getSpeciesByClass,
  getFamiliesByClass,

  getCoverageByPoint,
};
