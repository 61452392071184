import React from 'react';
import { Drawer } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({
  drawer: {
    width: ({ width }) => width,
    flexShrink: 0,
  },
  drawerPaper: {
    width: ({ width }) => width,
  },
}));

const DrawerWrapper = props => {
  const { children, width = 240, isDrawerOpen = true, ...otherProps } = props;
  const classes = useStyles({ width });
  return (
    <Drawer
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
      variant="persistent"
      anchor="left"
      open={isDrawerOpen}
      {...otherProps}
    >
      {children}
    </Drawer>
  );
};

DrawerWrapper.propTypes = {
  children: PropTypes.any,
  width: PropTypes.number,
  isDrawerOpen: PropTypes.bool,
};


export { DrawerWrapper } ;
