import React, { memo, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';

import { NviroButton } from 'src/components';
import { usersApi } from 'src/services';


const useStyles = makeStyles(theme => ({
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  message: {
    marginBottom: theme.spacing(1),
  },
  button: {
    height: '2.3rem',
    paddingRight: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
  },
}));

const EmailVerificationRetryNote = boxProps => {
  const [ resending, setResending ] = useState(false);
  const [ errorMsg, setErrorMsg ] = useState('');
  const [ successMsg, setSuccessMsg ] = useState('');

  const classes = useStyles();

  const handleResendEmail = async () => {
    if (resending || successMsg || errorMsg) {
      return;
    }
    try {
      setResending(true);
      const resp = await usersApi.resendVerificationEmail();
      setSuccessMsg(resp);
    } catch (e) {
      if (e.serverMessage) {
        setErrorMsg(e.serverMessage);
      }
      console.error(e);
      setErrorMsg('Error al intentar reenviar enlace. Por favor, intente más tarde');
    }
    setResending(false);
  };

  return (
    <Box { ...boxProps }>
      <Box className={classes.innerContainer}>
        <Typography variant="caption" className={classes.message}>
          ¿No te ha llegado el correo? Si luego de esperar unos minutos aún no has recibido el correo de
          verificación, puedes usar el siguiente botón para que te enviemos otro.
        </Typography>
        <Box>
          <NviroButton disabled={Boolean(errorMsg)} onClick={handleResendEmail} color="black" size="small"
            loading={resending} success={Boolean(successMsg)} shadow={true} className={classes.button}
          >
            <Typography style={{ fontSize: '0.8em' }}>
              Reenviar enlace
            </Typography>
          </NviroButton>
        </Box>
      </Box>
      { errorMsg && <Box mt={1}><Alert severity="error">Error: {errorMsg}.</Alert></Box> }
    </Box>
  );
};

const EmailVerificationRetryNoteMemoized = memo(EmailVerificationRetryNote);

export {
  EmailVerificationRetryNoteMemoized as EmailVerificationRetryNote,
};
