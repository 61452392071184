import React from 'react';
import { InputLabel, Box, TextField, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';

import { SelectChip } from 'src/components';


const PointQuadratRecordInputs = ({ record, handlers, validators, options }) => {

  const { touches, stratumsIds } = record;
  const { stratumsOptions } = options;
  const { handleChange, handleSelect } = handlers;
  const { hasBadData, hasValidTouches } = validators;

  return (
    <>
      <Box my={2}>
        <InputLabel shrink>Contactos</InputLabel>
        <TextField
          value={touches}
          name="touches"
          onChange={handleChange}
          type="number"
          fullWidth
          autoComplete="off"
          variant="outlined"
          size="small"
          error={hasBadData && !hasValidTouches}
        />
        {hasBadData && !hasValidTouches &&
          <FormHelperText error>El número de contactos debe estar entre 1 y 100</FormHelperText>
        }
      </Box>

      <Box my={2}>
        <InputLabel shrink>Estratos</InputLabel>
        <SelectChip
          value={stratumsIds}
          options={stratumsOptions}
          onChange={option => handleSelect({ stratumsIds: option.value })}
          isMulti
        />
      </Box>
    </>
  );
};

PointQuadratRecordInputs.propTypes = {
  record: PropTypes.object.isRequired,
  handlers: PropTypes.object,
  validators: PropTypes.objectOf(PropTypes.bool),
  options: PropTypes.object,
};


export { PointQuadratRecordInputs };