import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Box, Button, FormControl, Paper, CircularProgress, Grid } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';


import { usersApi } from 'src/services';
import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';
import { NviroTextField } from 'src/scenes/Home/components';
import { Feature } from 'src/scenes/Home/scenes/DemoLanding/components';


const useStyles = makeStyles(theme => ({
  container: {
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(5),
    },
  },
  titleContainer: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  titleText: {
    color: 'rgba(81, 147, 235, 1)',
    fontWeight: '600',
    fontSize: '4.375rem',
    marginTop: theme.spacing(7),
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.575rem',
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '3.575rem',
    },
  },
  paperMainMessage: {
    padding: theme.spacing(10),
    backgroundColor: '#EEEEEE',
    maxWidth: '560px',
    [theme.breakpoints.only('lg')]: {
      padding: theme.spacing(7),
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      padding: theme.spacing(3),
    },
  },
  mainMessage: {
    color: theme.palette.common.gray600,
    fontSize: '2.5rem',
    lineHeight: '2.5rem',
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.75rem',
    },
  },
  subscribeContainer: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  subMessage: {
    color: '#40474B',
    fontSize: '1.25rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.15rem',
    },
  },
  subscribeMessage: {
    color: '#40474B',
    textAlign: 'center',
    fontSize: '1.25rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.15rem',
    },
  },
  subscribeButton: {
    height: theme.spacing(7),
    fontSize: '0.938rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.838rem',
      padding: 0,
    },
  },
  checkIcon: {
    fontSize: '2.5rem',
    color: theme.palette.nviro.secondary.main,
  },
  featuresContainer: {
    paddingLeft: theme.spacing(9),
    paddingRight: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      paddingTop: theme.spacing(5),
    },
  },
  highlight: {
    color: theme.palette.primary.main,
  },
  grid: {
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      width: 'unset',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      width: 'unset',
    },
  },
  textField: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
  },
  formContainer: {
    maxWidth: '480px',
  },
}));

const DemoLandingContainer = () => {

  const classes = useStyles();

  const [ email, setEmail ] = useState('');
  const [ error, setError ] = useState('');
  const [ fetchStatus, setFetchStatus ] = useState(fetchStatusEnum.NOT_STARTED);


  const subscribeMe = async () => {
    setFetchStatus(fetchStatusEnum.LOADING);
    try {
      await usersApi.subscribeMail({ email, fillMe: '' });
      setFetchStatus(fetchStatusEnum.SUCCESS);
    } catch (e) {
      setFetchStatus(fetchStatusEnum.ERROR);
      setError(e.serverMessage || 'Error en el correo ingresado');
    }
  };

  const onChangeEmail = e => {
    const text = e.target.value;
    setEmail(text);
    setError('');
  };

  return (
    <Grid className={classes.container} container justifyContent='center'>
      <Grid item className={classes.titleContainer}>
        <Typography className={classes.titleText} align='center'>¿Qué es Nviro Capture?</Typography>
      </Grid>
      <Grid container item alignItems='center' justifyContent="center">
        <Grid item className={classes.grid}>
          <Paper elevation={0} className={classes.paperMainMessage}>
            <Box className={classes.subscribeContainer}>
              <Typography className={classes.mainMessage} component="div">
                Averigua lo que puede hacer <Box display="inline" className={classes.highlight}>Nviro Capture</Box> para tu empresa
              </Typography>
              <Box py={2}>
                <Typography className={classes.subMessage}>
                  La nueva forma de capturar y gestionar datos especializados en la industria ambiental
                </Typography>
              </Box>
              <Box pb={4} pt={2.5}>
                <Typography className={classes.subscribeMessage}>
                  ¿Quieres agendar una demostración?
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <FormControl className={classes.formContainer} fullWidth>
                  {
                    (fetchStatus === fetchStatusEnum.NOT_STARTED || fetchStatus === fetchStatusEnum.ERROR) &&
                      <NviroTextField
                        className={classes.textField}
                        label="Correo electrónico" variant="outlined"
                        type='email' onChange={onChangeEmail}
                        helperText={error}
                        error={fetchStatus === fetchStatusEnum.ERROR}
                        onEnter={subscribeMe}
                      />
                  }
                  {
                    fetchStatus === fetchStatusEnum.LOADING &&
                      <Box alignSelf="center">
                        <CircularProgress color="secondary" fontSize="large" />
                      </Box>
                  }
                  {
                    fetchStatus === fetchStatusEnum.SUCCESS &&
                      <Box alignSelf="center">
                        <CheckCircleOutline className={classes.checkIcon} />
                      </Box>
                  }
                  <br/>
                  <Button
                    disableElevation
                    variant="contained"
                    size="large"
                    className={classes.subscribeButton}
                    color="secondary"
                    {...(
                      (fetchStatus === fetchStatusEnum.NOT_STARTED || fetchStatus === fetchStatusEnum.ERROR) ?
                        { onClick: subscribeMe } : {}
                    )}
                    disabled={fetchStatus === fetchStatusEnum.LOADING}
                  >
                    {
                      fetchStatus !== fetchStatusEnum.SUCCESS ?
                        'Solicitar una demostración' :
                        'Listo, ¡nos pondremos en contacto contigo!'
                    }
                  </Button>
                </FormControl>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item className={classes.grid}>
          <Box alignItems="center" flexBasis={0} flexGrow={1} flexShrink={1}>
            <Box display="flex" flexDirection="column" className={classes.featuresContainer}>
              <Feature
                title="Planifica"
                description="Proyectos y campañas para múltiples personas."
                imageSrc="/img/home/ico-graph.svg"
              />
              <Feature
                title="Recolecta"
                description="Información de tus líneas base con apps especializadas."
                imageSrc="/img/home/ico-place.svg"
              />
              <Feature
                title="Analiza y Visualiza"
                description="Tus datos en gráficos y tablas generadas automáticamente."
                imageSrc="/img/home/ico-mac.svg"
              />
              <Feature
                title="Y más"
                description="Funcionalidades esperando por ti."
                imageSrc="/img/home/ico-op.svg"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};


export { DemoLandingContainer };
