import React, { useCallback } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Typography, Button, useMediaQuery } from '@material-ui/core';
import { Assessment, CloudDownload, ListAlt } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { BrowserUI, HeaderUI, StatisticsUI } from 'src/scenes/Home/components';
import { StepTitle, StepDescription } from 'src/scenes/Home/scenes/HowItWork/components';


const useStyles = makeStyles(theme => ({
  stepThreeDisplayContainer: {
    width: '780px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  stepThreeText: {
    width: '700px',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  statisticsWebPhoto: {
    width: '780px',
    height: '520px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  stepThreeButton: {
    borderRadius: '14px',
    fontSize: '5em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5em',
    },
  },
  stepThreeActiveButtonBox: {
    borderRadius: '14px',
    borderStyle: 'solid',
    borderColor: theme.palette.flora.primary.main,

  },
  stepThreeButtonBox: {
    borderRadius: '14px',
    borderStyle: 'solid',
    borderColor: 'grey',
  },
  stepThreeBox: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  icon: {
    color: theme.palette.primary.main,
  },
}));

const analyseAndDownloadComponentsTinny = [
  <img
    key={0}
    src={'/img/tmp/statistics-2.png'}
    style={ { width: 'inherit', height: 'inehrit' } }
    alt={ `Sitio web con estadísticas de flora` }
  />,
  <img key={1} src={'/img/tmp/downloadables-4.png'}
    style={ { width: '100%' } }
    alt={ `Sitio web con sección titulada 'Descargables'. Contiene una grilla con 6 subsecciones tituladas:` +
      ` 'Listado Florístico' 'Listado Florístico EECC', 'Especies Potenciales', 'Listado floral-forestal',` +
      ` 'KML de registros individuales', 'Tracks de la campaña'; cada una con opciones para descargar el contenido correspondiente.` }
  />,
  <img key={2} src={'/img/tmp/album-2.png'}
    style={ { width: '100%' } }
    alt={ `Sitio web con título 'Álbum General'. Tiene una grilla con 3 columnas y 2 filas de fotos,` +
    ` con una barra de desplazamiento que sugiere que hay una fila más. Las fotos son de diversos tipos` +
    ` de plantas cada una con el nombre de la especie así como el email de quien tomó la foto. Abajo de` +
    ` la grilla hay un selector de páginas: la página actual es la número 2 y hay 7 páginas en total.` }
  />,
];

const analyseAndDownloadComponents = [
  <StatisticsUI miniMode={ true } key={0}/>,
  <img key={1} src={'/img/tmp/downloadables_2.png'}
    style={ { width: '100%' } }
    alt={ `Sitio web con sección titulada 'Descargables'. Contiene una grilla con 6 subsecciones tituladas:` +
      ` 'Listado Florístico' 'Listado Florístico EECC', 'Especies Potenciales', 'Listado floral-forestal',` +
      ` 'KML de registros individuales', 'Tracks de la campaña'; cada una con opciones para descargar el contenido correspondiente.` }
  />,
  <img key={2} src={'/img/tmp/album.jpg'}
    style={ { width: '100%' } }
    alt={ `Sitio web con título 'Álbum General'. Tiene una grilla con 3 columnas y 2 filas de fotos,` +
      ` con una barra de desplazamiento que sugiere que hay una fila más. Las fotos son de diversos tipos` +
      ` de plantas cada una con el nombre de la especie así como el email de quien tomó la foto. Abajo de` +
      ` la grilla hay un selector de páginas: la página actual es la número 2 y hay 7 páginas en total.` }
  />,
];

const AnalyseAndDownloadSection = ({ activeButtonNumber, setActiveButtonNumber }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const setOne = useCallback(() => setActiveButtonNumber(1), [ setActiveButtonNumber ]);
  const setTwo = useCallback(() => setActiveButtonNumber(2), [ setActiveButtonNumber ]);
  const setThree = useCallback(() => setActiveButtonNumber(3), [ setActiveButtonNumber ]);

  return (
    <Box display="flex" className={classes.stepThreeBox}>
      <Box className={classes.stepThreeText} display="flex" flexDirection="column">
        <StepTitle>Analiza y descarga tus datos</StepTitle>
        <StepDescription>
          A medida que se vayan sincronizando datos de la campaña, se irá enriqueciendo la información y podrás ver estadísticas
          y descargar los datos de manera ordenada para ser utilizados en tus informes.
        </StepDescription>
        <Box display="flex" justifyContent="center">
          <Box mx={{ xs: 1, md: 3 }} mb={{ xs: 2, md: 0 }} display="flex" alignItems="center" flexDirection="column">
            <Button className={classes.stepThreeButton} onClick={setOne}>
              {/* TODO: do something to signal which button is the one currently showing its view? */}
              <Assessment className={classes.icon} fontSize="inherit"/>
            </Button>
            <Typography>
              Estadísticas
            </Typography>
          </Box>
          <Box mx={{ xs: 1, md: 3 }} display="flex" alignItems="center" flexDirection="column">
            <Button className={classes.stepThreeButton} onClick={setTwo}>
              <CloudDownload className={classes.icon} fontSize="inherit"/>
            </Button>
            <Typography>
              Descargables
            </Typography>
          </Box>
          <Box mx={{ xs: 1, md: 3 }} display="flex" alignItems="center" flexDirection="column">
            <Button className={classes.stepThreeButton} onClick={ setThree }>
              <ListAlt className={classes.icon} fontSize="inherit"/>
            </Button>
            <Typography>
              Y más
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box pl={{ xs: 0, md: 10 }} >
        <Box className={classes.stepThreeDisplayContainer}>
          {isDownSm ?
            analyseAndDownloadComponentsTinny[activeButtonNumber - 1] :
            <BrowserUI size="small">
              <HeaderUI size="small" user="Juan Von Hildebrand" />
              {analyseAndDownloadComponents[activeButtonNumber - 1]}
            </BrowserUI>
          }
        </Box>
      </Box>
    </Box>
  );
};

AnalyseAndDownloadSection.propTypes = {
  activeButtonNumber: PropTypes.number,
  setActiveButtonNumber: PropTypes.func,
};


export { AnalyseAndDownloadSection };