import React from 'react';
import { Box, Paper } from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';
import PropTypes from 'prop-types';


const opacity = {
  white: 0.4,
  black: 0.9,
};

const padding = {
  white: 1,
  black: 0.8,
};

const useStyles = makeStyles(theme => ({
  mainContainer: {
    backgroundColor: ({ color }) => alpha(theme.palette.common[color], opacity[color]),
    boxShadow: ({ shadow }) => shadow ?
      'inset 0 1px 1px 0 hsl(0deg 0% 100% / 10%), 0 50px 100px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%)' :
      '0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08)',
    color: theme.palette.common.white,
    padding: ({ color }) => theme.spacing(padding[color]),
    minWidth: theme.spacing(30),
    height: theme.spacing(62),
    borderRadius: '10px',
  },
  content: {
    backgroundColor: '#f5f8fb',
    borderRadius: '10px',
    height: 'inherit',
  },
}));

const MobileUI = ({ color = 'white', children, shadow = true }) => {
  const classes = useStyles({ color, shadow });
  return (
    <Box display="flex">
      <Box className={classes.mainContainer}>
        <Paper elevation={0} className={classes.content}>
          {children}
        </Paper>
      </Box>
    </Box>
  );
};

MobileUI.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
  shadow: PropTypes.bool,
};


export {
  MobileUI,
};