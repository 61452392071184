import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography } from '@material-ui/core';

import { PopupObservation } from 'src/scenes/Campaign/scenes/Monitoring/components/PopupObservation';


const IndirectRecordPopup = ({ indirectRecord, options }) => {
  const { typeId, email, observation, scientificName, otherType } = indirectRecord;
  const { indirectRecordsTypesOptions } = options;
  const indirectRecordTypeLabel = indirectRecordsTypesOptions?.find(({ value }) => value === typeId)?.label;
  const finalType = indirectRecordTypeLabel === 'Otros' ? otherType : indirectRecordTypeLabel;

  return <>
    <Card elevation={0}>
      <CardContent style={{ padding: '5px' }}>
        <Typography variant="h5">
          {scientificName ?? <em>Especie no reconocida</em>}
        </Typography>
        <Typography variant="body2" style={{ margin: '1px 0', fontSize: '14px' }} color="textPrimary">
          {email}
        </Typography>
        <Typography style={{ marginTop: '15px', marginBottom: '-10px' }} variant="overline" component="p">
          Tipo: {finalType}
        </Typography>
        <PopupObservation observation={observation}/>
      </CardContent>
    </Card>
  </>;
};

IndirectRecordPopup.propTypes = {
  indirectRecord: PropTypes.object,
  options: PropTypes.object,
};


export {
  IndirectRecordPopup,
};
