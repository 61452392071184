export const floraCoverageEnum = {
  'p': 'fuera de la unidad de muestreo',
  'r': 'cobertura despreciable',
  '+': 'cobertura muy baja',
  '1': '1 - 5%',
  '2': '5 - 10%',
  '3': '10 - 25%',
  '4': '25 - 50%',
  '5': '50 - 75%',
  '6': '75 - 90%',
  '7': '90 - 100%',
};