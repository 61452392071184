import React from 'react';
import { InputLabel, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import { SelectChip } from 'src/components';


const AgeStratumAndSexInputs = props => {

  const { record, handlers, options: { sexTypesOptions, ageStratumsOptions } } = props;
  const { handleSelect } = handlers;

  return (
    <>
      <Box my={2}>
        <InputLabel shrink required>Estrato etario</InputLabel>
        <SelectChip
          value={record.ageStratumId}
          options={ageStratumsOptions}
          onChange={option => handleSelect({ ageStratumId: option.value })}
        />
      </Box>
      <Box my={2}>
        <InputLabel shrink required>Sexo</InputLabel>
        <SelectChip
          value={record.sexId}
          options={sexTypesOptions}
          onChange={option => handleSelect({ sexId: option.value })}
        />
      </Box>
    </>
  );
};

AgeStratumAndSexInputs.propTypes = {
  record: PropTypes.object.isRequired,
  handlers: PropTypes.object,
  options: PropTypes.object,
};


export { AgeStratumAndSexInputs };