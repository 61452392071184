import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Box, TextField, FormHelperText } from '@material-ui/core';


const UserLinkValidation = props => {

  const { handleChange, errorText } = props;
  return (
    <Fragment>
      <Box my={3}>
        <TextField name="email" label="Correo electronico" type="text" onChange={handleChange} autoFocus required autoComplete="off"
          fullWidth variant="outlined" error={ Boolean(errorText) }/>
        { errorText && <FormHelperText error>{ errorText }</FormHelperText> }
      </Box>
    </Fragment>
  );
};

UserLinkValidation.propTypes = {
  handleChange: PropTypes.func,
  errorText: PropTypes.string,
};


export {
  UserLinkValidation,
};