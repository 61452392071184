import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { PersonAdd } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router';

import { WebContext } from 'src/scenes/web-context';
import { campaignsApi, usersApi } from 'src/services';
import { TitleContainer, ContentContainer, ActionsBox, DialogWrap } from 'src/components';
import { UsersList, UserUnlinkDialog, UsersLinkDialog } from 'src/scenes/Campaign/scenes/Users/components';
import { upperCaseFirstLetter } from 'src/utils/formatters';


const useStyles = makeStyles(theme => ({
  addButton: {
    color: theme.palette.common.white,
  },
  root: {
    ...theme.typography.body2,
  },
}));

const getAvailableCompanyUsers = (campaignUsers, companyUsersOptions) =>
  companyUsersOptions.filter(cu => !campaignUsers.find(u => u.id === cu.value));

const UsersContainer = () => {
  const match = useRouteMatch();
  const context = useContext(WebContext);
  const classes = useStyles();

  const { campaignHash } = match.params;
  const { campaignSubtypeLanguage: cLang, selectedProject: { userId: projectCreatorId } } = context;

  const [ state, setState ] = useState({
    campaignUsersList: [],
    companyUsersOptions: [],
    availableCompanyUsersOptions: [],
    rolesOptions: [],
    companyUsersList: [],
    dialog: {
      isOpen: false,
      data: {},
      type: '',
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      const [ campaignUsersList, rolesList, companyUsersList ] = await Promise.all([
        campaignsApi.getUsers(campaignHash),
        usersApi.getCampaignRoles(),
        usersApi.getUsersByCompany(),
      ]);
      const rolesOptions = rolesList.map(({ id, value }) => ({ value: id, label: upperCaseFirstLetter(value) }));
      const companyUsersOptions = companyUsersList.map(user => ({ value: user.id, label: user.email }));
      const availableCompanyUsersOptions = getAvailableCompanyUsers(campaignUsersList, companyUsersOptions);
      setState({ ...state, campaignUsersList, rolesOptions, companyUsersOptions, availableCompanyUsersOptions });
    };
    fetchData();
  // eslint-disable-next-line
  }, [ campaignHash ]);

  const linkUsers = async newUsers => {
    const linkedUsers = await campaignsApi.linkUsers(campaignHash, newUsers);
    const campaignUsersList = [ ...linkedUsers, ...state.campaignUsersList ];
    setState({
      ...state,
      campaignUsersList,
      availableCompanyUsersOptions: getAvailableCompanyUsers(campaignUsersList, state.companyUsersOptions),
      dialog: { isOpen: false },
    });
    const oldWorkingGroupCounter = context.selectedCampaign.workingGroupCounter;
    context.setSelectedCampaign({ ...context.selectedCampaign, workingGroupCounter: oldWorkingGroupCounter + newUsers.usersIds.length });
  };

  const unlinkUser = async userId => {
    await campaignsApi.unlinkUser(campaignHash, userId);
    const campaignUsersList = state.campaignUsersList.filter(({ id }) => id !== userId);
    setState({
      ...state,
      campaignUsersList,
      availableCompanyUsersOptions: getAvailableCompanyUsers(campaignUsersList, state.companyUsersOptions),
      dialog: { isOpen: false },
    });
    const oldWorkingGroupCounter = context.selectedCampaign.workingGroupCounter;
    context.setSelectedCampaign({ ...context.selectedCampaign, workingGroupCounter: oldWorkingGroupCounter - 1 });
  };

  const toggleDialog = ({ data = {}, type = '' }) => setState(ps => ({ ...ps, dialog: { isOpen: !ps.dialog.isOpen, data, type } }));

  const closeDialog = () => setState(ps => ({ ...ps, dialog: { isOpen: false, type: '', data: {} } }));

  const getDialogType = () => {
    const { dialog, rolesOptions, availableCompanyUsersOptions } = state;
    switch (dialog.type) {
      case 'delete':
        return <UserUnlinkDialog
          cLang={cLang}
          actions={{ closeDialog, unlinkUser: () => unlinkUser(dialog.data) }}
        />;
      case 'create':
        return <UsersLinkDialog
          options={{ rolesOptions, availableCompanyUsersOptions }}
          actions={{ closeDialog, linkUsers }}
        />;
      default:
        break;
    }
  };

  const { dialog, campaignUsersList, rolesOptions } = state;

  return (
    <Grid container>
      <TitleContainer maxWidth="xl" breadcrumbs={[ 'company', 'campaigns', { name: 'Grupo de trabajo' } ]}>
        Grupo de Trabajo
      </TitleContainer>
      <ContentContainer maxWidth="xl">
        <ActionsBox>
          { !context.isFan() &&
            <Button size="small" color="primary" className={classes.addButton} startIcon={<PersonAdd />}
              variant="contained" onClick={() => toggleDialog({ type: 'create' })}>
              Vincular persona
            </Button>
          }
        </ActionsBox>
        {campaignUsersList.length ?
          <UsersList usersList={campaignUsersList} projectCreatorId={projectCreatorId} currentUser={context.currentUser}
            options={{ rolesOptions }} actions={{ toggleDialog }} hasOwnWorkFinished={context.selectedCampaign?.hasOwnWorkFinished} /> :
          <Typography variant="body1" color="textPrimary">No se han añadido personas a la campaña.</Typography>
        }
      </ContentContainer>
      <DialogWrap maxWidth="xs" fullWidth onClose={closeDialog} aria-labelledby='form-dialog-title'
        open={ dialog.isOpen }>
        { getDialogType() }
      </DialogWrap>
    </Grid>
  );
};

UsersContainer.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};


export {
  UsersContainer,
};