import React from 'react';
import L from 'leaflet';
import ReactDOM from 'react-dom';

import { getMarkerIcon } from 'src/components/map/mapHelper';
import { campaignSubtypeLanguage } from 'src/utils/componentLanguage';
import { PointPopup } from 'src/scenes/Campaign/scenes/Monitoring/components';
import { ThemeProvider } from '@material-ui/core';
import themes from 'src/themes';


const render = (Component, element) => {
  ReactDOM.render(
    <ThemeProvider theme={themes['nviro']}>
      <Component />
    </ThemeProvider>,
    element,
  );
};

const mapOptions = {
  center: [ -32.48431324745671, -71.25296320202341 ],
  zoomControl: false,
  dragging: false,
  scrollWheelZoom: false,
  zoom: 16,
  doubleClickZoom: false,
  attributionControl: false,
};

const interestAreaPolygons = [
  {
    'id': '165',
    'name': 'Zona A',
    'geojson': [
      {
        'type': 'Feature',
        'geometry': {
          'type': 'Polygon',
          'coordinates': [
            [
              [
                -71.25176320202341,
                -32.48651324745671,
                0,
              ],
              [
                -71.2516649403473,
                -32.48256943224506,
                0,
              ],
              [
                -71.25339723966573,
                -32.48181727993751,
                0,
              ],
              [
                -71.2543880862421,
                -32.48169888577371,
                0,
              ],
              [
                -71.25498025521912,
                -32.48646120676445,
                0,
              ],
              [
                -71.25495424435044,
                -32.48646162793209,
                0,
              ],
              [
                -71.25495617859191,
                -32.48647838052944,
                0,
              ],
              [
                -71.25495810245584,
                -32.48648708462187,
                0,
              ],
              [
                -71.25496167663627,
                -32.48649540652226,
                0,
              ],
              [
                -71.254966804069,
                -32.48650312341866,
                0,
              ],
              [
                -71.25497334867634,
                -32.48651002672563,
                0,
              ],
              [
                -71.25498113457351,
                -32.48651593292024,
                0,
              ],
              [
                -71.25498995244976,
                -32.48652068342785,
                0,
              ],
              [
                -71.25499956717074,
                -32.48652415079832,
                0,
              ],
              [
                -71.25500972105881,
                -32.48652624225468,
                0,
              ],
              [
                -71.25502014031959,
                -32.48652690138146,
                0,
              ],
              [
                -71.25503054787161,
                -32.48652611060024,
                0,
              ],
              [
                -71.25819119283946,
                -32.48606255545709,
                0,
              ],
              [
                -71.25819839425169,
                -32.48610267501272,
                0,
              ],
              [
                -71.25503329644752,
                -32.48656688284468,
                0,
              ],
              [
                -71.2550158636269,
                -32.48656820086825,
                0,
              ],
              [
                -71.25499841141587,
                -32.48656707892809,
                0,
              ],
              [
                -71.25498141296943,
                -32.48656354641879,
                0,
              ],
              [
                -71.25496532819496,
                -32.48655769880497,
                0,
              ],
              [
                -71.25495059200823,
                -32.48654969602837,
                0,
              ],
              [
                -71.2549376045141,
                -32.4865397527637,
                0,
              ],
              [
                -71.25492671507581,
                -32.48652813960592,
                0,
              ],
              [
                -71.25491822091409,
                -32.48651516956836,
                0,
              ],
              [
                -71.2549123500232,
                -32.48650119568303,
                0,
              ],
              [
                -71.25490926180719,
                -32.48648659385488,
                0,
              ],
              [
                -71.25490654179592,
                -32.48646240004432,
                0,
              ],
              [
                -71.25176320202341,
                -32.48651324745671,
                0,
              ],
            ],
          ],
        },
        'properties': {
          'styleUrl': '#falseColor',
          'styleHash': '-6cc8e1c',
          'OBJECTID': '1',
          'Shape_Leng': '2182.51',
          'Shape_Area': '140711',
        },
      },
      {
        'type': 'Feature',
        'geometry': {
          'type': 'LineString',
          'coordinates': [
            [
              -71.25497367363214,
              -32.48647137850673,
              0,
            ],
            [
              -71.25820206079018,
              -32.48632663468526,
              0,
            ],
            [
              -71.25787891927807,
              -32.48173334604681,
              0,
            ],
            [
              -71.2581625692761,
              -32.4817044500048,
              0,
            ],
          ],
        },
        'properties': {
          'name': 'Línea de evacuación proyectada',
          'styleUrl': '#m_ylw-pushpin1',
          'styleHash': '-4a3628b2',
          'styleMapHash': {
            'normal': '#s_ylw-pushpin2',
            'highlight': '#s_ylw-pushpin_hl2',
          },
          'stroke': '#0000ff',
          'stroke-opacity': 1,
          'stroke-width': 5,
        },
      },
    ],
  },
];

const interestAreaLayers = interestAreaPolygons.map(layer => L.geoJSON(layer.geojson, {
  id: layer.id,
  name: layer.name,
}));

const individualRecords = [
  {
    'latitude': -32.4845882,
    'longitude': -71.2532542,
    'accuracy': 4.9,
    'email': 'bastian.gomez@nviro.cl',
    'byDarwinion': true,
    'species': {
      'genus': 'peumus',
      'family': 'monimiaceae',
      'species': 'boldus',
      'variety': '',
      'subspecies': '',
    },
  },
  {
    'observation': '',
    'latitude': -32.4839217,
    'longitude': -71.2512575,
    'accuracy': 5,
    'email': 'bastian.gomez@nviro.cl',
    'byDarwinion': true,
    'species': {
      'genus': 'cestrum',
      'family': 'solanaceae',
      'species': 'parqui',
      'variety': '',
      'subspecies': '',
    },
  },
];

const irMarkers = individualRecords.map(ir => {
  const coords = { lat: ir.latitude, lng: ir.longitude };
  const marker = new L.Marker(coords, { icon: getMarkerIcon('RI', 'nviro-flora') });
  return marker;
});

const plots = [
  {
    'name': '41',
    'statusId': 'todo',
    'observation': '',
    'dateVisited': null,
    'latitude': null,
    'longitude': null,
    'accuracy': null,
    'exposureId': null,
    'plannedLatitude': -32.48522769534328,
    'plannedLongitude': -71.2523138523102,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:11.543Z',
    'createdOnTerrain': false,
    'email': 'bastian.gomez@nviro.cl',
    'firstName': 'Bastian',
    'lastName': 'Gomez',
  },
  {
    'name': '52',
    'statusId': 'todo',
    'observation': '',
    'dateVisited': null,
    'latitude': null,
    'longitude': null,
    'accuracy': null,
    'exposureId': null,
    'plannedLatitude': -32.48554427409514,
    'plannedLongitude': -71.25232458114625,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:24.149Z',
    'createdOnTerrain': false,
    'email': 'bastian.gomez@nviro.cl',
    'firstName': 'Bastian',
    'lastName': 'Gomez',
  },
  {
    'name': '54',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T14:42:37.499Z',
    'latitude': -32.4851731,
    'longitude': -71.2545818,
    'accuracy': 3.9,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.485164379459256,
    'plannedLongitude': -71.25458836555482,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:26.519Z',
    'createdOnTerrain': false,
    'email': 'bastian.gomez@nviro.cl',
    'firstName': 'Bastian',
    'lastName': 'Gomez',
  },
  {
    'name': '55',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T14:47:53.185Z',
    'latitude': -32.4852564,
    'longitude': -71.2536303,
    'accuracy': 3.2,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.48527292094743,
    'plannedLongitude': -71.25359058380128,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:27.031Z',
    'createdOnTerrain': false,
    'email': 'bastian.gomez@nviro.cl',
    'firstName': 'Bastian',
    'lastName': 'Gomez',
  },
];

const pointStatusMapper = {
  todo: { color: 'red', hex: '#f44336', text: 'Pendiente' },
  synced: { color: 'nviro', hex: '#5193eb', text: 'Sincronizada' },
};

const plotsMarkers = plots.map(plot => {
  const coords = plot.plannedLatitude && plot.plannedLongitude ?
    [ plot.plannedLatitude, plot.plannedLongitude ] : [ plot.latitude, plot.longitude ];
  const marker = new L.Marker(coords, { icon: getMarkerIcon(plot.name, pointStatusMapper[plot.statusId].color) });
  if (plot.name === '70') {
    const div = document.createElement('div');
    render(() => <PointPopup point={plot} pointStatusMapper={pointStatusMapper} csLang={campaignSubtypeLanguage('plots')} />, div);
    marker.bindPopup(div);
    marker.openPopup();
  }

  return marker;
});

const layers = [ ...interestAreaLayers, ...plotsMarkers, ...irMarkers ];


export { mapOptions, layers };