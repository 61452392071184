import React from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { NavigateNext } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    height: '100%',
  },
  paper: {
    padding: theme.spacing(4),
    height: 'fill-available',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '380px',
    [theme.breakpoints.down('sm')]: {
      width: 'unset',
      padding: theme.spacing(2),
      height: 'unset',
    },
  },
  title: {
    fontSize: '1.875rem',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  description: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.common.gray700,
  },
  link: {
    cursor: 'pointer',
    fontSize: theme.typography.h6.fontSize,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    lineHeight: 'normal',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  linkIcon: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.h5.fontSize,
    fontWeight: 600,
  },
}));

const CategoryCard = ({ title, description, onClick, Icon }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper} elevation={5}>
      <Box display="flex" flexDirection="column">
        <Icon />
        <Typography className={classes.title}>{title}</Typography>
        <Typography color="textPrimary" className={classes.description}>{description}</Typography>
        <Box display="flex">
          <Box flexGrow={1} />
          <Box className={classes.link} onClick={onClick} display="flex" alignItems="center">
            <Typography color="primary" className={classes.link}>Ir</Typography>
            <NavigateNext className={classes.linkIcon}></NavigateNext>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

CategoryCard.propTypes = {
  Icon: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
};


export { CategoryCard };