import { get, patch, post, del } from 'src/services/api';


const getMapMethodologies = async queryParams => (await get('/fauna/map-methodologies', queryParams)).data;

const getMethodologiesList = async queryParams => (await get('/fauna/methodologies-list', queryParams)).data;

const updateMethodology = async ({ updatedData, methodologyId }) =>
  (await patch(`/fauna/methodologies/${methodologyId}`, updatedData)).data;

const deleteMethodology = async ({ toDelete, methodologyId }) => (await del(`/fauna/methodologies/${methodologyId}`, { toDelete })).data;

const getSexTypes = async () => (await get('/fauna/sex-types')).data;

const getAgeStratums = async () => (await get('/fauna/age-stratums')).data;

const getIndirectRecordTypes = async () => (await get('/fauna/indirect-record-types')).data;

const getMethodologiesTypes = async () => (await get('/fauna/methodologies-types')).data;

const getRecordsForMonitoringMap = async queryParams => (await get('/fauna/records-for-map', queryParams)).data;

const getFlightTypes = async () => (await get('/fauna/flight-types')).data;

const updateRecord = async ({ updatedData, recordId }) => (await patch(`/fauna/record/${recordId}`, updatedData)).data;

const updateIndividual = async updatedIndividual => (await patch(`/fauna/individual/${updatedIndividual.id}`, updatedIndividual)).data;

const createIndividual = async individual =>
  (await post(`/fauna/individual`, individual, { headers: { 'Content-Type': 'multipart/form-data' } })).data;

const deleteIndividual = async individual => (await patch(`/fauna/individual/${individual.id}`, { toDelete: true })).data;

const getCatalogs = async () => (await get('/fauna/catalogs')).data;

const search = async queryParams => (await get('/fauna/methodologies/search', queryParams)).data;

const create = async record => (await post(`/fauna/record`, record, { headers: { 'Content-Type': 'multipart/form-data' } })).data;


export {
  getMapMethodologies,
  getMethodologiesList,
  updateMethodology,
  deleteMethodology,
  getSexTypes,
  getAgeStratums,
  getIndirectRecordTypes,
  getMethodologiesTypes,
  getRecordsForMonitoringMap,
  updateRecord,
  createIndividual,
  updateIndividual,
  deleteIndividual,
  getCatalogs,
  search,
  create,
  getFlightTypes,
};
