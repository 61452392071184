import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { usersApi, campaignsApi, pointsApi, faunaApi } from 'src/services';
import { WebContext } from 'src/scenes/web-context';
import { PlanningMap, PlanningSidePanel, ImportPointsDialog, ImportAreaDialog, DeletePointsDialog,
  AssignPointsDialog, HelpDialog, BadImportDialog, PlanningFinishDialog, SuggestMethodologiesDialog } from 'src/scenes/Planning/components';
import { ContentContainer, NviroBreadcrumbs, DialogWrap } from 'src/components';
import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';
import { createForm, markerColors } from 'src/utils/util';
import { isCensus } from 'src/utils/checkers';
import { campaignSubtypeLanguage } from 'src/utils/componentLanguage';


const getAvailablePointInputs = ({ csLang, userIsFan }) => ([
  {
    id: 'pointName',
    label: `Nombre de ${csLang.point}`,
    helpText: 'Se permite cualquier valor, pero se recomienda utilizar nombres de hasta 8 caracteres para una mejor visualización',
  },
  ...(
    !userIsFan ? [ {
      id: 'userEmail',
      label: 'Correo de responsable',
      helpText: 'El correo debe coincidir con los de alguna persona registrada en la empresa, en caso contrario se ignoraran',
    } ] : []
  ),
]);

const useStyles = theme => ({
  content: {
    paddingTop: theme.spacing(1),
    maxWidth: '100%',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
});

class PlanningCampaignContainer extends Component {

  constructor(props) {
    super(props);
    this.userColorMap = { unassigned: { name: 'Sin Asignar', color: '#5e5e5e' } };
    this.csLang = campaignSubtypeLanguage(),

    this.state = {
      campaign: { layers: [] },
      selectedPoints: [],
      pointsList: [],
      campaignHash: this.props.match.params.campaignHash,
      companyUsersOptions: [],
      isPointsListFetched: false,
      dialog: {
        isOpen: false,
        data: {},
        contentType: '',
      },
      fetchStatus: fetchStatusEnum.NOT_STARTED,
    };
  }

  componentDidMount = async () => {
    this.context.changeCurrentPath(this.props.match.path);

    const campaignHash = this.state.campaignHash;
    const campaign = await campaignsApi.getCampaignPlanningByHash(campaignHash);
    if (isCensus(campaign.mainMethodId)) {
      this.props.history.replace(`/web/projects/${campaign.projectId}/campaigns`);
      return;
    }

    this.csLang = campaignSubtypeLanguage(campaign.mainMethodId);
    const [ companyUsers, points, methodologyTypesOptions ] = await Promise.all([
      usersApi.getUsersByCompany(),
      pointsApi.getPoints({ campaignHash }),
      faunaApi.getMethodologiesTypes(),
    ]);

    this.context.setSelectedProject({ id: campaign.projectId, name: campaign.projectName, userId: campaign.projectOwnerId });
    this.context.setSelectedCampaign(campaign);

    const { name: campaignName, projectName } = this.context.selectedCampaign;
    document.title = `${campaignName} - ${projectName} - Nviro Capture`;

    companyUsers.forEach((user, idx) =>
      this.userColorMap[user.id] = {
        color: markerColors[idx % markerColors.length],
        name: `${user.firstName} ${user.lastName}`,
        points: [],
      });
    const pointsList = this.getPointsWithColor(points);

    const companyUsersOptions = companyUsers.map(user => ({ value: user.id, label: user.email }));

    this.setState({
      campaign,
      companyUsersOptions,
      pointsList,
      isPointsListFetched: true,
      methodologyTypesOptions,
    });
  };

  toggleDialog = ({ data = {}, contentType = '' }) => this.setState(ps => ({ dialog: { isOpen: !ps.dialog.isOpen, data, contentType } }));

  closeDialog = () => this.setState({ dialog: { isOpen: false, data: {}, contentType: '' } });

  _getPreselectedMethodologySuggestions = typeOptions => {
    const labelsDict = typeOptions.reduce((acc, curr) => {
      acc[curr.value] = curr.label;
      return acc;
    }, {});

    const { selectedPoints: pixiSelectedPoints } = this.state;
    const selectedMethodologiesSet = new Set();
    let preSelectedSuggestions = [];
    const selectedPoints = pixiSelectedPoints.map(pixPt => pixPt.metaData);

    // los tipos de metodologías van a estar prerrellenados solo si todas las estaciones seleccionadas tienen
    // las mismas sugerencias. Empezemos viendo los datos de la primera, y luego si alguna de las otras estaciones
    // tiene datos que difieren, entonces no prerrelenamos nada.
    if (selectedPoints[0]?.suggestedMethodologies?.length) {
      preSelectedSuggestions = selectedPoints[0].suggestedMethodologies.map(sm => ({
        value: sm.methodEnumId, label: labelsDict[sm.methodEnumId],
      }));

      preSelectedSuggestions.forEach(sm => {
        selectedMethodologiesSet.add(sm.value);
      });

      for (let i = 1; i < selectedPoints.length; i++) {
        const currentPoint = selectedPoints[i];
        const currentSuggestions = currentPoint.suggestedMethodologies ?? [];

        if (currentSuggestions.length !== preSelectedSuggestions.length) {
          return [];
        }
        for (let mInd = 0; mInd < currentSuggestions.length; mInd ++) {
          const currentSuggestion = currentSuggestions[mInd];
          if (!selectedMethodologiesSet.has(currentSuggestion.methodEnumId)) {
            return [];
          }
        }
      }
    }
    // si llega hasta acá, todas las estaciones tienen las mismas metodologías o la primera estación no tenía sugerencias
    return preSelectedSuggestions;
  };

  getDialogType = () => {
    const { dialog, pointsList, companyUsersOptions, methodologyTypesOptions } = this.state;
    const { closeDialog, updatePoints, deletePoints, startCampaign } = this;
    const userIsFan = this.context.isFan();

    switch (dialog.contentType) {
      case 'points-import': {
        const { kmlFile, fileName, clearMap, doFitBounds, importPointsCount, propertiesKeys } = dialog.data;
        return <ImportPointsDialog
          currentPointsCount={pointsList.length}
          importPointsCount={importPointsCount}
          propertiesKeys={propertiesKeys}
          availablePointInputs={getAvailablePointInputs({ csLang: this.csLang, userIsFan })}
          actions={{
            importPoints: propertiesMapper =>
              this.createPointsFromKml({ kmlFile, fileName, propertiesMapper, clearMap, doFitBounds }),
            closeDialog,
          }}
          csLang={this.csLang}
        />;
      }
      case 'area-import': {
        const { kmlFile, fileName, dataByGeometry, doFitBounds } = dialog.data;
        return <ImportAreaDialog
          data={dataByGeometry}
          actions={{
            importInterestArea: options => this.importInterestArea({ kmlFile, fileName, doFitBounds, options }),
            closeDialog,
          }}
        />;
      }
      case 'bad-import': {
        return <BadImportDialog message={ dialog.data?.message } actions={{ closeDialog }}/>;
      }
      case 'assign-points': {
        return <AssignPointsDialog
          options={{ companyUsersOptions }}
          actions={{ updatePoints, closeDialog }}
          csLang={ this.csLang }
          canAddUserToCompany={ this.context.checkIfAdminPowers() }
          userIsFan={userIsFan}
        />;
      }
      case 'suggest-methodologies': {
        const preSelectedMethodologies = this._getPreselectedMethodologySuggestions(methodologyTypesOptions);

        return <SuggestMethodologiesDialog
          options={methodologyTypesOptions}
          preSelectedMethodologies={preSelectedMethodologies}
          actions={{ updatePoints, closeDialog }}
        />;
      }
      case 'delete-points': {
        return <DeletePointsDialog
          actions={{ deletePoints, closeDialog }}
          cLang={this.csLang}
        />;
      }
      case 'help': {
        return <HelpDialog
          actions={{ closeDialog }}
          cLang={this.csLang}
          userIsFan={userIsFan}
        />;
      }
      case 'finish-planning': {
        return <PlanningFinishDialog
          actions={{ closeDialog, startCampaign }}
          fetchStatus={this.state.fetchStatus}
          cLang={this.csLang}
          userIsFan={userIsFan}
        />;
      }
      default:
        break;
    }
  };

  groupByObjKey = (key, array) =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key] || 'unassigned';
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});

  getPointsWithColor = points => {
    const pointsByUser = this.groupByObjKey('userId', points);
    Object.keys(this.userColorMap).forEach(key => this.userColorMap[key].points = pointsByUser[key] ? pointsByUser[key] : []);
    const pointsList = points.map(point => ({ ...point, color: point.userId ? this.userColorMap[point.userId].color : '#5e5e5e' }));
    return pointsList;
  };

  addPointsToSelectedPoints = pointsToAdd =>
    this.setState(prevState => ({ selectedPoints: [ ...prevState.selectedPoints, ...pointsToAdd ] }));

  deletePointsFromSelectedPoints = pointsToRemove => {
    const pointsNames = pointsToRemove.map(p => p.metaData.name);
    this.setState(prevState =>
      ({ selectedPoints: prevState.selectedPoints.filter(point => !pointsNames.includes(point.metaData.name)) }),
    );
  };

  deselectAllPoints = () => this.setState({ selectedPoints: [] });

  createPoint = async pointData => {
    const { campaignHash } = this.state;
    const createdPoint = await pointsApi.createPoint({ campaignHash, ...pointData });
    this.setState(ps => ({
      pointsList: this.getPointsWithColor([ createdPoint, ...ps.pointsList ]),
      dialog: { isOpen: false },
    }));
    return createdPoint;
  };

  updatePoints = async pointData => {
    const { campaignHash, selectedPoints } = this.state;

    const idsToUpdate = selectedPoints.map(({ metaData: { id } }) => id);
    const updatedPointsDict = {};
    const pointsData = { campaignHash, idsToUpdate, ...pointData };
    const respPointData = await pointsApi.updatePointsPlanning(pointsData);

    respPointData.forEach(pnt => updatedPointsDict[pnt.id] = pnt);
    this.setState(ps => ({
      pointsList: this.getPointsWithColor(
        ps.pointsList.map(point =>
          updatedPointsDict[point.id] ? { ...point, ...pointData, ...updatedPointsDict[point.id] } : point,
        ),
      ),
    }));
    this.deselectAllPoints();
  };

  deletePoints = async () => {
    const { selectedPoints } = this.state;
    const selectedPointsIds = selectedPoints.map(({ metaData: { id } }) => id);
    await pointsApi.deletePoints(selectedPointsIds, this.state.campaignHash);
    this.setState(prevState => ({
      pointsList: this.getPointsWithColor(prevState.pointsList.filter(point => !selectedPointsIds.includes(point.id))),
      dialog: { isOpen: false },
    }));
    this.deselectAllPoints();
  };

  startCampaign = async () => {
    this.setState({ fetchStatus: fetchStatusEnum.LOADING });
    try {
      await campaignsApi.startCampaign(this.state.campaignHash);
      setTimeout(() => this.setState({ fetchStatus: fetchStatusEnum.SUCCESS }), 500);
      setTimeout(() => this.props.history.replace(`/web/campaigns/${this.state.campaignHash}`), 4500);
    } catch (e) {
      this.setState({ fetchStatus: fetchStatusEnum.ERROR });
    }
  };

  handleBadImport = message => {
    this.setState({ dialog: { isOpen: true, contentType: 'bad-import', data: { message } } });
  };

  createPointsFromKml = async ({ kmlFile, propertiesMapper, fileName, clearMap, doFitBounds }) => {
    const { campaignHash } = this.state;

    const kmlForm = createForm({ ...propertiesMapper, campaignHash });
    kmlForm.append('kmlFile', kmlFile, fileName);
    await pointsApi.createPointsFromKml(kmlForm, campaignHash);
    clearMap();
    this.deselectAllPoints();
    const points = await pointsApi.getPoints({ campaignHash });
    const pointsList = this.getPointsWithColor(points);

    // TODO eliminar este palito de fosforo, ayuda a que se rendericen los nuevos puntos al cargar un kml
    this.setState({
      pointsList: [],
    });

    this.setState({
      pointsList,
    });
    doFitBounds();
  };

  importInterestArea = async ({ kmlFile, fileName, doFitBounds, options }) => {
    const { campaignHash } = this.state;
    const kmlForm = new FormData();
    kmlForm.append('campaignHash', campaignHash);
    kmlForm.append('showMarkers', options.showMarkers);
    kmlForm.append('showPolygons', options.showPolygons);
    kmlForm.append('showLines', options.showLines);
    kmlForm.append('replaceAll', options.replaceAll);
    kmlForm.append('kmlFile', kmlFile, fileName);
    await campaignsApi.importInterestArea(kmlForm, campaignHash);

    this.setState({
      campaign: { layers: [] },
    }),

    this.setState({
      campaign: await campaignsApi.getCampaignPlanningByHash(campaignHash),
    });

    doFitBounds();
  };

  deleteKml = async kmlId => {
    await campaignsApi.deleteKml(kmlId);
    const campaign = await campaignsApi.getCampaignPlanningByHash(this.state.campaignHash);
    this.setState({ campaign });
  };

  onDialogCloseHandler = isFinishPlanningDialog => {
    const { fetchStatus } = this.state;
    return isFinishPlanningDialog && (fetchStatus === fetchStatusEnum.SUCCESS || fetchStatus === fetchStatusEnum.LOADING) ?
      undefined : this.closeDialog;
  };

  render() {
    const { selectedPoints, pointsList, campaign, isPointsListFetched, dialog } = this.state;
    const { classes } = this.props;
    const isSomePointNotAssigned = this.userColorMap['unassigned'].points === undefined ||
      this.userColorMap['unassigned'].points.length > 0;

    const isFinishPlanningDialog = dialog.contentType === 'finish-planning';
    const isPointsImportDialog = dialog.contentType === 'points-import';

    return (
      <Grid container>
        <ContentContainer className={classes.content}>
          <NviroBreadcrumbs breadcrumbs={[ 'company', 'campaigns', { name: 'Planificación' } ]} />
          {
            <PlanningMap
              isDialogOpen={dialog.isOpen}
              pointsList={pointsList}
              isPointsListFetched={isPointsListFetched}
              selectedPoints={selectedPoints}
              userColorMap={this.userColorMap}
              interestArea={campaign.layers}
              actions={{
                addPointsToSelectedPoints: this.addPointsToSelectedPoints,
                deletePointsFromSelectedPoints: this.deletePointsFromSelectedPoints,
                createPoint: this.createPoint,
                deleteKml: this.deleteKml,
                toggleDialog: this.toggleDialog,
                handleBadImport: this.handleBadImport,
              }}
              csLang={this.csLang}
            />
          }
          { campaign.mainMethodId && <>
            <PlanningSidePanel
              csLang={this.csLang}
              componentId={campaign.componentId}
              isSomePointNotAssigned={isSomePointNotAssigned}
              selectedPoints={selectedPoints}
              actions={{
                openFinishPlanningDialog: () => this.toggleDialog({ contentType: 'finish-planning' }),
                openAssignPointsDialog: () => this.toggleDialog({ contentType: 'assign-points', data: { size: 'sm' } }),
                openDeletePointsDialog: () => this.toggleDialog({ contentType: 'delete-points' }),
                openSuggestMethodologiesDialog: () => this.toggleDialog({ contentType: 'suggest-methodologies' }),
              }}
              userIsFan={this.context.isFan()}
            />
          </>}
        </ContentContainer>
        <DialogWrap maxWidth={(isFinishPlanningDialog || isPointsImportDialog) ? 'sm' : 'xs'} fullWidth
          onClose={this.onDialogCloseHandler(isFinishPlanningDialog)} aria-labelledby="form-dialog-title"
          open={dialog.isOpen}>
          {this.getDialogType()}
        </DialogWrap>
      </Grid>
    );
  }
}

PlanningCampaignContainer.propTypes = {
  history: PropTypes.any,
  match: PropTypes.object.isRequired,
  location: PropTypes.object,
  classes: PropTypes.object,
};

PlanningCampaignContainer.contextType = WebContext;

const PlanningCampaignContainerWithStyles = withStyles(useStyles)(PlanningCampaignContainer);


export {
  PlanningCampaignContainerWithStyles as PlanningCampaignContainer,
};