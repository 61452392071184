import React from 'react';
import { Box, Paper, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { AppRecordUI } from 'src/scenes/Home/components';


const headerHeight = '48px';
const tabsHeight = '41px';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    height: `calc(100% - ${headerHeight} - ${tabsHeight})`,
  },
  searcher: {
    borderRadius: 0,
    border: `2px solid ${theme.palette.flora.primary.main}`,
    marginBottom: theme.spacing(1),
  },
  searcherText: {
    color: theme.palette.common.gray700,
    padding: theme.spacing(1) - 2,
    fontSize: '0.8rem',
  },
  button: {
    backgroundColor: 'lightgrey',
    borderRadius: 0,
  },
  finishPlotButton: {
    backgroundColor: theme.palette.flora.primary.main,
    color: theme.palette.common.white,
    fontSize: '0.85rem',
    textTransform: 'capitalize',
    width: '60%',
    fontWeight: 400,
    '&:hover': {
      backgroundColor: theme.palette.flora.primary.main,
    },
  },
}));

const defaultRecords = [
  { name: 'Chloraea heteroglossa', specificName: 'orchidaceae', eecc: true, coverage: '3' },
  { name: 'Passiflora pinnatistipula', specificName: 'passifloraceae', coverage: 'r' },
  { name: 'Porlieria chilensis', specificName: 'zygophyllaceae', eecc: true, coverage: '2' },
  { name: 'Schinus molle', specificName: 'anacardiaceae', coverage: '1' },
];

const FloraAppRecordsUI = ({ records }) => {
  const classes = useStyles();

  return (
    <Box className={classes.mainContainer} display="flex" flexDirection="column">
      <Paper className={classes.searcher} elevation={0}>
        <Box display="flex">
          <Typography variant="body2" className={classes.searcherText}>Agregar una especie</Typography>
        </Box>
      </Paper>
      { records && records.map((rec, ind) => (
        <Box key={ind} px={1} pb={1} >
          <AppRecordUI name={ rec.name } specificName={ rec.specificName }
            coverage={ rec.coverage } eecc={ rec.eecc } unofficial={ rec.unofficial }
          />
        </Box>
      )) }
      <Box flexGrow={1} />
      <Box pb={1} textAlign="center" width="100%">
        <Button className={classes.finishPlotButton}>Finalizar Parcela</Button>
      </Box>
    </Box>
  );
};

// recommended to not go for more than 5 records here
FloraAppRecordsUI.propTypes = {
  records: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      specificName: PropTypes.string,
      eecc: PropTypes.bool,
      unofficial: PropTypes.bool,
      coverage: PropTypes.string,
    }),
  ),
};

FloraAppRecordsUI.defaultProps = {
  records: defaultRecords,
};


export {
  FloraAppRecordsUI,
};