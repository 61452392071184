import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemText, ListSubheader, ListItemIcon, Divider, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { useHistory, useLocation } from 'react-router';
import { DrawerWrapper } from 'src/components';
import { Handshake, MapSearch, Terrain, ChartBar } from 'src/utils/mdIcons';
import { truncateWithEllipsis as twe } from 'src/utils/util';


const useStyles = makeStyles(theme => ({
  titleBox: {
    height: '93px',
    textOverflow: 'ellipsis',
  },
  title: {
    color: theme.palette.common.gray800,
    lineHeight: 1.25,
    fontWeight: 500,
  },
  overTitle: {
    lineHeight: 1,
  },
  listItemSelected: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: theme.palette.common.white,
  },
}));

const paths = projectId => [
  `/web/projects/${projectId}/campaigns`,
  `/web/projects/${projectId}/monitoring-plans`,
  `/web/projects/${projectId}/compromises`,
  `/web/projects/${projectId}/analytics`,
];

const campaignsIndex = 0;
const monitoringPlansIndex = 1;
const compromisesIndex = 2;
const analyticsIndex = 3;

const getIndex = (projectId, pathname) => paths(projectId).findIndex(p => p === pathname);

const ProjectDrawer = ({ projectId, projectName }) => {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();

  const [ selectedIndex, setSelectedIndex ] = useState(getIndex(projectId, pathname));

  const handleSelectedIndex = index => () => {
    history.push(paths(projectId)[index]);
    setSelectedIndex(index);
  };

  useEffect(() => {
    setSelectedIndex(getIndex(projectId, pathname));
  }, [ projectId, pathname ]);

  const commonListItemProps = { button: true, component: 'a', classes: { selected: classes.listItemSelected } };

  return (
    <DrawerWrapper>
      <Box mt={12}>
        <Box px={2} py={1} display="flex" flexDirection="column" justifyContent="center" className={classes.titleBox}>
          <Typography variant="caption" className={classes.overTitle}>Proyecto</Typography>
          <Typography className={classes.title} variant="body1">{twe(projectName, 70)}</Typography>
        </Box>
        <Divider />
        <List>
          <ListSubheader color="primary">Acciones del proyecto</ListSubheader>
          <ListItem selected={selectedIndex === campaignsIndex} onClick={handleSelectedIndex(campaignsIndex)} {...commonListItemProps}>
            <ListItemIcon><Terrain /></ListItemIcon>
            <ListItemText primary="Campañas" />
          </ListItem>
          <ListItem selected={selectedIndex === monitoringPlansIndex} onClick={handleSelectedIndex(monitoringPlansIndex)}
            {...commonListItemProps}>
            <ListItemIcon><MapSearch /></ListItemIcon>
            <ListItemText primary="Planes de monitoreo" />
          </ListItem>
          <ListItem selected={selectedIndex === compromisesIndex} onClick={handleSelectedIndex(compromisesIndex)} {...commonListItemProps}>
            <ListItemIcon><Handshake /></ListItemIcon>
            <ListItemText primary="Compromisos" />
          </ListItem>
          <ListItem selected={selectedIndex === analyticsIndex} onClick={handleSelectedIndex(analyticsIndex)} {...commonListItemProps}>
            <ListItemIcon><ChartBar /></ListItemIcon>
            <ListItemText primary="Analíticas" />
          </ListItem>
        </List>
      </Box>
    </DrawerWrapper>
  );
};

ProjectDrawer.propTypes = {
  projectId: PropTypes.string,
  projectName: PropTypes.string,
};


export { ProjectDrawer };
