import React from 'react';
import { Button, Zoom, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircleOutlineOutlined } from '@material-ui/icons';
import PropTypes from 'prop-types';
import clsx from 'clsx';


const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.common.white,
    fontWeight: 'bold',
    backgroundColor: ({ color }) => (
      color === 'primary' ? theme.palette.primary.main
      : color === 'secondary' ? theme.palette.secondary.main
      : color === 'black' ? theme.palette.common.black
      : theme.palette.primary.main
    ),
    '&:hover': {
      backgroundColor: ({ color }) => (
        color === 'primary' ? theme.palette.primary.dark
        : color === 'secondary' ? theme.palette.secondary.dark
        : color === 'black' ? theme.palette.common.gray800
        : theme.palette.primary.main
      ),
    },
    boxShadow: ({ shadow }) => (
      shadow ? '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)' : ''
    ),
    height: '3rem',
    [theme.breakpoints.down('sm')]: {
      height: '2.5rem',
    },
    '&:disabled': {
      color: ({ color }) => (
        color === 'black' ? '#cdcdcd' : 'rgba(0, 0, 0, 0.35)'
      ),
      boxShadow: 'none',
    },
  },
  loadIcon: {
    color: theme.palette.common.white,
  },
  successIcon: {
    color: theme.palette.common.white,
  },
}));

const NviroButton = (
  { color = 'secondary', disabled = false, shadow = false, loading = false, success = false, onClick, className, children, ...props },
) => {
  const classes = useStyles({ color, shadow });
  return (
    <Button
      fullWidth
      className={clsx(classes.button, className)}
      {...(loading ? {} : { onClick })}
      disabled={loading || success || disabled}
      {...props}
    >
      {
        loading ? <CircularProgress size={25} thickness={4.8} className={classes.loadIcon} />
        : success ? <Zoom in><CheckCircleOutlineOutlined className={classes.successIcon} size="large" /></Zoom>
        : children
      }
    </Button>
  );
};

NviroButton.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.any,
  shadow: PropTypes.bool,
  disabled: PropTypes.bool,
};


export {
  NviroButton,
};