import L from 'leaflet';
import * as PIXI from 'pixi.js';
import 'leaflet-pixi-overlay';

import { truncateWithEllipsis as twe } from 'src/utils/util';


PIXI.utils.skipHello();

const getMarkerIcon = (number = '', color = 'grey') => {
  L.NumberedDivIcon = L.Icon.extend({
    options: {
      iconUrl: `/img/markers/${color}.png`,
      number: '',
      shadowUrl: '/img/markers/shadow-leaflet.png',
      iconSize: [ 32, 43 ],
      iconAnchor: [ 16, 43 ],
      popupAnchor: [ 0, -43 ],
      shadowSize: [ 50, 50 ],
      shadowAnchor: [ 15, 50 ],
      className: 'leaflet-div-icon-numbered',
    },
    createIcon: function () {
      const div = document.createElement('div');
      const img = this._createImg(this.options['iconUrl']);
      const numdiv = document.createElement('div');
      numdiv.setAttribute('class', 'number');
      numdiv.style.fontSize = `${16 - ((number.length - 2) * 2)}px`;
      numdiv.style.top = `${-43 + (number.length - 2)}px`;
      numdiv.innerHTML = this.options['number'];
      div.appendChild(img);
      div.appendChild(numdiv);
      this._setIconStyles(div, 'icon');
      return div;
    },
  });
  return new L.NumberedDivIcon({ number: number });
};

const setTextToButtonsInPanel = (L, csLang) => {
  // textos de creacion de poligono
  L.drawLocal.draw.toolbar.buttons.polygon = `Selección de ${csLang.points}`;
  L.drawLocal.draw.handlers.polygon.tooltip.start = 'Haz clic para comenzar a dibujar';
  L.drawLocal.draw.handlers.polygon.tooltip.cont = 'Haz clic para continuar dibujando';
  L.drawLocal.draw.handlers.polygon.tooltip.end = 'Haz clic en el 1er punto para cerrar la figura';

  // textos de creacion de punto
  L.drawLocal.draw.toolbar.buttons.marker = `Creación de ${csLang.points}`;
  L.drawLocal.draw.handlers.marker.tooltip.start = 'Haz clic para agregar una unidad de muestreo';

  // textos de opcion
  L.drawLocal.draw.toolbar.finish.text = 'Confirmar';
  L.drawLocal.draw.toolbar.finish.title = 'Confirmar';

  L.drawLocal.draw.toolbar.undo.text = 'Borrar último punto';
  L.drawLocal.draw.toolbar.undo.title = 'Borrar último punto';

  L.drawLocal.draw.toolbar.actions.text = 'Cerrar';
  L.drawLocal.draw.toolbar.actions.title = 'Cerrar la herramienta y terminar acción actual';

  // textos de edicion de punto
  L.drawLocal.edit.toolbar.buttons.edit = 'Mover punto';
  L.drawLocal.edit.toolbar.buttons.editDisabled = 'No hay puntos para mover';
  L.drawLocal.edit.handlers.edit.tooltip.subtext = 'Haz clic cancelar para revertir los cambios';
  L.drawLocal.edit.handlers.edit.tooltip.text = 'Arrastra un marcador para mover un punto';

  // textos de eliminacion de punto
  L.drawLocal.edit.toolbar.buttons.remove = 'Eliminar punto';
  L.drawLocal.edit.toolbar.buttons.removeDisabled = 'No hay puntos para eliminar';
  L.drawLocal.edit.handlers.remove.tooltip.text = 'Haz clic en un punto para eliminarlo';

  // textos de opcion
  L.drawLocal.edit.toolbar.actions.save.text = 'Confirmar';
  L.drawLocal.edit.toolbar.actions.save.title = 'Guarda los cambios';

  L.drawLocal.edit.toolbar.actions.cancel.text = 'Cancelar';
  L.drawLocal.edit.toolbar.actions.cancel.title = 'Descarta todos los cambios';
};

// http://stackoverflow.com/questions/31790344/determine-if-a-point-reside-inside-a-leaflet-polygon
const isMarkerInsidePolygon = (marker, poly) => {
  const polyPoints = poly.getLatLngs()[0];
  const x = marker.metaData.coords.lat;
  const y = marker.metaData.coords.lng;

  let inside = false;
  for (let i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
    const xi = polyPoints[i].lat;
    const yi = polyPoints[i].lng;
    const xj = polyPoints[j].lat;
    const yj = polyPoints[j].lng;

    const intersect = ((yi > y) !== (yj > y))
      && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    if (intersect) {
      inside = !inside;
    }
  }

  return inside;
};

const getLayerType = layer => {
  if (layer instanceof L.Marker) {
    return 'marker';
  } else if (layer instanceof L.Tooltip) {
    return 'tooltip';
  } else if (layer instanceof L.Polygon) {
    return 'polygon';
  } else if (layer instanceof L.Polyline) {
    return 'linestring';
  } else if (layer instanceof L.Layer) {
    return 'layer';
  } else {
    return 'unknown';
  }
};

const getMarkersInsidePolygon = (markers, polygon) => markers.filter(marker => isMarkerInsidePolygon(marker, polygon));

const _getMarkerSizes = (length, highlighted) => (
  length === 1 ? { fontSize: 15, fontWeight: highlighted ? 750 : 'normal', anchorX: 0.5, anchorY: 2.2 }
  : length === 2 ? { fontSize: 15, fontWeight: highlighted ? 750 : 'normal', anchorX: 0.5, anchorY: 2.2 }
  : length === 3 ? { fontSize: 15, fontWeight: highlighted ? 750 : 'normal', anchorX: 0.5, anchorY: 2.2 }
  : length === 4 ? { fontSize: 12, fontWeight: 600 + (highlighted ? 200 : 0), anchorX: 0.5, anchorY: 2.4 }
  : length === 5 ? { fontSize: 12, fontWeight: 600 + (highlighted ? 200 : 0), anchorX: 0.5, anchorY: 2.4 }
  : length === 6 ? { fontSize: 10, fontWeight: 600 + (highlighted ? 200 : 0), anchorX: 0.5, anchorY: 2.85 }
  : { fontSize: 10, fontWeight: 600 + (highlighted ? 200 : 0), anchorX: 0.5, anchorY: 2.85 }
);

// Llamado al actualizar colores de puntos (por ejemplo, luego de asignar persona o de sugerir metodologías)
const _getTextureMarker = ({ color, textLenght, renderer, isCensus = false }) => {
  const pixiPrefix = '0x';
  const colorWhitoutHashtag = color.split('#')[1];
  const pixiColor = pixiPrefix + colorWhitoutHashtag;

  const hasTwoOrLessChars = textLenght <= 2;
  const has3Chars = textLenght <= 3;
  const hasBetween4and6Chars = 4 <= textLenght && textLenght <= 6;
  const roundedRectWidth = hasTwoOrLessChars ? 32 : has3Chars ? 50 : hasBetween4and6Chars ? 57 : 64;

  const graphics = new PIXI.Graphics();
  if (isCensus) {
    graphics.beginFill('0xFFFFFF');
    graphics.lineStyle(4, pixiColor);
    graphics.drawCircle(0, 0, 8);
    graphics.endFill();
  } else {
    graphics.lineStyle(4, pixiColor, 1);
    graphics.beginFill('0xFFFFFF', 0.95);
    graphics.drawRoundedRect(0, 59, roundedRectWidth, 28, 16);
    graphics.endFill();

    const triangleWidth = 12.5;
    const triangleHalfway = triangleWidth / 2;
    const triangleHeight = 10;

    const offsetX = hasTwoOrLessChars ? 10 : has3Chars ? 18 : hasBetween4and6Chars ? 22 : 26;
    const offsetY = 87.5;

    graphics.beginFill(pixiColor, 1);
    graphics.moveTo(triangleWidth + offsetX, offsetY);
    graphics.lineTo(triangleHalfway + offsetX, triangleHeight + offsetY);
    graphics.lineTo(offsetX, offsetY);
    graphics.lineTo(triangleHalfway + offsetX, offsetY);
    graphics.closePath();
    graphics.endFill();
  }

  const markerTexture = renderer.generateTexture(graphics);
  return markerTexture;
};

const addPixiLayerOnLeafletLayer = async ({ leafletLayer, actions, id = 'id' }) => {

  PIXI.utils.clearTextureCache();

  const pixiContainer = new PIXI.Container();
  pixiContainer.interactive = true;
  pixiContainer.buttonMode = true;
  const markerSprites = [];

  const isCensus = id === 'censusesLayer';

  const pixiLayer = (() => {
    const doubleBuffering = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    let firstDraw = true;
    let prevZoom;
    let frame = null;
    const pixiOverlay = L.pixiOverlay(utils => {

      if (frame) {
        cancelAnimationFrame(frame);
        frame = null;
      }
      const zoom = utils.getMap().getZoom();
      const container = utils.getContainer();
      const renderer = utils.getRenderer();
      const project = utils.latLngToLayerPoint;
      const scale = utils.getScale();
      const invScale = 1 / scale;

      const selectMarkers = markerSpritesToSelect => {
        markerSpritesToSelect.forEach(markerSprite => {
          markerSprite.metaData.isSelected = true;
          const { name } = markerSprite.metaData;
          const markerTexture = _getTextureMarker({ color: '#26c6da', textLenght: name.toString().length, renderer });
          markerSprite.texture = markerTexture;
        });
        actions.addPointsToSelectedPoints(markerSpritesToSelect);
        renderer.render(container);
      };

      const deselectMarkers = markerSpritesToDeselect => {
        markerSpritesToDeselect.forEach(markerSprite => {
          markerSprite.metaData.isSelected = false;
          const { color, name } = markerSprite.metaData;
          const markerTexture = _getTextureMarker({ color, textLenght: name.toString().length, renderer });
          markerSprite.texture = markerTexture;
        });
        actions.deletePointsFromSelectedPoints(markerSpritesToDeselect);
        renderer.render(container);
      };

      const highlightMarkerText = (markerSpritesToUpdate, metaDataUpdates) => {
        markerSpritesToUpdate.forEach(markerSprite => {
          const { name, color } = markerSprite.metaData;

          // esto se necesita para desmarcar el marcador como seleccionado
          const markerTexture = _getTextureMarker({ color, textLenght: name.toString().length, renderer, isCensus });
          markerSprite.texture = markerTexture;

          const { fontWeight } = _getMarkerSizes(name.length, true);
          markerSprite.pixiText.style.fill = '0xDB6E00';
          markerSprite.pixiText.style.fontWeight = fontWeight;

          markerSprite.metaData = { ...markerSprite.metaData, ...metaDataUpdates };
        });
        renderer.render(container);
      };

      const unhighlightMarkerText = (markerSpritesToUpdate, metaDataUpdates) => {
        markerSpritesToUpdate.forEach(markerSprite => {
          const { name, color } = markerSprite.metaData;

          // esto se necesita para desmarcar el marcador como seleccionado
          const markerTexture = _getTextureMarker({ color, textLenght: name.toString().length, renderer, isCensus });
          markerSprite.texture = markerTexture;

          const { fontWeight } = _getMarkerSizes(name.length, false);
          markerSprite.pixiText.style.fill = '0x000000';
          markerSprite.pixiText.style.fontWeight = fontWeight;

          markerSprite.metaData = { ...markerSprite.metaData, ...metaDataUpdates };
        });
        renderer.render(container);
      };


      const setMarkersColor = (markerSpritesToUpdate, color) => {
        markerSpritesToUpdate.forEach(markerSprite => {
          const { name } = markerSprite.metaData;
          const markerTexture = _getTextureMarker({ color, textLenght: name.toString().length, renderer, isCensus });
          markerSprite.texture = markerTexture;
          markerSprite.metaData.color = color;
          markerSprite.metaData.isSelected = false;
        });
        renderer.render(container);
      };

      const removeMarkers = markerSpritesToRemove => {
        markerSpritesToRemove.forEach(markerSprite => {
          container.removeChild(markerSprite.pixiText);
          container.removeChild(markerSprite);
        });
        renderer.render(container);
      };

      const toggleMarkersVisibility = (markerSpritesToShow, visibility) => {
        markerSpritesToShow.forEach(markerSprite => {
          markerSprite.pixiText.visible = visibility;
          markerSprite.visible = visibility;
        });
        renderer.render(container);
      };

      const cleanRenderer = () => {
        renderer.destroy();
      };

      const createMarkers = points => {
        points.forEach(point => {
          const coords = project(point.coords);
          const nameLength = point.name.length;
          const hasMethodologies = point.suggestedMethodologies?.length;
          const { fontSize, anchorX, anchorY, fontWeight } = _getMarkerSizes(nameLength, hasMethodologies);
          const text = nameLength <= 8 ? point.name : twe(point.name, 7);
          const markerText = new PIXI.Text(text, { fontSize, fontWeight, fill: hasMethodologies ? '0xDB6E00' : '0x000000' });
          markerText.x = coords.x;
          markerText.y = coords.y;
          markerText.anchor.set(anchorX, anchorY);
          markerText.scale.set(invScale);
          markerText.currentScale = invScale;

          const markerTexture = _getTextureMarker({ color: point.color, textLenght: point.name.toString().length, renderer, isCensus });
          const markerSprite = new PIXI.Sprite(markerTexture);
          markerSprite.interactive = true;
          markerSprite.x = coords.x;
          markerSprite.y = coords.y;
          markerSprite.anchor.set(0.5, 1);
          markerSprite.scale.set(invScale);
          markerSprite.currentScale = invScale;
          markerSprite.metaData = {
            ...point,
            select: () => selectMarkers([ markerSprite ]),
            deselect: () => deselectMarkers([ markerSprite ]),
            show: () => toggleMarkersVisibility([ markerSprite ], false),
            hide: () => toggleMarkersVisibility([ markerSprite ], true),
          };

          if (!isCensus) {
            markerSprite.popup = actions.getPopup &&
            L.popup({ offset: [ 0, -35 ] }).setLatLng(point.coords).setContent(actions.getPopup(point));
          }

          markerSprite.pixiText = markerText;
          container.addChild(markerSprite);
          markerSprites.push(markerSprite);

          if (!isCensus) {
            container.addChild(markerText);
          }
        });

        renderer.render(container);
      };

      container.metaData = {
        id, selectMarkers, deselectMarkers, highlightMarkerText, unhighlightMarkerText, setMarkersColor,
        removeMarkers, createMarkers, toggleMarkersVisibility, cleanRenderer,
      };

      if (firstDraw) {
        prevZoom = zoom;

        utils.getMap().on('click', e => {
          const interaction = renderer.plugins.interaction;
          const pointerEvent = e.originalEvent;
          const pixiPoint = new PIXI.Point();
          interaction.mapPositionToPoint(pixiPoint, pointerEvent.clientX, pointerEvent.clientY);
          const target = interaction.hitTest(pixiPoint, container);
          const isMarker = target && target.metaData;
          const expectedAction =
            isMarker && actions.setPopoverData ? 'fillPopover'
            : isMarker && actions.getPopup ? 'openPopup'
            : isMarker ? 'selectOrDeselectMarker'
            : null;
          if (expectedAction === 'selectOrDeselectMarker') {
            target.metaData.isSelected ? deselectMarkers([ target ]) : selectMarkers([ target ]);
          } else if (expectedAction === 'fillPopover') {
            isCensus ? actions.setPopoverData(target.metaData) : target.popup.openOn(utils.getMap());
          } else if (expectedAction === 'openPopup') {
            target.popup.openOn(utils.getMap());
          }
        });

      }
      if (firstDraw || prevZoom !== zoom) {
        markerSprites.forEach(ms => {
          const mt = ms.pixiText;
          if (firstDraw) {
            ms.scale.set(invScale);
            mt.scale.set(invScale);
          } else {
            ms.currentScale = ms.scale.x;
            ms.targetScale = invScale;
            mt.currentScale = mt.scale.x;
            mt.targetScale = invScale;
          }
        });
      }
      const duration = 250;
      let start;
      const animate = timestamp => {
        if (start === null) {
          start = timestamp;
        }
        const progress = timestamp - start;
        let lambda = progress / duration;
        if (lambda > 1) {
          lambda = 1;
        }
        lambda = lambda * (0.4 + lambda * (2.2 + lambda * -1.6));
        markerSprites.forEach(ms => {
          const mt = ms.pixiText;
          ms.scale.set(ms.currentScale + lambda * (ms.targetScale - ms.currentScale));
          mt.scale.set(mt.currentScale + lambda * (mt.targetScale - mt.currentScale));
        });
        renderer.render(container);
        if (progress < duration) {
          frame = requestAnimationFrame(animate);
        }
      };

      if (!firstDraw && prevZoom !== zoom) {
        start = null;
        frame = requestAnimationFrame(animate);
      }

      firstDraw = false;
      prevZoom = zoom;
      renderer.render(container);
    }, pixiContainer, {
      doubleBuffering: doubleBuffering,
      autoPreventDefault: false,
    });
    return pixiOverlay;

  })();
  pixiLayer.addTo(leafletLayer);
};

const getBoundsFromPixiMarkers = markers => {
  const [ lats, lngs ] = markers.reduce((result, marker) => {
    result[0].push(marker.metaData.coords.lat);
    result[1].push(marker.metaData.coords.lng);
    return result;
  }, [ [], [] ]);
  const minlat = Math.min(...lats);
  const maxlat = Math.max(...lats);
  const minlng = Math.min(...lngs);
  const maxlng = Math.max(...lngs);
  return [ [ minlat, minlng ], [ maxlat, maxlng ] ];
};

const getPointNamesToAvoid = ({ pointsNames, pointPrefix }) => pointsNames.filter(name => name.match(new RegExp(`^${pointPrefix}[0-9]+$`)));

const santiagoCoords = {
  lat: -32.48431324,
  lng: -71.25296321,
};


export {
  setTextToButtonsInPanel,
  getMarkerIcon,
  isMarkerInsidePolygon,
  getLayerType,
  getMarkersInsidePolygon,
  addPixiLayerOnLeafletLayer,
  getBoundsFromPixiMarkers,
  getPointNamesToAvoid,
  santiagoCoords,
};