import React, { useState, useCallback } from 'react';
import { InputLabel, DialogContent, Box, DialogActions, Button,
  DialogTitle, FormHelperText, Typography } from '@material-ui/core';

import PropTypes from 'prop-types';
import { SelectWrapper, AlertWrapper, DialogButton } from 'src/components';
import { Link } from 'react-router-dom';
import { useFetchStatus } from 'src/hooks';


const AssignPointsDialog = props => {
  const { actions = {}, options, csLang, canAddUserToCompany, userIsFan, onlyUI = false } = props;

  const [ selectedUser, setSelectedUser ] = useState();

  const submit = async () => {
    if (props.userIsFan || selectedUser?.value) {
      const plotsData = {
        userId: selectedUser.value, // when the user's role is 'fan' the backend should assign it as the selectedUser
      };
      await props.actions.updatePoints(plotsData);
    }
  };

  const { fetchStatus, setFetchStatus, errorMessage, hasError, hasBadData, fetchStatusEnum, handleConfirm } = useFetchStatus({
    validationFn: () => ({ isValid: Boolean(selectedUser?.value) }),
    confirmFn: submit,
    closeFn: actions.closeDialog,
  });

  const handleUserChange = useCallback(selectedUser => {
    setFetchStatus(fetchStatusEnum.NOT_STARTED);
    setSelectedUser(selectedUser);
    // eslint-disable-next-line
  }, []);

  const showNoUserError = !props.userIsFan && hasError && !selectedUser?.value;
  const { companyUsersOptions } = options;

  return (
    <>
      <DialogTitle id="form-dialog-title">{ userIsFan ? 'Editar' : 'Asignar' } {csLang.points}</DialogTitle>
      <DialogContent>
        <AlertWrapper enterTransition={false} severity={'info'}>
          <Typography variant="body2">
            {userIsFan ?
              <>A continuación puedes editar los datos de las {csLang.points} seleccionadas.</>
              : <>La persona quedará como encargada de todas las {csLang.points} seleccionadas.</>
            }
          </Typography>
        </AlertWrapper>
        <Box px={1}>
          {!userIsFan &&
            <Box my={3}>
              <Box display="flex" justifyContent="space-between">
                <InputLabel shrink error={showNoUserError}>Responsable</InputLabel>
                <InputLabel style={ { fontSize: '0.75rem' }}>
                  {canAddUserToCompany &&
                      <Link {...(onlyUI ? {} : { to: '/web/company/users' }) }>¿Necesitas más personas en la empresa?</Link>
                  }
                </InputLabel>
              </Box>
              <SelectWrapper options={companyUsersOptions} placeholder={'Seleccione un usuario'}
                onChange={handleUserChange} fixedList isDisabled={onlyUI} />
              { hasBadData && <FormHelperText error>Debes seleccionar un usuario</FormHelperText> }
            </Box>
          }
          <Box>
            { hasError && <AlertWrapper severity="error">{errorMessage}</AlertWrapper> }
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog} color="default">Cancelar</Button>
        <DialogButton color='primary' disabled={onlyUI} fetchStatus={fetchStatus} onClick={onlyUI ? () => {} : handleConfirm}>
          Confirmar
        </DialogButton>
      </DialogActions>
    </>
  );
};


AssignPointsDialog.propTypes = {
  options: PropTypes.object,
  actions: PropTypes.object,
  csLang: PropTypes.object,
  canAddUserToCompany: PropTypes.bool,
  userIsFan: PropTypes.bool,
  onlyUI: PropTypes.bool,
};


export { AssignPointsDialog };
