import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';


const useStyles = makeStyles(theme => ({
  chip: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 500,
    borderRadius: theme.spacing(1) / 2,
    color: theme.palette.common.gray700,
    '&:focus': {
      backgroundColor: 'transparent !important',
    },
  },
  activedChip: {
    fontWeight: 600,
    backgroundColor: 'rgba(138, 195, 255, 0.25)',
    color: theme.palette.nviro.primary.main,
    border: 'none',
    '&:hover': {
      backgroundColor: 'rgba(138, 195, 255, 0.4) !important ',
    },
    '&:focus': {
      backgroundColor: 'rgba(138, 195, 255, 0.25) !important',
    },
  },
  focusedChip: {
    fontWeight: 600,
    color: theme.palette.common.black,
    borderColor: theme.palette.common.black,
  },
  firstItem: {
    borderTopLeftRadius: theme.spacing(1) / 2,
    borderBottomLeftRadius: theme.spacing(1) / 2,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 'none',
  },
  middleItem: {
    borderRadius: 0,
    borderRight: 'none',
  },
  lastItem: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: theme.spacing(1) / 2,
    borderBottomRightRadius: theme.spacing(1) / 2,
  },
}));

const ChipFilterWrapper = forwardRef((props, ref) => {
  const classes = useStyles();
  const { isActive = false, isFocused = false, isFirstItem, isMiddleItem, isLastItem, ...otherProps } = props;
  return (
    <Chip
      color={isActive ? 'primary' : 'default'}
      className={clsx(classes.chip, {
        [classes.activedChip]: isActive,
        [classes.focusedChip]: isFocused && !isActive,
        [classes.firstItem]: isFirstItem,
        [classes.middleItem]: isMiddleItem,
        [classes.lastItem]: isLastItem,
      })}
      ref={ref}
      variant="outlined"
      {...otherProps}
    />
  );
});

ChipFilterWrapper.displayName = 'ChipFilterWrapper';

ChipFilterWrapper.propTypes = {
  isActive: PropTypes.bool,
  isFocused: PropTypes.bool,
  isFirstItem: PropTypes.bool,
  isMiddleItem: PropTypes.bool,
  isLastItem: PropTypes.bool,
};


export { ChipFilterWrapper } ;