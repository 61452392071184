import React from 'react';
import { Box, InputLabel, FormHelperText, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { StratumsInput } from 'src/components';


const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 500,
  },
}));

const CampaignCreatePointQuadratThirdStep = ({ form, handlers, validators }) => {

  const classes = useStyles();

  const { stratumList } = form;
  const { handleSelect } = handlers;
  const { hasBadData } = validators;

  return (
    <Box px={1} width="100%">
      <Typography className={classes.title} variant="body1">Ingresar estratos</Typography>
      <Box my={2}>
        <InputLabel shrink error={hasBadData && stratumList.length === 0}>Estratos</InputLabel>
        <StratumsInput value={stratumList} onChange={stratumList => handleSelect({ stratumList })} />
        {hasBadData && stratumList.length === 0 && <FormHelperText error>Debes agregar por lo menos un estrato</FormHelperText>}
      </Box>
    </Box>
  );
};

CampaignCreatePointQuadratThirdStep.propTypes = {
  form: PropTypes.object,
  parameterOptions: PropTypes.array,
  handlers: PropTypes.object,
  validators: PropTypes.object,
};


export {
  CampaignCreatePointQuadratThirdStep,
};