import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';


const ActionsBox = ({ children, ...props }) => <Box pb={0.5} textAlign="right" {...props}>{children}</Box>;

ActionsBox.propTypes = {
  children: PropTypes.any,
};


export {
  ActionsBox,
};