import React, { useState } from 'react';
import { Snackbar } from '@material-ui/core';
import PropTypes from 'prop-types';


const SnackbarWrapper = ({ children, ...props }) => {
  const [ isSnackbarOpen, setSnackbarOpen ] = useState(true);
  return (
    <Snackbar
      open={isSnackbarOpen}
      onClose={() => setSnackbarOpen(false)}
      {...props}
    >
      {children}
    </Snackbar>
  );
};

SnackbarWrapper.propTypes = {
  open: PropTypes.any,
  children: PropTypes.any,
};


export { SnackbarWrapper };