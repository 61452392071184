import React, { useEffect } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

import { FixedMenuList } from 'src/components/select/FixedMenuList';

// Deprecado, usar "AutocompleteWrapper" para quitar la dependencia a react-select
const SelectWrapper = props => {
  useEffect(() => {
    console.warn('El componente "SelectWrapper" esta deprecado, usar "AutocompleteWrapper" en su lugar');
  }, []);
  const { fixedList, ...otherProps } = props;
  return (
    <Select
      menuPortalTarget={document.body}
      styles={{
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        container: () => ({ fontFamily: 'Asap' }),
        menuList: () => ({ fontFamily: 'Asap' }),
        multiValue: (base, state) => state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base,
        multiValueLabel: (base, state) => state.data.isFixed ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 } : base,
        multiValueRemove: (base, state) => state.data.isFixed ? { ...base, display: 'none' } : base,
      }}
      {...(fixedList ? { components: { MenuList: FixedMenuList } } : {}) }
      {...otherProps}
    />
  );
};

SelectWrapper.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.any,
  theme: PropTypes.object,
  fixedList: PropTypes.bool,
};


export { SelectWrapper } ;
