import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, DialogTitle, DialogContent, DialogActions, Zoom, FormControlLabel, FormGroup, Checkbox, Tooltip } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { withStyles } from '@material-ui/styles';
import InfoIcon from '@material-ui/icons/Info';

import { UserLinkForm, UserLinkValidation } from 'src/scenes/CompanyUsers/components';

const emailRegexp = /^.+@.+$/;

const useStyles = () => ({
  block: {
    display: 'block',
  },
  tooltip: {
    cursor: 'pointer',
    bottom: '8px',
    right: '8px',
    marginBottom: '-5px',
  },
});

const defaultState = rolesOptions => ({
  firstName: '',
  lastName: '',
  email: '',
  selectedRole: rolesOptions?.find(role => role.value === 'collector'),
  needRegister: false,
  activeStep: 0,
  // seguirá el formato errors.fieldName = 'Error message'
  errors: {},
  submitting: false,
  isFreelance: false,
});
class UserLinkDialogWithoutStyle extends Component {

  constructor(props) {
    super(props);
    this.state = defaultState(props.rolesOptions);
  }

  validateSecondStep = ({ firstName, lastName, selectedRole }) => {
    let isValid = true;
    const newErrorObject = {};
    if (!selectedRole?.value || this.props.rolesOptions.every(role => selectedRole.value !== role.value)) {
      newErrorObject.role = 'Debe elegir uno de los roles de la lista';
      isValid = false;
    }

    if (this.state.needRegister) {
      if (!firstName) {
        newErrorObject.firstName = 'Campo requerido';
        isValid = false;
      }
      if (!lastName) {
        newErrorObject.lastName = 'Campo requerido';
        isValid = false;
      }
    }

    this.setState({ errors: isValid ? {} : newErrorObject });
    return isValid;
  };

  handleSubmit = async () => {
    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true });
    const { firstName: rawFirstName, lastName: rawLastName, email: rawEmail, selectedRole, isFreelance } = this.state;
    const firstName = rawFirstName.trim();
    const lastName = rawLastName.trim();
    const email = rawEmail.trim().toLocaleLowerCase();
    const isValid = this.validateSecondStep({ firstName, lastName, selectedRole });
    if (!isValid) {
      this.setState({ submitting: false });
      return;
    }
    const newUser = { firstName, lastName, email, role: selectedRole.value, isFreelance };
    try {
      await this.props.actions.linkUser(newUser);
    } catch (e) {
      this.setState({ submitting: false });
      this.setState({ errors: { general: e.serverMessage ?? 'Hubo un error en el servidor, por favor intenta más tarde' } });
    }
  };

  checkExistence = async () => {
    if (!this.state.email) {
      this.setState({ errors: { email: 'Debe ingresar un email' } });
      return;
    }

    if (!this.state.email.match(emailRegexp)) {
      this.setState({ errors: { email: 'El email ingresado no tiene un formato válido' } });
      return;
    }

    const user = await this.props.actions.getUser(this.state.email);
    const userExist = Boolean(user);
    if (userExist && this.props.currentUsers.some(currentUser => currentUser.email === user.email)) {
      this.setState({ errors: { email: 'Ya hay una persona con este email en la compañía' } });
      return;
    }

    this.setState({ needRegister: !userExist, activeStep: 1, errors: {} });
  };
  toggleCheck = () => {
    this.setState({ isFreelance: !this.state.isFreelance });
  };

  goBack = () => this.setState(defaultState(this.props.rolesOptions));

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleSelect = obj => {
    this.setState({ ...obj });
    if (obj.selectedRole.value === 'admin') {
      this.setState({ isFreelance: false });
    }
  };

  render() {
    const { needRegister, activeStep, errors, submitting } = this.state;
    const { classes } = this.props;
    const { handleChange, handleSelect } = this;
    return (
      <Fragment>
        <DialogTitle id="form-dialog-title">Agregar persona a la compañía</DialogTitle>
        <DialogContent>
          { activeStep === 0 &&
            <UserLinkValidation
              handleChange={ this.handleChange }
              errorText={ errors?.email }
            />}
          { activeStep === 1 &&
            <>
              <UserLinkForm
                actions={{ handleChange, handleSelect }}
                needRegister={ needRegister}
                rolesOptions={ this.props.rolesOptions }
                errors={ errors }
                selectedRole={ this.state.selectedRole }
              />
              { this.state.selectedRole.value !== 'admin' &&
                <FormGroup className={classes.block}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={this.state.isFreelance} onChange={this.toggleCheck} />
                    }
                    label={'¿Es freelance?'}
                  />
                  <Tooltip
                    title={'Los usuarios freelance dejan de ver las campañas en las que participaron una vez que estas son finalizadas.'}>
                    <InfoIcon color="disabled" fontSize="small" className={classes.tooltip} />
                  </Tooltip>
                </ FormGroup>
              }
            </>
          }
          { errors?.general &&
            <Zoom in>
              <Alert severity="error">{ errors.general }</Alert>
            </Zoom>
          }
        </DialogContent>
        <DialogActions>
          {activeStep === 0 && <Button onClick={this.props.actions.closeDialog}>Cancelar</Button>}
          {activeStep === 0 && <Button onClick={this.checkExistence} color="primary">Siguiente</Button>}
          {activeStep === 1 && <Button onClick={this.goBack}>Volver</Button>}
          {activeStep === 1 && <Button disabled={submitting} onClick={this.handleSubmit} color="primary">Agregar</Button>}
        </DialogActions>
      </Fragment>
    );
  }
}

UserLinkDialogWithoutStyle.propTypes = {
  actions: PropTypes.object,
  rolesOptions: PropTypes.array,
  currentUsers: PropTypes.array,
  classes: PropTypes.object,
};

const UserLinkDialog = withStyles(useStyles)(UserLinkDialogWithoutStyle);

export {
  UserLinkDialog,
};
