import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({
  secondaryText: {
    fontSize: '0.8rem',
  },
  icon: {
    cursor: 'pointer',
  },
  main: {
    color: ({ mainColor }) => mainColor,
  },
}));

const IndicatorUI = ({ title = '', mainCount = '', mainText = '', mainColor, secondaryCount = '', secondaryText = '' }) => {
  const classes = useStyles({ mainColor });
  return (
    <Box display="flex" flexDirection="column" p={2} pb={1} >
      <Box display="flex" alignItems="center">
        <Typography noWrap variant="subtitle2" color="textSecondary">{title}</Typography>
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Box display="flex" alignItems="center">
          <Typography className={classes.main} variant="h3">{mainCount}</Typography>
          <Box display="flex" ml={1} >
            <Typography className={classes.main} variant="subtitle2">{mainText}</Typography>
          </Box>
        </Box>
        <Box display="flex">
          <Typography className={classes.secondaryText} variant="caption" color="textSecondary">
            {secondaryCount} {secondaryText}
          </Typography>
          <Box flexGrow={1}></Box>
          <InfoIcon className={classes.icon} color="disabled" fontSize="small" />
        </Box>
      </Box>
    </Box>
  );
};

IndicatorUI.propTypes = {
  title: PropTypes.string,
  mainCount: PropTypes.number,
  mainText: PropTypes.string,
  secondaryCount: PropTypes.number,
  secondaryText: PropTypes.string,
  mainColor: PropTypes.string,
};


export {
  IndicatorUI,
};
