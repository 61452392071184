import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Grid, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';

import { WebContext } from 'src/scenes/web-context';
import { TitleContainer, ContentContainer } from 'src/components';
import { campaignStatisticsApi } from 'src/services';
import {
  SpeciesIndicator, SpeciesUnrecognizedIndicator, SpeciesEECCIndicator, PointTimeByCampaignIndicator, SpeciesSaturationCurve,
  GeneralPointsProgress, PointTimeByUser, PointsByUser, SpeciesByUser, RecordsByUser,
  FamiliesByClass, SpeciesByClass, SpeciesCounterByPoint, CoverageByPoint,
} from 'src/scenes/Campaign/scenes/Statistics/components';
import { isTransect, isCensus } from 'src/utils/checkers';


const useStyles = makeStyles(theme => ({
  contentContainer: {
    paddingBottom: theme.spacing(2),
  },
  indicator: {
    height: theme.spacing(22),
  },
  saturation: ({ isCensusCampaign }) => ({
    height: isCensusCampaign ? theme.spacing(42) : theme.spacing(53),
  }),
  progress: {
    height: theme.spacing(53),
  },
  rank: {
    height: theme.spacing(53),
  },
  saturationAndProgress: {
    paddingTop: theme.spacing(2),
  },
  someByUser: {
    height: theme.spacing(42),
  },
  recordBySome: {
    height: theme.spacing(53),
  },
}));

const StatisticsContainer = () => {
  const context = useContext(WebContext);
  const { selectedCampaign: { componentId, mainMethodId }, campaignSubtypeLanguage: cLang } = context;
  const isCensusCampaign = isCensus(mainMethodId);

  const match = useRouteMatch();
  const { campaignHash } = match.params;

  const classes = useStyles({ isCensusCampaign });


  const [ data, setData ] = useState({
    pointTimeCampaign: {},
    pointTimeUsers: [],
    species: {},
    speciesEECC: {},
    speciesUnrecognized: { species: {}, records: {} },
  });
  useEffect(() => {
    const fetchData = async () => {
      const isCensusCampaign = isCensus(mainMethodId);
      const [ speciesCounter, recordCounter, pointTime ] = await Promise.all([
        campaignStatisticsApi.getSpeciesCounter(campaignHash),
        campaignStatisticsApi.getRecordsCounter(campaignHash),
        isCensusCampaign ? null : campaignStatisticsApi.getPointTimeByUser(campaignHash),
      ]);

      setData({
        species: { speciesCount: speciesCounter.total, recordsCount: recordCounter.total },
        speciesEECC: { speciesCount: speciesCounter.eecc, recordsCount: recordCounter.eecc },
        speciesUnrecognized: {
          species: {
            recognizedSpeciesCount: speciesCounter.total - speciesCounter.unofficial_source,
            unrecognizedSpeciesCount: speciesCounter.unofficial_source,
          },
          records: {
            recognizedRecordCount: recordCounter.total - recordCounter.unofficial_source,
            unrecognizedRecordCount: recordCounter.unofficial_source,
          },
        },
        pointTimeCampaign: pointTime?.[pointTime.length - 1],
        pointTimeUsers: pointTime?.slice(0, pointTime.length - 1),
      });
    };
    fetchData();
  }, [ campaignHash, mainMethodId ]);

  return (
    <Grid container>
      <TitleContainer maxWidth="xl" breadcrumbs={[ 'company', 'campaigns', { name: 'Estadísticas' } ]}>
        Estadísticas
      </TitleContainer>
      <ContentContainer maxWidth="xl" className={classes.contentContainer}>
        <Grid container spacing={2} direction="column">
          <Grid container item justifyContent="center" spacing={2} xs={12}>
            <Grid item xs={isCensusCampaign ? 4 : 3}>
              <Paper className={classes.indicator}>
                <SpeciesIndicator data={data.species} />
              </Paper>
            </Grid>
            <Grid item xs={isCensusCampaign ? 4 : 3}>
              <Paper className={classes.indicator}>
                <SpeciesEECCIndicator data={data.speciesEECC} />
              </Paper>
            </Grid>
            <Grid item xs={isCensusCampaign ? 4 : 3}>
              <Paper className={classes.indicator}>
                <SpeciesUnrecognizedIndicator data={data.speciesUnrecognized} />
              </Paper>
            </Grid>
            { !isCensusCampaign &&
              <Grid item xs={3}>
                <Paper className={classes.indicator}>
                  <PointTimeByCampaignIndicator data={data.pointTimeCampaign} cLang={cLang} componentId={componentId}/>
                </Paper>
              </Grid>
            }
            <Grid item xs={6}>
              <Paper className={classes.saturation}>
                <SpeciesSaturationCurve campaignHash={campaignHash} />
              </Paper>
            </Grid>
            { !isCensusCampaign && <>
              <Grid item xs={3}>
                <Paper className={classes.progress}>
                  <GeneralPointsProgress campaignHash={campaignHash} cLang={cLang} />
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper className={classes.rank}>
                  <PointTimeByUser campaignHash={campaignHash} data={data.pointTimeUsers} cLang={cLang} componentId={componentId}/>
                </Paper>
              </Grid>
            </> }
            { componentId === 'fauna' &&
              <Fragment>
                <Grid item xs={6}>
                  <Paper className={classes.recordBySome}>
                    <FamiliesByClass campaignHash={campaignHash} />
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper className={classes.recordBySome}>
                    <SpeciesByClass campaignHash={campaignHash} />
                  </Paper>
                </Grid>
              </Fragment>
            }
            {context.selectedCompany.role !== 'fan' &&
              <Fragment>
                <Grid item xs={isCensusCampaign ? 3 : 4}>
                  <Paper className={classes.someByUser}>
                    <SpeciesByUser campaignHash={campaignHash} />
                  </Paper>
                </Grid>
                { !isCensusCampaign &&
                  <Grid item xs={4}>
                    <Paper className={classes.someByUser}>
                      <PointsByUser campaignHash={campaignHash} cLang={cLang} />
                    </Paper>
                  </Grid>
                }
                <Grid item xs={isCensusCampaign ? 3 : 4}>
                  <Paper className={classes.someByUser}>
                    <RecordsByUser campaignHash={campaignHash} mainMethodId={mainMethodId} />
                  </Paper>
                </Grid>
                {isTransect(mainMethodId) &&
                  <>
                    <Grid item xs={4}>
                      <Paper className={classes.someByUser}>
                        <SpeciesCounterByPoint campaignHash={campaignHash} cLang={cLang}/>
                      </Paper>
                    </Grid>
                    <Grid item xs={4}>
                      <Paper className={classes.someByUser}>
                        <CoverageByPoint campaignHash={campaignHash} cLang={cLang}/>
                      </Paper>
                    </Grid>
                  </>
                }
              </Fragment>
            }
          </Grid>
        </Grid>
      </ContentContainer>
    </Grid>
  );
};

StatisticsContainer.propTypes = {
  match: PropTypes.object.isRequired,
};


export {
  StatisticsContainer,
};