import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Popover, FormGroup, FormControlLabel, Checkbox, Zoom, Divider } from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlankOutlined, ArrowDropDown } from '@material-ui/icons';

import { ChipFilterWrapper } from 'src/components/filter/ChipFilterWrapper';
import { upperCaseOnlyFirstLetter as upofl } from 'src/utils/formatters';


const FilterSelect = ({ id, label, prevValue: prevOptionsValues, options, onChange, inline = false, toUpperFirst = true }) => {

  const [ anchorEl, setAnchorEl ] = useState(null);
  const isOpenPopover = Boolean(anchorEl);
  const popoverId = isOpenPopover ? `${id}-popover` : undefined;

  const openPopup = event => setAnchorEl(event.currentTarget);

  const closePopup = () => setAnchorEl(null);

  const isOptionActive = ({ value }) => prevOptionsValues.includes(value);

  const [ selectedOptionsValues, setSelectedOptionsValues ] = useState(prevOptionsValues);

  const handleChangeOptionSelected = optionValue => {
    const newValues = [ ...selectedOptionsValues ];
    const index = newValues.indexOf(optionValue);
    index > -1 ? newValues.splice(index, 1) : newValues.push(optionValue);
    setSelectedOptionsValues(newValues);
  };

  const isOptionCheckedToActivate = ({ value }) => selectedOptionsValues.includes(value);
  const hasSomeOptionSelected = prevOptionsValues.length > 0;
  const hasOneOptionSelected = prevOptionsValues.length === 1;

  const handleConfirm = optionValue => {
    if (inline) {
      const newValues = [ ...prevOptionsValues ];
      const index = newValues.indexOf(optionValue);
      index > -1 ? newValues.splice(index, 1) : newValues.push(optionValue);
      onChange({ id, value: newValues });
    } else {
      onChange({ id, value: selectedOptionsValues });
      closePopup();
    }
  };

  const handleDelete = () => {
    onChange({ id, value: [] });
    setSelectedOptionsValues([]);
  };

  return (
    options.length > 0 &&
    <>
      {inline ?
        options.map((option, index) =>
          <ChipFilterWrapper
            isActive={isOptionActive(option)}
            isFirstItem={index === 0}
            isMiddleItem={0 < index && index < options.length - 1}
            isLastItem={index === options.length - 1}
            key={index}
            label={toUpperFirst ? upofl(option.label) : option.label}
            onClick={() => handleConfirm(option.value)}
            icon={isOptionActive(option) ?
              <Zoom in><CheckBox fontSize="small" /></Zoom> :
              <CheckBoxOutlineBlankOutlined fontSize="small" />
            }
          />,
        ) :
        <>
          <Popover
            id={popoverId}
            open={isOpenPopover}
            anchorEl={anchorEl}
            onClose={closePopup}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <Box px={2} pt={2} pb={1}>
              <FormGroup>
                {options.map((option, index) =>
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox checked={isOptionCheckedToActivate(option)} onChange={() => handleChangeOptionSelected(option.value)} />
                    }
                    label={toUpperFirst ? upofl(option.label) : option.label}
                  />)
                }
                <Box mt={1}>
                  <Divider />
                </Box>
                <Box mt={1} display="flex" alignSelf="flex-end">
                  <Button color="default" size="small" onClick={handleConfirm}>Aplicar</Button>
                </Box>
              </ FormGroup>
            </Box>
          </Popover>
          <ChipFilterWrapper
            isActive={hasSomeOptionSelected}
            isFocused={isOpenPopover && !hasSomeOptionSelected}
            icon={<ArrowDropDown fontSize="small" />}
            label={hasOneOptionSelected ? options.find(o => o.value === prevOptionsValues[0]).label : label}
            onClick={openPopup}
            {...(hasSomeOptionSelected ? { onDelete: handleDelete } : {})}
          />
        </>
      }
    </>
  );
};

FilterSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  prevValue: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  inline: PropTypes.bool,
  toUpperFirst: PropTypes.bool,
};


export {
  FilterSelect,
};