import { get, patch, post, del } from 'src/services/api';


const getRecordsWithTreesByPlot = async plotHash => (await get('forest/records-with-trees', { plotHash })).data;

const updateRecord = async ({ recordId, updatedData }) => (await patch(`/forest/record/${recordId}`, updatedData)).data;

const updateTree = async ({ id, ...dataToUpdate }) => (await patch(`/forest/tree/${id}`, dataToUpdate)).data;

const createRecord = async record => (await post(`/forest/record`, record, { headers: { 'Content-Type': 'multipart/form-data' } })).data;

const createTree = async tree => (await post(`/forest/tree`, tree, { headers: { 'Content-Type': 'multipart/form-data' } })).data;

const deleteTree = async ({ toDelete, treeId }) => (await del(`/forest/tree/${treeId}`, { toDelete })).data;


export {
  getRecordsWithTreesByPlot,
  updateRecord,
  updateTree,
  createRecord,
  createTree,
  deleteTree,
};
