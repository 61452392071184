import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Edit, PhotoAlbum, ContactSupport, NavigateNext, ExpandMore, Delete, ArrowForward, AddCircle } from '@material-ui/icons';
import urlJoin from 'url-join';
import { useRouteMatch } from 'react-router';

import config from 'src/config/local';
import { TableWrapper, LabelChip, LabelCoords, WarnableAccuracy } from 'src/components';
import { ForestTreesList, DirectIndividualsList } from 'src/scenes/Campaign/scenes/Records/components/RecordDetail';
import { campaignsApi } from 'src/services';
import { wgs84ToUTM } from 'src/utils/converters';
import { upperCaseFirstLetter as ucfl, upperCaseOnlyFirstLetter as upofl } from 'src/utils/formatters';
import { optionsToObject, formatDate, formatDateExtension } from 'src/utils/util';
import { cardinalPointsEnum } from 'src/utils/enums/cardinalPointsEnum';
import checkers from 'src/utils/checkers';


const { serverUrl } = config;

const isValidSpecies = record =>
  (checkers.isFaunaIndirectRecord(record.type) && record.scientificName === null)
  || record.byOfficialSource;

const NA = ({ r }) => <Box color={!isValidSpecies(r) ? 'inherit' : 'text.hint'}>No aplica</Box>; //eslint-disable-line

const recordTypeName = {
  'flora-record': 'Flora',
  'flora-individual-record': 'Flora Individual',
  'forest-record': 'Forestal',
  'flora-census-record': 'Censo',
  'fauna-direct-record': 'Directo',
  'fauna-indirect-record': 'Indirecto',
  'fauna-isolated-record': 'Aislado',
  'fauna-transit-record': 'Tránsito',
  'fauna-presence-record': 'Presencia',
};

const checkRecHasCoords = rec => checkers.isFloraIndividualRecord(rec.type) || checkers.isFaunaIndirectRecord(rec.type) ||
  checkers.isFaunaIsolatedRecord(rec.type) || checkers.isCensusRecord(rec.type);

const getColumns = ({ theme, userIsFan, campaignSubtypeId, optionsMaps, censusColumns }) => {
  const isPlot = checkers.isPlot(campaignSubtypeId);
  const isStation = checkers.isStation(campaignSubtypeId);
  const isTransect = checkers.isTransect(campaignSubtypeId);
  const isPointQuadratCampaign = checkers.isPointQuadrat(campaignSubtypeId);
  const isPointInterceptCampaign = checkers.isPointIntercept(campaignSubtypeId);
  const isCensusCampaign = checkers.isCensus(campaignSubtypeId);

  const sexTypesMap = optionsMaps.sexTypesOptions ?? {};
  const ageStratumsMap = optionsMaps.ageStratumsOptions ?? {};
  const indirectRecordsTypesMap = optionsMaps.indirectRecordsTypesOptions ?? {};

  const phytosanitaryStatusesMap = optionsMaps.phytosanitaryStatusesOptions ?? {};
  const phenologiesStatusesMap = optionsMaps.phenologiesStatusesOptions ?? {};
  const stratumsMap = optionsMaps.stratumsOptions ?? {};

  const labelFixColor = { style: { color: theme.palette.common.white, borderColor: theme.palette.common.white } };

  const columns = [
    { title: 'Especie', field: 'scientificName',
      render: r => r.scientificName ? <span style={ { fontStyle: 'italic' } }>{ucfl(r.scientificName)}</span> : 'Sin reconocer',
    },
    ...(!(isTransect || isCensusCampaign) ? [ { title: 'Tipo de registro', field: 'type', render: r => recordTypeName[r.type] } ] : []),
    ...userIsFan ? [] : [ { title: 'Capturada por', render: r => r.email || 'Sin información' } ],
    ...(isPlot ? [ {
      title: 'Parcela', render: r => !checkers.isFloraIndividualRecord(r.type) ? r.plotName : <NA r={r} />,
    } ] : []),
    ...(isStation ? [ {
      title: 'Estación muestreo', render: r => !checkers.isFaunaIsolatedRecord(r.type) ? r.ssName : <NA r={r} />,
    } ] : []),
    ...(isTransect ? [ { title: 'Transecta', field: 'transectName' } ] : []),
    ...(isStation ? [ { title: 'Metodología', render: r => !checkers.isFaunaIsolatedRecord(r.type) ? r.methodologyName : <NA r={r} />,
    } ] : []),
    ...(isStation ? [ {
      title: 'Tipo de metodología', render: r => !checkers.isFaunaIsolatedRecord(r.type) ? upofl(r.methodologyType) : <NA r={r} />,
    } ] : []),
    ...(isPlot ? [ { title: 'Cobertura', render: r => checkers.isFloraRecord(r.type) ? r.coverage : <NA r={r} /> } ] : []),

    ...((isPlot || isStation || isCensusCampaign) ? [ {
      title: 'Coordenada (UTM Sur-Oeste)', render: r => checkRecHasCoords(r) ?
        <LabelCoords latitude={r.latitude} longitude={r.longitude} {...r.byOfficialSource ? {} : { textColor: 'white' }}/> : <NA r={r} />,
    } ] : []),

    ...((isPlot || isStation || isCensusCampaign) ? [ { title: 'Precisión', render: r => checkRecHasCoords(r) ?
      <WarnableAccuracy accuracy={ r.accuracy } /> : <NA r={r} />,
    } ]
    : []
    ),
    ...((isPlot || isStation || isCensusCampaign) ? [ { title: 'Altitud', render: r => checkRecHasCoords(r) ? r.altitude : <NA r={r} /> },
    ] : []),
    ...(isPlot ? [ {
      title: 'Agrupaciones forestales', render: r => checkers.isForestRecord(r.type) ? r.trees.length : <NA r={r} />,
    } ] : []),
    ...(isStation ?
      [ {
        title: 'Cantidad',
        render: r => checkers.isFaunaDirectRecord(r.type) || checkers.isFaunaTransitRecord(r.type) ? r.number : <NA r={r} />,
      } ] : []
    ),
    ...(isStation ? [ {
      title: 'Tipo de RI',
      render: r => checkers.isFaunaIndirectRecord(r.type) ?
        (r.otherType ? `Otros - ${r.otherType}` : indirectRecordsTypesMap[r.typeId]) : <NA r={r} />,
    } ] : []),
    ...(isStation ? [ {
      title: 'Estrato etario', render: r => checkers.isFaunaIsolatedRecord(r.type) ? ageStratumsMap[r.ageStratumId] : <NA r={r} />,
    } ] : []),
    ...(isStation ? [ {
      title: 'Sexo', field: 'sexId', render: r => checkers.isFaunaIsolatedRecord(r.type) ? sexTypesMap[r.sexId] : <NA r={r} />,
    } ] : []),
    ...(isStation ? [ { title: 'Desde -> Hacia', field: 'fromTowards', sorting: false,
      render: r => checkers.isFaunaTransitRecord(r.type) ?
        (r.from || r.towards) ?
          <nobr>
            <Box display="flex">
              {cardinalPointsEnum[r.from] || 'Desconocido'}
              <ArrowForward fontSize="small" />
              {cardinalPointsEnum[r.towards] || 'Desconocido'}
            </Box>
          </nobr> :
          'Sin datos' :
        <NA r={r} />,
    } ] : []),
    ...(isStation ?
      [ { title: 'Altura (m)', field: 'height', render: r => checkers.isFaunaTransitRecord(r.type) ?
        r.height ? r.height : `${r.minHeight} - ${r.maxHeight}` : <NA r={r} />,
      } ] : []
    ),
    ...(isStation ?
      [ { title: 'Tipo de vuelo', field: 'flightTypeValue', render: r => checkers.isFaunaTransitRecord(r.type) ?
        r.flightTypeValue || 'Sin datos' : <NA r={r} />,
      } ] : []
    ),
    ...(isTransect ? [ { title: 'Punto en transecta', field: 'pointNumber' } ] : []),
    ...(isPointQuadratCampaign || isPointInterceptCampaign ? [ { title: 'Sustrato', field: 'substratumName',
      render: r => <div>{r.substratumName?.trim() || 'Sin sustrato'}</div> } ] : []),
    ...(isPointQuadratCampaign ? [ { title: 'Contactos', field: 'touches' } ] : []),
    ...(isPointQuadratCampaign ? [ {
      title: 'Estratos',
      render: r => checkers.isForestRecord(r.type) ? <NA r={r} /> : r.stratums.map(s =>
        s.id &&
          <Box key={s.id} mx={1}>
            <LabelChip
              variant="outlined"
              color="default"
              {...(!r.byOfficialSource ? labelFixColor : {})}
              label={`${stratumsMap[s.id]} a ${s.height} cm`}
            />
          </Box>,
      ),
    } ] : []),
    ...(isPlot || isTransect ? [ {
      title: 'Estado fenológico',
      render: r => checkers.isForestRecord(r.type) ? <NA r={r} />
      : !r.phenologiesStatuses?.length ? '-'
      : r.phenologiesStatuses.map(psId =>
        <Box key={psId} mx={1}>
          <LabelChip
            variant="outlined"
            color="default"
            {...(!r.byOfficialSource ? labelFixColor : {})}
            label={phenologiesStatusesMap[psId]}
          />
        </Box>,
      ),
    } ] : []),
    ...(isPlot || isTransect ? [ {
      title: 'Estado fitosanitario ',
      render: r => !r.phytosanitaryStatuses?.length ? '-' : r.phytosanitaryStatuses.map(psId =>
        <Box key={psId} mx={1}>
          <LabelChip
            variant="outlined"
            color="default"
            {...(!r.byOfficialSource ? labelFixColor : {})}
            label={phytosanitaryStatusesMap[psId]}
          />
        </Box>,
      ),
    } ] : []),
    ...(isPlot || isPointQuadratCampaign ?
      [ { title: 'N° Individuos', render: r =>
        checkers.isFloraRecord(r.type) ?
          r.individuals || '-' :
          checkers.isForestRecord(r.type) ?
            r.trees.reduce((total, tree) => total + tree.number, 0) :
            <NA r={r} /> } ] : []),
    ...(isCensusCampaign ? censusColumns.map(cenCol => ({
      title: `${cenCol.label}${cenCol.unit ? ` (${cenCol.unit})` : ''}`,
      render: rec => {
        const cellVal = rec.data[cenCol.id];
        return (cellVal == null || (cenCol.options && !cellVal?.length)) ? '-'
          : cenCol.type === 'bool' ? (cellVal ? 'Sí' : 'No')
          // tiene options y puede tener múltiples valores
          : cenCol.options && (cenCol.type.endsWith('}') || cenCol.type.endsWith(']')) ? cellVal.map(optVal =>
            <Box key={optVal} mx={1}>
              <LabelChip
                variant="outlined"
                color="default"
                {...(!rec.byOfficialSource ? labelFixColor : {})}
                label={ optionsMaps[`${cenCol.id}Options`][optVal] }
              />
            </Box>,
          ) // Si no cayó en el anterior, tiene solo un valor si tiene options:
          : cenCol.options ? <Box key={cellVal} mx={1}>
            <LabelChip
              variant="outlined"
              color="default"
              {...(!rec.byOfficialSource ? labelFixColor : {})}
              label={ optionsMaps[`${cenCol.id}Options`][cellVal] }
            />
          </Box>
          // si no es de las otras optiones... solo ponemos el valor directamente
          : cellVal;
      },
    })) : []),
    {
      title: 'Fecha de detección',
      render: r => r.detectionDate ?
        <Box whiteSpace="nowrap">{formatDate(r.detectionDate)} {formatDateExtension(r.detectionDate)}</Box>
        : checkers.isForestRecord(r.type) ? <NA r={r} /> : '-',
    },
    ...(isPlot ? [ { title: 'Creación', render: r => new Date(r.createdAt).toLocaleDateString('es') } ] : []),
    { title: 'Observaciones', field: 'observation',
      render: r => checkers.isForestRecord(r.type) ? <NA r={r} /> : <>{r.observation?.trim() || 'Sin observaciones'}</>,
    },
  ];

  return columns;
};

const RecordsList = props => {

  const { actions, campaignInProgress, tableRef, reviewPoint, coordinatesCampaign, setCampaignCounter,
    hasAdminPowers, currentUser, campaignSubtypeId, hasOwnWorkFinished, userIsFan, options, censusColumns } = props;
  const theme = useTheme();
  const match = useRouteMatch();
  const { campaignHash } = match.params;

  const optionsMaps = useMemo(() => Object.keys(options).reduce((acc, optsKey) => {
    const opts = options[optsKey];
    return { ...acc, [optsKey]: optionsToObject(opts) };
  }, {}), [ options ]);

  const phytosanitaryStatusesMap = optionsMaps.phytosanitaryStatusesOptions ?? {};
  const phenologiesStatusesMap = optionsMaps.phenologiesStatusesOptions ?? {};

  const tableColumns = getColumns({ campaignSubtypeId, userIsFan, optionsMaps, theme, censusColumns });


  const tableActions = [
    rowData => {
      const ownRecord = rowData.email === currentUser.email;
      // TODO: al cambiar las condiciones de forceReview, parece que admin podía editar aunque estuviera revisada?
      const editionDisabled = !campaignInProgress ||
        (!coordinatesCampaign && !hasAdminPowers && !ownRecord) ||
        (hasOwnWorkFinished && !coordinatesCampaign && !hasAdminPowers);
      return {
        hidden: !campaignInProgress,
        disabled: editionDisabled,
        icon: () => <Edit color="action" />,
        tooltip: editionDisabled ? `No puedes editar este registro` : 'Editar',
        onClick: (event, rowData) => actions.toggleDialog({
          data: rowData,
          type: `edit-${checkers.isStation(campaignSubtypeId) ? 'fauna' : 'flora'}-record`,
        }),
      };
    },

    rowData => {
      const isForestRecord = checkers.isForestRecord(rowData.type);
      const isFaunaDirectRecord = checkers.isFaunaDirectRecord(rowData.type);
      const isHidden = !(isForestRecord || isFaunaDirectRecord);
      const type = (
        isForestRecord ? 'create-tree' : isFaunaDirectRecord ? 'create-individual' : ''
      );
      const tooltip = (
        isForestRecord ? 'Agregar agrupación de individuos forestales' : isFaunaDirectRecord ? 'Agregar individuo' : ''
      );
      return ({
        hidden: isHidden,
        disabled: isHidden,
        icon: () => <AddCircle color="action" />,
        tooltip,
        onClick: () => actions.toggleDialog({ data: rowData, type }),
      });
    },

    rowData => {
      const isHidden = checkers.isForestRecord(rowData.type);
      const hasPictures = rowData.pictures?.length > 0;

      return ({
        hidden: isHidden,
        disabled: isHidden || !hasPictures,
        icon: () => <PhotoAlbum color={hasPictures ? 'action' : 'disabled'} />,
        tooltip: hasPictures ? 'Ver fotos' : 'Sin fotos',
        ...(hasPictures ? { onClick: (event, rowData) => actions.toggleDialog({
          data: {
            pictures: rowData.pictures.map(p => urlJoin(serverUrl, p)),
            title: rowData.scientificName ? rowData.scientificName : 'Sin reconocer',
          },
          type: 'photo',
        }) } : {}),
      });
    },

    // TODO: implementar consulta a la comunidad y restaurar el botón en producción
    ...!config.webUrl.includes('test') ? [] : [
      rowData => {
        const isForestRecord = checkers.isForestRecord(rowData.type);
        const isFloraRecord = checkers.isFloraRecord(rowData.type);
        const isFloraIndividualRecord = checkers.isFloraIndividualRecord(rowData.type);

        const hasPictures = (isFloraRecord || isFloraIndividualRecord) ?
          rowData.pictures.length > 0 : isForestRecord && rowData.trees.some(t => t.pictures.length > 0);
        const isValidUser = (isFloraRecord || isForestRecord) ?
          currentUser.id === parseInt(rowData.userId, 10) :
          isFloraIndividualRecord && currentUser.email === rowData.email;
        const isByOfficial = rowData.byOfficialSource;
        const hasAQuestion = rowData.hasAQuestion;
        const enableAction = hasPictures && isValidUser && !isByOfficial && !hasAQuestion;

        const tooltip = (
          enableAction ? 'Consultar'
          : isByOfficial ? 'Solo es posible consultar en especies creadas'
          : !isValidUser ? 'Solo puedes consultar por tus registros'
          : !hasPictures ? 'Necesitas tener al menos una foto para consultar'
          : rowData.hasAQuestion && !rowData.answeredQuestion ? 'Ya hay una consulta en progreso'
          : ''
        );

        return {
          disabled: !enableAction,
          icon: () => <ContactSupport color={enableAction ? 'action' : 'disabled'} />,
          tooltip,
          onClick: () => actions.toggleDialog({
            data: { recordHash: rowData.hash, speciesName: rowData.scientificName, type: rowData.type },
            type: 'ask-a-question',
          }),
        };
      },
    ],

    rowData => {
      const ownRecord = rowData.email === currentUser.email;
      const deletionDisabled = !campaignInProgress ||
        (!coordinatesCampaign && !hasAdminPowers && !ownRecord) ||
        (hasOwnWorkFinished && !coordinatesCampaign && !hasAdminPowers);
      return {
        hidden: !campaignInProgress,
        disabled: deletionDisabled,
        icon: () => <Delete color="action" />,
        tooltip: deletionDisabled ? `No puedes borrar este registro` : 'Borrar',
        onClick: (event, rowData) => actions.toggleDialog({
          data: rowData,
          type: 'delete',
        }),
      };
    },
  ];

  const tableOptions = {
    rowStyle: rowData => isValidSpecies(rowData) ? {} : { color: theme.palette.common.white, backgroundColor: theme.palette.error.main },
    showTitle: reviewPoint,
    toolbar: false,
    idSynonym: 'id',
  };

  const isForestOrFaunaDirectRecord = recordType => checkers.isForestRecord(recordType) || checkers.isFaunaDirectRecord(recordType);

  const detailPanel = [
    rowData => {
      if (checkers.isForestRecord(rowData.type)) {
        rowData.trees.forEach(tree => {
          tree.phenoMaped = tree.phenologiesStatuses.map(psId =>
            phenologiesStatusesMap[psId]);
          tree.phytoMaped = tree.phytosanitaryStatuses.map(psId =>
            phytosanitaryStatusesMap[psId]);
        });
      }

      const ownRecord = rowData.email === currentUser.email;
      const editionDisabled = !campaignInProgress ||
        (hasOwnWorkFinished && !coordinatesCampaign && !hasAdminPowers) ||
        (!coordinatesCampaign && !hasAdminPowers && !ownRecord);
      return {
        disabled: !isForestOrFaunaDirectRecord(rowData.type),
        icon: () => <NavigateNext style={{ ...(isForestOrFaunaDirectRecord(rowData.type) ? {} : { display: 'none' }) }} />,
        openIcon: ExpandMore,
        ...(isForestOrFaunaDirectRecord(rowData.type) ? { tooltip: 'Ver detalle' } : {}),
        render: () => (
          checkers.isForestRecord(rowData.type) ?
            (rowData.trees.length > 0 ?
              <ForestTreesList record={rowData} actions={{ updateTree: actions.updateTree, toggleDialog: actions.toggleDialog }}
                hideEdition={editionDisabled} disabledEdition={editionDisabled} />
              :
              <Box ml={4} p={3}>
                <Box display="flex" alignItems="center">
                  <Typography variant="body2" color="textPrimary">
                    No existen agrupaciones de individuos forestales para este registro forestal, puedes crear uno haciendo clic en el botón
                  </Typography>
                  <Box ml={1}>
                    <Button variant="outlined" size="small" color="primary"
                      onClick={() => actions.toggleDialog({ data: rowData, type: 'create-tree' })}>
                      Crear agrupación forestal
                    </Button>
                  </Box>
                </Box>
              </Box>
            )
            : checkers.isFaunaDirectRecord(rowData.type) ?
              (rowData.individuals.length > 0 ?
                <DirectIndividualsList
                  directRecord={rowData}
                  options={options}
                  actions={{ toggleDialog: actions.toggleDialog }}
                  hideEdition={editionDisabled}
                  disabledEdition={editionDisabled}
                  hideDeletion={editionDisabled}
                  disabledDeletion={editionDisabled}
                /> :
                <Box ml={4} p={3}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="body2" color="textPrimary">
                      No existen individuos detallados para este registro directo, puedes crear uno haciendo clic en el botón
                    </Typography>
                    <Box ml={1}>
                      <Button variant="outlined" size="small" color="primary"
                        onClick={() => actions.toggleDialog({ data: rowData, type: 'create-individual' })}>
                        Crear individuo
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )
              : false
        ),
      };
    },
  ];

  const handleData = async query => {
    const records = await actions.getRecords({ page: query.page + 1, size: query.pageSize });
    records.forEach(r => {
      // Note: necesario para que sea única la id en la "row" de la tabla, se guarda el valor original en recordId
      r.recordId = r.id;
      r.id = `${r.type}-${r.id}`;
      checkers.isFloraIndividualRecord(r.type) && (r.utm = wgs84ToUTM(r));
    });
    const campaignCounter = await campaignsApi.getCampaignCounter(campaignHash);
    setCampaignCounter(campaignCounter);

    return ({
      data: records,
      page: query.page,
      totalCount: records[0]?.totalRecordsCount || 0,
    });
  };

  return (
    <Box width="100%">
      <TableWrapper
        tableRef={tableRef}
        columns={tableColumns}
        data={handleData}
        actions={tableActions}
        options={tableOptions}
        detailPanel={detailPanel}
        {...(reviewPoint ? { title: 'Registros' } : {})}
        useUrlPagination
      />
    </Box>
  );
};

RecordsList.propTypes = {
  campaignInProgress: PropTypes.bool.isRequired,
  tableRef: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  reviewPoint: PropTypes.bool,
  coordinatesCampaign: PropTypes.bool,
  hasAdminPowers: PropTypes.bool,
  currentUser: PropTypes.object.isRequired,
  forceReview: PropTypes.bool,
  userIsFan: PropTypes.bool,
  hasOwnWorkFinished: PropTypes.bool,
  userRole: PropTypes.string,
  campaignSubtypeId: PropTypes.string,
  options: PropTypes.object,
  setCampaignCounter: PropTypes.func.isRequired,
  censusColumns: PropTypes.array,
};


export { RecordsList };