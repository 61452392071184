import React, { useState, useMemo } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { makeStyles } from '@material-ui/core/styles';
import { Button, DialogTitle, DialogContent, DialogActions, Box, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';


const useStyles = makeStyles(() => ({
  originalDisplay: {
    maxHeight: ({ maxSizeDisplay }) => `${maxSizeDisplay}px !important`,
    maxWidth: ({ maxSizeDisplay }) => `${maxSizeDisplay}px !important`,
  },
  previewDisplayHeight: {
    height: ({ maxSizeDisplay }) => `${maxSizeDisplay}px`,
  },
  previewDisplayWidth: {
    width: ({ maxSizeDisplay }) => `${maxSizeDisplay}px`,
  },
  imageHeight: {
    width: 'auto',
    height: '100%',
    maxHeight: ({ maxSizeDisplay }) => `${maxSizeDisplay}px`,
  },
  imageWidth: {
    height: 'auto',
    width: '100%',
    maxWidth: ({ maxSizeDisplay }) => `${maxSizeDisplay}px`,
  },
  fullContainer: {
    width: '100%',
  },
  middleWidth: {
    width: '50%',
  },
}));

const CropPhoto = ({ baseImage, onSave, onCancel, maxSizeDisplay = 250 }) => {
  const classes = useStyles({ maxSizeDisplay });
  const [ croppedImage, setCroppedImage ] = useState();
  const [ cropArea, setCropArea ] = useState({
    unit: '%',
    x: 0,
    y: 0,
    width: 40,
    height: 40,
  });

  const [ imageToCrop, setImageToCrop ] = useState({ image: baseImage, edited: false });

  const onCropComplete = async cropArea => {
    const bitmap = await createImageBitmap(imageToCrop.image);
    if (imageToCrop && cropArea.width && cropArea.height) {
      const croppedImageUrl = await getCroppedImg(bitmap, cropArea, imageToCrop.image.name);
      // La imagen blobeada no da datos como width y height así no-más, así que guardamos las dimensiones para nuestra conveniencia
      const cropWidthInPx = (cropArea.unit === '%' ? bitmap.width * (cropArea.width / 100.0) : cropArea.width);
      const cropHeightInPx = (cropArea.unit === '%' ? bitmap.height * (cropArea.height / 100.0) : cropArea.height);

      setCroppedImage({ image: croppedImageUrl, width: cropWidthInPx, height: cropHeightInPx });
    }
  };

  const getCroppedImg = async (image, cropArea, fileName) => {
    const maxDisplayDimension = Math.max(image.width, image.height) > maxSizeDisplay ? maxSizeDisplay : Math.max(image.width, image.height);
    const imageRatio = image.width > image.height ? maxDisplayDimension / image.width : maxDisplayDimension / image.height;
    const displayHeigth = image.height * imageRatio;
    const displayWidth = image.width * imageRatio;
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = displayWidth / image.width;
    const scaleY = displayHeigth / image.height;
    const ctx = canvas.getContext('2d');
    const cropWidthInPx = (cropArea.unit === '%' ? image.width * (cropArea.width / 100.0) : cropArea.width);
    const cropHeightInPx = (cropArea.unit === '%' ? image.height * (cropArea.height / 100.0) : cropArea.height);
    canvas.width = cropWidthInPx * pixelRatio / scaleX;
    canvas.height = cropHeightInPx * pixelRatio / scaleY;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);

    ctx.drawImage(
      image,
      cropArea.x / scaleX,
      cropArea.y / scaleY,
      cropWidthInPx / scaleX,
      cropHeightInPx / scaleY,
      0,
      0,
      cropWidthInPx / scaleX,
      cropHeightInPx / scaleY,
    );

    return new Promise(resolve => {
      canvas.toBlob(
        blob => {
          if (!blob) {
            return;
          }
          blob.name = fileName;
          resolve(blob);
        },
        'image/jpeg',
        1,
      );
    });
  };

  const revertCrop = () => {
    setImageToCrop({ image: baseImage, edited: false });
    setCropArea();
  };

  const doCrop = () => {
    setImageToCrop({ image: croppedImage.image, edited: true });
    setCropArea();
  };

  const saveCrop = async () => {
    await onSave(imageToCrop.image);
  };

  const currentImgSrc = useMemo(() => URL.createObjectURL(imageToCrop.image), [ imageToCrop ]);
  const previewImgSrc = useMemo(() => croppedImage ? URL.createObjectURL(croppedImage.image) : undefined, [ croppedImage ]);

  return (
    <>
      <DialogTitle>Seleccionar área a recortar</DialogTitle>
      <DialogContent>
        <Box mb={1} justifyContent='center' display='flex' className={classes.fullContainer}>
          <Box mb={1} justifyContent='center' display='grid' className={classes.middleWidth}>
            <Box mb={1} justifyContent='center' display='grid'>
              <InputLabel>Imagen actual</InputLabel>
            </Box>
            <Box my={1} justifyContent='center'>
              { imageToCrop &&
                <ReactCrop crop={cropArea} onChange={c => setCropArea(c)} onComplete={ onCropComplete }>
                  <img alt="Imagen actual" src={currentImgSrc} className={classes.originalDisplay}/>
                </ReactCrop>
              }
            </Box>
          </Box>
          <Box mb={1} justifyContent='center' display='grid' className={classes.middleWidth}>
            {croppedImage &&
              <>
                <Box mb={1} justifyContent='center' display='grid'>
                  <InputLabel>Previsualización</InputLabel>
                </Box>
                <Box my={1}
                  className={croppedImage.width >= croppedImage.height ?
                    classes.previewDisplayWidth :
                    classes.previewDisplayHeight
                  }
                  justifyContent="center"
                >
                  <img alt="Previsualización de imagen recortada" src={previewImgSrc}
                    className={croppedImage.width >= croppedImage.height ? classes.imageWidth : classes.imageHeight}/>
                </Box>
              </>
            }
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="default">Cancelar</Button>
        <Button onClick={revertCrop} color="default" disabled={!imageToCrop.edited}>Restaurar</Button>
        <Button onClick={doCrop}>
          Recortar
        </Button>
        <Button color="primary" onClick={saveCrop}>
          Guardar
        </Button>
      </DialogActions>
    </>
  );
};

CropPhoto.propTypes = {
  baseImage: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  maxSizeDisplay: PropTypes.number,
};


export { CropPhoto };