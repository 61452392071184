import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemText, ListSubheader, ListItemIcon, Divider, Typography, Paper, Icon } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Map, Grain, Spa, Description, ChevronLeft, ChevronRight } from '@material-ui/icons';

import { useHistory, useLocation } from 'react-router';
import { DrawerWrapper } from 'src/components';
import { truncateWithEllipsis as twe } from 'src/utils/util';


const useStyles = makeStyles(theme => ({
  titleBox: {
    height: '93px',
    textOverflow: 'ellipsis',
  },
  title: {
    color: theme.palette.common.gray800,
    lineHeight: 1.25,
    fontWeight: 500,
  },
  overTitle: {
    lineHeight: 1,
  },
  listItemSelected: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: theme.palette.common.white,
  },

  openDrawer: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: () => 0,
  },
  closeDrawer: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: ({ width }) => -width,
  },
  content: {
    position: 'fixed',
    top: '50%',
    marginLeft: ({ width }) => width,
  },
  toggleButton: {
    padding: 1,
  },
  toggleDrawerButton: {
    height: theme.spacing(9),
    borderRadius: 0,
    borderTopRightRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
}));

const itemList = [
  {
    Icon: Map,
    label: 'Monitoreo',
    endPath: 'monitoring',
    index: 0,
  },
  {
    Icon: Grain,
    label: 'Estaciones replica',
    endPath: 'replica-stations',
    index: 1,
  },
  {
    Icon: Spa,
    label: 'Ocurrencias',
    endPath: 'occurrences',
    index: 2,
  },
  {
    Icon: Description,
    label: 'Descargas',
    endPath: 'downloadables',
    index: 3,
  },
];

const getIndex = currentPathname => itemList.findIndex(item => item.endPath === currentPathname.split('/').pop());

const SmaCampaignDrawer = ({ campaignName, width, actions, isOpenDrawer, isBaseline }) => {
  const classes = useStyles({ width });
  const history = useHistory();
  const { pathname } = useLocation();

  const [ selectedIndex, setSelectedIndex ] = useState(getIndex(pathname));

  const handleSelectedIndex = index => () => {
    history.push(itemList[index].endPath);
    setSelectedIndex(index);
  };

  useEffect(() => {
    setSelectedIndex(getIndex(pathname));
  }, [ pathname ]);

  const commonListItemProps = { button: true, component: 'a', classes: { selected: classes.listItemSelected } };

  const itemListFiltered = isBaseline ? itemList.filter(i => ![ 'replica-stations', 'monitoring' ].includes(i.endPath)) : itemList;

  return (
    <Box className={isOpenDrawer ? classes.openDrawer : classes.closeDrawer}>
      <DrawerWrapper width={width} isDrawerOpen={isOpenDrawer}>
        <Box mt={12}>
          <Box px={2} py={1} display="flex" flexDirection="column" justifyContent="center" className={classes.titleBox}>
            <Typography variant="caption" className={classes.overTitle}>Campaña</Typography>
            <Typography className={classes.title} variant="body1">{twe(campaignName, 70)}</Typography>
          </Box>
          <Divider />
          <List>
            <ListSubheader color="primary">Acciones de la campaña</ListSubheader>
            {itemListFiltered.map(({ Icon, label, index }) => (
              <ListItem
                key={index}
                selected={selectedIndex === index}
                onClick={handleSelectedIndex(index)} {...commonListItemProps}
              >
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={label} />
              </ListItem>
            ))}
          </List>
        </Box>
      </DrawerWrapper>
      <Box className={classes.content}>
        <Paper onClick={actions.toggleDrawer} className={classes.toggleDrawerButton}>
          <Icon className={classes.toggleButton}>
            {isOpenDrawer ? <ChevronLeft /> : <ChevronRight />}
          </Icon>
        </Paper>
      </Box>
    </Box>
  );
};

SmaCampaignDrawer.propTypes = {
  campaignName: PropTypes.string,
  actions: PropTypes.object,
  width: PropTypes.number,
  isOpenDrawer: PropTypes.bool,
  isBaseline: PropTypes.bool,
};


export { SmaCampaignDrawer };