import React from 'react';
import L from 'leaflet';

import { getMarkerIcon } from 'src/components/map/mapHelper';
import { renderToString } from 'react-dom/server';
import { Delete } from '@material-ui/icons';


const mapOptions = {
  center: [ -34.20113384085422, -71.4305002099928 ],
  zoomControl: true,
  dragging: false,
  scrollWheelZoom: false,
  zoom: 14,
  doubleClickZoom: false,
  attributionControl: false,
};

const interestAreaPolygons = [
  {
    'id': '33',
    'name': 'Poligono_Cerro',
    'geojson': [
      {
        'type': 'Feature',
        'geometry': {
          'type': 'Polygon',
          'coordinates': [
            [
              [
                -71.44050020999285,
                -34.20313384085422,
                0,
              ],
              [
                -71.4441663194387,
                -34.20635029292949,
                0,
              ],
              [
                -71.443778586956,
                -34.20660281567452,
                0,
              ],
              [
                -71.44343471135187,
                -34.20676176027646,
                0,
              ],
              [
                -71.44312882711375,
                -34.2069992673135,
                0,
              ],
              [
                -71.44284138860634,
                -34.20723535954316,
                0,
              ],
              [
                -71.44252445888736,
                -34.20752187354876,
                0,
              ],
              [
                -71.44218403914721,
                -34.20775513353852,
                0,
              ],
              [
                -71.4396375698022,
                -34.20537233470802,
                0,
              ],
              [
                -71.4393496117737,
                -34.20568451140952,
                0,
              ],
              [
                -71.43918923139464,
                -34.20585973507868,
                0,
              ],
              [
                -71.4390040810578,
                -34.20594038317829,
                0,
              ],
              [
                -71.43874492813876,
                -34.20590064328057,
                0,
              ],
              [
                -71.43840362778462,
                -34.2058348413524,
                0,
              ],
              [
                -71.43807885612443,
                -34.20607624920578,
                0,
              ],
              [
                -71.43786993119353,
                -34.20631613916849,
                0,
              ],
              [
                -71.44072683499571,
                -34.20841019854783,
                0,
              ],
              [
                -71.44057648191112,
                -34.20849941085339,
                0,
              ],
              [
                -71.44007657079356,
                -34.2086012318164,
                0,
              ],
              [
                -71.4395357290148,
                -34.20862671743826,
                0,
              ],
              [
                -71.43901761189507,
                -34.20876608872553,
                0,
              ],
              [
                -71.43868717745059,
                -34.20894263605523,
                0,
              ],
              [
                -71.4384174668337,
                -34.20915582218477,
                0,
              ],
              [
                -71.43826499036481,
                -34.2095019264595,
                0,
              ],
              [
                -71.43689077013431,
                -34.20819351497632,
                0,
              ],
              [
                -71.43563504210206,
                -34.20696592116495,
                0,
              ],
              [
                -71.43536391176768,
                -34.20693519807484,
                0,
              ],
              [
                -71.43486886511243,
                -34.20687431048227,
                0,
              ],
              [
                -71.43426643090761,
                -34.20665158143185,
                0,
              ],
              [
                -71.43373909043031,
                -34.206351065185,
                0,
              ],
              [
                -71.43311501264718,
                -34.20599934434512,
                0,
              ],
              [
                -71.43274422716023,
                -34.20566124380414,
                0,
              ],
              [
                -71.43418178784678,
                -34.20469447832082,
                0,
              ],
              [
                -71.43281831943725,
                -34.20337784220183,
                0,
              ],
              [
                -71.43183412452268,
                -34.20244944498603,
                0,
              ],
              [
                -71.43026082715548,
                -34.20074528684367,
                0,
              ],
              [
                -71.42782175188717,
                -34.19839222285137,
                0,
              ],
              [
                -71.42639796926251,
                -34.19703098734997,
                0,
              ],
              [
                -71.42588910762798,
                -34.19656073753219,
                0,
              ],
              [
                -71.42510745040832,
                -34.19580416123471,
                0,
              ],
              [
                -71.42436386822504,
                -34.19517946427448,
                0,
              ],
              [
                -71.42516101574043,
                -34.19434919190529,
                0,
              ],
              [
                -71.42547637033233,
                -34.19399781183076,
                0,
              ],
              [
                -71.42603586397976,
                -34.19315908157519,
                0,
              ],
              [
                -71.42623965292907,
                -34.1927254012837,
                0,
              ],
              [
                -71.42623452394386,
                -34.19259044154006,
                0,
              ],
              [
                -71.42613407533507,
                -34.19195379244184,
                0,
              ],
              [
                -71.4261906301208,
                -34.19157245878896,
                0,
              ],
              [
                -71.42712341225933,
                -34.19174903739636,
                0,
              ],
              [
                -71.42734553999969,
                -34.1916885587583,
                0,
              ],
              [
                -71.42753662810088,
                -34.19164262902451,
                0,
              ],
              [
                -71.42797335685019,
                -34.19185987824923,
                0,
              ],
              [
                -71.42900361383731,
                -34.19171975937512,
                0,
              ],
              [
                -71.42980123936219,
                -34.19141169985096,
                0,
              ],
              [
                -71.4302419885896,
                -34.19121374193073,
                0,
              ],
              [
                -71.43064915142237,
                -34.1910634242216,
                0,
              ],
              [
                -71.4314292334229,
                -34.19244405615949,
                0,
              ],
              [
                -71.43203922836089,
                -34.19332122071719,
                0,
              ],
              [
                -71.43268781804149,
                -34.19439516677475,
                0,
              ],
              [
                -71.43312513579164,
                -34.19410098036202,
                0,
              ],
              [
                -71.43344116512442,
                -34.19458146525466,
                0,
              ],
              [
                -71.44050020999285,
                -34.20313384085422,
                0,
              ],
            ],
          ],
        },
        'properties': {
          'name': 'Polígono Cardal Cerro',
          'styleUrl': '#inline0',
          'styleHash': '-26ed16fa',
          'styleMapHash': {
            'normal': '#inline1',
            'highlight': '#inline',
          },
          'stroke-opacity': 1,
          'stroke': '#ff0000',
          'stroke-width': 2,
          'fill-opacity': 0,
        },
      },
    ],
  },
];

const interestAreaLayers = interestAreaPolygons.map(layer => L.geoJSON(layer.geojson, {
  id: layer.id,
  name: layer.name,
}));


const plotsMarkers = [
  { number: 1, color: 'green', coords: [ -34.19629643073853, -71.44246101379396 ] },
  { number: 2, color: 'green', coords: [ -34.20077080145103, -71.44143104553224 ] },
  { number: 3, color: 'grey', coords: [ -34.20105488046363, -71.4283847808838 ] },
  { number: 4, color: 'grey', coords: [ -34.1985336457759, -71.42834186553956 ] },
].map(({ number, color, coords }) => new L.Marker(coords, { icon: getMarkerIcon(number, color) }));

const layers = [ ...interestAreaLayers, ...plotsMarkers ];



const users = [ { name: 'John Lara', color: 'green', pointsCount: 5 } ];

const getLegend = () => {

  const legend = L.control({ position: 'bottomleft' });
  legend.onAdd = () => {

    const legendContainer = L.DomUtil.create('div', 'info bcw-legend nv-legend-small');

    const pointsTitle = L.DomUtil.create('div', '', legendContainer);
    pointsTitle.innerHTML = `<strong>Parcelas</strong>`;
    users.forEach(v => {
      const item = L.DomUtil.create('div', '', legendContainer);
      item.innerHTML = `
        <div class="bcw-legend-element">
          <div class="bcw-legend-box" style="background-color: ${v.color}; border-color: ${v.color}"></div>
          <div>
            <span>${v.name} (${v.pointsCount})</span>
          </div>
        </div>`;
    });

    const kmlTitle = L.DomUtil.create('div', '', legendContainer);
    kmlTitle.innerHTML = `<strong>Área de interés</strong>`;

    interestAreaPolygons.forEach(layer => {
      const item = L.DomUtil.create('div', 'bcw-legend-element', legendContainer);

      const checkbox = L.DomUtil.create('div', '', item);
      checkbox.innerHTML = `<div class="bcw-legend-box" style="background-color: blue; border-color: blue"></div>`;
      const layerTitle = L.DomUtil.create('div', '', item);
      layerTitle.innerHTML = `<div><span class="bcw-legend-title">${layer.name}</span></div>`;
      const delBtn = L.DomUtil.create('div', 'bcw-legend-del-kml', item);
      delBtn.innerHTML = renderToString(<Delete fontSize="small" style={{ fontSize: '1rem', marginLeft: '2px' }} />);
    });

    return legendContainer;
  };

  return legend;
};

const legend = getLegend();


export { mapOptions, layers, legend };