import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemText, ListSubheader, ListItemIcon, Divider, Typography, Avatar } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { CreditCard, Link } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router';
import urlJoin from 'url-join';
import clsx from 'clsx';

import config from 'src/config/local';
import { DrawerWrapper } from 'src/components';
import { truncateWithEllipsis as twe } from 'src/utils/util';
import { MonitorDashboard } from 'src/utils/mdIcons';

const { serverUrl } = config;


const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: 'transparent !important',
    border: `1px solid ${theme.palette.common.gray}`,
    height: '32px',
    width: '32px',
    marginRight: theme.spacing(1),
  },
  titleBox: {
    height: '93px',
    textOverflow: 'ellipsis',
  },
  title: {
    color: theme.palette.common.gray800,
    lineHeight: 1.25,
    fontWeight: 500,
    fontSize: '20px',
  },
  overTitle: {
    lineHeight: 1,
  },
  listItemSelected: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: theme.palette.common.white,
  },
  listItem: {
    borderRadius: '6px',
  },
  list: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  listItemIcon: {
    minWidth: '36px',
  },
}));

const itemList = [
  {
    Icon: MonitorDashboard,
    label: 'Perfil de empresa',
    endPath: 'profile',
  },
  {
    Icon: CreditCard,
    label: 'Suscripción',
    endPath: 'suscription',
  },
  {
    Icon: Link,
    label: 'Vinculos con Monitor',
    endPath: 'linked-apps',
    subPaths: [ 'allowed-campaigns' ],
  },
];

const getIndex = currentPathname => itemList.findIndex(item => {
  const lastString = currentPathname.split('/').pop();
  return item.endPath === lastString || item.subPaths?.includes(lastString);
});

const CompanySettingsDrawer = ({ company }) => {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();

  const [ selectedIndex, setSelectedIndex ] = useState(getIndex(pathname));

  const baseUrl = `/web/company`;

  const handleSelectedIndex = index => () => {
    history.push(urlJoin(baseUrl, itemList[index].endPath));
    setSelectedIndex(index);
  };

  useEffect(() => {
    setSelectedIndex(getIndex(pathname));
  }, [ pathname ]);

  const commonListItemProps = { button: true, component: 'a', classes: { root: classes.listItem, selected: classes.listItemSelected } };

  const getCompanyLetters = companyName => {
    if (companyName) {
      const [ firstLetter, secondLetter = ' ' ] = companyName.split(' ');
      return `${firstLetter[0].toUpperCase()}${secondLetter[0].toUpperCase().trim()}`;
    }
    return false;
  };

  return (
    <DrawerWrapper>
      <Box mt={12}>
        <Box px={2} py={1} display="flex" flexDirection="column" justifyContent="center" className={classes.titleBox}>
          <Typography variant="caption" className={classes.overTitle}>Compañía</Typography>
          <Box display="flex" alignItems="center" mt={1}>
            <Avatar className={clsx({ [classes.avatar]: Boolean(company.image) })}
              {...(company.image ? { src: urlJoin(serverUrl, company.image) } : {})}
            >
              {getCompanyLetters(company.name)}
            </Avatar>
            <Typography className={classes.title} variant="body1">{twe(company.name, 70)}</Typography>
          </Box>
        </Box>
        <Divider />
        <List className={classes.list}>
          <ListSubheader color="primary">Opciones</ListSubheader>
          {itemList.map(({ Icon, label }, itemIndex) => (
            <ListItem
              key={itemIndex}
              selected={selectedIndex === itemIndex}
              onClick={handleSelectedIndex(itemIndex)} {...commonListItemProps}
            >
              <ListItemIcon classes={{ root: classes.listItemIcon }}>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={label} />
            </ListItem>
          ))}
        </List>
      </Box>
    </DrawerWrapper>
  );
};

CompanySettingsDrawer.propTypes = {
  company: PropTypes.object,
};


export { CompanySettingsDrawer };