import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { CoolBackground } from 'src/scenes/Home/components';


const useStyles = makeStyles(theme => ({
  container: {
    height: '100vh',
  },
  grid: {
    zIndex: 0,
  },
  paper: {
    paddingRight: theme.spacing(12),
    paddingLeft: theme.spacing(12),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    width: '344px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      width: '100%',
    },
  },
  title: {
    color: theme.palette.common.gray600,
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '1.125rem',
  },
}));

const MaintenanceContainer = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.container} direction="column" container alignItems="center" justifyContent="center">
      <CoolBackground />
      <Grid item className={classes.grid} container justifyContent="center">
        <Paper className={classes.paper} elevation={5}>
          <Typography className={classes.title}>
            Nuestro servidor se encuentra en mantención, intente más tarde.
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};


export {
  MaintenanceContainer,
};