import React from 'react';
import { Box, Typography, Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

import { FeaturesTabs, Benefit } from 'src/scenes/Home/scenes/CoverPage/components';
import { FloraAppRecordsCompositionUI, FloraAppCampaignsPageUI,
  PlanningUI, MonitoringUI, AssignPointsUI, FloraAppMapPageUI, ReviewPointsUI, DownloadablesUI } from 'src/scenes/Home/components';
import { StatisticsCompositionUI } from 'src/scenes/Home/components/UI/StatisticsCompositionUI';


const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '4.375rem',
    fontWeight: 'bold',
    lineHeight: '90px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.75rem',
      lineHeight: '58px',
    },
  },
  benefitsSectionTitle: {
    paddingBottom: theme.spacing(10),
    color: theme.palette.common.black,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      paddingBottom: theme.spacing(4),
    },
  },
  featuresTabsContainer: {
    minHeight: '540px',
    alignItems: 'center',
  },

  gridItem: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },

  gridItem1: {
    order: 1,
  },
  gridItem2: {
    order: 2,
  },
  gridItem3: {
    order: 4,
    [theme.breakpoints.down('sm')]: {
      order: 3,
    },
  },
  gridItem4: {
    order: 3,
    [theme.breakpoints.down('sm')]: {
      order: 4,
    },
  },
  gridItem5: {
    order: 5,
  },
  gridItem6: {
    order: 6,
  },
  mobileComponent: {
    transform: 'scale(0.85)',
  },
}));

const benefits = {
  optimize: {
    title: 'OPTIMIZAR LA GESTIÓN DE TU PROYECTO',
    description: 'Una solución completa, desde la planificación de un proyecto ambiental hasta la entrega de resultados',
    link: 'how-it-work',
  },
  ease: {
    title: 'FACILITAR EL TRABAJO EN TERRENO',
    description: 'Organiza la ejecución de un proyecto y mejora los procesos de tu empresa',
    link: 'how-it-work',
  },
  reduce: {
    title: 'PROCESAR Y DESCARGAR TUS DATOS',
    description: `Descarga los datos para ser utilizados en tus informes de línea base, monitoreo o seguimiento
      bajo los estándares que exige la autoridad ambiental`,
    link: 'how-it-work',
  },
};

const BenefitsSection = () => {

  const classes = useStyles();
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Grid item xs={12} className={clsx(classes.gridItem, classes.benefitsSectionTitle)}>
        <Typography className={classes.title}>Con Nviro Capture puedes...</Typography>
      </Grid>
      <Grid item xs={12} md={6} className={clsx(classes.gridItem, classes.gridItem1)}>
        <Box display="flex" justifyContent="center" height="100%">
          <Benefit {...benefits.optimize} />
        </Box>
      </Grid>
      <Grid item xs={12} md={6} className={clsx(classes.gridItem, classes.featuresTabsContainer, classes.gridItem2)}>
        <FeaturesTabs
          tabs={[
            {
              id: 'planning',
              label: 'Planificación de terreno',
              Component: isDownSm ? () =>
                <Box className={classes.image} mt={10}>
                  <img width="100%" src="/img/tmp/planning-1.png" alt="planificación de terreno" />
                </Box> : PlanningUI,
            },
            {
              id: 'plots',
              label: 'Asignación de trabajo',
              Component: () =>
                <Box className={classes.assignPoint} mt={isDownSm ? -9 : 0}>
                  <AssignPointsUI />
                </Box>,
            },
            {
              id: 'monitoring',
              label: 'Monitoreo de avance',
              Component: isDownSm ? () =>
                <Box className={classes.image} mt={10}>
                  <img width="100%" src="/img/tmp/monitoring-1.png" alt="monitoreo de parcelas" />
                </Box> : MonitoringUI,
            },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={6} className={clsx(classes.gridItem, classes.gridItem3)}>
        <Box display="flex">
          <Benefit {...benefits.ease} />
        </Box>
      </Grid>
      <Grid item xs={12} md={6} order={{ xs: 0, md: 1 }} className={clsx(classes.featuresTabsContainer, classes.gridItem4)}>
        <FeaturesTabs
          tabs={[
            {
              id: 'catch-records',
              label: 'Captura de especies',
              Component: () =>
                <Box display="flex" width="100%" justifyContent="center">
                  <FloraAppRecordsCompositionUI />
                </Box>,
            },
            {
              id: 'explore-plots',
              label: 'Explora tus parcelas',
              Component: () =>
                <Box className={clsx({ [classes.mobileComponent]: isDownSm })} display="flex" width="100%" justifyContent="center">
                  <FloraAppMapPageUI />
                </Box>,
            },
            {
              id: 'review-campaigns',
              label: 'Campañas asignadas',
              Component: () =>
                <Box className={clsx({ [classes.mobileComponent]: isDownSm })} display="flex" width="100%" justifyContent="center">
                  <FloraAppCampaignsPageUI />
                </Box>,
            },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={6} className={clsx(classes.gridItem, classes.gridItem5)}>
        <Box display="flex" justifyContent="center">
          <Benefit {...benefits.reduce} />
        </Box>
      </Grid>
      <Grid item xs={12} md={6} className={clsx(classes.gridItem, classes.featuresTabsContainer, classes.gridItem6)}>
        <Box>
          <FeaturesTabs
            tabs={[
              {
                id: 'records-checker',
                label: 'Corrige tus registros',
                Component: ReviewPointsUI,
              },
              {
                id: 'downloadables',
                label: 'Informes automatizados',
                Component: DownloadablesUI,
              },
              {
                id: 'look-stats',
                label: 'Mira estadísticas',
                Component: StatisticsCompositionUI,
              },
            ]}
          />
        </Box>
      </Grid>
    </>
  );
};


export { BenefitsSection };
