import { get, del, post, patch } from 'src/services/api';

const getProject = async projectId => (await get(`project/${projectId}`)).data;

const getProjects = async queryParams => (await get('project', queryParams)).data;

const editProject = (projectId, projectToEdit) => patch(`project/${projectId}`, projectToEdit);

const deleteProject = projectId => del(`project/${projectId}`);

const createProject = async newProject => (await post('project', newProject)).data;


export {
  getProject,
  getProjects,
  editProject,
  deleteProject,
  createProject,
};
