import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { upperCaseFirstLetter } from 'src/utils/formatters';
import { Button, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';


const DirectIndividualDeleteDialog = ({ individual, actions }) => {
  const upSpeciesName = upperCaseFirstLetter(individual.scientificName);

  return (
    <Fragment>
      <DialogTitle id="form-dialog-title">Eliminar Individuo</DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="textPrimary">
          Estás por eliminar al individuo número { individual.index } de este registro de la
          especie <strong><i>{ upSpeciesName }</i></strong>, esta acción es irreversible y
          <Typography component='span' display="inline" color="error"> perderás todos los datos</Typography> asociados al individuo.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.toggleDialog}>Cancelar</Button>
        <Button onClick={ () => actions.deleteIndividual({ individual })} color="primary">
          Confirmar
        </Button>
      </DialogActions>
    </Fragment>
  );
};

DirectIndividualDeleteDialog.propTypes = {
  individual: PropTypes.object,
  actions: PropTypes.object,
};


export { DirectIndividualDeleteDialog };