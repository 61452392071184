import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, DialogTitle, DialogContent, DialogActions, Collapse, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';

import { DialogButton } from 'src/components';
import { fetchStatusEnum } from 'src/utils/enums';


const useStyles = makeStyles(theme => ({
  deleteButton: {
    color: theme.palette.danger.main,
  },
}));

const { NOT_STARTED, LOADING, ERROR } = fetchStatusEnum;

const DeleteAccountDialog = ({ actions }) => {

  const styles = useStyles();

  const [ fetchStatus, setFetchStatus ] = useState(NOT_STARTED);
  const [ errorAlert, setErrorAlert ] = useState({ isOpen: false, message: '' });

  const handleSubmit = async () => {
    setFetchStatus(LOADING);
    try {
      await actions.deleteAccount();
      // no es necesario cambiar el estado a SUCCESS porque salimos de la página de inmediato
    } catch (e) {
      setErrorAlert({ isOpen: true, message: e.serverMessage || e.message });
      setFetchStatus(ERROR);
    }
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">Eliminación de cuenta</DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="textPrimary">
          Al eliminar tu cuenta <strong>no podrás</strong>:<br />
        </Typography>
        <ul>
          <li><Typography variant="body1" color="textPrimary">
            <strong>Trabajar en campañas</strong> de ninguna empresa a la que estés vinculado
          </Typography></li>
          <li><Typography variant="body1" color="textPrimary">
            <strong>Acceder a tus datos</strong> personales sincronizados
          </Typography></li>
          <li><Typography variant="body1" color="textPrimary">
            <strong>Tomar datos</strong> en las aplicaciones móviles
          </Typography></li>
        </ul>
        <br />
        <Typography variant="body1" color="textPrimary">
          ¿Realmente deseas eliminar tu cuenta?
        </Typography>
        {fetchStatus === ERROR &&
          <Collapse in={errorAlert.isOpen}>
            <Alert severity="error" onClose={() => setErrorAlert(s => ({ ...s, isOpen: false }))}>
              {errorAlert.message}
            </Alert>
          </Collapse>
        }
      </DialogContent>
      <DialogActions>
        <Button disabled={ fetchStatus === LOADING } onClick={actions.closeDialog}>No</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={ handleSubmit } className={ styles.deleteButton }>Sí, eliminar</DialogButton>
      </DialogActions>
    </>
  );
};

DeleteAccountDialog.propTypes = {
  actions: PropTypes.object,
};


export {
  DeleteAccountDialog,
};
