import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography } from '@material-ui/core';

import formatters from 'src/utils/formatters';
import { PopupObservation } from 'src/scenes/Campaign/scenes/Monitoring/components/PopupObservation';


const IsolatedRecordPopup = ({ isolatedRecord, options }) => {
  const { ageStratumId, sexId, email, observation, scientificName } = isolatedRecord;
  const { sexTypesOptions, ageStratumsOptions } = options;
  const sexTypeLabel = sexTypesOptions?.find(({ value }) => value === sexId)?.label;
  const ageStratumLabel = ageStratumsOptions?.find(({ value }) => value === ageStratumId)?.label;
  return <Fragment>
    <Card elevation={0}>
      <CardContent style={{ padding: '5px' }}>
        <Typography variant="h5" style={{ fontStyle: 'italic' }}>
          {formatters.upperCaseFirstLetter(scientificName)}
        </Typography>
        <Typography variant="body2" style={{ margin: '1px 0', fontSize: '14px' }} color="textPrimary">
          {email}
        </Typography>
        <Typography style={{ marginTop: '15px', marginBottom: '-10px' }} variant="overline" component="p">
          Estrato etario: {ageStratumLabel}
        </Typography>
        <Typography style={{ marginTop: '-10px' }} variant="overline" component="p">
          Sexo: {sexTypeLabel}
        </Typography>
        <PopupObservation observation={observation}/>
      </CardContent>
    </Card>
  </Fragment>;
};

IsolatedRecordPopup.propTypes = {
  isolatedRecord: PropTypes.object,
  options: PropTypes.object,
};


export {
  IsolatedRecordPopup,
};
