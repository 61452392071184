import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import formatters from 'src/utils/formatters';
import { PopupObservation } from 'src/scenes/Campaign/scenes/Monitoring/components/PopupObservation';


const useStyles = makeStyles(theme => ({
  cardContent: {
    padding: theme.spacing(0.5),
  },
  family: {
    fontStyle: 'italic',
  },
  genus: {
    fontStyle: 'italic',
  },
  email: {
    fontSize: '14px',
  },
}));
const CensusRecordPopup = ({ censusRecord }) => {
  const classes = useStyles();

  if (!censusRecord) {
    return <></>;
  }

  const { email, observation, family = '', genus = '', species, subspecies, variety, code } = censusRecord;

  return <>
    <Card elevation={0}>
      <CardContent className={ classes.cardContent }>
        <Box mt={0}>
          <Typography className={ classes.family } color="textPrimary" component="span">
            {family}
          </Typography>
        </Box>
        <Box mt={0}>
          <Typography className={classes.genus} variant="h5" component="h3">
            {genus.trim() ? formatters.upperCaseFirstLetter(genus) : ''} {species}
          </Typography>
        </Box>
        { subspecies && <Box mt={-0.5}>
          <Typography variant="overline">Subespecie: {subspecies}</Typography>
        </Box>}
        { variety && <Box mt={-0.5}>
          <Typography variant="overline">Variedad: {variety}</Typography>
        </Box>}
        <Box my={1.5}>
          { code && <Box>
            <Typography className={ classes.email } color="textPrimary" component="span">
              Código: {code}
            </Typography>
          </Box>
          }
          <Box my={0.5}>
            <Typography className={ classes.email } color="textPrimary" component="span">
              {email}
            </Typography>
          </Box>
        </Box>
        <Box mt={1}>
          <PopupObservation observation={observation}/>
        </Box>
      </CardContent>
    </Card>
  </>;
};

CensusRecordPopup.propTypes = {
  censusRecord: PropTypes.object,
};


export {
  CensusRecordPopup,
};
