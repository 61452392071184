import React, { useRef, useEffect, useState } from 'react';
import { Grid, Paper, Container, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { IndicatorUI, ChartUI, TableUI } from 'src/scenes/Home/components';
import { species, eecc, unrecognized, pointsTime, saturationCurve, generalPointsProgress, pointsTimeUser,
} from 'src/scenes/Home/components/UI/statisticsUIDataFaker';


const useStyles = makeStyles(theme => ({
  statisticsSection: {
    padding: theme.spacing(3),
  },
  rank: {
    height: '100%',
  },
}));

const StatisticsUI = ({ miniMode = false }) => {
  const classes = useStyles();
  const [ height, setHeight ] = useState(250);
  const [ heightDone, setHeightDone ] = useState(false);

  const ref = useRef();

  useEffect(() => {
    if (!miniMode || !heightDone) {
      setHeight(ref.current.clientHeight);
      setHeightDone(true);
    }
  // eslint-disable-next-line
  }, []);

  return (
    <Grid container className={classes.statisticsSection}>
      <Container maxWidth="xl">
        <Typography variant="h4" display="inline" color="primary">Estadísticas</Typography>
        <Divider />
        <br />
        <Grid container spacing={2} direction="column">
          <Grid container item justifyContent="center" spacing={2} xs={12}>
            <Grid item xs={ miniMode ? 4 : 3 }>
              <Paper>
                <IndicatorUI {...species} />
              </Paper>
            </Grid>
            <Grid item xs={ miniMode ? 4 : 3 }>
              <Paper>
                <IndicatorUI {...eecc} />
              </Paper>
            </Grid>
            { !miniMode && <Grid item xs={3}>
              <Paper>
                <IndicatorUI {...unrecognized}/>
              </Paper>
            </Grid> }
            <Grid item xs={ miniMode ? 4 : 3 }>
              <Paper>
                <IndicatorUI {...pointsTime} />
              </Paper>
            </Grid>
            <Grid item xs={6} ref={ref}>
              { (!miniMode || heightDone) && <Paper>
                <ChartUI { ...saturationCurve } height={ miniMode ? height : undefined} />
              </Paper> }
            </Grid>
            <Grid item xs={ miniMode ? 6 : 3 }>
              <Paper className={classes.rank}>
                <ChartUI { ...generalPointsProgress } height={height} />
              </Paper>
            </Grid>
            { !miniMode && <Grid item xs={3}>
              <Paper className={classes.rank}>
                <TableUI { ...pointsTimeUser } />
              </Paper>
            </Grid> }
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};


StatisticsUI.propTypes = {
  miniMode: PropTypes.bool,
};

export {
  StatisticsUI,
};