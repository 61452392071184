import React from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Check } from '@material-ui/icons';
import { useHistory } from 'react-router';

import { NviroButton } from 'src/components';


const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  paper: {
    width: '320px',
    margin: theme.spacing(2),
    padding: theme.spacing(4),
    height: 'fill-available',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: ({ disabled }) => disabled ? 0.8 : 1,
    filter: ({ disabled }) => disabled ? 'grayscale(1)' : 'unset',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
      marginBottom: theme.spacing(2),
      height: 'unset',
      width: '100%',
    },
  },
  title: {
    fontSize: '2.313rem',
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  description: {
    paddingTop: theme.spacing(3),
    fontSize: '1rem',
    textAlign: 'center',
  },
  priceText: {
    fontSize: '1.875rem',
  },
  freePriceText: {
    fontSize: '3rem',
    fontWeight: 600,
    lineHeight: 'normal',
    color: theme.palette.common.gray700,
  },
  priceNumber: {
    fontSize: '3.5rem',
    fontWeight: 'bold',
    lineHeight: 'normal',
  },
  feature: {
    fontSize: '1rem',
  },
  button: {
    height: '3rem',
    fontWeight: 'bold',
    width: theme.spacing(29),
  },
  icon: {
    fontWeight: 'bold',
  },
  boxSpace: {
    height: '32px',
  },
  featuresContainer: {
    height: '240px',
    [theme.breakpoints.down('sm')]: {
      height: 'unset',
    },
  },
}));

const PricingCard = ({
  title, description, price, button, note, releaseOffer = false, isYearly = false, features, disabled = false,
}) => {

  const classes = useStyles({ disabled });
  const history = useHistory();
  return (
    <Box className={classes.container} display="flex" alignItems="center">
      <Paper className={classes.paper} elevation={5}>
        <Typography color="primary" className={classes.title}>{title}</Typography>
        <Box><Typography color="textPrimary" className={classes.description}>{description}</Typography></Box>
        <Box pt={3} display="flex" alignItems="center" justifyContent="center">
          {price > 0 &&
            <>
              <Typography color="textPrimary" className={classes.priceText}>$&nbsp;</Typography>
              <Typography color="textPrimary" className={classes.priceNumber}>{isYearly ? price * 0.8 : price }</Typography>
            </>
          }
          {!isYearly && price > 0 && <Typography color="textPrimary" className={classes.priceText}>&nbsp;/ mes</Typography>}
          {price === 0 && <Typography color="textPrimary" className={classes.freePriceText}>Gratis</Typography>}
          {price === undefined && <Box className={classes.boxSpace} />}
        </Box>
        <Box pt={3}>
          <NviroButton disabled={price > 0 && releaseOffer} color={button.color} className={classes.button}
            onClick={() => history.push(button.link)}>
            {button.text}
          </NviroButton>
        </Box>
        {price === undefined && <Box className={classes.boxSpace} />}
        <Box display="flex" className={classes.featuresContainer}
          flexGrow={1} pt={4} alignItems="center" flexDirection="column" justifyContent="flex-start" maxWidth={'260px'}>
          {features.data.map((feat, index) =>
            <Box key={index} display="flex" py={1} width="100%" alignItems="center">
              <Box pr={2} display="flex" alignItems="center">
                <Check className={classes.icon} color="primary" />
              </Box>
              <Box flexGrow={1}>
                <Typography color="textPrimary" className={classes.feature}>{feat}</Typography>
              </Box>
            </Box>,
          )}
          <Box mt={2} flexGrow={1}>
            <Typography color="textPrimary" variant="caption">{note}</Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

PricingCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.number,
  button: PropTypes.object,
  note: PropTypes.string,
  isYearly: PropTypes.bool,
  features: PropTypes.object,
  releaseOffer: PropTypes.bool,
  disabled: PropTypes.bool,
};


export { PricingCard };