import React from 'react';
import { Box, Typography, Paper } from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { Computer, Person, LocalFlorist } from '@material-ui/icons';
import L from 'leaflet';
import config from 'src/config/local';

import { Paw } from 'src/utils/mdIcons';
import { MapIndicator } from 'src/scenes/Home/scenes/CoverPage/components/MapIndicator';
import { MapWrapper } from 'src/components';


const users = {
  2020: 29,
  2021: 126,
  2022: 272,
  2023: 423,
  2024: 174,
  total: 1024,
};

const campaigns = {
  2017: 7,
  2018: 4,
  2019: 1,
  2020: 49,
  2021: 132,
  2022: 437,
  2023: 1166,
  2024: 580,
  total: 2376,
};

const flora = {
  plots: {
    2017: 4769,
    2018: 1480,
    2019: 28,
    2020: 13795,
    2021: 45742,
    2022: 108387,
    2023: 198358,
    2024: 52597,
    total: 425156,
  },
  transects: {
    2022: 445,
    2023: 86961,
    2024: 61921,
    total: 149327,
  },
  census: {
    2023: 375,
    2024: 2215,
    total: 2590,
  },
};

flora.all = {
  2023: flora.plots[2023] + flora.transects[2023] + flora.census[2023],
  2024: flora.plots[2024] + flora.transects[2024] + flora.census[2024],
  total: flora.plots.total + flora.transects.total + flora.census.total,
};

const fauna = {
  2020: 1,
  2021: 381,
  2022: 13918,
  2023: 44277,
  2024: 22080,
  total: 80657,
};

const allRecords = flora.all.total + fauna.total;

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100vh',
  },
  mapIndicators: {
    position: 'absolute',
    bottom: '0',
    zIndex: 314159,
    pointerEvents: 'initial',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      transform: 'scale(0.9)',
      width: '100%',
      overflowX: 'auto',
    },
  },
  titleContainer: {
    position: 'absolute',
    top: 40,
    zIndex: 401,
    width: '100%',
  },
  title: {
    fontSize: '4.375rem',
    fontWeight: 'bold',
    color: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
      lineHeight: '58px',
      textAlign: 'center',
    },
  },
  map: {
    position: 'relative',
    width: 'inherit',
    height: 'inherit',
  },
  paper: {
    backgroundColor: alpha(theme.palette.common.black, 0.2),
    padding: theme.spacing(1),
  },
}));

const { blackStreetsBaseMap } = config;

const mapOptions = {
  center: [ -33.4679997, -70.7003513 ],
  zoom: 5,
  maxZoom: 6,
  layers: [
    L.tileLayer(blackStreetsBaseMap, {}),
  ],
  scrollWheelZoom: false,
  zoomControl: true,
};

const MapSection = () => {

  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box display="flex" className={classes.titleContainer} justifyContent="center">
        <Paper className={classes.paper}>
          <Typography className={classes.title}>
            + de {(allRecords - Number(String(allRecords).slice(2))).toLocaleString('es-CL')} registros
          </Typography>
        </Paper>
      </Box>
      <Box className={classes.mapIndicators} p={{ xs: 1, md: 8 }} display="flex" flexDirection="column">
        <Box>
          <MapIndicator
            title="Campañas"
            number={campaigns.total}
            percent={campaigns[2024] / campaigns[2023] * 100}
            variant="dark" color="primary" Icon={Computer}
          />
        </Box>
        <Box mt={{ xs: 0, md: 5 }} ml={{ xs: 2, md: 0 }}>
          <MapIndicator
            title="Usuarios"
            number={users.total}
            percent={users[2024] / users[2023] * 100}
            variant="dark" color="fauna" Icon={Person}
          />
        </Box>
        <Box my={{ xs: 0, md: 5 }} mx={{ xs: 2, md: 0 }}>
          <MapIndicator
            title="Registros Flora"
            number={flora.all.total}
            percent={flora.all[2024] / flora.all[2023] * 100}
            variant="dark" color="flora" Icon={LocalFlorist}
          />
        </Box>
        <Box>
          <MapIndicator
            title="Registros Fauna"
            number={fauna.total}
            percent={fauna[2024] / fauna[2023] * 100}
            variant="dark" color="fauna" Icon={Paw}
          />
        </Box>
      </Box>
      <MapWrapper id="map" className={classes.map} options={mapOptions}></MapWrapper>
    </Box>
  );
};


export { MapSection };
