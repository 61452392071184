import React, { memo, useContext, useState } from 'react';
import { WebContext } from 'src/scenes/web-context';
import { Box, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import { NviroButton } from 'src/components';
import { usersApi } from 'src/services';


const EmailVerificationAlert = boxProps => {
  const { currentUser: { email, emailVerified } } = useContext(WebContext);
  const [ resending, setResending ] = useState(false);
  const [ errorMsg, setErrorMsg ] = useState('');
  const [ successMsg, setSuccessMsg ] = useState('');

  const handleResendEmail = async () => {
    if (resending || successMsg || errorMsg) {
      return;
    }
    try {
      setResending(true);
      const resp = await usersApi.resendVerificationEmail();
      setSuccessMsg(resp);
    } catch (e) {
      if (e.serverMessage) {
        setErrorMsg(e.serverMessage);
      }
      console.error(e);
      setErrorMsg('Error al intentar reenviar enlace. Por favor, intente más tarde');
    }
    setResending(false);
  };

  return email && !emailVerified ? (
    <Box { ...boxProps }>
      <Alert severity="warning"
        action={
          <NviroButton disabled={Boolean(errorMsg)} onClick={handleResendEmail} color="black" size="small"
            loading={resending} success={Boolean(successMsg)} shadow={true}
          >
            <Typography style={{ fontSize: '0.8rem' }}>
              Reenviar enlace
            </Typography>
          </NviroButton>
        }
      >
        <AlertTitle>Debes verificar tu email</AlertTitle>
        Por favor revisa tu email y sigue el enlace que te enviamos. Si no recibiste el correo o el enlace expiró, usa el botón para
        que te enviemos uno nuevo a <strong>{email}</strong>.
      </Alert>

      { errorMsg && <Box mt={1}><Alert severity="error">Error: {errorMsg}.</Alert></Box> }
    </Box>
  ) : null;
};

const EmailVerificationAlertMemoized = memo(EmailVerificationAlert);

export {
  EmailVerificationAlertMemoized as EmailVerificationAlert,
};
