import React, { useState, Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, InputLabel, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { SelectWrapper } from 'src/components';
import { WebContext } from 'src/scenes/web-context';


const UsersLinkDialog = props => {

  const { options: { rolesOptions, availableCompanyUsersOptions }, actions } = props;

  const [ state, setState ] = useState({
    selectedRole: { value: 'collector', label: 'Colector' },
    selectedUsers: [],
  });

  const { checkIfAdminPowers } = useContext(WebContext);

  const handleSubmit = async () => {
    const { selectedRole, selectedUsers } = state;
    const newUsers = {
      roleId: selectedRole.value,
      usersIds: selectedUsers.map(su => su.value),
    };
    if (selectedUsers.length) {
      await actions.linkUsers(newUsers);
    }
  };

  const canAddUserToCompany = checkIfAdminPowers();

  return (
    <Fragment>
      <DialogTitle id="form-dialog-title">Vincular persona a la campaña</DialogTitle>
      <DialogContent>
        <Box my={3}>
          <InputLabel shrink>
            Personas * {
              canAddUserToCompany &&
                <Link style={{ marginLeft: 5 }} to={`/web/company/users`}>¿Necesitas más personas en la empresa?</Link>
            }
          </InputLabel>
          <SelectWrapper
            isMulti options={availableCompanyUsersOptions} placeholder={'Seleccione personas'} fixedList
            onChange={selectedUsers => setState({ ...state, selectedUsers })}
            noOptionsMessage={() => 'No hay más personas disponibles en la empresa'}
          />
        </Box>
        <Box my={3}>
          <InputLabel shrink required>Rol</InputLabel>
          <SelectWrapper value={state.selectedRole} options={rolesOptions} placeholder={'Seleccione el rol'}
            onChange={selectedRole => setState({ ...state, selectedRole })} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <Button onClick={handleSubmit} color="primary">Guardar</Button>
      </DialogActions>
    </Fragment>
  );
};

UsersLinkDialog.propTypes = {
  actions: PropTypes.object,
  options: PropTypes.object,
};


export {
  UsersLinkDialog,
};
