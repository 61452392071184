import React from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';

import { GraphWrapper } from 'src/components';
import { campaignStatisticsApi } from 'src/services';
import { getRandomColor, addOrReplaceOpacityInColor } from 'src/utils/util';


const CoverageByPoint = props => {

  const apiCall = () => campaignStatisticsApi.getCoverageByPoint(props.campaignHash);

  const dataToGraphData = data => {
    const datasetsMetadata = [
      { status: 'coverage', title: '% de cobertura' },
    ].map(dm => ({ ...dm, color: getRandomColor() }));

    return {
      labels: data.map(d => d.name),
      datasets: datasetsMetadata.map(dm => (
        {
          label: dm.title,
          data: data.map(d => d.data[dm.status] || 0),
          backgroundColor: addOrReplaceOpacityInColor(dm.color, 0.7),
          borderColor: 'white',
          hoverBackgroundColor: addOrReplaceOpacityInColor(dm.color, 1),
        }
      )),
    };
  };

  return (
    <GraphWrapper
      title={'Cobertura por Transecta'}
      info={`Muestra el porcentaje de puntos dentro de la transecta que posee al menos una especie registrada.
      Solo se muestra información de las transectas que contengan especies registradas.`}
      apiCall={ apiCall }
      dataToGraphData={ dataToGraphData }
      chartjsComponent={ Bar }
      options={ {
        plugins: {
          legend: { display: false },
          tooltip: { mode: 'index', axis: 'y' } },
        scales: {
          x: {
            stacked: true,
            ticks: { autoSkip: false },
            title: { display: true, text: 'Transectas' },
          },
          y: {
            stacked: true,
            ticks: { maxTicksLimit: 10 },
            title: { display: true, text: '% Cobertura' },
          },
        },
      } }
    />
  );
};



CoverageByPoint.propTypes = {
  campaignHash: PropTypes.string.isRequired,
};

export {
  CoverageByPoint,
};