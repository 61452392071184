import React from 'react';
import PropTypes from 'prop-types';

import { AppHeaderUI, FloraAppRecordsUI, MobileUI, AppTabsUI } from 'src/scenes/Home/components';


const FloraAppRecordsPageUI = ({ records }) => (
  <MobileUI>
    <AppHeaderUI backText="Atrás" title="Parcela 2" />
    <AppTabsUI />
    <FloraAppRecordsUI records={ records } />
  </MobileUI>
);

// recommended to not go for more than 5 records here
FloraAppRecordsPageUI.propTypes = {
  records: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      specificName: PropTypes.string,
      eecc: PropTypes.bool,
      unofficial: PropTypes.bool,
      coverage: PropTypes.string,
    }),
  ),
};

export {
  FloraAppRecordsPageUI,
};