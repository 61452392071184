import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => ({
  container: {
    width: theme.spacing(52),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  menuTitle: {
    fontSize: '1.125rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  submenuTitle: {
    fontSize: '1.125rem',
  },
  submenu: {
    cursor: 'pointer',
  },
}));

const HeaderPopover = ({ title, Icon, submenu }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container} display="flex" alignItems="center">
      <Box mx={2} display="flex" flexDirection="column" alignItems="center">
        <Icon size="large" color="primary" />
        <Typography className={classes.menuTitle} color="secondary">{title}</Typography>
      </Box>
      <Box ml={6} display="flex" flexDirection="column">
        {submenu.map(({ title: submenuTitle, Icon, onClick }, idx) =>
          <Box key={idx} my={1} className={classes.submenu} display="flex" alignItems="center" onClick={onClick}>
            <Icon color="primary" />
            <Box ml={2}><Typography className={classes.submenuTitle}>{submenuTitle}</Typography></Box>
          </Box>,
        )}
      </Box>
    </Box>
  );
};

HeaderPopover.propTypes = {
  title: PropTypes.string,
  submenu: PropTypes.array,
  Icon: PropTypes.object,
};


export { HeaderPopover };