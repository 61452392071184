import React from 'react';
import { Box, InputLabel, TextField, FormHelperText, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { SelectWrapper, SelectChip } from 'src/components';


const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 500,
  },
  group: {
    borderRadius: theme.shape.borderRadius,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.gray,
  },
}));

const CampaignCreateFirstStep = ({ form, options, handlers, validators }) => {

  const classes = useStyles();

  const { name, selectedCoordinators, selectedCollectors, selectedComponent, selectedCampaignType,
    selectedCampaignMethod, selectedCatalog } = form;
  const { companyUsersOptions, collectorsOptions, componentOptions, campaignMainTypesOptions,
    campaignMethodsInComponentOptions, catalogs } = options;
  const { handleChange, handleSelectCoordinators, handleSelectCollectors, handleChangeComponent,
    handleSelect, handleCatalogChange } = handlers;
  const { hasBadData, isFan, isProjectOwnerACoordinator } = validators;

  return (
    <Box px={1} width="100%">
      <Typography className={classes.title} variant="body1">Ingresar datos principales</Typography>
      <Box my={2}>
        <InputLabel required shrink>Nombre</InputLabel>
        <TextField error={hasBadData && !name} name="name" value={name} onChange={handleChange} fullWidth autoFocus
          required autoComplete="off" variant="outlined" size="small" placeholder="Ej: Campaña primavera 2022" />
        {hasBadData && !name && <FormHelperText error>Debes ingresar un nombre para la campaña</FormHelperText>}
      </Box>

      {!isFan && <>
        <Box my={2}>
          <InputLabel shrink error={hasBadData && selectedCoordinators.length === 0}>Coordinador(es)</InputLabel>
          <SelectWrapper isMulti fixedList options={companyUsersOptions} className="basic-multi-select"
            value={selectedCoordinators} classNamePrefix="select" onChange={handleSelectCoordinators}
            placeholder={'Seleccione coordinador(es)'} noOptionsMessage={() => 'No hay más usuarios'}
          />
          {hasBadData && !isProjectOwnerACoordinator &&
            <FormHelperText error>El creador del proyecto debe ser coordinador en la campaña</FormHelperText>
          }
        </Box>
        <Box my={2}>
          <InputLabel shrink>Colector(es) <small>(opcional)</small></InputLabel>
          <SelectWrapper isMulti fixedList options={collectorsOptions} className="basic-multi-select"
            value={selectedCollectors} classNamePrefix="select" onChange={handleSelectCollectors}
            placeholder={'Seleccione colector(es)'} noOptionsMessage={() => 'No hay más usuarios'}
          />
        </Box>
      </> }

      <Box my={2}>
        <InputLabel shrink error={hasBadData && !selectedCampaignType.value}>Tipo</InputLabel>
        <Box px={1} display="flex" alignItems="center" height={64} className={classes.group}>
          <SelectChip
            value={selectedCampaignType.value}
            returnFullOption
            options={campaignMainTypesOptions}
            onChange={selectedCampaignType => handleSelect({ selectedCampaignType })}
          />
        </Box>
        {hasBadData && !selectedCampaignType.value && <FormHelperText error>Debes seleccionar un tipo</FormHelperText>}
      </Box>

      <InputLabel shrink>Configuración</InputLabel>
      <Box px={2} py={1} display="flex" flexDirection="column" className={classes.group}>
        <Box my={0.5}>
          <InputLabel shrink error={hasBadData && !selectedComponent.value}>Componente</InputLabel>
          <SelectChip
            value={selectedComponent.value}
            options={componentOptions}
            onChange={handleChangeComponent}
          />
          {hasBadData && !selectedComponent.value && <FormHelperText error>Debes seleccionar un componente</FormHelperText>}
        </Box>

        <Box my={0.5}>
          <InputLabel shrink error={hasBadData && !selectedCampaignMethod.value}>Metodología</InputLabel>
          <SelectChip
            value={selectedCampaignMethod?.value}
            options={campaignMethodsInComponentOptions[selectedComponent.value]}
            onChange={selectedCampaignMethod => handleSelect({ selectedCampaignMethod })}
            toUpperFirst={false}
          />
          {hasBadData && !selectedComponent.value && <FormHelperText error>Debes seleccionar una metodología</FormHelperText>}
        </Box>
        <Box my={0.5}>
          <InputLabel shrink error={hasBadData && !selectedCatalog?.value}>Catálogo</InputLabel>
          <SelectChip
            toUpperFirst={false}
            value={selectedCatalog.value}
            options={catalogs[selectedComponent.value]}
            onChange={handleCatalogChange} />
          {hasBadData && !selectedCatalog?.value && <FormHelperText error>Debes seleccionar un catálogo</FormHelperText>}
        </Box>
      </Box>
    </Box>
  );
};

CampaignCreateFirstStep.propTypes = {
  form: PropTypes.object,
  options: PropTypes.object,
  handlers: PropTypes.object,
  validators: PropTypes.object,
};


export {
  CampaignCreateFirstStep,
};