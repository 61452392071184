import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography, InputLabel, Grid, Chip } from '@material-ui/core';

import { DialogButton, AutocompleteWrapper, AlertWrapper } from 'src/components';
import { useFetchStatus } from 'src/hooks';


const isSynced = status => status === 'synced';

const getOptionSelected = (opt, optSelected) => opt.value === optSelected.value;

const PointsSuggestMethodologiesDialog = ({ actions, options, selectedPoints }) => {
  const preSelectedMethodologies = useMemo(() => {
    const selectedMethodologiesSet = new Set();
    let preSelectedSuggestions = [];
    const labelsDict = options.reduce((acc, curr) => {
      acc[curr.value] = curr.label;
      return acc;
    }, {});

    // los tipos de metodologías van a estar prerrellenados solo si todas las estaciones seleccionadas tienen
    // las mismas sugerencias. Empezemos viendo los datos de la primera, y luego si alguna de las otras estaciones
    // tiene datos que difieren, entonces no prerrelenamos nada.
    if (selectedPoints[0]?.suggestedMethodologies?.length) {
      preSelectedSuggestions = selectedPoints[0].suggestedMethodologies.map(sm => ({
        value: sm.methodEnumId, label: labelsDict[sm.methodEnumId],
      }));

      preSelectedSuggestions.forEach(sm => {
        selectedMethodologiesSet.add(sm.value);
      });

      for (let i = 1; i < selectedPoints.length; i++) {
        const currentPoint = selectedPoints[i];
        const currentSuggestions = currentPoint.suggestedMethodologies ?? [];

        if (currentSuggestions.length !== preSelectedSuggestions.length) {
          return [];
        }

        for (let mInd = 0; mInd < currentSuggestions.length; mInd++) {
          const currentSuggestion = currentSuggestions[mInd];
          if (!selectedMethodologiesSet.has(currentSuggestion.methodEnumId)) {
            return [];
          }
        }
      }
    }
    // si llega hasta acá, todas las estaciones tienen las mismas metodologías o la primera estación no tenía sugerencias
    return preSelectedSuggestions;
  }, [ selectedPoints, options ]);

  const [ selectedMethodologies, setSelectedMethodologies ] = useState(preSelectedMethodologies);

  const pointsSynced = useMemo(() => selectedPoints.filter(p => isSynced(p.statusId)), [ selectedPoints ]);

  const submit = async () => {
    await actions.editPoints({ suggestedMethodologies: selectedMethodologies.map(opt => ({ methodEnumId: opt.value })) });
  };

  const { fetchStatus, setFetchStatus, errorMessage, hasError, fetchStatusEnum, handleConfirm } = useFetchStatus({
    confirmFn: submit,
    closeFn: actions.closeDialog,
  });

  const handleSelectMethodologies = (event, selectedMethodologies) => {
    setFetchStatus(fetchStatusEnum.NOT_STARTED);
    setSelectedMethodologies(selectedMethodologies);
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">Sugerir metodologías en múltiples estaciones</DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Typography color="textPrimary" variant="body1">Estaciones seleccionadas:</Typography>
          <Grid container justifyContent="center" alignItems="center"
            style={{ ...(selectedPoints.length > 40 ? { overflowY: 'scroll' } : {}), maxHeight: '135px' }}>
            { selectedPoints.map(point =>
              <Box p={0.2} key={point.id}>
                <Chip label={isSynced(point.statusId) ? <strong><u>{point.name}</u></strong> : point.name} />
              </Box>,
            )}
          </Grid>
        </Box>
        { pointsSynced.length ?
          <Box p={1}>
            <Typography variant="body1" color="textPrimary">
              Se han seleccionado estaciones de muestreo que ya fueron
              <Typography display="inline" component="span" color="error"> sincronizadas</Typography>
              , para las cuales ya no se puede sugerir metodologías, para continuar debes <strong>sacarlas de la selección</strong>.
            </Typography>
          </Box> :
          <>
            <Box p={1}>
              <InputLabel shrink>Metodología(s) a sugerir</InputLabel>
              <AutocompleteWrapper options={options} className="basic-multi-select"
                value={selectedMethodologies}
                getOptionSelected={getOptionSelected}
                onChange={handleSelectMethodologies}
                placeholder={'Seleccione las metodologías a realizar en la estación'} noOptionsText="No hay más tipos de metodologías"
                multiple clearable
              />
            </Box>
            <Box>
              { hasError && <AlertWrapper severity="error">{errorMessage}</AlertWrapper> }
            </Box>
          </>
        }
      </DialogContent>
      <DialogActions>
        {pointsSynced.length ?
          <Button onClick={actions.closeDialog} color="primary">Cerrar</Button> :
          <>
            <Button onClick={actions.closeDialog} color="secondary">Cancelar</Button>
            <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>Confirmar</DialogButton>
          </>
        }
      </DialogActions>
    </>
  );
};

PointsSuggestMethodologiesDialog.propTypes = {
  selectedPoints: PropTypes.array,
  options: PropTypes.array,
  actions: PropTypes.object,
};


export {
  PointsSuggestMethodologiesDialog,
};
