import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, DialogTitle, DialogContent, DialogActions, Typography, Divider } from '@material-ui/core';

import { RecordDataList } from 'src/scenes/Campaign/scenes/Records/components/RecordDataList';


const RecordWasDeletedDialog = ({ refreshAction, closeDialog, editionData }) => (
  <Fragment>
    <DialogTitle id="form-dialog-title">El registro fue recientemente borrado</DialogTitle>

    <DialogContent>
      <Typography mb={1} variant="body1" color="textPrimary">
        <strong>Este registro fue borrado por alguien más.
            Por favor refresca la página para ver la lista más actual de registros.</strong>
      </Typography>

      <Box my={1}>
        <Divider />
      </Box>

      { editionData && <>
        <Typography variant="body1" color="textPrimary">
            Aún puedes ver los datos de la edición que estabas enviando a continuación:
        </Typography>
        <RecordDataList data={ editionData }/>
      </>}
    </DialogContent>

    <DialogActions>
      <Button onClick={ closeDialog }>Cancelar</Button>
      <Button onClick={ refreshAction } color="primary">
          Refrescar
      </Button>
    </DialogActions>
  </Fragment>
);

RecordWasDeletedDialog.propTypes = {
  editionData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  actionName: PropTypes.string,
  refreshAction: PropTypes.func,
  closeDialog: PropTypes.func,
};

export { RecordWasDeletedDialog };
