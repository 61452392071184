import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';


const SimpleDialog = ({ actionObjs, title, message }) => (
  <Fragment>
    { title && <DialogTitle id="form-dialog-title">{ title }</DialogTitle> }
    { message &&
      <DialogContent>
        <Typography variant="body1" component='div'>
          { message }
        </Typography>
      </DialogContent>
    }
    { actionObjs &&
      <DialogActions>
        { actionObjs.map(({ actionFunc, actionName, color }) => (
          <Button key={actionName} onClick={actionFunc} color={ color ?? 'default' }>{ actionName }</Button>
        ))
        }
      </DialogActions>
    }
  </Fragment>
);

SimpleDialog.propTypes = {
  actionObjs: PropTypes.arrayOf(
    PropTypes.shape({
      actionFunc: PropTypes.func.isRequired,
      actionName: PropTypes.string.isRequired,
      color: PropTypes.string,
    }),
  ),
  message: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
  title: PropTypes.string,
};

export {
  SimpleDialog,
};
