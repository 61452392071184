import React, { useMemo } from 'react';
import { Typography, Box, Paper, IconButton, Tooltip, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Delete, Edit, People, ScatterPlot, LocalFlorist, Pets } from '@material-ui/icons';

import { AccessControl, LabelChip } from 'src/components';
import { humanReadableFormatDate, formatDate } from 'src/utils/util';
import { upperCaseOnlyFirstLetter as upofl } from 'src/utils/formatters';
import { isTransect, isCensus } from 'src/utils/checkers';


const campaignStates = {
  pending: 'SIN INICIALIZAR',
  'in-progress': 'EN PROGRESO',
  finished: 'FINALIZADA',
};

const useStyles = makeStyles(theme => ({
  paper: {
    cursor: 'pointer',
    paddingLeft: theme.spacing(3),
    borderRadius: '10px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    background: 'white',
    transform: 'none',
    boxSizing: 'border-box',
    borderLeftStyle: 'solid',
    borderLeftColor: props => props.componentId && theme.palette[props.componentId].primary.main,
    borderLeftWidth: theme.spacing(2),
    transition: theme.transitions.create(
      [ 'transform', 'border-left-width', 'border-left-color' ],
      { duration: theme.transitions.duration.shortest },
    ),
    '&:hover': {
      borderLeftWidth: theme.spacing(8),
      borderLeftColor: theme.palette.text.primary,
      '& $actions': {
        opacity: 1,
        visibility: 'visible',
      },
      '& $plannificationButton': {
        textDecoration: 'none',
        backgroundColor: 'rgba(46, 46, 46, 0.08)',
      },
    },
  },
  logo: {
    marginTop: theme.spacing(1) / 2,
    opacity: 0.15,
    objectFit: 'cover',
    objectPosition: '100% 0',
    marginLeft: -1 * theme.spacing(3),
    height: theme.spacing(12),
    width: theme.spacing(6),
    filter: 'grayscale(100%)',
  },

  actions: {
    visibility: 'hidden',
    transform: `translateX(-${theme.spacing(9)}px)`,
    opacity: 0,
    transition: theme.transitions.create(
      [ 'opacity' ],
      { duration: theme.transitions.duration.shortest },
    ),
    minWidth: 30,
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  editButton: {
    color: theme.palette.common.white,
  },
  title: {
    fontWeight: 300,
    fontSize: '1.7rem',
  },
  dateLabel: {
    fontWeight: 400,
    fontSize: '0.8rem',
    lineHeight: 2,
  },
  dateIcon: {
    color: theme.palette.text.secondary,
  },
  plannificationButton: {
    color: theme.palette.text.primary,
  },
  hiddenLongText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  rightComponent: {
    lexShrink: 0,
    width: '40%',
    marginRight: '10px',
  },
  statusLabel: {
    color: ({ statusId }) => (
      statusId === 'pending' ? theme.palette.error.main
      : statusId === 'in-progress' ? theme.palette.fauna.primary.main
      : statusId === 'finished' ? theme.palette.success.main
      : theme.palette.common.gray
    ),
    fontWeight: 500,
    fontSize: '0.8rem',
    lineHeight: 1,
  },
  labelChip: {
    cursor: 'pointer',
  },
  dataLabel: {
    marginLeft: theme.spacing(0.2),
    fontWeight: 500,
  },
}));

const CampaignCard = ({ campaign, isPending: isPendingFn, actions, cLang, companyRole, currentUserId, projectOwnerId }) => {
  const classes = useStyles(campaign);
  const handleDelete = e => {
    e.stopPropagation();
    actions.openDialog({ data: campaign.hash, contentType: 'delete' });
  };
  const handleEdit = e => {
    e.stopPropagation();
    actions.openDialog({ data: campaign, contentType: 'edit' });
  };

  const handleGoTo = () => actions.goTo(campaign);

  const openStartCampaignDialog = () => {
    if (isCensus(campaign.mainMethodId)) {
      actions.openDialog({ data: campaign.hash, contentType: 'start-census' });
    }
  };

  const recordCountIcon = {
    flora: <LocalFlorist fontSize="small" color="action" />,
    fauna: <Pets fontSize="small" color="action" />,
  };

  const defaultCampaignData = { points: {}, users: 1, recordsCount: 0 };
  const { data = defaultCampaignData } = campaign;

  const campaignRole = useMemo(() => {
    if (companyRole === 'admin' || companyRole === 'god') {
      return companyRole;
    }

    if (campaign.data.users) {
      const foundUserRole = campaign.data.users.find(urole => urole.userId === currentUserId);
      return foundUserRole?.rol ?? null;
    }
    // if there's no "data.users" i'm a collector (honestly, the back should prolly just send the users array to collectors too)
    return 'collector';
    // no vemos cambios a companyRole porque cuando eso pasa la página entera debe cambiar, si no se generan cambios a medias en la card
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [ campaign.data.users, currentUserId ]);

  const camOwnerOrProjOwnerOrSuper = companyRole === 'admin' || companyRole === 'god'
    || currentUserId === campaign.ownerId || currentUserId === projectOwnerId;

  const isPending = isPendingFn(campaign.statusId);
  const pointsData = data.points || {};
  const totalPoints = pointsData ? Object.keys(pointsData).reduce((sum, key) => pointsData[key] + sum, 0) : 0;
  const syncedPoints = pointsData['synced'] ? pointsData['synced'] : 0;
  const isCensusCampaign = isCensus(campaign.mainMethodId);

  return (
    <Box pb={1}>
      <Paper className={classes.paper} onClick={isCensusCampaign && isPending ? openStartCampaignDialog : handleGoTo}>
        <Box display="flex" alignItems="center">
          <Box display="flex" flexDirection="column" alignItems="center" className={classes.actions}>
            <AccessControl action="campaign:update" campaignRole={ campaignRole } checkOnCampaignRole>
              <IconButton size="small" aria-label="edit" className={classes.editButton} onClick={handleEdit}>
                <Edit />
              </IconButton>
            </AccessControl>
            <AccessControl action="campaign:delete" extraCondition={ camOwnerOrProjOwnerOrSuper }>
              <IconButton size="small" aria-label="delete" className={classes.deleteButton} onClick={handleDelete}>
                <Delete />
              </IconButton>
            </AccessControl>
          </Box>
          <Box position="absolute">
            <img className={classes.logo} src={`/img/logos/${campaign.componentId}-icon.png`} alt="app-icon"></img>
          </Box>
          <Box display='flex' flexDirection="column" sx={{ p: 1, width: '60%' }} className={classes.hiddenLongText}>
            <Box display="flex" alignItems="center">
              <Typography noWrap className={classes.title} variant="h5" color="textPrimary" title={campaign.name}>
                {campaign.name}
              </Typography>
            </Box>
            <Typography variant="body2" color="textSecondary">{campaign.projectName}</Typography>
            <Box pb={0.5} display="flex">
              <Box pr={0.25}>
                <LabelChip
                  className={classes.labelChip}
                  label={upofl(campaign.componentId)}
                  color={`${campaign.componentId}.primary.main`}
                />
              </Box>
              <Box px={0.25}>
                <LabelChip className={classes.labelChip} label={upofl(campaign.primaryTypeName)} color="gray800" />
              </Box>
              <Box pl={0.25}>
                <LabelChip
                  className={classes.labelChip}
                  label={`${isTransect(campaign.mainMethodId) ? 'Transecta -' : ''} ${campaign.mainMethodName}`.trim()}
                  color="gray600"
                />
              </Box>
            </Box>
            <Typography variant="caption" color="textSecondary">
              {humanReadableFormatDate(campaign.updatedAt || campaign.createdAt)}
            </Typography>
          </Box>
          <Box className={classes.rightComponent} display="flex" flexDirection="column" alignItems="center">
            <Typography variant="caption" color="textSecondary" className={classes.dateLabel}>
                Inicia el {formatDate(campaign.startDate)} y termina el {formatDate(campaign.endDate)}
            </Typography>
            <Box my={1} display="flex" justifyContent="center" >
              <Typography variant="caption" className={classes.statusLabel}>
                {campaignStates[campaign.statusId]}
              </Typography>
            </Box>
            {isPending ?
              <Box display="flex" justifyContent="center">
                { isCensusCampaign ?
                  <Button size="small" variant="outlined" className={classes.plannificationButton}>
                    Comenzar
                  </Button>
                  : <Button size="small" variant="outlined" className={classes.plannificationButton}>
                    Ir a planificar
                  </Button>
                }
              </Box> :
              <Box display="flex" justifyContent="center">
                { !isCensusCampaign &&
                  <Tooltip title={`${cLang.Points} (sincronizadas / totales)`}>
                    <Box mx={1} display="flex">
                      <ScatterPlot fontSize="small" color="action" />
                      <Typography className={classes.dataLabel} variant="caption" color="textSecondary">
                        {syncedPoints}/{totalPoints}
                      </Typography>
                    </Box>
                  </Tooltip>
                }
                <Tooltip title="Registros totales">
                  <Box mx={1} display="flex">
                    {recordCountIcon[campaign.componentId]}
                    <Typography className={classes.dataLabel} variant="caption" color="textSecondary">{data.recordsCount}</Typography>
                  </Box>
                </Tooltip>
                {data.users &&
                  <Tooltip title="Personas involucradas">
                    <Box mx={1} display="flex">
                      <People fontSize="small" color="action" />
                      <Typography className={classes.dataLabel} variant="caption" color="textSecondary">{data.users.length}</Typography>
                    </Box>
                  </Tooltip>}
              </Box>
            }
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

CampaignCard.propTypes = {
  campaign: PropTypes.object,
  actions: PropTypes.object,
  isPending: PropTypes.func,
  cLang: PropTypes.object,
  companyRole: PropTypes.string.isRequired,
  currentUserId: PropTypes.number,
  projectOwnerId: PropTypes.number,
};


export { CampaignCard };
