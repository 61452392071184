import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { TableWrapper } from 'src/components';
import { Delete } from '@material-ui/icons';


const UsersList = props => {
  const { usersList, options: { rolesOptions }, actions, currentUser, projectCreatorId, hasOwnWorkFinished } = props;
  /* eslint-disable react/display-name */
  const tableColumns = [
    { title: 'Correo', field: 'email' },
    { title: 'Nombre', field: 'name', render: useCallback(r => <div>{r.firstName} {r.lastName}</div>, []) },
    {
      title: 'Rol', field: 'role',
      render: useCallback(r => <div>{rolesOptions.find(role => role.value === r.roleId).label}</div>, [ rolesOptions ]) },
    {
      title: 'Trabajo terminado', field: 'hasOwnWorkFinished',
      render: useCallback(
        r => <div>{r.workFinished || (r.id.toString() === currentUser.id.toString() && hasOwnWorkFinished) ? 'Sí' : 'No'}</div>,
        [ hasOwnWorkFinished, currentUser.id ],
      ),
    },
  ];

  const itsMe = email => currentUser.email === email;
  const itsCreator = userId => projectCreatorId === userId;

  const tableActions = [
    rowData => ({
      disabled: itsMe(rowData.email) || itsCreator(rowData.id),
      tooltip: itsMe(rowData.email) ? 'No te puedes eliminar a ti mismo de la campaña'
      : itsCreator(rowData.id) ? 'No es posible eliminar al creador de la campaña'
      : 'Desvincular',
      icon: () => <Delete color={itsMe(rowData.email) ? 'disabled' : 'action'} />,
      ...(!itsMe(rowData.email) && { onClick: (event, rowData) => actions.toggleDialog({ data: rowData.id, type: 'delete' }) }),
    }),
  ];

  const tableOptions = {
    pageSizeOptions: [],
  };

  return (
    <TableWrapper columns={tableColumns} data={usersList} actions={tableActions} options={tableOptions} />
  );
};

UsersList.propTypes = {
  usersList: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  projectCreatorId: PropTypes.string.isRequired,
  hasOwnWorkFinished: PropTypes.bool,
};


export { UsersList };