import React from 'react';
import { CircularProgress, Zoom, Button } from '@material-ui/core';
import { CheckCircleOutlineOutlined } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles(theme => ({
  successColor: {
    color: theme.palette.common.white,
  },
}));

const { LOADING, SUCCESS } = fetchStatusEnum;

const FetchButton = ({ children, fetchStatus, ...props }) => {
  const classes = useStyles();
  return (
    <Button {...props}>
      {
        fetchStatus === LOADING ? <CircularProgress size={25} thickness={4.8} />
        : fetchStatus === SUCCESS ? <Zoom in><CheckCircleOutlineOutlined className={classes.successColor} fontSize="small" /></Zoom>
        : children ? children
        : 'Confirmar'
      }
    </Button>
  );
};

FetchButton.propTypes = {
  children: PropTypes.any,
  fetchStatus: PropTypes.oneOf([ ...Object.values(fetchStatusEnum) ]).isRequired,
};


export { FetchButton };