import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { Box, FormControlLabel, Checkbox, Typography } from '@material-ui/core';


const PhotoConfigCheckbox = memo(({ config, setConfig }) => {
  const handleChange = useCallback(event =>
    setConfig(pc => ({ ...pc, photos: { ...pc.config?.photos, withMetadata: event.target.checked } })),
  [ setConfig ]);

  return <Box my={0} py={0}>
    <FormControlLabel
      control={<Checkbox
        checked={Boolean(config?.photos?.withMetadata)}
        onChange={handleChange}
      />}
      label={<Typography variant="body2">Incluir coordenadas y fecha en las fotos</Typography>}
    />
  </Box>;
});

PhotoConfigCheckbox.propTypes = {
  config: PropTypes.object,
  setConfig: PropTypes.func,
};


export {
  PhotoConfigCheckbox,
};
