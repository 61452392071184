import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { MapWrapper } from 'src/components';
import { mapOptions, getLayers, legend } from 'src/scenes/Home/scenes/CoverPage/components/monitoringUIDataFaker';
import { BrowserUI, HeaderUI } from 'src/scenes/Home/components';


const useStyles = makeStyles(theme => ({
  container: {
  },
  map: {
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
    height: '450px',
  },
  mapContainer: {
    position: 'relative',
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
  },
  sidePanel: {
    position: 'absolute',
    top: -8,
    right: 0,
    zIndex: 314159,
    pointerEvents: 'initial',
    padding: theme.spacing(2),
    transform: 'scale(0.9)',
    width: '240px',
    height: '95%',
  },
  titleProps: {
    fontSize: '1.75rem',
  },
  buttonProps: {
    transform: 'scale(0.9)',
  },
}));

const MonitoringUI = () => {
  const classes = useStyles();

  return (
    <BrowserUI size="small">
      <HeaderUI size="small" user="Juan Von Hildebrand"/>
      <Box className={classes.mapContainer}>
        <MapWrapper id="planning-map" className={classes.map} options={mapOptions} layers={getLayers()} legend={legend} disableZoom />
      </Box>
    </BrowserUI>
  );
};


export {
  MonitoringUI,
};