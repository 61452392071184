import React, { Fragment, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Collapse, List, ListItem, ListItemText, Divider,
  Typography, ListSubheader, ListItemIcon, Button, Zoom, Paper, Icon, Badge, Tooltip } from '@material-ui/core';
import { ExpandLess, ExpandMore, Map, Grain, Spa, BarChart, Description, CheckCircle,
  Pets, Group, ChevronLeft, ChevronRight, Explore, LinearScale } from '@material-ui/icons';

import { formatDate, truncateWithEllipsis as twe } from 'src/utils/util';
import { DrawerWrapper, DialogWrap, AccessControl } from 'src/components';
import { FinishCampaignDialog, FinishWorkDialog } from 'src/scenes/Campaign/components';
import { WebContext } from 'src/scenes/web-context';


const campaignStates = {
  pending: 'Pendiente',
  'in-progress': 'En progreso',
  finished: 'Finalizada',
};

const icons = {
  monitoring: <Map />,
  plots: <Grain />,
  'sampling-stations': <Grain />,
  'transects': <LinearScale />,
  'records?type=isolated': <Pets />,
  statistics: <BarChart />,
  downloads: <Description />,
  'work-team': <Group />,
  'records': <Spa />,
  methodologies: <Explore />,
};

const useStyles = makeStyles(theme => ({
  listItemSelected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: theme.palette.common.white,
  },
}));

// eslint-disable-next-line
const ItemWithBadge = ({ text, tooltipText, count = 0 }) =>
  <Box display="flex">
    <Box flexGrow={1}>
      {text}
    </Box>
    <Box display="flex" alignSelf="center" mr={2}>
      <Tooltip title={tooltipText}>
        <Badge badgeContent={count} color={'error'} overlap="rectangular"></Badge>
      </Tooltip>
    </Box>
  </Box>;

// eslint-disable-next-line
const NormalItem = ({ active, id, text, changeActive, style = {} }) => {
  const classes = useStyles();
  const { selectedCampaign: { recordsCounter: { unofficial_source: uncorrectedRecords } } } = useContext(WebContext);
  const isRecordsItem = id === 'records';
  const hasUncorrectedRecords = uncorrectedRecords > 0;
  return (
    <ListItem classes={{ selected: classes.listItemSelected }} button onClick={() => changeActive(id)} selected={id === active}>
      <ListItemIcon>{icons[id]}</ListItemIcon>
      <ListItemText
        style={style}
        primary={
          isRecordsItem && hasUncorrectedRecords ?
            <ItemWithBadge text={text} tooltipText={'Registros sin corregir'} count={uncorrectedRecords} />
            : text
        }
      />
    </ListItem>
  );
};

// eslint-disable-next-line react/prop-types
const NestedItem = ({ active, titleText, changeActive, subMenus }) => {
  const [ open, setOpen ] = useState(false);
  return (
    <Fragment>
      <ListItem button onClick={() => setOpen(!open)}>
        <ListItemText primary={titleText} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {subMenus.map(item => // eslint-disable-line react/prop-types
            <NormalItem style={{ marginLeft: 20 }} active={active} changeActive={changeActive}
              id={item.id} key={item.id} text={item.text} />)
          }
        </List>
      </Collapse>
    </Fragment>);
};

const campaignDrawerStyles = makeStyles(theme => ({
  componentValue: {
    textTransform: 'capitalize',
  },
  finishWorkBtn: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.nviro.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.nviro.primary.dark,
    },
  },
  finishCampaignBtn: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  finishBtnDisabled: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    cursor: 'default',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  content: {
    position: 'fixed',
    top: '50%',
    marginLeft: ({ width }) => width,
  },
  openDrawer: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: () => 0,
  },
  closeDrawer: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: ({ width }) => -width,
  },
  toggleButton: {
    padding: 1,
  },
  toggleDrawerButton: {
    height: theme.spacing(9),
    borderRadius: 0,
    borderTopRightRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
}));

const CampaignDrawer = ({ campaign, active, campaignItemsList, isDrawerOpen, width = 240, actions,
  completionStatusByUser, completionStatusByCampaign, roleInCampaign }) => {
  const classes = campaignDrawerStyles({ width });
  const { selectedCompany: { isFreelance },
    campaignSubtypeLanguage, isFan, selectedCampaign: { catalogName, workingGroupCounter } } = useContext(WebContext);
  const { changeActive, finishCampaign, finishWork, toggleDrawer } = actions;

  const [ dialog, setDialog ] = useState({ isOpen: false, type: '' });
  const closeDialog = () => setDialog({ isOpen: false, type: '' });
  const openDialog = type => setDialog({ isOpen: true, type });

  const getDialogType = () => {
    switch (dialog.type) {
      case 'finish-campaign':
        return <FinishCampaignDialog
          catalogName={catalogName}
          completionStatusByCampaign={completionStatusByCampaign}
          campaign={campaign}
          actions={{ closeDialog, finishCampaign }}
          cLang={campaignSubtypeLanguage}
          isFreelance={Boolean(isFreelance)} />;
      case 'finish-work':
        return <FinishWorkDialog
          completionStatusByUser={completionStatusByUser}
          catalogName={catalogName}
          actions={{ closeDialog, finishWork }}
          cLang={campaignSubtypeLanguage}
          forceReview={campaign.forceReview}/>;
      default:
        break;
    }
  };

  const isCampaignFinished = campaign.statusId === 'finished';

  return (
    <Box className={isDrawerOpen ? classes.openDrawer : classes.closeDrawer}>
      <DrawerWrapper width={width} isDrawerOpen={isDrawerOpen}>
        <Box mt={12}>
          <Box p={2}>
            <Box mb={1}>
              <Typography variant="h6" color="primary">{campaign.name}</Typography>
            </Box>
            <Box display="flex" justifyContent="flex-start">
              <Box display="flex" flexDirection="column">
                <Typography variant="body2">Proyecto:</Typography>
                <Typography variant="body2">Línea base:</Typography>
                <Typography variant="body2">Catálogo:</Typography>
                <Typography variant="body2">Estado:</Typography>
                <Typography variant="body2">Fecha inicio:</Typography>
                <Typography variant="body2">Fecha término:</Typography>
                <Typography variant="body2">Personas:</Typography>
              </Box>
              <Box ml={1} display="flex" flexDirection="column">
                <Typography variant="body2" title={campaign.projectName}>{twe(campaign.projectName, 11)}</Typography>
                <Typography variant="body2" className={classes.componentValue}>{campaign.componentId}</Typography>
                <Typography variant="body2" title={campaign.catalogName}>{twe(campaign.catalogName, 11)}</Typography>
                <Typography variant="body2">{campaignStates[campaign.statusId]} </Typography>
                <Typography variant="body2">{formatDate(campaign.startDate)}</Typography>
                <Typography variant="body2">{formatDate(campaign.endDate)}</Typography>
                <Typography variant="body2">{workingGroupCounter}</Typography>
              </Box>
            </Box>
            {!isFan() && roleInCampaign &&
              <Box mt={2} textAlign="center">
                {campaign.hasOwnWorkFinished ?
                  <Button disableElevation className={classes.finishBtnDisabled} endIcon={<Zoom in><CheckCircle /></Zoom>}
                    fullWidth variant="contained" size="small">
                    Trabajo finalizado
                  </Button> :
                  <Button disabled={isCampaignFinished} className={classes.finishWorkBtn} fullWidth variant="contained" size="small"
                    onClick={() => openDialog('finish-work')}>
                    Finalizar trabajo
                  </Button>
                }
              </Box>
            }
            <AccessControl action="campaign:finish" checkOnCampaignRole>
              <Box mt={1} textAlign="center">
                {isCampaignFinished ?
                  <Button disableElevation className={classes.finishBtnDisabled} endIcon={<Zoom in><CheckCircle /></Zoom>}
                    fullWidth variant="contained" size="small">
                    Campaña finalizada
                  </Button> :
                  <Button className={classes.finishCampaignBtn} fullWidth variant="contained"
                    color="primary" size="small" onClick={() => openDialog('finish-campaign')}>
                    Finalizar campaña
                  </Button>
                }
              </Box>
            </AccessControl>
          </Box>
          <Divider />
          <List>
            <ListSubheader color="primary">Opciones de campaña</ListSubheader>
            {campaignItemsList.map(({ id, text, subMenus }) =>
              <Fragment key={id}>
                {subMenus ?
                  <NestedItem titleText={text} subMenus={subMenus} active={active} changeActive={changeActive} /> :
                  <NormalItem active={active} changeActive={changeActive} id={id} text={text} />
                }
              </Fragment>)
            }
          </List>
          <Divider />
        </Box>
        <DialogWrap maxWidth="xs" fullWidth onClose={closeDialog} aria-labelledby="form-dialog-title" open={dialog.isOpen}>
          {getDialogType()}
        </DialogWrap>
      </DrawerWrapper>
      <Box className={classes.content}>
        <Paper onClick={toggleDrawer} className={classes.toggleDrawerButton}>
          <Icon className={classes.toggleButton}>
            {isDrawerOpen ? <ChevronLeft /> : <ChevronRight />}
          </Icon>
        </Paper>
      </Box>
    </Box>
  );
};

CampaignDrawer.propTypes = {
  active: PropTypes.string.isRequired,
  campaignItemsList: PropTypes.array,
  campaign: PropTypes.object,
  roleInCampaign: PropTypes.any,
  completionStatusByCampaign: PropTypes.object,
  completionStatusByUser: PropTypes.object,
  actions: PropTypes.object.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
};


export { CampaignDrawer };