import React, { PureComponent } from 'react';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';

import { GraphWrapper } from 'src/components';
import { campaignStatisticsApi } from 'src/services';


export class GeneralPointsProgress extends PureComponent {

  apiCall = () => campaignStatisticsApi.getGeneralPointsProgress(this.props.campaignHash);

  dataToGraphData = data => ({
    labels: [ 'Pendientes', 'Sincronizadas' ],
    datasets: [
      {
        data: [ data.todo, data.synced ],
        backgroundColor: [
          'rgba(244, 67, 54, 0.7)',
          'rgba(81, 147, 235, 0.7)',
          'rgba(0, 200, 83, 0.7)',
        ],
        borderColor: 'white',
        hoverBackgroundColor: [
          'rgba(244, 67, 54, 1)',
          'rgba(81, 147, 235, 1)',
          'rgba(0, 200, 83, 1)',
        ],
      },
    ],
  });

  render() {
    const { cLang } = this.props;
    return (
      <GraphWrapper
        title={`Progreso en ${cLang.points}`}
        info={`Muestra la cantidad de ${cLang.points} por estado`}
        apiCall={ this.apiCall }
        dataToGraphData={ this.dataToGraphData }
        chartjsComponent={ Doughnut }
        legend={ { position: 'top' } }
      />
    );
  }
}

GeneralPointsProgress.propTypes = {
  campaignHash: PropTypes.string.isRequired,
  cLang: PropTypes.object.isRequired,
};
