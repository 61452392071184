import React, { useState, useEffect, useRef } from 'react';
import { Box, Chip, TextField, CircularProgress, Popper, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import { truncateWithEllipsis as twe, generateDebouncer } from 'src/utils/util';
import { upperCaseFirstLetter as ucfl } from 'src/utils/formatters';
import { LabelChip } from 'src/components';


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: ({ isDisabled }) => isDisabled ? theme.palette.common.lightGray : 'transparent',
  },
  font: {
    fontSize: theme.typography.body2.fontSize,
    fontStyle: ({ useScientificNameFormat, inputValue, useScientificNameFormatInPlaceholder }) =>
      (useScientificNameFormat && inputValue) || (useScientificNameFormatInPlaceholder && inputValue === '') ? 'italic'
      : 'normal',
  },
  menuOptionsPopper: {
    zIndex: theme.zIndex.modal,
  },
  ssNameLabel: {
    backgroundColor: theme.palette.common.gray600,
    color: theme.palette.common.white,
  },
  userLabel: {
    fontStyle: 'normal',
  },
  label: {
    height: theme.spacing(2),
    fontSize: '0.75rem',
  },
  input: {
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: 'blue',
    },
  },
  partHighlight: {
    fontWeight: 700,
  },
  endAdornment: {
    paddingRight: theme.spacing(1),
  },
}));

const SelectAsync = props => {
  const {
    id = 'select-async ',
    onChange,
    getOptions = () => [],
    label,
    placeholder,
    initialOptions = true,
    forceUpdate = false,
    disabled = false,
    error = false,
    useScientificNameFormat = false,
    useScientificNameFormatInPlaceholder = false,
    clearable = false,
    debounce = true,
    ...autoCompleteProps
  } = props;

  const [ open, setOpen ] = useState(false);
  const [ options, setOptions ] = useState([]);

  const [ isFetching, setIsFetching ] = useState(false);
  const [ inputValue, setInputValue ] = useState('');

  const isDisabled = disabled instanceof Function ? disabled(options, inputValue) : disabled;
  const classes = useStyles({ isDisabled, useScientificNameFormat, useScientificNameFormatInPlaceholder, inputValue });

  useEffect(() => {
    const fetchData = async () => {
      const options = await getOptions({ queryText: '' });
      setOptions(options);
    };
    initialOptions && fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ forceUpdate ]);

  const getAndSetOptionsBase = async ({ queryText }) => {
    const options = await getOptions({ queryText });
    setOptions(options);
    setIsFetching(false);
  };

  const timeoutRef = useRef();

  const getAndSetOptions = debounce ? generateDebouncer({ callback: getAndSetOptionsBase, timeoutRef }) : getAndSetOptionsBase;

  const handleInputChange = async (event, newInputValue) => {
    setInputValue(newInputValue);
    if (newInputValue.length || initialOptions) {
      setIsFetching(true);
      getAndSetOptions({ queryText: newInputValue });
    } else {
      setOptions([]);
      if (debounce) {
        getAndSetOptions.cancel();
      }
      setIsFetching(false);
    }
  };

  const handleOptionSelected = (event, optionSelected) => onChange(optionSelected);

  return (
    <Autocomplete
      id={id}
      open={open}
      freeSolo
      onOpen={() => setOpen(true) }
      onClose={() => setOpen(false)}
      onChange={handleOptionSelected}
      clearOnBlur
      onBlur={e => {
        if (e.target.value === '') {
          onChange({ label: '', value: '' });
        }
      }}
      getOptionSelected={(option, value) => option.value === value.value}
      disableClearable={!clearable}
      getOptionLabel={option => option.label || ''}
      options={options}
      loading={isFetching}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      PopperComponent={params => <Popper {...params} className={clsx(classes.menuOptionsPopper, classes.font) } />}
      classes={{ root: classes.root, noOptions: classes.font, endAdornment: classes.endAdornment }}
      size="small"
      loadingText="Cargando"
      filterOptions={options => options.filter(option => option.value !== '')}
      renderInput={params => (
        <TextField
          {...params}
          error={error}
          variant="outlined"
          label={label}
          placeholder={placeholder instanceof Function ? placeholder(options) : placeholder}
          InputLabelProps={{ disableAnimation: true }}
          InputProps={{
            ...params.InputProps,
            className: classes.font,
            endAdornment: (
              <>
                { isFetching ? <CircularProgress color="inherit" size={20} /> : (inputValue && params.InputProps.endAdornment) }
              </>
            ),
          }}
        />
      )}
      // eslint-disable-next-line react/display-name
      renderOption = {(option, { inputValue }) =>
        <Box display="flex" width="100%">
          <Box flexGrow={1}>
            {parse(option.label, match(option.label, inputValue, { insideWords: true })).map((part, index) =>
              <Typography
                key={index}
                variant="body2"
                component="span"
                className={clsx(classes.font, { [classes.partHighlight]: part.highlight })}
              >
                {part.text}
              </Typography>,
            )}
          </Box>
          {option.samplingStationName &&
            <Box display="flex" alignItems="center">
              <Chip
                title={`Estación de muestreo: ${option.samplingStationName}`}
                size="small"
                className={clsx(classes.label, classes.ssNameLabel)}
                label={`EM: ${twe(ucfl(option.samplingStationName), 7)}`}
              />
            </Box>
          }
          {option.userCreatorInfo &&
            <Box display="flex" alignItems="center">
              <Typography className={classes.userLabel} variant='caption'>
                Agregada por
              </Typography>
              &nbsp;
              <LabelChip
                size="small"
                className={classes.userLabel}
                label={option.userCreatorInfo}
              />
              {option.isRecordCreator &&
                <>
                  &nbsp;
                  <Typography className={classes.userLabel} variant='caption'>
                    <strong> creador del registro</strong>
                  </Typography>
                </>
              }
            </Box>
          }
        </Box>
      }
      disabled={isDisabled}
      {...autoCompleteProps}
    />
  );
};

SelectAsync.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.oneOfType([ PropTypes.string, PropTypes.func ]),
  getOptions: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.oneOfType([ PropTypes.bool, PropTypes.func ]),
  initialOptions: PropTypes.bool,
  forceUpdate: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
  error: PropTypes.bool,
  useScientificNameFormat: PropTypes.bool,
  useScientificNameFormatInPlaceholder: PropTypes.bool,
  clearable: PropTypes.bool,
  debounce: PropTypes.bool,
};


export {
  SelectAsync,
};