import React from 'react';
import PropTypes from 'prop-types';
import { Button, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';


const MethodologyDeleteDialog = ({ actions, methodology }) => {
  const { id, name, typeName, samplingStationName } = methodology;
  return <>
    <DialogTitle id="form-dialog-title">Eliminar metodología</DialogTitle>
    <DialogContent>
      <Typography variant="body1" color="textPrimary">
        Estás por eliminar la metodología <strong>{name}</strong> de tipo <strong>{typeName}</strong> ubicada
        en la <strong>estación {samplingStationName}</strong>, esta acción es irreversible y
        <Typography component='span' display="inline" color="error"> se perderán todos los datos</Typography> asociados a la metodología.
      </Typography>
      <br />
      <Typography variant="body1" color="textPrimary">
        <strong>¿Realmente deseas eliminar la metodología?</strong>
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={actions.closeDialog}>Cancelar</Button>
      <Button onClick={() => actions.deleteMethodology({ id })} color="primary">
          Confirmar
      </Button>
    </DialogActions>
  </>;
};

MethodologyDeleteDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  methodology: PropTypes.object,
};


export { MethodologyDeleteDialog };