import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Box, Button } from '@material-ui/core';
import { AddBox, FilterList } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import { Pagination, PaginationItem } from '@material-ui/lab';
import urljoin from 'url-join';

import { CompanyDrawer, TitleContainer, ContentContainer, ActionsBox, DialogWrap, AccessControl } from 'src/components';
import { CampaignCreateDialog, CampaignDeleteDialog, CampaignEditDialog, CampaignCard,
  CampaignsFiltersDialog, StartCensusCampaignDialog } from 'src/scenes/Project/scenes/Campaigns/components';
import { campaignsApi, usersApi, projectsApi, floraApi, faunaApi } from 'src/services';
import { WebContext } from 'src/scenes/web-context';
import { campaignSubtypeLanguage } from 'src/utils/componentLanguage';
import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';


const useStyles = theme => ({
  content: {
    maxWidth: theme.spacing(110),
  },
  mainContainer: {
    width: 'calc(100% - 240px)',
  },
  filterButton: {
    marginRight: theme.spacing(1),
    minWidth: '30px',
    padding: '4px 7px',
    minHeight: '30px',
  },
});

const { NOT_STARTED, LOADING, SUCCESS, ERROR } = fetchStatusEnum;
class CampaignsContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      campaignsList: [],
      totalCampaignsCount: 0,
      options: {},
      project: {},
      dialog: {
        isOpen: false,
        data: {},
        contentType: '',
      },
      requestsStatus: NOT_STARTED,
      projectBelongsToUser: false,
      companyDrawerShouldUpdate: { latestCampaigns: false },
      companyDrawerStopUpdate: () => this.setState({ companyDrawerShouldUpdate: { latestCampaigns: false } }),
      catalogs: { flora: [], fauna: [] },
      startCampaignFetchStatus: fetchStatusEnum.NOT_STARTED,
      startCampaignErrorMsg: '',
    };
  }

  componentDidMount = async () => {
    this.setState({ requestsStatus: LOADING });
    try {
      await this.loadData();
      await this.getValidCatalogs();
      this.setState({ requestsStatus: SUCCESS });
    } catch (e) {
      console.error(e);
      this.setState({ requestsStatus: ERROR });
    }
  };

  getValidCatalogs = async () => {
    const [ floraCatalogs, faunaCatalogs ] = await Promise.all([ floraApi.getCatalogs(), faunaApi.getCatalogs() ]);
    this.setState({ catalogs: {
      flora: floraCatalogs.filter(c => c.selectable).map(c => ({ label: c.displayName, value: c.id })),
      fauna: faunaCatalogs.filter(c => c.selectable).map(c => ({ label: c.displayName, value: c.id })),
    } });
  };

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (nextProps.match.params.projectId !== prevState.project.id) {
      return {
        project: { ...prevState.project, id: nextProps.match.params.projectId },
      };
    }
    return null;
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const sameListButDifferentQuery = prevState.campaignsList === this.state.campaignsList &&
      prevProps.location.search !== this.props.location.search;

    if (prevProps.match.params.projectId !== this.state.project.id) {
      await this.loadData();
    } else if (sameListButDifferentQuery) {
      await this.getAndSetCampaigns();
    }
  };

  getAndSetCampaigns = async () => {
    const { projectId } = this.props.match.params;
    const queryString = new URLSearchParams(this.props.location.search);
    projectId && queryString.set('projectId', projectId);
    const { campaignsList, totalCampaignsCount } = await campaignsApi.getCampaigns(queryString.toString());
    this.setState({ campaignsList, totalCampaignsCount });
  };

  loadData = async () => {
    const { projectId } = this.props.match.params;
    this.context.changeCurrentPath(this.props.match.path);

    const [ companyUsers, project, campaignMainTypesOptions, campaignMethodsInComponentOptions, censusFields ] = await Promise.all([
      usersApi.getUsersByCompany(),
      projectsApi.getProject(projectId),
      campaignsApi.getMainTypes(),
      campaignsApi.getMainMethodsInComponent(),
      floraApi.getCensusFields(),
      this.getAndSetCampaigns(),
    ]);

    this.context.setCensusFields(censusFields);

    this.context.setSelectedProject({ id: project.id, name: project.projectName, userId: project.userId });
    document.title = `${project.projectName} - Nviro Capture`;

    const projectBelongsToUser = parseInt(project.userId, 10) === parseInt(this.context.currentUser.id, 10);
    this.setState({
      project,
      projectBelongsToUser,
      options: {
        companyUsersOptions: companyUsers.map(user => ({ value: user.id, label: user.email, isFixed: user.id === project.userId })),
        campaignMainTypesOptions,
        campaignMethodsInComponentOptions,
        censusSettingsOptions: censusFields.sort((a, b) => a.name < b.name ? -1 : (a.name === b.name ? 0 : 1))
          .reduce((settings, field, ind) => {
            const { isActiveByDefault: isActive, isRequiredByDefault: isRequired, ...rest } = field;
            settings.fields.push({
              isActive, isRequired, ...rest,
            });
            if (field.canBeParameter) {
              settings.parameterOptions.push({ value: ind, label: field.label });
            }
            return settings;
          }, { fields: [], parameterOptions: [] }),
      },
    });
  };

  defaultFilters = {
    status: [ 'pending', 'in-progress', 'finished' ],
    participation: 'all',
    component: [ 'flora', 'fauna' ],
  };


  getQueryParams = () => {
    const query = new URLSearchParams(this.props.location.search);

    const tempSize = parseInt(query.get('size') || 5, 10);
    const size = tempSize < 10 ? tempSize : 10;
    const page = parseInt(query.get('page') || 1, 10);

    const status = query.getAll('status').length ? query.getAll('status') : undefined;
    const component = query.getAll('component').length ? query.getAll('component') : undefined;
    const participation = query.get('participation') || undefined;
    return { page, size, status, component, participation };
  };

  addFiltersToQuery = (query, filters) => {
    Object.keys(filters).forEach(key => {
      if (Array.isArray(filters[key])) {
        query.delete(key);
        filters[key].forEach(val => query.append(key, val));
      } else if (filters[key] === undefined) {
        query.delete(key);
      } else {
        query.set(key, filters[key]);
      }
    });
  };

  applyFilters = async filters => {
    this.updateFiltersAndPage({ filters, page: 1 });
    await this.setState({ dialog: { isOpen: false } });
  };

  updateFiltersAndPage = async ({ filters = {}, page = 1 }) => {
    const { location, history } = this.props;
    const query = new URLSearchParams(location.search);
    this.addFiltersToQuery(query, { ...filters, page });
    await history.push({ pathname: this.props.location.pathname, search: `?${query.toString()}` });
  };

  handlePage = async (event, page) => {
    const query = new URLSearchParams(this.props.location.search);
    query.set('page', page);
    await this.props.history.push({ pathname: this.props.location.pathname, search: `?${query.toString()}` });
  };

  closeDialog = () => this.setState({
    dialog: { isOpen: false }, contentType: '', startCampaignFetchStatus: NOT_STARTED, startCampaignErrorMsg: '',
  });

  toggleDialog = ({ data = {}, contentType = '' } = {}) => this.setState(ps => ({
    dialog: { isOpen: !ps.dialog.isOpen, data, contentType },
  }));

  getDialogType = () => {
    const { dialog, project, options, startCampaignFetchStatus, startCampaignErrorMsg } = this.state;
    const { isFan } = this.context;
    const commonActions = { closeDialog: this.closeDialog };
    const userIsFan = isFan();

    switch (dialog.contentType) {
      case 'filter':
        return <CampaignsFiltersDialog
          prevFilters={dialog.data}
          defaultFilters={this.defaultFilters}
          actions={{ applyFilters: this.applyFilters, ...commonActions }}
        />;
      case 'edit':
        return <CampaignEditDialog
          campaign={dialog.data}
          isFan={userIsFan}
          companyUsersOptions={options.companyUsersOptions}
          actions={{ editCampaign: this.editCampaign, ...commonActions }}
        />;
      case 'delete':
        return <CampaignDeleteDialog
          actions={{ deleteCampaign: () => this.deleteCampaign(dialog.data), ...commonActions }}
        />;
      case 'create':
        return <CampaignCreateDialog
          projectId={parseInt(this.props.match.params.projectId, 10)}
          projectUserId={project.userId}
          options={options}
          isFan={userIsFan}
          catalogs={this.state.catalogs}
          actions={{ createCampaign: this.createCampaign, ...commonActions }}
        />;
      case 'start-census':
        return <StartCensusCampaignDialog
          campaignHash={dialog.data}
          userIsFan={userIsFan} fetchStatus={startCampaignFetchStatus} fetchErrorMessage={startCampaignErrorMsg}
          actions={{ startCampaign: this.startCampaign, ...commonActions }}
        />;
      default:
        break;
    }
  };

  createCampaign = async newCampaign => {
    const { size } = this.getQueryParams();
    const createdCampaign = await campaignsApi.createCampaign(newCampaign);
    createdCampaign.projectName = this.context.selectedProject.name;
    createdCampaign.ownerId = this.context.currentUser.id;
    this.setState(prevState => ({
      campaignsList: [ createdCampaign, ...prevState.campaignsList.filter((p, index) => index + 1 < size) ],
      totalCampaignsCount: prevState.totalCampaignsCount + 1,
    }));
  };

  deleteCampaign = async campaignHash => {
    const projectId = parseInt(this.props.match.params.projectId, 10);

    await campaignsApi.deleteCampaign({ campaignHash });

    const { page: currentPage, size } = this.getQueryParams();
    const nextCampaignIndex = currentPage * size;
    const { totalCampaignsCount, campaignsList } = await campaignsApi.getCampaigns({ projectId, page: nextCampaignIndex, size: 1 });
    const campaignInLastPosition = totalCampaignsCount >= size ? campaignsList : [];

    // retroceder una página si se borró el último elemento a no ser que se esté en la 1ra página
    if (this.state.campaignsList.length === 1 && currentPage > 1) {
      this.setState({
        companyDrawerShouldUpdate: { latestCampaigns: true },
      });
      this.updateFiltersAndPage({ page: currentPage - 1 });
    } else {
      this.setState(prevState => ({
        campaignsList: [ ...prevState.campaignsList.filter(campaign => campaign.hash !== campaignHash), ...campaignInLastPosition ],
        companyDrawerShouldUpdate: { latestCampaigns: true },
        totalCampaignsCount,
      }));
    }
  };

  editCampaign = async (campaignHash, campaignToEdit) => {
    const updatedCampaign = await campaignsApi.editCampaign(campaignHash, campaignToEdit);
    if (updatedCampaign) {
      this.setState(prevState => ({
        campaignsList: prevState.campaignsList.map(campaign => campaign.hash === campaignHash ? updatedCampaign : campaign),
        companyDrawerShouldUpdate: { latestCampaigns: true },
      }));
    } else {
      this.setState(prevState => ({
        campaignsList: prevState.campaignsList.filter(campaign => campaign.hash !== campaignHash),
        companyDrawerShouldUpdate: { latestCampaigns: true },
      }));
    }
  };

  isPending = campaignStatus => campaignStatus === 'pending';

  goToCampaign = ({ hash: campaignHash }) =>
    this.props.history.push(urljoin('/web/campaigns', campaignHash));

  goToPlannification = ({ hash: campaignHash }) =>
    this.props.history.push(urljoin('/web/campaigns', campaignHash, 'planning'));

  goTo = campaign => {
    usersApi.addLastCampaignViewed(campaign.id);
    this.isPending(campaign.statusId) ? this.goToPlannification(campaign) : this.goToCampaign(campaign);
  };

  startCampaign = async ({ campaignHash }) => {
    if (this.state.startCampaignFetchStatus === fetchStatusEnum.LOADING) {
      return;
    }
    this.setState({ startCampaignFetchStatus: fetchStatusEnum.LOADING });
    try {
      await campaignsApi.startCampaign(campaignHash);
      setTimeout(() => this.setState({ startCampaignFetchStatus: fetchStatusEnum.SUCCESS }), 500);
      setTimeout(() => this.props.history.replace(`/web/campaigns/${campaignHash}`), 4500);
    } catch (e) {
      console.error(e);
      this.setState({
        startCampaignFetchStatus: fetchStatusEnum.ERROR,
        startCampaignErrorMsg: e.serverMessage || e.message || 'Error inesperado, por favor inténtalo más tarde',
      });
    }
  };

  render() {
    const { campaignsList, dialog, companyDrawerShouldUpdate: { latestCampaigns }, companyDrawerStopUpdate,
      projectBelongsToUser, totalCampaignsCount, requestsStatus, startCampaignFetchStatus } = this.state;
    const userIsAdmin = this.context.checkIfAdminPowers();
    const { classes } = this.props;
    const { selectedCompany: { role: userRole }, currentUser: { id: currentUserId } } = this.context;
    const { page, size, ...filters } = this.getQueryParams();
    const totalPages = Math.ceil(totalCampaignsCount / size);
    const projectOwnerId = parseInt(this.state.project.userId, 10);
    const clickAwayClosesDialog = (dialog.contentType !== 'create' && dialog.contentType !== 'start-census') ||
      (dialog.contentType === 'start-census' && startCampaignFetchStatus !== fetchStatusEnum.SUCCESS
        && startCampaignFetchStatus !== fetchStatusEnum.LOADING);

    return (
      <Fragment>
        <CompanyDrawer shouldUpdate={{ latestCampaigns }} stopUpdate={companyDrawerStopUpdate} />
        <Grid container className={classes.mainContainer}>
          <TitleContainer breadcrumbs={[ 'company', 'campaigns' ]}>Campañas</TitleContainer>
          <ContentContainer className={classes.content}>
            <ActionsBox>
              <Button className={classes.filterButton} size="small" variant="contained" color="primary"
                onClick={() => this.toggleDialog({ contentType: 'filter', data: filters })}><FilterList fontSize="small" /></Button>
              <AccessControl action="campaign:create" extraCondition={ projectBelongsToUser || userIsAdmin }>
                <Button size="small" variant="contained" color="primary" onClick={() => this.toggleDialog({ contentType: 'create' })}
                  startIcon={<AddBox />} disabled={ requestsStatus !== SUCCESS }>Crear Campaña</Button>
              </AccessControl>
            </ActionsBox>
            {userRole && campaignsList.map(campaign =>
              <CampaignCard
                key={campaign.hash}
                campaign={campaign}
                isPending={this.isPending}
                cLang={campaignSubtypeLanguage(campaign.mainMethodId)}
                companyRole={ userRole }
                currentUserId={ currentUserId }
                actions={{ openDialog: this.toggleDialog, goTo: this.goTo }}
                projectOwnerId={ projectOwnerId }
              />,
            )}
            {!campaignsList.length &&
              <Box><Typography variant="body1" color="textPrimary">
                No se han encontrado campañas, para crear una haz clic en el botón &ldquo;Añadir campaña&rdquo;
              </Typography></Box> }

            {totalCampaignsCount > size &&
              <Box pt={3} display="flex" justifyContent="center" style={{ marginBottom: '20px' }}>
                <Pagination page={page} count={totalPages} variant="outlined" shape="rounded" color="primary" onChange={this.handlePage}
                  renderItem={item => (
                    <PaginationItem {...item} />
                  )}
                />
              </Box>
            }
          </ContentContainer>
        </Grid>
        <DialogWrap maxWidth={this.state.dialog.contentType === 'create' ? 'md' : 'sm'}
          fullWidth { ...(clickAwayClosesDialog && { onClose: this.closeDialog })}
          aria-labelledby="form-dialog-title" open={dialog.isOpen}>
          { this.getDialogType() }
        </DialogWrap>
      </Fragment>
    );
  }
}

CampaignsContainer.propTypes = {
  match: PropTypes.object.isRequired,
  classes: PropTypes.object,
  history: PropTypes.any,
  location: PropTypes.object.isRequired,
};

CampaignsContainer.contextType = WebContext;

const CampaignsContainerWithStyles = withStyles(useStyles)(CampaignsContainer);


export {
  CampaignsContainerWithStyles as CampaignsContainer,
};
