import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Box, Chip, Avatar, Button, Typography } from '@material-ui/core';
import { Place as PlaceIcon, DeleteForever } from '@material-ui/icons/';
import { red } from '@material-ui/core/colors';
import clsx from 'clsx';

import { TooltipWrapper as Tooltip } from 'src/components/tooltipWrapper/TooltipWrapper';


const useStyles = makeStyles(theme => ({
  mapPaper: {
    position: 'absolute',
    top: theme.spacing(18) + 4,
    right: theme.spacing(3),
    zIndex: theme.zIndex.drawer,
    pointerEvents: 'initial',
    width: theme.spacing(46),
    padding: theme.spacing(2),
    height: '82vh',
  },
  subtitle: {
    lineHeight: '1.3',
  },
  chipAvatar: {
    backgroundColor: '#FFF',
  },
  selectedPoints: {
    minHeight: '2em',
    height: '70%',
    overflowY: 'scroll',
  },
  editionButton: {
    whiteSpace: 'nowrap',
    width: ({ componentId }) => componentId === 'fauna' ? '130px' : '100%',
    marginRight: ({ componentId }) => componentId === 'fauna' ? 'unset' : theme.spacing(2),
    backgroundColor: theme.palette.common.black,
    '&:hover': {
      backgroundColor: theme.palette.common.gray800,
    },
  },
  deleteButton: {
    backgroundColor: ({ hasSomePointSelected }) => hasSomePointSelected ? red[500] : '',
    '&:hover': {
      backgroundColor: red[900],
    },
    color: ({ hasSomePointSelected }) => hasSomePointSelected ? 'white' : '',
    width: ({ userIsFan }) => userIsFan ? '100%' : '10px',
    whiteSpace: 'nowrap',
  },
  unselectButton: {
    marginBottom: '1em',
    marginTop: '1em',
  },
  helpParagraph: {
    marginTop: '0.5em',
  },
}));

const PlanningSidePanel = props => {
  const { className, titleProps, isSomePointNotAssigned = true, selectedPoints = [],
    actions = {}, csLang, userIsFan, componentId } = props;

  const hasSomePointSelected = selectedPoints.length > 0;
  const classes = useStyles({ hasSomePointSelected, userIsFan, componentId });
  return (
    <Paper className={clsx(classes.mapPaper, className)}>
      { csLang?.Points &&
        <Box display="flex" flexDirection="column" my={1} height="100%">
          <Typography className={titleProps} variant="h4" display="inline" color="primary">Planificar campaña</Typography>
          <Box mt={2}>
            <Typography className={classes.subtitle} variant="h6" color="textPrimary">
              {`${csLang.Points} seleccionadas: ${selectedPoints.length}`}
            </Typography>
          </Box>
          {hasSomePointSelected &&
            <Box alignSelf="flex-end">
              <Button className={classes.unselectButton} variant="outlined" size="small"
                // TODO: buscar una manera más limpia de obtener el container de pixi
                onClick={() => selectedPoints.length && selectedPoints[0].parent.metaData.deselectMarkers(selectedPoints)}>
                  Desmarcar todas
              </Button>
            </Box>}
          <Box className={classes.selectedPoints}>
            {hasSomePointSelected ?
              <Box style={{ textAlign: 'center' }}>
                {selectedPoints.map(point =>
                  <Chip key={point.metaData.id} size="small" style={{ marginLeft: 5, marginTop: 5 }} variant="outlined"
                    label={point.metaData.name} onDelete={point.metaData.deselect}
                    avatar={<Avatar className={classes.chipAvatar} style={{ color: point.metaData.color }}>
                      <PlaceIcon />
                    </Avatar>} />,
                )}
              </Box> :
              <Box>
                <Typography variant="body2" color="textPrimary" className={ classes.helpParagraph }>
                  No has seleccionado ninguna {csLang.point}. Si necesitas editar los datos de alguna {csLang.point} debes seleccionarla.
                </Typography>
                <Typography variant="body2" color="textPrimary" className={ classes.helpParagraph }>
                  Puedes crear {csLang.points} con el botón de marcador en la parte izquierda del mapa.
                </Typography>
                { !userIsFan &&
                  <Typography variant="body2" color="textPrimary" className={ classes.helpParagraph }>
                    (Debes asignar a cada {csLang.point} creada a alguien que se encargue de ella)
                  </Typography>
                }
              </Box>
            }
          </Box>

          <Box flexGrow={1} />

          <Box display="flex" justifyContent="space-between" pt={3}>
            { componentId === 'fauna' &&
              <Button
                className={classes.editionButton} variant="contained" color="secondary"
                onClick={actions.openSuggestMethodologiesDialog} disabled={!hasSomePointSelected}
              >
                Metodologías
              </Button>
            }
            {!userIsFan &&
              <Button
                className={classes.editionButton} variant="contained" color="secondary"
                onClick={actions.openAssignPointsDialog} disabled={!hasSomePointSelected}
              >
                Asignar
              </Button>
            }
            <Tooltip title={`Borrar ${csLang.points} seleccionadas`}
              arrow placement="top"
            >
              <Box>
                <Button className={classes.deleteButton} variant="contained" onClick={actions.openDeletePointsDialog}
                  disabled={!hasSomePointSelected}
                >
                  <DeleteForever/>
                </Button>
              </Box>
            </Tooltip>
          </Box>
          <Box my={1} display="flex">
            {isSomePointNotAssigned ?
              <Tooltip title={`Para finalizar la planificación todas las ${csLang.points} deben estar asignadas`}
                arrow
              >
                <Box width="100%">
                  <Button fullWidth variant="contained" onClick={actions.openFinishPlanningDialog}
                    disabled={isSomePointNotAssigned} color="primary">
                    Terminar planificación
                  </Button>
                </Box>
              </Tooltip> :
              <Box width="100%">
                <Button fullWidth variant="contained" onClick={actions.openFinishPlanningDialog}
                  disabled={isSomePointNotAssigned} color="primary">
                  Terminar planificación
                </Button>
              </Box>
            }
          </Box>
        </Box>
      }
    </Paper>
  );
};

PlanningSidePanel.propTypes = {
  className: PropTypes.string,
  actions: PropTypes.object,
  isSomePointNotAssigned: PropTypes.bool,
  selectedPoints: PropTypes.array,
  csLang: PropTypes.object,
  userIsFan: PropTypes.bool,
  titleProps: PropTypes.string,
  componentId: PropTypes.string,
};


export {
  PlanningSidePanel,
};
