import React, { useState } from 'react';
import { ImageList, ImageListItem, ImageListItemBar, IconButton, Button, InputLabel, FormHelperText, Box } from '@material-ui/core';
import { HighlightOff, AddAPhoto, Edit } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { CropPhoto, DialogWrap } from 'src/components';
import { readExifFromBlob, exifAndBlobToBlob } from 'src/utils/util';


const cleanExifForCrop = exifObj => {
  delete exifObj.thumbnail;
  delete exifObj.Image;
  delete exifObj['0th'];
  delete exifObj['1st'];
};

const useStyles = makeStyles(theme => ({
  addPhotoButton: {
    width: '135px',
    height: '120px',
  },
  hidden: {
    display: 'none',
  },
  redElement: {
    color: 'red',
  },
  whiteElement: {
    color: 'white',
  },
  backEdit: {
    height: '25px',
    width: '25px',
    opacity: '0.8',
  },
  imageItem: {
    borderRadius: theme.shape.borderRadius,
  },
  imageListItem: {
    border: `1px solid ${theme.palette.common.gray}`,
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      border: `1px solid ${theme.palette.common.black}`,
    },
  },
  addPhotoImg: {
    height: '30px',
    width: '30px',
  },
  noBackgroundColor: {
    background: 'rgba(0,0,0,0)',
  },
  actionsButtons: {
    marginTop: '12px',
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
    background: 'rgba(0, 0, 0, 0.5)',
  },
  itemBar: {
    height: theme.spacing(4),
  },
}));

const PhotoInput = props => {
  const { imgFiles, imgFilesData, onChange, inputLabelText, onlyJpg, required } = props;

  const classes = useStyles();
  const [ isInvalidFile, setIsInvalidFile ] = useState(false);
  const hiddenFileInput = React.useRef();
  const [ editValues, setEditValues ] = useState({ isOpen: false, index: -1, editedImage: null });

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleImageUpload = async obj => {
    const newImgsList = [ ...imgFiles ];
    const newImgsDataList = [ ...imgFilesData ];
    let hasError = false;
    const uploadedFiles = Array.from(obj.target.files);
    for (let i = 0; i < uploadedFiles.length; i++) {
      const image = uploadedFiles[i];
      const imageFormat = image.name.split('.').pop().toLowerCase();
      if (onlyJpg && (imageFormat === 'jpg' || imageFormat === 'jpeg')) {
        newImgsList.push(image);
        // para ponerle en el created_at
        const dateString = (new Date(image.lastModified)).toISOString();
        newImgsDataList.push({ dateString });
      } else {
        hasError = true;
      }
    }

    setIsInvalidFile(hasError);
    if (newImgsList.length > 0) {
      onChange({ imgFiles: newImgsList, imgFilesData: newImgsDataList });
    }
  };

  const deleteElement = index => {
    const newImgsList = imgFiles.filter((element, i) => i !== index);
    const newImgsDataList = imgFilesData.filter((element, i) => i !== index);
    onChange({ imgFiles: newImgsList, imgFilesData: newImgsDataList });
  };

  const startEditingImage = index => setEditValues({ isOpen: true, index, editedImage: null });

  const onCancel = () => setEditValues({ isOpen: false, index: -1 });

  const onSaveCrop = async newImage => {
    const imgIndex = editValues.index;
    // tomar el exif de la imagen original
    const exifObj = await readExifFromBlob(imgFiles[imgIndex]);
    // limpiar thumbnail, ya que recortamos, y otras cosas dejando solo los propiedades Exif y Gps de los metadatos
    cleanExifForCrop(exifObj);
    // y le ponemos el exif al recorte
    const finalImage = await exifAndBlobToBlob(exifObj, newImage);
    const newImagesList = imgFiles.map((image, idx) => idx === imgIndex ? finalImage : image);
    // imgFilesData no se cambia, con lo que se preserva ese dato de la imagen original también
    onChange({ imgFiles: newImagesList });
    setEditValues({ isOpen: false, index: -1 });
  };

  return (
    <>
      <Box my={1}>
        <InputLabel shrink required={required}>
          {inputLabelText}
        </InputLabel>
        {isInvalidFile &&
          <FormHelperText error>Solo se cargaron las imágenes de formato válido (.jpg, .jpeg).</FormHelperText>
        }
        <input type="file" name="myImage" accept="image/jpeg" ref={hiddenFileInput}
          className={ classes.hidden } onChange={handleImageUpload} multiple/>
      </Box>
      <Box my={1}>
        <ImageList cols={4} rowHeight={120}>
          {imgFiles && imgFiles.map((item, index) => (
            <ImageListItem key={`${index}-${item.name}`} classes={{ item: classes.imageItem }}>
              <img src={URL.createObjectURL(item)} alt=''/>
              <ImageListItemBar title={item.name} classes={{ root: classes.itemBar }} />
              <ImageListItemBar className={classes.noBackgroundColor}
                position="top"
                actionIcon={
                  <Box display="flex" alignItems="center" flexDirection="column" className={classes.actionsButtons}>
                    <IconButton onClick={() => deleteElement(index)} size="small">
                      <HighlightOff className={classes.redElement}/>
                    </IconButton>
                    <IconButton onClick={() => startEditingImage(index)} size="small">
                      <Edit className={classes.whiteElement}/>
                    </IconButton>
                  </Box>
                }
              />
            </ImageListItem>
          ))}
          <ImageListItem className={classes.imageListItem}>
            <Box display="flex" alignItems="center">
              <Button onClick={handleClick} className={classes.addPhotoButton}>
                <AddAPhoto className={classes.addPhotoImg}/>
              </Button>
            </Box>
          </ImageListItem>
        </ImageList>
      </Box>
      <DialogWrap maxWidth='sm' fullWidth onClose={onCancel} aria-labelledby='form-dialog-title' open={editValues.isOpen}>
        <CropPhoto baseImage={imgFiles[editValues.index]} onSave={onSaveCrop} onCancel={onCancel}/>
      </DialogWrap>
    </>
  );
};

PhotoInput.propTypes = {
  imgFiles: PropTypes.array,
  imgFilesData: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  inputLabelText: PropTypes.string,
  onlyJpg: PropTypes.bool,
  required: PropTypes.bool,
};


export { PhotoInput };