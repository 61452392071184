import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Avatar, Box } from '@material-ui/core';
import PropTypes from 'prop-types';


const useFeatureStyles = makeStyles(theme => ({
  featureTitle: {
    fontSize: '1.55rem',
    color: 'rgba(81, 147, 235, 1)',
    textTransform: 'uppercase',
    fontWeight: 600,
    lineHeight: 1,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
    },
  },
  featureDescription: {
    fontSize: '1.4rem',
    color: '#40474B',
    lineHeight: 1,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.05rem',
    },
  },
  featureIcon: {
    height: '64px',
    width: '64px',
    [theme.breakpoints.down('xs')]: {
      height: '56px',
      width: '56px',
    },
  },
}));

const Feature = ({ title, description, imageSrc }) => {
  const classes = useFeatureStyles();
  return (
    <Box display="flex" alignItems="center" py={3}>
      <Box px={3}>
        <Avatar className={classes.featureIcon} size="large" src={imageSrc} />
      </Box>
      <Box display="flex" flexDirection="column">
        <Box>
          <Typography className={classes.featureTitle}>{title}</Typography>
        </Box>
        <Box pt={1}>
          <Typography className={classes.featureDescription}>{description}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

Feature.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  imageSrc: PropTypes.string,
};


export { Feature };