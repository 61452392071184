import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, InputLabel, TextField, FormHelperText, DialogActions, Collapse } from '@material-ui/core';
import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';
import { Alert } from '@material-ui/lab';
import { DateTimePicker } from '@material-ui/pickers';

import { DialogButton, PhotoInput } from 'src/components';
import { PhenologiesAndPhytosanitaryStatusesInputs } from 'src/scenes/Campaign/scenes/Records/components/FloraRecordInputs';


const { NOT_STARTED, LOADING, SUCCESS, ERROR, BAD_DATA } = fetchStatusEnum;

const fields = [
  {
    key: 'number', label: 'Cantidad', isValid: data => 1 <= data && data <= 1000,
    errorMsg: 'Solo se permiten valores entre 1 y 1000.',
  },
  { key: 'height', label: 'Altura (m)', isValid: data => 0 <= data && data <= 1000,
    errorMsg: 'Solo se permiten valores entre 0 y 1000.',
  },
  {
    key: 'firstDiameter', label: 'Diámetro de copa 1 (m)', isValid: data => 0 <= data && data <= 100,
    errorMsg: 'Solo se permiten valores entre 0 y 100.' },
  {
    key: 'secondDiameter', label: 'Diámetro de copa 2 (m)', isValid: data => 0 <= data && data <= 100,
    errorMsg: 'Solo se permiten valores entre 0 y 100.',
  },
  {
    key: 'numberOfOffshoots', label: 'Vástagos', isValid: data => 1 <= data && data <= 50,
    errorMsg: 'Solo se permiten valores entre 1 y 50.',
  },
  {
    key: 'dap', label: 'DAP (cm)', isValid: data => 0 <= data && data <= 2000,
    errorMsg: 'Solo se permiten valores entre 0 y 2000.',
  },
];

const ForestTreeCreateDialog = ({ actions, options }) => {
  const { phenologiesStatusesOptions, phytosanitaryStatusesOptions } = options;

  const fieldsDefault = fields.reduce((obj, curr) => {
    obj[curr.key] = '';
    return obj;
  }, {});

  const [ tree, setTree ] = useState({
    detectionDate: new Date(),
    phenologiesStatuses: [],
    phytosanitaryStatuses: [],
    ...fieldsDefault,
  });

  const [ fetchStatus, setFetchStatus ] = useState(NOT_STARTED);
  const hasBadData = fetchStatus === BAD_DATA;

  const [ errorAlert, setErrorAlert ] = useState({ isOpen: false, message: '' });

  const handleChange = e => setTree({ ...tree, [e.target.name]: e.target.value });

  const handleSelect = obj => setTree({ ...tree, ...obj });

  // imgFilesData es un array de objetos con datos sobre las imágenes de imgFiles (imgFilesData[i] son los datos de imgFiles[i])
  const handleImagesChange = useCallback(({ imgFiles, imgFilesData }) => setTree(pt =>
    ({ ...pt, imgFiles, ...(imgFilesData && { imgFilesData }) }),
  ), []);

  const handleConfirm = async () => {
    const isValid = fields.every(({ key, isValid }) => isValid(tree[key]));

    try {
      setFetchStatus(LOADING);

      if (isValid) {
        await actions.createTree({ ...tree, ...(tree.imgFilesData && { imgFilesData: JSON.stringify(tree.imgFilesData) }) });
        setFetchStatus(SUCCESS);
        setTimeout(() => actions.closeDialog(), 300);
      } else {
        setFetchStatus(BAD_DATA);
      }
    } catch (e) {
      console.error(e);
      setErrorAlert({ isOpen: true, message: e.serverMessage || e.message });
      setFetchStatus(ERROR);
    }
  };

  const { observation, detectionDate } = tree;

  return (
    <>
      <DialogTitle>Crear agrupación de individuos forestales</DialogTitle>
      <DialogContent>
        <Box p={1}>
          {fields.map(({ key, label, isValid, errorMsg }, i) =>
            <Box my={1} key={i}>
              <InputLabel shrink>{label}</InputLabel>
              <TextField
                value={tree[key]}
                inputProps={{ min: 0 }}
                name={key}
                onChange={handleChange}
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                autoComplete="off"
                error={hasBadData && !isValid(tree[key])}
              />
              {hasBadData && !isValid(tree[key]) && <FormHelperText error>{errorMsg}</FormHelperText>}
            </Box>)
          }

          <PhenologiesAndPhytosanitaryStatusesInputs
            record={tree}
            handlers={{ handleSelect }}
            options={{ phenologiesStatusesOptions, phytosanitaryStatusesOptions }}
          />

          <Box my={2}>
            <InputLabel shrink>Fecha de detección</InputLabel>
            <Box display="flex" alignItems="center">
              <Box display="flex" flexDirection="column">
                <DateTimePicker
                  value={detectionDate}
                  ampm={false}
                  TextFieldComponent={props => <TextField {...props} variant="outlined" size="small" />}
                  onChange={detectionDate => handleSelect({ detectionDate: new Date(detectionDate) })}
                  format="DD-MM-YYYY HH:mm"
                />
              </Box>
            </Box>
          </Box>

          <Box my={2}>
            <InputLabel shrink>Observaciones</InputLabel>
            <TextField name="observation" value={observation} onChange={handleChange} minRows="2"
              maxRows="5" multiline fullWidth autoComplete="off" variant="outlined" size="small" />
          </Box>
          <Box my={2}>
            <PhotoInput
              onChange={handleImagesChange}
              imgFiles={tree.imgFiles || []}
              imgFilesData={tree.imgFilesData || []}
              inputLabelText={'Evidencia (.jpg, .jpeg)'}
              onlyJpg
            />
          </Box>

          {fetchStatus === ERROR &&
              <Collapse in={errorAlert.isOpen}>
                <Alert severity="error" onClose={() => setErrorAlert(s => ({ ...s, isOpen: false }))}>
                  {errorAlert.message}
                </Alert>
              </Collapse>
          }
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>Crear</DialogButton>
      </DialogActions>
    </>);
};

ForestTreeCreateDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  options: PropTypes.object,
};


export { ForestTreeCreateDialog };