import React, { PureComponent } from 'react';
import ImageGallery from 'react-image-gallery';
import PropTypes from 'prop-types';

import 'react-image-gallery/styles/scss/image-gallery.scss';


const descriptionMaker = (pic, title) => {
  const finalTitle = pic.title || title;
  const eeccString = pic.eeccCategory ? ` Categoría de conservación: ${pic.eeccCategory}.` : '';
  return `${finalTitle}.${eeccString}`;
};
export class DialogImagePreviewer extends PureComponent {
  render () {
    const { index, pictures, title, ...props } = this.props;
    return (
      pictures.length > 0 &&
        <ImageGallery
          ref={ ref => this.imageGalery = ref }
          items={ pictures.map((p => ({
            original: p.url || p,
            thumbnail: p.url || p,
            originalTitle: p.title || title,
            description: descriptionMaker(p, title),
            originalAlt: descriptionMaker(p, title),
          })))}
          useBrowserFullscreen={ false }
          showBullets
          showIndex
          startIndex={ index }
          slideDuration={0}
          slideInterval={1000}
          { ...props }
        />
    );
  }
}

DialogImagePreviewer.defaultProps = {
  index: 0,
};

DialogImagePreviewer.propTypes = {
  pictures: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
    }),
  ])),
  index: PropTypes.number,
  title: PropTypes.string,
};
