import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';

import { withAccessControl } from 'src/components';


const CompanyDeleteDialog = props => {
  const { actions } = props;
  return (
    <Fragment>
      <DialogTitle id="form-dialog-title">Borrar empresa</DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Typography variant="body1" color="textPrimary">
            ¿Realmente deseas eliminar la empresa? Esto eliminará también los datos asociados
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog} color="secondary">Cancelar</Button>
        <Button onClick={actions.deleteCompany} color="primary">Borrar</Button>
      </DialogActions>
    </Fragment>
  );
};

CompanyDeleteDialog.propTypes = {
  actions: PropTypes.object.isRequired,
};

const CompanyDeleteDialogWithAccessControl = withAccessControl({ action: 'company:delete' })(CompanyDeleteDialog);


export { CompanyDeleteDialogWithAccessControl as CompanyDeleteDialog };