import React, { Component, Fragment } from 'react';
import { Button } from '@material-ui/core';
import { Box, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import PropTypes from 'prop-types';


export class SpeciesQuestionDialog extends Component {
  render () {
    const { speciesName, actions } = this.props;

    return (
      <Fragment>
        <DialogTitle id='form-dialog-title'>
          Consultar por <Box component='span' fontStyle='italic' >{ `"${speciesName}"` }</Box>
        </DialogTitle>
        <DialogContent>
          <Box p={1}>
            Al hacer clic en enviar las imágenes asociadas a este registro se harán públicas para los usuarios
            de Nviro para que puedan ayudarte a reconocer la especie correcta.
            <br/>
            ¿Deseas enviar la consulta
            a nuestros expertos?
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={ actions.toggleDialog } color='secondary'>Cancelar</Button>
          <Button onClick={ actions.askAQuestion } color='primary'>Enviar</Button>
        </DialogActions>
      </Fragment>
    );
  }
}

SpeciesQuestionDialog.propTypes = {
  speciesName: PropTypes.string,
  actions: PropTypes.object.isRequired,
};
