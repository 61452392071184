import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, InputLabel,
  TextField, FormControlLabel, Checkbox, Collapse, FormHelperText } from '@material-ui/core';
import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';
import { Alert } from '@material-ui/lab';
import { DateTimePicker } from '@material-ui/pickers';

import { PhotoInput, CoordinatesInput, DialogButton } from 'src/components';
import { AgeStratumAndSexInputs } from 'src/scenes/Campaign/scenes/Records/components/FaunaRecordInputs';
import { isEmpty } from 'src/utils/util';


const { NOT_STARTED, LOADING, SUCCESS, ERROR, BAD_DATA } = fetchStatusEnum;

const DirectIndividualCreateDialog = props => {
  const { actions, options: { sexTypesOptions, ageStratumsOptions } } = props;

  const [ individual, setIndividual ] = useState({
    ageStratumId: ageStratumsOptions[0].value,
    sexId: sexTypesOptions[0].value,
    observation: '',
    recaptured: false,
    detectionDate: new Date(),
  });

  const [ coords, setCoords ] = useState({ lat: -33.4679997, lng: -70.7003513, accuracy: 0, altitude: 0 });

  const handleSelect = obj => setIndividual({ ...individual, ...obj });

  const handleChange = e => setIndividual({ ...individual, [e.target.name]: e.target.value });

  // imgFilesData es un array de objetos con datos sobre las imágenes de imgFiles (imgFilesData[i] son los datos de imgFiles[i])
  const handleImagesChange = useCallback(({ imgFiles, imgFilesData }) => setIndividual(pi =>
    ({ ...pi, imgFiles, ...(imgFilesData && { imgFilesData }) }),
  ), []);


  const [ fetchStatus, setFetchStatus ] = useState(NOT_STARTED);
  const hasBadData = fetchStatus === BAD_DATA;

  const [ errorAlert, setErrorAlert ] = useState({ isOpen: false, message: '' });

  const handleConfirm = async () => {
    try {
      setFetchStatus(LOADING);
      if (hasCoords) {
        await actions.createIndividual({
          ...individual,
          ...(individual.imgFilesData && { imgFilesData: JSON.stringify(individual.imgFilesData) }),
          latitude: coords.lat,
          longitude: coords.lng,
          altitude: coords.altitude,
          accuracy: coords.accuracy,
        });
        setFetchStatus(SUCCESS);
        setTimeout(() => actions.closeDialog(), 300);
      } else {
        setFetchStatus(BAD_DATA);
      }
    } catch (e) {
      console.error(e);
      setErrorAlert({ isOpen: true, message: e.serverMessage || e.message });
      setFetchStatus(ERROR);
    }
  };

  const { recaptured, observation, detectionDate } = individual;

  const hasCoords = !isEmpty(coords.lat) && !isEmpty(coords.lng);

  return (
    <Fragment>
      <DialogTitle>Crear individuo</DialogTitle>
      <DialogContent>
        <Box p={1}>

          <Box my={2}>
            <InputLabel shrink>Coordenadas</InputLabel>
            <CoordinatesInput required value={coords} onChange={setCoords} />
            {hasBadData && !hasCoords && <FormHelperText error>Debes ingresar latitud y longitud</FormHelperText>}
          </Box>

          <AgeStratumAndSexInputs
            record={individual}
            handlers={{ handleSelect }}
            options={{ sexTypesOptions, ageStratumsOptions }}
          />

          <Box my={2}>
            <FormControlLabel
              label="Recapturado"
              control={
                <Checkbox
                  checked={recaptured}
                  onChange={ () => handleSelect({ recaptured: !recaptured }) }
                  name="Recapturado"
                  color="primary"
                />
              }
            />
          </Box>

          <Box my={2}>
            <InputLabel shrink>Fecha de detección</InputLabel>
            <Box display="flex" alignItems="center">
              <Box display="flex" flexDirection="column">
                <DateTimePicker
                  value={detectionDate}
                  ampm={false}
                  TextFieldComponent={props => <TextField {...props} variant="outlined" size="small" />}
                  onChange={detectionDate => handleSelect({ detectionDate: new Date(detectionDate) })}
                  format="DD-MM-YYYY HH:mm"
                />
              </Box>
            </Box>
          </Box>

          <Box my={2}>
            <InputLabel shrink>Observaciones</InputLabel>
            <TextField value={observation} name="observation" minRows="2" maxRows="5" size="small"
              variant="outlined" multiline fullWidth autoComplete="off" onChange={handleChange} />
          </Box>

          <Box my={2}>
            <PhotoInput
              onChange={handleImagesChange}
              imgFiles={individual.imgFiles || []}
              imgFilesData={individual.imgFilesData || []}
              inputLabelText={'Evidencia (.jpg, .jpeg)'}
              onlyJpg
            />
          </Box>

          {fetchStatus === ERROR &&
              <Collapse in={errorAlert.isOpen}>
                <Alert severity="error" onClose={() => setErrorAlert(s => ({ ...s, isOpen: false }))}>
                  {errorAlert.message}
                </Alert>
              </Collapse>
          }
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>Crear</DialogButton>
      </DialogActions>
    </Fragment>
  );
};

DirectIndividualCreateDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
};


export { DirectIndividualCreateDialog };