import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, TextField } from '@material-ui/core';

import { withAccessControl } from 'src/components';


const CompanyEditDialog = props => {
  const [ company, setCompany ] = useState(props.company);
  const handleChange = e => setCompany({ [e.target.name]: e.target.value });
  const { actions } = props;
  const { name: companyName } = company;
  return (
    <Fragment>
      <DialogTitle id="form-dialog-title">Editar empresa</DialogTitle>
      <DialogContent>
        <Box p={1}>
          <TextField label="Nombre de la empresa" name="name" value={companyName} onChange={handleChange}
            fullWidth autoFocus autoComplete="off" />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog} color="secondary">Cancelar</Button>
        <Button onClick={() => actions.editCompany(company)} color="primary">Editar</Button>
      </DialogActions>
    </Fragment>
  );
};

CompanyEditDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
};

const CompanyEditDialogWithAccessControl = withAccessControl({ action: 'company:update' })(CompanyEditDialog);


export { CompanyEditDialogWithAccessControl as CompanyEditDialog };