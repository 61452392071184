import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Box, TextField, InputLabel, FormHelperText } from '@material-ui/core';

import { SelectChip } from 'src/components';


const UserLinkForm = props => {
  const { rolesOptions, actions, needRegister, errors, selectedRole } = props;
  return (
    <Fragment>
      {needRegister &&
        <Fragment>
          <Box my={1}>
            <TextField name="firstName" label="Nombre" type="text" onChange={actions.handleChange} required fullWidth />
            { errors?.firstName && <FormHelperText error>{ errors.firstName }</FormHelperText> }
          </Box>
          <Box my={1}>
            <TextField name="lastName" label="Apellido" type="text" onChange={actions.handleChange} required fullWidth />
            { errors?.lastName && <FormHelperText error>{ errors.lastName }</FormHelperText> }
          </Box>
        </Fragment>
      }
      <Box my={1}>
        <InputLabel error={ Boolean(errors.role) } shrink required>Rol</InputLabel>
        <SelectChip
          value={selectedRole.value}
          returnFullOption
          options={rolesOptions}
          onChange={selectedRole => actions.handleSelect({ selectedRole })}
        />
        { errors.role && <FormHelperText error>{ errors.role }</FormHelperText> }
      </Box>
    </Fragment>
  );
};

UserLinkForm.propTypes = {
  actions: PropTypes.object,
  rolesOptions: PropTypes.array,
  needRegister: PropTypes.bool,
  errors: PropTypes.object,
  selectedRole: PropTypes.object,
};


export {
  UserLinkForm,
};