import { get, post } from 'src/services/api';


const searchInFlora = async params => (await get('species/flora/search', params)).data;

const searchInFauna = async params => (await get('species/fauna/search', params)).data;

const askAFloraQuestion = async (recordHash, type) => (await post('species/flora/question', {
  hash: recordHash,
  type,
})).data;

const searchGenuses = async ({ queryText, excludeLost, onlyOfficial, campaignId }) =>
  (await get('species/genus/search', { queryText, excludeLost, onlyOfficial, campaignId })).data;

const searchSpecies = async ({ queryText, genus, excludeLost, onlyOfficial, campaignId }) =>
  (await get('species/species/search', { queryText, genus, excludeLost, onlyOfficial, campaignId })).data;


export {
  searchInFlora,
  searchInFauna,
  askAFloraQuestion,
  searchGenuses,
  searchSpecies,
};
