import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';

import { TitleContainer, ContentContainer } from 'src/components';


const CompanyProfileContainer = () =>
  <>
    <Grid container>
      <TitleContainer breadcrumbs={[ 'company', { name: 'Perfil' } ]}>Perfil de empresa</TitleContainer>
      <ContentContainer>
        <Box pb={1}>
          <Typography variant="body1" color="textPrimary"></Typography>
        </Box>
      </ContentContainer>
    </Grid>
  </>;

export { CompanyProfileContainer };