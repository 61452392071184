import React from 'react';
import PropTypes from 'prop-types';

import { SimpleDialog } from 'src/components';


const title = 'No se pudo importar el KML';
const BadImportDialog = ({ actions, message }) => {
  const actionObjs = [ { actionFunc: actions.closeDialog, actionName: 'Cerrar' } ];
  return (
    <SimpleDialog title={ title } actionObjs={ actionObjs } message={ message }></SimpleDialog>
  );
};

BadImportDialog.defaultProps = {
  message: 'Hubo un problema al intentar importar el KML recibido. Por favor revisa que el archivo sea válido.',
};

BadImportDialog.propTypes = {
  actions: PropTypes.object,
  message: PropTypes.string,
};


export {
  BadImportDialog,
};
