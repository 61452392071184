import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import urlJoin from 'url-join';

import { formatDate } from 'src/utils/util';
import { isPlot, isStation, isTransect } from 'src/utils/checkers';
import { PopupObservation } from 'src/scenes/Campaign/scenes/Monitoring/components/PopupObservation';


const useStyles = makeStyles(() => ({
  card: {
    padding: '5px',
  },
  codeText: {
    margin: '1px 0',
    fontSize: '13px',
  },
  emailText: {
    margin: '1px 0',
    fontSize: '14px',
  },
  statusText: {
    lineHeight: '1.5',
  },
}));

const PointPopup = ({ point, pointStatusMapper, csLang, campaignSubtypeId = 'plots', campaignHash }) => {
  const classes = useStyles();
  const typeForUrl = isStation(campaignSubtypeId) ? 'sampling-stations'
    : isTransect(campaignSubtypeId) ? 'transects'
    : campaignSubtypeId;
  point.statusId = isTransect(campaignSubtypeId) ? (point.synced ? 'synced' : 'todo') : point.statusId;
  const { id, email, name, dateVisited, finishedDate, statusId, startDate, endDate, observation } = point;
  const date = dateVisited || finishedDate;
  return (
    <Card elevation={0}>
      <CardContent className={classes.card}>
        <Typography variant="h5">
          {csLang.Point} {name}
        </Typography>
        <Typography variant="body2" component="span" className={classes.emailText} color="textPrimary">
          {email}
        </Typography>
        <Box my={3} display="flex" flexDirection="column">
          <Typography variant="overline" className={classes.statusText}>
            Estado: {pointStatusMapper[statusId].text}
          </Typography>
          {(isPlot(campaignSubtypeId) || isStation(campaignSubtypeId)) &&
            <Typography variant="overline" className={classes.statusText}>
              Fecha de visita: {date ? formatDate(date) : 'No visitado'}
            </Typography>
          }
          {isTransect(campaignSubtypeId) &&
            <>
              <Typography variant="overline" className={classes.statusText}>
                Fecha de inicio: {startDate ? formatDate(startDate) : 'No visitado'}
              </Typography>
              <Typography variant="overline" className={classes.statusText}>
                Fecha de fin: {endDate ? formatDate(endDate) : 'No visitado'}
              </Typography>
            </>
          }
        </Box>
        <PopupObservation observation={observation}/>
        <Button fullWidth target='_blank' variant="outlined" color='primary'
          href={ campaignHash ? urlJoin('/web/campaigns', campaignHash, `${typeForUrl}?point=${id}&page=1`) : undefined }
        >
          Ir a detalle
        </Button>
      </CardContent>
    </Card>
  );
};

PointPopup.propTypes = {
  point: PropTypes.object,
  pointStatusMapper: PropTypes.object,
  csLang: PropTypes.object,
  campaignSubtypeId: PropTypes.string,
  campaignHash: PropTypes.string,
};


export {
  PointPopup,
};