import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, TextField, InputLabel, FormHelperText } from '@material-ui/core';

import { AutocompleteWrapper, DialogWrap } from 'src/components';
import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';
import { AlertWrapper } from 'src/components';


const ProjectEditDialog = ({ previousProjectData, actions, isOpen, coordinatorsAndAdminsOptions }) => {

  const [ fetchStatus, setFetchStatus ] = useState(fetchStatusEnum.NOT_STARTED);
  const [ name, setName ] = useState(previousProjectData.name);
  const [ errorMsg, setErrorMsg ] = useState('');
  const [ userSelected, setUserSelected ] = useState(
    coordinatorsAndAdminsOptions.find(o => o.value === previousProjectData.userId.toString()),
  );

  const handleSubmit = async () => {
    if (fetchStatus === fetchStatusEnum.LOADING) {
      return;
    }
    setFetchStatus(fetchStatusEnum.LOADING);
    try {
      const trimmedName = name.trim();
      if (trimmedName.length === 0 || !userSelected) {
        setFetchStatus(fetchStatusEnum.BAD_DATA);
        return;
      }
      const projectToEdit = { name: trimmedName, userId: userSelected.value };
      await actions.editProject(previousProjectData.id, projectToEdit);
      setFetchStatus(fetchStatusEnum.SUCCESS);
    } catch (e) {
      setFetchStatus(fetchStatusEnum.ERROR);
      setErrorMsg(e.serverMessage || 'Hubo un error, por favor intenta más tarde');
    }
  };

  const updateName = e => setName(e.target.value);

  return (
    <DialogWrap maxWidth="xs" fullWidth onClose={actions.closeDialog} aria-labelledby="form-dialog-title" open={isOpen}>
      <DialogTitle id="form-dialog-title">Editar Proyecto</DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Box>
            <Box display="flex">
              <InputLabel shrink error={fetchStatus === fetchStatusEnum.BAD_DATA && name.trim().length === 0}>
                Nombre del Proyecto
              </InputLabel>
            </Box>
            <TextField name="name" value={name} onChange={ updateName }
              fullWidth autoFocus autoComplete="off" variant="outlined" size="small"/>
            {fetchStatus === fetchStatusEnum.BAD_DATA && name.trim().length === 0 &&
              <FormHelperText error>Debe ingresar un nombre para el proyecto</FormHelperText>
            }
          </Box>
          <Box my={ 2 }>
            <Box display="flex" justifyContent="space-between">
              <InputLabel shrink error={fetchStatus === fetchStatusEnum.BAD_DATA && !userSelected}>
                Responsable del Proyecto
              </InputLabel>
            </Box>
            <AutocompleteWrapper
              options={coordinatorsAndAdminsOptions}
              placeholder={'Seleccione un usuario'}
              value={ userSelected }
              onChange={(_, option) => setUserSelected(option)}
            />
            {fetchStatus === fetchStatusEnum.BAD_DATA && !userSelected &&
              <FormHelperText error>Debes seleccionar responsable del proyecto</FormHelperText>
            }
          </Box>
          <AlertWrapper BoxProps={ { mt: 1 } } severity="error" isOpen={fetchStatus === fetchStatusEnum.ERROR} hideCloseButton>
            { errorMsg }
          </AlertWrapper>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        { fetchStatus !== fetchStatusEnum.LOADING && <Button onClick={handleSubmit} color="primary">Guardar</Button> }
      </DialogActions>
    </DialogWrap>
  );
};

ProjectEditDialog.propTypes = {
  previousProjectData: PropTypes.object,
  actions: PropTypes.object,
  isOpen: PropTypes.bool,
  coordinatorsAndAdminsOptions: PropTypes.array,
};


export {
  ProjectEditDialog,
};
