import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { TableWrapper } from 'src/components';
import { Edit, Delete } from '@material-ui/icons';


const EnvironmentsList = ({ environmentsList, actions }) => {
  /* eslint-disable react/display-name */
  const tableColumns = [
    { title: 'Ambiente', field: 'name', width: '20%' },
    { title: 'Descripción', field: 'description', render: r => <div>{r.description ? r.description.trim() : 'Sin descripción'}</div> },
  ];

  const tableActions = [
    {
      tooltip: 'Editar',
      icon: () => <Edit />,
      onClick: (event, rowData) => actions.toggleDialog({ data: rowData, type: 'edit' }),
    },
    {
      tooltip: 'Eliminar',
      icon: () => <Delete />,
      onClick: (event, rowData) => actions.toggleDialog({ data: rowData.id, type: 'delete' }),
    },
  ];

  return (
    <Fragment>
      <TableWrapper columns={tableColumns} data={environmentsList} actions={tableActions} />
    </Fragment>
  );
};

EnvironmentsList.propTypes = {
  environmentsList: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
};


export { EnvironmentsList };