import React from 'react';
import { Box, Container, Grid, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { HeaderUI, BrowserUI } from 'src/scenes/Home/components';


const useStyles = makeStyles(theme => ({
  sidePanel: {
    width: theme.spacing(10),
  },
  downloadablesSection: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(0),
  },
}));


const DownloadablesPageUI = () => {
  const classes = useStyles();
  return (
    <>
      <BrowserUI size="small">
        <HeaderUI user="Juan Von Hildebrand" />
        <Box display="flex">
          <Box className={classes.sidePanel}></Box>
          <Grid container className={classes.downloadablesSection}>
            <Container maxWidth="xl">
              <Typography variant="h4" display="inline" color="primary">Descargables</Typography>
              <Divider />
              <br />
              <Box>
                <img height="75%" src="/img/tmp/downloadables-cover.png" alt="descargables de campaña"></img>
              </Box>
            </Container>
          </Grid>
        </Box>
      </BrowserUI>
    </>
  );
};


export {
  DownloadablesPageUI,
};