import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, InputLabel,
  TextField, FormControlLabel, Checkbox, FormHelperText, Collapse } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { DateTimePicker } from '@material-ui/pickers';

import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';
import { AgeStratumAndSexInputs } from 'src/scenes/Campaign/scenes/Records/components/FaunaRecordInputs';
import { CoordinatesInput, DialogButton } from 'src/components';
import { isEmpty } from 'src/utils/util';


const { NOT_STARTED, LOADING, SUCCESS, ERROR, BAD_DATA } = fetchStatusEnum;

const DirectIndividualEditDialog = props => {
  const { actions, prevIndividual, options: { sexTypesOptions, ageStratumsOptions } } = props;

  const [ individual, setIndividual ] = useState(prevIndividual);

  const [ coords, setCoords ] = useState({
    lat: prevIndividual.latitude,
    lng: prevIndividual.longitude,
    accuracy: isEmpty(prevIndividual.accuracy) ? '' : prevIndividual.accuracy,
    altitude: isEmpty(prevIndividual.altitude) ? '' : prevIndividual.altitude,
  });

  const handleSelect = obj => setIndividual(prevIndividual => ({ ...prevIndividual, ...obj }));

  const handleChange = e => setIndividual({ ...individual, [e.target.name]: e.target.value });

  const [ fetchStatus, setFetchStatus ] = useState(NOT_STARTED);
  const hasBadData = fetchStatus === BAD_DATA;

  const [ errorAlert, setErrorAlert ] = useState({ isOpen: false, message: '' });

  const handleConfirm = async () => {
    try {
      setFetchStatus(LOADING);
      if (hasCoords) {
        await actions.updateIndividual({
          id,
          sexId,
          ageStratumId,
          recaptured,
          observation,
          latitude: coords.lat,
          longitude: coords.lng,
          altitude: coords.altitude,
          accuracy: coords.accuracy,
          detectionDate,
        });
        setFetchStatus(SUCCESS);
        setTimeout(() => actions.closeDialog(), 300);
      } else {
        setFetchStatus(BAD_DATA);
      }
    } catch (e) {
      console.error(e);
      setErrorAlert({ isOpen: true, message: e.serverMessage || e.message });
      setFetchStatus(ERROR);
    }
  };

  const { id, sexId, ageStratumId, recaptured, observation, detectionDate } = individual;

  const hasCoords = !isEmpty(coords?.lat) && !isEmpty(coords?.lng);

  return (
    <Fragment>
      <DialogTitle>Editar individuo</DialogTitle>
      <DialogContent>
        <Box p={1}>
          <AgeStratumAndSexInputs
            record={individual}
            handlers={{ handleSelect }}
            options={{ sexTypesOptions, ageStratumsOptions }}
          />
          <Box my={1}>
            <InputLabel shrink>Recapturado</InputLabel>
            <FormControlLabel
              label="Recapturado"
              control={
                <Checkbox checked={recaptured}
                  onChange={ () => setIndividual(prevInd => ({ ...prevInd, recaptured: !prevInd.recaptured })) }
                  name="Recapturado"
                  color="primary"
                />
              }
            />
          </Box>

          <Box my={2}>
            <InputLabel shrink>Coordenadas</InputLabel>
            <CoordinatesInput required value={coords} onChange={setCoords} />
            {hasBadData && !hasCoords && <FormHelperText error>Debes ingresar latitud y longitud</FormHelperText>}
          </Box>

          <Box my={2}>
            <InputLabel shrink>Fecha de detección</InputLabel>
            <Box display="flex" alignItems="center">
              <Box display="flex" flexDirection="column">
                <DateTimePicker
                  value={detectionDate}
                  ampm={false}
                  TextFieldComponent={props => <TextField {...props} variant="outlined" size="small" />}
                  onChange={detectionDate => handleSelect({ detectionDate: new Date(detectionDate) })}
                  format="DD-MM-YYYY HH:mm"
                />
              </Box>
            </Box>
          </Box>

          <Box my={2}>
            <InputLabel shrink>Observaciones</InputLabel>
            <TextField value={observation} name="observation" minRows="2" maxRows="5" size="small"
              variant="outlined" multiline fullWidth autoComplete="off" onChange={handleChange} />
          </Box>

          {fetchStatus === ERROR &&
              <Collapse in={errorAlert.isOpen}>
                <Alert severity="error" onClose={() => setErrorAlert(s => ({ ...s, isOpen: false }))}>
                  {errorAlert.message}
                </Alert>
              </Collapse>
          }
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog} color="default">Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} color="primary" onClick={handleConfirm}>Editar</DialogButton>
      </DialogActions>
    </Fragment>
  );
};

DirectIndividualEditDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  prevIndividual: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
};


export { DirectIndividualEditDialog };