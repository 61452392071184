import React, { useState, useEffect } from 'react';
import { Typography, Box, Grid, Button, Container, Collapse } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { TitleContainer, ContentContainer, ActionsBox, DialogWrap, CompanyDrawer } from 'src/components';
import { EnvironmentsList, EnvironmentCreateDialog,
  EnvironmentEditDialog, EnvironmentDeleteDialog } from 'src/scenes/CompanyEnvironment/components';

import { companiesApi } from 'src/services';


const CompanyEnvironmentContainer = () => {

  const [ environmentsList, setEnvironmentsList ] = useState([]);
  const [ dialog, setDialog ] = useState({
    isOpen: false,
    data: {},
    type: '',
  });
  const [ openAlert, setOpenAlert ] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const environments = await companiesApi.getEnvironments();
      setEnvironmentsList(environments);
    };
    fetchData();
  }, []);

  useEffect(() => {
    document.title = 'Ambientes de fauna - Nviro Capture';
  }, []);

  const toggleDialog = ({ data = {}, type = '' }) => setDialog({ ...dialog, isOpen: !dialog.isOpen, data, type });

  const closeDialog = () => toggleDialog({});

  const getDialogType = () => {
    switch (dialog.type) {
      case 'create':
        return <EnvironmentCreateDialog actions={{ closeDialog, createEnvironment }}
        />;
      case 'edit':
        return <EnvironmentEditDialog prevEnvironment={dialog.data}
          actions={{ closeDialog, editEnvironment: environmentToEdit => editEnvironment(environmentToEdit, dialog.data.id) }}
        />;
      case 'delete':
        return <EnvironmentDeleteDialog actions={{ closeDialog, deleteEnvironment: () => deleteEnvironment(dialog.data) }}
        />;
      default:
        break;
    }
  };

  const createEnvironment = async newEnvironment => {
    const createdEnvironment = await companiesApi.createEnvironment(newEnvironment);
    setEnvironmentsList([ createdEnvironment, ...environmentsList ]);
    closeDialog();
  };

  const editEnvironment = async (environmentToEdit, environmentId) => {
    const updatedEnvironment = await companiesApi.updateEnvironment(environmentId, environmentToEdit);
    setEnvironmentsList(environmentsList.map(env => env.id === environmentId ? updatedEnvironment : env));
    closeDialog();
  };

  const deleteEnvironment = async environmentId => {
    await companiesApi.removeEnvironment(environmentId);
    setEnvironmentsList(environmentsList.filter(env => env.id !== environmentId));
    closeDialog();
  };

  return (
    <>
      <CompanyDrawer />
      <Grid container >
        <TitleContainer maxWidth="xl">
          Ambientes de fauna
        </TitleContainer>
        <ContentContainer maxWidth="xl">
          <ActionsBox>
            <Button size="small" color="primary" variant="contained" onClick={() => toggleDialog({ type: 'create' })}>
              Agregar ambiente
            </Button>
          </ActionsBox>
          <Container maxWidth="xl">
            <Box width="fit-content">
              <Collapse in={openAlert}>
                <Alert severity="info" onClose={() => setOpenAlert(false)}>
                  Los ambientes agregados en esta sección, aparecerán en la aplicación móvil de fauna como
                  opciones al momento de crear/editar una estación de muestreo
                </Alert>
              </Collapse>
              <br />
            </Box>
            {environmentsList.length ?
              <EnvironmentsList environmentsList={environmentsList} actions={{ toggleDialog }} /> :
              <Typography variant="body1" color="textPrimary">
                No se han añadido ambientes.
              </Typography>
            }
          </Container>
        </ContentContainer>
        <DialogWrap maxWidth="xs" fullWidth onClose={closeDialog} aria-labelledby='form-dialog-title'
          open={ dialog.isOpen }>
          { getDialogType() }
        </DialogWrap>
      </Grid>
    </>
  );
};


export {
  CompanyEnvironmentContainer,
};