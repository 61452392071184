const floraLanguage = {
  Point: 'Parcela',
  Points: 'Parcelas',
  point: 'parcela',
  points: 'parcelas',
  singles: 'individuales',
  single: 'individual',
  p: 'p',
  pointPrefix: 'P',
};

const censusLanguage = {
  singles: 'de censos',
  single: 'de censos',
};


const faunaLanguage = {
  Point: 'Estación de muestreo',
  Points: 'Estaciones de muestreo',
  point: 'estación de muestreo',
  points: 'estaciones de muestreo',
  singles: 'aislados',
  single: 'aislado',
  p: 'e',
  pointPrefix: 'E',
};

const transectLanguage = {
  Point: 'Transecta',
  Points: 'Transectas',
  point: 'transecta',
  points: 'transectas',
  p: 't',
  pointPrefix: 'T',
};

const genericLanguage = {
  Point: 'Unidad de muestreo',
  Points: 'Unidades de muestreo',
  point: 'unidad de muestreo',
  points: 'unidades de muestreo',
  p: 'udm',
  pointPrefix: 'U',
};

const campaignSubtypeLanguage = (campaignSubtypeId = 'default') => {
  switch (campaignSubtypeId) {
    case 'plots':
      return floraLanguage;
    case 'stations':
      return faunaLanguage;
    case 'point-quadrat':
      return transectLanguage;
    case 'point-intercept':
      return transectLanguage;
    case 'census':
      return censusLanguage;
    default:
      return genericLanguage;
  }
};


export {
  campaignSubtypeLanguage,
};