import React from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';

import { GraphWrapper } from 'src/components';
import { campaignStatisticsApi } from 'src/services';
import { getRandomColor, addOrReplaceOpacityInColor } from 'src/utils/util';


const SpeciesCounterByPoint = props => {

  const apiCall = () => campaignStatisticsApi.getSpeciesCounterByPoint(props.campaignHash);

  const dataToGraphData = data => {
    const datasetsMetadata = [
      { status: 'species', title: 'N° de especies' },
    ].map(dm => ({ ...dm, color: getRandomColor() }));

    return {
      labels: data.map(d => d.name),
      datasets: datasetsMetadata.map(dm => (
        {
          label: dm.title,
          data: data.map(d => d.data[dm.status] || 0),
          backgroundColor: addOrReplaceOpacityInColor(dm.color, 0.7),
          borderColor: 'white',
          hoverBackgroundColor: addOrReplaceOpacityInColor(dm.color, 1),
        }
      )),
    };
  };

  const cLang = props.cLang;

  return (
    <GraphWrapper
      title={`Especies por ${cLang.Points}`}
      info={`Muestra la cantidad de especies diferentes en cada ${cLang.points} que contenga registros de especies.`}
      apiCall={ apiCall }
      dataToGraphData={ dataToGraphData }
      chartjsComponent={ Bar }
      options={ {
        plugins: {
          legend: { display: false },
          tooltip: {
            mode: 'index',
            axis: 'y',
            callbacks: {
              afterTitle: tooltipItem => {
                const totalSpecies = tooltipItem.map(ti => parseFloat(ti.raw)).reduce((pv, cv) => pv + cv, 0);
                return `${totalSpecies} ${cLang.points}`;
              },
            },
          },
        },
        scales: {
          x: { stacked: true, ticks: { autoSkip: false } },
          y: { stacked: true, ticks: { maxTicksLimit: 5 } },
        },
      } }
    />
  );
};



SpeciesCounterByPoint.propTypes = {
  campaignHash: PropTypes.string.isRequired,
  cLang: PropTypes.object.isRequired,
};

export {
  SpeciesCounterByPoint,
};