import React from 'react';
import { InputLabel, Box, TextField, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';


const DirectRecordInputs = props => {
  const { record, required = false, handlers, validators } = props;

  const { individuals, number } = record;
  const { handleSelect } = handlers;
  const { hasBadData, isNumberUnderOne, isNumberUnderCurrentIndividuals } = validators;

  const minIndividuals = Math.max(individuals.length, 1);

  return (
    <Box my={2}>
      <InputLabel required={required} shrink>Número de individuos</InputLabel>
      <TextField
        value={number.toString()}
        inputProps={{ min: minIndividuals }}
        onChange={({ target: { value } }) => handleSelect({ number: value ? parseInt(value, 10) : value })}
        type="number"
        fullWidth
        autoComplete="off"
        variant="outlined"
        size="small"
        error={hasBadData && (isNumberUnderOne || isNumberUnderCurrentIndividuals)}
      />

      {hasBadData && (
        isNumberUnderOne ? <FormHelperText error>El número de individuos <strong>debe ser 1 o más</strong></FormHelperText>
        : isNumberUnderCurrentIndividuals ?
          <FormHelperText error>
            El número <strong>no puede ser menor a {minIndividuals}</strong> que corresponde a la cantidad de individuos detallados
          </FormHelperText>
          : <></>
      )}

    </Box>
  );
};

DirectRecordInputs.propTypes = {
  record: PropTypes.object.isRequired,
  required: PropTypes.bool,
  handlers: PropTypes.object,
  validators: PropTypes.objectOf(PropTypes.bool),
};


export { DirectRecordInputs };