import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, DialogTitle, DialogContent, DialogActions, Typography, Divider } from '@material-ui/core';

import { RecordDataList } from 'src/scenes/Campaign/scenes/Records/components/RecordDataList';


// TODO: manejar reintentos de alguna forma especial?
const RecordWasEditedDialog = ({ conflictData, editionData, actionName, redoAction, closeDialog }) => (
  <Fragment>
    <DialogTitle id="form-dialog-title">Conflicto al intentar { actionName }</DialogTitle>

    <DialogContent>
      <Typography mb={1} variant="body1" color="textPrimary">
        <strong>El registro fue recientemente actualizado por alguien más.</strong>
      </Typography>
      { conflictData && <>
        <Typography variant="body1" color="textPrimary">
            Los datos actualizados del registro son:
        </Typography>
        <RecordDataList data={ conflictData }/>
      </>}
      <Typography variant="body1" color="textPrimary">
          Si deseas continuar de todas formas, puedes usar el botón de Reenviar para volver a intentar { actionName }
      </Typography>

      <Box my={1}>
        <Divider />
      </Box>

      { editionData && <>
        <Typography variant="body1" color="textPrimary">
            Aún puedes ver los datos de la edición que estabas enviando a continuación:
        </Typography>
        <RecordDataList data={ editionData }/>
      </>}
    </DialogContent>

    <DialogActions>
      <Button onClick={ closeDialog }>Cancelar</Button>
      <Button onClick={ redoAction } color="primary">
          Reenviar
      </Button>
    </DialogActions>
  </Fragment>
);

RecordWasEditedDialog.propTypes = {
  conflictData: PropTypes.array,
  editionData: PropTypes.array,
  actionName: PropTypes.string,
  redoAction: PropTypes.func,
  closeDialog: PropTypes.func,
};

export { RecordWasEditedDialog };
