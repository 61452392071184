import React from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';

import { DialogButton, AlertWrapper } from 'src/components';
import { useFetchStatus } from 'src/hooks';


const CampaignDeleteDialog = props => {
  const { actions } = props;

  const { fetchStatus, errorMessage, hasError, handleConfirm } = useFetchStatus({
    confirmFn: actions.deleteCampaign,
    closeFn: actions.closeDialog,
  });

  return (
    <>
      <DialogTitle id="form-dialog-title">Borrar campaña</DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Typography variant="body1" color="textPrimary">
            Al eliminar esta campaña, eliminarás también la información contenida dentro de ella
            (parcelas, registros, etc), para ti y para todos los involucrados
          </Typography>
          <br />
          <Typography variant="body1" color="textPrimary">
            ¿Realmente deseas eliminar la campaña?
          </Typography>
          <br />
          {hasError && <AlertWrapper severity="error">{errorMessage}</AlertWrapper>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color="primary">Borrar</DialogButton>
      </DialogActions>
    </>
  );
};

CampaignDeleteDialog.propTypes = {
  actions: PropTypes.object.isRequired,
};


export {
  CampaignDeleteDialog,
};
