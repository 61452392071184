import React, { useCallback, useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Box, Typography, Hidden, useMediaQuery } from '@material-ui/core';
import { useSpring, animated, config } from 'react-spring';
import { useHistory } from 'react-router';

import { NviroButton } from 'src/components';
import { BrowserUI, HeaderUI, AppCampaignUI, FloraAppCampaignsPageUI, FloraAppRecordsPageUI, ReviewPointsUI,
} from 'src/scenes/Home/components';
import { AnalyseAndDownloadSection, StepTitle, StepDescription } from 'src/scenes/Home/scenes/HowItWork/components';


const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(7),
    color: theme.palette.common.black,
  },
  stepOneBox: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  floraAppCampaignsContainer: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      transform: 'scale(0.85)',
      marginTop: theme.spacing(-5),
    },
  },
  stepOneSection: {
    paddingTop: theme.spacing(15),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        paddingRight: theme.spacing(5),
        paddingLeft: theme.spacing(5),
      },
    },
  },
  title: {
    fontSize: '4.375rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3.125rem',
      lineHeight: '58px',
      textAlign: 'center',
    },
  },
  stepOneText: {
    width: '754px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  planningPhoto: {
    width: '700px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  stepTwoSection: {
    paddingTop: theme.spacing(25),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingTop: theme.spacing(5),
      paddingRight: theme.spacing(5),
      paddingLeft: theme.spacing(5),
    },
  },
  stepTwoBox: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  stepTwoText: {
    width: '700px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  recordsWebPhoto: {
    width: '780px',
    // : '420px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  recordsMobileContainer: {
    width: '800px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      transform: 'scale(0.85)',
      marginTop: theme.spacing(-5),
    },
  },
  knowMoreSection: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(5),
      paddingLeft: theme.spacing(5),
    },
  },
  knowMoreText: {
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: '4.375rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3.125rem',
      lineHeight: '58px',
      textAlign: 'center',
    },
  },

  knowMoreButton: {
    width: theme.spacing(30),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(25),
    },
  },

  channelOne: {
    backgroundColor: theme.palette.common.lightGray,
    width: '32px',
    height: '144px',
    borderBottomLeftRadius: '16px',
    [theme.breakpoints.down('sm')]: {
      borderBottomLeftRadius: '0px',
      width: '16px',
      height: '128px',
    },
  },
  channelTwo: {
    backgroundColor: theme.palette.common.lightGray,
    width: '418px',
    height: '32px',
    marginTop: '-32px',
    marginRight: theme.spacing(10) * -1,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  channelThree: {
    backgroundColor: theme.palette.common.lightGray,
    width: '200px',
    height: '32px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  knowMoreButtons: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  stepThreeSection: {
    paddingTop: theme.spacing(25),
    paddingBottom: theme.spacing(25),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      paddingRight: theme.spacing(5),
      paddingLeft: theme.spacing(5),
    },
  },
}));

const fakeCampa = { plots: { done: 40, total: 40 }, synced: 100, map: false, area: false, ri: 54 };

const records = [
  { name: 'Chloraea heteroglossa', specificName: 'orchidaceae', eecc: true, coverage: '3' },
  { name: 'Passiflora pinnatistipula', specificName: 'passifloraceae', coverage: 'r' },
  { name: 'Porlieria chilensis', specificName: 'zygophyllaceae', eecc: true, coverage: '2' },
  { name: 'Schinus desconocida', specificName: '-', unofficial: true, coverage: '1' },
  { name: 'Mutisia decurrens', specificName: 'asteraceae', coverage: '2' },
];

const HowItWorkContainer = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [ currentStepThreeButton, setCurrenStepThreeButton ] = useState(1);
  const setCurrentStepThreeHandler = useCallback(num => setCurrenStepThreeButton(num), [ setCurrenStepThreeButton ]);

  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [ campaignAnimationStyles ] = useSpring(() => ({
    from: { x: 70 },
    to: { x: 754 },
    config: { duration: 3200, ...config.molasses },
    delay: 300,
  }));

  useEffect(() => {
    document.title = '¿Cómo funciona? - Nviro Capture';
  }, []);

  return (
    <Box className={classes.container}>
      <Grid container justifyContent="center">
        <Grid item>
          <Typography className={classes.title} color="primary">¿Cómo funciona?</Typography>
        </Grid>
      </Grid>
      <Grid className={classes.stepOneSection} container alignItems="center" direction="column">
        <Grid item>
          <Box display="flex" className={classes.stepOneBox}>
            <Box pr={{ xs: 0, md: 4 }} display="flex" flexDirection="column" alignItems="center" >
              <Hidden mdUp>
                <Box>
                  <StepTitle>
                    Crea y planifica una campaña
                  </StepTitle>
                  <StepDescription>
                    Distribuye tu campaña en múltiples parcelas, asígnalas a distintas personas,
                    una vez confirmada la planificación, todos serán notificados y estarán listos para comenzar a colectar!
                  </StepDescription>
                </Box>
              </Hidden>
              <Box className={classes.planningPhoto}>
                {isDownSm ?
                  <img className={classes.planningPhoto}
                    alt={ `Mapa con 28 marcadores numerados que representan parcelas,` +
                      ` y un área resaltada, con un panel con el título 'Planificar campaña de flora'` }
                    src={'/img/tmp/monitoring_ligua_2.png'}
                  /> :
                  <BrowserUI size="small" shadow={false} darkMode>
                    <HeaderUI size="small" user="Juan Von Hildebrand" />
                    <img className={classes.planningPhoto}
                      alt={ `Mapa con 28 marcadores numerados que representan parcelas,` +
                        ` y un área resaltada, con un panel con el título 'Planificar campaña de flora'` }
                      src={'/img/tmp/monitoring_ligua.png'}
                    />
                  </BrowserUI>
                }
              </Box>
              <Box className={classes.channelOne}></Box>
              <Box alignSelf="flex-end" className={classes.channelTwo}></Box>
              <Hidden smDown>
                <Box mt={-1}>
                  <animated.div style={campaignAnimationStyles} >
                    <Box width="233px" >
                      <AppCampaignUI
                        title="Campaña de Ejemplo"
                        project="Proyecto 2021"
                        dateText="Comenzó hoy"
                        catalogue="Plantas Vasculares del Conosur (Zuloaga)"
                        companyName="Nviro"
                        companyInitials="NVI"
                        data={ { plots: { total: 28, done: 0 }, synced: 0, map: true, area: true }}
                      />
                    </Box>
                  </animated.div>
                </Box>
              </Hidden>
            </Box>
            <Box pl={{ xs: 0, md: 5 }} pt={{ xs: 0, md: 12 }} className={classes.stepOneText} display="flex" flexDirection="column">
              <Hidden smDown>
                <Box>
                  <StepTitle>Crea y planifica una campaña</StepTitle>
                  <StepDescription>
                    Distribuye tu campaña en múltiples parcelas, asígnalas a distintas personas,
                    una vez confirmada la planificación, todos serán notificados y estarán listos para comenzar a colectar!
                  </StepDescription>
                </Box>
              </Hidden>
              <Box className={classes.floraAppCampaignsContainer} pt={{ xs: 0, md: '60px' }} display="flex" alignItems="center">
                <Box>
                  <Box mt={-1} className={classes.channelThree}></Box>
                </Box>
                <FloraAppCampaignsPageUI syncedColor={ true } campaignData={ fakeCampa } />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid className={classes.stepTwoSection} container justifyContent="center">
        <Box className={classes.stepTwoBox} display="flex" justifyContent="center" alignItems="center">
          <Box className={classes.stepTwoText} display="flex" flexDirection="column">
            <StepTitle>Captura y corrige tus datos</StepTitle>
            <StepDescription className={classes.stepDescription}>
              Una vez capturados los datos en terreno, sincroniza los datos y éstos se verán en la versión web de Nviro Capture,
              en donde además podrás revisar las parcelas y completar los datos de los registros capturados.
            </StepDescription>
          </Box>
          <Box className={classes.recordsMobileContainer} display="flex" justifyContent="center">
            <Box display="flex" width="100%" justifyContent="center">
              <FloraAppRecordsPageUI records={ records }/>
            </Box>
          </Box>
        </Box>
        <Box pl={{ xs: 0, md: 22 }} mt={{ xs: 0, md: -10 }} width="100%">
          <Box className={classes.recordsWebPhoto}>
            <ReviewPointsUI />
          </Box>
        </Box>
      </Grid>
      <Grid className={classes.stepThreeSection} container alignItems="center" direction="column">
        <Grid item>
          <AnalyseAndDownloadSection activeButtonNumber={ currentStepThreeButton } setActiveButtonNumber={ setCurrentStepThreeHandler } />
        </Grid>
      </Grid>
      <Grid className={classes.knowMoreSection} container alignItems="center" direction="column">
        <Grid item>
          <img src="/img/home/info.svg" alt="info-icon" />
        </Grid>
        <Grid item>
          <Typography className={classes.knowMoreText} color="primary">¿Quiéres saber más?</Typography>
        </Grid>
        <Grid item>
          <Box pt={2} display="flex" className={classes.knowMoreButtons}>
            <NviroButton className={classes.knowMoreButton} onClick={() => history.push('/schedule-demo')}>Agendar una demo</NviroButton>
            <Box px={{ xs: 0, md: 1 }} py={{ xs: 1, md: 0 }} />
            <NviroButton className={classes.knowMoreButton} color="black" onClick={() => history.push('/pricing')}>Ver planes</NviroButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};


export { HowItWorkContainer };