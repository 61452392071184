import React, { useEffect, useRef, useState } from 'react';
import { Box, Checkbox, Collapse, Divider, InputLabel, List, ListItem, ListItemIcon, ListItemText,
  Typography, Input, FormControlLabel, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import * as togeojson from '@tmcw/togeojson';
import L from 'leaflet';
import JsZip from 'jszip';
import { Delete } from '@material-ui/icons';
import { renderToString } from 'react-dom/server';

import { tryParseXml } from 'src/utils/util';
import config from 'src/config/local';
import { MapWrapper, AlertWrapper, NviroButton } from 'src/components';


const { maxSizeFileUpload, maxKmlPoints, maxKmlLines, maxKmlPolygons } = config;

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 500,
  },
  textField: {
    height: theme.spacing(4),
    fontSize: theme.typography.body2.fontSize,
  },
  list: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.gray400,
    borderRadius: theme.shape.borderRadius,
  },

  listItem: {
    alignItems: 'center',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.common.gray400,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    '&:last-child': {
      borderBottom: 'none',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.lightGray,
    },
  },
  itemData: {
    fontWeight: 600,
    marginLeft: theme.spacing(1),
  },
  selector: {
    marginTop: theme.spacing(1),
  },
  hidden: {
    display: 'none',
  },
  fileName: {
    fontWeight: 'bold',
  },
  uploadButton: {
    height: '2.5rem',
  },
  configButton: {
    height: '2.3rem',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '10rem',
  },
  boxBorder: {
    borderRadius: theme.shape.borderRadius,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.gray,
  },
}));

const geometriesMapper = {
  Point: 'punto(s)',
  Polygon: 'polígono(s)',
  LineString: 'línea(s)',
};

const CampaignCreateCensusFourthStep = ({ form, handlers }) => {
  const mapRef = useRef();
  const fileInputRef = useRef();

  const classes = useStyles();

  const [ confirmingKml, setConfirmingKml ] = useState(false);
  const [ loadingKmlForImport, setLoadingKmlForImport ] = useState(false);
  const [ kmlFileData, setKmlFileData ] = useState(null); // datos del kml del archivo que se está importando actualmente
  const [ importConfig, setImportConfig ] = useState({ showMarkers: true, showLines: true, showPolygons: true, replaceAll: false });
  const [ importingKml, setImportingKml ] = useState(false);
  const [ kmlValidationErrorMessage, setKmlValidationErrorMessage ] = useState('');
  const [ invalidSettingsMessage, setInvalidSettingsMessage ] = useState('');

  const [ leafletLayers, setLeafletLayers ] = useState([]);
  const [ legend, setLegend ] = useState(null);

  const mapOptions = {
    scrollWheelZoom: false,
    zoom: 8,
    doubleClickZoom: true,
    attributionControl: false,
  };

  const { interestAreas } = form;
  const { handleAreaImport, handleDeleteArea } = handlers;

  useEffect(() => {
    if (confirmingKml) {
      return;
    }
    let newLayers = [];
    if (interestAreas.length) {

      newLayers = interestAreas.map(({ layer }) => L.geoJSON(layer.geojson, {
        id: layer.id,
        name: layer.name,
        pointToLayer: (feature, latlng) => new L.CircleMarker(latlng, { radius: 5, fillOpacity: 1 }),
      }));
    }
    setLeafletLayers(newLayers);
    makeLegend(newLayers);
    setImportingKml(false);
    // eslint-disable-next-line
  }, [ confirmingKml, interestAreas ]);

  const handleOptionClick = optionType => () => setImportConfig(ps => ({ ...ps, [optionType]: !ps[optionType] }));

  const handleFileUpload = async e => {
    setInvalidSettingsMessage('');
    setImportConfig({ showMarkers: true, showLines: true, showPolygons: true, replaceAll: false });
    const file = e.target.files[0];
    let kmlFile;
    const propertyToCheck = file.type || file.name;
    setLoadingKmlForImport(true);
    try {
      if (interestAreas.length > 4) {
        handleBadUpload(`Se permite un máximo de 5 archivos de área de interés`);
        return;
      }
      if (file.size > maxSizeFileUpload) {
        handleBadUpload(`El tamaño del archivo supera el máximo permitido (${maxSizeFileUpload / 1000000} MB)`);
        return;
      }
      if (propertyToCheck.match(/\.kml/)) {
        kmlFile = file;
      } else if (propertyToCheck.match(/\.kmz/)) {
        const kmzZip = await new JsZip().loadAsync(file);
        const kmlZip = kmzZip.filter(relativePath => relativePath.match(/\.kml$/))[0];
        kmlFile = await kmlZip.async('blob');
      } else {
        handleBadUpload('Solo se aceptan archivos .kml o .kmz');
        return;
      }

      const kmlAsText = await new Response(kmlFile).text();
      const kmlDoc = tryParseXml(kmlAsText);
      if (!kmlDoc) {
        console.error('Error recibiendo archivo. El KML es inválido.');
        handleBadUpload('Error recibiendo archivo. El KML es inválido.');
        return;
      }

      const geojson = togeojson.kml(kmlDoc);
      // las geometrías pueden ser una geometría como se debe o null
      if (!geojson?.features?.length || geojson.features.some(feat => feat.geometry === undefined)) {
        console.error('KML parece no contener geometrías');
        handleBadUpload('Hubo un problema al intentar importar el KML recibido. Por favor revisa que el archivo sea válido.');
        return;
      }

      const featuresWithLocation = geojson.features.filter(feat => feat.geometry !== null);
      const dataByGeometry = featuresWithLocation.reduce((acc, obj) => {
        const key = obj.geometry.type;
        acc[key] = acc[key] + 1 || 1;
        return acc;
      }, {});

      if (!Object.keys(dataByGeometry).some(key => [ 'Point', 'LineString', 'Polygon' ].includes(key))) {
        handleBadUpload(`Solo se soportan geometrías de tipo punto, linea y/o polígono dentro del KML`);
        return;
      }
      const importPointsCount = dataByGeometry['Point'];
      const importLinesCount = dataByGeometry['LineString'];
      const importPolygonsCount = dataByGeometry['Polygon'];
      if (importPointsCount && importPointsCount > maxKmlPoints) {
        handleBadUpload(
          `El KML recibido supera el máximo de puntos permitidos (${importPointsCount} recibidos, ${maxKmlPoints} permitidos)`,
        );
        return;
      }
      if (importLinesCount && importLinesCount > maxKmlLines) {
        handleBadUpload(
          `El KML recibido supera el máximo de líneas permitidas (${importLinesCount} recibidas, ${maxKmlLines} permitidas)`,
        );
        return;
      }
      if (importPolygonsCount && importPolygonsCount > maxKmlPolygons) {
        handleBadUpload(
          `El KML recibido supera el máximo de polígonos permitidos (${importPolygonsCount} recibidos, ${maxKmlPolygons} permitidos)`,
        );
        return;
      }
      const data = {
        kmlFile,
        fileName: file.name,
        dataByGeometry,
        geojson: { ...geojson, features: featuresWithLocation },
      };

      setKmlFileData(data);
      setLoadingKmlForImport(false);
      setKmlValidationErrorMessage(false);
      setConfirmingKml(true); // ahora se pueden configurar los showCosas y confirmar para pasarle el kml al mapa
    } catch (e) {
      console.error(e);
      handleBadUpload('Ocurrió un error inesperado, por favor intenta más tarde');
    }
  };

  const handleImportClick = () => {
    try {
      if (importingKml) {
        return;
      }
      if (!importConfig.showMarkers && !importConfig.showLines && !importConfig.showPolygons) {
        setInvalidSettingsMessage('No tienes ningún tipo de geometría seleccionada.'
          + ' Debes importar por lo menos un tipo de geometría del KML/KMZ');
        return;
      }

      setInvalidSettingsMessage('');
      setImportingKml(true);
      handleAreaImport(kmlFileData, importConfig);
      setConfirmingKml(false);
    } catch (e) {
      console.error(e);
      handleBadUpload('Ocurrió un error inesperado, por favor intenta más tarde');
    }
  };

  const cancelImport = () => {
    setConfirmingKml(false);
    setImportConfig({ showMarkers: true, showLines: true, showPolygons: true, replaceAll: false });
    setInvalidSettingsMessage('');
  };

  const makeLegend = layers => {
    const hiddenLayers = [];
    layers.forEach(layer => {
      const element = document.getElementById(`layer-${layer.options.id}`);
      if (element) {
        const isLayerHidden = L.DomUtil.hasClass(element, 'bcw-legend-box-uncheck');
        isLayerHidden && hiddenLayers.push(layer.options.id);
      }
    });

    // TODO: yhann dijo que hay una mejor forma de hacer esto, para verlo algún día:
    // https://github.com/cswcl/nviro-front/pull/414#discussion_r1283756098
    legend && legend.remove();
    const newLegend = L.control({ position: 'bottomleft' });
    newLegend.onAdd = () => {
      const legendContainer = L.DomUtil.create('div', 'info bcw-legend');

      if (layers.length) {
        const kmlTitle = L.DomUtil.create('div', '', legendContainer);
        kmlTitle.innerHTML = `<strong>Área de interés</strong>`;

        layers.forEach(layer => {
          const areLayerShowing = !hiddenLayers.includes(layer.options.id);
          if (!areLayerShowing) {
            mapRef.current.removeLayer(layer);
          }
          const item = L.DomUtil.create('div', 'bcw-legend-element', legendContainer);
          const checkbox = L.DomUtil.create('div', `bcw-legend-box ${areLayerShowing ? '' : 'bcw-legend-box-uncheck'}`, item);
          checkbox.style.backgroundColor = 'blue';
          checkbox.style.borderColor = 'blue';
          checkbox.id = `layer-${layer.options.id}`;
          const layerTitle = L.DomUtil.create('div', '', item);
          layerTitle.innerHTML = `<span class="bcw-legend-title">${layer.options.name}</span>`;
          const delBtn = L.DomUtil.create('div', 'bcw-legend-del-kml', item);
          delBtn.innerHTML = renderToString(<Delete fontSize="small" style={{ fontSize: '1rem', marginLeft: '2px' }} />);

          L.DomEvent.on(checkbox, 'click', e => {
            const colorCircle = e.target;
            if (L.DomUtil.hasClass(colorCircle, 'bcw-legend-box-uncheck')) {
              L.DomUtil.removeClass(colorCircle, 'bcw-legend-box-uncheck');
              mapRef.current.addLayer(layer);
            } else {
              L.DomUtil.addClass(colorCircle, 'bcw-legend-box-uncheck');
              mapRef.current.removeLayer(layer);
            }
          });

          L.DomEvent.on(delBtn, 'click', async e => {
            e.stopPropagation();
            const colorBtn = e.target;
            L.DomUtil.addClass(colorBtn, 'bcw-legend-box-uncheck');
            const loading = L.DomUtil.create('div');
            loading.innerHTML = renderToString(<Box ml={0.5}><CircularProgress size={12} thickness={4.8} /></Box>);
            delBtn.replaceWith(loading);
            handleDeleteArea(layer.options.id);
            item.parentNode.removeChild(item);
          });
        });
      }
      return legendContainer;
    };
    setLegend(newLegend);
  };

  const handleBadUpload = message => {
    setKmlValidationErrorMessage(message);
    setLoadingKmlForImport(false);
    setConfirmingKml(false);
    setImportingKml(false);
  };
  const disabledCheckboxes = Boolean(kmlValidationErrorMessage || importingKml);
  const disabledFileInput = interestAreas.length > 4 || loadingKmlForImport || importingKml;

  return (
    <Box px={1} width="100%">
      <Typography className={classes.title} variant="body1">Área de interés</Typography>
      <Box my={2} display="flex">
        <Box width="100%">
          <InputLabel htmlFor="interesting-file">
            <NviroButton color='primary' className={classes.uploadButton}
              onClick={() => fileInputRef.current.click()} disabled={loadingKmlForImport}
            >
              Cargar área de interés (KML/KMZ)
            </NviroButton>
            { !loadingKmlForImport &&
              // Chrome tiene problemas reimportando el mismo archivo luego de cancelar o de importarlo y borrarlo del mapa
              // creo que es porque el input queda con el valor y si ve que quieres resubir el mismo archivo dice "oh, ya lo tengo
              // así que no haré nada". Por eso lo estoy condicionando con el loadingKML... TODO: ver si hay una solución menos parche
              <Input className={classes.hidden} id="interesting-file" type="file" inputProps={ { accept: '.kml, .kmz' } }
                ref={fileInputRef} onChange={handleFileUpload} disabled={disabledFileInput}/>
            }
          </InputLabel>
          <Box mt={1}><small>Archivo seleccionado:
            { confirmingKml && <span className={classes.fileName}> {kmlFileData?.fileName ?? ''}</span> }
          </small></Box>
        </Box>
      </Box>
      <Collapse in={confirmingKml} timeout={500}> { kmlFileData &&
        <Box px={3} py={3} my={2} className={classes.boxBorder}>
          <Box mb={0}><Typography variant="body1">
            Se encontraron las siguientes geometrías, seleccione las que desea incorporar al área de interés:
          </Typography></Box>
          <List>
            { kmlFileData.dataByGeometry['Point'] &&
              <ListItem role={undefined} dense button onClick={handleOptionClick('showMarkers')}>
                <ListItemIcon>
                  <Checkbox
                    disabled={disabledCheckboxes} edge="start" checked={Boolean(importConfig.showMarkers)} disableRipple />
                </ListItemIcon>
                <ListItemText primary={`${kmlFileData.dataByGeometry['Point']} ${geometriesMapper['Point']}`} />
              </ListItem>}
            {kmlFileData.dataByGeometry['LineString'] &&
              <ListItem role={undefined} dense button onClick={handleOptionClick('showLines')}>
                <ListItemIcon>
                  <Checkbox
                    disabled={disabledCheckboxes} edge="start" checked={Boolean(importConfig.showLines)} disableRipple />
                </ListItemIcon>
                <ListItemText primary={`${kmlFileData.dataByGeometry['LineString']} ${geometriesMapper['LineString']}`} />
              </ListItem>}
            {kmlFileData.dataByGeometry['Polygon'] &&
              <ListItem role={undefined} dense button onClick={handleOptionClick('showPolygons')}>
                <ListItemIcon>
                  <Checkbox
                    disabled={disabledCheckboxes} edge="start" checked={Boolean(importConfig.showPolygons)} disableRipple />
                </ListItemIcon>
                <ListItemText primary={`${kmlFileData.dataByGeometry['Polygon']} ${geometriesMapper['Polygon']}`} />
              </ListItem>}
          </List>
          <Divider />
          <Box my={1.5}>
            <FormControlLabel
              control={<Checkbox checked={importConfig.replaceAll} onChange={handleOptionClick('replaceAll')} />}
              label="Reemplazar capas anteriores"
            />
            { importConfig.replaceAll &&
              <Typography color="error" variant="caption" component="p">
                * Esta acción eliminará todas las capas cargadas anteriormente
              </Typography>
            }
          </Box>
          { invalidSettingsMessage &&
            <AlertWrapper variant="outlined" severity='error'>
              { invalidSettingsMessage }
            </AlertWrapper>
          }
          <Divider />
          <Box mt={3} display="flex" justifyContent="center">
            <NviroButton className={classes.configButton} onClick={cancelImport}>
              Cancelar
            </NviroButton>
            <NviroButton disabled={!confirmingKml || !kmlFileData} onClick={handleImportClick} loading={importingKml}
              className={classes.configButton} color="primary">
              Importar
            </NviroButton>
          </Box>
        </Box>
      } </Collapse>
      <Box mt={0}>
        { kmlValidationErrorMessage &&
          <AlertWrapper variant="outlined" severity='error'>
            { kmlValidationErrorMessage }
          </AlertWrapper>
        }
      </Box>
      <Box mb={2} mt={4} display="flex">
        <MapWrapper id='interest-area-step' className={classes.map} options={mapOptions}
          height={400} layers={leafletLayers} mapRef={mapRef} legend={legend} fitBoundsOnUpdate />
      </Box>
    </Box>
  );
};

CampaignCreateCensusFourthStep.propTypes = {
  form: PropTypes.object,
  options: PropTypes.object,
  handlers: PropTypes.object,
  validators: PropTypes.object,
};


export {
  CampaignCreateCensusFourthStep,
};