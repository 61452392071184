import { get, del, post, patch } from 'src/services/api';


const getCampaigns = async queryParams => (await get('campaigns', queryParams)).data;

const deleteCampaign = campaignHash => del('campaigns/deleteCampaign', campaignHash);

const createCampaign = async newCampaign =>
  (await post('campaigns/addCampaign', newCampaign, { headers: { 'Content-Type': 'multipart/form-data' } })).data;

const editCampaign = async (campaignHash, campaignToEdit) => (await patch(`campaigns/${campaignHash}`, campaignToEdit)).data;

const startCampaign = campaignHash => patch(`campaigns/${campaignHash}/start`);

const finishWork = campaignHash => patch(`campaigns/${campaignHash}/work-finished`);

const finishCampaign = campaignHash => patch(`campaigns/${campaignHash}/finish`);

const completionStatus = async campaignHash => (await get(`campaigns/${campaignHash}/completion`)).data;

const completionStatusByUser = async campaignHash => (await get(`campaigns/${campaignHash}/completion-by-user`)).data;

const getCampaignByHash = async campaignHash => (await get(`campaigns/${campaignHash}`)).data;

const getCampaignPlanningByHash = async campaignHash => (await get(`campaigns/planning/${campaignHash}`)).data;

const deleteKml = async kmlId => await del(`campaigns/kml/${kmlId}`);

const getTracks = async campaignHash => (await get(`campaigns/${campaignHash}/track`)).data;

const importInterestArea = async (kmlForm, campaignHash) =>
  (await post(`campaigns/import-area/${campaignHash}`, kmlForm, { headers: { 'Content-Type': 'multipart/form-data' } })).data;

const getUsers = async campaignHash => (await get(`campaigns/${campaignHash}/users`)).data;

const getRoleInCampaign = async campaignHash => (await get(`campaigns/${campaignHash}/own-role`)).data;

const linkUsers = async (campaignHash, newUsers) => (await post(`campaigns/${campaignHash}/link-users`, newUsers)).data;

const unlinkUser = async (campaignHash, userId) => (await post(`campaigns/${campaignHash}/unlink-user`, { userId })).data;

const search = async queryText => (await get('campaigns/search', { queryText })).data;

const getRecords = async queryParams => (await get('records', queryParams)).data;

const getCampaignCounter = async campaignHash => (await get(`campaigns/${campaignHash}/records-counter`)).data;

const getTypes = async () => (await get(`campaigns/types`)).data; // deprecado

const getMainTypes = async () => (await get(`campaigns/main-types`)).data;

const getMainMethodsInComponent = async () => (await get(`campaigns/main-methods`)).data;

const getStratums = async campaignId => (await get(`campaigns/${campaignId}/stratums`)).data;

const getCensusSettings = async campaignId => (await get(`campaigns/${campaignId}/census-settings`)).data;

const getCensusSettingsWithFieldProps = async campaignId => (await get(`campaigns/${campaignId}/census-settings-with-field-props`)).data;

const getUsersWithSomeBlock = async campaignId => (await get(`campaigns/${campaignId}/users-with-some-block`)).data;


export {
  getCampaigns,
  deleteCampaign,
  createCampaign,
  editCampaign,
  getCampaignByHash,
  getCampaignPlanningByHash,
  startCampaign,
  finishCampaign,
  finishWork,
  deleteKml,
  getTracks,
  completionStatus,
  completionStatusByUser,
  importInterestArea,
  getUsers,
  getRoleInCampaign,
  linkUsers,
  unlinkUser,
  search,
  getRecords,
  getCampaignCounter,
  getTypes,
  getMainTypes,
  getMainMethodsInComponent,
  getStratums,
  getCensusSettings,
  getCensusSettingsWithFieldProps,
  getUsersWithSomeBlock,
};
