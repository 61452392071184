import React, { useState, useEffect, useRef, useContext } from 'react';
import { Grid, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useRouteMatch, useLocation, useHistory } from 'react-router';

import { TitleContainer, ContentContainer, Collapser, FilterText, FilterSelect, DialogWrap, DialogImagePreviewer } from 'src/components';
import { MethodologiesList, MethodologyEditDialog, MethodologyDeleteDialog } from 'src/scenes/Campaign/scenes/Methodologies/components';
import { FaunaRecordCreateDialog } from 'src/scenes/Campaign/scenes/Records/components';
import { faunaApi, usersApi, speciesApi, pointsApi } from 'src/services';
import { upperCaseFirstLetter } from 'src/utils/formatters';
import { WebContext } from 'src/scenes/web-context';
import { enumsToOptions, objectIsEmpty as oie } from 'src/utils/util';
import { cardinalPointsEnum, floraRecordsTypesEnum, faunaRecordsTypesEnum } from 'src/utils/enums';
import { recordTypesByMethodologies } from 'src/utils/recordTypesByMethodologies';
import { createRecord } from 'src/scenes/Campaign/campaignHelper';


const pointFilterKey = 'point';
const methodologyTypeFilterKey = 'methodologyType';

const MethodologiesContainer = ({ changeActive }) => {
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const context = useContext(WebContext);
  const { componentId, mainMethodId: campaignSubtypeId } = context.selectedCampaign;
  const { campaignHash } = match.params;

  const tableRef = useRef();
  const [ filters, setFilters ] = useState([]);
  const [ filtersLabels, setFiltersLabels ] = useState();

  const [ options, setOptions ] = useState({
    sexTypesOptions: [],
    ageStratumsOptions: [],
    indirectRecordsTypesOptions: [],
    methodologiesTypesOptions: [],
    cardinalPointsOptions: enumsToOptions(cardinalPointsEnum),
    flightTypes: [],
  });

  const [ dialog, setDialog ] = useState({ isOpen: false, data: {}, type: '' });

  const query = new URLSearchParams(location.search);
  query.set('campaignHash', campaignHash);

  const getQueryParams = () => {
    const query = new URLSearchParams(location.search);

    const fixedSize = 10;
    query.set('size', fixedSize);
    const page = parseInt(query.get('page') || 1, 10);

    const point = query.get(pointFilterKey);
    const methodologyType = query.getAll(methodologyTypeFilterKey).length ? query.getAll(methodologyTypeFilterKey) : [];

    return { page, size: fixedSize, methodologyType, point };
  };

  const { point } = getQueryParams();

  const addFiltersToQuery = (query, filters) => {
    Object.keys(filters).forEach(key => {
      if (Array.isArray(filters[key])) {
        query.delete(key);
        filters[key].forEach(val => query.append(key, val));
      } else if (typeof filters[key] === 'boolean') {
        filters[key] ? query.set(key, filters[key]) : query.delete(key);
      } else if (filters[key] !== null) {
        query.set(key, filters[key]);
      }
    });
  };

  const setFilter = async ({ id, value, label = '' }) => {
    const queryForPath = new URLSearchParams(location.search);

    const filter = { [id]: value };

    const filtersObj = { ...filter };
    let tablePage = 0;
    if (id !== 'page') {
      filtersObj.page = 1;
    } else {
      tablePage = value - 1;
    }
    addFiltersToQuery(queryForPath, filtersObj);
    setFilters(prev => ({ ...prev, ...filter }));
    label && setFiltersLabels(prev => ({ ...prev, [id]: label }));

    const queryForPathString = queryForPath.toString();
    await history.push({ pathname: location.pathname, search: `?${queryForPathString}` });
    tableRef.current && tableRef.current.onQueryChange({ page: tablePage });
  };

  const transformToOptions = ({ searchResult }) =>
    searchResult.map(r => ({
      ...r,
      label: upperCaseFirstLetter(r.name || r.scientificName),
      value: r.id,
    }));

  const getOptions = key => async ({ queryText, ...filters }) => {
    const { id: campaignId } = context.selectedCampaign;

    const searchApi = {
      species: speciesApi.searchInFauna,
      point: pointsApi.search,
    };

    const searchResult = await searchApi[key]({ queryText, campaignId, ...filters });
    return transformToOptions({ searchResult });
  };

  useEffect(() => {
    if (componentId === 'fauna') {
      const fetchOptions = async () => {
        const [ sexTypesOptions, ageStratumsOptions, indirectRecordsTypesOptions, methodologiesTypesOptions,
          flightTypes ] = await Promise.all([
          faunaApi.getSexTypes(),
          faunaApi.getAgeStratums(),
          faunaApi.getIndirectRecordTypes(),
          faunaApi.getMethodologiesTypes(),
          faunaApi.getFlightTypes(),
        ]);
        setOptions({ ...options, sexTypesOptions, ageStratumsOptions, indirectRecordsTypesOptions, methodologiesTypesOptions,
          flightTypes });
      };
      fetchOptions();
    }
    // eslint-disable-next-line
    }, [ campaignHash ]);

  useEffect(() => {
    const fetchData = async () => {
      const { methodologyType, point } = getQueryParams();
      const filtersThatNeedLabels = { point };
      Object.keys(filtersThatNeedLabels).forEach(key => filtersThatNeedLabels[key] === null ? delete filtersThatNeedLabels[key] : {});
      const filtersLabels = oie(filtersThatNeedLabels) ? {} : await usersApi.getLabels({ campaignSubtypeId, ...filtersThatNeedLabels });
      setFilters({ methodologyType, point });
      setFiltersLabels(filtersLabels);
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const getMethodologies = async ({ page, size }) => {
    query.set('page', page);
    query.set('size', size);
    return await faunaApi.getMethodologiesList(query.toString());
  };

  const editMethodology = async ({ id: methodologyId, ...methodology }) => {
    await faunaApi.updateMethodology({ updatedData: methodology, methodologyId });
    tableRef.current && tableRef.current.onQueryChange();
  };

  const deleteMethodology = async ({ id: methodologyId }) => {
    await faunaApi.deleteMethodology({ toDelete: true, methodologyId });
    tableRef.current && tableRef.current.onQueryChange();
    closeDialog();
  };

  const goToRecords = methodologyId => changeActive('records', `?methodology=${methodologyId}`);

  const openCreateRecordDialog = methodology => {
    const { name, id, samplingStationName, samplingStationId, timeIntervals: transitIntervals, typeId: methodologyType } = methodology;
    setDialog({
      isOpen: true,
      type: 'create-record',
      data: {
        methodologySelected: { label: name, value: id, samplingStationName, samplingStationId, transitIntervals, methodologyType },
        pointSelected: { label: samplingStationName, value: samplingStationId },
      },
    });
  };

  const openEditMethodologyDialog = methodology => setDialog({ isOpen: true, type: 'edit-methodology', data: methodology });

  const openDeleteMethodologyDialog = methodology => setDialog({ isOpen: true, type: 'delete-methodology', data: methodology });

  const openViewPhotoDialog = picturesInfo => setDialog({ isOpen: true, type: 'view-photos', data: picturesInfo });

  const closeDialog = () => setDialog({ isOpen: false, type: '' });

  const getDialogType = () => {
    switch (dialog.type) {
      case 'create-record': {
        return <FaunaRecordCreateDialog
          getOptions={{
            getSpeciesOptions: getOptions('species'),
          }}
          preselectedData={dialog.data}
          actions={{ createRecord: createRecord({ campaignId: context.selectedCampaign.id, tableRef }), closeDialog }}
          options={{
            recordsTypesOptions:
              recordTypesByMethodologies[dialog.data.methodologySelected.methodologyType]
                .map(rt => ({ label: componentId === 'flora' ? floraRecordsTypesEnum[rt] : faunaRecordsTypesEnum[rt], value: rt })),
            ...options,
          }}
          ctLang={context.campaignSubtypeLanguage}
        />;
      }
      case 'edit-methodology': {
        return <MethodologyEditDialog
          actions={{ editMethodology, closeDialog }}
          prevMethodology={dialog.data}
        />;
      }
      case 'delete-methodology': {
        return <MethodologyDeleteDialog
          actions={{ deleteMethodology, closeDialog }}
          methodology={dialog.data}
        />;
      }
      case 'view-photos':
        return <DialogImagePreviewer
          pictures={dialog.data.pictures}
          title={upperCaseFirstLetter(dialog.data.title)}
          additionalClass='image-species-container'
        />;
      default:
        break;
    }
  };

  const filtersQuery = new URLSearchParams();
  addFiltersToQuery(filtersQuery, filters);
  return (
    <Grid container>
      <TitleContainer maxWidth="xl" breadcrumbs={[ 'company', 'campaigns', 'points', { name: 'Metodologías' } ]}>
        Metodologías
      </TitleContainer>
      <ContentContainer maxWidth="xl">
        <Box my={1}>
          <Collapser title={'Filtros'}>
            {filtersLabels &&
              <>
                <Grid container spacing={1}>
                  <Grid item>
                    <FilterText
                      id={pointFilterKey}
                      label={context.campaignSubtypeLanguage.Point}
                      prevValue={{ label: filtersLabels[pointFilterKey], value: point }}
                      getOptions={getOptions(pointFilterKey)}
                      onChange={setFilter}
                    />
                  </Grid>
                  <Grid item>
                    <FilterSelect
                      id={methodologyTypeFilterKey}
                      label="Tipo de metodología"
                      prevValue={filters[methodologyTypeFilterKey]}
                      options={options.methodologiesTypesOptions}
                      onChange={setFilter}
                    />
                  </Grid>
                </Grid>
              </>
            }
          </Collapser>
        </Box>
        <MethodologiesList
          tableRef={tableRef}
          actions={{
            getMethodologies,
            goToRecords,
            openCreateRecordDialog,
            openEditMethodologyDialog,
            openDeleteMethodologyDialog,
            openViewPhotoDialog,
          }}
          currentUser={context.currentUser}
          selectedCampaign={context.selectedCampaign}
          hasAdminPowers={context.checkIfAdminPowers()}
        />
      </ContentContainer>
      <DialogWrap maxWidth='sm' fullWidth onClose={closeDialog} aria-labelledby='form-dialog-title'
        open={dialog.isOpen}>
        {getDialogType()}
      </DialogWrap>
    </Grid>
  );
};

MethodologiesContainer.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  changeActive: PropTypes.func.isRequired,
};


export {
  MethodologiesContainer,
};