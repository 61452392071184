import React from 'react';
import { Paper, Tabs, Tab, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Flower, PineTree } from 'src/utils/mdIcons';


const useStyles = makeStyles(theme => ({
  mainContainer: {
    height: theme.spacing(5),
    backgroundColor: theme.palette.flora.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(0),
    borderTop: '1px solid lightgrey',
  },
  paper: {
    backgroundColor: theme.palette.flora.primary.main,
    width: '100%',
    height: theme.spacing(5),
  },
  tabs: {
    height: theme.spacing(5),
    minHeight: 'auto',
  },
  tab: {
    minWidth: 'auto',
    minHeight: 'auto',
  },
  tabFlex: {
    height: theme.spacing(5),
  },
  icon: {
    color: theme.palette.common.white,
  },
  tabLabel: {
    color: theme.palette.common.white,
  },
  tabIndicator: {
    backgroundColor: theme.palette.common.white,
  },
}));

const AppTabsUI = () => {
  const classes = useStyles();
  return (
    <Box py={1} display="flex" className={classes.mainContainer} justifyContent="space-between" alignItems="center">
      <Paper className={classes.paper} elevation={0}>
        <Tabs classes={{ root: classes.tab, flexContainer: classes.tabFlex }} variant="fullWidth" value={0}
          TabIndicatorProps={{ className: classes.tabIndicator }}>
          <Tab className={classes.tab} label={
            <Box display="flex">
              <Flower fontSize="small" className={classes.icon} />
              <Typography variant="caption" className={classes.tabLabel}>Flora</Typography>
            </Box>
          } />
          <Tab className={classes.tab} label={
            <Box display="flex">
              <PineTree fontSize="small" className={classes.icon} />
              <Typography variant="caption" className={classes.tabLabel}>Forestal</Typography>
            </Box>
          } />
        </Tabs>
      </Paper>
    </Box>
  );
};

AppTabsUI.propTypes = {
  backText: PropTypes.string,
  title: PropTypes.string,
};


export {
  AppTabsUI,
};