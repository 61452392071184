import { get } from 'src/services/api';


const getAllSources = async () => (await get('catalogs-and-sources/sources')).data;

const getSelectableCatalogs = async () => (await get('catalogs-and-sources/catalogs')).data;


export {
  getAllSources,
  getSelectableCatalogs,
};
