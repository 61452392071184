import React from 'react';
import urlJoin from 'url-join';
import config from 'src/config/local';

import { isTransect } from 'src/utils/checkers';
import { PhotoConfigCheckbox } from 'src/scenes/Campaign/scenes/Downloadables/components/PhotoConfigCheckbox';


const { serverUrl, smacoreLink } = config;

const descriptionPStyle = { margin: 0 };
const infoDivStyle = { paddingLeft: '0.5em', paddingRight: '0.5em' };
const PhotoInfo = <div style={infoDivStyle}>
  <p>Las coordenadas se toman como las mejores coordenadas disponibles en Nviro para el objeto de la foto, en formato decimal</p>
  <p>
    Cuando la foto es subida por la página web en vez de por la aplicación móvil, su fecha se saca de las siguientes fuentes:
    fecha de detección del registro, si no existe, entonces se usa fecha de creación en los metadatos de la foto (notar que
    no suele tener zona horaria), y si tampoco existe el metadato, se usa la última fecha de la modificación detectada del archivo
    subido (si la foto se recorta en Nviro, se preserva la fecha previa al recorte). La fecha tiene formato
    {'"'}[año]-[mes]-[día]T[hora]{'"'}.
  </p>
</div>;

const l = urlChunk => urlJoin(serverUrl, urlChunk);

const plots = ({ campaignId, hasPermissionFor, userIsFan }) => [
  {
    title: 'SMA-Darwincore (V5.2)',
    link: l(`/api/downloads/flora-plot-smacore?campaignId=${campaignId}`),
    formats: [ 'xlsx' ],
    description: <p style={descriptionPStyle}>
      Archivo con los datos de la campaña en formato <a href={smacoreLink} target="_blank" rel="noreferrer">SMA-Darwincore</a>.
    </p>,
    disabled: !hasPermissionFor('flora-download:plots-smacore', { extraCondition: !userIsFan, checkOnCampaignRole: true }),
    // hide to people who can't use it for reasons other than "is a fan".
    hidden: !hasPermissionFor('flora-download:plots-smacore', { checkOnCampaignRole: true }) && !userIsFan,
    info: (
      <div style={infoDivStyle}>
        { userIsFan && <p><strong>Solo disponible para la versión pagada de Nviro</strong></p> }
        <p>Ya que Nviro debió adaptar los datos que tiene actualmente a este nuevo formato, por favor note lo siguiente:</p>
        <ul style={{ paddingLeft: '2em' }}>
          <li>
            Los datos de línea base se reportan usando el formato de reporte biodiversidad SMA - Monitoreo,
            actualizada a la versión 5.2 del 5 de Diciembre del 2022.
          </li>
          <li>
            Los registros individuales no se incluyen, pues no tienen una parcela (ni
            estación ni réplica, en términos de la SMA) relacionada.
          </li>
          <li>
            Los datos de división, clase y orden de la especie (requeridos por la SMA) se obtienen cruzando los datos de Nviro
            con los del Inventario nacional de especies de Chile del MMA, y existen unos pocos casos en los cuales algunas especies
            de flora de los catálogos de Nviro no se encuentran en dicho inventario, por lo que en estos casos los datos taxonómicos
            mencionados quedan sin rellenar.
          </li>
          <li>
            Por otro lado, los valores taxonómicos considerados como válidos por la SMA no siempre coinciden con los del
            inventario del MMA, por lo que las especies del reino Plantae que pertenezcan a las divisiones Coniferophyta,
            Pteridophyta, Gnetophyta, Magnoliophyta, Antocerotophyta podrían potencialmente ser consideradas como inválidas para la SMA.
          </li>
          <li>
            Cuando existen, se usan los datos de cobertura de suelos de las parcelas para llenar las columnas
            de {'"Ecosistema nivel 1"'} y {'"Ecosistema nivel 2"'} de la hoja de {'"EstacionReplica"'}, correspondiendo cada nivel a los
            niveles elegidos en la aplicación que usa también dos niveles para definir la cobertura. En el caso que se use la
            opción {'"Otro"'} y se escriba una cobertura de suelo a mano, se deja lo escrito en la columna
            de {'"Ecosistema nivel 2"'} (que es más específica) y se deja el nivel 1 vacío.
          </li>
          <li>
            En los registros flora y registros forestales, los datos de condiciones reproductivas y
            estados del organismo pueden tener múltiples valores a la vez. En estos casos, se escriben en la columna de
            {'"Propiedades dinámicas"'} de la hoja de {'"Ocurrencia"'}, en las propiedades nombradas {'"Estados de los organismos"'} y
            {' "Condiciones reproductivas"'} según corresponda, a modo de no entrar en conflicto con las validaciones de la SMA que
            esperan un solo valor en las columnas de {'"Condición reproductivas"'} y {'"Estados del organismo"'}.
          </li>
          <li>
            Dado que la SMA exige que la columna {'"Valor"'} sea numérica, la cobertura se convierte a una escala basada en
            la descrita en la página 43 del documento
            {' "Guía para la descripción de los componentes suelo, flora y fauna de ecosistemas terrestres en el SEIA"'}, que
            se puede en encontrar en
            el <a rel="nofollow noopener noreferrer" target="_blank"
              href="https://sea.gob.cl/documentacion/guias-evaluacion-impacto-ambiental/descripcion-area-influencia">
              sitio web del SEA</a>.
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: 'Especies potenciales',
    link: l(`/api/downloads/probable-species?campaignId=${campaignId}&type=flora`),
    formats: [ 'csv', 'xlsx' ],
    description: `Listado de especies potenciales de la campaña provenientes de múltiples fuentes de datos.`,
    disabled: false,
    beta: true,
  },
  {
    title: 'Tabla de Cobertura',
    link: l(`/api/downloads/coverage-table?campaignId=${campaignId}`),
    formats: [ 'csv', 'xlsx' ],
    description: `Tabla de cobertura de la campaña, incluye las especies de los registros de flora colectados en
      las parcelas.`,
    disabled: !hasPermissionFor('flora-download:coverage-table'),
  },
  {
    title: 'Tabla de Cobertura EECC',
    link: l(`/api/downloads/coverage-table-eecc?campaignId=${campaignId}`),
    formats: [ 'csv', 'xlsx' ],
    description: `Tabla de cobertura de la campaña, incluye solo las especies colectadas en las parcelas que están en
      categoría de conservación.`,
    disabled: !hasPermissionFor('flora-download:coverage-table-eecc'),
  },
  {
    title: 'Registros individuales',
    link: l(`/api/downloads/individual-records?campaignId=${campaignId}`),
    formats: [ 'csv', 'xlsx', 'kmz' ],
    description: `Listado o kmz con los registros individuales colectados en la campaña.`,
    disabled: !hasPermissionFor('flora-download:individual-records'),
  },
  {
    title: 'Inventario de flora',
    link: l(`/api/downloads/flora-inventory?campaignId=${campaignId}`),
    formats: [ 'csv', 'xlsx' ],
    description: `Listado de registros de flora.`,
    disabled: !hasPermissionFor('flora-download:flora-inventory'),
  },
  {
    title: 'Inventario forestal',
    link: l(`/api/downloads/forest-inventory?campaignId=${campaignId}`),
    formats: [ 'csv', 'xlsx' ],
    description: `Listado de registros forestales.`,
    disabled: !hasPermissionFor('flora-download:forest-inventory'),
  },
  {
    title: 'Inventario flora-forestal',
    link: l(`/api/downloads/flora-forest-inventory?campaignId=${campaignId}`),
    formats: [ 'csv', 'xlsx' ],
    description: `Listado de registros de flora y forestales que incluye fórmulas como el factor de expansión.`,
    disabled: !hasPermissionFor('flora-download:flora-forest-inventory'),
  },
  {
    title: 'Listado de parcelas',
    link: l(`/api/downloads/plots-list?campaignId=${campaignId}`),
    formats: [ 'csv', 'xlsx', 'kmz' ],
    description: `Listado con las parcelas de la campaña.`,
    disabled: false,
  },
  {
    title: 'KML de campaña',
    link: l(`/api/downloads/campaign-kml?campaignId=${campaignId}`),
    formats: [ 'kml' ],
    description: 'KML que posee las unidades de muestreo y área de interés de la campaña.',
    disabled: !hasPermissionFor('flora-download:campaign-kml'),
  },
];

const pointQuadrat = ({ campaignId, hasPermissionFor, userIsFan }) => [
  {
    title: 'SMA-Darwincore (V5.2)',
    link: l(`/api/downloads/flora-point-quadrat-smacore?campaignId=${campaignId}`),
    formats: [ 'xlsx' ],
    description: <p>
      Archivo con los datos de la campaña en formato <a href={smacoreLink} target="_blank" rel="noreferrer">SMA-Darwincore</a>.
    </p>,
    disabled: !hasPermissionFor('flora-download:point-quadrat-smacore', { extraCondition: !userIsFan, checkOnCampaignRole: true }),
    // hide to people who can't use it for reasons other than "is a fan".
    hidden: !hasPermissionFor('flora-download:point-quadrat-smacore', { checkOnCampaignRole: true }) && !userIsFan,
    info: (
      <div style={{ paddingLeft: '0.5em', paddingRight: '0.5em' }}>
        { userIsFan && <p><strong>Solo disponible para la versión pagada de Nviro</strong></p> }
        <p>Ya que Nviro debió adaptar los datos que tiene actualmente a este nuevo formato, por favor note lo siguiente:</p>
        <ul style={{ paddingLeft: '2em' }}>
          <li>
            Los datos se reportan usando el formato de reporte biodiversidad SMA - Monitoreo,
            actualizada a la versión 5.2 del 5 de Diciembre del 2022.
          </li>
          <li>
            Los datos de división, clase y orden de la especie, requeridos por la SMA a partir de la versión 3 del formato,
            se obtienen cruzando los datos de Nviro con los del Inventario nacional de especies de Chile del MMA, y existen
            unos pocos casos en los cuales algunas especies de flora de los catálogos de Nviro no se encuentran en dicho inventario,
            por lo que en estos casos los datos taxonómicos mencionados quedan sin rellenar.
          </li>
          <li>
            Por otro lado, a partir de la versión 5, los valores taxonómicos considerados como válidos por la SMA
            no siempre coinciden con los del inventario de la MMA, por lo que las
            las divisiones Coniferophyta y Gnetophyta se traducen a Tracheophyta, que la SMA considera como el nombre aceptado
            para ellas.
          </li>
          <li>
            Hay datos sobre los ecosistemas que Nviro no ha podido reportar en la hoja de {'"EstacionReplica"'}.
          </li>
          <li>
            Dado que los datos que se toman en campañas de transecto no son individualizados, las columnas {'"Condición reproductiva"'} y
            {'"Estado del organismo"'} en la hoja de {'"Ocurrencia"'} pueden tener varios valores a la vez. En estos casos, los valores
            se escriben en la columna de {'"Propiedades dinámicas"'}, en las propiedades nombradas {'"Estados de los organismos"'} y
            {'"Condiciones reproductivas"'} según corresponda, a modo de no entrar en conflicto con las validaciones de la SMA que esperan
            valores individualizados.
          </li>
          <li>
            Todos los sustratos registrados se toman como componentes abióticos. Por lo tanto, a pesar de tener materia orgánica,
            en la hoja de Ocurrencia se maneja a los rastrojos como al resto de los componentes abióticos y no se les asigna una especie.
            El sustrato de tipo mantillo se trata similarmente.
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: 'Transecto flora',
    link: l(`/api/downloads/flora-transect?campaignId=${campaignId}`),
    formats: [ 'csv', 'xlsx' ],
    description: `Descargable con los datos de especies tomados en las transectas en los distintos estratos por punto.`,
    disabled: false,
  },
  {
    title: 'Frecuencia en transecto',
    link: l(`/api/downloads/flora-point-quadrat-frequency?campaignId=${campaignId}`),
    formats: [ 'csv', 'xlsx' ],
    description: `Descargable que contiene la distribución de las especies dentro de las diferentes transectas.`,
    disabled: false,
  },
  {
    title: 'KML de campaña',
    link: l(`/api/downloads/campaign-kml?campaignId=${campaignId}`),
    formats: [ 'kml' ],
    description: 'KML que posee las unidades de muestreo y área de interés de la campaña.',
    disabled: !hasPermissionFor('flora-download:campaign-kml'),
  },
];

const pointIntercept = ({ campaignId, hasPermissionFor, userIsFan }) => [
  {
    title: 'SMA-Darwincore (V5.2)',
    link: l(`/api/downloads/flora-point-intercept-smacore?campaignId=${campaignId}`),
    formats: [ 'xlsx' ],
    description: <p>
      Archivo con los datos de la campaña en formato <a href={smacoreLink} target="_blank" rel="noreferrer">SMA-Darwincore</a>.
    </p>,
    disabled: !hasPermissionFor('flora-download:point-intercept-smacore', { extraCondition: !userIsFan, checkOnCampaignRole: true }),
    // hide to people who can't use it for reasons other than "is a fan".
    hidden: !hasPermissionFor('flora-download:point-intercept-smacore', { checkOnCampaignRole: true }) && !userIsFan,
    info: (
      <div style={{ paddingLeft: '0.5em', paddingRight: '0.5em' }}>
        { userIsFan && <p><strong>Solo disponible para la versión pagada de Nviro</strong></p> }
        <p>Ya que Nviro debió adaptar los datos que tiene actualmente a este nuevo formato, por favor note lo siguiente:</p>
        <ul style={{ paddingLeft: '2em' }}>
          <li>
            Los datos se reportan usando el formato de reporte biodiversidad SMA - Monitoreo,
            actualizada a la versión 5.2 del 5 de Diciembre del 2022.
          </li>
          <li>
            Los datos de división, clase y orden de la especie, requeridos por la SMA a partir de la versión 3 del formato,
            se obtienen cruzando los datos de Nviro con los del Inventario nacional de especies de Chile del MMA, y existen
            unos pocos casos en los cuales algunas especies de flora de los catálogos de Nviro no se encuentran en dicho inventario,
            por lo que en estos casos los datos taxonómicos mencionados quedan sin rellenar.
          </li>
          <li>
            Por otro lado, a partir de la versión 5, los valores taxonómicos considerados como válidos por la SMA
            no siempre coinciden con los del inventario de la MMA, por lo que las
            las divisiones Coniferophyta y Gnetophyta se traducen a Tracheophyta, que la SMA considera como el nombre aceptado
            para ellas.
          </li>
          <li>
            Hay datos sobre los ecosistemas que Nviro no ha podido reportar en la hoja de {'"EstacionReplica"'}.
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: 'Especies y sustratos por punto',
    link: l(`/api/downloads/flora-point-intercept-presences-and-substratums?campaignId=${campaignId}`),
    formats: [ 'xlsx' ],
    description: `Descargable que contiene las especies colectadas y sustratos registrados en los puntos de las transectas.
      Cada hoja del documento representa una transecta distinta.`,
    disabled: false,
  },
  {
    title: 'KML de campaña',
    link: l(`/api/downloads/campaign-kml?campaignId=${campaignId}`),
    formats: [ 'kml' ],
    description: 'KML que posee las unidades de muestreo y área de interés de la campaña.',
    disabled: !hasPermissionFor('flora-download:campaign-kml'),
  },
];

// Descargables comunes de parcelas y transectas
const flora = ({ campaignId, mainMethodId, hasPermissionFor, config, setConfig }) => [
  {
    title: 'Riqueza Florística',
    link: l(`/api/downloads/floristic-list?campaignId=${campaignId}`),
    formats: [ 'csv', 'xlsx' ],
    description: `Listado de todas las especies colectadas de la campaña.`,
    disabled: !hasPermissionFor('flora-download:floristic-list'),
  },
  {
    title: 'Riqueza Florística EECC',
    link: l(`/api/downloads/floristic-list-eecc?campaignId=${campaignId}`),
    formats: [ 'csv', 'xlsx' ],
    description: `Listado de todas las especies colectadas de la campaña que se encuentran en categoría de conservación.`,
    disabled: !hasPermissionFor('flora-download:floristic-list-eecc'),
  },
  {
    title: 'KML de EECC',
    link: l(`/api/downloads/eecc-species-kml?campaignId=${campaignId}`),
    formats: [ 'kml' ],
    description: `KML con las especies en categoría de conservación colectadas en la campaña. NOTA:
      ${
        isTransect(mainMethodId) ?
          `Los registros tienen una ubicación aproximada basada en el punto interno de la transecta en el cual fueron capturados.` :
          `Los registros individuales tienen ubicación propia mientras que las especies colectadas en parcelas tendrán la ubicación de la
          parcela en la cual fueron colectadas.`
      }
    `,
    disabled: !hasPermissionFor('flora-download:eecc-species-kml'),
  },
  {
    title: 'Tracks de la campaña',
    link: l(`/api/downloads/campaign-track-kml?campaignId=${campaignId}`),
    formats: [ 'kml' ],
    description: `KML con los tracks de los usuarios en campaña.`,
    disabled: false,
  },
  {
    title: 'Fotos de la campaña',
    link: l(`/api/downloads/campaign-pictures?campaignId=${campaignId}${config?.photos?.withMetadata ? '&withMetadata=yeah' : ''}`),
    formats: [ 'zip' ],
    description: <div>
      <p style={descriptionPStyle}>
        Archivo comprimido que posee las fotos tomadas de todas las
        { isTransect(mainMethodId) ? ' transectas y registros ' : ' parcelas, registros de flora/forestales y registros individuales ' }
        de la campaña actual.
      </p>
      <PhotoConfigCheckbox config={config} setConfig={setConfig}/>
    </div>,
    disabled: false,
    info: PhotoInfo,
  },
  {
    title: 'Datos de la campaña',
    link: l(`/api/downloads/all-campaign-data?campaignId=${campaignId}`),
    formats: [ 'csv', 'xlsx' ],
    description: `Descargable que contiene toda la información de la campaña.`,
    disabled: false,
  },
];

const fauna = () => [
  //TODO: Completar con descargables que son de fauna
];

const stations = ({ campaignId, hasPermissionFor, userIsFan, config, setConfig }) => [
  {
    title: 'SMA-Darwincore (V5.2)',
    link: l(`/api/downloads/fauna-smacore?campaignId=${campaignId}`),
    formats: [ 'xlsx' ],
    description: <p style={descriptionPStyle}>
      Archivo con los datos de la campaña en formato <a href={smacoreLink} target="_blank" rel="noreferrer">SMA-Darwincore</a>.
    </p>,
    disabled: !hasPermissionFor('fauna-download:smacore', { extraCondition: !userIsFan, checkOnCampaignRole: true }),
    // hide to people who can't use it for reasons other than "is a fan".
    hidden: !hasPermissionFor('fauna-download:smacore', { checkOnCampaignRole: true }) && !userIsFan,
    info: (
      <div style={{ paddingLeft: '0.5em', paddingRight: '0.5em' }}>
        { userIsFan && <p><strong>Solo disponible para la versión pagada de Nviro</strong></p> }
        <p>Ya que Nviro debió adaptar los datos que tiene actualmente a este nuevo formato, por favor note lo siguiente:</p>
        <ul style={{ paddingLeft: '2em' }}>
          <li>
            Los datos se reportan usando el formato de reporte biodiversidad SMA - Monitoreo,
            actualizada a la versión 5.2 del 5 de Diciembre del 2022.
          </li>
          <li>
            Solo se incluyen datos de estaciones de muestreo cuyo estado sea {'"Sincronizada"'}.
          </li>
          <li>
            Los registros aislados no se incluyen, pues no tienen estación ni metodología relacionadas. Tampoco se incluyen registros
            indirectos sin especie asociada.
          </li>
          <li>
            Hay datos sobre los ecosistemas que Nviro no ha podido reportar en la hoja de {'"EstacionReplica"'}. Para el ecosistema nivel 2
            se usan los ambientes de las estaciones de muestreo.
          </li>
          <li>
            La columna de {'"Condición reproductiva"'} en la hoja de {'"Ocurrencia"'} se deja vacía. Nviro no tiene estos datos de momento,
            pero además no son requeridos en línea base.
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: 'Informe de Captura - SAG',
    link: l(`/api/downloads/sag-report?campaignId=${campaignId}`),
    formats: [ 'csv', 'xlsx' ],
    description: <div>
      Descargable de la información base para el { '"' }informe de captura de fauna silvestre{ '"' }. Actualmente
      solo incluye la información de los individuos capturados mediante la metodología <strong>Trampa Sherman</strong>.
    </div>,
    warning: (
      <div style={ infoDivStyle }>
        A cada individuo capturado se le asocian las coordenadas de la trampa más cercana. Por este motivo,
        es indispensable que las ubicaciones de tanto el equipo como del registro de individuo sean correctas. Si este último
        es creado lejos del área de trabajo, se recuerda que la ubicación puede ser escrita manualmente en la aplicación
        o editada en la web.
      </div>
    ),
    disabled: false,
  },
  {
    title: 'Fotos de la campaña',
    link: l(`/api/downloads/campaign-pictures?campaignId=${campaignId}${config?.photos?.withMetadata ? '&withMetadata=yeah' : ''}`),
    formats: [ 'zip' ],
    description: <div>
      <p style={descriptionPStyle}>
        Archivo comprimido que posee las fotos tomadas de todas las estaciones de muestreo, metodologías,
        registros directos, registros indirectos y registros aislados de la campaña actual.
      </p>
      <PhotoConfigCheckbox config={config} setConfig={setConfig}/>
    </div>,
    info: PhotoInfo,
    disabled: false,
  },
  {
    title: 'Tracks de la campaña',
    link: l(`/api/downloads/campaign-track-kml?campaignId=${campaignId}`),
    formats: [ 'kml' ],
    description: `KML con los tracks de los usuarios en campaña.`,
    disabled: false,
  },
  {
    title: 'Listado de fauna',
    link: l(`/api/downloads/faunistic-list?campaignId=${campaignId}`),
    description: `Listado de especies colectadas de la campaña, incluye registros aislados y registros directos e indirectos
      de las estaciones de muestreo.`,
    formats: [ 'csv', 'xlsx' ],
    disabled: false,
  },
  {
    title: 'Listado de fauna EECC',
    link: l(`/api/downloads/faunistic-list?campaignId=${campaignId}&onlyEecc=true`),
    description: `Listado de especies eecc colectadas de la campaña, incluye registros aislados y registros directos e indirectos
      de las estaciones de muestreo.`,
    formats: [ 'csv', 'xlsx' ],
    disabled: false,
  },
  {
    title: 'Resumen de EM',
    link: l(`/api/downloads/sampling-stations-summary?campaignId=${campaignId}`),
    description: `Resumen de las estaciones de muestreo junto con las metodologías usadas en cada una`,
    formats: [ 'csv', 'xlsx' ],
    disabled: false,
  },
  {
    title: 'Metodologías por Ambiente',
    link: l(`/api/downloads/method-count-per-environment?campaignId=${campaignId}`),
    description: `Conteo de las metodologías por ambiente usadas en la campaña.`,
    formats: [ 'csv', 'xlsx' ],
    disabled: false,
  },
  {
    title: 'Participación relativa por Ambiente',
    link: l(`/api/downloads/relative-participation-per-environment?campaignId=${campaignId}`),
    description: `Participación relativa de las estaciones de muestreo por ambiente en la campaña.`,
    formats: [ 'csv', 'xlsx' ],
    disabled: false,
  },
  {
    title: 'Abundancia de especies',
    link: l(`/api/downloads/abundance-table?campaignId=${campaignId}`),
    description: `Conteo de especies colectadas en registros directos en la campaña que incluye la abundancia relativa `
      + `y la densidad absoluta de las especies`,
    info: (
      <div>
        <strong>- Abundancia relativa:</strong> calculada como el total de apariciones de una especie dividida por el
          total de apariciones de todas las especies.<br/>
        <strong>- Densidad absoluta:</strong> calculada como 10.000 por el promedio de las densidades de las especies por
          estación; que es el promedio de la cantidad de individuos dividido por área de cada metodología en la que esté
          la especie.
      </div>
    ),
    formats: [ 'csv', 'xlsx' ],
    disabled: false,
  },
  {
    title: 'KML de campaña',
    link: l(`/api/downloads/campaign-kml?campaignId=${campaignId}`),
    formats: [ 'kml' ],
    description: 'KML que posee las estaciones de muestreo y área de interés de la campaña.',
    disabled: false,
  },
  {
    title: 'Registros aislados',
    link: l(`/api/downloads/isolated-records?campaignId=${campaignId}`),
    formats: [ 'csv', 'xlsx', 'kml' ],
    description: `Información correspondiente a los registros aislados colectados en campaña.`,
    disabled: false,
  },
  {
    title: 'Especies potenciales',
    link: l(`/api/downloads/probable-species?campaignId=${campaignId}&type=fauna`),
    formats: [ 'csv', 'xlsx' ],
    description: `Listado de especies potenciales de la campaña provenientes de múltiples fuentes de datos.`,
    disabled: false,
    beta: true,
  },
  {
    title: 'KML de EECC',
    link: l(`/api/downloads/fauna-eecc-species-kml?campaignId=${campaignId}`),
    description: `KML que posee eecc colectadas durante la campaña. En este se incluyen registros directos
      (con individuos si tiene), indirectos, de tránsito y aislados`,
    formats: [ 'kml' ],
    disabled: false,
  },
  {
    title: 'Individuos Registrados',
    link: l(`/api/downloads/fauna-individuals?campaignId=${campaignId}`),
    description: `Información correspondiente a los individuos registrados durante la campaña.`,
    formats: [ 'csv', 'xlsx', 'kml' ],
    disabled: false,
  },
  {
    title: 'Registros por metodologías',
    link: l(`/api/downloads/abundance-per-methods?campaignId=${campaignId}`),
    description: `Información de los registros con especie agrupados por metodologías. También se
      incluyen los registros aislados.`,
    formats: [ 'csv', 'xlsx', 'kml' ],
    disabled: false,
  },
  {
    title: 'Kml metodologías',
    link: l(`/api/downloads/fauna-methodologies-kml?campaignId=${campaignId}`),
    description: `Kml que posee información de las distintas metodologías pertenecientes a la campaña.`,
    formats: [ 'kml' ],
    disabled: false,
  },
  {
    title: 'Registros de tránsito',
    link: l(`/api/downloads/aerial-transit?campaignId=${campaignId}`),
    formats: [ 'csv', 'xlsx', 'kml' ],
    description: `Descargable con los registros tomados mediante la metodología de tránsito aéreo en la campaña.`,
    disabled: false,
  },
  {
    title: 'Registros indirectos',
    link: l(`/api/downloads/indirect-records?campaignId=${campaignId}`),
    formats: [ 'csv', 'xlsx', 'kml' ],
    description: `Descargable con los registros indirectos tomados durante los transectos lineales en la campaña.`,
    disabled: false,
  },
  {
    title: 'Equipamiento utilizado',
    link: l(`/api/downloads/equipment?campaignId=${campaignId}`),
    formats: [ 'csv', 'xlsx', 'kml' ],
    description: `Descargable con la información del equipamiento utilizado en las diferentes metodologías.`,
    disabled: false,
  },
  {
    title: 'Datos de la campaña',
    link: l(`/api/downloads/all-campaign-data?campaignId=${campaignId}`),
    formats: [ 'csv', 'xlsx' ],
    description: `Descargable que contiene toda la información de la campaña.`,
    disabled: false,
  },
];

const census = ({ campaignId, hasPermissionFor, userIsFan }) => [
  {
    title: 'SMA-Darwincore (V5.2)',
    link: l(`/api/downloads/flora-census-smacore?campaignId=${campaignId}`),
    formats: [ 'xlsx' ],
    description: <p style={descriptionPStyle}>
      Archivo con los datos de la campaña en formato <a href={smacoreLink} target="_blank" rel="noreferrer">SMA-Darwincore</a>.
    </p>,
    disabled: !hasPermissionFor('flora-download:census-smacore', { extraCondition: !userIsFan, checkOnCampaignRole: true }),
    // hide to people who can't use it for reasons other than "is a fan".
    hidden: !hasPermissionFor('flora-download:census-smacore', { checkOnCampaignRole: true }) && !userIsFan,
    info: (
      <div style={infoDivStyle}>
        { userIsFan && <p><strong>Solo disponible para la versión pagada de Nviro</strong></p> }
        <p>Ya que Nviro debió adaptar los datos que tiene actualmente a este nuevo formato, por favor note lo siguiente:</p>
        <ul style={{ paddingLeft: '2em' }}>
          <li>
            Los datos de línea base se reportan usando el formato de reporte biodiversidad SMA - Monitoreo,
            actualizada a la versión 5.2 del 5 de Diciembre del 2022.
          </li>
          <li>
            En las campañas de censo no se usa el concepto de unidades de muestreo, por lo que no hay objetos directamente
            análogos a las estaciones-réplicas o a los eventos del formato SMA-Darwincore. Para poder escribir datos en la hoja de
            {'"EstacionReplica"'} y en las columnas de Evento de la hoja de {'"Ocurrencia"'}, los datos se derivan directamente de
            los registros, resultando en una estación réplica por registro, para rellenar las columnas que se puedan.
          </li>
          <li>
            Los datos de división, clase y orden de la especie, requeridos por la SMA a partir de la versión 3 del formato,
            se obtienen cruzando los datos de Nviro con los del Inventario nacional de especies de Chile del MMA, y existen
            unos pocos casos en los cuales algunas especies de flora de los catálogos de Nviro no se encuentran en dicho inventario,
            por lo que en estos casos los datos taxonómicos mencionados quedan sin rellenar.
          </li>
          <li>
            Por otro lado, a partir de la versión 5, los valores taxonómicos considerados como válidos por la SMA
            no siempre coinciden con los del inventario de la MMA, por lo que las
            especies del reino Plantae que pertenezcan a las divisiones Coniferophyta, Pteridophyta, Gnetophyta, Magnoliophyta,
            Antocerotophyta podrían potencialmente ser consideradas como inválidas para la SMA.
          </li>
          <li>
            Hay datos sobre los ecosistemas que Nviro no ha podido reportar en la hoja de {'"EstacionReplica"'}.
          </li>
          <li>
            Los datos de estados fenológicos se análogan a la columan de {'"Condición reproductiva"'} de la hoja de {'"Ocurrencia"'}
            cuando el registro tiene solo uno de dichos estados. Si un solo registro tiene más de un estado fenológico,
            estos se escriben en la columna de {'"Propiedades dinámicas"'}, en la propiedad nombrada {'"Condiciones reproductivas"'}
            a modo de no entrar en conflicto con las validaciones de la SMA que esperan un único valor de condicón reproductiva.
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: 'Registros de la campaña',
    link: l(`/api/downloads/census-records?campaignId=${campaignId}`),
    formats: [ 'csv', 'xlsx', 'kmz' ],
    description: `Listado o kmz con los registros colectados en la campaña.`,
    disabled: !hasPermissionFor('flora-download:census-records'),
  },
];

const componentMap = { flora, fauna };
const mainMethodMap = { stations, plots, 'point-quadrat': pointQuadrat, 'point-intercept': pointIntercept, census };

const downloadables = ({ componentId, mainMethodId, campaignId, hasPermissionFor, userIsFan, config, setConfig }) => {

  const props = { campaignId, hasPermissionFor, userIsFan, config, setConfig };
  const downloadablesByMethodId = mainMethodMap[mainMethodId](props).filter(downloadable => !downloadable.hidden);
  const downloadablesByComponentId = componentMap[componentId]({ ...props, mainMethodId }).filter(downloadable => !downloadable.hidden);

  return [ ...downloadablesByMethodId, ...downloadablesByComponentId ];
};


export {
  downloadables,
};
