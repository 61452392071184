import React, { Fragment } from 'react';
import { DialogContent, DialogContentText, DialogActions, Button, DialogTitle } from '@material-ui/core';
import PropTypes from 'prop-types';


const DeletePointsDialog = props => {
  const { actions, cLang } = props;

  return (
    <Fragment>
      <DialogTitle id="form-dialog-title">Eliminar {cLang.points}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          ¿Realmente deseas eliminarlas?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog} color="default">Cancelar</Button>
        <Button onClick={actions.deletePoints} color="primary">Confirmar</Button>
      </DialogActions>
    </Fragment>
  );
};


DeletePointsDialog.propTypes = {
  actions: PropTypes.object,
  cLang: PropTypes.object,
};


export { DeletePointsDialog };
