import { get, patch } from 'src/services/api';


const getLinkedCompanies = async () => (await get('company/monitor-companies')).data;

const getCompanyCampaigns = async params => (await get('company/export-allowed', params)).data;

const updateCampaignsToAllowExport = async params => (await patch('company/export-allowed-campaigns', params)).data;

const updateProjectsToAllowExport = async params => (await patch('company/export-allowed-projects', params)).data;


export { getLinkedCompanies, getCompanyCampaigns, updateCampaignsToAllowExport, updateProjectsToAllowExport };