import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import urlJoin from 'url-join';

import { AccessControl } from 'src/components';
import { ProjectsContainer, PlanningCampaignContainer, ProfileContainer, AlbumContainer, CatalogsInfoContainer,
  CompanyUsersContainer, CompanySettingsContainer, CompaniesContainer, FaqContainer, DownloadRedirection,
  MobileNotYetContainer, CompanyEnvironmentContainer, CampaignsContainer, BillsContainer } from 'src/scenes';

import CampaignContainer from 'src/scenes/Campaign/CampaignContainer';


const routes = [
  {
    id: 'projects',
    path: 'projects',
    Component: ProjectsContainer,
  },
  {
    id: 'profile',
    path: 'profile',
    Component: ProfileContainer,
    noEmailVerification: true,
  },
  {
    id: 'companies',
    path: 'companies',
    action: 'companies-page:visit',
    Component: CompaniesContainer,
  },
  {
    id: 'bills',
    path: 'bills',
    action: 'bills-page:visit',
    Component: BillsContainer,
  },
  {
    id: 'album',
    path: 'album',
    Component: AlbumContainer,
  },
  {
    id: 'faq',
    path: 'faq',
    Component: FaqContainer,
    noEmailVerification: true,
  },
  {
    id: 'company-users',
    path: 'company/users',
    action: 'company-page:visit',
    Component: CompanyUsersContainer,
  },
  {
    id: 'company-environment',
    path: 'company/environment',
    Component: CompanyEnvironmentContainer,
  },
  {
    id: 'company',
    path: 'company',
    action: 'company-page:visit',
    Component: CompanySettingsContainer,
    hasNestedRoutes: true,
  },
  {
    id: 'campaigns-by-project',
    path: 'projects/:projectId/campaigns',
    action: 'campaigns-page:visit',
    Component: CampaignsContainer,
  },
  // NOTE: esta ruta reemplaza a la vista de campañas, trae opciones del proyecto: campañas - planes de monitoreo - compromisos - analíticas
  // {
  //   id: 'project',
  //   path: 'projects/:projectId',
  //   // TODO: crear permiso para entrar al proyecto (que es distinto de ver campañas ahora que existen planes y compromiso)
  //   action: 'campaigns-page:visit',
  //   Component: ProjectContainer,
  //   hasNestedRoutes: true,
  // },
  // TODO: esta ruta es parte de las rutas de una campaña, debería estar el componente y todo dentro de Campaign
  {
    id: 'campaign-planning',
    path: 'campaigns/:campaignHash/planning',
    Component: PlanningCampaignContainer,
  },
  {
    id: 'campaign',
    path: 'campaigns/:campaignHash',
    action: 'campaign-page:visit',
    Component: CampaignContainer,
    hasNestedRoutes: true,
  },
  {
    id: 'download-redirect',
    path: 'download-file',
    Component: DownloadRedirection,
  },
  {
    id: 'catalogs',
    path: 'catalogs',
    Component: CatalogsInfoContainer,
  },
];

const WebRoutes = () => {

  const { path: basePath } = useRouteMatch();
  const homePath = urlJoin(basePath, 'projects');
  const profilePath = urlJoin(basePath, 'profile');
  const mobilePath = urlJoin(basePath, 'mobile-not-yet');
  const redirectToHomePath = () => <Redirect to={homePath} />;
  const redirectWithEmailCheck = ({ webContext }) =>
    webContext.currentUser?.emailVerified ? <Redirect to={homePath} /> : <Redirect to={profilePath} />;
  const redirectToMobilePath = () => <Redirect to={mobilePath} />;

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  const noAccessRedirect = checkEmailVerified =>
    checkEmailVerified ? redirectWithEmailCheck
    : isMobile ? redirectToMobilePath
    : redirectToHomePath;

  return (
    <Switch>
      <Route exact path={basePath} component={isMobile ? redirectToMobilePath : redirectToHomePath} />
      <Route exact path={mobilePath} component={isMobile ? MobileNotYetContainer : redirectToHomePath} />
      { routes.map(({ id, path, action, Component, hasNestedRoutes = false, noEmailVerification = false }) =>
        <Route key={id} exact={!hasNestedRoutes} path={`${basePath}/${path}`} render={props =>
          <AccessControl action={action} checkEmailVerified={!noEmailVerification} noAccessRender={noAccessRedirect(!noEmailVerification)}>
            {isMobile ? <MobileNotYetContainer /> : <Component {...props} />}
          </AccessControl>
        } />,
      )}
    </Switch>
  );
};


export { WebRoutes };
