import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, DialogTitle, DialogContent, DialogActions, Typography, Collapse } from '@material-ui/core';

import { WebContext } from 'src/scenes/web-context';
import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';
import { Alert } from '@material-ui/lab';
import { DialogButton } from 'src/components';


const { NOT_STARTED, LOADING, SUCCESS, ERROR } = fetchStatusEnum;

const PointDeleteDialog = props => {

  const { isFan } = useContext(WebContext);
  const { point, actions, csLang } = props;
  const pointDescription = `${csLang.point} "${point.name}"`;

  const [ fetchStatus, setFetchStatus ] = useState(NOT_STARTED);
  const [ errorAlert, setErrorAlert ] = useState({ isOpen: false, message: '' });

  const userIsFan = isFan();

  const handleConfirm = async () => {
    try {
      setFetchStatus(LOADING);
      await actions.editPoints({ toDelete: true });
      setFetchStatus(SUCCESS);
      setTimeout(() => actions.toggleDialog(), 300);
    } catch (e) {
      setErrorAlert({ isOpen: true, message: e.serverMessage || e.message });
      setFetchStatus(ERROR);
    }
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">Eliminar {csLang.Point}</DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Typography variant="body1" color="textPrimary">
            Estás por eliminar la <strong>{ pointDescription }</strong>, esta acción es irreversible y
            <Typography component='span' display="inline" color="error"> se perderán todos los datos</Typography> que se hayan
            capturado en ella.
          </Typography>
          { (point.statusId === 'todo' && ! userIsFan) && <>
            <br />
            <Typography variant="body1" color="textPrimary">
              Debes considerar que la {csLang.point} se encuentra en estado&nbsp;
              <Typography display="inline" component="span" color="error">
                pendiente&nbsp;
              </Typography>
              por lo que aún puede haber trabajo en progreso.
            </Typography>
            <br />
          </>}
          {fetchStatus === ERROR &&
            <Collapse in={errorAlert.isOpen}>
              <Alert severity="error" onClose={() => setErrorAlert(s => ({ ...s, isOpen: false }))}>
                {errorAlert.message}
              </Alert>
            </Collapse>
          }
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.toggleDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>Confirmar</DialogButton>
      </DialogActions>
    </>
  );
};

PointDeleteDialog.propTypes = {
  point: PropTypes.object,
  actions: PropTypes.object,
  csLang: PropTypes.object,
};


export {
  PointDeleteDialog,
};