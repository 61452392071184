import React from 'react';
import { Typography, Box, Paper, Button, Zoom } from '@material-ui/core';
import { RadioButtonUnchecked, CheckCircle, ArrowForwardIos, ArrowBackIos } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { truncateWithEllipsis as twe } from 'src/utils/util';
import { isTransect, isPlot, isStation } from 'src/utils/checkers';


const useStyles = makeStyles(theme => ({
  paper: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: 'white',
    transform: 'none',
    boxSizing: 'border-box',
  },
  reviewTitle: {
    overflowWrap: 'anywhere',
  },
  reviewedButton: {
    width: theme.spacing(28),
  },
  reviewedButtonDisabled: {
    width: theme.spacing(28),
    color: theme.palette.common.white,
  },
  contentWidth: {
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
    },
  },
  changePointButton: {
    cursor: 'pointer',
    '&:hover': {
      borderRadius: '4px',
      background: theme.palette.action.hover,
    },
  },
}));


const PointCard = ({ point, campaignSubtypeId, hasMorePoints, actions, canModify, csLang, options: { pointStatusesOptions } }) => {
  const { name, firstName, lastName, email, reviewed, observation, area, slope, exposureId, ...restPoint } = point;
  const { dateVisited, finishedDate, startDate, endDate } = restPoint;

  const classes = useStyles();

  const statusOption = (
    isTransect(campaignSubtypeId) ? { label: point.synced ? 'Sincronizada' : 'Pendiente' }
    : pointStatusesOptions.find(o => restPoint.statusId === o.value)
  );

  const titleLimit = 100;
  return (
    <Box pb={2}>
      <Paper className={classes.paper}>
        <Box display="flex">
          <Box className={classes.changePointButton} display="flex" alignItems="center" justifyContent="center" flexBasis="5%"
            {...(hasMorePoints ? { onClick: () => actions.onArrowClick('prev') } : {})} >
            <Box ml={1}><ArrowBackIos {...(hasMorePoints ? {} : { color: 'disabled' })} /></Box>
          </Box>
          <Box display="flex" flexBasis="90%" justifyContent="center">

            <Box className={classes.contentWidth} display="flex" flexDirection="column" flexBasis="90%">
              <Box pb={1}>
                <Typography variant="h4" title={name.length > titleLimit ? name : ''} color="textPrimary" className={classes.reviewTitle}>
                  {csLang.Point} <strong>{twe(name, titleLimit)}</strong>
                </Typography>
              </Box>
              <Box display="flex" width="100%">
                <Box p={0.5} alignSelf="flex-end" flexBasis="25%">
                  <Typography variant="body2" color="textPrimary">
                    Asignada a: <strong>{firstName} {lastName}</strong>
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    Correo: <strong>{email}</strong>
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    Estado: <strong>{statusOption?.label}</strong>
                  </Typography>
                </Box>

                <Box p={0.5} alignSelf="flex-end" textAlign="center" flexBasis="25%">
                  {isPlot(campaignSubtypeId) &&
                    <Typography variant="body2" color="textPrimary">
                      Fecha visitada: <strong>{dateVisited ? new Date(dateVisited).toLocaleDateString('es') : 'Sin visitar'}</strong>
                    </Typography>
                  }
                  {isStation(campaignSubtypeId) &&
                    <Typography variant="body2" color="textPrimary">
                      Fecha finalizada: <strong>{finishedDate ? new Date(finishedDate).toLocaleDateString('es') : 'Sin finalizar'}</strong>
                    </Typography>
                  }
                  {isTransect(campaignSubtypeId) &&
                    <Typography variant="body2" color="textPrimary">
                      Fecha inicio: <strong>{startDate ? new Date(startDate).toLocaleDateString('es') : 'No iniciada'}</strong>
                    </Typography>
                  }
                  {isTransect(campaignSubtypeId) &&
                    <Typography variant="body2" color="textPrimary">
                      Fecha fin: <strong>{endDate ? new Date(endDate).toLocaleDateString('es') : 'No finalizada'}</strong>
                    </Typography>
                  }
                  <Typography variant="body2" color="textPrimary">
                    Observaciones: <strong>{observation || 'Sin observaciones'}</strong>
                  </Typography>
                </Box>

                <Box p={0.5} alignSelf="flex-end" textAlign="center" flexBasis="25%">
                  {isPlot(campaignSubtypeId) &&
                    <>
                      <Typography variant="body2" color="textPrimary">
                        Tamaño: <strong>{area ? `${area} m²` : 'No aplica'} </strong>
                      </Typography>
                      <Typography variant="body2" color="textPrimary">
                        Pendiente: <strong>{slope ? `${slope} %` : 'No aplica'}</strong>
                      </Typography>
                      <Typography variant="body2" color="textPrimary">
                        Exposición: <strong>{exposureId || 'No aplica'}</strong>
                      </Typography>
                    </>
                  }
                </Box>

                <Box p={0.5} alignSelf="center" textAlign="center" flexBasis="25%">
                  {
                    reviewed ?
                      <Button className={classes.reviewedButtonDisabled} variant="contained" disabled={!canModify}
                        disableElevation fullWidth disableRipple
                        color="primary" endIcon={<Zoom in><CheckCircle /></Zoom>} >
                        Revisada
                      </Button>
                      :
                      <Button className={classes.reviewedButton} variant="outlined" disabled={!canModify} fullWidth color="primary"
                        endIcon={<RadioButtonUnchecked />} onClick={actions.reviewPoint}>
                        Marcar como revisada
                      </Button>
                  }
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.changePointButton} display="flex" alignItems="center" justifyContent="center" flexBasis="5%"
            {...(hasMorePoints ? { onClick: () => actions.onArrowClick('next') } : {})} >
            <ArrowForwardIos {...(hasMorePoints ? {} : { color: 'disabled' })} />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

PointCard.propTypes = {
  point: PropTypes.object,
  hasMorePoints: PropTypes.bool,
  actions: PropTypes.object,
  options: PropTypes.object,
  canModify: PropTypes.bool,
  csLang: PropTypes.object,
  campaignSubtypeId: PropTypes.string,
};


export { PointCard };
