import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, InputLabel,
  TextField, FormHelperText, DialogActions, FormControlLabel, Checkbox } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';

import { PhenologiesAndPhytosanitaryStatusesInputs } from 'src/scenes/Campaign/scenes/Records/components/FloraRecordInputs';
import { AlertWrapper, DialogButton } from 'src/components';
import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';


const columns = [
  { key: 'number', value: 'Cantidad', validate: data => 1 <= data && data <= 1000,
    errorMsg: 'Solo se permiten valores entre 1 y 1000.', format: data => Math.floor(data).valueOf().toString() },
  { key: 'height', value: 'Altura (m)', validate: data => 0 <= data && data <= 1000,
    errorMsg: 'Solo se permiten valores entre 0 y 1000.', format: data => Number(data).valueOf().toString() },
  { key: 'firstDiameter', value: 'Diámetro de copa 1 (m)', validate: data => 0 <= data && data <= 100,
    errorMsg: 'Solo se permiten valores entre 0 y 100.', format: data => Number(data).valueOf().toString() },
  { key: 'secondDiameter', value: 'Diámetro de copa 2 (m)', validate: data => 0 <= data && data <= 100,
    errorMsg: 'Solo se permiten valores entre 0 y 100.', format: data => Number(data).valueOf().toString() },
  { key: 'numberOfOffshoots', value: 'Vástagos', validate: data => 1 <= data && data <= 50,
    errorMsg: 'Solo se permiten valores entre 1 y 50.', format: data => Math.floor(data).valueOf().toString() },
  { key: 'dap', value: 'DAP (cm)', validate: data => 0 <= data && data <= 2000,
    errorMsg: 'Solo se permiten valores entre 0 y 2000.', format: data => Number(data).valueOf().toString() },
];

const ForestTreeEditDialog = ({ prevForestData, actions, options }) => {
  const [ forestData, setForestData ] = useState(prevForestData);
  // debido al tiempo que este campo no existió, hay cosas que lo tienen en null. Para esos dejaremos opcional añadirle valor o no
  const [ isDetectionDateEditable, setIsDetectionDateEditable ] = useState(Boolean(prevForestData.detectionDate));
  const [ fetchStatus, setFetchStatus ] = useState(fetchStatusEnum.NOT_STARTED);
  const [ generalErrorMsg, setGeneralErrorMsg ] = useState('');

  const handleIsDetectionDateEditableChange = useCallback(event =>
    setIsDetectionDateEditable(event.target.checked), [ setIsDetectionDateEditable ],
  );

  const { phenologiesStatusesOptions, phytosanitaryStatusesOptions } = options;

  const handleSelect = obj => setForestData({ ...forestData, ...obj });

  const updateData = async () => {
    if (fetchStatus === fetchStatusEnum.LOADING) {
      return;
    }
    try {
      setFetchStatus(fetchStatusEnum.LOADING);
      const dataToUpdate = { id: forestData.id, observation: forestData.observation,
        phenologiesStatuses: forestData.phenologiesStatuses, phytosanitaryStatuses: forestData.phytosanitaryStatuses,
        ...((!isDetectionDateEditable && !prevForestData.detectionDate) ? {} : { detectionDate: forestData.detectionDate }),
      };
      let validData = true;
      columns.forEach(({ key, validate, format }) => {
        validData = validData && validate(forestData[key]);
        dataToUpdate[key] = format(forestData[key]);
      });
      if (validData) {
        await actions.updateTree(dataToUpdate);
        setGeneralErrorMsg('');
        setFetchStatus(fetchStatusEnum.SUCCESS);
        setTimeout(actions.closeDialog, 300);
        return;
      }
      setGeneralErrorMsg('Hubo campos con datos incorrectos, por favor revisa el formulario e intenta de nuevo');
      setFetchStatus(fetchStatusEnum.ERROR);
    } catch (e) {
      setFetchStatus(fetchStatusEnum.ERROR);
      setGeneralErrorMsg(e.serverMessage || e.message || 'Hubo un error, por favor intenta más tarde');
    }
  };

  return (
    <Fragment>
      <DialogTitle>Editar agrupación de individuos forestales</DialogTitle>
      <DialogContent>
        <Box p={1}>
          {columns.map(({ key, value, validate, errorMsg, format }, i) =>
            <Box my={1} key={i}>
              <InputLabel shrink>{value}</InputLabel>
              <TextField value={format(forestData[key])} inputProps={{ min: 0 }} name={key} variant="outlined" size="small"
                onChange={e => setForestData({ ...forestData, [e.target.name]: e.target.value })} type="number" fullWidth
                autoComplete="off" error={!validate(forestData[key])} />
              {!validate(forestData[key]) && <FormHelperText error>{errorMsg}</FormHelperText>}
            </Box>)
          }
          <PhenologiesAndPhytosanitaryStatusesInputs
            record={forestData}
            handlers={{ handleSelect }}
            options={{ phenologiesStatusesOptions, phytosanitaryStatusesOptions }}
          />
          { !prevForestData.detectionDate &&
            <FormControlLabel
              label="¿Agregar fecha de detección?"
              control={
                <Checkbox
                  checked={isDetectionDateEditable}
                  onChange={handleIsDetectionDateEditableChange}
                  name="Agregar fecha de detección"
                  color="primary"
                />
              }
            />
          }
          { isDetectionDateEditable &&
            <Box my={2}>
              <InputLabel shrink>Fecha de detección</InputLabel>
              <Box display="flex" alignItems="center">
                <Box display="flex" flexDirection="column">
                  <DateTimePicker
                    value={ forestData.detectionDate }
                    ampm={ false }
                    TextFieldComponent={ props => <TextField {...props} variant="outlined" size="small" /> }
                    onChange={ detectionDate => handleSelect({ detectionDate: new Date(detectionDate) }) }
                    format={ forestData.detectionDate ? 'DD-MM-YYYY HH:mm' : ' - ' }
                  />
                </Box>
              </Box>
            </Box>
          }
          <Box my={1} mb={3}>
            <InputLabel shrink>Observaciones</InputLabel>
            <TextField value={forestData.observation} name="observation" maxRows="5" minRows="2"
              fullWidth autoComplete="off" variant="outlined" size="small" multiline
              onChange={e => setForestData({ ...forestData, [e.target.name]: e.target.value })}
            />
          </Box>
          <AlertWrapper severity="error" isOpen={fetchStatus === fetchStatusEnum.ERROR && generalErrorMsg} hideCloseButton>
            { generalErrorMsg }
          </AlertWrapper>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="default" onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={updateData} color='primary'>Editar</DialogButton>
      </DialogActions>
    </Fragment>);
};

ForestTreeEditDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  prevForestData: PropTypes.object.isRequired,
  options: PropTypes.object,
};


export { ForestTreeEditDialog };