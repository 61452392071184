import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography, Zoom } from '@material-ui/core';

import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';
import { AlertWrapper, DialogButton } from 'src/components';

const StartCensusCampaignDialog = ({ actions, fetchStatus, userIsFan, campaignHash, fetchErrorMessage }) => {
  const handleStartCampaign = () => actions.startCampaign({ campaignHash });

  const isLoading = fetchStatusEnum.LOADING === fetchStatus;
  const succeeded = fetchStatusEnum.SUCCESS === fetchStatus;
  const failed = fetchStatusEnum.ERROR === fetchStatus;

  return (
    <>
      <DialogTitle id="form-dialog-title">Comenzar campaña</DialogTitle>
      <DialogContent>
        <Box>
          {
            (fetchStatus === fetchStatusEnum.NOT_STARTED || isLoading) &&
            <Box p={1} height={ userIsFan ? 45 : 200 }>
              { !userIsFan && <>
                <Typography variant="body1" color="textPrimary">
                  Esta acción notificará al grupo de trabajo que la campaña ya está disponible para empezar a trabajar.
                </Typography>
                <br />
                <Typography variant="body1" color="textPrimary">
                  Recuerda, si quieres que más personas puedan ver y trabajar en esta campaña, podrás luego agregarlas
                  en la sección de <strong>Grupo de trabajo</strong>.
                </Typography>
                <br />
              </> }
              <Typography variant="body1" color="textPrimary">
                ¿Deseas dar comienzo a la campaña?
              </Typography>
            </Box>
          }
          {
            succeeded &&
            <Zoom in>
              <Box p={1}>
                <Typography variant="body1" color="textPrimary">
                  La planificación se ha completado correctamente{ !userIsFan ? ' y el grupo de trabajo fue notificado' : '' }, se te
                  redigirirá a la campaña en progreso.
                </Typography>
              </Box>
            </Zoom>
          }
          { failed && <Box mt={1}>
            <AlertWrapper severity="error" hideCloseButton>{fetchErrorMessage}.</AlertWrapper>
          </Box> }
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading || succeeded} onClick={actions.closeDialog} color="default">
          Cancelar
        </Button>
        <DialogButton disabled={failed} fetchStatus={fetchStatus} onClick={handleStartCampaign} color="primary">
          Confirmar
        </DialogButton>
      </DialogActions>
    </>
  );
};

StartCensusCampaignDialog.propTypes = {
  actions: PropTypes.object,
  campaignHash: PropTypes.string,
  fetchStatus: PropTypes.string,
  fetchErrorMessage: PropTypes.string,
  userIsFan: PropTypes.bool,
};


export {
  StartCensusCampaignDialog,
};