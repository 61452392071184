import React, { useEffect, useState } from 'react';
import { Grid, Box, Paper, Fab, Button, Typography, Zoom, CircularProgress, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch } from 'react-router';

import { TitleContainer, ContentContainer, Cards, Collapser, DialogWrap } from 'src/components';
import { monitorApi } from 'src/services';
import { CampaignSettingCard } from 'src/scenes/CompanySettings/components';
import { CheckCircle, RestorePage, Save } from '@material-ui/icons';
import { useFetchStatus } from 'src/hooks';
import { ProjectExportableDialog, ProjectNotExportableDialog } from 'src/scenes/CompanySettings/scenes/LinkedCampaigns/components';


const useStyles = makeStyles(theme => ({
  addButton: {
    color: theme.palette.common.white,
  },
  paper: {
    padding: theme.spacing(2),
    background: 'none',
    border: `1px solid ${theme.palette.common.gray400}`,
  },
  fabContainer: {
    right: '40px',
    bottom: '40px',
    position: 'fixed',
  },
  fab: {
    backgroundColor: theme.palette.common.gray800,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.black,
    },
  },
}));

const CompanyLinkedCampaignsContainer = () => {

  const match = useRouteMatch();
  const classes = useStyles();

  const { companyId } = match.params;

  const [ projects, setProjects ] = useState([]);
  const [ variant, setVariant ] = useState('condensed');
  const [ selectedCampaignsToAllowExport, setSelectedCampaignsToAllowExport ] = useState([]);
  const [ initialSelectedCampaigns, setInitialSelectedCampaigns ] = useState([]);
  const [ selectedProjectsToAllowExport, setSelectedProjectsToAllowExport ] = useState([]);

  const { fetchStatus, fetchStatusEnum, setFetchStatus, handleConfirm } = useFetchStatus({
    confirmFn: async () => {
      await monitorApi.updateCampaignsToAllowExport({ monitorCompanyId: companyId, campaignIds: selectedCampaignsToAllowExport });
      setInitialSelectedCampaigns(selectedCampaignsToAllowExport);
      setTimeout(() => setFetchStatus(NOT_STARTED), 10000);
    },
  });

  const { LOADING, SUCCESS, NOT_STARTED } = fetchStatusEnum;

  const toggleHasExportAllowed = campaignId => {
    const hasExportAllowed = selectedCampaignsToAllowExport.includes(campaignId);
    setSelectedCampaignsToAllowExport(prev => hasExportAllowed ? prev.filter(p => p !== campaignId) : [ ...prev, campaignId ]);
  };

  useEffect(() => {
    const fetch = async () => {
      const projects = await monitorApi.getCompanyCampaigns({ monitorCompanyId: companyId });
      setProjects(projects);
      setSelectedProjectsToAllowExport(projects.filter(p => p.shared).map(p => p.id));
      const initialSelectedCampaigns = projects.map(p => p.campaigns.filter(c => c.shared)).flat().map(c => c.id);
      setSelectedCampaignsToAllowExport(initialSelectedCampaigns);
      setInitialSelectedCampaigns(initialSelectedCampaigns);
    };
    fetch();
  }, []); // eslint-disable-line

  const onKeyDown = e => {
    if (e.metaKey && e.which === 76) {
      setVariant(prev => prev === 'condensed' ? 'grid' : 'condensed');
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  const toggleAllCampaignsFromProject = ({ projectId, isSelecting }) => {
    const project = projects.find(p => p.id === projectId);
    if (isSelecting) {
      const campaignsIds = [ ...new Set([ ...selectedCampaignsToAllowExport, ...project.campaigns.map(c => c.id) ]) ];
      setSelectedCampaignsToAllowExport(campaignsIds);
    } else {
      const campaignsIds = selectedCampaignsToAllowExport.filter(cId => project.campaigns.includes(cId));
      setSelectedCampaignsToAllowExport(campaignsIds);
    }
  };

  const undoSelection = () => {
    setSelectedCampaignsToAllowExport(initialSelectedCampaigns);
  };

  const handleSuccessSnack = () => {
    setFetchStatus(NOT_STARTED);
  };

  const [ dialog, setDialog ] = useState({ isOpen: false, type: '' });
  const closeDialog = () => setDialog({ isOpen: false, type: '' });
  const openDialog = (type, data = {}) => setDialog({ isOpen: true, type, data });

  const openProjectExportDialog = projectId => openDialog('exportable-project', projectId);
  const openProjectNotExportDialog = projectId => openDialog('not-exportable-project', projectId);

  const getDialogType = () => {
    switch (dialog.type) {
      case 'exportable-project':
        return <ProjectExportableDialog actions={{ projectExportable: () => handleProjectConfirm(dialog.data), closeDialog }} />;
      case 'not-exportable-project':
        return <ProjectNotExportableDialog actions={{ projectExportable: () => handleProjectConfirm(dialog.data), closeDialog }} />;
      default:
        break;
    }
  };

  const handleProjectConfirm = async projectId => {

    const isSelecting = selectedProjectsToAllowExport.includes(projectId);
    const newProjectsToAllowExport = isSelecting ?
      selectedProjectsToAllowExport.filter(sp => sp !== projectId)
      : [ projectId, ...selectedProjectsToAllowExport ];
    await monitorApi.updateProjectsToAllowExport({ monitorCompanyId: companyId, projectIds: newProjectsToAllowExport });
    setSelectedProjectsToAllowExport(newProjectsToAllowExport);
    setProjects(projects.map(p => p.id === projectId ? { ...p, shared: !isSelecting } : p));

  };

  return (
    <Grid container>
      <TitleContainer breadcrumbs={[ 'company', { name: 'Perfil' } ]}>Permisos de exportación</TitleContainer>
      <ContentContainer maxWidth="lg">
        <Box pb={1}>
          {projects.map((project, index) =>
            <Box key={index} mt={4}>
              <Collapser
                title={project.name}
                isExpanded={index === 0}
                Actions={() => {
                  const isSelecting = !project.campaigns.some(c => selectedCampaignsToAllowExport.includes(c.id));
                  return (
                    <Box display="flex">
                      <Button color="secondary" variant="contained" size="small"
                        onClick={() => toggleAllCampaignsFromProject({ projectId: project.id, isSelecting })}>
                        {isSelecting ? 'Marcar todas' : 'Desmarcar todas'}
                      </Button>
                    </Box>
                  );
                }}
                Title={() =>
                  <Box display="flex">
                    <Typography variant="h6">{project.name}</Typography>
                    <Box ml={1}>
                      {project.shared ?
                        <Button size="small" variant="outlined" startIcon={<CheckCircle />} color="primary"
                          onClick={() => openProjectNotExportDialog(project.id)}>
                          Exportación permitida
                        </Button>
                        :
                        <Button size="small" variant="outlined" color="primary" onClick={() => openProjectExportDialog(project.id)}>
                          Permitir exportar proyecto
                        </Button>
                      }
                    </Box>
                  </Box>
                }
                disableExpand={project.shared}
              >
                <Paper className={classes.paper} elevation={0}>
                  <Cards
                    values={project.campaigns}
                    variant={variant}
                    Content={({ itemData }) =>
                      <CampaignSettingCard
                        campaign={itemData}
                        actions={{ toggleHasExportAllowed }}
                        hasExportAllowed={selectedCampaignsToAllowExport.includes(itemData.id)}
                        variant={variant}
                      />
                    }
                  />
                </Paper>
              </Collapser>
            </Box>,
          )}
        </Box>
        <Box className={classes.fabContainer} display="flex">
          <Zoom in={initialSelectedCampaigns.length !== selectedCampaignsToAllowExport.length}>
            <Fab className={classes.fab} size="medium" variant="extended" color="default" onClick={undoSelection}>
              <RestorePage />
              <Box ml={1}>Deshacer cambios</Box>
            </Fab>
          </Zoom>
          <Box mx={1} />
          <Fab size="medium" variant="extended" color="secondary" onClick={handleConfirm}
            disabled={initialSelectedCampaigns.length === selectedCampaignsToAllowExport.length}>
            {fetchStatus === LOADING ? <CircularProgress size={25} thickness={4.8} /> : <Save />}
            <Box ml={1}>Permitir exportar campañas seleccionadas</Box>
          </Fab>
        </Box>
      </ContentContainer>
      <Snackbar
        open={fetchStatus === SUCCESS}
        onClose={handleSuccessSnack}
        message={`Se ha permitido correctamente la exportación de ${selectedCampaignsToAllowExport.length} campañas`}
        key={'allow-success'}
      />
      <DialogWrap maxWidth='sm' fullWidth onClose={closeDialog} aria-labelledby='form-dialog-title' open={dialog.isOpen}>
        {getDialogType()}
      </DialogWrap>
    </Grid>
  );
};


export { CompanyLinkedCampaignsContainer };