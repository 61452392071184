import { get, post, del } from 'src/services/api';


const getPoints = async queryParams => (await get('point', queryParams)).data;

const getPointsForMonitoringMap = async queryParams => (await get('point/for-monitoring-map', queryParams)).data;

const createPoint = async newPoint => (await post('point', newPoint)).data;

const updatePointsPlanning = async pointsData => (await post('point/update-many-planning', pointsData)).data;

const deletePoints = async (ids, campaignHash) => (await del('point', { ids, campaignHash })).data;

const createPointsFromKml = async (kmlForm, campaignHash) =>
  (await post(`point/import-kml/${campaignHash}`, kmlForm, { headers: { 'Content-Type': 'multipart/form-data' } })).data;

const search = async queryParams => (await get('point/search', queryParams)).data;

const getPointStatuses = async campaignSubtypeId => (await get('point/completion-statuses', { campaignSubtypeId })).data;

const updatePoints = async pointsData => (await post('point/update-many', pointsData)).data;


export {
  getPoints,
  createPoint,
  updatePointsPlanning,
  deletePoints,
  createPointsFromKml,
  search,
  getPointStatuses,
  updatePoints,
  getPointsForMonitoringMap,
};
