import React from 'react';
import L from 'leaflet';
import ReactDOM from 'react-dom';

import { getMarkerIcon } from 'src/components/map/mapHelper';
import { campaignSubtypeLanguage } from 'src/utils/componentLanguage';
import { MonitoringMapLegend, PointPopup } from 'src/scenes/Campaign/scenes/Monitoring/components';
import { ThemeProvider } from '@material-ui/core';
import themes from 'src/themes';


const render = (Component, element) => {
  ReactDOM.render(
    <ThemeProvider theme={themes['nviro']}>
      <Component />
    </ThemeProvider>,
    element,
  );
};

const mapOptions = {
  center: [ -32.48431324745671, -71.25596320202341 ],
  zoomControl: true,
  dragging: false,
  scrollWheelZoom: false,
  zoom: 16,
  doubleClickZoom: false,
  attributionControl: false,
};

const interestAreaPolygons = [
  {
    'id': '165',
    'name': 'Zona A',
    'geojson': [
      {
        'type': 'Feature',
        'geometry': {
          'type': 'Polygon',
          'coordinates': [
            [
              [
                -71.25176320202341,
                -32.48651324745671,
                0,
              ],
              [
                -71.2516649403473,
                -32.48256943224506,
                0,
              ],
              [
                -71.25339723966573,
                -32.48181727993751,
                0,
              ],
              [
                -71.2543880862421,
                -32.48169888577371,
                0,
              ],
              [
                -71.25498025521912,
                -32.48646120676445,
                0,
              ],
              [
                -71.25495424435044,
                -32.48646162793209,
                0,
              ],
              [
                -71.25495617859191,
                -32.48647838052944,
                0,
              ],
              [
                -71.25495810245584,
                -32.48648708462187,
                0,
              ],
              [
                -71.25496167663627,
                -32.48649540652226,
                0,
              ],
              [
                -71.254966804069,
                -32.48650312341866,
                0,
              ],
              [
                -71.25497334867634,
                -32.48651002672563,
                0,
              ],
              [
                -71.25498113457351,
                -32.48651593292024,
                0,
              ],
              [
                -71.25498995244976,
                -32.48652068342785,
                0,
              ],
              [
                -71.25499956717074,
                -32.48652415079832,
                0,
              ],
              [
                -71.25500972105881,
                -32.48652624225468,
                0,
              ],
              [
                -71.25502014031959,
                -32.48652690138146,
                0,
              ],
              [
                -71.25503054787161,
                -32.48652611060024,
                0,
              ],
              [
                -71.25819119283946,
                -32.48606255545709,
                0,
              ],
              [
                -71.25819839425169,
                -32.48610267501272,
                0,
              ],
              [
                -71.25503329644752,
                -32.48656688284468,
                0,
              ],
              [
                -71.2550158636269,
                -32.48656820086825,
                0,
              ],
              [
                -71.25499841141587,
                -32.48656707892809,
                0,
              ],
              [
                -71.25498141296943,
                -32.48656354641879,
                0,
              ],
              [
                -71.25496532819496,
                -32.48655769880497,
                0,
              ],
              [
                -71.25495059200823,
                -32.48654969602837,
                0,
              ],
              [
                -71.2549376045141,
                -32.4865397527637,
                0,
              ],
              [
                -71.25492671507581,
                -32.48652813960592,
                0,
              ],
              [
                -71.25491822091409,
                -32.48651516956836,
                0,
              ],
              [
                -71.2549123500232,
                -32.48650119568303,
                0,
              ],
              [
                -71.25490926180719,
                -32.48648659385488,
                0,
              ],
              [
                -71.25490654179592,
                -32.48646240004432,
                0,
              ],
              [
                -71.25176320202341,
                -32.48651324745671,
                0,
              ],
            ],
          ],
        },
        'properties': {
          'styleUrl': '#falseColor',
          'styleHash': '-6cc8e1c',
          'OBJECTID': '1',
          'Shape_Leng': '2182.51',
          'Shape_Area': '140711',
        },
      },
      {
        'type': 'Feature',
        'geometry': {
          'type': 'LineString',
          'coordinates': [
            [
              -71.25497367363214,
              -32.48647137850673,
              0,
            ],
            [
              -71.25820206079018,
              -32.48632663468526,
              0,
            ],
            [
              -71.25787891927807,
              -32.48173334604681,
              0,
            ],
            [
              -71.2581625692761,
              -32.4817044500048,
              0,
            ],
          ],
        },
        'properties': {
          'name': 'Línea de evacuación proyectada',
          'styleUrl': '#m_ylw-pushpin1',
          'styleHash': '-4a3628b2',
          'styleMapHash': {
            'normal': '#s_ylw-pushpin2',
            'highlight': '#s_ylw-pushpin_hl2',
          },
          'stroke': '#0000ff',
          'stroke-opacity': 1,
          'stroke-width': 5,
        },
      },
    ],
  },
];

const interestAreaLayers = interestAreaPolygons.map(layer => L.geoJSON(layer.geojson, {
  id: layer.id,
  name: layer.name,
}));

const individualRecords = [
  {
    'latitude': -32.4845882,
    'longitude': -71.2532542,
    'accuracy': 4.9,
    'email': 'bastian.gomez@nviro.cl',
    'byDarwinion': true,
    'species': {
      'genus': 'peumus',
      'family': 'monimiaceae',
      'species': 'boldus',
      'variety': '',
      'subspecies': '',
    },
  },
  {
    'latitude': -32.4827503,
    'longitude': -71.2520201,
    'accuracy': 7.3,
    'email': 'bastian.gomez@nviro.cl',
    'byDarwinion': true,
    'species': {
      'genus': 'schinus',
      'family': 'anacardiaceae',
      'species': 'latifolius',
      'variety': '',
      'subspecies': '',
    },
  },
  {
    'observation': '',
    'latitude': -32.4829894,
    'longitude': -71.2520282,
    'accuracy': 3.8,
    'email': 'bastian.gomez@nviro.cl',
    'byDarwinion': true,
    'species': {
      'genus': 'vachellia',
      'family': 'fabaceae',
      'species': 'caven',
      'variety': '',
      'subspecies': '',
    },
  },
  {
    'observation': '',
    'latitude': -32.4827712,
    'longitude': -71.254476,
    'accuracy': 3.9,
    'email': 'bastian.gomez@nviro.cl',
    'byDarwinion': true,
    'species': {
      'genus': 'schinus',
      'family': 'anacardiaceae',
      'species': 'latifolius',
      'variety': '',
      'subspecies': '',
    },
  },
  {
    'observation': '',
    'createdAt': '2021-06-18T13:57:58.937Z',
    'latitude': -32.4834556,
    'longitude': -71.2520597,
    'accuracy': 4.2,
    'email': 'bastian.gomez@nviro.cl',
    'byDarwinion': true,
    'species': {
      'genus': 'maytenus',
      'family': 'celastraceae',
      'species': 'boaria',
      'variety': '',
      'subspecies': '',
    },
  },
  {
    'observation': '',
    'latitude': -32.4839217,
    'longitude': -71.2512575,
    'accuracy': 5,
    'email': 'bastian.gomez@nviro.cl',
    'byDarwinion': true,
    'species': {
      'genus': 'cestrum',
      'family': 'solanaceae',
      'species': 'parqui',
      'variety': '',
      'subspecies': '',
    },
  },
  {
    'observation': '',
    'latitude': -32.4848234,
    'longitude': -71.2580628,
    'accuracy': 4,
    'email': 'eduardo.blanco@nviro.cl',
    'byDarwinion': false,
    'species': {
      'genus': 'acacia',
      'family': '',
      'species': 'melanoxylon',
      'variety': '',
      'subspecies': '',
    },
  },
  {
    'observation': '',
    'latitude': -32.4847414,
    'longitude': -71.2580284,
    'accuracy': 4.4,
    'email': 'eduardo.blanco@nviro.cl',
    'byDarwinion': false,
    'species': {
      'genus': 'acacia',
      'family': '',
      'species': 'melanoxylon',
      'variety': '',
      'subspecies': '',
    },
  },
  {
    'observation': '',
    'latitude': -32.4822838,
    'longitude': -71.2579084,
    'accuracy': 4,
    'email': 'eduardo.blanco@nviro.cl',
    'byDarwinion': false,
    'species': {
      'genus': 'acacia',
      'family': '',
      'species': 'melanoxylon',
      'variety': '',
      'subspecies': '',
    },
  },
  {
    'observation': '',
    'latitude': -32.4817602,
    'longitude': -71.2580619,
    'accuracy': 3.7,
    'email': 'eduardo.blanco@nviro.cl',
    'byDarwinion': false,
    'species': {
      'genus': 'punto',
      'family': '',
      'species': 'de',
      'variety': '',
      'subspecies': 'conexión',
    },
  },
];

const irMarkers = individualRecords.map(ir => {
  const coords = { lat: ir.latitude, lng: ir.longitude };
  const marker = new L.Marker(coords, { icon: getMarkerIcon('RI', 'nviro-flora') });
  return marker;
});

const track = [
  {
    'userId': '5',
    'email': 'ernesto.ceballos@nviro.cl',
    'firstName': 'Ernesto',
    'lastName': 'Ceballos',
    'trackLine': {
      'type': 'LineString',
      'coordinates': [
        [
          -71.25520744,
          -32.48597095,
        ],
        [
          -71.25505603,
          -32.48602653,
        ],
        [
          -71.25382813,
          -32.48214917,
        ],
        [
          -71.25395574,
          -32.48198605,
        ],
        [
          -71.2539496,
          -32.48211967,
        ],
        [
          -71.25381819,
          -32.4822801,
        ],
        [
          -71.2530067,
          -32.48243112,
        ],
        [
          -71.25296836,
          -32.482535,
        ],
        [
          -71.25201065,
          -32.48287026,
        ],
        [
          -71.25119889,
          -32.48337809,
        ],
        [
          -71.25222487,
          -32.48319916,
        ],
        [
          -71.25268425,
          -32.48302679,
        ],
        [
          -71.25285416,
          -32.4829953,
        ],
        [
          -71.25336866,
          -32.48270592,
        ],
        [
          -71.25336497,
          -32.48268611,
        ],
        [
          -71.25336331,
          -32.48268523,
        ],
        [
          -71.25422968,
          -32.48301756,
        ],
        [
          -71.25445965,
          -32.48280686,
        ],
        [
          -71.25445772,
          -32.48280153,
        ],
        [
          -71.25352874,
          -32.48327296,
        ],
        [
          -71.25334575,
          -32.48331232,
        ],
        [
          -71.25288053,
          -32.48345707,
        ],
        [
          -71.25271693,
          -32.48350914,
        ],
        [
          -71.25238859,
          -32.48354323,
        ],
        [
          -71.2522085,
          -32.4836671,
        ],
        [
          -71.25204103,
          -32.48356854,
        ],
        [
          -71.25188686,
          -32.48367142,
        ],
        [
          -71.25175611,
          -32.48439653,
        ],
        [
          -71.25193588,
          -32.48453633,
        ],
        [
          -71.25211231,
          -32.48464967,
        ],
        [
          -71.25279316,
          -32.48399919,
        ],
        [
          -71.25279832,
          -32.48402229,
        ],
        [
          -71.25298808,
          -32.48401319,
        ],
        [
          -71.25319827,
          -32.48423098,
        ],
        [
          -71.25335682,
          -32.48426443,
        ],
        [
          -71.25366898,
          -32.4837778,
        ],
        [
          -71.25371526,
          -32.48391367,
        ],
        [
          -71.25379065,
          -32.48443917,
        ],
        [
          -71.2539684,
          -32.48434299,
        ],
        [
          -71.2541935,
          -32.48426756,
        ],
        [
          -71.25439387,
          -32.4842786,
        ],
        [
          -71.25459534,
          -32.48428452,
        ],
        [
          -71.25455001,
          -32.48422029,
        ],
        [
          -71.25456157,
          -32.48421832,
        ],
        [
          -71.25444983,
          -32.48472887,
        ],
        [
          -71.25278399,
          -32.48494156,
        ],
        [
          -71.25259339,
          -32.48521656,
        ],
        [
          -71.25254617,
          -32.48521586,
        ],
        [
          -71.25256316,
          -32.48524761,
        ],
        [
          -71.25266242,
          -32.48538443,
        ],
        [
          -71.25260197,
          -32.48560292,
        ],
        [
          -71.25233226,
          -32.48574983,
        ],
        [
          -71.25207932,
          -32.48584172,
        ],
        [
          -71.25194615,
          -32.48592573,
        ],
        [
          -71.25211799,
          -32.48593548,
        ],
        [
          -71.25219582,
          -32.4862304,
        ],
        [
          -71.25233848,
          -32.48611025,
        ],
        [
          -71.25256567,
          -32.48606423,
        ],
        [
          -71.25237004,
          -32.48608525,
        ],
        [
          -71.25251525,
          -32.48601202,
        ],
        [
          -71.25284047,
          -32.48594244,
        ],
        [
          -71.25306515,
          -32.48593188,
        ],
        [
          -71.25324234,
          -32.48609913,
        ],
        [
          -71.25315385,
          -32.48598007,
        ],
        [
          -71.25315316,
          -32.48592283,
        ],
        [
          -71.25317583,
          -32.4859141,
        ],
        [
          -71.25317541,
          -32.48591466,
        ],
        [
          -71.25317204,
          -32.48592271,
        ],
        [
          -71.25317423,
          -32.48591532,
        ],
        [
          -71.25328509,
          -32.48581528,
        ],
        [
          -71.25434108,
          -32.48573479,
        ],
        [
          -71.25408313,
          -32.4861154,
        ],
        [
          -71.25335303,
          -32.48615507,
        ],
        [
          -71.25305256,
          -32.48624178,
        ],
        [
          -71.25265218,
          -32.48616761,
        ],
        [
          -71.25264284,
          -32.48631561,
        ],
        [
          -71.2570568,
          -32.48588638,
        ],
        [
          -71.25727231,
          -32.48588728,
        ],
        [
          -71.25780257,
          -32.48557432,
        ],
        [
          -71.25784395,
          -32.48571151,
        ],
        [
          -71.25791148,
          -32.48601895,
        ],
        [
          -71.2578446,
          -32.48227525,
        ],
        [
          -71.25789922,
          -32.48173218,
        ],
        [
          -71.25788,
          -32.4815286,
        ],
        [
          -71.25802168,
          -32.48114894,
        ],
        [
          -71.25812193,
          -32.4818318,
        ],
      ],
    },
  },
  {
    'userId': '4',
    'email': 'bastian.gomez@nviro.cl',
    'firstName': 'Bastian',
    'lastName': 'Gomez',
    'trackLine': {
      'type': 'LineString',
      'coordinates': [
        [
          -71.255085121,
          -32.485982697,
        ],
        [
          -71.255192919,
          -32.48596677,
        ],
        [
          -71.254599399,
          -32.486176491,
        ],
        [
          -71.254534302,
          -32.486036795,
        ],
        [
          -71.253486819,
          -32.48623399,
        ],
        [
          -71.253139641,
          -32.486021561,
        ],
        [
          -71.252835749,
          -32.485433498,
        ],
        [
          -71.252900347,
          -32.485282428,
        ],
        [
          -71.253299945,
          -32.484631345,
        ],
        [
          -71.253511485,
          -32.484356118,
        ],
        [
          -71.2538996,
          -32.484270445,
        ],
        [
          -71.253902288,
          -32.484028006,
        ],
        [
          -71.253871683,
          -32.483812495,
        ],
        [
          -71.253424669,
          -32.484325821,
        ],
        [
          -71.253721578,
          -32.48341536,
        ],
        [
          -71.253790123,
          -32.483144567,
        ],
        [
          -71.254021174,
          -32.482878437,
        ],
        [
          -71.254187967,
          -32.482677139,
        ],
        [
          -71.251990816,
          -32.482746551,
        ],
        [
          -71.252036681,
          -32.483011152,
        ],
        [
          -71.252023551,
          -32.482992247,
        ],
        [
          -71.252196422,
          -32.48320482,
        ],
        [
          -71.252206781,
          -32.483133102,
        ],
        [
          -71.252239211,
          -32.483205124,
        ],
        [
          -71.252171685,
          -32.483232092,
        ],
        [
          -71.252334986,
          -32.48314603,
        ],
        [
          -71.252498073,
          -32.483044813,
        ],
        [
          -71.252666623,
          -32.482973274,
        ],
        [
          -71.252743006,
          -32.483098638,
        ],
        [
          -71.252893355,
          -32.483052412,
        ],
        [
          -71.2530911,
          -32.482987312,
        ],
        [
          -71.25317469,
          -32.482864052,
        ],
        [
          -71.253335012,
          -32.482734871,
        ],
        [
          -71.253316271,
          -32.482593554,
        ],
        [
          -71.253467595,
          -32.482718471,
        ],
        [
          -71.253653343,
          -32.482778658,
        ],
        [
          -71.253850166,
          -32.48284809,
        ],
        [
          -71.254047575,
          -32.482866137,
        ],
        [
          -71.254212464,
          -32.482913943,
        ],
        [
          -71.254258872,
          -32.483043519,
        ],
        [
          -71.254351193,
          -32.482924288,
        ],
        [
          -71.254445175,
          -32.48279015,
        ],
        [
          -71.254435494,
          -32.482803363,
        ],
        [
          -71.254271562,
          -32.482842643,
        ],
        [
          -71.254128622,
          -32.482915429,
        ],
        [
          -71.253924323,
          -32.482959652,
        ],
        [
          -71.253770537,
          -32.483178178,
        ],
        [
          -71.253532563,
          -32.483233543,
        ],
        [
          -71.25349922,
          -32.483367307,
        ],
        [
          -71.253364309,
          -32.483293642,
        ],
        [
          -71.253175554,
          -32.483317149,
        ],
        [
          -71.252989436,
          -32.483268781,
        ],
        [
          -71.252843281,
          -32.483361005,
        ],
        [
          -71.252799785,
          -32.483493609,
        ],
        [
          -71.25267555,
          -32.483594988,
        ],
        [
          -71.252421977,
          -32.483557625,
        ],
        [
          -71.25225796,
          -32.483621418,
        ],
        [
          -71.252099948,
          -32.483588214,
        ],
        [
          -71.252101143,
          -32.483468163,
        ],
        [
          -71.251930007,
          -32.483578802,
        ],
        [
          -71.251690577,
          -32.483772137,
        ],
        [
          -71.251475279,
          -32.483790076,
        ],
        [
          -71.251379229,
          -32.483902153,
        ],
        [
          -71.251214946,
          -32.483939636,
        ],
        [
          -71.250947344,
          -32.484071532,
        ],
        [
          -71.25084398,
          -32.483868126,
        ],
        [
          -71.250976918,
          -32.483881555,
        ],
        [
          -71.251034594,
          -32.484034306,
        ],
        [
          -71.251176924,
          -32.484148212,
        ],
        [
          -71.251326289,
          -32.484282947,
        ],
        [
          -71.251485527,
          -32.48447615,
        ],
        [
          -71.251672409,
          -32.484623043,
        ],
        [
          -71.251901561,
          -32.484661384,
        ],
        [
          -71.252073794,
          -32.484619337,
        ],
        [
          -71.252162546,
          -32.484504755,
        ],
        [
          -71.252021337,
          -32.484348379,
        ],
        [
          -71.252709856,
          -32.484085853,
        ],
        [
          -71.253033548,
          -32.484258562,
        ],
        [
          -71.253190847,
          -32.484294591,
        ],
        [
          -71.253421252,
          -32.484254836,
        ],
        [
          -71.25347501,
          -32.484052437,
        ],
        [
          -71.253607334,
          -32.48381496,
        ],
        [
          -71.253771578,
          -32.483798406,
        ],
        [
          -71.2538234,
          -32.483931723,
        ],
        [
          -71.253834674,
          -32.484086476,
        ],
        [
          -71.253735097,
          -32.484209056,
        ],
        [
          -71.253757213,
          -32.484351466,
        ],
        [
          -71.253822794,
          -32.484477502,
        ],
        [
          -71.253956445,
          -32.484384905,
        ],
        [
          -71.254085413,
          -32.484292921,
        ],
        [
          -71.254221915,
          -32.484364976,
        ],
        [
          -71.254521555,
          -32.484211165,
        ],
        [
          -71.254475745,
          -32.484341489,
        ],
        [
          -71.254411938,
          -32.484471387,
        ],
        [
          -71.254430814,
          -32.484698362,
        ],
        [
          -71.254438837,
          -32.484833979,
        ],
        [
          -71.254405194,
          -32.484916263,
        ],
        [
          -71.254564705,
          -32.484857346,
        ],
        [
          -71.254471883,
          -32.484969709,
        ],
        [
          -71.254482707,
          -32.485106617,
        ],
        [
          -71.254677237,
          -32.485078946,
        ],
        [
          -71.254601779,
          -32.485242126,
        ],
        [
          -71.254435931,
          -32.485221991,
        ],
        [
          -71.254211675,
          -32.48520156,
        ],
        [
          -71.254015501,
          -32.485246091,
        ],
        [
          -71.253773885,
          -32.485240334,
        ],
        [
          -71.253601286,
          -32.485227737,
        ],
        [
          -71.253444997,
          -32.485166802,
        ],
        [
          -71.25323819,
          -32.485062152,
        ],
        [
          -71.253031019,
          -32.485070763,
        ],
        [
          -71.25285505,
          -32.485036072,
        ],
        [
          -71.252697135,
          -32.485000278,
        ],
        [
          -71.252692846,
          -32.485139669,
        ],
        [
          -71.252622236,
          -32.485311969,
        ],
        [
          -71.252661596,
          -32.485443989,
        ],
        [
          -71.25262191,
          -32.485604576,
        ],
        [
          -71.252404296,
          -32.485730635,
        ],
        [
          -71.252196263,
          -32.485822329,
        ],
        [
          -71.252022295,
          -32.485878596,
        ],
        [
          -71.252114445,
          -32.486000234,
        ],
        [
          -71.252173225,
          -32.486182253,
        ],
        [
          -71.252327735,
          -32.486134901,
        ],
        [
          -71.252496258,
          -32.486142871,
        ],
        [
          -71.252714404,
          -32.48600087,
        ],
        [
          -71.252920778,
          -32.485982163,
        ],
        [
          -71.253121181,
          -32.486010019,
        ],
        [
          -71.253230282,
          -32.485865884,
        ],
        [
          -71.253252242,
          -32.486032779,
        ],
        [
          -71.25323977,
          -32.485775698,
        ],
        [
          -71.253172099,
          -32.485843909,
        ],
        [
          -71.253181759,
          -32.485925951,
        ],
        [
          -71.253181759,
          -32.485925951,
        ],
        [
          -71.253272175,
          -32.485813765,
        ],
        [
          -71.253517921,
          -32.485756165,
        ],
        [
          -71.253758906,
          -32.485641014,
        ],
        [
          -71.253903826,
          -32.485703721,
        ],
        [
          -71.254056086,
          -32.485745504,
        ],
        [
          -71.254228436,
          -32.485750818,
        ],
        [
          -71.254431701,
          -32.485765939,
        ],
        [
          -71.254482152,
          -32.485894506,
        ],
        [
          -71.254356409,
          -32.486083282,
        ],
        [
          -71.254170218,
          -32.486174247,
        ],
        [
          -71.254120959,
          -32.486159083,
        ],
        [
          -71.256364203,
          -32.486334826,
        ],
        [
          -71.256092528,
          -32.4859698,
        ],
        [
          -71.25641474,
          -32.486356049,
        ],
        [
          -71.256528253,
          -32.486588924,
        ],
        [
          -71.256265629,
          -32.486220493,
        ],
        [
          -71.262862671,
          -32.476171344,
        ],
      ],
    },
  },
  {
    'userId': '9',
    'email': 'eduardo.blanco@nviro.cl',
    'firstName': 'Eduardo',
    'lastName': 'Blanco',
    'trackLine': {
      'type': 'LineString',
      'coordinates': [
        [
          -71.253292725,
          -32.486186513,
        ],
        [
          -71.253139572,
          -32.485997098,
        ],
        [
          -71.252958888,
          -32.485846941,
        ],
        [
          -71.252816261,
          -32.485682683,
        ],
        [
          -71.252820897,
          -32.48544275,
        ],
        [
          -71.252920406,
          -32.485291911,
        ],
        [
          -71.253117719,
          -32.48524792,
        ],
        [
          -71.253294346,
          -32.485135226,
        ],
        [
          -71.253351917,
          -32.485004968,
        ],
        [
          -71.253318854,
          -32.484838946,
        ],
        [
          -71.253222016,
          -32.484667076,
        ],
        [
          -71.25333743,
          -32.484476687,
        ],
        [
          -71.25362154,
          -32.484269974,
        ],
        [
          -71.25390657,
          -32.484247658,
        ],
        [
          -71.254032112,
          -32.484104289,
        ],
        [
          -71.254009346,
          -32.483928254,
        ],
        [
          -71.253933109,
          -32.483755131,
        ],
        [
          -71.253880192,
          -32.483611215,
        ],
        [
          -71.253786893,
          -32.483493131,
        ],
        [
          -71.253770295,
          -32.48331823,
        ],
        [
          -71.253854081,
          -32.483137046,
        ],
        [
          -71.253905918,
          -32.482973089,
        ],
        [
          -71.254059728,
          -32.48286887,
        ],
        [
          -71.254169127,
          -32.482677069,
        ],
        [
          -71.254192775,
          -32.482492642,
        ],
        [
          -71.25409792,
          -32.482358471,
        ],
        [
          -71.253972539,
          -32.482249875,
        ],
        [
          -71.253868718,
          -32.48214715,
        ],
        [
          -71.252961156,
          -32.482463189,
        ],
        [
          -71.25281217,
          -32.482600183,
        ],
        [
          -71.252664114,
          -32.482695569,
        ],
        [
          -71.252501853,
          -32.482711343,
        ],
        [
          -71.252315802,
          -32.482738971,
        ],
        [
          -71.252204316,
          -32.482602946,
        ],
        [
          -71.252067394,
          -32.482679192,
        ],
        [
          -71.251976711,
          -32.482800994,
        ],
        [
          -71.251941399,
          -32.482933814,
        ],
        [
          -71.251209554,
          -32.483339288,
        ],
        [
          -71.251257244,
          -32.483166898,
        ],
        [
          -71.251298061,
          -32.483132436,
        ],
        [
          -71.25154472,
          -32.483205545,
        ],
        [
          -71.251753052,
          -32.483203099,
        ],
        [
          -71.251975363,
          -32.48322739,
        ],
        [
          -71.252194472,
          -32.483205584,
        ],
        [
          -71.253504927,
          -32.483295378,
        ],
        [
          -71.25278938,
          -32.484020915,
        ],
        [
          -71.253189061,
          -32.485922135,
        ],
        [
          -71.254104967,
          -32.486155567,
        ],
        [
          -71.253725336,
          -32.486099854,
        ],
        [
          -71.253631615,
          -32.486252056,
        ],
        [
          -71.253335901,
          -32.486191919,
        ],
        [
          -71.253279268,
          -32.486228947,
        ],
        [
          -71.252813342,
          -32.486266134,
        ],
        [
          -71.252701352,
          -32.486381703,
        ],
        [
          -71.252660592,
          -32.486247643,
        ],
        [
          -71.252699948,
          -32.486379647,
        ],
        [
          -71.252666832,
          -32.486559238,
        ],
        [
          -71.252835769,
          -32.486561015,
        ],
        [
          -71.252906241,
          -32.486669025,
        ],
        [
          -71.252744747,
          -32.486716864,
        ],
        [
          -71.252643554,
          -32.486544502,
        ],
        [
          -71.252560909,
          -32.486427251,
        ],
        [
          -71.252508454,
          -32.486280782,
        ],
        [
          -71.252867586,
          -32.486238407,
        ],
        [
          -71.254231284,
          -32.486132585,
        ],
        [
          -71.255234345,
          -32.486049257,
        ],
        [
          -71.255709247,
          -32.486039064,
        ],
        [
          -71.256015788,
          -32.485944694,
        ],
        [
          -71.25631528,
          -32.48583571,
        ],
        [
          -71.257817256,
          -32.485752298,
        ],
        [
          -71.257794676,
          -32.485930885,
        ],
        [
          -71.257796297,
          -32.486124765,
        ],
        [
          -71.257632032,
          -32.486108735,
        ],
        [
          -71.257452046,
          -32.486164844,
        ],
        [
          -71.257256967,
          -32.486167547,
        ],
        [
          -71.257086371,
          -32.486210585,
        ],
        [
          -71.256374412,
          -32.486323412,
        ],
        [
          -71.255642609,
          -32.486154215,
        ],
        [
          -71.256395367,
          -32.48631717,
        ],
        [
          -71.256232888,
          -32.486314591,
        ],
        [
          -71.256075195,
          -32.486347435,
        ],
        [
          -71.255910424,
          -32.486344803,
        ],
        [
          -71.255729172,
          -32.486368359,
        ],
        [
          -71.255549644,
          -32.486421568,
        ],
        [
          -71.255888347,
          -32.486339723,
        ],
        [
          -71.255447762,
          -32.486506708,
        ],
        [
          -71.255438287,
          -32.486369565,
        ],
        [
          -71.255442929,
          -32.486185183,
        ],
        [
          -71.255495842,
          -32.486035952,
        ],
        [
          -71.257857208,
          -32.486166169,
        ],
        [
          -71.258057086,
          -32.486117058,
        ],
        [
          -71.257964157,
          -32.485884207,
        ],
        [
          -71.258231399,
          -32.486012995,
        ],
        [
          -71.257835542,
          -32.486122441,
        ],
        [
          -71.257929706,
          -32.485885177,
        ],
        [
          -71.258077565,
          -32.48606867,
        ],
        [
          -71.258116975,
          -32.485545628,
        ],
        [
          -71.258077559,
          -32.485402955,
        ],
        [
          -71.25803296,
          -32.484870753,
        ],
        [
          -71.257989149,
          -32.484723006,
        ],
        [
          -71.258033888,
          -32.484557754,
        ],
        [
          -71.258285769,
          -32.484185975,
        ],
      ],
    },
  },
];

const trackLines = track.filter(t => t.trackLine).map(({ trackLine, ...track }) => {
  const color = `#${Math.random().toString(16).substr(-6)}`;
  const line = L.geoJSON(trackLine, { style: { color } });
  line.data = { color, ...track };
  return line;
});

const plots = [
  {
    'name': '41',
    'statusId': 'todo',
    'observation': '',
    'dateVisited': null,
    'latitude': null,
    'longitude': null,
    'accuracy': null,
    'exposureId': null,
    'plannedLatitude': -32.48522769534328,
    'plannedLongitude': -71.2523138523102,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:11.543Z',
    'createdOnTerrain': false,
    'email': 'bastian.gomez@nviro.cl',
    'firstName': 'Bastian',
    'lastName': 'Gomez',
  },
  {
    'name': '43',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T14:52:36.301Z',
    'latitude': -32.4850542,
    'longitude': -71.2528644,
    'accuracy': 3.7,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.485010612126885,
    'plannedLongitude': -71.25282883644105,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:13.045Z',
    'createdOnTerrain': false,
    'email': 'bastian.gomez@nviro.cl',
    'firstName': 'Bastian',
    'lastName': 'Gomez',
  },
  {
    'name': '44',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T14:56:58.177Z',
    'latitude': -32.4852712,
    'longitude': -71.2526409,
    'accuracy': 3.8,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.48528196606554,
    'plannedLongitude': -71.25261425971986,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:14.081Z',
    'createdOnTerrain': false,
    'email': 'bastian.gomez@nviro.cl',
    'firstName': 'Bastian',
    'lastName': 'Gomez',
  },
  {
    'name': '51',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T15:04:41.191Z',
    'latitude': -32.4861185,
    'longitude': -71.2524125,
    'accuracy': 4,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.48606888786044,
    'plannedLongitude': -71.25228166580202,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:23.694Z',
    'createdOnTerrain': false,
    'email': 'bastian.gomez@nviro.cl',
    'firstName': 'Bastian',
    'lastName': 'Gomez',
  },
  {
    'name': '52',
    'statusId': 'todo',
    'observation': '',
    'dateVisited': null,
    'latitude': null,
    'longitude': null,
    'accuracy': null,
    'exposureId': null,
    'plannedLatitude': -32.48554427409514,
    'plannedLongitude': -71.25232458114625,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:24.149Z',
    'createdOnTerrain': false,
    'email': 'bastian.gomez@nviro.cl',
    'firstName': 'Bastian',
    'lastName': 'Gomez',
  },
  {
    'name': '53',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T14:38:01.841Z',
    'latitude': -32.4848719,
    'longitude': -71.2544304,
    'accuracy': 4.6,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.48491111547768,
    'plannedLongitude': -71.25443816184999,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:26.043Z',
    'createdOnTerrain': false,
    'email': 'bastian.gomez@nviro.cl',
    'firstName': 'Bastian',
    'lastName': 'Gomez',
  },
  {
    'name': '54',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T14:42:37.499Z',
    'latitude': -32.4851731,
    'longitude': -71.2545818,
    'accuracy': 3.9,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.485164379459256,
    'plannedLongitude': -71.25458836555482,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:26.519Z',
    'createdOnTerrain': false,
    'email': 'bastian.gomez@nviro.cl',
    'firstName': 'Bastian',
    'lastName': 'Gomez',
  },
  {
    'name': '55',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T14:47:53.185Z',
    'latitude': -32.4852564,
    'longitude': -71.2536303,
    'accuracy': 3.2,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.48527292094743,
    'plannedLongitude': -71.25359058380128,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:27.031Z',
    'createdOnTerrain': false,
    'email': 'bastian.gomez@nviro.cl',
    'firstName': 'Bastian',
    'lastName': 'Gomez',
  },
  {
    'name': '56',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T15:17:24.235Z',
    'latitude': -32.4857448,
    'longitude': -71.2544697,
    'accuracy': 3.9,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.48571613066544,
    'plannedLongitude': -71.25445961952211,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:28.013Z',
    'createdOnTerrain': false,
    'email': 'bastian.gomez@nviro.cl',
    'firstName': 'Bastian',
    'lastName': 'Gomez',
  },
  {
    'name': '57',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T15:09:29.661Z',
    'latitude': -32.4859624,
    'longitude': -71.2531784,
    'accuracy': 4.2,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.48594225723159,
    'plannedLongitude': -71.25325798988344,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:28.510Z',
    'createdOnTerrain': false,
    'email': 'bastian.gomez@nviro.cl',
    'firstName': 'Bastian',
    'lastName': 'Gomez',
  },
  {
    'name': '58',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T15:14:54.126Z',
    'latitude': -32.485576,
    'longitude': -71.2537767,
    'accuracy': 3.7,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.48556236427587,
    'plannedLongitude': -71.2537729740143,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:29.371Z',
    'createdOnTerrain': false,
    'email': 'bastian.gomez@nviro.cl',
    'firstName': 'Bastian',
    'lastName': 'Gomez',
  },
  {
    'name': '59',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T15:21:06.719Z',
    'latitude': -32.4861901,
    'longitude': -71.2540688,
    'accuracy': 4.1,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.48615029317061,
    'plannedLongitude': -71.25407338142396,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:30.103Z',
    'createdOnTerrain': false,
    'email': 'bastian.gomez@nviro.cl',
    'firstName': 'Bastian',
    'lastName': 'Gomez',
  },
  {
    'name': '29',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T12:44:37.368Z',
    'latitude': -32.4820958,
    'longitude': -71.2539405,
    'accuracy': 4.1,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.48212516463698,
    'plannedLongitude': -71.25397682189943,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:32:54.341Z',
    'createdOnTerrain': false,
    'email': 'ernesto.ceballos@nviro.cl',
    'firstName': 'Ernesto',
    'lastName': 'Ceballos',
  },
  {
    'name': '30',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T12:56:11.777Z',
    'latitude': -32.482427,
    'longitude': -71.2530287,
    'accuracy': 9.2,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.482441754303224,
    'plannedLongitude': -71.25294685363771,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:32:57.772Z',
    'createdOnTerrain': false,
    'email': 'ernesto.ceballos@nviro.cl',
    'firstName': 'Ernesto',
    'lastName': 'Ceballos',
  },
  {
    'name': '31',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T13:04:48.048Z',
    'latitude': -32.4829449,
    'longitude': -71.2520291,
    'accuracy': 4,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.482903068680265,
    'plannedLongitude': -71.25201344490053,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:32:59.235Z',
    'createdOnTerrain': false,
    'email': 'ernesto.ceballos@nviro.cl',
    'firstName': 'Ernesto',
    'lastName': 'Ceballos',
  },
  {
    'name': '32',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T13:12:50.925Z',
    'latitude': -32.4833781,
    'longitude': -71.2511989,
    'accuracy': 4.3,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.483346290070074,
    'plannedLongitude': -71.25124096870424,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:00.244Z',
    'createdOnTerrain': false,
    'email': 'ernesto.ceballos@nviro.cl',
    'firstName': 'Ernesto',
    'lastName': 'Ceballos',
  },
  {
    'name': '33',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T13:37:23.844Z',
    'latitude': -32.4827091,
    'longitude': -71.253326,
    'accuracy': 4.3,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.48265884371639,
    'plannedLongitude': -71.25338673591615,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:03.996Z',
    'createdOnTerrain': false,
    'email': 'ernesto.ceballos@nviro.cl',
    'firstName': 'Ernesto',
    'lastName': 'Ceballos',
  },
  {
    'name': '34',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T13:44:42.785Z',
    'latitude': -32.4830263,
    'longitude': -71.2542374,
    'accuracy': 3.6,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.48302065824131,
    'plannedLongitude': -71.25426650047304,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:04.487Z',
    'createdOnTerrain': false,
    'email': 'ernesto.ceballos@nviro.cl',
    'firstName': 'Ernesto',
    'lastName': 'Ceballos',
  },
  {
    'name': '35',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T13:49:03.760Z',
    'latitude': -32.4833055,
    'longitude': -71.2534982,
    'accuracy': 3.9,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.48331915412936,
    'plannedLongitude': -71.25352621078493,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:05.481Z',
    'createdOnTerrain': false,
    'email': 'ernesto.ceballos@nviro.cl',
    'firstName': 'Ernesto',
    'lastName': 'Ceballos',
  },
  {
    'name': '36',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T13:31:51.750Z',
    'latitude': -32.482954,
    'longitude': -71.2526876,
    'accuracy': 4.3,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.48296638615532,
    'plannedLongitude': -71.25277519226076,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:06.329Z',
    'createdOnTerrain': false,
    'email': 'ernesto.ceballos@nviro.cl',
    'firstName': 'Ernesto',
    'lastName': 'Ceballos',
  },
  {
    'name': '37',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T13:52:41.526Z',
    'latitude': -32.4834553,
    'longitude': -71.2528601,
    'accuracy': 3.2,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.483481969650974,
    'plannedLongitude': -71.25282883644105,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:07.944Z',
    'createdOnTerrain': false,
    'email': 'ernesto.ceballos@nviro.cl',
    'firstName': 'Ernesto',
    'lastName': 'Ceballos',
  },
  {
    'name': '38',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T13:56:26.018Z',
    'latitude': -32.4836622,
    'longitude': -71.2521988,
    'accuracy': 6.3,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.4836809659996,
    'plannedLongitude': -71.25218510627748,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:08.636Z',
    'createdOnTerrain': false,
    'email': 'ernesto.ceballos@nviro.cl',
    'firstName': 'Ernesto',
    'lastName': 'Ceballos',
  },
  {
    'name': '39',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T13:19:36.683Z',
    'latitude': -32.4832292,
    'longitude': -71.2521206,
    'accuracy': 114.3,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.483174428973975,
    'plannedLongitude': -71.25221729278566,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:09.784Z',
    'createdOnTerrain': false,
    'email': 'ernesto.ceballos@nviro.cl',
    'firstName': 'Ernesto',
    'lastName': 'Ceballos',
  },
  {
    'name': '40',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T14:02:24.335Z',
    'latitude': -32.4842143,
    'longitude': -71.2510831,
    'accuracy': 5.8,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.48416036448707,
    'plannedLongitude': -71.25106930732728,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:10.415Z',
    'createdOnTerrain': false,
    'email': 'ernesto.ceballos@nviro.cl',
    'firstName': 'Ernesto',
    'lastName': 'Ceballos',
  },
  {
    'name': '42',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T14:26:28.169Z',
    'latitude': -32.4842952,
    'longitude': -71.2541635,
    'accuracy': 5.9,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.48428699762339,
    'plannedLongitude': -71.2541699409485,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:12.323Z',
    'createdOnTerrain': false,
    'email': 'ernesto.ceballos@nviro.cl',
    'firstName': 'Ernesto',
    'lastName': 'Ceballos',
  },
  {
    'name': '45',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T14:23:20.920Z',
    'latitude': -32.4844299,
    'longitude': -71.2538138,
    'accuracy': 4,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.484440766192265,
    'plannedLongitude': -71.25386953353883,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:15.085Z',
    'createdOnTerrain': false,
    'email': 'ernesto.ceballos@nviro.cl',
    'firstName': 'Ernesto',
    'lastName': 'Ceballos',
  },
  {
    'name': '46',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T14:29:21.352Z',
    'latitude': -32.4842141,
    'longitude': -71.2545954,
    'accuracy': 6.1,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.48416036448707,
    'plannedLongitude': -71.25457763671876,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:17.983Z',
    'createdOnTerrain': false,
    'email': 'ernesto.ceballos@nviro.cl',
    'firstName': 'Ernesto',
    'lastName': 'Ceballos',
  },
  {
    'name': '47',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T14:18:36.087Z',
    'latitude': -32.4837648,
    'longitude': -71.2537537,
    'accuracy': 3.8,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.483726192381084,
    'plannedLongitude': -71.25374078750612,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:19.354Z',
    'createdOnTerrain': false,
    'email': 'ernesto.ceballos@nviro.cl',
    'firstName': 'Ernesto',
    'lastName': 'Ceballos',
  },
  {
    'name': '48',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T14:11:47.486Z',
    'latitude': -32.4840207,
    'longitude': -71.2528007,
    'accuracy': 3.9,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.48403373117253,
    'plannedLongitude': -71.25277519226076,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:19.840Z',
    'createdOnTerrain': false,
    'email': 'ernesto.ceballos@nviro.cl',
    'firstName': 'Ernesto',
    'lastName': 'Ceballos',
  },
  {
    'name': '60',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T14:15:05.308Z',
    'latitude': -32.4843233,
    'longitude': -71.2531477,
    'accuracy': 3.8,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.4843050880569,
    'plannedLongitude': -71.25313997268678,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:31.152Z',
    'createdOnTerrain': false,
    'email': 'ernesto.ceballos@nviro.cl',
    'firstName': 'Ernesto',
    'lastName': 'Ceballos',
  },
  {
    'name': '61',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T14:07:18.022Z',
    'latitude': -32.4846366,
    'longitude': -71.2521366,
    'accuracy': 5.8,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.484576444123014,
    'plannedLongitude': -71.25217437744142,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:31.955Z',
    'createdOnTerrain': false,
    'email': 'ernesto.ceballos@nviro.cl',
    'firstName': 'Ernesto',
    'lastName': 'Ceballos',
  },
  {
    'name': '49',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T15:41:02.588Z',
    'latitude': -32.4866708,
    'longitude': -71.2529032,
    'accuracy': 3.9,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.48670203833163,
    'plannedLongitude': -71.25290393829347,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:22.408Z',
    'createdOnTerrain': false,
    'email': 'eduardo.blanco@nviro.cl',
    'firstName': 'Eduardo',
    'lastName': 'Blanco',
  },
  {
    'name': '50',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T15:36:52.909Z',
    'latitude': -32.486381,
    'longitude': -71.2527211,
    'accuracy': 4.8,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.48638546365291,
    'plannedLongitude': -71.25270009040834,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:23.196Z',
    'createdOnTerrain': false,
    'email': 'eduardo.blanco@nviro.cl',
    'firstName': 'Eduardo',
    'lastName': 'Blanco',
  },
  {
    'name': '62',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T16:02:35.858Z',
    'latitude': -32.486464,
    'longitude': -71.2554741,
    'accuracy': 4.5,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.48646686867669,
    'plannedLongitude': -71.25543594360353,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:35.737Z',
    'createdOnTerrain': false,
    'email': 'eduardo.blanco@nviro.cl',
    'firstName': 'Eduardo',
    'lastName': 'Blanco',

  },
  {
    'name': '63',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T15:58:14.804Z',
    'latitude': -32.4863241,
    'longitude': -71.2563742,
    'accuracy': 4,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.4863402386079,
    'plannedLongitude': -71.25635862350465,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:36.542Z',
    'createdOnTerrain': false,
    'email': 'eduardo.blanco@nviro.cl',
    'firstName': 'Eduardo',
    'lastName': 'Blanco',
  },
  {
    'name': '64',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T15:52:22.198Z',
    'latitude': -32.4862201,
    'longitude': -71.2570792,
    'accuracy': 4.6,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.48624074342885,
    'plannedLongitude': -71.25707745552064,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:37.554Z',
    'createdOnTerrain': false,
    'email': 'eduardo.blanco@nviro.cl',
    'firstName': 'Eduardo',
    'lastName': 'Blanco',
  },
  {
    'name': '65',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T16:17:05.827Z',
    'latitude': -32.4861156,
    'longitude': -71.2580472,
    'accuracy': 7.7,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.486120151823364,
    'plannedLongitude': -71.25806987285615,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:40.596Z',
    'createdOnTerrain': false,
    'email': 'eduardo.blanco@nviro.cl',
    'firstName': 'Eduardo',
    'lastName': 'Blanco',
  },
  {
    'name': '66',
    'statusId': 'todo',
    'observation': '',
    'dateVisited': null,
    'latitude': null,
    'longitude': null,
    'accuracy': null,
    'exposureId': null,
    'plannedLatitude': -32.48610658427224,
    'plannedLongitude': -71.2581503391266,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:41.676Z',
    'createdOnTerrain': false,
    'email': 'eduardo.blanco@nviro.cl',
    'firstName': 'Eduardo',
    'lastName': 'Blanco',
  },
  {
    'name': '67',
    'statusId': 'synced',
    'observation': '',
    'dateVisited': '2021-06-18T16:23:15.723Z',
    'latitude': -32.4855166,
    'longitude': -71.2580753,
    'accuracy': 11.5,
    'exposureId': 'no-aplica',
    'plannedLatitude': -32.48552317763877,
    'plannedLongitude': -71.25812351703645,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:44.199Z',
    'createdOnTerrain': false,
    'email': 'eduardo.blanco@nviro.cl',
    'firstName': 'Eduardo',
    'lastName': 'Blanco',
  },
  {
    'name': '68',
    'statusId': 'todo',
    'observation': '',
    'dateVisited': null,
    'latitude': null,
    'longitude': null,
    'accuracy': null,
    'exposureId': null,
    'plannedLatitude': -32.484718160724846,
    'plannedLongitude': -71.25809133052827,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:46.463Z',
    'createdOnTerrain': false,
    'email': 'eduardo.blanco@nviro.cl',
    'firstName': 'Eduardo',
    'lastName': 'Blanco',
  },
  {
    'name': '69',
    'statusId': 'todo',
    'observation': '',
    'dateVisited': null,
    'latitude': null,
    'longitude': null,
    'accuracy': null,
    'exposureId': null,
    'plannedLatitude': -32.484899064029484,
    'plannedLongitude': -71.25810205936433,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:47.672Z',
    'createdOnTerrain': false,
    'email': 'eduardo.blanco@nviro.cl',
    'firstName': 'Eduardo',
    'lastName': 'Blanco',
  },
  {
    'name': '70',
    'statusId': 'todo',
    'observation': '',
    'dateVisited': null,
    'latitude': null,
    'longitude': null,
    'accuracy': null,
    'exposureId': null,
    'plannedLatitude': -32.48247874627281,
    'plannedLongitude': -71.25792503356935,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:51.349Z',
    'createdOnTerrain': false,
    'email': 'eduardo.blanco@nviro.cl',
    'firstName': 'Eduardo',
    'lastName': 'Blanco',
  },
  {
    'name': '71',
    'statusId': 'todo',
    'observation': '',
    'dateVisited': null,
    'latitude': null,
    'longitude': null,
    'accuracy': null,
    'exposureId': null,
    'plannedLatitude': -32.48337423635895,
    'plannedLongitude': -71.25803232192995,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:52.356Z',
    'createdOnTerrain': false,
    'email': 'eduardo.blanco@nviro.cl',
    'firstName': 'Eduardo',
    'lastName': 'Blanco',
  },
  {
    'name': '72',
    'statusId': 'todo',
    'observation': '',
    'dateVisited': null,
    'latitude': null,
    'longitude': null,
    'accuracy': null,
    'exposureId': null,
    'plannedLatitude': -32.48169139535544,
    'plannedLongitude': -71.25823616981508,
    'plannedAltitude': null,
    'plannedAccuracy': null,
    'createdAt': '2021-06-17T23:33:57.997Z',
    'createdOnTerrain': false,
    'email': 'eduardo.blanco@nviro.cl',
    'firstName': 'Eduardo',
    'lastName': 'Blanco',
  },
];

const pointStatusMapper = {
  todo: { color: 'red', hex: '#f44336', text: 'Pendiente' },
  synced: { color: 'nviro', hex: '#5193eb', text: 'Sincronizada' },
};

const getLayers = () => {
  const plotsMarkers = plots.map(plot => {
    const coords = plot.plannedLatitude && plot.plannedLongitude ?
      [ plot.plannedLatitude, plot.plannedLongitude ] : [ plot.latitude, plot.longitude ];
    const marker = new L.Marker(coords, { icon: getMarkerIcon(plot.name, pointStatusMapper[plot.statusId].color) });
    if (plot.name === '70') {
      const div = document.createElement('div');
      render(() => <PointPopup point={plot} pointStatusMapper={pointStatusMapper} csLang={campaignSubtypeLanguage('plots')} />, div);
      marker.bindPopup(div);
      marker.openPopup();
    }

    return marker;
  });
  return [ ...interestAreaLayers, ...plotsMarkers, ...irMarkers, ...trackLines ];
};

const getLegend = () => {

  const legend = L.control({ position: 'bottomleft' });
  legend.onAdd = () => {

    const legendContainer = L.DomUtil.create('div', 'info bcw-legend nv-legend-small');
    render(() =>
      <ThemeProvider theme={themes['nviro']}>
        <MonitoringMapLegend
          trackLayers={trackLines}
          pointStatusMapper={pointStatusMapper}
          interestArea={interestAreaPolygons}
          csLang={campaignSubtypeLanguage('plots')}
          hasIndividualRecords
        />
      </ThemeProvider>, legendContainer);
    return legendContainer;
  };

  return legend;
};

const legend = getLegend();


export { mapOptions, getLayers, legend };