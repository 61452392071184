import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/styles';

import themes from 'src/themes';
import { AppRoutes } from 'src/AppRoutes';


const App = () => (
  <ThemeProvider theme={themes['nviro']}>
    <MuiPickersUtilsProvider utils={MomentUtils} >
      <AppRoutes />
    </MuiPickersUtilsProvider>
  </ThemeProvider>
);


export default App;
