import React, { Component } from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { LoadingCard } from 'src/components';
import { objectIsEmpty } from 'src/utils/util';


export class PointTimeByCampaignIndicator extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
    };
  }

  componentDidUpdate = async prevProps => {
    if (!objectIsEmpty(this.props.data) && this.props.data !== prevProps.data) {
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading, error } = this.state;
    const { data, cLang, componentId } = this.props;
    const { mean, total } = data;
    return (
      <LoadingCard loading={ loading } error={ error } title={`Tiempo por ${cLang.point}`}
        info={
          componentId === 'flora' ?
            `
              Muestra la velocidad en h/${cLang.p} (horas por ${cLang.point}) o min/${cLang.p} (minutos por ${cLang.point})
              a la cual se están completando las ${cLang.points}, utilizando como datos la fecha del 1er registro capturado y la fecha
              en la cual se finalizó la ${cLang.point}. Para esta medición no se consideran las ${cLang.points} sin registros
            ` :
            `
              Muestra la velocidad en h/${cLang.p} (horas por ${cLang.point}) o min/${cLang.p} (minutos por ${cLang.point})
              a la cual se están completando las ${cLang.points}, utilizando como datos la fecha la 1ra metodología creada y la fecha
              en la cual se finalizó la ${cLang.point}. Para esta medición no se consideran las ${cLang.points} sin metodologías
            `
        }
      >
        {
          !objectIsEmpty(data) ?
            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
              <Box display="flex" alignItems="center">
                <Typography variant="h2" color="primary">{mean > 1 ? Math.round(mean) : Math.round(mean * 60)} </Typography>
                <Box display="flex" ml={1} >
                  <Typography variant="body1" color="primary">{mean > 1 ? `horas` : `minutos`} por {cLang.point}</Typography>
                </Box>
              </Box>
              <Typography noWrap variant="body2" color="textSecondary">
                Calculado de {total} {cLang.points}
              </Typography>
            </Box>
            :
            <span>Faltan datos para calcular esta estadística</span>
        }
      </LoadingCard>
    );
  }
}


PointTimeByCampaignIndicator.propTypes = {
  data: PropTypes.object,
  componentId: PropTypes.string,
  cLang: PropTypes.object,
};
