import React from 'react';
import { Box, Typography, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';

import { TableWrapper } from 'src/components';


const TableUI = ({ title, data, columns, options }) => (
  <Box display="flex" flexDirection="column" p={2} >
    <Box display="flex" alignItems="center">
      <Typography noWrap variant="subtitle2" color="textSecondary">{title}</Typography>
    </Box>
    <Box display="flex" flexDirection="column" height="100%">
      <Box display="flex" flexGrow={1} width="100%">
        <TableWrapper columns={columns} data={data} options={options}
          /* eslint-disable react/display-name */
          components={{ Container: props => <Paper {...props} elevation={0} style={{ width: '100%' }} /> }}
        />
      </Box>
    </Box>
  </Box>
);

TableUI.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  columns: PropTypes.array,
  options: PropTypes.object,
};


export { TableUI };
