import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { HeaderUI, StatisticsUI, BrowserUI } from 'src/scenes/Home/components';


const useStyles = makeStyles(theme => ({
  sidePanel: {
    width: theme.spacing(16),
  },
}));

const StatisticsPageUI = () => {
  const classes = useStyles();
  return (
    <BrowserUI>
      <HeaderUI user="Juan Von Hildebrand"/>
      <Box display="flex">
        <Box className={classes.sidePanel}></Box>
        <StatisticsUI />
      </Box>
    </BrowserUI>
  );
};


export {
  StatisticsPageUI,
};