import React, { Component } from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { LoadingCard } from 'src/components';
import { objectIsEmpty } from 'src/utils/util';


export class SpeciesUnrecognizedIndicator extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
    };
  }

  componentDidUpdate = async prevProps => {
    if (!objectIsEmpty(this.props.data) && this.props.data !== prevProps.data) {
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading, error } = this.state;
    const { data } = this.props;
    const {
      species: { recognizedSpeciesCount, unrecognizedSpeciesCount },
      records: { recognizedRecordCount, unrecognizedRecordCount },
    } = data;

    return (
      <LoadingCard loading={ loading } error={ error } title="No reconocidas"
        info={ `
          Total de especies no reconocidas, una especie puede tener múltiples registros
        `}
      >
        {
          !objectIsEmpty(data) ?
            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
              <Box display="flex" alignItems="center">
                <Typography variant="h2" color="primary">{unrecognizedSpeciesCount}</Typography>
                <Box display="flex" ml={1} >
                  <Typography variant="body2" color="primary">
                    Especies no reconocidas contra {recognizedSpeciesCount} reconocidas
                  </Typography>
                </Box>
              </Box>
              <Typography noWrap variant="body2" color="textSecondary">
                {unrecognizedRecordCount} registros no reconocidos contra {recognizedRecordCount} reconocidos
              </Typography>
            </Box>
            :
            <span>Aún no se han registrado especies</span>
        }
      </LoadingCard>
    );
  }
}

SpeciesUnrecognizedIndicator.propTypes = {
  data: PropTypes.object,
};
