import React from 'react';
import PropTypes from 'prop-types';
import urlJoin from 'url-join';
import { Box, Badge, IconButton, Avatar } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Delete, Edit, PhotoAlbum, AssignmentTurnedIn, LocalFlorist, Cancel,
  AssignmentInd, Explore, AlternateEmail, AddCircle, InfoOutlined } from '@material-ui/icons';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { AvatarGroup } from '@material-ui/lab';
import { useRouteMatch } from 'react-router';

import { TableWrapper, LabelChip, LabelCoords, WarnableAccuracy, TooltipWrapper as Tooltip } from 'src/components';
import config from 'src/config/local';
import { WebSync, WebCheck, TextBoxCheckOutline, Paw } from 'src/utils/mdIcons';
import { isTransect as isTransectFn, isPlot as isPlotFn, isStation as isStationFn, isFlora,
  isFauna as checkIsFauna } from 'src/utils/checkers';
import { campaignsApi } from 'src/services';


const NA = () => <Box color="text.hint">No aplica</Box>;

const { serverUrl } = config;

const StyledBadge = withStyles(theme => ({
  badge: {
    height: theme.spacing(2),
    minWidth: theme.spacing(2),
  },
}))(Badge);

const useStyles = makeStyles(theme => ({
  tooltipIcon: {
    fontSize: theme.typography.body2.fontSize,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  syncColor: {
    color: theme.palette.nviro.secondary.main,
  },
  avatar: {
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      backgroundColor: 'white',
    },
  },
}));

const PointsList = props => {
  const { actions, hasPermissionFor, formOptions, hasOwnWorkFinished, hasAdminPowers, coordinatesCampaign,
    userRole, campaignInProgress, csLang, tableRef, campaignSubtypeId, setCampaignCounter, userId } = props;

  const classes = useStyles();
  const theme = useTheme();

  const match = useRouteMatch();
  const { campaignHash } = match.params;

  const { pointStatusesOptions, soilCoveragesOptions, methodologyTypesOptions } = formOptions;

  const methodologyTypesLabelsDict = methodologyTypesOptions.reduce((acc, curr) => {
    acc[curr.value] = curr.label;
    return acc;
  }, {});

  const isTransect = isTransectFn(campaignSubtypeId);
  const isPlot = isPlotFn(campaignSubtypeId);
  const isStation = isStationFn(campaignSubtypeId);

  const isSynced = point => isTransect ? point.synced : point.statusId === 'synced';

  const isAddRecordAllowed = point => (campaignInProgress || hasAdminPowers) && isSynced(point);

  const isNotResponsableUser = point => userId !== Number(point.userId) && !(hasAdminPowers || coordinatesCampaign);

  const tableColumns = [
    /* eslint-disable react/display-name */
    { title: 'Nombre', field: 'name' },
    ...userRole === 'fan' ? [] : [ { title: 'Responsable', field: 'email', emptyValue: 'Sin asignar',
      render: r =>
        <Box mr={1} display="flex" alignItems="center">
          <Box mr={0.5} whiteSpace="nowrap">{r.firstName} {r.lastName}</Box>
          <Tooltip interactive title={r.email}>
            <AlternateEmail className={classes.tooltipIcon} />
          </Tooltip>
        </Box>,
    } ],
    ...userRole === 'fan' || !isStation ? [] : [ { title: 'Colaboradores', field: 'collaboratingUsers',
      render: r =>
        <Box display="flex" alignItems="center">
          {
            r.collaboratingUsers.length && !(r.collaboratingUsers.length === 1 && r.collaboratingUsers[0].id === Number(r.userId)) ? (
              <AvatarGroup max={4} spacing='medium'>
                { r.collaboratingUsers.filter(cu => cu.id !== Number(r.userId)).map(cu => (
                  <Tooltip key={cu.id} interactive title={cu.name} arrow>
                    <Avatar
                      alt={cu.name}
                      className={ classes.avatar }
                      // TODO: des-comentar cuando tengamos fotos de usuario
                      // src={ urlJoin(serverUrl, '/uploads/photos/users', `${cu.id}-circle-thumbnail-128x128.png`) }
                    >
                      {cu.initials}
                    </Avatar>
                  </Tooltip>))
                }
              </AvatarGroup>
            )
              : isNotResponsableUser(r) ? 'Tú'
              : '-'
          }
        </Box>,
    } ],
    { title: 'Estado', field: 'statusId', render: r => {
      const statusInFront = isTransect ? (r.synced ? 'Sincronizada' : 'Pendiente')
        : isStation && (isNotResponsableUser(r) || r.collaboratingUsers.length) && r.statusId !== 'synced' ? 'En proceso'
        : pointStatusesOptions.find(opt => opt.value === r.statusId)?.label;
      return (
        <Box mr={1} display="flex" alignItems="center">
          <Box mr={0.5} whiteSpace="nowrap">{ statusInFront }</Box>
          {
            statusInFront === 'En proceso' &&
            <Tooltip interactive title={ userId === Number(r.userId) ?
              `Debes marcar esta estación como lista para sincronizar y luego sincronizarla en el móvil para terminarla`
              : `El encargado aún no termina su trabajo en esta estación`
            }>
              <InfoOutlined className={classes.tooltipIcon} />
            </Tooltip>
          }
        </Box>
      );
    },
    },
    ...isStation ? [
      { title: 'Ambiente', field: 'environmentId', emptyValue: '-',
        render: r => <div>{r.environmentName}</div>,
        cellStyle: (cellId, row) => row.environmentDeletedAt ? { color: theme.palette.error.main } : {},
      },
      { title: 'Metodologías sugeridas', render: r =>
        !r.suggestedMethodologies?.length ? '-' : r.suggestedMethodologies.map(({ methodEnumId }) =>
          <Box key={methodEnumId} mx={1}>
            <LabelChip
              variant="outlined"
              color="default"
              label={methodologyTypesLabelsDict[methodEnumId]}
            />
          </Box>,
        ),
      },
    ] : [],
    { title: 'Coord. planificada (UTM Sur-Oeste)', render: r =>
      r.createdOnTerrain ? <NA /> : <LabelCoords latitude={r.plannedLatitude} longitude={r.plannedLongitude} />,
    },
    { title: `Coord. ${isTransect ? 'inicial' : ''} terreno (UTM Sur-Oeste)`, render: r =>
      <LabelCoords latitude={r.latitude} longitude={r.longitude} />,
    },
    { title: `Precisión ${isTransect ? 'inicial' : ''} terreno`, render: r =>
      <WarnableAccuracy accuracy={ isTransect ? r.startAccuracy : r.accuracy } />,
    },
    { title: `Altitud ${isTransect ? 'inicial ' : ''}terreno(m)`, field: isTransect ? 'startAltitude' : 'altitude', emptyValue: '-' },
    ...isTransect ? [
      { title: 'Coord. final terreno (UTM Sur-Oeste)', render: r => <LabelCoords latitude={r.endLatitude} longitude={r.endLongitude} /> },
      { title: 'Precisión final terreno', render: r => <WarnableAccuracy accuracy={ r.endAccuracy } /> },
      { title: 'Altitud final terreno(m)', field: 'endAltitude', emptyValue: '-' },

    ] : [],
    ...isPlot ? [
      { title: 'Tamaño(m²)', field: 'area', emptyValue: '-' },
      { title: 'Pendiente(%)', field: 'slope', emptyValue: '-' },
      { title: 'Exposición', field: 'exposureId', emptyValue: '-' },
      { title: 'Cobertura de Suelo', render: r => {
        if (!r.soilCoverId) {
          return '-';
        }
        const { value, levelOne, levelTwo } = soilCoveragesOptions.find(sco => sco.value === r.soilCoverId);
        return value === 'other' ? r.soilCoverOther
          : levelTwo !== '' ? levelTwo
          : levelOne;
      } },
    ] : [],
    ...isTransect ? [
      { title: 'Largo (m)', field: 'length', emptyValue: '-' },
      { title: 'N° de puntos', field: 'intervalDistance', emptyValue: '-', render: r => r.length / r.intervalDistance * 100 + 1 },
    ] : [],
    { title: 'Revisada', field: 'reviewed', emptyValue: 'x',
      render: r => <div>{r.reviewed ? 'Sí' : 'No'}</div> },
    ...isPlot ? [ { title: 'Fecha visitada', field: 'dateVisited', emptyValue: 'No visitada',
      render: r => <div>{new Date(r.dateVisited).toLocaleDateString('es')}</div> } ] : [],
    ...isStation ? [ { title: 'Fecha de finalización', field: 'finishedDate', emptyValue: 'No finalizada',
      render: r => <div>{new Date(r.finishedDate).toLocaleDateString('es')}</div> } ] : [],
    ...isTransect ? [
      { title: 'Fecha inicio', field: 'startDate', emptyValue: 'No iniciada',
        render: r => <div>{new Date(r.startDate).toLocaleDateString('es')}</div> },
      { title: 'Fecha fin', field: 'endDate', emptyValue: 'No finalizada',
        render: r => <div>{new Date(r.endDate).toLocaleDateString('es')}</div>,
      } ] : [],
    { title: 'Observaciones', field: 'observation',
      render: r => <div>{r.observation?.trim() ? r.observation : 'Sin observaciones'}</div> },
    { title: 'Acciones',
      render: rowData => {
        const total = Object.values(rowData.recordCount).reduce((v1, v2) => v1 + v2, 0);
        const hasRecords = total > 0;
        const hasSomeMethodology = rowData.collaboratingUsers?.length > 0;
        return (
          <Box display="flex" alignItems="center" justifyContent='space-around'>
            { isFlora(campaignSubtypeId) && hasPermissionFor('point:update') && campaignInProgress &&
              <Box display="flex">
                <Tooltip title={isSynced(rowData) ? `${csLang.Point} ya esta sincronizada` : 'Cambiar a estado sincronizado'}>
                  <IconButton size="small" { ...(!isSynced(rowData) ? { onClick: () => actions.openSyncPointDialog(rowData) } : {})}>
                    {isSynced(rowData) ? <WebCheck className={classes.syncColor} /> : <WebSync color="inherit" />}
                  </IconButton>
                </Tooltip>
              </Box>
            }
            <Box display="flex">
              <Tooltip title="Ver registros">
                <IconButton
                  size="small"
                  { ...(hasRecords ? { onClick: () => actions.goTo['records'](rowData.id) } : {})}
                >
                  {isFlora(campaignSubtypeId)
                    && <LocalFlorist color={hasRecords ? 'primary' : 'disabled'} />}
                  {checkIsFauna(campaignSubtypeId)
                    && <Paw color={hasRecords ? 'primary' : 'disabled'} />}
                </IconButton>
              </Tooltip>
            </Box>
            <Box display="flex">
              <Tooltip title="Corregir registros">
                <IconButton
                  size="small"
                  { ...(hasRecords ? { onClick: () => actions.goTo['reviewMode'](rowData.id) } : {})}
                >
                  <StyledBadge badgeContent={rowData.recordCount.other} max={10} color="error" overlap='rectangular'>
                    <TextBoxCheckOutline color={hasRecords ? 'inherit' : 'disabled'} />
                  </StyledBadge>
                </IconButton>
              </Tooltip>
            </Box>
            {(isPlot || isTransect) &&
              <Box display="flex">
                <Tooltip title={
                  isAddRecordAllowed(rowData) ? 'Agregar registro'
                  : (!isSynced(rowData) ? `${csLang.Point} debe encontrarse en estado sincronizada` : 'Trabajo ya fue finalizado')
                }>
                  <IconButton
                    size="small" { ...(isAddRecordAllowed(rowData) ? { onClick: () => actions.openCreateRecordDialog(rowData) } : {}) }>
                    <AddCircle color={isAddRecordAllowed(rowData) ? 'action' : 'disabled'} />
                  </IconButton>
                </Tooltip>
              </Box>
            }

            {isStation &&
              <Box display="flex">
                <Tooltip title="Ver metodologías">
                  <IconButton size="small"
                    { ...(hasSomeMethodology ? { onClick: () => actions.goTo['methodologies'](rowData.id) } : {})}
                  >
                    <Explore color={hasSomeMethodology ? 'action' : 'disabled'} />
                  </IconButton>
                </Tooltip>
              </Box>
            }
            <Box display="flex">
              <Tooltip title="Ver fotos">
                <IconButton size="small" onClick={() => rowData.pictures.length && actions.toggleDialog({
                  data: { pictures: rowData.pictures.map(p => urlJoin(serverUrl, p)), title: rowData.name },
                  type: 'photo',
                })}>
                  <PhotoAlbum {...(rowData.pictures.length ? {} : { color: 'disabled' })}
                  />
                </IconButton>
              </Tooltip>
            </Box>
            {hasPermissionFor('point:update') && campaignInProgress &&
              <Box display="flex">
                <Tooltip title="Editar">
                  <IconButton size="small"
                    onClick={() => actions.openEditPointDialog(rowData)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
              </Box>
            }
            {hasPermissionFor('point:update') && campaignInProgress &&
              <Box display="flex">
                <Tooltip title="Eliminar">
                  <IconButton
                    size="small" onClick={() => actions.toggleDialog({ data: { points: [ rowData ] }, type: 'delete' })}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Box>
            }
          </Box>
        );
      },
      cellStyle: { width: `${(hasPermissionFor('point:update')) ? 10 : 5}%` },
    },
  ];

  const openDialogReviewPoints = {
    icon: () => <AssignmentTurnedIn />,
    tooltip: `Marcar ${csLang.points} como revisadas`,
    onClick: (event, rowsSelected) => actions.toggleDialog({ data: { points: rowsSelected }, type: 'review',
    }),
    position: 'toolbarOnSelect',
  };

  const openDialogEditPoints = {
    icon: () => <AssignmentInd />,
    tooltip: `Reasignar ${csLang.points}`,
    onClick: (event, rowsSelected) => actions.toggleDialog({
      data: { points: rowsSelected }, type: 'multi-reassign',
    }),
    position: 'toolbarOnSelect',
  };

  const openSuggestMethodologiesDialog = {
    icon: () => <Explore />,
    tooltip: `Sugerir metodologías para las estaciones seleccionadas`,
    onClick: (event, rowsSelected) => actions.toggleDialog({
      data: { points: rowsSelected }, type: 'multi-suggest-methodologies',
    }),
    position: 'toolbarOnSelect',
  };

  const closeSelection = {
    icon: () => <Cancel />,
    tooltip: 'Deseleccionar todo',
    onClick: () => tableRef.current.onAllSelected(false),
    position: 'toolbarOnSelect',
  };

  const tableActions = [
    ...(!hasOwnWorkFinished || hasAdminPowers || coordinatesCampaign) ? [ openDialogReviewPoints ] : [ ],
    ...(hasPermissionFor('point:update') ? (
      userRole !== 'fan' ? [ openDialogEditPoints ] : []
    ) : []),
    ...(hasPermissionFor('point:update') && isStation ? [ openSuggestMethodologiesDialog ] : []),
    closeSelection,
  ];

  const tableOptions = {
    selection: campaignInProgress,
    toolbarButtonAlignment: 'left',
    search: false,
    idSynonym: 'id', // Note: necesario para que sea única la id en la "row" de la tabla
    // toolbar: false, TODO: Revisar si trasladar a otro lado los botones que salen al seleccionar una fila, en la parte filtros quizá? mm
  };

  const handleData = async query => {
    const points = await actions.getPoints({ page: query.page + 1, size: query.pageSize });
    const campaignCounter = await campaignsApi.getCampaignCounter(campaignHash);
    setCampaignCounter(campaignCounter);
    return ({
      data: points,
      page: query.page,
      totalCount: points[0]?.totalPointsCount || 0,
    });
  };

  return (
    <Box width="100%">
      <TableWrapper
        tableRef={tableRef}
        options={tableOptions}
        columns={tableColumns}
        data={handleData}
        {...(campaignInProgress ? { actions: tableActions } : {})}
        useUrlPagination
      />
    </Box>
  );
};

PointsList.propTypes = {
  hasPermissionFor: PropTypes.func,
  match: PropTypes.object,
  history: PropTypes.any,
  formOptions: PropTypes.object,
  actions: PropTypes.object.isRequired,
  userRole: PropTypes.string,
  campaignInProgress: PropTypes.bool.isRequired,
  hasOwnWorkFinished: PropTypes.bool,
  csLang: PropTypes.object,
  campaignSubtypeId: PropTypes.string,
  coordinatesCampaign: PropTypes.bool,
  hasAdminPowers: PropTypes.bool,
  tableRef: PropTypes.object.isRequired,
  setCampaignCounter: PropTypes.func.isRequired,
  userId: PropTypes.number,
};


export { PointsList };
