import React, { forwardRef } from 'react';
import MaterialTable, { MTableToolbar, MTableBodyRow, MTableCell } from '@material-table/core';
import { Search, AddBox, ArrowUpward, Check, ChevronLeft, ChevronRight, Clear,
  DeleteOutline, Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt, ViewColumn } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Paper, TablePagination } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory } from 'react-router';

import themes from 'src/themes';


/* eslint-disable react/display-name */
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles(theme => ({
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  stickyLastColumn: {
    '& table:first-child': {
      '& tr': {
        '& td:not(:first-child):last-child, th:last-child': {
          backgroundColor: theme.palette.grey[ 100 ],
          position: 'sticky',
          right: 0,
          zIndex: 999,
        },
        '& th:last-child': {
          zIndex: 999,
        },
      },
    },
  },
  row: {
    backgroundColor: theme.palette.common.white,
    transition: theme.transitions.create(
      [ 'background-color' ],
      { duration: theme.transitions.duration.standard },
    ),
    '&:hover': {
      backgroundColor: theme.palette.grey[ 100 ],
    },
  },
  cell: {
    padding: '8px !important',
  },
  pagination: {
    fontFamily: 'Asap',
  },
}));

// @useUrlPagination: si es true, entonces la tabla lee y modifica los parámetros "page" y "size" en la url al hacer cosas de paginación
const TableWrapper = props => {
  const { options, lastColumnSticked = true, elevation = 1, useUrlPagination = false, defaultPageSize = 10, ...otherProps } = props;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const initialPage = props.options?.initialPage ?? (
    useUrlPagination ? (parseInt(new URLSearchParams(location.search).get('page') || 1, 10) - 1) : null
  );
  const initialPageSize = useUrlPagination ?
    parseInt(new URLSearchParams(location.search).get('size') || defaultPageSize, 10) : defaultPageSize;

  // Si se usa la paginación en url, entonces se modifica la función de datos para que ponga la página
  const finalData = useUrlPagination && typeof otherProps.data === 'function' ?
    query => {
      const params = new URLSearchParams(location.search);
      const paramPage = query.page ?? 0;
      params.set('page', paramPage + 1);
      history.push({ pathname: location.pathname, search: `?${params.toString()}` });
      return otherProps.data(query);
    }
    : otherProps.data;

  const onRowsPerPageChange = rowsPerPage => {
    const params = new URLSearchParams(location.search);
    params.set('size', rowsPerPage);
    history.push({ pathname: location.pathname, search: `?${params.toString()}` });
  };

  return (
    <MaterialTable
      components={{
        Container: props =>
          <Paper {...props} elevation={elevation}
            className={[ classes.paper, lastColumnSticked ? classes.stickyLastColumn : '' ].join(' ')} />,
        Toolbar: props => <MTableToolbar {...props} />,
        Row: props => <MTableBodyRow {...props} className={classes.row} />,
        Cell: props => <MTableCell {...props} className={classes.cell} />,
        Pagination: props => <TablePagination {...props} labelDisplayedRows={ () => '' } className={classes.pagination} />,
      }}
      icons={tableIcons}
      localization={{
        toolbar: {
          searchPlaceholder: 'Buscar',
          searchTooltip: 'Buscar',
          nRowsSelected: '{0} fila(s) seleccionada(s)',
        },
        header: {
          actions: 'Acciones',
        },
        body: {
          emptyDataSourceMessage: 'No hay datos para mostrar',
        },
        pagination: {
          labelDisplayedRows: '{from}-{to} de {count}',
          firstTooltip: 'Primera página',
          previousTooltip: 'Página anterior',
          nextTooltip: 'Siguiente página',
          lastTooltip: 'Última página',
          labelRowsPerPage: 'Filas por página:',
          labelRows: 'filas',
        },
      }}
      { ...(useUrlPagination ? { onRowsPerPageChange } : {}) }
      options={{
        showTitle: false,
        actionsColumnIndex: -1,
        clientSorting: false,
        headerStyle: { fontFamily: themes['nviro'].typography.fontFamily },
        initialPage,
        pageSizeOptions: [ 10, 20, 30, 40, 50 ],
        pageSize: initialPageSize,
        ...options,
      }}
      { ...otherProps }
      data={finalData}
    />
  );
};

TableWrapper.propTypes = {
  options: PropTypes.object,
  lastColumnSticked: PropTypes.bool,
  useUrlPagination: PropTypes.bool,
  elevation: PropTypes.number,
  defaultPageSize: PropTypes.number,
};


export { TableWrapper };
