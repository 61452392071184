import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';
import { humanReadableArrayOfStrings } from 'src/utils/util';


const FinishWorkDialog = props => {
  const { actions, cLang, catalogName, forceReview, completionStatusByUser } = props;
  const handleFinishWork = async () => {
    await actions.finishWork();
    actions.closeDialog();
  };

  const { hasTodoPoints, hasUnreviewedPoints, hasUncorrectedSingleRecords } = completionStatusByUser;
  const hasSomePending = hasTodoPoints || hasUnreviewedPoints || hasUncorrectedSingleRecords;
  const hashPendingText = {
    hasTodoPoints: `${cLang.points} pendientes`,
    hasUnreviewedPoints: `${cLang.points} sin revisar`,
    hasUncorrectedSingleRecords: `registros ${cLang.singles} con nombres que no pertenecen a ${catalogName}`,
  };

  const pendingText = Object.keys(completionStatusByUser).filter(k => completionStatusByUser[k]).map(k => hashPendingText[k]);
  const canFinish = !(forceReview && hasUnreviewedPoints);

  return (
    <Fragment>
      <DialogTitle id="form-dialog-title">Finalizar trabajo</DialogTitle>
      <DialogContent>
        <Box p={1}>
          {
            canFinish ?
              <>
                <Typography color="textPrimary" variant="body1">
                  Con esta acción das por terminado tu trabajo, por lo que no podrás realizar más cambios en la campaña.
                </Typography>
                <br />
                {
                  hasSomePending &&
                    <Typography variant="body1" color="textPrimary">
                      Debes considerar que aún hay&nbsp;
                      <Typography display="inline" component="span" color="error">
                        {humanReadableArrayOfStrings(pendingText)}
                      </Typography>
                        .
                      <Typography color="textPrimary" component="span" variant="body1">
                        <br/>
                        <span>¿Realmente deseas finalizar el trabajo?</span>
                      </Typography>
                    </Typography>
                }
              </>
              :
              <Typography variant="body1" color="textPrimary">
                Debido a la configuración de la campaña no es posible finalizar el trabajo sin antes
                haber marcado como revisadas todas las {`${cLang.points}`}.
              </Typography>
          }
        </Box>
      </DialogContent>
      <DialogActions>
        {
          canFinish ?
            <>
              <Button onClick={actions.closeDialog}>Cancelar</Button>
              <Button onClick={handleFinishWork} color="primary">Confirmar</Button>
            </>
            :
            <Button onClick={actions.closeDialog}>Ok</Button>
        }

      </DialogActions>
    </Fragment>
  );
};

FinishWorkDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  cLang: PropTypes.object.isRequired,
  catalogName: PropTypes.string.isRequired,
  forceReview: PropTypes.bool,
  completionStatusByUser: PropTypes.object,
};


export { FinishWorkDialog };
