import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { CheckBold, CloseThick } from 'src/utils/mdIcons';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    textAlign: 'center',
  },
  featureName: {
    color: '#6C757A',
    fontSize: '1.25rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
  },
  icon: {
    fontSize: '3rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  checkIcon: {
    color: theme.palette.primary.main,
  },
  notCheckIcon: {
    color: '#ABABAB',
  },
}));

const FeatureRow = ({ name, availabilityList }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.container} alignItems="center" container>
      <Grid item xs={3} md={6}>
        <Typography className={classes.featureName}>{name}</Typography>
      </Grid>
      {availabilityList.map((isAvailable, idx) => isAvailable ?
        <Grid item xs={3} md={2} key={idx}>
          <CheckBold className={clsx(classes.icon, classes.checkIcon)} />
        </Grid> :
        <Grid item xs={3} md={2} key={idx}>
          <CloseThick className={clsx(classes.icon, classes.notCheckIcon)} />
        </Grid>,
      )}
    </Grid>
  );
};

FeatureRow.propTypes = {
  name: PropTypes.string,
  availabilityList: PropTypes.array,
};


export { FeatureRow };