import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';
import { Button, Box, DialogTitle, DialogContent, DialogActions, TextField, Zoom } from '@material-ui/core';

import { withAccessControl } from 'src/components';


const EnvironmentEditDialog = ({ actions, prevEnvironment }) => {

  const [ environment, setEnvironment ] = useState(prevEnvironment);
  const [ error, setError ] = useState('');
  const [ showErrorContainer, setShowErrorContainer ] = useState(false);

  const handleChange = e => {
    setEnvironment(pe => ({ ...pe, [e.target.name]: e.target.value }));
    setError('');
  };

  const handleSubmit = async () => {
    try {
      await actions.editEnvironment(environment);
    } catch (e) {
      setShowErrorContainer(true);
      if (e.serverMessage) {
        setError(e.serverMessage);
      } else {
        setError('Ocurrió un error al tratar de actualizar el ambiente');
        console.error(e);
      }
    }
  };

  const { name, description } = environment;

  return (
    <Fragment>
      <DialogTitle id="form-dialog-title">Editar ambiente</DialogTitle>
      <DialogContent>
        <Box my={3}>
          <Box my={2}>
            <TextField label="Nombre" name="name" value={name} onChange={handleChange}
              fullWidth autoFocus autoComplete="off" />
          </Box>
          <Box my={2}>
            <TextField label="Descripción" name="description" value={description} onChange={handleChange} maxRows="5"
              fullWidth multiline autoComplete="off" />
          </Box>
        </Box>
        {
          showErrorContainer &&
            <Zoom in={ error !== '' } onExited={ () => setShowErrorContainer(false) }>
              <Alert severity="error">{ error }</Alert>
            </Zoom>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog} color="secondary">Cancelar</Button>
        <Button onClick={ handleSubmit } color="primary">Confirmar</Button>
      </DialogActions>
    </Fragment>
  );
};

EnvironmentEditDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  prevEnvironment: PropTypes.object.isRequired,
};

const EnvironmentEditDialogWithAccessControl = withAccessControl({ action: 'environment:update' })(EnvironmentEditDialog);


export { EnvironmentEditDialogWithAccessControl as EnvironmentEditDialog };