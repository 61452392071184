import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => ({
  stepTitle: {
    paddingBottom: theme.spacing(1),
    fontSize: '3rem',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.875rem',
      lineHeight: '34px',
    },
  },
}));

const StepTitle = ({ children }) => {
  const classes = useStyles();
  return (
    <Typography className={classes.stepTitle}>{children}</Typography>
  );
};

StepTitle.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};


export { StepTitle };