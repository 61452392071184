import React from 'react';
import { Box, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { BrowserUI, HeaderUI } from 'src/scenes/Home/components';


const DownloadablesUI = () => {
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {isDownSm ?
        <img width="100%" src="/img/tmp/downloadables-3.png" alt="descargables de campaña"></img> :
        <BrowserUI size="small">
          <HeaderUI size="small" user="Juan Von Hildebrand"/>
          <Box>
            <img height="100%" width="100%" src="/img/tmp/downloadables.png" alt="descargables de campaña"></img>
          </Box>
        </BrowserUI>
      }
    </>
  );
};


export {
  DownloadablesUI,
};