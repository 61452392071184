import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Stepper, Step, StepLabel, StepContent } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';


const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: 'rgb(232, 244, 253)',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  stepper: {
    padding: theme.spacing(0),
    backgroundColor: ({ isStyled }) => isStyled ? 'inherit' : 'transparent',
  },
}));

const StepperWrapper = ({ steps, activeStep, styled: isStyled, ...props }) => {
  const classes = useStyles({ isStyled });
  return (
    <Box className={clsx({ [classes.container]: isStyled })} >
      <Stepper className={classes.stepper} activeStep={activeStep} {...props} >
        {steps.map(({ label, Component }, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
            {props.orientation === 'vertical' &&
              <StepContent>
                <Component />
              </StepContent>
            }
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};


StepperWrapper.propTypes = {
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.number,
  orientation: PropTypes.string,
  styled: PropTypes.bool,
};


export {
  StepperWrapper,
};