const classesTranslation = {
  reptilia: 'Reptil',
  mammalia: 'Mamífero',
  aves: 'Ave',
  amphibia: 'Anfibio',
};

const translations = { ...classesTranslation };


export { translations };