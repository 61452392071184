import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';


const useWidth = () => {
  const theme = useTheme();
  const keys = [ ...theme.breakpoints.keys ].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
};


export {
  useWidth,
};