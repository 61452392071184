import { useState } from 'react';
import PropTypes from 'prop-types';


const usePagination = data => {

  const [ currentPage, setCurrentPage ] = useState(0);

  const [ itemsPerPage, setItemsPerPage ] = useState(5);

  const itemCount = data.length;

  const getCurrentData = () => {
    const start = currentPage * itemsPerPage;
    const end = start + itemsPerPage;
    return data.slice(start, end);
  };

  const maxPage = Math.ceil(itemCount / itemsPerPage);

  const next = () => {
    setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
  };

  const prev = () => {
    setCurrentPage(currentPage => Math.max(currentPage - 1, 0));
  };

  const jump = page => {
    const pageNumber = Math.max(0, page);
    setCurrentPage(() => Math.min(pageNumber, maxPage));
  };

  return { next, prev, jump, currentPage, getCurrentData, setCurrentPage, maxPage, itemsPerPage, setItemsPerPage };
};

usePagination.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
};


export { usePagination };