import React from 'react';
import { SvgIcon } from '@material-ui/core';
import {
  mdiFlower, mdiPineTree, mdiCheckBold, mdiCloseThick, mdiGrid, mdiMap, mdiCloudSync, mdiGoKartTrack, mdiPinOutline, mdiCheck,
  mdiClose, mdiAlert, mdiCloudDownload, mdiLayers, mdiPaw, mdiLayersPlus, mdiMapMarkerPlus, mdiSelectionMultipleMarker,
  mdiMapMarkerMultiple, mdiMushroom, mdiHandshakeOutline, mdiMapSearchOutline, mdiTerrain, mdiChartBar, mdiWebSync, mdiWebCheck,
  mdiTextBoxCheckOutline, mdiNotebookOutline, mdiNotebook, mdiViewColumnOutline, mdiOfficeBuilding, mdiStore, mdiAutoFix, mdiFactory,
  mdiMonitorDashboard, mdiFileSign, mdiTriangle, mdiTriangleDown, mdiFormatListGroup, mdiLinkPlus,
} from '@mdi/js';


const Flower = props => <SvgIcon {...props}><path d={mdiFlower} /></SvgIcon>;
const PineTree = props => <SvgIcon {...props}><path d={mdiPineTree} /></SvgIcon>;
const CheckBold = props => <SvgIcon {...props}><path d={mdiCheckBold} /></SvgIcon>;
const CloseThick = props => <SvgIcon {...props}><path d={mdiCloseThick} /></SvgIcon>;
const MapIcon = props => <SvgIcon {...props}><path d={mdiMap} /></SvgIcon>;
const GridIcon = props => <SvgIcon {...props}><path d={mdiGrid} /></SvgIcon>;
const CloudSyncIcon = props => <SvgIcon {...props}><path d={mdiCloudSync} /></SvgIcon>;
const GoKartTrackIcon = props => <SvgIcon {...props}><path d={mdiGoKartTrack} /></SvgIcon>;
const PinOutlineIcon = props => <SvgIcon {...props}><path d={mdiPinOutline} /></SvgIcon>;
const CheckIcon = props => <SvgIcon {...props}><path d={mdiCheck} /></SvgIcon>;
const CloseIcon = props => <SvgIcon {...props}><path d={mdiClose} /></SvgIcon>;
const AlertIcon = props => <SvgIcon {...props}><path d={mdiAlert} /></SvgIcon>;
const CloudDownloadIcon = props => <SvgIcon {...props}><path d={mdiCloudDownload} /></SvgIcon>;
const LayersIcon = props => <SvgIcon {...props}><path d={mdiLayers} /></SvgIcon>;
const Paw = props => <SvgIcon {...props}><path d={mdiPaw} /></SvgIcon>;
const LayersPlus = props => <SvgIcon {...props}><path d={mdiLayersPlus} /></SvgIcon>;
const MapMarkerPlus = props => <SvgIcon {...props}><path d={mdiMapMarkerPlus} /></SvgIcon>;
const SelectionMultipleMarker = props => <SvgIcon {...props}><path d={mdiSelectionMultipleMarker} /></SvgIcon>;
const MapMarkerMultiple = props => <SvgIcon {...props}><path d={mdiMapMarkerMultiple} /></SvgIcon>;
const Mushroom = props => <SvgIcon {...props}><path d={mdiMushroom} /></SvgIcon>;
const Handshake = props => <SvgIcon {...props}><path d={mdiHandshakeOutline} /></SvgIcon>;
const MapSearch = props => <SvgIcon {...props}><path d={mdiMapSearchOutline} /></SvgIcon>;
const Terrain = props => <SvgIcon {...props}><path d={mdiTerrain} /></SvgIcon>;
const ChartBar = props => <SvgIcon {...props}><path d={mdiChartBar} /></SvgIcon>;
const WebSync = props => <SvgIcon {...props}><path d={mdiWebSync} /></SvgIcon>;
const WebCheck = props => <SvgIcon {...props}><path d={mdiWebCheck} /></SvgIcon>;
const TextBoxCheckOutline = props => <SvgIcon {...props}><path d={mdiTextBoxCheckOutline} /></SvgIcon>;
const NotebookOutline = props => <SvgIcon {...props}><path d={mdiNotebookOutline} /></SvgIcon>;
const Notebook = props => <SvgIcon {...props}><path d={mdiNotebook} /></SvgIcon>;
const ViewColumnOutline = props => <SvgIcon {...props}><path d={mdiViewColumnOutline} /></SvgIcon>;
const OfficeBuilding = props => <SvgIcon {...props}><path d={mdiOfficeBuilding} /></SvgIcon>;
const Store = props => <SvgIcon {...props}><path d={mdiStore} /></SvgIcon>;
const AutoFix = props => <SvgIcon {...props}><path d={mdiAutoFix} /></SvgIcon>;
const Factory = props => <SvgIcon {...props}><path d={mdiFactory} /></SvgIcon>;
const MonitorDashboard = props => <SvgIcon {...props}><path d={mdiMonitorDashboard} /></SvgIcon>;
const FileSign = props => <SvgIcon {...props}><path d={mdiFileSign} /></SvgIcon>;
const Triangle = props => <SvgIcon {...props}><path d={mdiTriangle} /></SvgIcon>;
const TriangleDown = props => <SvgIcon {...props}><path d={mdiTriangleDown} /></SvgIcon>;
const FormatListGroup = props => <SvgIcon {...props}><path d={mdiFormatListGroup} /></SvgIcon>;
const LinkPlus = props => <SvgIcon {...props}><path d={mdiLinkPlus} /></SvgIcon>;


export {
  Flower, PineTree, CheckBold, CloseThick, MapIcon, GridIcon, CloudSyncIcon, GoKartTrackIcon, PinOutlineIcon, CheckIcon,
  CloseIcon, AlertIcon, CloudDownloadIcon, LayersIcon, Paw, LayersPlus, MapMarkerPlus, SelectionMultipleMarker, MapMarkerMultiple,
  Mushroom, Handshake, MapSearch, Terrain, ChartBar, WebSync, WebCheck, TextBoxCheckOutline, NotebookOutline, Notebook, ViewColumnOutline,
  OfficeBuilding, Store, AutoFix, Factory, MonitorDashboard, FileSign, Triangle, TriangleDown, FormatListGroup, LinkPlus,
};