import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Popover, FormGroup, FormControlLabel, Checkbox, Zoom, Divider } from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlankOutlined, ArrowDropDown } from '@material-ui/icons';

import { ChipFilterWrapper } from 'src/components/filter/ChipFilterWrapper';


const FilterCheckbox = ({ id, label, prevValue, onChange, inline = false }) => {

  const [ anchorEl, setAnchorEl ] = useState(null);
  const isOpenPopover = Boolean(anchorEl);
  const popoverId = isOpenPopover ? 'simple-popover' : undefined;

  const openPopup = event => setAnchorEl(event.currentTarget);

  const closePopup = () => setAnchorEl(null);

  const isActive = prevValue;
  const [ isCheckedToActivate, setCheckedToActivate ] = useState(prevValue);

  const toggleCheckToActivate = () => setCheckedToActivate(!isCheckedToActivate);

  const handleConfirm = () => {
    if (inline) {
      onChange({ id, value: !prevValue });
    } else {
      onChange({ id, value: isCheckedToActivate });
      closePopup();
    }
  };

  const handleDelete = () => {
    onChange({ id, value: false });
    setCheckedToActivate(false);
  };

  return (
    <>
      {inline ?
        <ChipFilterWrapper
          isActive={isActive}
          label={label}
          onClick={handleConfirm}
          icon={isActive ?
            <Zoom in><CheckBox fontSize="small" /></Zoom> :
            <CheckBoxOutlineBlankOutlined fontSize="small" />
          }
        /> :
        <>
          <Popover
            id={popoverId}
            open={isOpenPopover}
            anchorEl={anchorEl}
            onClose={closePopup}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <Box px={2} pt={2} pb={1}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox checked={isCheckedToActivate} onChange={toggleCheckToActivate} />
                  }
                  label={label}
                />
                <Box mt={1}>
                  <Divider />
                </Box>
                <Box mt={1} display="flex" alignSelf="flex-end">
                  <Button color="default" size="small" onClick={handleConfirm}>Aplicar</Button>
                </Box>
              </ FormGroup>
            </Box>
          </Popover>
          <ChipFilterWrapper
            isActive={prevValue}
            isFocused={isOpenPopover}
            icon={<ArrowDropDown fontSize="small" />}
            label={label}
            onClick={openPopup}
            {...(prevValue ? { onDelete: handleDelete } : {})}
          />
        </>
      }
    </>
  );
};

FilterCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  prevValue: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  inline: PropTypes.bool,
};


export {
  FilterCheckbox,
};