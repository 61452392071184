import React, { useState } from 'react';
import { Box, Typography, Collapse } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';


const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const Collapser = props => {
  const { children, title, isExpanded = true, Actions, Title, disableExpand = false } = props;
  const classes = useStyles();
  const [ expanded, setExpanded ] = useState(isExpanded);

  const handleExpandClick = () => setExpanded(!expanded);

  return (
    <Box display="flex" flexDirection="column">
      <Box className={classes.root} display="flex" alignItems="center" >
        {!disableExpand && <ExpandMore
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
        />}
        <Box display="flex" width="100%">
          {Title ? <Title onClick={handleExpandClick} /> : <Typography variant="h6" onClick={handleExpandClick}>{title}</Typography>}
          {Actions && expanded && <><Box flexGrow={1} /><Actions /></>}
        </Box>
      </Box>
      <Box mt={1}>
        <Collapse in={expanded} timeout="auto">{children}</Collapse>
      </Box>
    </Box>
  );
};

Collapser.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  isExpanded: PropTypes.bool,
  Actions: PropTypes.func,
  Title: PropTypes.func,
  disableExpand: PropTypes.bool,
};


export { Collapser } ;
