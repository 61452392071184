import React, { Component } from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { LoadingCard } from 'src/components';
import { objectIsEmpty } from 'src/utils/util';


export class SpeciesEECCIndicator extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
    };
  }

  componentDidUpdate = async prevProps => {
    if (!objectIsEmpty(this.props.data) && this.props.data !== prevProps.data) {
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading, error } = this.state;
    const { data } = this.props;
    const { speciesCount, recordsCount } = data;

    return (
      <LoadingCard loading={ loading } error={ error } title="Categoría de conservación"
        info={`Total de especies en categoría de conservación encontradas, una especie en categoría de
          conservación puede tener múltiples registros`}
      >
        {
          !objectIsEmpty(data) ?
            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
              <Box display="flex" alignItems="center">
                <Typography variant="h2" color="error">{speciesCount}</Typography>
                <Box display="flex" ml={1} >
                  <Typography variant="body1" color="error">Especies en categoría de conservación</Typography>
                </Box>
              </Box>
              <Typography noWrap variant="body2" color="textSecondary">{recordsCount} registros en categoría de conservación</Typography>
            </Box>
            :
            <span>Aún no se han registrado especies</span>
        }
      </LoadingCard>
    );
  }
}

SpeciesEECCIndicator.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  info: PropTypes.string,
};
