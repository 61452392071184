import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


// eslint-disable-next-line react/prop-types
const GroupTitle = ({ title }) =>
  <Typography style={{ fontWeight: 800, lineHeight: 2 }} variant="overline" component="div">{title}</Typography>;

const useStyle = makeStyles(theme => ({
  img: {
    marginRight: theme.spacing(1),
  },
  pointAndRITitle: {
    lineHeight: 1,
    textTransform: 'capitalize',
  },
  legendRI: {
    marginRight: '9px',
    color: 'dimgray',
    fontSize: '11px',
    fontWeight: 'bolder',
    lineHeight: '0px',
    marginLeft: '-24px',
    marginTop: '-5px',
  },
  legendFaunaInd: {
    marginRight: '15px',
    color: 'dimgray',
    fontSize: '11px',
    fontWeight: 'bolder',
    lineHeight: '0px',
    marginLeft: '-18px',
    marginTop: '-5px',
  },
  legendAR: {
    color: 'dimgray',
    fontSize: '10px',
    marginTop: '-4px',
    fontWeight: 'bolder',
    lineHeight: '1px',
    marginLeft: '-23px',
    marginRight: '9px',
  },
  legendLine: {
    borderBottom: '5px solid',
    width: '14px',
    marginRight: '7px',
  },
  legendPolygon: {
    marginRight: '7px',
    height: '14px',
    width: '20px',
    border: '2px solid #3388ff',
    backgroundColor: 'rgba(51, 136, 255, 0.2)',
    marginTop: theme.spacing(1) / 2,
  },
  legendRC: {
    marginLeft: '2px',
    marginRight: '10px',
    height: '14px',
    width: '14px',
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
    border: `3px solid ${theme.palette.flora.primary.main}`,
    display: 'inline-block',
  },
}));

const MonitoringMapLegend = props => {
  const { trackLayers = [], pointStatusMapper, interestArea = [], csLang, hasIndividualRecords, hasIsolatedRecords,
    hasIndirectRecords, hasFaunaIndividuals, hasLinealTransect, hasSamplingPoint, hasShermanTrap, hasAerialTransit, hasCameraTrap,
    hasEchoLocationDetection, hasPlayBack, hasCensusRecords,
  } = props;
  const classes = useStyle();
  return (
    <Box p={1}>
      {!hasCensusRecords &&
        <>
          <GroupTitle title={csLang.Points} />
          {Object.values(pointStatusMapper).map((v, i) =>
            <Box key={i}>
              <Box display="flex" alignItems="center">
                <img alt="" className={classes.img} src={`/img/markers/${v.color}.png`} height="25" />
                <Typography className={classes.pointAndRITitle} variant="overline">{v.text}</Typography>
              </Box>
            </Box>)}
        </>
      }

      {(hasLinealTransect || hasSamplingPoint || hasShermanTrap || hasAerialTransit || hasCameraTrap || hasEchoLocationDetection ||
        hasPlayBack) &&
      <>
        <GroupTitle title="Metodologías" />
        {hasLinealTransect &&
          <Box display="flex" alignItems="center">
            <img alt="" className={classes.img} src={`/img/markers/grey.png`} height="25" />
            <Box className={classes.legendRI}>TLx</Box>
            <Typography className={classes.pointAndRITitle} variant="overline">
              Transecto lineal - Clase
            </Typography>
          </Box>
        }
        {hasSamplingPoint &&
          <Box display="flex" alignItems="center">
            <img alt="" className={classes.img} src={`/img/markers/calypso.png`} height="25" />
            <Box className={classes.legendRI}>PM</Box>
            <Typography className={classes.pointAndRITitle} variant="overline">
              Punto de muestreo
            </Typography>
          </Box>
        }
        {hasShermanTrap &&
          <Box display="flex" alignItems="center">
            <img alt="" className={classes.img} src={`/img/markers/black.png`} height="25" />
            <Box className={classes.legendRI}>TS</Box>
            <Typography className={classes.pointAndRITitle} variant="overline">
              Trampas Sherman
            </Typography>
          </Box>
        }
        {hasAerialTransit &&
          <Box display="flex" alignItems="center">
            <img alt="" className={classes.img} src={`/img/markers/purple.png`} height="25" />
            <Box className={classes.legendRI}>TA</Box>
            <Typography className={classes.pointAndRITitle} variant="overline">
              Tránsito Aéreo
            </Typography>
          </Box>
        }
        {hasCameraTrap &&
          <Box display="flex" alignItems="center">
            <img alt="" className={classes.img} src={`/img/markers/blue.png`} height="25" />
            <Box className={classes.legendRI}>TC</Box>
            <Typography className={classes.pointAndRITitle} variant="overline">
              Trampas Cámara
            </Typography>
          </Box>
        }
        {hasEchoLocationDetection &&
          <Box display="flex" alignItems="center">
            <img alt="" className={classes.img} src={`/img/markers/orange.png`} height="25" />
            <Box className={classes.legendRI}>DEL</Box>
            <Typography className={classes.pointAndRITitle} variant="overline">
              Detección de Eco Localizaciones
            </Typography>
          </Box>
        }
        {hasPlayBack &&
          <Box display="flex" alignItems="center">
            <img alt="" className={classes.img} src={`/img/markers/yellow.png`} height="25" />
            <Box className={classes.legendRI}>PBx</Box>
            <Typography className={classes.pointAndRITitle} variant="overline">
              Play Back - Clase
            </Typography>
          </Box>
        }
      </>

      }

      {(hasFaunaIndividuals || hasIndividualRecords || hasIsolatedRecords) &&
        <>
          <GroupTitle title="Registros" />
          {hasFaunaIndividuals &&
            <Box display="flex" alignItems="center">
              <img alt="" className={classes.img} src={`/img/markers/yellow.png`} height="25" />
              <Box className={classes.legendFaunaInd}>I</Box>
              <Typography className={classes.pointAndRITitle} variant="overline">
                Individuo
              </Typography>
            </Box>
          }
          {hasIndividualRecords &&
            <Box display="flex" alignItems="center">
              <img alt="" className={classes.img} src={`/img/markers/nviro-flora.png`} height="25" />
              <Box className={classes.legendRI}>RI</Box>
              <Typography className={classes.pointAndRITitle} variant="overline">
                Registro individual
              </Typography>
            </Box>
          }
          {hasIsolatedRecords &&
            <Box display="flex" alignItems="center">
              <img alt="" className={classes.img} src={`/img/markers/nviro-fauna.png`} height="25" />
              <Box className={classes.legendAR}>RA</Box>
              <Typography className={classes.pointAndRITitle} variant="overline">
                Registro aislado
              </Typography>
            </Box>
          }
          {hasIndirectRecords &&
            <Box display="flex" alignItems="center">
              <img alt="" className={classes.img} src={`/img/markers/purple.png`} height="25" />
              <Box className={classes.legendAR}>RI</Box>
              <Typography className={classes.pointAndRITitle} variant="overline">
                Registro indirecto
              </Typography>
            </Box>
          }
        </>
      }

      {hasCensusRecords &&
        <>
          <GroupTitle title="Registros" />
          <Box display="flex" alignItems="center" mt={0.5}>
            <Box className={classes.legendRC} />
            <Typography className={classes.pointAndRITitle} variant="overline">
              Registro censo
            </Typography>
          </Box>
        </>
      }

      {trackLayers.length > 0 &&
        <>
          <GroupTitle title="Tracks" />
          {trackLayers.map(({ data }, i) =>
            <Box key={i} display="flex" alignItems="center">
              <Box className={classes.legendLine} style={{ borderBottomColor: data.color }} />
              <Typography style={{ textTransform: 'lowercase', lineHeight: 1 }} variant="overline">
                {data.email}
              </Typography>
            </Box>)
          }
        </>
      }
      {interestArea.length > 0 &&
        <>
          <GroupTitle title="Área de interés" />
          {interestArea.map(layer =>
            <Box key={layer.id} display="flex" alignItems="center">
              <Box className={classes.legendPolygon} />
              <Typography style={{ textTransform: 'lowercase', lineHeight: 1 }} variant="overline">
                {layer.name}
              </Typography>
            </Box>)
          }
        </>
      }
    </Box>
  );
};

MonitoringMapLegend.propTypes = {
  trackLayers: PropTypes.array,
  pointStatusMapper: PropTypes.object,
  hasIndividualRecords: PropTypes.bool,
  hasIsolatedRecords: PropTypes.bool,
  hasIndirectRecords: PropTypes.bool,
  hasFaunaIndividuals: PropTypes.bool,
  hasLinealTransect: PropTypes.bool,
  hasCensusRecords: PropTypes.bool,
  hasSamplingPoint: PropTypes.bool,
  hasShermanTrap: PropTypes.bool,
  hasAerialTransit: PropTypes.bool,
  hasCameraTrap: PropTypes.bool,
  hasEchoLocationDetection: PropTypes.bool,
  hasPlayBack: PropTypes.bool,
  interestArea: PropTypes.array,
  csLang: PropTypes.object,
};


export {
  MonitoringMapLegend,
};

