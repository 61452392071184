import React from 'react';
import { InputLabel, Box, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';

import { SelectChip } from 'src/components';
import checkers from 'src/utils/checkers';
import { objectIsEmpty } from 'src/utils/util';


const PhenologiesAndPhytosanitaryStatusesInputs = ({ record, handlers, options, validators = {} }) => {

  const { phenologiesStatuses, phytosanitaryStatuses } = record;
  const { handleSelect } = handlers;
  const { phenologiesStatusesOptions, phytosanitaryStatusesOptions } = options;
  const { isOnlyDead, hasSelectedPhenologies = true } = validators;

  const isFloraIndividualRecord = checkers.isFloraIndividualRecord(record.type);
  const isFloraPointQuadratRecord = checkers.isFloraPointQuadratRecord(record.type);

  const handleChange = (keyField, option) => {
    const hasSelection = Array.isArray(option) ? option.length > 0 : !objectIsEmpty(option);
    handleSelect({ [keyField]: hasSelection ? (isFloraIndividualRecord ? [ option.value ] : option.value) : [] });
  };

  return (
    <>
      <Box my={2}>
        <InputLabel shrink>Estado fitosanitario {isFloraPointQuadratRecord ? '' : '(opcional)'}</InputLabel>
        <SelectChip
          value={isFloraIndividualRecord ? phytosanitaryStatuses[0] : phytosanitaryStatuses}
          options={phytosanitaryStatusesOptions}
          onChange={option => handleChange('phytosanitaryStatuses', option)}
          isMulti={!isFloraIndividualRecord}
          allowEmpty={!isFloraPointQuadratRecord}
        />
      </Box>
      <Box my={2}>
        <InputLabel shrink>Estado fenológico {isFloraPointQuadratRecord ? '' : '(opcional)'}</InputLabel>
        <SelectChip
          value={isFloraIndividualRecord ? phenologiesStatuses[0] : phenologiesStatuses}
          options={phenologiesStatusesOptions}
          onChange={option => handleChange('phenologiesStatuses', option)}
          isMulti={!isFloraIndividualRecord}
          allowEmpty={!isFloraPointQuadratRecord || isOnlyDead}
        />
        {isFloraPointQuadratRecord && !hasSelectedPhenologies && !isOnlyDead &&
          <FormHelperText error>
            Debes ingresar al menos un estado fenológico a menos que el estado fitosanitario sea solo <strong>Muerto</strong>
          </FormHelperText>
        }
      </Box>
    </>
  );
};

PhenologiesAndPhytosanitaryStatusesInputs.propTypes = {
  record: PropTypes.object.isRequired,
  handlers: PropTypes.object,
  options: PropTypes.object,
  validators: PropTypes.objectOf(PropTypes.bool),
};


export { PhenologiesAndPhytosanitaryStatusesInputs };