import React from 'react';
import { Box, Hidden } from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  background: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: '-250px',
    bottom: 0,
    zIndex: 0,
  },
  middleBack: {
    position: 'absolute',
    top: '-660px',
    left: 0,
    right: 0,
    transformOrigin: '0 50%',
    height: '1698px',
    backgroundColor: theme.palette.common.black,
    transform: 'skewY(-25deg)',
    [theme.breakpoints.down('sm')]: {
      top: '-900px',
    },
  },
  nviroB: {
    borderRadius: '6px',
    top: '820px',
    left: 'calc(50% - 800px)',
    right: 'calc(50% + 333px)',
    height: '65px',
    backgroundColor: alpha(theme.palette.primary.main, 0.95),
    position: 'absolute',
    transform: 'skewY(-25deg)',
  },
  nviroT: {
    borderRadius: '6px',
    top: '600px',
    left: 'calc(50% - 800px)',
    right: 'calc(50% + 333px)',
    height: '65px',
    backgroundColor: theme.palette.secondary.main,
    position: 'absolute',
    transform: 'skewY(25deg)',
  },
}));

const CoolBackground = () => {
  const classes = useStyles();
  return (
    <Box className={classes.background}>
      <Box className={classes.middleBack} />
      <Hidden smDown>
        <Box className={classes.nviroB} />
        <Box className={classes.nviroT} />
      </Hidden>
    </Box>
  );
};


export {
  CoolBackground,
};