import React, { useState } from 'react';
import { Box, Typography, Divider, Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { AutocompleteWrapper } from 'src/components';


const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.gray,
  },
  list: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.gray400,
    borderRadius: theme.shape.borderRadius,
  },
  subtitle: {
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
}));

const FormTypeInput = ({ id, onChangeForm, onChangeFields, options }) => {
  const classes = useStyles();

  const selectedFormTypeOption = options.find(fto => fto.value === id);
  const { label, value, fields } = selectedFormTypeOption;

  const [ selectedFieldsIds, setSelectedFieldsIds ] = useState(fields.map(f => f.id));

  const handleChangeForm = (_, { value: id }) => {
    onChangeForm(id);
    setSelectedFieldsIds(options.find(fto => fto.value === id).fields.map(f => f.id));
  };

  const handleChangeField = fieldId => {
    setSelectedFieldsIds(prevFields => {
      const selectedFieldsIds = prevFields.includes(fieldId) ? prevFields.filter(fId => fId !== fieldId) : [ fieldId, ...prevFields ];
      onChangeFields(selectedFieldsIds);
      return selectedFieldsIds;
    });
  };

  return (
    <>
      <Box px={2} py={2} display="flex" flexDirection="column" className={classes.root}>
        <Box>
          <AutocompleteWrapper
            value={{ label, value }}
            options={options}
            onChange={handleChangeForm}
            getOptionSelected={(option, value) => option.value === value.value}
          />
          <Box mt={2} mb={1} display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="body2" className={classes.subtitle}>Campos visibles</Typography>
            <Box ml={1} width="100%"><Divider /></Box>
          </Box>
          <Box display="flex" flexWrap="wrap">
            {fields.map((field, index) => (
              <FormControlLabel
                key={index}
                label={field.label}
                control={
                  <Checkbox checked={selectedFieldsIds.includes(field.id)} onChange={() => handleChangeField(field.id)} color="secondary" />
                }
                disabled={field.required}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

FormTypeInput.propTypes = {
  id: PropTypes.string,
  onChangeForm: PropTypes.func,
  onChangeFields: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    fields: PropTypes.array.isRequired,
  })),
};


export {
  FormTypeInput,
};