import urlJoin from 'url-join';

import config from 'src/config/local';

const { serverUrl } = config;

const notRedirectUrls = [
  'users/reset-password',
  'users/authenticate',
  'users/authenticate-accepting-terms-and-policy',
  'users/verification-token-validity',
  'users/verify-email',
];

const request = async ({ url, method = 'POST', data = {}, options = {} }) => {
  try {
    const finalUrl = new URL(url.match(/^https?:\/\//) ? url : urlJoin(serverUrl, 'api', url));
    const requestOptions = {
      method,
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      credentials: 'include',
      ...options,
    };

    if (method.toLowerCase() === 'get') {
      // Note: no se soportan arreglos dentro del objeto, por lo que se permite enviar el string directamente
      finalUrl.search = typeof data === 'object' ? new URLSearchParams(data) : data;
    } else {
      if (requestOptions.headers['Content-Type'] === 'multipart/form-data') {
        requestOptions.body = data;
        delete requestOptions.headers['Content-Type'];
      } else {
        requestOptions.body = JSON.stringify(data);
      }
    }
    const resp = await fetch(finalUrl, requestOptions);

    const contentType = resp.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      const jsonResp = await resp.json();
      if (localStorage.getItem('maintenance')) {
        localStorage.removeItem('maintenance');
        window.dispatchEvent(new Event('server-maintenance'));
      }
      if (200 <= resp.status && resp.status < 400) {
        return { ...jsonResp, httpStatus: resp.status };
      } else if (resp.status === 401 && !notRedirectUrls.includes(url)) {
        localStorage.clear();
        window.location.href = '/login?needLogin=true';
      } else if (resp.status === 404) {
        window.location.href = '/';
      } else if (resp.status === 503) {
        localStorage.setItem('maintenance', 'on');
        window.dispatchEvent(new Event('server-maintenance'));
        if (window.location.pathname.match(/^\/web(?=$|\/)/)) {
          window.location.href = '/maintenance';
        }
      }
      return Promise.reject({ serverMessage: jsonResp.message, httpStatus: resp.status, data: jsonResp.data });
    } else {
      const textResp = await resp.text();
      console.log(textResp);
      return Promise.reject({ message: 'Error en el tipo de datos esperado' });
    }
  } catch (e) {
    console.log(e);
    if (e.code === 20 && e.message === 'The user aborted a request.') {
      return Promise.reject({ message: 'Se canceló la petición de datos' });
    }
    return Promise.reject({ message: 'Error al intentar comunicarse con el servidor. Por favor revise su conexión o inténtelo más tarde' });
  }
};

const get = (url, data, options) => request({ url, method: 'GET', data, options });
const post = (url, data, options) => request({ url, method: 'POST', data, options });
const patch = (url, data, options) => request({ url, method: 'PATCH', data, options });
const del = (url, data, options) => request({ url, method: 'DELETE', data, options });


export {
  get,
  post,
  patch,
  del,
};
