import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, Paper, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowForward } from '@material-ui/icons';
import { useHistory } from 'react-router';

import { TitleContainer, ContentContainer, Cards, ActionsBox } from 'src/components';
import { monitorApi } from 'src/services';
import { LinkPlus } from 'src/utils/mdIcons';


const useStyles = makeStyles(theme => ({
  addButton: {
    color: theme.palette.common.white,
  },
  paper: {
    padding: theme.spacing(4),
    background: 'none',
    border: `1px solid ${theme.palette.common.gray400}`,
  },
}));

const CompanyLinkedAppsContainer = () => {

  const history = useHistory();

  const [ linkedCompanies, setLinkedCompanies ] = useState([]);
  const [ linkRequests ] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    const fetch = async () => {
      const linkedCompanies = await monitorApi.getLinkedCompanies();
      setLinkedCompanies(linkedCompanies);
    };
    fetch();
  }, []);

  const goToAllowedCampaigns = companyId => history.push(`/web/company/linked-apps/${companyId}/allowed-campaigns`);

  const cardActions = itemData => ([
    {
      Icon: ArrowForward,
      tooltip: 'Ver permisos de campañas',
      onClick: () => goToAllowedCampaigns(itemData.id),
    },
  ]);

  return (
    <Grid container>
      <TitleContainer breadcrumbs={[ 'company', { name: 'Perfil' } ]}>Vinculaciones con Apps</TitleContainer>
      <ContentContainer maxWidth="lg">
        <Box pb={1}>
          <Box mt={4}>
            <Box display="flex">
              <Typography variant="h5" color="textPrimary">Empresas de <strong>Monitor</strong> vinculadas</Typography>
              <Box flexGrow={1}></Box>
              <ActionsBox>
                <Button size="small" color="primary" className={classes.addButton} startIcon={<LinkPlus />}
                  variant="contained" onClick={() => console.log('hola')}>
                  Enviar solicitud de vinculo
                </Button>
              </ActionsBox>
            </Box>
            <Paper className={classes.paper} elevation={0}>
              <Box mt={-1}>
                <Cards values={linkedCompanies} actions={cardActions} variant="grid"/>
              </Box>
            </Paper>
          </Box>
          <Box mt={8}>
            <Typography variant="h5" color="textPrimary">Solicitudes pendientes</Typography>
            <Paper elevation={0} className={classes.paper}>
              {linkRequests.length ?
                <Cards values={linkRequests} variant="condensed" /> :
                <Typography variant="body2">No tienes solicitudes pendientes</Typography>
              }
            </Paper>
          </Box>
        </Box>
      </ContentContainer>
    </Grid>
  );
};


export { CompanyLinkedAppsContainer };