import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, TextField, Collapse } from '@material-ui/core';

import { DialogButton, withAccessControl } from 'src/components';
import { fetchStatusEnum } from 'src/utils/enums';
import { Alert } from '@material-ui/lab';


const { NOT_STARTED, LOADING, SUCCESS, ERROR } = fetchStatusEnum;

const ProjectCreateDialog = ({ actions }) => {

  const [ name, setName ] = useState('');
  const [ errorState, setErrorState ] = useState({ error: false, helperText: '' });

  const [ fetchStatus, setFetchStatus ] = useState(NOT_STARTED);
  const [ errorAlert, setErrorAlert ] = useState({ isOpen: false, message: '' });

  const handleSubmit = async () => {
    const trimmedName = name.trim();
    if (trimmedName.length === 0) {
      setErrorState({ error: true, helperText: 'No puedes crear un proyecto sin nombre' });
    } else {
      const newProject = { name: trimmedName };

      setFetchStatus(LOADING);
      try {
        await actions.createProject(newProject);
        setFetchStatus(SUCCESS);
      } catch (e) {
        setErrorAlert({ isOpen: true, message: e.serverMessage || e.message });
        setFetchStatus(ERROR);
      }
    }
  };

  const handleNameChange = ({ target: { value } }) => setName(value);

  const { error, helperText } = errorState;

  return (
    <>
      <DialogTitle id="form-dialog-title">Crear proyecto</DialogTitle>
      <DialogContent>
        <Box p={1}>
          <TextField label="Nombre del proyecto" name="name" error={ error } helperText={helperText} value={name}
            onChange={ handleNameChange } fullWidth autoFocus autoComplete="off" variant="outlined" size="small"/>
        </Box>
        {fetchStatus === ERROR &&
          <Collapse in={errorAlert.isOpen}>
            <Alert severity="error" onClose={() => setErrorAlert(s => ({ ...s, isOpen: false }))}>
              {errorAlert.message}
            </Alert>
          </Collapse>
        }
      </DialogContent>
      <DialogActions>
        <Box color="text.disabled"><Button disabled={ fetchStatus === LOADING } onClick={actions.closeDialog}>Cancelar</Button></Box>
        <DialogButton fetchStatus={fetchStatus} onClick={ handleSubmit } color="primary" >Guardar</DialogButton>
      </DialogActions>
    </>
  );
};

ProjectCreateDialog.propTypes = {
  actions: PropTypes.object,
};

const ProjectCreateDialogWithAccessControl = withAccessControl({ action: 'project:create' })(ProjectCreateDialog);


export {
  ProjectCreateDialogWithAccessControl as ProjectCreateDialog,
};
