import React from 'react';
import { Box, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { AppHeaderUI, FloraAppMapUI, MobileUI } from 'src/scenes/Home/components';


const FloraAppMapPageUI = () => {
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box py={1}>
      <MobileUI color="white" shadow={!isDownSm}>
        <AppHeaderUI title="Campaña A" backText="Atrás" showContextMenu={false} />
        <FloraAppMapUI />
      </MobileUI>
    </Box>
  );
};


export {
  FloraAppMapPageUI,
};