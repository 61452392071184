import React, { Component } from 'react';
import Header from 'src/components/header/Header';
import PropTypes from 'prop-types';
import { Box, CssBaseline } from '@material-ui/core';
import LogRocket from 'logrocket';

import { usersApi, permissionsApi } from 'src/services';
import { WebContext } from 'src/scenes/web-context';
import { ThemeProvider, withTheme } from '@material-ui/styles';
import themes from 'src/themes';
import { WebRoutes } from 'src/scenes/WebRoutes';
import { campaignSubtypeLanguage } from 'src/utils/componentLanguage';
import { checkPermission } from 'src/utils/checkPermission';
import { MaintenanceAlert } from 'src/components';
import config from 'src/config/local';


class Web extends Component {

  constructor(props) {
    super(props);
    const currentUser = localStorage.getItem('user') !== null ? JSON.parse(localStorage.getItem('user')) : this.logout();
    const selectedCompany = localStorage.getItem('company') !== null ? JSON.parse(localStorage.getItem('company')) : this.logout();

    if (config.logRocketIsOn) {
      LogRocket.identify(currentUser.id, {
        name: `${currentUser.firstName} ${currentUser.lastName}`,
        email: currentUser.email,
      });
    }

    this.state = {
      selectedTheme: themes['nviro'],
      setTheme: app => this.setState({ selectedTheme: themes[app] }),
      currentUser,
      selectedCompany,
      changeCompany: selectedCompany => {
        localStorage.setItem('company', JSON.stringify(selectedCompany));
        this.setState({ selectedCompany });
      },
      censusFields: [],
      setCensusFields: censusFields => this.setState({ censusFields }),
      selectedProject: {}, // { id, name, userId }
      setSelectedProject: selectedProject => this.setState({ selectedProject }),
      selectedCampaign: {},
      campaignSubtypeLanguage: campaignSubtypeLanguage(),
      setSelectedCampaign: selectedCampaign => {
        if (selectedCampaign.companyId && selectedCampaign.companyId !== this.state.selectedCompany.id) {
          throw new Error('La campaña no pertenece a la empresa actualmente seleccionada');
        }
        this.setState({
          selectedCampaign,
          campaignSubtypeLanguage: campaignSubtypeLanguage(selectedCampaign.mainMethodId),
        });
      },
      currentPath: undefined,
      changeCurrentPath: currentPath => this.setState({ currentPath }),
      rolePermissions: [],
      changeRolePermissions: rolePermissions => this.setState({ rolePermissions }),
      campaignRolesPermissions: {},
      changeCampaignRolesPermissions: campaignRolesPermissions => this.setState({ campaignRolesPermissions }),
      hasPermissionFor: (action, options = {}) => checkPermission({ action, webContext: this.state, ...options }),
      setCampaignCounter: counterData => this.setState(ps => ({
        selectedCampaign: {
          ...ps.selectedCampaign,
          recordsCounter: counterData.recordsCounter,
        },
      })),
      checkIfCoordinatesCampaign: () => {
        const campaignCoordinators = this.state.selectedCampaign?.coordinatorsIds;
        const currentUser = this.state.currentUser;
        if (campaignCoordinators && currentUser) {
          return campaignCoordinators.includes(currentUser.id.toString());
        }
        return null;
      },
      checkIfAdminPowers: () => {
        const companyRole = this.state.selectedCompany?.role;
        if (companyRole) {
          return companyRole === 'admin' || companyRole === 'god';
        }
        // null to distinguish between not having the role, and there not being a role to begin with
        return null;
      },
      isFan: () => this.state.selectedCompany?.role === 'fan',
    };
  }

  logout = () => {
    usersApi.logout();
    localStorage.clear();
    return {};
  };

  componentDidMount = async () => {
    const [ { currentPermissions: rolePermissions, emailVerified }, campaignRolesPermissions ] = await Promise.all([
      usersApi.getExtraUserData(),
      permissionsApi.getCampaignRolesPermissions(),
    ]);
    this.setState({ rolePermissions, campaignRolesPermissions, currentUser: { ...this.state.currentUser, emailVerified } });
  };

  render() {
    const { rolePermissions, selectedTheme, selectedCompany: { role: userRole } } = this.state;
    const isGod = userRole === 'god';
    return (
      <WebContext.Provider value={this.state}>
        <ThemeProvider theme={selectedTheme}>
          <CssBaseline />
          <Header />
          <MaintenanceAlert />
          <Box display="flex" style={{ minHeight: 'calc(100vh - 97px)', paddingTop: '97px', backgroundColor: '#f4f6f6' }}>
            {(rolePermissions.length || isGod) && <WebRoutes />}
          </Box>
        </ThemeProvider>
      </WebContext.Provider>
    );
  }
}

Web.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.any,
  theme: PropTypes.object,
};

const WebWithTheme = withTheme(Web);


export { WebWithTheme as WebContainer };
