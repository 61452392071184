import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, Container } from '@material-ui/core';

import { TitleContainer, ContentContainer, ActionsBox, DialogWrap } from 'src/components';
import { CompaniesList, CompanyCreateDialog, CompanyEditDialog, CompanyDeleteDialog } from 'src/scenes/Companies/components';
import { usersApi, companiesApi } from 'src/services';


const CompaniesContainer = () => {

  const [ companiesList, setCompaniesList ] = useState([]);
  const [ dialog, setDialog ] = useState({
    isOpen: false,
    data: {},
    type: '',
  });
  const [ usersOptions, setUsersOptions ] = useState([]);

  useEffect(() => {
    document.title = 'Administración de Empresas - Nviro Capture';
    const fetchData = async () => {
      const [ companiesList, usersList ] = await Promise.all([
        companiesApi.getCompanies(),
        usersApi.getUsers(),
      ]);

      const usersOptions = usersList.map(user => ({ value: user.id, label: user.email }));
      setCompaniesList(companiesList);
      setUsersOptions(usersOptions);
    };
    fetchData();
  }, [ ]);

  const toggleDialog = ({ data = {}, type = '' }) => setDialog({ ...dialog, isOpen: !dialog.isOpen, data, type });

  const closeDialog = () => toggleDialog({});

  const getDialogType = () => {
    switch (dialog.type) {
      case 'create':
        return <CompanyCreateDialog usersOptions={usersOptions} actions={{ closeDialog, createCompany }}
        />;
      case 'delete':
        return <CompanyDeleteDialog actions={{ closeDialog, deleteCompany: () => deleteCompany(dialog.data) }}
        />;
      case 'edit':
        return <CompanyEditDialog company={dialog.data} usersOptions={usersOptions}
          actions={{ closeDialog, editCompany: companyToEdit => editCompany(companyToEdit, dialog.data.id) }}
        />;
      default:
        break;
    }
  };

  const createCompany = async newCompany => {
    const createdCompany = await companiesApi.create(newCompany);
    setCompaniesList([ createdCompany, ...companiesList ]);
    closeDialog();
  };

  const editCompany = async (companyToEdit, companyId) => {
    const updatedCompany = await companiesApi.update(companyId, companyToEdit);
    setCompaniesList(companiesList.map(company => company.id === companyId ? updatedCompany : company));
    closeDialog();
  };

  const deleteCompany = async companyId => {
    await companiesApi.remove(companyId);
    setCompaniesList(companiesList.filter(company => company.id !== companyId));
    closeDialog();
  };

  return (
    <Grid container >
      <TitleContainer maxWidth="xl">
        Empresas
      </TitleContainer>
      <ContentContainer maxWidth="xl">
        <ActionsBox>
          <Button size="small" color="primary" variant="contained" onClick={() => toggleDialog({ type: 'create' })}>
            Agregar empresa
          </Button>
        </ActionsBox>
        <Container maxWidth="xl">
          { companiesList.length ?
            <CompaniesList companiesList={companiesList} actions={{ toggleDialog }} />
            :
            <Typography variant="body1" color="textPrimary">
              No se han añadido empresas.
            </Typography>
          }
        </Container>
      </ContentContainer>
      <DialogWrap maxWidth="xs" fullWidth onClose={ () => toggleDialog({}) } aria-labelledby='form-dialog-title'
        open={ dialog.isOpen }>
        { getDialogType() }
      </DialogWrap>
    </Grid>
  );
};


export {
  CompaniesContainer,
};