import React from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';

import { AlertWrapper, DialogButton } from 'src/components';
import { useFetchStatus } from 'src/hooks';


const ProjectNotExportableDialog = ({ actions }) => {

  const { fetchStatus, errorMessage, handleConfirm, hasError } = useFetchStatus({
    confirmFn: () => actions.projectExportable(),
    closeFn: actions.closeDialog,
  });

  return (
    <>
      <DialogTitle id="form-dialog-title">
        Dejar de permitir exportar proyecto
      </DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Typography variant="body1">
            Esta acción dejara de permitir exportar todos las campañas que pertenezcan al proyecto.
          </Typography>
          < br/>
          <Typography variant="body1">
            ¿Seguro que quieres dejar de permitir exportar el proyecto?
          </Typography>
          {hasError && <AlertWrapper severity="error">{errorMessage}</AlertWrapper>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>
            Sí, dejar de permitir exportar proyecto
        </DialogButton>
      </DialogActions>
    </>
  );
};

ProjectNotExportableDialog.propTypes = {
  actions: PropTypes.object,
};


export {
  ProjectNotExportableDialog,
};
