import React from 'react';
import urljoin from 'url-join';
import PropTypes from 'prop-types';
import { WebContext } from 'src/scenes/web-context';
import { Typography, Grid, Box, Button, InputBase, alpha, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Pagination, PaginationItem } from '@material-ui/lab';
import { FilterList, PostAdd, Search } from '@material-ui/icons';

import { ProjectDeleteDialog, ProjectCreateDialog, ProjectCard, ProjectEditDialog,
  ProjectsFiltersDialog } from 'src/scenes/Projects/components';
import { CompanyDrawer, TitleContainer, ContentContainer, ActionsBox, DialogWrap, AccessControl } from 'src/components';
import { projectsApi, usersApi } from 'src/services';


const useStyles = theme => ({
  content: {
    maxWidth: theme.spacing(100),
  },
  mainContainer: {
    width: 'calc(100% - 240px)',
    justifyContent: 'center',
  },
  filterButton: {
    marginRight: theme.spacing(1),
    minWidth: '30px',
    padding: '4px 7px',
    minHeight: '30px',
  },
  search: {
    position: 'relative',
    borderRadius: '10px',
    backgroundColor: alpha(theme.palette.common.gray, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.gray, 0.25),
    },
    float: 'inline-start',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '210px',
      '&:focus': {
        width: '300px',
      },
    },
  },
  loadingBox: {
    width: '100%',
    paddingTop: theme.spacing(16),
  },
});

class ProjectsContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      projectsList: [],
      totalProjectsCount: 0,
      coordinatorsAndAdmins: [],
      searching: false,
      loading: false,
      dialog: {
        isOpen: false,
        data: {},
        type: '',
      },
      companyDrawerShouldUpdate: { latestProjects: false },
      companyDrawerStopUpdate: () => this.setState({ companyDrawerShouldUpdate: { latestProjects: false } }),
    };

    this.searchTimeout;
  }

  defaultFilters = {
    name: '',
    status: 'all',
    creator: 'anyone',
    component: [ 'flora', 'fauna' ],
  };

  getQueryParams = () => {
    const query = new URLSearchParams(this.props.location.search);

    const tempSize = parseInt(query.get('size') || 5, 10);
    const size = tempSize < 10 ? tempSize : 10;
    const page = parseInt(query.get('page') || 1, 10);

    const name = query.get('name') || undefined;
    const status = query.get('status') || undefined;
    const creator = query.get('creator') || undefined;
    const component = query.getAll('component').length ? query.getAll('component') : undefined;

    return { page, size, name, status, creator, component };
  };

  getAndSetProjects = async () => {
    const queryString = new URLSearchParams(this.props.location.search).toString();
    const { projectsList, totalProjectsCount } = await projectsApi.getProjects(queryString);
    this.setState({ projectsList, totalProjectsCount });
  };

  componentDidMount = async () => {
    document.title = 'Proyectos - Nviro Capture';
    this.setState({ loading: true });
    this.context.changeCurrentPath(undefined);
    this.context.setSelectedProject({});

    const coordinatorsAndAdmins = (await usersApi.getUsersByCompany())
      .filter(({ role }) => [ 'admin', 'coordinator' ].includes(role));
    this.setState({ coordinatorsAndAdmins });

    await this.getAndSetProjects();
    this.setState({ loading: false });
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const sameListButDifferentQuery = prevState.projectsList === this.state.projectsList &&
      prevProps.location.search !== this.props.location.search;

    if (sameListButDifferentQuery) {
      this.setState({ loading: true });
      await this.getAndSetProjects();
      this.setState({ loading: false });
    }
  };

  getDialogType = () => {
    const { dialog } = this.state;
    const commonActions = { closeDialog: this.closeDialog };
    switch (dialog.type) {
      case 'filter':
        return <ProjectsFiltersDialog
          prevFilters={dialog.data}
          defaultFilters={this.defaultFilters}
          actions={{ applyFilters: this.applyFilters, ...commonActions }}
          inPersonalCompany={ this.context.isFan() }
        />;
      case 'create':
        return <ProjectCreateDialog
          actions={{ createProject: this.createProject, ...commonActions }}
        />;
      case 'edit':
        return <ProjectEditDialog
          previousProjectData={dialog.data}
          coordinatorsAndAdminsOptions={ this.state.coordinatorsAndAdmins.map(({ id, email }) => ({ value: id, label: email })) }
          actions={{ editProject: this.editProject, ...commonActions }}
          isOpen={dialog.isOpen}
        />;
      case 'delete':
        return <ProjectDeleteDialog
          actions={ { deleteProject: () => this.deleteProject(dialog.data), ...commonActions }}
          isOpen={dialog.isOpen}
        />;
      default:
        break;
    }
  };

  closeDialog = () => this.setState({ dialog: { isOpen: false, type: '' } });

  toggleDialog = ({ data = {}, type = '' } = {}) =>
    this.setState(prevState => ({ dialog: { isOpen: !prevState.dialog.isOpen, data, type } }));

  createProject = async newProject => {
    const { size } = this.getQueryParams();
    const creator = this.context.currentUser;
    const createdProject = await projectsApi.createProject(newProject);
    createdProject.creatorEmail = createdProject.creatorEmail ?? creator.email;
    createdProject.creatorName = createdProject.creatorName ?? `${creator.firstName} ${creator.lastName}`;

    this.setState(prevState => ({
      projectsList: [ createdProject, ...prevState.projectsList.filter((p, index) => index + 1 < size) ],
      totalProjectsCount: prevState.totalProjectsCount + 1,
      dialog: { isOpen: false },
    }));
  };

  deleteProject = async projectId => {
    try {
      await projectsApi.deleteProject(projectId);

      const { page: currentPage, size } = this.getQueryParams();
      const nextProjectIndex = currentPage * size;
      const { projectsList, totalProjectsCount } = await projectsApi.getProjects({ page: nextProjectIndex, size: 1 });
      const projectInLastPosition = totalProjectsCount >= size ? projectsList : [];

      // retroceder una página si se borró el último elemento a no ser que se esté en la 1ra página
      if (this.state.projectsList.length === 1 && currentPage > 1) {
        this.setState({
          companyDrawerShouldUpdate: { latestProjects: true },
        });
        this.updateFiltersAndPage({ page: currentPage - 1 });
      } else {
        this.setState(prevState => ({
          projectsList: [ ...prevState.projectsList.filter(({ id }) => id !== projectId), ...(projectInLastPosition) ],
          companyDrawerShouldUpdate: { latestProjects: true, latestCampaigns: true },
          totalProjectsCount,
        }));
      }
    } catch (e) {
      console.error(e);
    }
  };

  editProject = async (projectId, projectToEdit) => {
    await projectsApi.editProject(projectId, projectToEdit);
    const userUpdated = this.state.coordinatorsAndAdmins.find(({ id }) => id === projectToEdit.userId);
    const userForProject = { creatorEmail: userUpdated.email, creatorName: `${userUpdated.firstName} ${userUpdated.lastName}` };
    this.setState(prevState => ({
      projectsList: prevState.projectsList.map(project =>
        project.id === projectId ? { ...project, ...userForProject, ...projectToEdit } : project),
      dialog: { isOpen: false },
      companyDrawerShouldUpdate: { latestProjects: true },
    }));
  };

  addFiltersToQuery = (query, filters) => {
    Object.keys(filters).forEach(key => {
      if (Array.isArray(filters[key])) {
        query.delete(key);
        filters[key].forEach(val => query.append(key, val));
      } else if (filters[key] === undefined) {
        query.delete(key);
      } else {
        query.set(key, filters[key]);
      }
    });
  };

  applyFilters = async filters => {
    this.setState({ dialog: { isOpen: false } });
    await this.updateFiltersAndPage({ filters, page: 1 });
  };

  updateFiltersAndPage = async ({ filters = {}, page = 1 }) => {
    const { location, history } = this.props;
    const query = new URLSearchParams(location.search);
    this.addFiltersToQuery(query, { ...filters, page });
    await history.push({ pathname: this.props.location.pathname, search: `?${query.toString()}` });
  };

  goToCampaigns = projectId => {
    usersApi.addLastProjectViewed(projectId);
    this.props.history.push(urljoin(this.props.match.path, `${projectId}`, 'campaigns'));
  };

  handlePage = async (event, page) => {
    const query = new URLSearchParams(this.props.location.search);
    query.set('page', page);
    await this.props.history.push({ pathname: this.props.location.pathname, search: `?${query.toString()}` });
  };

  automaticSearch = e => {
    this.setState({ searching: true });

    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(async () => {
      this.setState({ searching: false });
      await this.applyFilters({ name: e.target.value });
    }, 500);
  };

  render() {
    const { classes } = this.props;
    const { projectsList, totalProjectsCount, dialog,
      companyDrawerShouldUpdate: { latestProjects, latestCampaigns },
      companyDrawerStopUpdate, searching, loading } = this.state;
    const { page, size, name, ...filters } = this.getQueryParams();
    const totalPages = Math.ceil(totalProjectsCount / size);

    return (
      <>
        <CompanyDrawer shouldUpdate={{ latestProjects, latestCampaigns }} stopUpdate={companyDrawerStopUpdate} />
        <Grid container className={classes.mainContainer}>
          <TitleContainer breadcrumbs={[ 'company' ]}>Proyectos</TitleContainer>
          <Box display="flex" flexDirection="column" width="100%">
            <ContentContainer className={classes.content}>
              <ActionsBox>
                <Box className={classes.search}>
                  { !dialog.isOpen ? // se rerenderea para resetear el texto luego de aplicar búsqueda por nombre desde el diálogo
                    <>
                      <Box className={classes.searchIcon}>
                        { searching ? <CircularProgress size={ 15 } /> : <Search/> }
                      </Box>
                      <InputBase
                        placeholder="Nombre del proyecto..."
                        classes={{ root: classes.inputRoot, input: classes.inputInput }}
                        onChange={ this.automaticSearch }
                        inputProps={{ 'aria-label': 'search' }}
                        defaultValue={ name }
                      />
                    </> :
                    <> {/* caja de reemplazo para no dejar vacío cuando haya diálogo */}
                      <Box className={classes.searchIcon}><Search/></Box>
                      <InputBase
                        placeholder="Nombre del proyecto..."
                        classes={{ root: classes.inputRoot, input: classes.inputInput }}
                        inputProps={{ 'aria-label': 'search' }}
                        defaultValue={ name }
                      />
                    </>
                  }
                </Box>
                <Button className={classes.filterButton} size="small" variant="contained" color="primary"
                  onClick={() => this.toggleDialog({ type: 'filter', data: filters })}><FilterList fontSize="small" /></Button>
                <AccessControl action="project:create">
                  <Button size="small" variant="contained" color="primary" startIcon={<PostAdd />}
                    onClick={() => this.toggleDialog({ type: 'create' })} >Crear Proyecto</Button>
                </AccessControl>
              </ActionsBox>
              { searching || loading ?
                <Box display="flex" className={classes.loadingBox} alignItems="center" flexDirection="column">
                  <CircularProgress/>
                </Box>
                : projectsList.length ?
                  <>
                    { projectsList.map(project =>
                      <ProjectCard key={project.id} actions={{ toggleDialog: this.toggleDialog, goToCampaigns: this.goToCampaigns }}
                        undeletable={ project.id === parseInt(this.context.currentUser.onTerrainProject.id, 10) }
                        userIsAdmin={ this.context.checkIfAdminPowers() }
                        project={ {
                          ...project,
                          belongsToUser: this.context.selectedCompany.role === 'admin' ||
                            parseInt(project.userId, 10) === this.context.currentUser.id,
                        }}
                      />)
                    }
                    { totalProjectsCount > size &&
                      <Box pt={3} display="flex" justifyContent="center">
                        <Pagination page={page} count={totalPages} variant="outlined" shape="rounded"
                          color="primary" onChange={this.handlePage} renderItem={item => (<PaginationItem {...item} />) } />
                      </Box>
                    }
                  </> :
                  <Box mt={1}>
                    <Typography variant="body1" color="textPrimary">
                      No se han encontrado proyectos, para crear uno haz clic en el botón &ldquo;Crear proyecto&rdquo;
                    </Typography>
                  </Box>
              }
            </ContentContainer>
          </Box>
        </Grid>
        <DialogWrap maxWidth='xs' fullWidth onClose={this.closeDialog} open={dialog.isOpen}>
          { this.getDialogType() }
        </DialogWrap>
      </>
    );
  }
}

ProjectsContainer.propTypes = {
  history: PropTypes.any,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  classes: PropTypes.object,
};

ProjectsContainer.contextType = WebContext;

const ProjectsContainerWithStyles = withStyles(useStyles)(ProjectsContainer);


export { ProjectsContainerWithStyles as ProjectsContainer };