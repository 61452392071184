import React, { useCallback } from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
  },
}));

const useStylesInputProps = makeStyles(theme => ({
  root: {
    color: theme.palette.common.black,
    boxShadow: `2px 2px 2px ${theme.palette.common.lightGray}`,
    borderRadius: '4px',
  },
  focused: {
    boxShadow: 'none',
  },
  notchedOutline: {
    borderColor: theme.palette.common.lightGray,
  },
}));

const useStylesInputLabel = makeStyles(theme => ({
  root: {
    color: ({ color }) => `${theme.palette[color].main} !important`,
    top: 'auto',
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 600,
    textTransform: 'uppercase',
    transform: 'translate(21px, 20px) scale(1)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8125rem',
      lineHeight: '16px',
    },
  },
  shrink: {
    transform: 'translate(10px, -20px) !important',
  },
}));

const NviroTextField = ({ color = 'primary', InputProps, InputLabelProps, onEnter, ...props }) => {
  const classes = useStyles();

  const onKeyUp = useCallback(e => {
    if (e.key === 'Enter') {
      onEnter();
    }
  }, [ onEnter ]);

  return <TextField
    className={classes.root}
    InputProps={{ classes: useStylesInputProps(), notched: false, color: 'secondary', ...InputProps }}
    InputLabelProps={{ classes: useStylesInputLabel({ color }), ...InputLabelProps }}
    variant="outlined"
    autoComplete="off"
    fullWidth
    {...(onEnter ? { onKeyUp } : {})}
    {...props}
  />;
};

NviroTextField.propTypes = {
  color: PropTypes.string,
  InputProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
  onEnter: PropTypes.func,
};


export {
  NviroTextField,
};