import React, { PureComponent } from 'react';
import { Card, CardHeader, CardContent, CardActions, Button, Box, Tooltip, Chip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';


const useStyles = () => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: props => props.disabled ? 'lightgrey' : 'inherit',
  },
  content: {
    opacity: props => props.disabled ? '50%' : 'inherit',
  },
  actions: {
    color: props => props.disabled ? 'lightgrey' : 'inherit',
  },
});

class Downloadable extends PureComponent {
  render() {
    const { classes } = this.props;
    const { title, description, link, disabled, formats = [], info, warning, beta } = this.props;
    const warningOrInfo = warning || info;
    const betaColor = beta && !disabled ? 'primary' : 'default';

    return (
      <Card className={classes.card}>
        <CardHeader className={classes.title} title={ beta ?
          <Box display='flex' alignItems='center'>
            <Box>{ title }</Box>
            <Box display='flex' alignItems='center' ml={1}>
              <Chip title='Esta funcionalidad aún está en etapa de prueba.' size="small" color={ betaColor } label='BETA'/>
            </Box>
          </Box>
          : <>{ title }</>
        }></CardHeader>
        <CardContent className={classes.content}>{ description }</CardContent>
        <Box flexGrow={1}></Box>
        <CardActions className={classes.actions}>
          <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between' >
            <Box>
              {
                formats.map(format => (
                  <Button key={ format } size="small" href={ `${link}&format=${format}` } color="primary" disabled={disabled}
                    target="_blank"
                  >
                    { format }
                  </Button>
                ))
              }
            </Box>
            { warningOrInfo &&
              <Tooltip title={ warningOrInfo } interactive>
                { info ?
                  <InfoIcon color="disabled" fontSize="small" style={{ cursor: 'pointer' }} /> :
                  <WarningOutlinedIcon fontSize="small" style={{ cursor: 'pointer', color: '#ff9966' }} />
                }
              </Tooltip>
            }
          </Box>
        </CardActions>
      </Card>
    );
  }
}

Downloadable.propTypes = {
  title: PropTypes.string.isRequired,
  // Puede ser un texto o un html/componentes
  description: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]).isRequired,
  link: PropTypes.string.isRequired,
  formats: PropTypes.arrayOf(PropTypes.oneOf([ 'csv', 'xlsx', 'kml', 'zip', 'kmz' ])),
  disabled: PropTypes.bool.isRequired,
  info: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
  warning: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
  classes: PropTypes.object,
  beta: PropTypes.bool,
};

const DownloadableWithStyles = withStyles(useStyles)(Downloadable);


export { DownloadableWithStyles as Downloadable };