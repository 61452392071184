import React, { forwardRef } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({
  'listbox': {
    '& li:last-child': {
      display: 'none',
    },
  },
}));

const emptyOption = { label: undefined, value: undefined };

const AutocompleteWrapper = forwardRef(({ error = false, fixEmptyWarn = false, multiple = false, ...props }, ref) => {
  const classes = useStyles();
  const placeholder = props.placeholder || (props.options && props.options[0]?.label ?
    `Ej: ${props.options[0].label}` :
    'No hay opciones disponibles');
  const propsToUse = props.clearable ? props : { ...props, disableClearable: true };
  delete propsToUse['clearable'];
  return <Autocomplete
    ref={ref}
    {...fixEmptyWarn ? { classes: { listbox: classes.listbox } } : {}}
    getOptionLabel={option => option.label || '' }
    renderInput={params =>
      <TextField {...params} error={error} placeholder={placeholder} variant="outlined" InputLabelProps={{ disableAnimation: true }} />
    }
    size="small"
    multiple={multiple}
    noOptionsText="No hay opciones"
    {...propsToUse}
    options={fixEmptyWarn ? [ ...propsToUse.options, emptyOption ] : propsToUse.options}
  />;
});

AutocompleteWrapper.propTypes = {
  error: PropTypes.bool,
  value: PropTypes.oneOfType([ PropTypes.array, PropTypes.object ]),
  placeholder: PropTypes.string,
  options: PropTypes.array,
  clearable: PropTypes.bool,
  fixEmptyWarn: PropTypes.bool,
  multiple: PropTypes.bool,
};


export { AutocompleteWrapper } ;