import React, { useState } from 'react';
import { Box, InputLabel, TextField, Typography, Divider, Button, FormHelperText } from '@material-ui/core';
import { AddCircleOutline, Delete } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.gray,
  },
  button: {
    whiteSpace: 'nowrap',
    minWidth: 'max-content',
  },
  textField: {
    height: theme.spacing(4),
    fontSize: theme.typography.body2.fontSize,
  },
  list: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.gray400,
    borderRadius: theme.shape.borderRadius,
  },

  listItem: {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.common.gray400,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    '&:last-child': {
      borderBottom: 'none',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.lightGray,
      '& $deleteIcon': {
        visibility: 'visible',
        animationName: '$fadeIn',
        animation: 'linear',
        animationDuration: '300ms',
      },
    },
  },
  itemData: {
    fontWeight: 600,
    marginLeft: theme.spacing(1),
  },

  deleteIcon: {
    cursor: 'pointer',
    visibility: 'hidden',
  },

  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  subtitle: {
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
}));

const emptyStratum = { name: '', height: 0 };

const StratumsInput = ({ value: list = [], onChange }) => {
  const classes = useStyles();

  const [ stratum, setStratum ] = useState(emptyStratum);
  const [ hasBadData, setHasBadData ] = useState(false);

  const hasDuplicatedName = list.some(l => l.name === stratum.name);
  const hasDuplicatedHeight = list.some(l => l.height === stratum.height);

  const handleAddStratum = () => {
    const newStratumList = [ ...list, stratum ];
    if (!hasDuplicatedName && !hasDuplicatedHeight && stratum.name && stratum.height) {
      onChange(newStratumList);
      setHasBadData(false);
    } else {
      setHasBadData(true);
    }
  };

  const handleDelete = indexToDelete => {
    onChange([ ...list.filter((l, index) => index !== indexToDelete) ]);
  };

  const { name, height } = stratum;
  const commonProps = {
    autoComplete: 'off',
    variant: 'outlined',
    size: 'small',
    fullWidth: true,
    required: true,
    InputProps: { className: classes.textField },
  };

  return (
    <>
      <Box px={2} py={2} display="flex" flexDirection="column" className={classes.root}>
        <Box mb={2}>
          <Box className={classes.list}>
            {list.length === 0 && <Box px={2} py={0.5}><Typography variant="body2">No se han agregado estratos</Typography></Box>}
            {list.length > 0 &&
              list
                .sort((a, b) => a.height - b.height)
                .map((stratum, index) =>
                  <Box key={index} display="flex" className={classes.listItem} px={2} py={0.5}>
                    <Box width="45%">
                      <Typography display="inline" variant="body2">Estrato:</Typography>
                      <Typography display="inline" variant="body2" className={classes.itemData}>{stratum.name}</Typography>
                    </Box>
                    <Box width="45%" flexGrow={1}>
                      <Typography display="inline" variant="body2">Altura (cm):</Typography>
                      <Typography display="inline" variant="body2" className={classes.itemData}>{stratum.height}</Typography>
                    </Box>
                    <Delete className={classes.deleteIcon} fontSize="small" onClick={() => handleDelete(index)} />
                  </Box>,
                )
            }
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="body2" className={classes.subtitle}>Agregar estrato</Typography>
          <Box ml={1} width="100%"><Divider /></Box>
        </Box>
        <Box mt={2} display="flex">
          <Box>
            <InputLabel shrink error={hasBadData && (!name || hasDuplicatedName)}>Nombre</InputLabel>
            <TextField
              value={name}
              placeholder="Ej: E1"
              onChange={e => setStratum({ ...stratum, name: e.target.value })}
              {...commonProps}
            />
            {hasBadData && !name && <FormHelperText error>Debes ingresar un nombre</FormHelperText>}
            {hasBadData && hasDuplicatedName && <FormHelperText error>No debes repetir el nombre</FormHelperText>}
          </Box>
          <Box mx={1}>
            <InputLabel shrink error={hasBadData && ((!height && height !== 0) || hasDuplicatedHeight)}>Altura (cm)</InputLabel>
            <TextField
              value={height}
              type="number"
              placeholder="Ej: 10"
              inputProps={{ step: 0.1, min: 0 }} onKeyPress={event => (event?.key === '-' || event?.key === '+') && event.preventDefault()}
              onChange={e => setStratum({ ...stratum, height: e.target.value ? parseFloat(e.target.value) : e.target.value })}
              {...commonProps}
            />
            {hasBadData && (!height && height !== 0) && <FormHelperText error>Debes ingresar una altura</FormHelperText>}
            {hasBadData && hasDuplicatedHeight && <FormHelperText error>No debes repetir la altura</FormHelperText>}
          </Box>
          <Box mt={2}>
            <Button className={classes.button} startIcon={<AddCircleOutline />} size="small" color="primary" onClick={handleAddStratum}>
              Agregar estrato
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

StratumsInput.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  name: PropTypes.string,
};


export {
  StratumsInput,
};