import { useState } from 'react';

import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';


const { LOADING, SUCCESS, NOT_STARTED, ERROR, BAD_DATA } = fetchStatusEnum;

const useFetchStatus = ({ confirmFn, closeFn, validationFn } = {}) => {
  const [ fetchStatus, setFetchStatus ] = useState(NOT_STARTED);
  const [ errorMessage, setErrorMessage ] = useState();

  const hasBadData = fetchStatus === BAD_DATA;
  const hasError = fetchStatus === ERROR;
  const isLoading = fetchStatus === LOADING;

  const handleConfirm = confirmFn ? async () => {
    try {
      setFetchStatus(LOADING);
      const { isValid, errorMessage } = validationFn ? validationFn() : { isValid: true };
      if (isValid) {
        await confirmFn();
        setFetchStatus(SUCCESS);
        if (closeFn) {
          setTimeout(closeFn, 300);
        }
      } else {
        setFetchStatus(BAD_DATA);
        setErrorMessage(errorMessage || 'Tienes campos con datos incorrectos, por favor revisa el formulario e intenta de nuevo');
      }
    } catch (e) {
      console.error(e);
      setErrorMessage(e.serverMessage || e.message || 'Error inesperado, por favor inténtalo más tarde');
      setFetchStatus(ERROR);
    }
  } : null;

  return { fetchStatus, setFetchStatus, setErrorMessage, errorMessage, handleConfirm, fetchStatusEnum, hasBadData, hasError, isLoading };
};


export { useFetchStatus };