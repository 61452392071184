import React, { memo } from 'react';
import { Box, Button } from '@material-ui/core';
import { useLocation } from 'react-router-dom';


// eslint-disable-next-line react/display-name
export const DownloadRedirection = memo(() => {
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const url = query.get('url');

  return (
    <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column' flexGrow={ 1 }>
      <h2>Puedes descargar el archivo pulsando el botón</h2>
      <Button href={ url } color="primary" disabled={ !url } variant='contained'>Descargar</Button>
    </Box>
  );
});
