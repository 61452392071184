import React, { PureComponent } from 'react';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';

import { GraphWrapper } from 'src/components';
import { campaignStatisticsApi } from 'src/services';
import { getRandomColor, addOrReplaceOpacityInColor } from 'src/utils/util';
import { translations as tls } from 'src/utils/translations';


export class SpeciesByClass extends PureComponent {

  apiCall = () => campaignStatisticsApi.getSpeciesByClass(this.props.campaignHash);

  dataToGraphData = data => {
    const colors = data.map(getRandomColor);

    return {
      labels: data.map(d => d.class ? tls[d.class] : 'No reconocido'),
      datasets: [
        {
          data: data.map(d => d.total),
          backgroundColor: colors.map(c => addOrReplaceOpacityInColor(c, 0.7)),
          borderColor: 'white',
          hoverBackgroundColor: colors.map(c => addOrReplaceOpacityInColor(c, 1)),
        },
      ],
    };
  };

  render() {
    return (
      <GraphWrapper
        title='Especies por clase'
        info='Muestra la cantidad de especies por clase'
        apiCall={ this.apiCall }
        dataToGraphData={ this.dataToGraphData }
        chartjsComponent={ Doughnut }
        legend={ { position: 'top' } }
      />
    );
  }
}

SpeciesByClass.propTypes = {
  campaignHash: PropTypes.string.isRequired,
};
