import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, TextField, Zoom } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { withAccessControl } from 'src/components';


const EnvironmentCreateDialog = props => {
  const [ environment, setEnvironment ] = useState({
    name: '',
    description: '',
  });
  const [ error, setError ] = useState('');
  const [ showErrorContainer, setShowErrorContainer ] = useState(false);

  const handleChange = e => {
    setEnvironment(pe => ({ ...pe, [e.target.name]: e.target.value }));
    setError('');
  };

  const handleSubmit = async () => {
    const { name, description } = environment;
    if (name) {
      try {
        await actions.createEnvironment({ name, description });
      } catch (e) {
        setShowErrorContainer(true);
        if (e.serverMessage) {
          setError(e.serverMessage);
        } else {
          setError('Ocurrió un error al tratar de crear el ambiente');
          console.error(e);
        }
      }
    }
  };

  const { actions } = props;
  const { name, description } = environment;
  return (
    <Fragment>
      <DialogTitle id="form-dialog-title">Agregar ambiente</DialogTitle>
      <DialogContent>
        <Box my={3}>
          <Box my={2}>
            <TextField label="Nombre" name="name" value={name} onChange={handleChange}
              fullWidth autoFocus autoComplete="off" />
          </Box>
          <Box my={2}>
            <TextField label="Descripción" name="description" value={description} onChange={handleChange} maxRows="5"
              fullWidth multiline autoComplete="off" />
          </Box>
          {
            showErrorContainer &&
              <Zoom in={ error !== '' } onExited={ () => setShowErrorContainer(false) }>
                <Alert severity="error">{ error }</Alert>
              </Zoom>
          }
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog} color="secondary">Cancelar</Button>
        <Button onClick={handleSubmit} color="primary">Confirmar</Button>
      </DialogActions>
    </Fragment>
  );
};

EnvironmentCreateDialog.propTypes = {
  actions: PropTypes.object.isRequired,
};

const EnvironmentCreateDialogWithAccessControl = withAccessControl({ action: 'environment:create' })(EnvironmentCreateDialog);


export { EnvironmentCreateDialogWithAccessControl as EnvironmentCreateDialog };
