import React, { Component, Fragment } from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';

import { DialogWrap } from 'src/components/dialog/DialogWrap';
import { DialogImagePreviewer } from 'src/components/imagesPreviewer/DialogImagePreviewer';


export class ImagesPreviewer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
    };
  }

  openPreviewer = () => {
    this.setState({ isVisible: true });
  };

  closePreviewer = () => {
    this.setState({ isVisible: false });
  };

  render () {
    const { pictures, title, buttonTitle, ...props } = this.props;
    const { isVisible } = this.state;

    return (
      <Fragment>
        <Button variant='contained' color='secondary' onClick={ this.openPreviewer } disabled={ pictures.length === 0 }>
          { buttonTitle }
        </Button>
        <DialogWrap maxWidth='sm' fullWidth onClose={this.closePreviewer }
          open={ isVisible }>
          <DialogImagePreviewer
            pictures={ pictures }
            title={ title }
            { ...props }
          />
        </DialogWrap>
      </Fragment>
    );
  }
}

ImagesPreviewer.defaultProps = {
  buttonTitle: 'Ver',
};

ImagesPreviewer.propTypes = {
  buttonTitle: PropTypes.string,
  pictures: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
};
