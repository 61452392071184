import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemText, ListSubheader, ListItemIcon, Divider, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Terrain, Description, BugReport } from '@material-ui/icons';

import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { DrawerWrapper } from 'src/components';
import { truncateWithEllipsis as twe } from 'src/utils/util';
import { MapSearch, MonitorDashboard } from 'src/utils/mdIcons';
import urlJoin from 'url-join';


const useStyles = makeStyles(theme => ({
  titleBox: {
    height: '93px',
    textOverflow: 'ellipsis',
  },
  title: {
    color: theme.palette.common.gray800,
    lineHeight: 1.25,
    fontWeight: 500,
  },
  overTitle: {
    lineHeight: 1,
  },
  listItemSelected: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: theme.palette.common.white,
  },
}));

const itemList = [
  {
    Icon: MonitorDashboard,
    label: 'Dashboard',
    endPath: 'summary',
  },
  {
    Icon: Terrain,
    label: 'Campañas',
    endPath: 'campaigns',
  },
  {
    Icon: MapSearch,
    label: 'Planes de monitoreo',
    endPath: 'monitoring-plans',
    subPaths: [ 'comparison', 'analytics', 'groups' ],
  },
  // {
  //   Icon: Handshake,
  //   label: 'Compromisos',
  //   endPath: 'compromises',

  // },
  // {
  //   Icon: Description,
  //   label: 'RCA',
  //   endPath: 'rca',
  // },
  {
    Icon: BugReport,
    label: 'Incidencias',
    endPath: 'issues',
  },
  {
    Icon: Description,
    label: 'Fuentes de datos',
    endPath: 'import',
  },
];

const getIndex = currentPathname => itemList.findIndex(item => {
  const lastString = currentPathname.split('/').pop();
  return item.endPath === lastString || item.subPaths?.includes(lastString);
});

const AuditableUnitDrawer = ({ name }) => {
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const { pathname } = useLocation();

  const [ selectedIndex, setSelectedIndex ] = useState(getIndex(pathname));

  const { auditableUnitId, titleHolder, companyId } = match.params;

  const isCompany = Boolean(companyId);

  const baseUrl = isCompany ?
    `/web/sma/company/${companyId}`
    : `/web/sma/title-holder/${titleHolder}/auditable-units/${auditableUnitId}`;

  const handleSelectedIndex = index => () => {
    history.push(urlJoin(baseUrl, itemList[index].endPath));
    setSelectedIndex(index);
  };

  useEffect(() => {
    setSelectedIndex(getIndex(pathname));
  }, [ pathname ]);

  const commonListItemProps = { button: true, component: 'a', classes: { selected: classes.listItemSelected } };

  return (
    <DrawerWrapper>
      <Box mt={12}>
        <Box px={2} py={1} display="flex" flexDirection="column" justifyContent="center" className={classes.titleBox}>
          <Typography variant="caption" className={classes.overTitle}>{isCompany ? 'Compañia' : 'Unidad fiscalizable'}</Typography>
          <Typography className={classes.title} variant="body1">{twe(name, 70)}</Typography>
        </Box>
        <Divider />
        <List>
          <ListSubheader color="primary">Acciones</ListSubheader>
          {itemList.map(({ Icon, label }, itemIndex) => (
            <ListItem
              key={itemIndex}
              selected={selectedIndex === itemIndex}
              onClick={handleSelectedIndex(itemIndex)} {...commonListItemProps}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={label} />
            </ListItem>
          ))}
        </List>
      </Box>
    </DrawerWrapper>
  );
};

AuditableUnitDrawer.propTypes = {
  name: PropTypes.string,
};


export { AuditableUnitDrawer };