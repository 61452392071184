import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';


const ChartUI = ({ title, data, options, legend, chart: Chart, height }) => (
  <Box display="flex" flexDirection="column" p={2} height="100%">
    <Box display="flex" alignItems="center">
      <Typography noWrap variant="subtitle2" color="textSecondary">{title}</Typography>
    </Box>
    <Box display="flex" alignItems="center" pt={3} px={3} { ...(height ? { height: height - 93 } : {})}>
      <Chart data={data} options={options} legend={legend} />
    </Box>
  </Box>
);

ChartUI.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
  options: PropTypes.object,
  chart: PropTypes.object,
  legend: PropTypes.object,
  height: PropTypes.number,
};


export { ChartUI };