import React, { PureComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';

import { GraphWrapper } from 'src/components';
import { upperCaseFirstLetter } from 'src/utils/formatters';
import { getRandomColor, addOrReplaceOpacityInColor } from 'src/utils/util';
import { campaignStatisticsApi } from 'src/services';
import { translations as tls } from 'src/utils/translations';

export class FamiliesByClass extends PureComponent {

  apiCall = () => campaignStatisticsApi.getFamiliesByClass(this.props.campaignHash);

  dataToGraphDataClasses = data => {
    const colors = data.map(getRandomColor);
    const datasets = data.map((c, i) => ({
      data: c.families.map(({ name, amount }) => ({ x: upperCaseFirstLetter(name), y: amount })),
      label: c.class ? tls[c.class] : 'No reconocido',
      backgroundColor: addOrReplaceOpacityInColor(colors[i], 0.7),
      borderColor: 'white',
      hoverBackgroundColor: addOrReplaceOpacityInColor(colors[i], 1),
      fill: false,
    }));
    return { datasets };
  };

  render() {
    return (
      <GraphWrapper
        title={`Especies por familia y clase`}
        info={`Muestra la cantidad de especies distintas en las familias de cada clase`}
        apiCall={ this.apiCall }
        options={
          {
            interaction: {
              mode: 'point',
            },
            scales: {
              y: {
                title: {
                  display: true,
                  text: 'Cantidad de Especies',
                },
                ticks: {
                  stepSize: 1,
                },
                stacked: true,
              },
              x: {
                stacked: true,
                title: {
                  display: true,
                  text: 'Familias',
                },
                ticks: {
                  font: {
                    style: 'italic',
                  },
                },
              },
            },
          }
        }
        dataToGraphData={ this.dataToGraphDataClasses }
        chartjsComponent={ Bar }
        legend={ { position: 'top' } }
      />
    );
  }
}

FamiliesByClass.propTypes = {
  campaignHash: PropTypes.string.isRequired,
};
