import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import formatters from 'src/utils/formatters';
import { PopupObservation } from 'src/scenes/Campaign/scenes/Monitoring/components/PopupObservation';


const useStyles = makeStyles(() => ({
  family: {
    margin: '1px 0!important',
    fontStyle: 'italic',
  },
  email: {
    margin: '1px 0!important',
    fontSize: '14px',
  },
  subspecies: {
    marginTop: '-4px!important',
    marginBottom: '0px!important',
  },
  variety: {
    marginTop: '-4px!important',
    marginBottom: '0px!important',
  },
  observation: {
    marginTop: '15px',
  },
}));
const IndividualRecordPopup = ({ individualRecord }) => {
  const { email, observation, family, genus = '', species, subspecies, variety } = individualRecord;
  const classes = useStyles();

  return <Fragment>
    <Card elevation={0}>
      <CardContent style={{ padding: '5px' }}>
        <Typography className={ classes.family } color="textPrimary" gutterBottom>
          {family}
        </Typography>
        <Typography style={{ fontStyle: 'italic' }} variant="h5" component="h3">
          {genus.trim() ? formatters.upperCaseFirstLetter(genus) : ''} {species}
        </Typography>
        {subspecies && <Typography className={ classes.subspecies } variant="overline" component="p">
          Subespecie: {subspecies}
        </Typography>}
        {variety && <Typography className={ classes.variety } variant="overline" component="p">
          Variedad: {variety}
        </Typography>}
        <Typography className={ classes.email } color="textPrimary">
          {email}
        </Typography>
        <PopupObservation observation={observation}/>
      </CardContent>
    </Card>
  </Fragment>;
};

IndividualRecordPopup.propTypes = {
  individualRecord: PropTypes.object,
};


export {
  IndividualRecordPopup,
};
