import React, { Fragment } from 'react';
import { Icon, Box, Typography, DialogContent, DialogActions, Button, DialogTitle } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { LayersPlus, MapMarkerPlus, SelectionMultipleMarker, MapMarkerMultiple } from 'src/utils/mdIcons';


const useStyles = makeStyles({
  imageIcon: {
    height: '85%',
    width: '85%',
  },
  iconRoot: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  glossaryItem: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.7em',
  },
  glossaryText: {
    marginLeft: '1em',
  },
  helpParagraph: {
    marginTop: '0.5em',
  },
});

const HelpDialog = props => {
  const { actions, cLang, userIsFan } = props;
  const iconStyle = { marginLeft: '-3px', marginTop: '2px', color: '#484848' };
  const classes = useStyles();

  return (
    <Fragment>
      <DialogTitle id="form-dialog-title">Ayuda</DialogTitle>
      <DialogContent>
        <Typography variant='body2' color="textPrimary">
          En este mapa puedes colocar marcadores para indicar lugares de estudio ({ cLang.points }),
          y luego seleccionarlos para editar los datos asociados.
        </Typography>

        { !userIsFan &&
          <Typography variant='body2' color="textPrimary" className={ classes.helpParagraph }>
            Para proseguir se debe asignar a cada { cLang.point } a alguien que se encargue de ella.
          </Typography>
        }

        <Box mt={2}>
          <Typography className={ classes.glossaryItem } variant="body2" color="textPrimary">
            <Icon classes={{ root: classes.iconRoot }}>
              <LayersPlus fontSize="small" style={iconStyle} />
            </Icon>
            <span className={ classes.glossaryText }>Importar área de interés a través de un archivo KML o KMZ.</span>
          </Typography>

          <Typography className={ classes.glossaryItem } variant="body2" color="textPrimary">
            <Icon classes={{ root: classes.iconRoot }}>
              <SelectionMultipleMarker fontSize="small" style={iconStyle} />
            </Icon>
            <span className={ classes.glossaryText }>
              Herramienta de selección de { cLang.points }. Puedes dibujar un polígono que envuelva las { cLang.points } cuyos
              datos quieras editar.
            </span>
          </Typography>

          <Typography className={ classes.glossaryItem } variant="body2" color="textPrimary">
            <Icon classes={{ root: classes.iconRoot }}>
              <MapMarkerMultiple fontSize="small" style={iconStyle} />
            </Icon>
            <span className={ classes.glossaryText }>
              Crear marcadores. Pueden luego ser seleccionados con un clic o mediante la herramienta de selección de { cLang.points }.
            </span>
          </Typography>

          <Typography className={ classes.glossaryItem } variant="body2" color="textPrimary">
            <Icon classes={{ root: classes.iconRoot }}>
              <MapMarkerPlus fontSize="small" style={iconStyle} />
            </Icon>
            <span className={ classes.glossaryText }>Importar { cLang.points } a través de un archivo KML o KMZ.</span>
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog} color="default">Cerrar</Button>
      </DialogActions>
    </Fragment>
  );
};


HelpDialog.propTypes = {
  actions: PropTypes.object,
  cLang: PropTypes.object,
  userIsFan: PropTypes.bool,
};


export { HelpDialog };
