const speciesClassByMethodologyType = {
  'lineal-transect-amphibians': [ 'amphibia' ],
  'lineal-transect-birds': [ 'aves' ],
  'lineal-transect-mammals': [ 'mammalia' ],
  'lineal-transect-reptiles': [ 'reptilia' ],
  'lineal-transect-mixed': [ 'amphibia', 'aves', 'mammalia', 'reptilia' ],
  'sampling-point': [ 'amphibia', 'aves', 'mammalia', 'reptilia' ],
  'aerial-transit': [ 'aves' ],
  'echo-location-detection': [ 'mammalia' ],
  'play-back-amphibians': [ 'amphibia' ],
  'play-back-birds': [ 'aves' ],
  'camera-traps': [ 'amphibia', 'aves', 'mammalia', 'reptilia' ],
  'sherman-traps': [ 'amphibia', 'aves', 'mammalia', 'reptilia' ],
};


export {
  speciesClassByMethodologyType,
};