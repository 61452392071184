import React, { useState } from 'react';
import { DialogContent, Box, Grid, DialogActions, Button, DialogTitle, Chip, Typography, Collapse } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';

import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';
import { DialogButton } from 'src/components';


const { NOT_STARTED, LOADING, SUCCESS, ERROR } = fetchStatusEnum;

const todoStatus = 'todo';

const PointsReviewDialog = ({ actions, selectedPoints, csLang, catalogName }) => {

  const [ fetchStatus, setFetchStatus ] = useState(NOT_STARTED);
  const [ errorAlert, setErrorAlert ] = useState({ isOpen: false, message: '' });

  const hasSpeciesUncorrected = selectedPoints.some(p => p.recordCount.other);
  const hasTodoPoints = selectedPoints.some(p => p.statusId === todoStatus);

  const hasSomePending = hasSpeciesUncorrected || hasTodoPoints;

  const handleConfirm = async () => {
    try {
      setFetchStatus(LOADING);
      await actions.editPoints({ reviewed: true });
      setFetchStatus(SUCCESS);
      setTimeout(() => actions.toggleDialog(), 300);
    } catch (e) {
      setErrorAlert({ isOpen: true, message: e.serverMessage || e.message });
      setFetchStatus(ERROR);
    }
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">Marcar como revisadas las {csLang.points}</DialogTitle>
      <>
        <DialogContent>
          <Box p={1}>
            <Typography color="textPrimary" variant="body1">
              Las {csLang.points} seleccionadas son:
            </Typography>
            <Box mb={3}>
              <Grid container justifyContent="center" alignItems="center"
                style={{ ...(selectedPoints.length > 40 ? { overflowY: 'scroll' } : {}), maxHeight: '216px' }}>
                {selectedPoints.map(point =>
                  <Chip key={point.id} label={point.statusId === todoStatus ? <strong><u>{point.name}</u></strong> : point.name} />,
                )}
              </Grid>
            </Box>
            <br />
            {hasSomePending &&
              <>
                {hasTodoPoints &&
                  <Typography color="textPrimary" variant="body1">
                    Se han seleccionado <Typography display="inline" color="error" component="span">{csLang.points} pendientes</Typography>,
                    antes de marcarlas como revisadas debes <strong>sincronizar los datos capturados desde el móvil</strong>.
                  </Typography>
                }
                {hasSpeciesUncorrected &&
                  <Box mt={1}>
                    <Typography color="textPrimary" variant="body1">
                      {hasTodoPoints ? 'Además se' : 'Se'}&nbsp;
                      han seleccionado <Typography display="inline" color="error" component="span">{csLang.points} que contienen especies
                      que no pertenecen a {catalogName}</Typography>, antes de marcarlas como revisadas debes <strong>corregir los nombres
                      de especies</strong>.
                    </Typography>
                  </Box>
                }
              </>
            }
            <br />
            <Typography color="textPrimary" variant="body1">
              ¿Realmente deseas marcar como revisadas la(s) {csLang.points}(s) {hasSomePending && 'de todos modos'} ?
            </Typography>
            <br />
            {fetchStatus === ERROR &&
              <Collapse in={errorAlert.isOpen}>
                <Alert severity="error" onClose={() => setErrorAlert(s => ({ ...s, isOpen: false }))}>
                  {errorAlert.message}
                </Alert>
              </Collapse>
            }
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={actions.toggleDialog} color="default">Cancelar</Button>
          <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>Confirmar</DialogButton>
        </DialogActions>
      </>
    </>
  );
};

PointsReviewDialog.propTypes = {
  actions: PropTypes.object,
  selectedPoints: PropTypes.array,
  csLang: PropTypes.object,
  catalogName: PropTypes.string,
};


export { PointsReviewDialog };