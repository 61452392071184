import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';


const UserUnlinkDialog = props => {
  const { actions } = props;
  return (
    <Fragment>
      <DialogTitle id="form-dialog-title">Desvincular usuario</DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Typography color="textPrimary" variant="body2">
            Esta acción desasignará todas las campañas del usuario
          </Typography>
          <Typography color="textPrimary" variant="body2">
            ¿Realmente deseas desvincular al usuario?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <Button onClick={actions.unlinkUser} color="primary">Desvincular</Button>
      </DialogActions>
    </Fragment>
  );
};

UserUnlinkDialog.propTypes = {
  actions: PropTypes.object.isRequired,
};


export { UserUnlinkDialog };
