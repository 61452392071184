import React, { useState, useCallback } from 'react';
import { DialogContent, DialogActions, Button, DialogTitle, Box, InputLabel,
  TextField, Checkbox, FormHelperText, Typography, Collapse } from '@material-ui/core';
import { Remove, Clear } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { DateTimePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';

import { SelectChip, SelectAsync, CoordinatesInput, PhotoInput, PopoverWrapper, LabelChip, AlertWrapper,
  DialogButton } from 'src/components';
import { DirectRecordInputs, IndirectRecordInputs, TransitRecordInputs, AgeStratumAndSexInputs }
  from 'src/scenes/Campaign/scenes/Records/components/FaunaRecordInputs';
import { speciesClassByMethodologyType } from 'src/utils/speciesClassByMethodologyType';
import { formatDate as fd, formatDateExtension as fde, humanReadableArrayOfStrings as hraos, isEmpty } from 'src/utils/util';
import { translations as tls } from 'src/utils/translations';
import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';
import { recordTypesByMethodologies } from 'src/utils/recordTypesByMethodologies';
import checkers from 'src/utils/checkers';


const { NOT_STARTED, LOADING, SUCCESS, ERROR, BAD_DATA } = fetchStatusEnum;

const emptyOption = { label: '', value: '' };

const FaunaRecordCreateDialog = props => {
  const { actions, options, getOptions, preselectedData, ctLang } = props;
  const { recordsTypesOptions, indirectRecordsTypesOptions, sexTypesOptions, ageStratumsOptions,
    cardinalPointsOptions, methodologiesTypesOptions, flightTypes } = options;
  const { getPointsOptions, getMethodologiesOptions, getSpeciesOptions } = getOptions;
  const [ record, setRecord ] = useState({
    number: 1,
    observation: '',
    type: recordsTypesOptions[0].value,
    ageStratumId: ageStratumsOptions[0].value,
    sexId: sexTypesOptions[0].value,
    typeId: '', // no confundir con 'type', este es el tipo de registro indirecto
    recognizable: true,
    height: 0,
    detectionDate: new Date(),
    individuals: [],
  });

  const [ coords, setCoords ] = useState({ lat: -33.4679997, lng: -70.7003513, accuracy: 0, altitude: 0 });

  const [ forceUpdate, setForceUpdate ] = useState(false);
  const [ errorAlert, setErrorAlert ] = useState({ isOpen: false, message: '' });

  const [ fetchStatus, setFetchStatus ] = useState(NOT_STARTED);
  const hasBadData = fetchStatus === BAD_DATA;

  const handleChange = e => {
    e.persist();
    setRecord(prevRecord => ({ ...prevRecord, [e.target.name]: e.target.value }));
  };

  const handleSelect = obj => setRecord(prevRecord => ({ ...prevRecord, ...obj }));

  const [ selectedOptions, setSelectedOptions ] = useState({
    point: preselectedData?.pointSelected || emptyOption,
    methodology: preselectedData?.methodologySelected || emptyOption,
    species: emptyOption,
  });

  const setSelectedOption = optionKey =>
    ({ label, value, ...others }) => setSelectedOptions(prevOptionsGroup => ({
      ...prevOptionsGroup,
      [ optionKey ]: { label, value, ...others },
    }));

  const { point: selectedPoint, methodology: selectedMethodology, species: selectedSpecies } = selectedOptions;

  const exclusiveConditions = () => (
    isFaunaDirectRecord ? !isNumberUnderOne
    : isFaunaIndirectRecord ? hasCoords && hasSelectedIndirectRecordType && isRecognizableSpecies ? true : hasObservation
    : isFaunaIsolatedRecord ? hasCoords && hasAgeStratumId && hasSexId
    : isFaunaTransitRecord ? isNumberBetween1And500 &&
    (isHeightBetween0And1000 || (isMinHeightBetween0And1000 && isMaxHeightBetween0And1000 && isValidMinMax)) && hasFrom && hasTowards &&
      isDetectionDateBetweenSomeInterval
    : isFaunaPresenceRecord ? (isEvidenceRequired ? hasEvidence : true)
    : true
  );

  const handleConfirm = async () => {
    try {
      setFetchStatus(LOADING);
      if (
        (isMethodologyRequired ? hasSelectedPoint && hasSelectedMethodology : true) &&
        (isDetectionDateRequired ? hasDetectionDate : true) &&
        (isRecognizableSpecies ? hasSelectedSpecies : true) &&
        exclusiveConditions()
      ) {
        await actions.createRecord({
          ...record,
          speciesName: selectedSpecies.value ? undefined : selectedSpecies.label,
          ...(record.imgFilesData && { imgFilesData: JSON.stringify(record.imgFilesData) }),
          pointId: selectedPoint.value,
          methodologyId: selectedMethodology.value,
          ...(isRecognizableSpecies ? { speciesId: selectedSpecies.value } : {}),
          typeId: record.typeId,
          latitude: coords.lat,
          longitude: coords.lng,
          altitude: coords.altitude,
          accuracy: coords.accuracy,
          detectionDate: record.detectionDate === null ? undefined : record.detectionDate,
          ...isFaunaTransitRecord ? selectedRange ? { height: undefined } : { maxHeight: undefined, minHeight: undefined } : {},
        });
        setFetchStatus(SUCCESS);
        setTimeout(() => actions.closeDialog(), 300);
      } else {
        setFetchStatus(BAD_DATA);
      }
    } catch (e) {
      console.error(e);
      setErrorAlert({ isOpen: true, message: e.serverMessage || e.message });
      setFetchStatus(ERROR);
    }
  };

  const { number, height, minHeight, maxHeight,
    selectedRange: prevSelectedRange, observation, detectionDate } = record;
  const { methodologyType } = selectedMethodology;

  const selectedRange = prevSelectedRange === undefined ?
    (height === null && (minHeight !== null && maxHeight !== null)) :
    prevSelectedRange;

  const isFaunaDirectRecord = checkers.isFaunaDirectRecord(record.type);
  const isFaunaIndirectRecord = checkers.isFaunaIndirectRecord(record.type);
  const isFaunaIsolatedRecord = checkers.isFaunaIsolatedRecord(record.type);
  const isFaunaTransitRecord = checkers.isFaunaTransitRecord(record.type);
  const isFaunaPresenceRecord = checkers.isFaunaPresenceRecord(record.type);

  const hasSelectedPoint = Boolean(selectedPoint.value);
  const hasSelectedMethodology = Boolean(selectedMethodology.value);
  const hasSelectedSpecies = Boolean(selectedSpecies.label);
  const isMethodologyRequired = !isFaunaIsolatedRecord;
  const isMethodologyChangeable = !hasSelectedPoint && !hasSelectedMethodology;
  const isRecognizableSpecies = record.recognizable;
  const hasSelectedIndirectRecordType = Boolean(record.typeId);
  const isNumberUnderOne = number < 1;
  const hasObservation = Boolean(record.observation);
  const hasAgeStratumId = Boolean(record?.ageStratumId);
  const hasSexId = Boolean(record?.sexId);
  const isNumberBetween1And500 = 1 <= number && number <= 500;
  const isHeightBetween0And1000 = !selectedRange && height ? (0 <= height && height <= 1000) : false;
  const isMinHeightBetween0And1000 = selectedRange && minHeight ? (0 <= minHeight && minHeight <= 1000) : false;
  const isValidMinMax = minHeight && maxHeight ? minHeight <= maxHeight : true;
  const isMaxHeightBetween0And1000 = selectedRange && maxHeight ? (0 <= maxHeight && maxHeight <= 1000) : false;
  const hasFrom = Boolean(record?.from);
  const hasTowards = Boolean(record?.towards);
  // Nota: cuando un registro de presencia se usa con fecha null es porque es un registro potencial
  const isPotentialSpecies = false;
  const isDetectionDateRequired = !isPotentialSpecies;
  const hasDetectionDate = detectionDate instanceof Date;
  const isDetectionDateBetweenSomeInterval = isFaunaTransitRecord && selectedMethodology.transitIntervals ?
    selectedMethodology.transitIntervals.some(ti => new Date(ti.startDate) <= detectionDate && detectionDate <= new Date(ti.endDate)) :
    true;
  const isEvidenceRequired = methodologyType === 'camera-traps';
  const hasEvidence = record.imgFiles?.length > 0;
  const isPointOptionDisabled = Boolean(preselectedData?.pointSelected);
  const isMethodologyOptionDisabled = Boolean(preselectedData?.methodologySelected);
  const hasMoreThanOneRecordType = !isFaunaIsolatedRecord && recordTypesByMethodologies[methodologyType].length > 1;
  const hasCoords = !isEmpty(coords.lat) && !isEmpty(coords.lng);

  const getMethodologiesOptionsWithFilters = ({ queryText }) =>
    getMethodologiesOptions({ queryText, recordType: record.type, ...(selectedPoint.label ? { point: selectedPoint.value } : {}) });

  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const handlePopoverOpen = event => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);
  const isOpenPopover = Boolean(anchorEl);

  const handleClearDate = e => {
    e.stopPropagation();
    handleSelect({ detectionDate: null });
  };

  const handleImagesChange = useCallback(({ imgFiles, imgFilesData }) => setRecord(pr =>
    ({ ...pr, imgFiles, ...(imgFilesData && { imgFilesData }) }),
  ), []);

  return (
    <>
      <DialogTitle id='form-dialog-title'>
        {`Crear registro ${isFaunaIsolatedRecord ? 'aislado' : '' }`}
      </DialogTitle>
      <DialogContent>
        <Box p={1}>
          {!isMethodologyChangeable &&
              <AlertWrapper severity="info">
                Estás creando un nuevo registro en la {ctLang.point} <strong>{selectedPoint.label}</strong> para la
                metodología <strong>{selectedMethodology.label}</strong> de tipo &nbsp;
                <strong>{(methodologiesTypesOptions.find(m => m.value === selectedMethodology.methodologyType)).label.toLowerCase()}
                </strong>
              </AlertWrapper>
          }
          {isMethodologyRequired && isMethodologyChangeable && <>
            <Box my={2}>
              <InputLabel required shrink>{ctLang.Point}</InputLabel>
              <SelectAsync
                id="select-points"
                value={selectedOptions.point}
                onChange={
                  pointSelected => {
                    if (pointSelected) {
                      setSelectedOption('point')(pointSelected);
                      if (hasSelectedMethodology && pointSelected.value !== selectedMethodology.samplingStationId) {
                        setSelectedOption('methodology')(emptyOption);
                      }
                    }
                    setForceUpdate(selectedOptions.point.value);
                  }
                }
                {...(isPointOptionDisabled ? {} : { getOptions: ({ queryText }) => getPointsOptions({ queryText }) })}
                placeholder={`Elige una ${ctLang.point}`}
                noOptionsText="No se encontraron estaciones de muestreo"
                error={hasBadData && !hasSelectedPoint}
                disabled={isPointOptionDisabled}
              />
              {hasBadData && !hasSelectedPoint && <FormHelperText error>Debes seleccionar una {ctLang.point}</FormHelperText>}
            </Box>
            <Box my={2}>
              <InputLabel required shrink>Metodología</InputLabel>
              <SelectAsync
                id="select-methodologies"
                value={selectedMethodology}
                onChange={
                  methodologySelected => {
                    if (methodologySelected) {
                      if (!hasSelectedPoint) {
                        setSelectedOption('point')({
                          label: methodologySelected.samplingStationName,
                          value: methodologySelected.samplingStationId,
                        });
                      }
                      setSelectedOption('methodology')({
                        label: methodologySelected.label,
                        value: methodologySelected.value,
                        methodologyType: methodologySelected.typeId,
                        transitIntervals: methodologySelected.timeIntervals,
                      });
                      methodologySelected.timeIntervals &&
                        handleSelect({ detectionDate: new Date(methodologySelected.timeIntervals[0].startDate) });
                    }
                  }
                }
                {...(isMethodologyOptionDisabled ? {} : { getOptions: getMethodologiesOptionsWithFilters })}
                placeholder={options => options.length === 0 ? `No se encontraron metodologías` : `Elige una metodología`}
                disabled={(options, inputValue) => (options.length === 0 && inputValue === '') || isMethodologyOptionDisabled }
                forceUpdate={forceUpdate}
                noOptionsText={`No se encontraron metodologías ${selectedOptions.point.value && 'en la estación de muestreo seleccionada'}`}
                error={hasBadData && !hasSelectedMethodology}
              />
              {hasBadData && !hasSelectedMethodology && <FormHelperText error>Debes seleccionar una metodología</FormHelperText>}
            </Box>
          </>}
          {hasMoreThanOneRecordType && <Box my={2}>
            <InputLabel shrink>Tipo</InputLabel>
            <SelectChip
              value={record.type}
              options={recordsTypesOptions}
              onChange={option => {
                handleSelect({ type: option.value });
                setSelectedOption('methodology')(preselectedData?.methodologySelected || emptyOption);
                setSelectedOption('point')(preselectedData?.pointSelected || emptyOption);
                setSelectedOption('species')(emptyOption);
                setForceUpdate(record.type);
                setFetchStatus(NOT_STARTED);
              }}
            />
          </Box>}
          <Box my={2} display="flex">
            {isFaunaIndirectRecord &&
              <Box mr={-1} display="flex" flexDirection="column" alignItems="center">
                <InputLabel shrink>Reconocible</InputLabel>
                <Box mr="18px">
                  <Checkbox
                    checked={isRecognizableSpecies}
                    onChange={() => {
                      handleSelect({ recognizable: !isRecognizableSpecies });
                      if (isRecognizableSpecies) {
                        setSelectedOption('species')(emptyOption);
                      }
                    }}
                    disabled={(!hasSelectedMethodology && isMethodologyRequired)}
                    inputProps={{ 'aria-label': 'Checkbox Especie Reconocible' }}
                  />
                </Box>
              </Box>
            }
            <Box width="100%">
              <InputLabel required shrink>Especie <em>(se mostrarán máximo 10 resultados)</em></InputLabel>
              <SelectAsync
                id="select-species"
                initialOptions={false}
                value={selectedSpecies}
                getOptions={
                  ({ queryText }) =>
                    getSpeciesOptions({
                      queryText,
                      recordType: record.type,
                      ...(!isFaunaIsolatedRecord ? { methodId: selectedMethodology.value } : {}),
                    })
                }
                placeholder={(
                  isMethodologyRequired && !hasSelectedMethodology ? `Debes seleccionar una metodología` :
                  isFaunaIndirectRecord && !isRecognizableSpecies ? `La especie no se pudo reconocer` :
                    `Elige una especie ${methodologyType ?
                      `de ${hraos(speciesClassByMethodologyType[methodologyType].map(s => tls[s]), 'o').toLowerCase()}` :
                      ''}`
                )}
                disabled={
                  (isMethodologyRequired && !hasSelectedMethodology) ||
                  (isFaunaIndirectRecord && !isRecognizableSpecies)
                }
                error={hasBadData && !hasSelectedSpecies}
                useScientificNameFormat
                freeSolo
                onChange={optionSelected => {
                  if (typeof optionSelected === 'string') { // Cuando se selecciona con enter llega un string, magic
                    setSelectedOption('species')({ label: optionSelected, value: undefined });
                  } else if (optionSelected?.inputValue) { // Cuando se agrega un nuevo tipo
                    setSelectedOption('species')({ label: optionSelected.inputValue, value: undefined });
                  } else { // Cuando se selecciona normalmente
                    setSelectedOption('species')({ label: optionSelected.label, value: optionSelected.value });
                  }
                }}
                filterOptions={(options, params) => {
                  const speciesAlreadyExist = (
                    options.some(o => o.label.toLowerCase() === params.inputValue.toLowerCase() && o.userCreatorInfo === 'ti')
                  );
                  if (params.inputValue !== '' && !speciesAlreadyExist) {
                    options.push({
                      inputValue: params.inputValue,
                      label: `Agregar "${params.inputValue}"`,
                    });
                  }
                  return options;
                }}
                getOptionLabel={option => typeof option === 'string' ? option : option.label ? option.label : '' }
              />
              {hasBadData && !hasSelectedSpecies && (isFaunaIndirectRecord ? isRecognizableSpecies : true) &&
                <FormHelperText error>Debes seleccionar una especie</FormHelperText>}
            </Box>
          </Box>

          {isFaunaDirectRecord &&
            <DirectRecordInputs
              required
              record={record}
              handlers={{ handleSelect }}
              validators={{ hasBadData, isNumberUnderOne }}
            />
          }

          {(isFaunaIndirectRecord || isFaunaIsolatedRecord) &&
            <Box my={2}>
              <InputLabel shrink>Coordenadas</InputLabel>
              <CoordinatesInput required value={coords} onChange={setCoords} />
              {hasBadData && !hasCoords && <FormHelperText error>Debes ingresar latitud y longitud</FormHelperText>}
            </Box>
          }

          {isFaunaIndirectRecord &&
            <IndirectRecordInputs
              record={record}
              options={{ indirectRecordsTypesOptions }}
              handlers={{ handleSelect }}
              validators={{ hasBadData, hasSelectedIndirectRecordType }}
            />
          }

          {isFaunaIsolatedRecord &&
            <AgeStratumAndSexInputs
              record={record}
              handlers={{ handleSelect }}
              options={{ sexTypesOptions, ageStratumsOptions }}
            />
          }

          {isFaunaTransitRecord &&
            <TransitRecordInputs
              record={record}
              handlers={{ handleChange, handleSelect }}
              validators={{ hasBadData, isNumberBetween1And500, isHeightBetween0And1000,
                isMinHeightBetween0And1000, isMaxHeightBetween0And1000, isValidMinMax, hasFrom, hasTowards }}
              options={{ cardinalPointsOptions, flightTypes }}
            />
          }

          <Box my={2}>
            <Box>
              <InputLabel required={isDetectionDateRequired} shrink>Fecha de detección</InputLabel>
              <Box display="flex" alignItems="center">
                <Box display="flex" flexDirection="column">
                  <DateTimePicker
                    value={detectionDate}
                    ampm={false}
                    TextFieldComponent={props => <TextField {...props} variant="outlined" size="small" />}
                    onChange={detectionDate => handleSelect({ detectionDate: new Date(detectionDate) })}
                    format="DD-MM-YYYY HH:mm"
                    {...(isFaunaPresenceRecord && !isDetectionDateRequired ?
                      { InputProps: { endAdornment: <Box display="flex" onClick={handleClearDate}><Clear fontSize="small" /></Box> } } : {}
                    )}
                    error={(hasBadData && isDetectionDateRequired && !hasDetectionDate) ||
                      (hasBadData && !isDetectionDateBetweenSomeInterval)}
                  />
                  {hasBadData && isDetectionDateRequired && !hasDetectionDate &&
                    <FormHelperText error>Debes ingresar una fecha de detección</FormHelperText>}
                  {hasBadData && !isDetectionDateBetweenSomeInterval &&
                    <FormHelperText error>La fecha de detección debe estar dentro de un intervalo</FormHelperText>}
                </Box>
                {isFaunaTransitRecord && selectedMethodology.transitIntervals?.length &&
                  <Box ml={2}>
                    <Typography variant="body2" color="primary" aria-owns={isOpenPopover ? 'int-pop' : undefined}
                      aria-haspopup="true" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
                      Intervalos disponibles
                    </Typography>
                    <PopoverWrapper id="int-pop" open={isOpenPopover} anchorEl={anchorEl} onClose={handlePopoverClose} disableRestoreFocus>
                      <Box p={3}>
                        {selectedMethodology.transitIntervals?.map((ti, idx) =>
                          <Box display="flex" alignItems="center" key={idx}>
                            <Box mr={1}>
                              <Typography variant="subtitle2">Intervalo {idx + 1}:</Typography>
                            </Box>
                            <LabelChip label={`Inicio: ${fd(ti.startDate)} ${fde(ti.startDate)}`} />
                            <Remove fontSize="small" />
                            <LabelChip label={`Fin: ${fd(ti.endDate)} ${fde(ti.endDate)}`} />
                          </Box>,
                        )}
                      </Box>
                    </PopoverWrapper>
                  </Box>
                }
              </Box>
            </Box>
          </Box>
          <Box my={2}>
            <InputLabel shrink required={isFaunaIndirectRecord && !isRecognizableSpecies}>Observaciones</InputLabel>
            <TextField
              value={observation} name="observation" variant="outlined" size="small" onChange={handleChange}
              minRows="2" maxRows='5' multiline fullWidth autoComplete="off"
              error={isFaunaIndirectRecord && hasBadData && !hasObservation && !isRecognizableSpecies}
            />
            {isFaunaIndirectRecord && hasBadData && !hasObservation && !isRecognizableSpecies &&
              <FormHelperText error>Debes agregar una observación</FormHelperText>}
          </Box>

          {!isFaunaDirectRecord &&
            <Box my={1} mb={3}>
              <PhotoInput
                onChange={handleImagesChange}
                imgFiles={record.imgFiles || []}
                imgFilesData={record.imgFilesData || []}
                inputLabelText={'Evidencia (.jpg, .jpeg)'}
                onlyJpg
                required={isEvidenceRequired}
              />
              {hasBadData && isEvidenceRequired && !hasEvidence &&
                <FormHelperText error>Se debe cargar al menos una imagen como evidencia del registro.</FormHelperText>
              }
            </Box>
          }

          {isFaunaDirectRecord &&
            <AlertWrapper severity="info">
              Para <strong>agregar individuos de registros directos </strong> debes ir a las acciones de este
              registro ubicadas en la lista de registros
            </AlertWrapper>
          }

          {fetchStatus === ERROR &&
            <Box mt={2}>
              <Collapse in={errorAlert.isOpen}>
                <Alert severity="error" onClose={() => setErrorAlert(s => ({ ...s, isOpen: false }))}>
                  {errorAlert.message}
                </Alert>
              </Collapse>
            </Box>
          }

        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>Crear</DialogButton>
      </DialogActions>
    </>
  );
};

FaunaRecordCreateDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  ctLang: PropTypes.object,
  options: PropTypes.object,
  getOptions: PropTypes.object,
  recordType: PropTypes.string,
  preselectedData: PropTypes.object,
};


export { FaunaRecordCreateDialog };