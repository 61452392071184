import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Box, Drawer, Hidden, IconButton, Grid, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Menu,
  // Business, Group, Storage, ContactMail, ContactSupport, Announcement, Laptop
} from '@material-ui/icons';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import {
  MenuTitle,
  // HeaderPopover
} from 'src/scenes/Home/scenes/Header/components';
import { NviroButton } from 'src/components';
import { usersApi } from 'src/services';


const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 2,
    backgroundColor: theme.palette.common.white,
  },
  toolbar: {
    height: theme.spacing(19),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    [theme.breakpoints.down(1500)]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(14),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  logo: {
    cursor: 'pointer',
    marginRight: theme.spacing(16),
    height: theme.spacing(10),
    [theme.breakpoints.down(1500)]: {
      marginRight: theme.spacing(7),
      height: theme.spacing(9),
    },
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(2),
      height: theme.spacing(7),
    },
  },
  nav: {
    paddingBottom: 0,
  },
  altMenuButton: {
    maxHeight: 'fit-content',
  },
  button: {
    height: '3rem',
    fontWeight: 'bold',
    width: '8.625rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.813rem',
      height: '2.5rem',
      width: '7rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.813rem',
      height: '2.5rem',
      width: '10rem',
    },
  },
  loginButton: {
    backgroundColor: theme.palette.secondary.main,
  },
  registerButton: {
    backgroundColor: '#40474B',
  },
  menuAltItem: {
    width: '100%',
  },
  menuAltLink: {
    color: theme.palette.common.black,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontSize: '0.813rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
}));


const HomeHeaderContainer = () => {

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const [ openDrawer, setOpenDrawer ] = useState(false);
  const [ userIsAuthenticated, setUserIsAuthenticated ] = useState(false);

  useEffect(() => {
    const checkIfUserIsAuthenticated = async () => {
      const isAuthenticated = await usersApi.isAuthenticated();
      if (isAuthenticated) {
        setUserIsAuthenticated(true);
      }
    };

    checkIfUserIsAuthenticated();
  }, []);

  const toggleDrawer = event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenDrawer(prevState => !prevState);
  };

  const goTo = page => () => history.push(`/${page}`);

  // const companySubmenu = [
  //   { title: 'Quiénes somos', Icon: Group, onClick: goTo('about-us') },
  //   { title: 'Contacto', Icon: ContactMail, onClick: goTo('contact') },
  // ];

  // const resourcesSubmenu = [
  //   { title: 'Preguntas frecuentes', Icon: ContactSupport, onClick: goTo('faq') },
  //   { title: '¿Que es nuevo?', Icon: Announcement, onClick: goTo('what-is-new') },
  //   { title: 'Solicitar demo', Icon: Laptop, onClick: goTo('schedule-demo') },
  // ];

  return (
    <AppBar position="sticky" elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Link to='/'>
          <img className={classes.logo} src={theme.logo} alt="nviro-logo" />
        </Link>
        <Hidden smDown>
          <MenuTitle onClick={goTo('how-it-work')}>¿Cómo funciona?</MenuTitle>
          <MenuTitle onClick={goTo('pricing')}>Planes</MenuTitle>
          <MenuTitle onClick={goTo('about-us')}>Quiénes somos</MenuTitle>
          <MenuTitle onClick={goTo('contact')}>Contacto</MenuTitle>
          {/* <MenuTitle Submenu={() => <HeaderPopover title="Compañía" Icon={Business} submenu={companySubmenu} />}>
            Compañía
          </MenuTitle>
          <MenuTitle Submenu={() => <HeaderPopover title="Recursos" Icon={Storage} submenu={resourcesSubmenu} />}>
            Recursos
          </MenuTitle> */}
          <Box flexGrow={1}></Box>
          {
            userIsAuthenticated ?
              <Box>
                <NviroButton className={classes.button} color="black" onClick={goTo('web')}>
                  Ir a trabajar
                </NviroButton>
              </Box> :
              <>
                <Box mx={2}>
                  <NviroButton className={classes.button} onClick={goTo('login')}>
                    Iniciar Sesión
                  </NviroButton>
                </Box>
                <Box>
                  <NviroButton className={classes.button} color="black" onClick={goTo('register')}>
                    Registrarse
                  </NviroButton>
                </Box>
              </>
          }
        </Hidden>
        <Hidden mdUp>
          <Box flexGrow={1}></Box>
          <IconButton onClick={toggleDrawer} edge="start" color="primary" aria-label="menu">
            <Menu fontSize="large"/>
          </IconButton>
        </Hidden>
      </Toolbar>
      <Drawer open={openDrawer} anchor="top" onClose={toggleDrawer}>
        <div className={classes.list} role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer}>
          <Box display="flex" alignItems="center" p={3}>
            <img className={classes.logo} src={theme.logo} alt="nviro-logo" onClick={() => history.push('/')} />
            <Box flexGrow={1}></Box>
            <IconButton onClick={e => { toggleDrawer(e); e.stopPropagation(); }} edge="start"
              className={classes.altMenuButton} color="primary" aria-label="menu">
              <Menu fontSize="large"/>
            </IconButton>
          </Box>
          <Grid container direction="column">
            <Grid item className={classes.menuAltItem}>
              <Typography className={classes.menuAltLink} onClick={goTo('how-it-work')}>¿Cómo funciona?</Typography>
            </Grid>
            <Divider />
            <Grid item className={classes.menuAltItem}>
              <Typography className={classes.menuAltLink} onClick={goTo('pricing')}>Planes</Typography>
            </Grid>
            <Divider />
            <Grid item className={classes.menuAltItem}>
              <Typography className={classes.menuAltLink} onClick={goTo('about-us')}>Quiénes somos</Typography>
            </Grid>
            <Grid item className={classes.menuAltItem}>
              <Divider />
              <Typography className={classes.menuAltLink} onClick={goTo('contact')}>Contacto</Typography>
            </Grid>
            <Grid item className={classes.menuAltItem}>
              <Divider />
              <Box my={2} display="flex" justifyContent="center">
                {
                  userIsAuthenticated ?
                    <NviroButton className={classes.button} color="black" onClick={goTo('web')}>
                      Ir a trabajar
                    </NviroButton> :
                    <>
                      <NviroButton className={classes.button} color="black" onClick={goTo('register')}>
                        Registrarse
                      </NviroButton>
                      <Box mx={1} />
                      <NviroButton className={classes.button} onClick={goTo('login')}>
                        Iniciar Sesión
                      </NviroButton>
                    </>
                }
              </Box>
            </Grid>
          </Grid>
        </div>
      </Drawer>
    </AppBar>
  );
};


export { HomeHeaderContainer };
