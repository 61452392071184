import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { Edit, PhotoAlbum, Delete } from '@material-ui/icons';
import PropTypes from 'prop-types';
import urlJoin from 'url-join';
import config from 'src/config/local';

import { TableWrapper, LabelCoords } from 'src/components';
import { optionsToObject, formatDate, formatDateExtension } from 'src/utils/util';


const { serverUrl } = config;

const DirectIndividualsList = ({ directRecord, options, actions, disabledEdition, hideEdition, hideDeletion, disabledDeletion }) => {

  const { scientificName, recordId, individuals } = directRecord;

  const { sexTypesOptions, ageStratumsOptions } = options;

  const detailTableColumns = [
    { title: 'N° individuo', field: 'index', filtering: false },
    {
      title: 'Coordenada (UTM Sur-Oeste)',
      filtering: false,
      render: useCallback(r => <LabelCoords latitude={r.latitude} longitude={r.longitude} />, []),
    },
    {
      title: 'Estrato etario',
      field: 'ageStratumId',
      filtering: false,
      render: useCallback(
        r => <span>{ageStratumsOptions && optionsToObject(ageStratumsOptions)[r.ageStratumId]}</span>, [ ageStratumsOptions ],
      ),
    },
    {
      title: 'Sexo',
      filtering: false,
      render: useCallback(r => <span>{sexTypesOptions && optionsToObject(sexTypesOptions)[r.sexId]}</span>, [ sexTypesOptions ]),
    },
    {
      title: 'Recapturado',
      filtering: false,
      render: useCallback(r => <span>{r.recaptured ? 'Si' : 'No'}</span>, []),
    },
    {
      title: 'Fecha de detección',
      render: useCallback(r => <div>{`${formatDate(r.detectionDate)} ${formatDateExtension(r.detectionDate)}`}</div>, []),
    },
    { title: 'Observaciones', field: 'observation', filtering: false,
      render: useCallback(r => <span>{r.observation ? r.observation : 'Sin observaciones'}</span>, []),
    },
  ];

  const detailTableActions = [
    {
      hidden: hideEdition,
      disabled: disabledEdition,
      icon: () => <Edit color={disabledEdition ? 'disabled' : 'action' } />,
      ...(disabledEdition ? {} : { tooltip: 'Editar' }),
      onClick: (event, rowData) => actions.toggleDialog({ data: rowData, type: 'edit-individual' }),
    },
    rowData => ({
      disabled: rowData.pictures.length === 0,
      icon: () => <PhotoAlbum color={rowData.pictures.length ? 'action' : 'disabled' } />,
      ...(rowData.pictures.length > 0 && { tooltip: 'Ver Fotos' }),
      onClick: (event, rowData) => actions.toggleDialog({
        data: {
          pictures: rowData.pictures.map(p => urlJoin(serverUrl, p)),
          title: rowData.scientificName,
        },
        type: 'photo',
      }),
    }),
    {
      hidden: hideDeletion,
      disabled: disabledDeletion,
      icon: () => <Delete color={disabledEdition ? 'disabled' : 'action' } />,
      tooltip: disabledDeletion ? `No puedes borrar este individuo` : 'Borrar',
      onClick: (event, rowData) => actions.toggleDialog({
        data: rowData,
        type: 'delete-individual',
      }),
    },
  ];

  return (
    <>
      <Box mx={5} mb={1} mt={1}>
        <TableWrapper
          columns={detailTableColumns}
          actions={detailTableActions}
          data={individuals.map((r, i) => ({ ...r, index: i + 1, scientificName: scientificName, recordId }))}
          lastColumnSticked={false}
          options={{ emptyRowsWhenPaging: false, toolbar: false, paging: false }}
        />
      </Box>
    </>
  );
};

DirectIndividualsList.propTypes = {
  options: PropTypes.object,
  actions: PropTypes.object,
  directRecord: PropTypes.object,
  disabledEdition: PropTypes.bool,
  hideEdition: PropTypes.bool,
  hideDeletion: PropTypes.bool,
  disabledDeletion: PropTypes.bool,
};


export { DirectIndividualsList };