import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, DialogTitle, DialogContent, DialogActions, Typography, Collapse } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { DialogButton } from 'src/components';
import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';


const { NOT_STARTED, LOADING, SUCCESS, ERROR } = fetchStatusEnum;

const PointSyncDialog = props => {
  const { point, actions, csLang } = props;
  const pointDescription = `${csLang.point} "${point.name}"`;

  const [ fetchStatus, setFetchStatus ] = useState(NOT_STARTED);
  const [ errorAlert, setErrorAlert ] = useState({ isOpen: false, message: '' });

  const handleConfirm = async () => {
    try {
      setFetchStatus(LOADING);
      await actions.editPoints({ toSync: true });
      setFetchStatus(SUCCESS);
      setTimeout(() => actions.closeDialog(), 300);
    } catch (e) {
      setErrorAlert({ isOpen: true, message: e.serverMessage || e.message });
      setFetchStatus(ERROR);
    }
  };

  return (
    <>
      <DialogTitle id="form-dialog-title">Marcar {csLang.point} como sincronizada</DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Typography variant="body1" color="textPrimary">
            Estás por marcar como sincronizada la <strong>{pointDescription}</strong>, esta acción es irreversible
            y<strong> no permitirá que se sincronicen datos desde la app móvil</strong>. Debes considerar que podría
            haber trabajo en progreso antes de continuar.
          </Typography>
          <br />
          <Typography color="textPrimary" variant="body1">
            ¿Realmente deseas marcar como sincronizada la {csLang.point}?
          </Typography>
          {fetchStatus === ERROR &&
            <Collapse in={errorAlert.isOpen}>
              <Alert severity="error" onClose={() => setErrorAlert(s => ({ ...s, isOpen: false }))}>
                {errorAlert.message}
              </Alert>
            </Collapse>
          }
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>Confirmar</DialogButton>
      </DialogActions>
    </>
  );
};

PointSyncDialog.propTypes = {
  point: PropTypes.object,
  actions: PropTypes.object,
  csLang: PropTypes.object,
};


export {
  PointSyncDialog,
};