import React, { Fragment, forwardRef, memo, useImperativeHandle, useState } from 'react';
import { Box, Typography, Paper, Tooltip, Collapse, TablePagination, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { usePagination } from 'src/hooks';


const useStyles = makeStyles(theme => ({
  mainContainer: {
    overflowX: 'auto',
  },

  listPaperCondensed: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.gray400,
    borderRadius: theme.shape.borderRadius,
  },
  listItem: {
    cursor: ({ collapsableData }) => collapsableData ? 'pointer' : 'auto',
    '&:hover': {
      '& $actionsContainer': {
        visibility: 'visible',
        backgroundColor: 'inherit',
        animationName: '$fadeIn',
        animation: 'linear',
        animationDuration: ({ alwaysShowActions }) => alwaysShowActions ? '0ms' : '300ms',
      },
    },
  },
  listItemDefault: {
    boxShadow: '0 0 1px 0#b3bac5,0 1px 1px 0#c1c7d0',
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    '&:hover': {
      transition: 'box-shadow 300ms ease 0s',
      boxShadow: 'rgb(179 186 197) 0px 0px 1px 0px, rgb(193 199 208) 0px 8px 16px -6px',
    },
  },
  listItemCondensed: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.common.gray400,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  listItemDisabled: {
    backgroundColor: theme.palette.common.gray300,
  },
  listItemHovereable: {
    '&:hover': {
      backgroundColor: theme.palette.common.lightGray,
    },
  },
  defaultItemData: {
    color: theme.palette.common.gray800,
    fontWeight: 600,
    marginLeft: theme.spacing(1),
  },
  actionIcons: {
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.gray600,
    cursor: 'pointer',
    height: theme.spacing(4),
    width: theme.spacing(4),
    '&:hover': {
      color: theme.palette.common.gray800,
      backgroundColor: theme.palette.common.lightGray,
    },
  },
  actionsContainer: {
    borderLeft: ({ isTableMode }) => isTableMode ? `1px solid ${theme.palette.common.gray400}` : '',
    marginLeft: ({ actionsCount }) => theme.spacing(4) * -1 * actionsCount - theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
    position: 'sticky',
    right: 0,
    backgroundColor: theme.palette.common.white,
    visibility: ({ alwaysShowActions }) => alwaysShowActions ? 'block' : 'hidden',
  },

  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },

  tableContainer: {
    overflowX: 'auto',
    display: 'inline-flex',
    flexFlow: 'column nowrap',
    minWidth: '100%',
    height: '100%',
  },
  tableHeader: {
    display: 'flex',
    flexFlow: 'row nowrap',
    width: '100%',
    flex: '0 0 auto',
  },
  tableRow: {
    display: 'flex',
    flex: '1 1 auto',
  },
  tableHeaderCell: {
    color: theme.palette.common.gray800,
    fontWeight: 600,
  },
  tableRowCell: {
    color: theme.palette.common.gray800,
    fontWeight: 500,
  },
  tableCell: {
    minWidth: '150px',
    flex: 1,
    height: '100%',
    overflowWrap: 'anywhere',
    paddingLeft: theme.spacing(1),
  },
  paginationFont: {
    fontSize: '0.75rem',
  },
  gridItem: {
    marginTop: theme.spacing(0),
  },
}));

/* eslint-disable react/prop-types */
const DefaultContent = ({ itemData }) => {
  const classes = useStyles();
  return (
    <>
      <Box width="50%">
        <Typography variant="body2" className={classes.defaultItemData}>{itemData?.name}</Typography>
      </Box>
      <Box width="50%">
        <Typography variant="body2" className={classes.defaultItemData}>{itemData?.responsibleUser}</Typography>
      </Box>
    </>
  );
};

/* eslint-disable react/prop-types */
const ContentWithHeader = ({ item, columns }) => {
  const classes = useStyles();
  return (
    <>
      {columns.map((c, index) =>
        <Typography key={index} className={clsx(classes.tableRowCell, classes.tableCell)} variant="body2" component="div">
          {c.render ? c.render(item) : item[c.field]}
        </Typography>,
      )}
    </>
  );
};

/* eslint-disable react/prop-types */
const ActionsComponent = ({ actions, item, actionsClass }) => {
  const actionsResult = actions(item);
  const classes = useStyles();
  return (
    <Box display="flex" className={actionsClass}>
      { actionsResult.map(({ tooltip = '', Icon, onClick }, index) =>
        <Tooltip key={`action-${index}`} title={tooltip}>
          <Box display="flex" width="100%" alignItems="center" className={classes.actionIcons} px={0.5}>
            <Icon onClick={onClick} />
          </Box>
        </Tooltip>,
      )}
    </Box>
  );
};

const CardsWithMemo = forwardRef((props, _ref) => {

  const {
    values: list = [],
    variant = 'default',
    actions,
    collapsableData,
    Content,
    onHover,
    onLeave,
    columns = [],
    checkItemDisabled,
    alwaysShowActions = false,
    showPagination = false,
    classes: { defaultItem: defaultItemCustom } = {},
  } = props;

  const isTableMode = columns.length > 0;

  const classes = useStyles({ variant, collapsableData, isTableMode, alwaysShowActions });

  const pagination = usePagination(list);
  const { jump, currentPage, itemsPerPage, getCurrentData, setItemsPerPage } = pagination;

  const actionsContainerClass = classes.actionsContainer;

  const isCondensed = variant === 'condensed';
  const isDefault = variant === 'default';
  const isGrid = variant === 'grid';

  const [ collapsedItems, setCollapsedItems ] = useState(list.map(f => f.id));

  const handleChangePage = (event, newPage) => jump(newPage);

  const handleChangeRowsPerPage = event => {
    setItemsPerPage(parseInt(event.target.value, 10));
    jump(0);
  };

  useImperativeHandle(_ref, () => ({
    getPagination: () => pagination,
  }));

  const handleCollapseItem = itemId => {
    setCollapsedItems(prevItems => prevItems.includes(itemId) ? prevItems.filter(iId => iId !== itemId) : [ itemId, ...prevItems ]);
  };

  const listToShow = showPagination ? getCurrentData() : list;

  return (
    <Box className={clsx({ [classes.mainContainer]: isTableMode })}>
      {isCondensed &&
      <>
        <Paper className={clsx(classes.listPaperCondensed, { [classes.tableContainer ]: isTableMode })} elevation={0}>
          {isTableMode &&
            <Box display="flex" alignItems="center" px={2} py={0.5}
              className={clsx(classes.listItem, classes.listItemCondensed, { [classes.tableHeader]: isTableMode })}
            >
              {columns.map((c, index) =>
                <Typography key={index} className={clsx(classes.tableHeaderCell, classes.tableCell)} variant="body2">{c.title}</Typography>,
              )}
            </Box>
          }
          {list.length === 0 && <Box px={2} py={0.5}><Typography variant="body2">No se han agregado elementos</Typography></Box>}
          {list.length > 0 &&
            listToShow
              .map((item, index) => {
                const itemDisabled = checkItemDisabled ? checkItemDisabled(item) : item.isDisabled;
                return <Box key={index} display="flex" alignItems="center" px={2} py={0.5}
                  className={
                    clsx(
                      classes.listItem,
                      classes.listItemCondensed,
                      {
                        [classes.tableRow]: isTableMode,
                        [classes.listItemDisabled]: itemDisabled,
                        [classes.listItemHovereable]: !itemDisabled,
                      },
                    )
                  }
                >
                  {Content ?
                    <Box width="100%"><Content itemData={item} /></Box>
                    : isTableMode ? <ContentWithHeader item={item} columns={columns} isTableMode />
                    : <DefaultContent itemData={item} />
                  }
                  { actions &&
                    <ActionsComponent item={item} actions={actions} isTableMode={isTableMode} actionsClass={actionsContainerClass} />
                  }
                </Box>;
              })
          }
        </Paper>
        {showPagination &&
            <Box display="flex" justifyContent="right">
              <TablePagination
                classes={{ caption: classes.paginationFont }}
                component="div"
                count={list.length}
                page={(currentPage + 1) > Math.ceil(list.length / itemsPerPage) ? 0 : currentPage}
                onPageChange={handleChangePage}
                rowsPerPage={itemsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[ 5, 10 ].filter(() => list.length > 5)}
                labelRowsPerPage="Elementos por pagina"
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
                labelDisplayedRows={({ from, to, count }) => <>{from}-{to} de {count !== -1 ? count : <>más de {to}</>}</>}
              />
            </Box>
        }
      </>}

      {isDefault &&
        <>
          {list.length === 0 && <Box px={2} py={0.5}><Typography variant="body2">No se han agregado elementos</Typography></Box>}
          {list.length > 0 &&
            list.map((item, index) =>
              <Fragment key={index}>
                <Paper className={clsx(classes.listItem, classes.listItemDefault, defaultItemCustom)}
                  onClick={() => handleCollapseItem(item.id)} {...(onHover ? { onMouseEnter: () => onHover(item) } : {})}
                  {...(onHover ? { onMouseLeave: () => onLeave(item) } : {})}
                >
                  <Box display="flex" alignItems="center">
                    { Content ? <Content itemData={item} /> : <DefaultContent itemData={item} /> }
                    { actions &&
                      <ActionsComponent item={item} actions={actions} isTableMode={isTableMode} actionsClass={actionsContainerClass} />
                    }

                  </Box>
                </Paper>
                {collapsableData &&
                  <Box display="flex" justifyContent="end">
                    <Box width="95%">
                      <Collapse in={collapsedItems.includes(item.id)} timeout="auto" unmountOnExit>
                        <collapsableData.Component itemData={item[collapsableData.key]} />
                      </Collapse>
                    </Box>
                  </Box>
                }
              </Fragment>
              ,
            )
          }
        </>
      }

      {isGrid &&
          <>
            {list.length === 0 && <Box px={2} py={0.5}><Typography variant="body2">No se han agregado elementos</Typography></Box>}
            {list.length > 0 &&
              <Grid container spacing={2}>
                {list.map((item, index) =>
                  <Grid key={index.toString()} item xs={12} sm={6} md={4} lg={3} >
                    <Paper key={index} className={clsx(classes.listItem, classes.listItemDefault, defaultItemCustom, classes.gridItem)}
                      onClick={() => handleCollapseItem(item.id)} {...(onHover ? { onMouseEnter: () => onHover(item) } : {})}
                      {...(onHover ? { onMouseLeave: () => onLeave(item) } : {})}
                    >
                      <Box display="flex">
                        {Content ?
                          <Content itemData={item} />
                          :
                          <>
                            <Box display="flex" alignItems="center" width="100%">
                              <DefaultContent itemData={item} />
                              <Box flexGrow={1} />
                              <ActionsComponent
                                item={item} actions={actions} isTableMode={isTableMode} actionsClass={actionsContainerClass}
                              />
                            </Box>
                          </>
                        }
                      </Box>
                    </Paper>
                  </Grid>)
                }
              </Grid>
            }
          </>
      }
    </Box>
  );
});

const Cards = memo(CardsWithMemo);

Cards.propTypes = {
  values: PropTypes.array,
  variant: PropTypes.string,
  actions: PropTypes.func,
  collapsableData: PropTypes.shape({
    key: PropTypes.string.isRequired,
    Component: PropTypes.func.isRequired,
  }),
  Content: PropTypes.func,
  checkItemDisabled: PropTypes.func,
  alwaysShowActions: PropTypes.bool,
};


export {
  Cards,
};
