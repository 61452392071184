import React from 'react';
import { InputLabel, Box, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

import { AutocompleteWrapper } from 'src/components';


const filter = createFilterOptions();

const IndirectRecordInputs = props => {

  const { handlers, record, options, validators } = props;
  const { indirectRecordsTypesOptions } = options;
  const { hasBadData, hasSelectedIndirectRecordType } = validators;
  const { handleSelect } = handlers;
  const selectedType = indirectRecordsTypesOptions.find(irto => irto.value === record.typeId);

  const isOthers = selectedType?.value === 'others';

  return (
    <Box my={2}>
      <InputLabel required shrink>Tipo de registro indirecto</InputLabel>
      <AutocompleteWrapper
        id="indirect-record-type"
        value={{ label: isOthers ? record.otherType : selectedType?.label, value: selectedType?.value }}
        options={indirectRecordsTypesOptions}
        freeSolo
        onChange={(event, optionSelected) => {
          if (typeof optionSelected === 'string') { // Cuando se selecciona con enter llega un string, magic
            handleSelect({ otherType: optionSelected, typeId: 'others' });
          } else if (optionSelected && optionSelected.inputValue) { // Cuando se agrega un nuevo tipo
            optionSelected.label = optionSelected.inputValue;
            handleSelect({ otherType: optionSelected.inputValue, typeId: 'others' });
          } else { // Cuando se selecciona normalmente
            handleSelect({ typeId: optionSelected.value, otherType: '' });
          }
        }}
        filterOptions={(options, params) => {
          const optionsWithOther = options.filter(option => option.value !== 'others');
          const filtered = filter(optionsWithOther, params);
          // Suggest the creation of a new value
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              label: `Agregar "${params.inputValue}"`,
            });
          }
          return filtered;
        }}
        getOptionLabel={option => typeof option === 'string' ? option : option.label ? option.label : '' }
        placeholder="Elige un tipo de registro indirecto"
        error={hasBadData && !hasSelectedIndirectRecordType}
      />
      {hasBadData && !hasSelectedIndirectRecordType &&
        <FormHelperText error>Debes ingresar un tipo de registro indirecto</FormHelperText>}
    </Box>
  );
};

IndirectRecordInputs.propTypes = {
  record: PropTypes.object.isRequired,
  handlers: PropTypes.object,
  options: PropTypes.object,
  validators: PropTypes.objectOf(PropTypes.bool),
};


export { IndirectRecordInputs };