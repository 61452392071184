const serverUrl = 'https://api.capture.nviro.cl/';
const webUrl = 'https://capture.nviro.cl/';
const contactUrl = 'https://mailer.csw.cl/contact';
const mapboxToken = 'pk.eyJ1Ijoic2ViYXBjYXJyb3phIiwiYSI6ImNscTZzN2MyNDB2a3oya2xzM2prMmplb3QifQ.3eklBPYOZ6JbFa36RVdzcw';
const baseMapUrl = 'https://api.mapbox.com/styles/v1';
const satelliteStreetsBaseMap = `${baseMapUrl}/mapbox/satellite-streets-v9/tiles/256/{z}/{x}/{y}?&access_token=${mapboxToken}`;
const blackStreetsBaseMap = `${baseMapUrl}/sebapcarroza/ckr0sls6o1ysc18pjsqttiets/tiles/256/{z}/{x}/{y}@2x?access_token=${mapboxToken}`;
const smacoreLink = 'https://portal.sma.gob.cl/index.php/2022/04/05/sma-dicta-instruccion-para-reporte-estandarizado-de-componente-de-biodiversidad/';
const maxSizeFileUpload = 5000000;
const maxKmlPoints = 10000;
const maxKmlLines = 10000;
const maxKmlPolygons = 10000;
const maintenanceDates = {
  start: '', // formato: "YYYY-MM-DD HH:mm:ss-Z", ejemplo: "2023-12-31 18:00:00-04:00"
  finish: '', // mismo formato, se deben ingresar ambas o ninguna fecha
};

// utilizar esto en caso de querer realizar alguna configuración, se activará un proyecto aparte en la web de logRocket.
// Notar que hay cierto desfase en el registro, a veces de unos 20min. No es instantáneo
const forceLogRocketInDev = false;

// variables que se calculan solas, NO EDITAR
const isProductionServer = process.env.NODE_ENV === 'production' && webUrl.match('https://capture.nviro.cl');
const logRocketIsOn = forceLogRocketInDev || isProductionServer;


export default {
  webUrl,
  serverUrl,
  contactUrl,
  satelliteStreetsBaseMap,
  blackStreetsBaseMap,
  maxSizeFileUpload,
  maxKmlPoints,
  maxKmlLines,
  maxKmlPolygons,
  maintenanceDates,
  smacoreLink,
  forceLogRocketInDev,
  logRocketIsOn,
  isProductionServer,
};
