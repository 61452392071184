import { get, post, del, patch } from 'src/services/api';
import config from 'src/config/local';


const isAuthenticated = async () => (await post('isAuthenticated')).data.isAuthenticated;

const authenticate = async userCredentials => (await post('users/authenticate', userCredentials)).data;

const authenticateAcceptingTerms = async userCredentials => (
  await post('users/authenticate-accepting-terms-and-policy', userCredentials)
).data;

const logout = async () => await get('users/logout');

const getCampaignMenuByApp = async queryParams => (await get('web/campaign-items', queryParams)).data;

const getFaq = async () => (await get('web/faq-items')).data;

const getLabels = async filtersNames => (await get('web/filters-labels', filtersNames)).data;

const getUsers = async () => (await get('user')).data;

const getUsersByCompany = async () => (await get('users/getUsersByCompany')).data;

const getCompanyRoles = async () => (await get('users/company-roles')).data;

const getCampaignRoles = async () => (await get('users/campaign-roles')).data;

const getPermissionsByRole = async () => (await get('users/permissions')).data;

const getExtraUserData = async () => (await get('users/extra-data')).data;

const registerUser = async newUser => await post('user/register', newUser);

const linkUser = async newUser => (await post('company/link-user', newUser)).data;

const unlinkUserFromCompany = async userId => del('company/unlink-user', { userId });

const updateUser = async userToEdit => (await patch('users', userToEdit)).data;

const getPictures = async queryParams => (await get('users/pictures', queryParams)).data;

const recoverPassword = async data => await post('users/recover-password', data);

const resetPassword = async data => await patch('users/reset-password', data);

const contact = async contactData => await post(config.contactUrl, contactData, { credentials: 'omit' });

const addLastProjectViewed = async projectId => (await post('users/last-projects-viewed', { projectId })).data;

const addLastCampaignViewed = async campaignId => (await post('users/last-campaigns-viewed', { campaignId })).data;

const getLastProjectsViewed = async () => (await get('users/last-projects-viewed')).data;

const getLastCampaignsViewed = async () => (await get('users/last-campaigns-viewed')).data;

const getOne = async email => (await get('user/one', { email })).data;

const subscribeMail = async data => await post('/web/subscribe', data);

const getVerificationTokenValidity = async token => await get('users/verification-token-validity', { token });

const verifyEmail = async token => await patch('users/verify-email', token);

const resendVerificationEmail = async () => await post('users/send-verification-email');

const deleteAccount = () => del('user/delete-account');

export {
  authenticate,
  authenticateAcceptingTerms,
  getUsers,
  getUsersByCompany,
  isAuthenticated,
  logout,
  getCampaignMenuByApp,
  getCompanyRoles,
  getCampaignRoles,
  registerUser,
  linkUser,
  unlinkUserFromCompany,
  getPermissionsByRole,
  updateUser,
  getPictures,
  contact,
  recoverPassword,
  resetPassword,
  addLastProjectViewed,
  addLastCampaignViewed,
  getLastProjectsViewed,
  getLastCampaignsViewed,
  getOne,
  getFaq,
  getLabels,
  subscribeMail,
  getVerificationTokenValidity,
  verifyEmail,
  resendVerificationEmail,
  getExtraUserData,
  deleteAccount,
};
