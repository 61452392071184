import React, { useState, useCallback } from 'react';
import { DialogContent, Box, DialogActions, Button, DialogTitle, InputLabel, FormControlLabel, Checkbox,
  Collapse, TextField, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';
import { DateTimePicker } from '@material-ui/pickers';

import { SpeciesNameAlt, SelectAsync, CoordinatesInput, DialogButton, AlertWrapper } from 'src/components';
import { FloraRecordInputs, PhenologiesAndPhytosanitaryStatusesInputs, PointQuadratRecordInputs }
  from 'src/scenes/Campaign/scenes/Records/components/FloraRecordInputs';
import { fetchStatusEnum } from 'src/utils/enums/fetchStatusEnum';
import checkers from 'src/utils/checkers';
import { upperCaseFirstLetter as ucfl } from 'src/utils/formatters';
import { isEmpty } from 'src/utils/util';


const { NOT_STARTED, LOADING, SUCCESS, ERROR, BAD_DATA } = fetchStatusEnum;

const emptyOption = { label: '', value: '' };

const FloraRecordEditDialog = props => {
  const { prevRecord, actions, options, currentUser, getOptions } = props;
  const { coverageOptions, phenologiesStatusesOptions, phytosanitaryStatusesOptions, stratumsOptions } = options;
  const { getSpeciesOptions } = getOptions;

  const isFloraRecord = checkers.isFloraRecord(prevRecord.type);
  const isForestRecord = checkers.isForestRecord(prevRecord.type);
  const isFloraIndividualRecord = checkers.isFloraIndividualRecord(prevRecord.type);
  const isFloraPointQuadratRecord = checkers.isFloraPointQuadratRecord(prevRecord.type);
  const isFloraPointInterceptRecord = checkers.isFloraPointInterceptRecord(prevRecord.type);

  const [ record, setRecord ] = useState({
    ...isFloraPointQuadratRecord ? { stratumsIds: prevRecord.stratums.map(s => s.id?.toString()) } : {},
    ...prevRecord,
  });

  const [ coords, setCoords ] = useState({
    ...isFloraIndividualRecord ? {
      lat: prevRecord.latitude,
      lng: prevRecord.longitude,
      accuracy: isEmpty(prevRecord.accuracy) ? '' : prevRecord.accuracy,
      altitude: isEmpty(prevRecord.altitude) ? '' : prevRecord.altitude,
    } : {},
  });

  // debido al tiempo que este campo no existió, hay cosas que lo tienen en null. Para esos dejaremos opcional añadirle valor o no
  const [ isDetectionDateEditable, setIsDetectionDateEditable ] = useState(Boolean(prevRecord.detectionDate));
  const handleIsDetectionDateEditableChange = useCallback(event =>
    setIsDetectionDateEditable(event.target.checked), [ setIsDetectionDateEditable ],
  );

  const [ renameAll, setRenameAll ] = useState(false);

  const [ fetchStatus, setFetchStatus ] = useState(NOT_STARTED);
  const hasBadData = fetchStatus === BAD_DATA;
  const [ errorAlert, setErrorAlert ] = useState({ isOpen: false, message: '' });

  const [ selectedSpecies, setSelectedSpecies ] = useState(emptyOption);

  const handleChange = e => setRecord({ ...record, [e.target.name]: e.target.value });
  const handleSelect = obj => setRecord(prevRecord => ({ ...prevRecord, ...obj }));
  const toggleRenameAll = () => setRenameAll(prevRenameAll => !prevRenameAll);

  const exclusiveConditions = () => (
    isFloraRecord ? isIndividualsEmptyOrBetween1And10000 :
    isFloraPointQuadratRecord ? hasValidTouches && (hasSelectedPhenologies || isOnlyDead) :
    isFloraIndividualRecord ? hasCoords :
    true
  );


  const handleConfirm = async () => {
    try {
      setFetchStatus(LOADING);
      if (isFloraPointInterceptRecord || exclusiveConditions()) {
        const recordToUpdate = {
          ...record,
          speciesName: selectedSpecies.value ? undefined : selectedSpecies.label,
          speciesHash: selectedSpecies.value?.trim() ? selectedSpecies.value : record.speciesHash,
          ...(hasSpeciesChange ? { byOfficialSource: true } : {}),
          ...(isFloraIndividualRecord ?
            { latitude: coords.lat, longitude: coords.lng, altitude: coords.altitude, accuracy: coords.accuracy } : {}),
        };
        if ((isFloraRecord || isForestRecord || isFloraIndividualRecord) && (!isDetectionDateEditable && !prevRecord.detectionDate)) {
          delete recordToUpdate.detectionDate;
        }

        await actions.updateRecord({
          recordToUpdate,
          renameAll,
          initialState: prevRecord,
        }, record.type);
        setFetchStatus(SUCCESS);
        setErrorAlert({ isOpen: false, message: '' });
        setTimeout(() => actions.closeDialog(), 300);
      } else {
        setFetchStatus(BAD_DATA);
        setErrorAlert({ isOpen: true, message: 'Hubo campos con datos incorrectos, por favor revisa el formulario e intenta de nuevo' });
      }
    } catch (e) {
      setErrorAlert({ isOpen: true, message: e.serverMessage || e.message || 'Hubo un error, por favor intenta más tarde' });
      setFetchStatus(ERROR);
    }
  };

  const { individuals, touches } = record;

  const isIndividualsEmptyOrBetween1And10000 = individuals === '' || individuals === null || (1 <= individuals && individuals <= 10000);
  const hasSpeciesChange = selectedSpecies.label !== '' && prevRecord.scientificName !== selectedSpecies.label;
  const captorEmail = record.email;
  const isCaptor = currentUser.email === captorEmail;
  const hasSelectedPhenologies = record.phenologiesStatuses?.length > 0;
  const isOnlyDead = record.phytosanitaryStatuses?.length === 1 && record.phytosanitaryStatuses[0] === 'mu';
  const hasValidTouches = touches ? (1 <= parseInt(touches, 10) && parseInt(touches, 10) <= 100) : undefined;
  const hasCoords = !isEmpty(coords?.lat) && !isEmpty(coords?.lng);

  return (
    <>
      <DialogTitle id='form-dialog-title'>
        Edición {prevRecord.scientificName && <Box component='span' fontStyle='italic' >{`"${prevRecord.scientificName}"`}</Box>}
      </DialogTitle>
      <DialogContent>
        <Box p={1}>

          <Box my={2}>
            <InputLabel required shrink>Especie <em>(se mostrarán máximo 10 resultados)</em></InputLabel>
            <SelectAsync
              id="select-species"
              initialOptions={false}
              value={selectedSpecies}
              getOptions={({ queryText }) => getSpeciesOptions({
                queryText,
                recordType: record.type,
                recordId: record.recordId,
                ...((isFloraRecord || isForestRecord) ? { pointId: record.plotId } : {}),
                ...((isFloraPointQuadratRecord || isFloraPointInterceptRecord) ? {
                  transectPoint: record.pointNumber,
                  pointId: record.transectId,
                } : {}),
              })}
              placeholder={ucfl(record.scientificName)}
              useScientificNameFormat
              useScientificNameFormatInPlaceholder={record.scientificName !== null}
              freeSolo
              onChange={optionSelected => {
                if (typeof optionSelected === 'string') { // Cuando se selecciona con enter llega un string, magic
                  setSelectedSpecies({ label: optionSelected, value: undefined });
                } else if (optionSelected?.inputValue) { // Cuando se agrega un nuevo tipo
                  setSelectedSpecies({ label: optionSelected.inputValue, value: undefined });
                } else { // Cuando se selecciona normalmente
                  setSelectedSpecies({ label: optionSelected.label, value: optionSelected.hash });
                }
              }}
              filterOptions={(options, params) => {
                const speciesAlreadyExist = (
                  options.some(o => o.label.toLowerCase() === params.inputValue.toLowerCase() && o.userCreatorInfo === 'ti')
                );
                if (params.inputValue !== '' && !speciesAlreadyExist) {
                  options.push({
                    inputValue: params.inputValue,
                    label: `Agregar "${params.inputValue}"`,
                  });
                }
                return options;
              }}
              getOptionLabel={option => typeof option === 'string' ? option : option.label ? option.label : '' }
            />

          </Box>

          {(!isFloraPointQuadratRecord && !isFloraPointInterceptRecord) &&
            <Box my={2}>
              <FormControlLabel
                control={<Checkbox checked={renameAll} onChange={toggleRenameAll} />}
                label="¿Renombrar todos los registros a la misma especie?"
              />
              <Collapse in={hasSpeciesChange && renameAll}>
                <AlertWrapper variant="filled" severity='warning'>
                  {hasSpeciesChange &&
                    <>
                      Esto <strong>renombrará todos {isCaptor ? 'tus' : 'los'} registros</strong> que tengan
                      la especie <SpeciesNameAlt name={prevRecord.scientificName} /> a <SpeciesNameAlt name={selectedSpecies.label} />
                      {!isCaptor && <>que hayan sido registradas por {captorEmail}</>}
                      .
                    </>
                  }
                </AlertWrapper>
              </Collapse>
            </Box>
          }

          {isFloraRecord &&
            <FloraRecordInputs
              record={record}
              handlers={{ handleChange, handleSelect }}
              validators={{ isIndividualsEmptyOrBetween1And10000, hasBadData }}
              options={{ coverageOptions }}
            />
          }

          {isFloraIndividualRecord &&
            <Box my={2}>
              <InputLabel shrink>Coordenadas</InputLabel>
              <CoordinatesInput required value={coords} onChange={setCoords} />
              {hasBadData && !hasCoords && <FormHelperText error>Debes ingresar latitud y longitud</FormHelperText>}
            </Box>
          }

          {isFloraPointQuadratRecord &&
            <PointQuadratRecordInputs
              record={record}
              handlers={{ handleChange, handleSelect }}
              validators={{ hasValidTouches, hasBadData }}
              options={{ stratumsOptions }}
            />
          }

          {(isFloraRecord || isFloraIndividualRecord || isFloraPointQuadratRecord || isFloraPointInterceptRecord) &&
            <PhenologiesAndPhytosanitaryStatusesInputs
              record={record}
              handlers={{ handleSelect }}
              options={{ phenologiesStatusesOptions, phytosanitaryStatusesOptions }}
              validators={{ hasSelectedPhenologies, isOnlyDead }}
            />
          }
          {!isForestRecord && <>
            { !prevRecord.detectionDate &&
              <FormControlLabel
                label="¿Agregar fecha de detección?"
                control={
                  <Checkbox
                    checked={isDetectionDateEditable}
                    onChange={handleIsDetectionDateEditableChange}
                    name="Agregar fecha de detección"
                    color="primary"
                  />
                }
              />
            }

            { isDetectionDateEditable &&
              <Box my={2}>
                <InputLabel shrink>Fecha de detección</InputLabel>
                <Box display="flex" alignItems="center">
                  <Box display="flex" flexDirection="column">
                    <DateTimePicker
                      value={ record.detectionDate }
                      ampm={ false }
                      TextFieldComponent={ props => <TextField {...props} variant="outlined" size="small" /> }
                      onChange={ detectionDate => handleSelect({ detectionDate: new Date(detectionDate) }) }
                      format={ record.detectionDate ? 'DD-MM-YYYY HH:mm' : ' - ' }
                    />
                  </Box>
                </Box>
              </Box>
            }
          </>}

          {!isForestRecord &&
            <Box my={2}>
              <InputLabel shrink>Observaciones</InputLabel>
              <TextField name="observation" value={record.observation} onChange={handleChange} minRows="2"
                maxRows="5" multiline fullWidth autoComplete="off" variant="outlined" size="small" />
            </Box>
          }
          <AlertWrapper severity="error" isOpen={errorAlert.isOpen} onClose={() => setErrorAlert(s => ({ ...s, isOpen: false }))}>
            {errorAlert.message}
          </AlertWrapper>

        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.closeDialog}>Cancelar</Button>
        <DialogButton fetchStatus={fetchStatus} onClick={handleConfirm} color='primary'>Editar</DialogButton>
      </DialogActions>
    </>
  );
};

FloraRecordEditDialog.propTypes = {
  prevRecord: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  options: PropTypes.object,
  getOptions: PropTypes.object,
};


export { FloraRecordEditDialog };