import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import formatters from 'src/utils/formatters';


const SpeciesName = ({ species = {}, quotes = false }) =>
  <Box component='span' fontStyle='italic' >
    { quotes && '"' }{ formatters.species(species) }{ quotes && '"' }
  </Box>;

const SpeciesNameAlt = ({ name }) =>
  <Box component='span' fontStyle='italic'>
    &quot;{formatters.upperCaseFirstLetter(name)}&quot;
  </Box>;

SpeciesName.propTypes = {
  species: PropTypes.object,
  quotes: PropTypes.bool,
};

SpeciesNameAlt.propTypes = {
  name: PropTypes.string,
};

export { SpeciesName, SpeciesNameAlt };